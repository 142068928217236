<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Add code to multiple"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content fxLayout="column">
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="grow"
      *ngIf="!addSeparately"
    >
      <textarea
        [value]="
          formattedSelectedNodesTargets[0] | targetTitle: titleMode:titleLevels
        "
        matInput
        [readonly]="true"
        class="formatted-selected-targets-title"
      ></textarea>
    </mat-form-field>

    <div
      fxLayout="row"
      fxLayoutGap="10px"
    >
      <div fxFlex="50">
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field add-mode-field"
        >
          <mat-label>Add mode</mat-label>
          <mat-select
            [(ngModel)]="selectedAddMode"
            (ngModelChange)="onSelectedAddModeChange()"
          >
            <mat-option
              *ngFor="let addMode of addModeOptions"
              [value]="addMode"
            >
              {{ addMode }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          class="add-separately-control-container"
        >
          <mat-slide-toggle
            color="primary"
            [(ngModel)]="addSeparately"
            [checked]="addSeparately"
            (ngModelChange)="onAddSeparatelyChange()"
            labelPosition="before"
            matTooltip="This allows you to add the selected codes to the selected rows, with each code added to to each row sequentially in the order selected."
          >
            Add separately
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="10px"
      class="target-list-container"
    >
      <div fxFlex="50">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="target-list-header"
        >
          <h2 class="target-list-title">Add code to multiple</h2>
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            color="primary"
            [(ngModel)]="selectAll"
            (ngModelChange)="onSelectAllChange()"
            class="select-all-control"
            >Select all</mat-checkbox
          >
        </div>
        <div class="target-list">
          <div
            fxLayout="column"
            class="target-list-item-container"
          >
            <div fxLayout="column">
              <mat-checkbox
                *ngFor="let targetOption of targetOptions"
                tupMatCheckbox
                appearance="small"
                color="primary"
                [(ngModel)]="targetOption.selected"
                (ngModelChange)="onSelectTargetOption()"
                fxFlex
                fxLayoutAlign="start center"
                class="tiny-checkbox selectable-target-list-item"
                >{{
                  targetOption.targetItem.target
                    | targetTitle
                      : targetOption.targetItem.target.activeTitleMode
                      : targetOption.targetItem.target.titleLevels
                }}</mat-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="50">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="target-list-header"
        >
          <h2 class="target-list-title">{{ selectedTargetType }}</h2>
        </div>
        <div class="target-list">
          <div
            fxLayout="column"
            class="target-list-item-container"
          >
            <table class="selected-target-table">
              <tr
                *ngFor="let title of selectedFormattedTargetTitles"
                class="selected-target-list-item"
              >
                <td>{{ title }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onAddCodeClick()"
      data-qa="add-code-button"
    >
      Add code
    </button>
  </mat-dialog-actions>
</div>
