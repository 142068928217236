<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Add insights"
    class="dialog-title"
    cdkDragHandle
  >
    <app-undo-redo-controls></app-undo-redo-controls>

    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="!isProgressing && close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>
  <mat-dialog-content fxLayout="column">
    <div
      fxFlex
      fxLayout="column grid"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="split-wrapper"
    >
      <ui-split
        unit="pixel"
        direction="horizontal"
        #split="uiSplit"
      >
        <ui-split-area
          [size]="splitSizes.codebook"
          [minSize]="splitSizes.minSize"
          [maxSize]="splitSizes.maxSize"
        >
          <div
            fxLayout="column"
            class="left-panel"
          >
            <app-codebook-and-visual-code-builder-panel
              #codebookVisualCodeBuilderPanel
              [selectedSurvey]="selectedSurvey"
              [isReadonly]="false"
              (codebookContextMenu)="onCodebookContextMenu($event)"
              (closePanel)="onVisualCodeBuilderClose()"
              (applyChanges)="onApplyCodingChanges($event)"
              (dropNode)="onDropNode($event)"
              (isSearchingOrFiltering)="
                onCodebookSearchingOrFilteringStateChange($event)
              "
            >
            </app-codebook-and-visual-code-builder-panel>
          </div>
        </ui-split-area>

        <ui-split-area>
          <div
            fxFill
            fxLayout="column"
            #gridViewContainer
          >
            <div
              fxFlex
              class="data-controls-container grid-views-container"
              fxLayout="row"
              fxLayoutGap="10px"
            >
              <app-coding-grid-action-controls
                #appCodingGridActionControls
                [isAddToRowsOnly]="true"
                [isDeletable]="isDeletable"
                [isReadonly]="false"
                [selectedRows]="selectedRows"
                [selectedTab]="targetType.rows"
                [findAndReplaceDialogOpen]="findAndReplaceDialogOpen"
                [findAndReplaceDialogState]="findAndReplaceDialogState"
                [moreMenuVisibility]="moreMenuVisibility"
                [isColumnHidden]="isColumnHidden"
                [hideColumnItems]="hideColumnItems"
                (openFindAndReplaceClicked)="onOpenFindAndReplace()"
                (deleteSelectedRowsClicked)="onDeleteSelectedRows()"
                (separateCountActionClicked)="onSeparateCountActionClicked()"
                (openNTileSettingsActionClicked)="onOpenNTileSettings()"
                (openNewCodeBuilderActionClicked)="onOpenNewCodeBuilder()"
                (duplicateSelectedRowsActionClicked)="onDuplicateSelectedRows()"
                (renameGroupNameActionClicked)="onRenameGroupName()"
                (saveCustomAudienceActionClicked)="onSaveCustomAudience()"
                (openMoreMenuActionClicked)="onOpenMoreMenu()"
                (hideColumnsActionClicked)="onHideColumns()"
                (combineActionClicked)="onCombineActionClicked($event)"
                (changeTargetTitleModeActionClicked)="
                  onChangeTargetTitleModeActionClicked($event)
                "
              >
              </app-coding-grid-action-controls>
            </div>

            <div class="add-insights-table">
              <app-coding-grid-table
                #rowCodingGrid
                fxFlex
                [isReadonly]="false"
                [isAddToRowsOnly]="true"
                [tableTargetType]="targetType.rows"
                [data]="rows"
                [sort]="gridSort"
                [dropzoneMenu]="dropzoneMenu"
                [searchHits]="searchHits"
                [isDeletable]="isDeletable"
                [isProgressing]="isProgressing"
                [gridPreference]="codingGridPreferences[targetType.rows]"
                (editClicked)="onOpenCodeBuilder($event)"
                (selectedRowsChange)="onSelectedRowsChange($event)"
                (deleteClicked)="onDeleteSelectedRows($event)"
                (deleteAllClicked)="onDeleteAllRows($event)"
                (combineClicked)="onCombineActionClicked($event)"
                (separateCountClicked)="onSeparateCountActionClicked($event)"
                (changeTargetTitleModeClicked)="
                  onChangeTargetTitleModeActionClicked($event)
                "
                (clickedRow)="onClickRow($event)"
                (clickedEmptyRow)="onClickEmptyRow()"
                (assignGroupNameClicked)="onRenameGroupName($event)"
                (duplicateClicked)="onDuplicateSelectedRows($event)"
                (nTileSettingsClicked)="onOpenNTileSettings($event)"
                (saveCustomAudienceClicked)="onSaveCustomAudience($event)"
                [isViewActive]="true"
                (dropzoneEnter)="
                  onDropzoneEnter(
                    $event.trigger,
                    $event.targetType,
                    $event.targetItem
                  )
                "
                (dropzoneLeave)="onDropzoneLeave()"
                (dropzoneDrop)="onTableDropzoneDrop($event)"
                (moveItems)="onMoveItems($event)"
                (sortChange)="onSortChange($event)"
                (sortedRows)="onSortedRowsChange($event, targetType.rows)"
              ></app-coding-grid-table>
            </div>
          </div>
        </ui-split-area>
      </ui-split>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="dialog-actions"
  >
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      [disabled]="isProgressing"
      (click)="close()"
    >
      Close
    </button>
  </mat-dialog-actions>
</div>

<mat-menu
  #dropzoneMenu="matMenu"
  [hasBackdrop]="false"
  class="action-menu"
>
  <div class="action-menu-button-container">
    <button
      mat-menu-item
      *ngFor="let dropzoneActionItem of dropzoneActionItems"
      [dndDropzone]
      [dndDragoverClass]="menuItemDragoverClass"
      [dndDisableDropIf]="isLoadingSelectedNodes"
      [disabled]="isLoadingSelectedNodes"
      (dragenter)="onActionMenuEnter($event)"
      (dragleave)="onActionMenuLeave($event)"
      (dndDrop)="onDrop(dropzoneActionItem)"
    >
      {{ dropzoneActionItem.name }}
    </button>
  </div>
</mat-menu>

<div
  class="codebook-context-menu__trigger"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="codebookContextMenu"
  #codebookMenuTrigger="matMenuTrigger"
></div>
<mat-menu
  #codebookContextMenu="matMenu"
  data-qa="codebook-context-menu"
>
  <ng-template
    matMenuContent
    let-node="node"
    let-path="path"
    let-level="level"
    let-selectedNodes="selectedNodes"
    let-selectionTreeNode="selectionTreeNode"
  >
    <div
      *ngIf="
        selectedNodes && selectedNodes.length > 0;
        else codebookSingleSelection
      "
      class="contextual-menu"
      data-qa="codebook-context-menu"
    >
      <div
        class="mat-menu-title"
        mat-menu-item
      >
        <b>Selection</b>
      </div>
      <mat-divider></mat-divider>
      <button
        *ngIf="!node.shouldDisableAdding"
        mat-menu-item
        (click)="addToRows(selectedNodes)"
      >
        Add selected to Rows
      </button>

      <button
        *ngIf="!node.shouldDisableAdding"
        mat-menu-item
        (click)="addToMultiple(selectedNodes, targetType.rows)"
      >
        Add code to multiple Rows
      </button>

      <ng-container *ngIf="!node.coding">
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="expandAllCodebook(node)"
          [title]="node.description"
        >
          Expand <b>{{ node.description }}</b>
        </button>
      </ng-container>

      <ng-container
        *ngIf="node.isMyContainer && !isCodebookSearchingOrFiltering"
      >
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="updateCustomAudiencesNodeContainer(node)"
          [title]="node.description"
        >
          Move <b>{{ node.description }}</b> to
        </button>
      </ng-container>

      <ng-container *ngIf="node.isMyContainer">
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="editCustomAudiences(node)"
          [title]="node.description"
        >
          Edit <b>{{ node.description }}</b>
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="deleteCustomAudiencesNode(node)"
          [title]="node.description"
        >
          Delete <b>{{ node.description }}</b>
        </button>

        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="renameCustomAudiencesNode(selectionTreeNode)"
          [title]="node.description"
        >
          Rename <b>{{ node.description }}</b>
        </button>
      </ng-container>

      <ng-container
        *ngIf="
          node.isCustomCoding &&
          !node.isMyContainer &&
          node.document &&
          !isCodebookSearchingOrFiltering
        "
      >
        <mat-divider></mat-divider>
        <span
          mat-menu-item
          class="is-inactive"
        >
          Owned by <b>{{ node.document.metadata.by.attributes.name }}</b>
        </span>
      </ng-container>

      <ng-container *ngIf="node.canRefresh">
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="sort(selectionTreeNode, 'aToZ')"
        >
          Sort by A-Z
        </button>
        <button
          mat-menu-item
          (click)="sort(selectionTreeNode, 'desc')"
        >
          Sort by created date
        </button>

        <button
          *ngIf="!isCodebookSearchingOrFiltering"
          mat-menu-item
          (click)="refreshCustomNodes(selectionTreeNode)"
        >
          Refresh
        </button>
      </ng-container>

      <ng-container *ngIf="node.canEditTitle">
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          (click)="editCustomNodeTitle(selectionTreeNode)"
        >
          Edit Title
        </button>
      </ng-container>
    </div>
    <ng-template #codebookSingleSelection>
      <div
        class="contextual-menu"
        data-qa="codebook-context-menu"
      >
        <div
          class="mat-menu-title"
          mat-menu-item
        >
          <b>{{ node.description }}</b>
        </div>
        <mat-divider></mat-divider>
        <button
          *ngIf="!node.shouldDisableAdding"
          mat-menu-item
          (click)="addToRows(path)"
        >
          Add to Rows
        </button>
        <button
          *ngIf="!node.shouldDisableAdding"
          mat-menu-item
          (click)="addToMultiple(path, targetType.rows)"
        >
          Add code to multiple Rows
        </button>

        <ng-container *ngIf="!node.coding">
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="expandAllCodebook(node)"
          >
            Expand
          </button>
        </ng-container>

        <ng-container *ngIf="node.canRefresh">
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="sort(selectionTreeNode, 'aToZ')"
          >
            Sort by A-Z
          </button>
          <button
            mat-menu-item
            (click)="sort(selectionTreeNode, 'desc')"
          >
            Sort by created date
          </button>
          <button
            *ngIf="!isCodebookSearchingOrFiltering"
            mat-menu-item
            (click)="refreshCustomNodes(selectionTreeNode)"
          >
            Refresh
          </button>
        </ng-container>

        <ng-container *ngIf="node.canEditTitle">
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            (click)="editCustomNodeTitle(selectionTreeNode)"
          >
            Edit Title
          </button>
        </ng-container>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>

<mat-menu #changeTitleModeMenu="matMenu">
  <div data-qa="change-title-mode-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of changeTitleModeItems"
      (click)="onChangeTargetTitleModeActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #combineMenu="matMenu">
  <div data-qa="combine-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of combineActionItems"
      [matTooltip]="action.tooltip"
      matTooltipPosition="right"
      (click)="onCombineActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #moreMenu="matMenu">
  <div
    data-qa="more-action-menu"
    class="more-action-menu"
  >
    <button
      data-tracking="coding-grid-delete-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      (click)="onDeleteSelectedRows()"
    >
      <mat-icon
        matTooltip="Delete selected items, all items or coding grid"
        class="material-icons-outlined"
      >
        delete
      </mat-icon>
      Delete
    </button>

    <button
      data-tracking="coding-grid-find-and-replace-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onOpenFindAndReplace()"
      [disabled]="findAndReplaceDialogOpen || findAndReplaceDialogState"
    >
      <mat-icon matTooltip="Search">search</mat-icon>
      Search
    </button>

    <button
      data-tracking="coding-grid-count-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      (click)="onSeparateCountActionClicked()"
      data-qa="separate-count-top-menu-action"
    >
      <mat-icon
        matTooltip="Separate count"
        class="material-symbols-outlined"
      >
        calculate
      </mat-icon>
      Separate count
    </button>

    <button
      data-tracking="coding-grid-ntiles-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onOpenNTileSettings()"
      [disabled]="selectedRows.length !== 1 || !isDeletable"
    >
      <mat-icon matTooltip="N-Tiles">grid_on</mat-icon>
      N-Tiles
    </button>

    <button
      data-tracking="coding-grid-new-code-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onOpenNewCodeBuilder()"
      [disabled]="selectedRows.length > 0"
    >
      <mat-icon matTooltip="Create new coding statement"
        >integration_instructions</mat-icon
      >
      New coding statement
    </button>

    <button
      data-tracking="coding-grid-title-mode-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      [matMenuTriggerFor]="changeTitleModeMenu"
      data-qa="title-mode-top-menu-action"
    >
      <mat-icon
        matTooltip="Change title mode"
        class="material-symbols-outlined"
      >
        title
      </mat-icon>
      Title mode
    </button>

    <button
      data-tracking="coding-grid-duplicate-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      data-qa="duplicate-top-menu-action"
      (click)="onDuplicateSelectedRows()"
    >
      <mat-icon
        matTooltip="Duplicate selected items"
        class="material-symbols-outlined"
      >
        content_copy
      </mat-icon>
      Duplicate
    </button>

    <button
      data-tracking="coding-grid-combine-btn"
      mat-menu-item
      class="menu-control-button"
      [matMenuTriggerFor]="combineMenu"
      [disabled]="selectedRows.length < 2"
      data-qa="combine-top-menu-action"
    >
      <mat-icon
        matTooltip="Combine selected items"
        class="material-symbols-outlined"
      >
        join
      </mat-icon>
      Combine
    </button>

    <button
      data-tracking="coding-grid-group-btn"
      mat-menu-item
      class="menu-control-button"
      [disabled]="!isDeletable"
      data-qa="group-name-top-menu-action"
      (click)="onRenameGroupName()"
    >
      <mat-icon
        matTooltip="Assign group name"
        class="material-symbols-outlined"
      >
        group_work
      </mat-icon>
      Assign group name
    </button>

    <button
      data-tracking="coding-grid-hide-columns-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        isColumnHidden
          ? 'hide-column-flat-button'
          : 'hide-column-stroked-button'
      "
      [matMenuTriggerFor]="hideColumns"
    >
      <mat-icon
        matTooltip="Hide columns"
        class="material-symbols-outlined"
      >
        hide
      </mat-icon>
      Hide columns
    </button>

    <button
      data-tracking="coding-grid-save-custom-audience-btn"
      mat-menu-item
      class="menu-control-button"
      (click)="onSaveCustomAudience()"
      [disabled]="!isDeletable"
    >
      <mat-icon
        matTooltip="Save selected items to custom audiences"
        class="material-symbols-outlined"
      >
        bookmark
      </mat-icon>
      Save custom audiences
    </button>
  </div>
</mat-menu>

<mat-menu #hideColumns="matMenu">
  <div
    data-qa="hide-column-mode-action-menu"
    (click)="$event.stopPropagation()"
  >
    <mat-selection-list
      #hideColumnSelectionList
      (selectionChange)="onHideColumns()"
    >
      <mat-list-option
        color="primary"
        data-tracking="data-context-option"
        *ngFor="let item of hideColumnItems[targetType.rows]"
        [value]="item.value"
        [selected]="item.show"
        class="list-option-background"
      >
        {{ item.label }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-menu>

<button
  #virtualButton
  class="hidden-virtual-button"
></button>
