import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { TargetType } from '../models';

export interface MoveTargetItemsActionContext {
  type: TargetType;
  sourceIndices: number[];
  targetIndex: number;
}

@Injectable({
  providedIn: 'root',
})
export class MoveTargetItemsAction extends ActionDefinition<MoveTargetItemsActionContext> {
  constructor(private documentService: DocumentService) {
    super();
  }

  public invoke(context: MoveTargetItemsActionContext): void {
    this.documentService.moveTargetItems(context);
  }
}
