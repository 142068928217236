import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import {
  CHART_SETTING_VERSION,
  ChartFilter,
  ChartFilterOperator,
  ChartSearchHit,
  ChartSearchInput,
  ChartSelectState,
  ChartSettings,
  ChartSettingsMode,
  ChartTargetMode,
  DATA_VIEW_MODES,
  DataViewMode,
  GLOBAL_CHART_SETTING_KEYS,
  GlobalChartSettings,
  INITIAL_CHART_COUNT,
  MAX_EXPORT_CHARTS_NUMBER,
  MAX_QUICK_REPORT_CUSTOM_AUDIENCES,
  MultipleSurveyChartData,
} from 'src/app/models/charts.model';
import {
  ALL_RESPONDENTS_CODING,
  APPLICATION_NAME,
  DocumentDataState,
  EMPTY_TARGET_ITEM_INDEX,
  Operator,
  SearchHit,
  Target,
  TargetItem,
  TargetType,
} from 'src/app/models/document.model';
import { CrossTabTableData } from 'src/app/models/crosstab.model';
import { CrosstabService } from 'src/app/services/crosstab.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DocumentService } from 'src/app/services/document.service';
import {
  ChartSettingsService,
  ChartsService,
  DataItemsService,
  PptxService,
  TargetService,
  XlsxChartsService,
} from '../../services';
import { SelectMenuOption } from 'src/app/models/application.model';
import { AddInsightsService } from '../../services/add-insights.service';
import {
  ChartStatus,
  DataItem,
  DataItemId,
  DataItemType,
  ExportFileType,
  Survey,
  SurveyCodeMap,
  SurveyTimeProps,
} from 'src/app/models';
import { first, takeUntil } from 'rxjs/operators';
import { ChartComponent } from '../chart/chart.component';
import { getSurveyTimeProps } from '../../utils/export-utils';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';
import { SurveyTimeXlsxChartsBuilder } from '../../builders/surveytime-xlsx-charts.builder';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { cloneDeep, groupBy, isEmpty, isEqual, uniqBy } from 'lodash';
import { DisplayType } from '@telmar-global/tup-audience-groups';
import { TargetTitlePipe } from '../../pipes';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { FindAndReplaceService } from 'src/app/services/find-and-replace.service';

export interface TargetOption {
  id: string;
  title: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss'],
})
export class GraphViewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() isReadonly = true;
  @Input() isQuickReport = true;
  @Input() targetMode;
  @Input() survey: Survey;
  @Input() surveys: Survey[];
  @Input() isLoading: boolean;
  @Output() chartExportEnabled: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public chartList = [];

  private allCombinedChartList = [];
  public combinedChartList = [];

  private allSingleChartList = [];
  public singleChartList = [];

  public insightsGroupChartList: MultipleSurveyChartData[];
  public surveysGroupChartList: MultipleSurveyChartData[];

  public chartTargetModeType: typeof ChartTargetMode = ChartTargetMode;
  public dataViewModeType: typeof DataViewMode = DataViewMode;

  public chartFilterOperator = ChartFilterOperator;
  public dataItemType = DataItemType;

  private defaultCrossTabData: CrossTabTableData[];
  private shouldIncludeTotalsRow = false;
  public shouldIncludeTotalsColumn = false;
  public defaultTargets: Target[];
  public defaultTargetOptions: TargetOption[] = [{ id: 'all', title: 'All' }];
  public defaultTargetGroupNames: string[];
  public defaultTargetGroups: Record<string, Target[]>;
  private dynamicCrossTabData: CrossTabTableData[];
  public dynamicTargets: Target[] = [];
  public customAudienceTargets: Target[];

  private dynamicDataUpdated = new BehaviorSubject<boolean>(false);
  public dynamicDataUpdated$ = this.dynamicDataUpdated.asObservable();

  public selectedSurveysInSingleTarget: string[];
  public selectedSurveysInCombinedTargets: string[];
  public selectedTargetOption: TargetOption[] = [];
  public selectedSingleTargetCustomAudiencesOption: string | Target = 'none';
  public selectedCombinedTargetsCustomAudiencesOption: string[] | Target[] = [];
  public preSelectedCombinedTargetsCustomAudiencesOption: string[] | Target[] =
    [];

  public selectedSingleTargetGroupTarget: Record<string, Target | 'none'>;
  public selectedCombinedTargetsGroupTarget: Record<string, Target[] | []>;
  public preSelectedCombinedTargetsGroupTarget: Record<string, Target[] | []> =
    {};

  public customAudiencesLoaded = false;

  public chartSettings: ChartSettings;
  public dataItemKeys: Record<DataItemId, DataItem>;
  public targetColors: Record<string, string>;

  public selectedDataViewMode: DataViewMode = DataViewMode.default;
  public dataViewModes: SelectMenuOption<DataViewMode>[] = DATA_VIEW_MODES;
  public singleChartFullWidthStatus: ChartStatus = {};
  private unloadedChartList = [];
  public chartNumber = 0;

  public isInitialising = false;
  public isLoadingDynamicData = false;
  private customAudiencesDataReadySub: Subscription;
  private customAudiencesDataReady = new BehaviorSubject<boolean>(false);
  public customAudiencesDataReady$ =
    this.customAudiencesDataReady.asObservable();
  public isExporting = false;

  public searchHits: SearchHit[];
  public findAndReplaceDialogState: boolean;
  public findAndReplaceDialogOpen = false;
  public highlightSearchChartIndices: number[] = [];
  public focusedHighlightSearchChartIndex = -1;
  public maxQuickReportCustomAudiences = MAX_QUICK_REPORT_CUSTOM_AUDIENCES;

  @ViewChild('chartScrollbar') chartScrollbar: ElementRef;
  @ViewChild('viewContainer') viewContainer: ElementRef;
  @ViewChild('chartSettingsRow') chartSettingsRow: ElementRef;
  @ViewChild('additionalChartSettingsRow')
  additionalChartSettingsRow: ElementRef;
  public chartContainerHeight = 0;
  public isAdditionalChartSettingsRowExpanded = false;
  public filteredTablebases: TargetItem[];
  public currentTablebase: TargetItem;
  public currentTablebaseTarget: Target;

  public surveyCodeMap: SurveyCodeMap;

  private unsubscribe: Subject<void> = new Subject<void>();
  @ViewChildren(ChartComponent) charts: QueryList<ChartComponent>;

  private needRefresh = false;

  private selectedCharts: Record<string, ChartSelectState> = {};
  public numbOfSelectedCharts = 0;

  public showScrollToTopBtn = false;
  private readonly scrollOffset = 200;

  constructor(
    private targetTitlePipe: TargetTitlePipe,
    private documentService: DocumentService,
    private chartSettingsService: ChartSettingsService,
    private crossTabService: CrosstabService,
    private chartsService: ChartsService,
    private dialogService: DialogService,
    private dataItemsService: DataItemsService,
    private pptxService: PptxService,
    private xlsxChartsService: XlsxChartsService,
    private messageService: TupUserMessageService,
    private targetService: TargetService,
    private findAndReplaceService: FindAndReplaceService,
    private addInsightsService: AddInsightsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.unsetSelectedCharts();
    this.selectedDataViewMode = this.isQuickReport
      ? DataViewMode.dynamic
      : this.selectedDataViewMode || DataViewMode.default;

    this.scrollToTop();
    this.listenToDataItemsChanges();
    this.listenToCrossTabDataChanges();
    this.listenToDocumentStateChanges();
    this.listenToSearchHitChanges();
    this.prepareTrendingChartData();
    this.listenToTablebaseDataChanges();
    if (!this.isQuickReport) {
      this.prepareViewData();
    } else {
      this.listenToCustomAudiencesChanges();
    }
    this.prefetchCustomAudienceData();
    this.listenToDynamicDataChanges();
    this.listenToExportXlsxChanges();
    this.listenToExportPptChanges();
  }

  private listenToTablebaseDataChanges(): void {
    combineLatest([
      this.documentService.documentState$.pipe(isNotNullOrUndefined()),
      this.documentService.tablebases$.pipe(isNotNullOrUndefined()),
      this.documentService.activeTablebase$.pipe(isNotNullOrUndefined()),
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        ([{ tables }, tablebases, tablebase]: [
          DocumentDataState,
          Target[],
          Target
        ]) => {
          const noneDeletableIndex = EMPTY_TARGET_ITEM_INDEX;
          this.filteredTablebases = [
            ...tables.map((target: Target, index: number) => ({
              type: TargetType.tables,
              target,
              index:
                target.coding !== ALL_RESPONDENTS_CODING
                  ? index
                  : noneDeletableIndex,
            })),
            ...tablebases.map((target: Target) => ({
              type: TargetType.tables,
              target,
              index: noneDeletableIndex,
            })),
          ];
          this.currentTablebase = this.filteredTablebases.find(
            (item) => item.target === tablebase
          );

          this.currentTablebaseTarget = this.currentTablebase?.target;
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.addScrollListener();
    }, 1000);

    if ('targetMode' in changes && !changes.targetMode.firstChange) {
      this.onTargetModeChanged();
      return;
    }

    const loadingStateChanged =
      'isLoading' in changes &&
      changes.isLoading.previousValue &&
      changes.isLoading.previousValue !== changes.isLoading.currentValue;
    const surveyChanged =
      'survey' in changes &&
      changes.survey.previousValue &&
      changes.survey.previousValue !== changes.survey.currentValue;
    if (loadingStateChanged || surveyChanged) {
      this.refreshView();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateChartContainerHeight();
    }, 0);
  }

  @HostListener('window:resize')
  public onResize() {
    this.updateChartContainerHeight();
  }

  @HostListener('window:keydown', ['$event'])
  listenToSearchEvent(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
      if (!(this.findAndReplaceDialogOpen || this.findAndReplaceDialogState)) {
        event.preventDefault();
        const chartList = this.getChartListData();
        this.openFindAndReplace(
          this.targetMode,
          this.selectedDataViewMode,
          chartList
        );
      }
    }
  }

  public loadMoreCharts(): void {
    if (!this.canLoadMoreCharts()) {
      return;
    }
    this.updateChartList();
  }

  // tslint:disable-next-line:no-shadowed-variable
  public onFilterRemove(index: number): void {
    const selectedChartSettings = this.getSelectedChartSettings();
    selectedChartSettings.forEach((settings: ChartSettings) => {
      const filters: ChartFilter[] = settings.filters;
      filters[index].dataItem = DataItemType.none;
      filters[index].operator = ChartFilterOperator.none;
      filters[index].value[0] = 0;
      filters[index].value[1] = 0;

      const newSettings = {
        ...settings,
        filters,
      };
      this.chartSettingsService.saveChartSettings(newSettings);
    });
    this.refreshView();
  }

  public openChartFilter(): void {
    const selectedChartSettings: ChartSettings[] =
      this.getSelectedChartSettings();

    const filters: ChartFilter[] = this.getGlobalFilters(selectedChartSettings);

    this.dialogService
      .chartFilters(
        ChartSettingsMode.global,
        filters,
        this.chartSettings.targetMode,
        '600px'
      )
      .afterClosed()
      .subscribe((chartFilters: ChartFilter[] | null) => {
        if (chartFilters && !isEqual(chartFilters, filters)) {
          this.chartSettingsService.saveGlobalChartFilters(
            selectedChartSettings,
            chartFilters
          );

          this.refreshView();
        }
      });
  }

  public onUnselectAllCharts(): void {
    this.charts.map((chart: ChartComponent) => {
      chart.exportSelected = false;
    });
    this.unsetSelectedCharts();
  }

  public openChartSettings(): void {
    if (this.selectedDataViewMode === DataViewMode.dynamic) {
      this.openOriginalChartSettings();
      return;
    }
    const selectedChartKeys = Object.keys(this.selectedCharts);
    const shouldSelectAll = selectedChartKeys.length < 1;
    const chartSettings = this.getSelectedChartSettings();
    const config: GlobalChartSettings = {
      activeTitleMode: '',
      titleLevels: undefined,
      primaryDataItem: '',
      primaryChartType: '',
      secondaryDataItem: '',
      secondaryChartType: '',
      topRowsCount: '',
      columnSortOrder: '',
      decimalPlaces: '',
    };
    GLOBAL_CHART_SETTING_KEYS.forEach((key) => {
      const values = chartSettings.map((settings) => settings[key]);
      const areAllValuesEqual = values.every((value) => value === values[0]);
      config[key] = areAllValuesEqual
        ? values[0]
        : key !== 'titleLevels'
        ? ''
        : undefined;
    });

    this.dialogService
      .globalChartSettings(config, this.targetMode)
      .afterClosed()
      .subscribe((settings: GlobalChartSettings | null) => {
        if (settings) {
          this.chartSettingsService.saveGlobalChartSettings(
            chartSettings,
            settings,
            shouldSelectAll
          );
          this.refreshView();
        }
      });
  }

  public openOriginalChartSettings(): void {
    this.dialogService
      .chartSettings(
        ChartSettingsMode.global,
        this.chartSettings,
        this.targetColors,
        this.getActiveChartTargets(),
        null,
        null,
        null,
        this.isReadonly
      )
      .afterClosed()
      .subscribe((settings: ChartSettings | null) => {
        if (settings) {
          this.chartSettingsService.saveChartSettings(settings);
          this.refreshView();
        }
      });
  }

  public openFindAndReplace(
    targetMode: ChartTargetMode,
    dataViewModeType: DataViewMode,
    chartListData: any
  ) {
    if (this.findAndReplaceDialogOpen) {
      return;
    }

    this.findAndReplaceDialogOpen = true;
    setTimeout(() => {
      this.findAndReplaceDialogOpen = false;
    }, 1000);

    const data = {
      targetMode,
      dataViewModeType,
      chartListData,
    } as ChartSearchInput;

    this.dialogService
      .findAndReplace(data)
      .afterClosed()
      .subscribe((_) => {
        this.findAndReplaceService.updateDialogState(false);
        this.findAndReplaceService.updateSearchHits([]);
      });
  }

  public onTargetModeChanged(): void {
    this.unsetSelectedCharts();
    this.scrollToTop();
    this.updateChartSearchInput();

    if (
      this.targetMode === ChartTargetMode.single ||
      this.targetMode === ChartTargetMode.combined
    ) {
      if (this.selectedDataViewMode === DataViewMode.dynamic) {
        this.listenToCustomAudiencesChanges();
      } else {
        this.prepareViewData();
      }
    } else {
      this.initChartSettings();
      this.initialiseChartList();
    }
    setTimeout(() => {
      this.updateChartContainerHeight();
    }, 0);
  }

  public onDataViewModeChanged(): void {
    this.unsetSelectedCharts();
    if (this.selectedDataViewMode !== DataViewMode.dynamic) {
      this.prepareViewData();
      this.updateChartSearchInput();
    } else {
      this.listenToCustomAudiencesChanges();
    }
  }

  public onGroupTargetChanged(): void {
    if (this.targetMode === ChartTargetMode.single) {
      this.prepareDynamicData();
    }
  }

  public onGroupSelectOpenedChange(opened: boolean, groupName: string): void {
    if (opened) {
      this.preSelectedCombinedTargetsGroupTarget[groupName] =
        this.selectedCombinedTargetsGroupTarget[groupName];
    } else {
      if (
        !isEqual(
          this.preSelectedCombinedTargetsGroupTarget[groupName],
          this.selectedCombinedTargetsGroupTarget[groupName]
        )
      ) {
        this.prepareDynamicData();
      }
    }
  }

  public onSingleTargetChanged(): void {
    this.scrollToTop();
    this.prepareSingleChartList();
    this.initialiseChartList();
    this.updateChartSearchInput();
  }

  public onCustomAudienceTargetChanged(): void {
    this.scrollToTop();

    if (this.targetMode === ChartTargetMode.single) {
      this.prepareDynamicData();
    }
  }

  public onSelectOpenedChange(opened: boolean): void {
    if (opened) {
      this.preSelectedCombinedTargetsCustomAudiencesOption =
        this.selectedCombinedTargetsCustomAudiencesOption;
    } else {
      if (
        !isEqual(
          this.preSelectedCombinedTargetsCustomAudiencesOption,
          this.selectedCombinedTargetsCustomAudiencesOption
        )
      ) {
        this.prepareDynamicData();
      }
    }
  }

  public onTableBaseChanged(): void {
    this.prepareDynamicData();
  }

  public saveAsNormalReport(): void {
    const formattedTargets = this.formatDynamicTargets();
    const currentTable = this.currentTablebase.target;
    this.documentService.saveAsNormalReport(formattedTargets, currentTable);
  }

  public onSelectedSurveysInCombinedTargetChanged(): void {
    this.scrollToTop();
    this.prepareCombinedChartList();
    this.initialiseChartList();
  }

  public refreshView(): void {
    // todo: to be improved, we need to sort out individual chart update issue in the lib
    this.ngOnDestroy();
    this.ngOnInit();
  }

  public refreshGlobalSettings(): void {
    this.initChartSettings();
  }

  public onChartSelectStateChanged(chartSelectState: ChartSelectState): void {
    if (chartSelectState.selected) {
      this.selectedCharts[chartSelectState.id] = chartSelectState;
    } else {
      delete this.selectedCharts[chartSelectState.id];
    }
    this.numbOfSelectedCharts = Object.keys(this.selectedCharts).length;
  }

  public increaseWidthChartContainer($event, chart): void {
    this.singleChartFullWidthStatus[`${chart.target.id}_${chart.title}`] =
      $event;
  }

  public async exportAs(type: ExportFileType, docName?: string): Promise<void> {
    while (this.canLoadMoreCharts()) {
      this.updateChartList();
    }
    setTimeout(() => {
      const allChartProps = this.getSlideProps();
      let chartToExportProps = allChartProps.filter(
        (prop: SurveyTimeProps) => prop.selected
      );
      if (chartToExportProps.length === 0) {
        chartToExportProps = allChartProps;
      }

      this.exportCharts(type, chartToExportProps, docName);
    }, 0);
  }

  selectionChanged(e: any): void {
    const allId = this.defaultTargetOptions[0].id;
    if (e.value.id === allId || this.selectedTargetOption.length === 0) {
      this.selectedTargetOption = [this.defaultTargetOptions[0]];
    } else {
      this.selectedTargetOption = this.selectedTargetOption.filter(
        (option) => option.id !== allId
      );
    }

    this.onSingleTargetChanged();
  }

  onAddInsights(): void {
    this.needRefresh = false;
    this.addInsightsService
      .openDialog(this.survey)
      .afterClosed()
      .subscribe(() => {
        if (this.needRefresh) {
          this.prepareDynamicData();
        }
      });
  }

  public scrollToTop(): void {
    this.chartScrollbar?.nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.showScrollToTopBtn = false;
  }

  ngOnDestroy(): void {
    this.removeScrollListener();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getSelectedChartSettings(): ChartSettings[] {
    const selectedChartKeys = Object.keys(this.selectedCharts);
    const shouldSelectAll = selectedChartKeys.length < 1;

    return shouldSelectAll
      ? this.chartSettingsService.getChartSettings(
          this.targetMode,
          this.selectedDataViewMode,
          false
        )
      : selectedChartKeys.reduce(
          (acc, key) => [...acc, this.selectedCharts[key].settings],
          []
        );
  }

  private getGlobalFilters(
    selectedChartSettings: ChartSettings[]
  ): ChartFilter[] {
    const filters: ChartFilter[] = [];

    this.chartSettings.filters.forEach(
      (chartFilter: ChartFilter, index: number) => {
        const filterKeys = Object.keys(chartFilter);
        const allFilters = selectedChartSettings.map(
          (settings) => settings.filters[index]
        );
        const filter = {} as ChartFilter;

        filterKeys.forEach((key: string) => {
          if (key === 'value') {
            // tslint:disable-next-line:no-shadowed-variable
            const numberValues = allFilters.map((filter) =>
              filter[key].join(',')
            );
            const areNumberValuesEqual = numberValues.every(
              (numbs) => numbs === numberValues[0]
            );

            if (areNumberValuesEqual) {
              filter.value = allFilters[0].value;
            } else {
              filter.value = [0, 0];
            }
          } else {
            // tslint:disable-next-line:no-shadowed-variable
            const allValues = allFilters.map((filter) => filter[key]);

            if (allValues.every((value) => value === allValues[0])) {
              filter[key] = allValues[0];
            } else {
              switch (key) {
                case 'dataItem':
                  filter[key] = DataItemType.none;
                  break;
                case 'operator':
                  filter[key] = ChartFilterOperator.none;
                  break;
                case 'target':
                  filter[key] = 'None';
                  break;
              }
            }
          }
        });

        filters.push(filter);
      }
    );

    return filters;
  }

  private async prepareViewData(): Promise<void> {
    try {
      this.isInitialising = true;

      await this.prepareChartData();

      this.initialiseChartList();

      this.isInitialising = false;
      setTimeout(() => {
        this.updateChartContainerHeight();
      }, 0);
    } catch (error) {
      // let's log out the error for easier debugging
      console.log(`error`, error);
    }
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.crossTabData$
      .pipe(takeUntil(this.unsubscribe), first())
      .subscribe((data: CrossTabTableData[]) => {
        this.defaultCrossTabData = data;
      });
  }

  private listenToDocumentStateChanges(): void {
    this.documentService.surveyCodeMap$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((map: SurveyCodeMap) => {
        this.surveyCodeMap = map;
      });
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe), first())
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.needRefresh = true;
        this.shouldIncludeTotalsRow = rows.length < 1;
        this.shouldIncludeTotalsColumn = columns.length < 1;
        this.defaultTargets = !this.shouldIncludeTotalsColumn
          ? columns
          : [this.chartsService.createTotalsTarget(true)];
        const targetOptions: TargetOption[] = this.defaultTargets.map(
          (target) => ({
            id: target.id,
            title: this.targetTitlePipe.transform(
              target,
              target.activeTitleMode,
              target.titleLevels
            ),
          })
        );
        this.defaultTargetOptions.push(...targetOptions);
        this.defaultTargetOptions = uniqBy(this.defaultTargetOptions, 'id');
        this.selectedTargetOption = [this.defaultTargetOptions[0]];

        this.setupTargetGroups();
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private listenToSearchHitChanges(): void {
    this.findAndReplaceService.dialogState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: boolean) => {
        setTimeout(() => {
          this.findAndReplaceDialogState = state;
        }, 0);
      });

    this.findAndReplaceService.searchHits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((hits: SearchHit[]) => {
        this.searchHits = hits;

        if (this.searchHits?.length > 0) {
          this.addSearchHighlightsToCharts();
        } else {
          this.resetSearchHighlights(false);
        }
      });
  }

  private resetSearchHighlights(update: boolean = true) {
    this.searchHits = [];
    this.highlightSearchChartIndices = [];
    this.focusedHighlightSearchChartIndex = -1;
    if (update) {
      this.findAndReplaceService.updateSearchHits([]);
    }
  }

  private setupTargetGroups(): void {
    this.defaultTargetGroups = groupBy(this.defaultTargets, (target) =>
      this.targetTitlePipe.transform(target, DisplayType.group)
    );
    this.defaultTargetGroupNames = Object.keys(this.defaultTargetGroups);
    this.selectedSingleTargetGroupTarget = this.defaultTargetGroupNames.reduce(
      (acc, groupName) => ({
        ...acc,
        [groupName]:
          !this.selectedSingleTargetGroupTarget ||
          isEmpty(this.selectedSingleTargetGroupTarget) ||
          this.selectedSingleTargetGroupTarget[groupName] === 'none'
            ? 'none'
            : this.defaultTargetGroups[groupName].find(
                (target: Target) =>
                  target.id ===
                  (this.selectedSingleTargetGroupTarget[groupName] as Target)
                    ?.id
              ),
      }),
      {}
    );

    this.selectedCombinedTargetsGroupTarget =
      this.defaultTargetGroupNames.reduce(
        (acc, groupName) => ({
          ...acc,
          [groupName]:
            typeof this.selectedCombinedTargetsGroupTarget === 'undefined' ||
            Object.keys(this.selectedCombinedTargetsGroupTarget).length === 0 ||
            typeof this.selectedCombinedTargetsGroupTarget[groupName] ===
              'undefined' ||
            this.selectedCombinedTargetsGroupTarget[groupName]?.length === 0
              ? []
              : this.selectedCombinedTargetsGroupTarget[groupName],
        }),
        {}
      );
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.chartDataItems$
      .pipe(takeUntil(this.unsubscribe), first())
      .subscribe((value: DataItem[]) => {
        this.dataItemKeys = value.reduce(
          (acc, item: DataItem) => ({
            ...acc,
            [item.id]: item,
          }),
          {}
        );
      });
  }

  private listenToCustomAudiencesChanges(): void {
    this.customAudiencesDataReadySub = this.customAudiencesDataReady$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((ready: boolean) => {
        if (ready) {
          this.customAudiencesDataReadySub?.unsubscribe();
          this.prepareDynamicData();
        }
      });
  }

  private listenToDynamicDataChanges(): void {
    this.dynamicDataUpdated$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((updated: boolean) => {
        if (this.selectedDataViewMode === DataViewMode.dynamic && updated) {
          this.prepareViewData();
          this.updateChartSearchInput();
          this.documentService.resetRestoreState();
        }
      });
  }

  private initChartSettings(): void {
    this.chartSettings = cloneDeep(
      this.chartSettingsService.getGlobalChartSettings(
        this.surveys,
        this.dataItemKeys,
        this.targetMode,
        this.getActiveChartTargets(),
        this.isDynamicDataMode() ? DataViewMode.dynamic : DataViewMode.default
      )
    );

    const primaryDataItemId = this.chartSettings.primaryDataItem;
    const secondaryDataItemId = this.chartSettings.secondaryDataItem;

    if (!this.dataItemKeys[primaryDataItemId]) {
      const firstAvailableDataItemId: DataItemId = parseInt(
        Object.keys(this.dataItemKeys)[0],
        10
      );
      this.chartSettings.primaryDataItem = firstAvailableDataItemId;
      this.chartSettings.extraTableSettings = [firstAvailableDataItemId];
    }

    if (!this.dataItemKeys[secondaryDataItemId]) {
      this.chartSettings.secondaryDataItem = 0;
    }
  }

  private prepareTrendingChartData(): void {
    const hasMultipleSurveys = this.surveys?.length > 1;
    if (hasMultipleSurveys) {
      const { insightsGroupedData, surveysGroupedData } =
        this.chartsService.getTrendingSurveyTargetChartList(
          this.defaultCrossTabData,
          this.defaultTargets,
          this.surveyCodeMap,
          this.shouldIncludeTotalsRow
        );
      this.insightsGroupChartList = insightsGroupedData;
      this.surveysGroupChartList = surveysGroupedData;
    }
  }

  private async prepareChartData(): Promise<void> {
    let crosstabData = [];
    let targets = [];

    if (this.isDynamicDataMode()) {
      crosstabData = this.dynamicCrossTabData;
      targets = this.dynamicTargets;
    } else {
      crosstabData = this.defaultCrossTabData;
      targets = this.defaultTargets;
    }

    this.targetColors = this.chartSettingsService.getChartSeriesColours(
      targets,
      this.targetMode,
      this.isDynamicDataMode() ? DataViewMode.dynamic : DataViewMode.default
    );

    this.allCombinedChartList =
      this.chartsService.getCombinedTargetsSingleSurveyChartList(
        crosstabData,
        targets,
        this.surveyCodeMap,
        this.shouldIncludeTotalsRow
      );

    this.initChartSettings();

    if (this.targetMode === ChartTargetMode.combined) {
      this.prepareCombinedChartList();
    } else {
      this.allSingleChartList = this.chartsService.getSingleChartList(
        this.allCombinedChartList,
        targets
      );

      this.allSingleChartList.forEach((chart, index) => {
        const localChartSettings = this.documentService.findChartSettings(
          this.targetMode,
          `${chart.target.id}_${CHART_SETTING_VERSION}`,
          `${chart.title}_${CHART_SETTING_VERSION}`,
          this.selectedDataViewMode
        );
        this.singleChartFullWidthStatus[`${chart.target.id}_${chart.title}`] =
          localChartSettings
            ? localChartSettings.showDataTable
            : this.chartSettings.showDataTable;
      });
      this.prepareSingleChartList();
    }
  }

  private isAllSelected(targetOption: TargetOption[]): boolean {
    return (
      targetOption.length === 1 &&
      targetOption[0].id === this.defaultTargetOptions[0].id
    );
  }

  private prepareSingleChartList(): void {
    this.selectedSurveysInSingleTarget =
      this.selectedSurveysInSingleTarget ??
      this.surveys.map((survey: Survey) => this.surveyCodeMap[survey.code]);

    const targetOption =
      this.selectedDataViewMode === DataViewMode.default
        ? this.selectedTargetOption
        : this.selectedSingleTargetCustomAudiencesOption;
    if (this.selectedDataViewMode === DataViewMode.default) {
      this.singleChartList = this.isAllSelected(targetOption as TargetOption[])
        ? this.allSingleChartList
        : this.allSingleChartList.filter((chart) => {
            const selectedTargetIds = Array.isArray(targetOption)
              ? targetOption.map((target) => target.id)
              : [(targetOption as Target).id];
            return selectedTargetIds.includes(chart.target.id);
          });
    } else {
      this.singleChartList = this.allSingleChartList;
    }

    this.singleChartList = this.singleChartList.filter((chart) =>
      this.selectedSurveysInSingleTarget.includes(chart.surveyCode[0])
    );

    if (this.searchHits?.length > 0) {
      this.addSearchHighlightsToCharts();
    }
  }

  private addSearchHighlightsToCharts() {
    let focusedHighlightSearchChartIndex = -1;
    const hitsByChartIndex = this.searchHits.reduce((acc, value) => {
      const chartIndex = (value.details as ChartSearchHit).chartIndex;
      if ((value.details as ChartSearchHit).focus) {
        focusedHighlightSearchChartIndex = chartIndex;
      }

      if (!acc.includes(chartIndex)) {
        acc.push(chartIndex);
      }
      return acc;
    }, []);
    this.highlightSearchChartIndices = hitsByChartIndex;
    if (focusedHighlightSearchChartIndex > -1) {
      this.initialiseChartList(focusedHighlightSearchChartIndex + 1);
      this.focusedHighlightSearchChartIndex = focusedHighlightSearchChartIndex;
      setTimeout((_) => {
        this.scrollToGraph(focusedHighlightSearchChartIndex);
      }, 0);
    }
  }

  private prepareCombinedChartList(): void {
    this.selectedSurveysInCombinedTargets =
      this.selectedSurveysInCombinedTargets ??
      this.surveys.map((survey: Survey) => this.surveyCodeMap[survey.code]);
    this.combinedChartList = this.allCombinedChartList.filter((chart) =>
      this.selectedSurveysInCombinedTargets.includes(chart.surveyCodes[0])
    );

    if (this.searchHits?.length > 0) {
      this.addSearchHighlightsToCharts();
    }
  }

  private initialiseChartList(chartToLoadNumber: number = 0): void {
    const chartListData = this.getChartListData();
    this.chartNumber = chartListData.length;

    const loadedChartCount =
      chartToLoadNumber > INITIAL_CHART_COUNT
        ? chartToLoadNumber + 1
        : this.chartNumber < INITIAL_CHART_COUNT + 1
        ? this.chartNumber
        : INITIAL_CHART_COUNT;
    this.unloadedChartList = [...chartListData.slice(loadedChartCount)];
    this.chartList = chartListData.slice(0, loadedChartCount);

    setTimeout(() => {
      this.chartExportEnabled.emit(this.chartNumber > 0);
    }, 0);

    setTimeout(() => {
      this.updateShowScrollToTopBtnState();
    }, 1000);
  }

  private getChartListData(): any {
    let chartList;
    switch (this.targetMode) {
      case ChartTargetMode.single:
        chartList = this.singleChartList;
        break;
      case ChartTargetMode.combined:
        chartList = this.combinedChartList;
        break;
      case ChartTargetMode.surveysGroup:
        chartList = this.surveysGroupChartList;
        break;
      case ChartTargetMode.insightsGroup:
        chartList = this.insightsGroupChartList;
        break;
    }

    return chartList;
  }

  private canLoadMoreCharts(): boolean {
    return this.chartList.length < this.chartNumber;
  }

  private updateChartList() {
    const difference = this.chartNumber - this.chartList.length;
    const add =
      difference % 2 === 0 ? (difference > 2 ? 4 : 2) : difference >= 3 ? 3 : 1;
    this.chartList.push(...this.unloadedChartList.slice(0, add));
    this.unloadedChartList = [...this.unloadedChartList.slice(add)];
  }

  private updateChartContainerHeight(): void {
    if (
      !this.viewContainer ||
      !this.chartSettingsRow ||
      !this.additionalChartSettingsRow
    ) {
      return;
    }
    const additionalChartSettingsRowHeight =
      this.additionalChartSettingsRow.nativeElement.offsetHeight || 0;
    this.isAdditionalChartSettingsRowExpanded =
      additionalChartSettingsRowHeight > 47; // single row height
    const newHeight =
      this.viewContainer.nativeElement.offsetHeight -
      this.chartSettingsRow.nativeElement.offsetHeight -
      additionalChartSettingsRowHeight -
      40;
    if (this.chartContainerHeight !== newHeight) {
      this.chartContainerHeight = newHeight;
    }
  }

  private getSlideProps(): SurveyTimeProps[] {
    return this.charts.map((chart: ChartComponent) => {
      const chartNote = this.documentService.findNoteByInsightTarget(
        chart.associatedInsight,
        chart.associatedTarget
      )[0];
      return getSurveyTimeProps(
        chart,
        chart.activeSurveys,
        this.surveyCodeMap,
        chartNote
      );
    });
  }

  private async exportCharts(
    type: ExportFileType,
    props: SurveyTimeProps[],
    docName?: string
  ): Promise<void> {
    if (props.length > MAX_EXPORT_CHARTS_NUMBER) {
      this.messageService.openMessageDialog(
        `Sorry! There are ${props.length} charts. You can only export ${MAX_EXPORT_CHARTS_NUMBER} charts at a time. Please update your charts and try again.`,
        APPLICATION_NAME
      );
      return;
    }
    const documentName = docName || this.documentService.document.metadata.name;
    const initialisedBuilder =
      type === ExportFileType.pptx || type === ExportFileType.googleSlides
        ? await new SurveyTimePptxBuilder(this.messageService).init(
            docName,
            props
          )
        : await new SurveyTimeXlsxChartsBuilder().init(props, type);
    switch (type) {
      case ExportFileType.pptx:
        await this.pptxService.saveAs(
          initialisedBuilder as SurveyTimePptxBuilder,
          documentName
        );
        break;
      case ExportFileType.googleSlides:
        await this.pptxService.exportToSlides(
          initialisedBuilder as SurveyTimePptxBuilder,
          documentName
        );
        break;
      case ExportFileType.xlsx:
        await this.xlsxChartsService.saveAs(
          initialisedBuilder as SurveyTimeXlsxChartsBuilder,
          documentName
        );
        break;
      case ExportFileType.googleSheets:
        await this.xlsxChartsService.exportToSheets(
          initialisedBuilder as SurveyTimeXlsxChartsBuilder,
          documentName
        );
        break;
    }
  }

  private async prefetchCustomAudienceData(): Promise<void> {
    try {
      this.isLoadingDynamicData = true;

      const customAudiences: Target[] = await this.chartsService
        .getDynamicAudienceTargets(this.survey)
        .toPromise();

      this.customAudienceTargets = customAudiences;
      this.selectedSingleTargetCustomAudiencesOption = customAudiences.length
        ? this.customAudienceTargets[0]
        : this.selectedSingleTargetCustomAudiencesOption;
      this.selectedCombinedTargetsCustomAudiencesOption = customAudiences.length
        ? [this.customAudienceTargets[0]]
        : this.selectedCombinedTargetsCustomAudiencesOption;
      this.customAudiencesLoaded = true;
      this.isLoadingDynamicData = false;
      this.customAudiencesDataReady.next(true);
    } catch (error) {
      console.log(`error`, error);
      this.messageService.showSnackBar(
        `Custom audiences error: ${error}`,
        'OK',
        10000
      );
      this.isLoadingDynamicData = false;
    }
  }

  private prepareDynamicData(): void {
    const formattedTargets = this.formatDynamicTargets();
    if (!this.isQuickReport && formattedTargets.length < 1) {
      this.setDynamicChartData([], []);
      return;
    }

    this.isLoadingDynamicData = true;
    this.documentService
      .fetchDynamicCrosstabData(
        formattedTargets,
        undefined,
        undefined,
        this.currentTablebaseTarget
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (crossTabData: CrossTabTableData[]) => {
          this.setDynamicChartData(formattedTargets, crossTabData);
        },
        (error) => {
          this.setDynamicChartData([], []);
          console.log(`error`, error);
          this.messageService.showSnackBar(error, 'OK', 10000);
        }
      );
  }

  private formatDynamicTargets(): Target[] {
    if (this.targetMode === this.chartTargetModeType.single) {
      const selectedTargets = Object.keys(this.selectedSingleTargetGroupTarget)
        .map((key: string) => this.selectedSingleTargetGroupTarget[key])
        .filter(
          (selectedGroupTarget: Target | 'none') =>
            selectedGroupTarget !== 'none'
        );

      let firstDynamicTarget;
      if (this.selectedSingleTargetCustomAudiencesOption !== 'none') {
        firstDynamicTarget = cloneDeep(
          this.selectedSingleTargetCustomAudiencesOption as Target
        );
      } else {
        if (selectedTargets.length < 1) {
          return [];
        }
        firstDynamicTarget = cloneDeep(selectedTargets[0]);
        selectedTargets.shift();
      }
      firstDynamicTarget.activeTitleMode =
        this.documentService.getActiveTitleMode();

      selectedTargets.forEach((selectedTarget: Target) => {
        this.targetService.addChildTarget(
          firstDynamicTarget,
          selectedTarget,
          Operator.and
        );
      });
      return [firstDynamicTarget];
    } else {
      let dynamicTargets = [];
      if (this.selectedCombinedTargetsCustomAudiencesOption.length) {
        dynamicTargets = cloneDeep(
          this.selectedCombinedTargetsCustomAudiencesOption as Target[]
        );
        const activeTitleMode = this.documentService.getActiveTitleMode();

        dynamicTargets.forEach((target: Target) => {
          target.activeTitleMode = activeTitleMode;
        });
      }
      Object.keys(this.selectedCombinedTargetsGroupTarget).forEach((key) => {
        this.selectedCombinedTargetsGroupTarget[key].forEach((target) =>
          dynamicTargets.push(target)
        );
      });
      return dynamicTargets;
    }
  }

  private setDynamicChartData(
    targets: Target[],
    crossTabTableData: CrossTabTableData[]
  ): void {
    this.dynamicTargets = cloneDeep(targets);
    this.dynamicCrossTabData = cloneDeep(crossTabTableData);
    this.isLoadingDynamicData = false;
    this.dynamicDataUpdated.next(true);
  }

  private getActiveChartTargets(): Target[] {
    return this.isDynamicDataMode() ? this.dynamicTargets : this.defaultTargets;
  }

  private isDynamicDataMode(): boolean {
    return (
      (this.targetMode === ChartTargetMode.single &&
        this.selectedDataViewMode === DataViewMode.dynamic) ||
      (this.targetMode === ChartTargetMode.combined &&
        this.selectedDataViewMode === DataViewMode.dynamic)
    );
  }

  private listenToExportXlsxChanges() {
    this.xlsxChartsService.isExporting$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((shouldShowSpinner) => (this.isExporting = shouldShowSpinner));
  }

  private listenToExportPptChanges() {
    this.pptxService.isExporting$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((shouldShowSpinner) => (this.isExporting = shouldShowSpinner));
  }

  public scrollToGraph(id) {
    if (document.getElementById(id)) {
      this.cdr.detectChanges();
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }
  }

  private updateChartSearchInput() {
    this.resetSearchHighlights();

    const chartList = this.getChartListData();
    this.findAndReplaceService.updateChartInput({
      targetMode: this.targetMode,
      dataViewModeType: this.selectedDataViewMode,
      chartListData: chartList,
    });
  }

  private unsetSelectedCharts(): void {
    this.selectedCharts = {};
    this.numbOfSelectedCharts = 0;
  }

  private addScrollListener() {
    this.showScrollToTopBtn = false;
    const container: HTMLElement = this.chartScrollbar?.nativeElement;
    if (!container) {
      return;
    }
    container.addEventListener('scroll', (event) => {
      this.showScrollToTopBtn = container.scrollTop > this.scrollOffset;
    });
  }

  private updateShowScrollToTopBtnState(): void {
    const container: HTMLElement = this.chartScrollbar?.nativeElement;
    if (!container) {
      return;
    }
    this.showScrollToTopBtn = container.scrollTop > this.scrollOffset;
  }

  private removeScrollListener(): void {
    this.chartScrollbar?.nativeElement.removeEventListener('scroll', () => {});
  }
}
