import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import ExportingLocal from 'highcharts/modules/offline-exporting';
import Fullscreen from 'highcharts/modules/full-screen';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  Empty,
  TupDocument,
  TupDocumentService,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { cloneDeep, isEqual, unionBy } from 'lodash';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import {
  ChartViewMode,
  CleaningMethod,
  CleaningMethodOptionsItem,
  CLUSTER_CHART_VIEW_MODES,
  CLUSTER_VERSION,
  ClusterData,
  ClusterFeatureItem,
  CLUSTERS_SOLUTION_TYPE_LIST,
  ClusterSettings,
  ClusterSolution,
  ClusterSolutionCluster,
  ClusterTableRowData,
  ClusterVariableRowData,
  ClusterViewType,
  DEFAULT_CLEAN_DROP_RESPONDENTS_VALID_VALUES_THRESHOLD,
  DEFAULT_SURVEY_COPYRIGHT,
  DisplayType,
  DocumentDataState,
  DocumentViewType,
  EligibleClusterQualityMetrics,
  ExportParams,
  GetClustersResponseBody,
  GetResearchStatusResponseBody,
  HIGHLIGHT_COLORS,
  HighlightType,
  NumberOfClusters,
  Operator,
  RECOMMENDED_SOLUTION_BAR_CHART_COLOR,
  ResearchStatus,
  SaveClusteringResponseBody,
  SelectMenuOption,
  StartClusteringResponseBody,
  Survey,
  SurveyCodeMap,
  SurveyTimeDocument,
  SurveyTimeDocumentClusterApp,
  Target,
  TITLE_MODES,
  ViewType,
} from 'src/app/models';
import {
  ClusterService,
  DialogService,
  DocumentService,
  PptxService,
  TargetService,
  TitleLevelsService,
  TitleModeService,
} from 'src/app/services';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { TargetTitlePipe } from 'src/app/pipes';
import { ClusterTableComponent } from 'src/app/components/cluster-table/cluster-table.component';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesType,
  VisualCodingTarget,
} from '@telmar-global/tup-audience-groups';
import { SaveClusterCodesDialogResult } from 'src/app/dialogs/save-cluster-codes-dialog/save-cluster-codes-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { ClusterApiService } from 'src/app/services/cluster-api.service';
import { SurveyTimePptxBuilder } from 'src/app/builders/surveytime-pptx.builder';
import html2canvas from 'html2canvas';
import { TitleLevelsDialogResult } from '../../dialogs';

More(Highcharts);
Exporting(Highcharts);
Fullscreen(Highcharts);
ExportingLocal(Highcharts);

@Component({
  selector: 'app-cluster-dashboard',
  templateUrl: './cluster-dashboard.component.html',
  styleUrls: ['./cluster-dashboard.component.scss'],
})
export class ClusterDashboardComponent implements OnInit, OnDestroy {
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: any;
  public recommendedChartOptions: any;
  public gettingSolutions = false;

  private unsubscribe: Subject<void> = new Subject<void>();
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;
  public readonly elligibleMetrics = [
    ...new Set(Object.keys(EligibleClusterQualityMetrics)),
  ];
  public readonly chartViewMode = ChartViewMode;
  private researchActiveTable: Target;
  private activeTable: Target;
  public isReadonly = true;
  public researchId: string;
  public status: string;
  public statusValue: number;
  public progressMessage: string;
  public inProgress = true;
  public statusMessage: any;
  public currentDoc: TupDocument<SurveyTimeDocument>;
  public readonly researchStatus: typeof ResearchStatus = ResearchStatus;
  public clustersSolutionTypeList = CLUSTERS_SOLUTION_TYPE_LIST;
  public selectedClustersSolutionNumber: NumberOfClusters;
  public recommendedClusterSolutionNumber: NumberOfClusters;
  public clusterData: GetClustersResponseBody;
  public selectedViewResults: ClusterViewType = 'chart';
  public tableData: ClusterTableRowData[] = [];
  public surveys: Survey[] = [];
  private previouslySelectedSurvey: Survey;
  public selectedClusterSurvey: Survey;
  public chartViewModes: SelectMenuOption<ChartViewMode>[] =
    CLUSTER_CHART_VIEW_MODES;
  public showRecommendedChart = ChartViewMode.default;
  private activeSurvey: Survey;

  public settings: ClusterSettings;
  private preferences: CleaningMethodOptionsItem = {
    cleanDropRespondentsValidValuesThreshold:
      DEFAULT_CLEAN_DROP_RESPONDENTS_VALID_VALUES_THRESHOLD,
  };
  public readonly highlightColors = HIGHLIGHT_COLORS;
  public readonly highlightColorTypes = {
    [HighlightType.table]: 'table',
    [HighlightType.variable]: 'variable',
    [HighlightType.cluster]: 'cluster',
  };

  @ViewChild('reClusterConfirmation') reClusterConfirmation: TemplateRef<any>;
  @ViewChild('researchIdNotFound') researchIdNotFoundContent: TemplateRef<any>;
  private container: UserContainer;
  private chartRef: Highcharts.Chart;

  @ViewChild(ClusterTableComponent, { static: false })
  clusterTableComponent: ClusterTableComponent;

  public clusterTitles: string[];

  public readonly titleModes = TITLE_MODES;
  public activeTitleMode: DisplayType;
  private titleLevels: number[];

  public chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  constructor(
    private router: Router,
    private clusterService: ClusterService,
    private clusterApiService: ClusterApiService,
    private targetService: TargetService,
    private tupDocumentService: TupDocumentService,
    private userContainerService: TupUserContainerService,
    private userMessageService: TupUserMessageService,
    private activatedRoute: ActivatedRoute,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private targetTitlePipe: TargetTitlePipe,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private authService: TupAuthService,
    private pptxService: PptxService
  ) {
    this.isReadonly =
      !!this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.clusterTitles = [];
    this.listenToTitleModeAndLevelsChanges();
    this.listenToDocumentDataChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public clickTitleMode(displayType: DisplayType): void {
    if (displayType === DisplayType.levels) {
      this.openTitleLevelsDialog();
    }
  }

  public selectTitleMode(): void {
    if (this.activeTitleMode !== DisplayType.levels) {
      this.titleLevels = [];
      this.updateDataTitles();
    }
  }

  public onSelectedViewResultsChange(): void {
    this.updateViewResults();
  }

  public onSelectedSolutionChange(): void {
    this.updateViewResults();
  }

  public openSettings(): void {
    this.dialogService
      .clusterSettings(this.selectedViewResults, this.settings)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: ClusterSettings) => {
        if (!isEqual(this.settings, result)) {
          this.settings = result;
          this.saveSettingsToDoc(this.settings);
          this.updateViewResults();
        }
      });
  }

  public clearHighlightSettings(): void {
    this.settings.showHighlighting = false;
    this.saveSettingsToDoc(this.settings);
    this.updateViewResults();
  }

  public openSaveClusterCodesDialog(): void {
    this.dialogService
      .saveClusterCodes({
        clustersSolutionTypeList: this.clustersSolutionTypeList,
        selectedClustersSolutionNumber: this.selectedClustersSolutionNumber,
        title: this.settings.title,
        survey: this.selectedClusterSurvey,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((dialogResult: SaveClusterCodesDialogResult) => {
        this.gettingSolutions = true;

        const selectedSolution = dialogResult.selectedSolution;
        const solutionName = dialogResult.name;
        const containerName = dialogResult.container.name;
        this.clusterService
          .saveClustering(this.researchId, selectedSolution)
          .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
          .subscribe((result: SaveClusteringResponseBody) => {
            const solutionItems: DocumentAudienceGroupItem[] =
              this.formatSolutions(result['cluster-keys'], selectedSolution);
            const docsToCreate: TupDocument<DocumentAudienceGroup>[] = [];
            docsToCreate.push(
              this.getSolutionVariableDocument(
                solutionName,
                selectedSolution,
                solutionItems
              )
            );

            if (dialogResult.saveVariables) {
              const variableDoc = this.getSolutionVariableDocument(
                solutionName,
                selectedSolution
              );
              docsToCreate.push(variableDoc);
            }

            this.saveToDocumentAudienceGroup(docsToCreate, containerName);
          });
      });
  }

  private formatSolutions(
    keys: string[],
    selectedSolution: number
  ): DocumentAudienceGroupItem[] {
    return keys.map((key: string, index: number) => {
      const target: VisualCodingTarget = {
        activeTitleMode: this.activeTitleMode,
        ownTitle: `${selectedSolution} clusters solution - ${this.getClusterTitle(
          index
        )}`,
        id: uuidv4(),
        fileVersion: 1,
        title: `${selectedSolution} clusters solution - ${this.getClusterTitle(
          index
        )}`,
        coding: key,
        operator: Operator.and,
        created: new Date().getTime(),
        targets: [],
      };
      return {
        title: `${selectedSolution} clusters solution - ${this.getClusterTitle(
          index
        )}`,
        coding: key,
        options: {
          statement: null,
          target: {
            activeTitleMode: this.activeTitleMode,
            ownTitle: `${selectedSolution} clusters solution - ${this.getClusterTitle(
              index
            )}`,
            id: uuidv4(),
            fileVersion: 1,
            title: `${selectedSolution} clusters solution - ${this.getClusterTitle(
              index
            )}`,
            coding: key,
            operator: Operator.and,
            created: new Date().getTime(),
            targets: [target],
          },
        },
      };
    });
  }

  private getSolutionVariableDocument(
    fileName: string,
    selectedSolution: number,
    solutionItems?: DocumentAudienceGroupItem[]
  ): TupDocument<DocumentAudienceGroup> {
    const items =
      solutionItems ||
      (this.currentDoc.content.apps.clustering as SurveyTimeDocumentClusterApp)
        .features;
    const title = solutionItems
      ? `${fileName} -- ${selectedSolution} clusters`
      : `${fileName} -- ${selectedSolution} variables`;
    return this.documentService.createOwnCodesDocumentObject(title, {
      hasVehicles: false,
      survey: this.selectedClusterSurvey,
      type: SaveOwnCodesType.audience,
      targets: items,
      vehicles: null,
    });
  }

  private saveToDocumentAudienceGroup(
    docsToCreate: TupDocument<DocumentAudienceGroup>[],
    containerName: string
  ): void {
    const observables: Observable<HttpResponse<Empty>>[] = [];
    docsToCreate.forEach((doc) => {
      observables.push(
        this.tupDocumentService
          .create(containerName, doc)
          .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      );
    });

    combineLatest(observables).subscribe((result) => {
      let info = `Cluster solution has been saved`;
      if (result.length > 1) {
        info = `Cluster solution and variables have been saved`;
      }
      this.userMessageService.showSnackBar(
        `${info} into ${this.selectedClusterSurvey.title}`,
        'OK'
      );
      this.gettingSolutions = false;
    });
  }

  public openFullScreen(): void {
    this.chartRef.fullscreen.toggle();
  }

  public openVariableDialog(): void {
    const variableTotal = this.clusterData.totals.variables;
    const featureItems = (
      this.currentDoc.content.apps.clustering as SurveyTimeDocumentClusterApp
    ).features;
    const variableData: ClusterVariableRowData[] =
      this.clusterService.formatVariableImportanceData(
        featureItems,
        variableTotal
      );
    this.dialogService
      .changeVariables(variableData)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: ClusterVariableRowData[]) => {
        this.reClustering(result);
      });
  }

  public onReCluster(): void {
    this.userMessageService
      .openCustomMessageDialog(
        this.reClusterConfirmation,
        'Re-cluster confirmation',
        {
          confirmText: 'Re-cluster',
          centered: true,
          width: '400px',
        }
      )
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          const rows: ClusterFeatureItem[] = this.currentDoc.content.rows.map(
            (row) => ({
              ...this.convertTargetToAudienceGroupItem(row),
              selected: true,
            })
          );
          this.reClustering(rows);
        }
      });
  }

  public openPreferences(): void {
    this.dialogService
      .clusterPreferences(this.preferences)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((preferences: CleaningMethodOptionsItem) => {
        if (
          this.preferences.cleanDropRespondentsValidValuesThreshold !==
          preferences.cleanDropRespondentsValidValuesThreshold
        ) {
          this.preferences = preferences;
          this.updateClustering(this.researchId);
        }
      });
  }

  public onSelectedSurveyChanged(): void {
    this.userMessageService
      .openDialog(
        'Changing the survey will trigger a re-cluster which will reset the current clusters, settings and selected variables and start a new cluster calculation by using current rows in the crosstab.',
        'Change survey',
        {
          cancelText: 'Cancel',
          confirmText: 'OK',
        }
      )
      .afterClosed()
      .subscribe((result: boolean | undefined) => {
        if (!result) {
          this.prepareSurveyData(this.previouslySelectedSurvey);
          return;
        }

        const rows: ClusterFeatureItem[] = (
          this.currentDoc.content.apps
            ?.clustering as SurveyTimeDocumentClusterApp
        )?.features;
        this.reClustering(rows, true);
      });
  }

  private listenToDocumentDataChanges(): void {
    combineLatest([
      this.userContainerService.container.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.selectedSurvey$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.activeTablebase$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
    ]).subscribe(
      ([container, survey, tablebase]: [UserContainer, Survey, Target]) => {
        this.activeSurvey = survey;
        this.currentDoc = this.documentService.document;
        this.surveys = this.documentService.document.content?.surveys;
        this.activeTable = tablebase;
        this.container = container;
        this.isReadonly = !(
          this.authService.user.attributes.email ===
          this.currentDoc?.metadata?.by?.attributes?.email
        );
        this.clusterApiService.setCurrentDoc(this.currentDoc);

        this.inProgress = true;
        this.loadResearch();
      }
    );
  }

  private listenToTitleModeAndLevelsChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.titleLevelsService.updateNumberOfTitleLevelsByTargets([
          ...columns,
          ...rows,
        ]);
      });
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((activeTitleMode: DisplayType) => {
        this.activeTitleMode = activeTitleMode;
      });

    this.titleLevelsService.titleLevels$
      .pipe(
        takeUntil(this.unsubscribe),
        filter((titleLevels: number[]) => !!titleLevels?.length)
      )
      .subscribe((titleLevels: number[]) => {
        this.titleLevels = titleLevels;
      });
  }

  private loadResearch(): void {
    if (this.hasResearchId(this.currentDoc)) {
      const clusteringApp = this.currentDoc.content.apps
        ?.clustering as SurveyTimeDocumentClusterApp;
      const researchId = clusteringApp?.researchId;
      this.researchId = researchId;
      this.researchActiveTable = clusteringApp.tablebase;
      const respondentsThreshold =
        clusteringApp?.cleaningMethodOptions
          ?.cleanDropRespondentsValidValuesThreshold;
      if (respondentsThreshold !== null && respondentsThreshold !== undefined) {
        this.preferences = clusteringApp?.cleaningMethodOptions;
      }
      this.activeTitleMode =
        clusteringApp.activeTitleMode || this.activeTitleMode;
      this.titleLevels = clusteringApp.titleLevels || this.titleLevels;
      this.clusterTitles = clusteringApp.clusterTitles || this.clusterTitles;
      this.prepareSurveyData(clusteringApp.survey);
      this.getClusterData(researchId);
    } else if (!this.isReadonly) {
      this.prepareSurveyData(this.activeSurvey);
      this.createClustering();
    } else {
      this.inProgress = false;
    }
  }

  private prepareSurveyData(survey: Survey): void {
    this.selectedClusterSurvey =
      this.surveys.find(
        (surveyItem: Survey) =>
          surveyItem.code === survey.code &&
          surveyItem.authorizationGroup === survey.authorizationGroup
      ) || survey;
    this.updatePreviouslySelectedSurvey(this.selectedClusterSurvey);
    this.surveys = this.getEligibleSurveys();
  }

  private updatePreviouslySelectedSurvey(survey: Survey): void {
    this.previouslySelectedSurvey = this.selectedClusterSurvey;
  }

  private hasResearchId(doc: TupDocument<SurveyTimeDocument>): boolean {
    const clusteringApp = doc.content.apps
      ?.clustering as SurveyTimeDocumentClusterApp;
    return (
      clusteringApp?.version === CLUSTER_VERSION &&
      clusteringApp?.researchId?.length > 0
    );
  }

  private getClusterData(researchId: string): void {
    this.clusterService
      .getClusterStatus(researchId)
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe(
        (result: GetResearchStatusResponseBody) => {
          this.status = result.status;
          this.statusValue = result.progress;
          this.progressMessage = result.phase;
          this.statusMessage = result.message;

          if (result.status === ResearchStatus.finished) {
            this.clusterService
              .getClusters(researchId)
              .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
              .subscribe((clustersData: GetClustersResponseBody) => {
                this.inProgress = false;
                this.populateClusterData(clustersData);
              });
          }
        },
        (error) => {
          this.inProgress = false;
          if (error === 'research session not found') {
            this.statusMessage = error;
            !this.isReadonly
              ? this.reClusterOnFailureDialog()
              : this.showFailureDialog();
          }
        }
      );
  }

  private populateClusterData(clustersData: GetClustersResponseBody): void {
    this.clusterData = clustersData;
    this.setClustersSolutionOptions(clustersData.solutions);
    this.recommendedClusterSolutionNumber =
      clustersData['recommended-solution'].overall;
    this.setSelectedClustersSolutionNumber(
      this.recommendedClusterSolutionNumber
    );
    this.settings =
      (this.currentDoc.content.apps?.clustering as SurveyTimeDocumentClusterApp)
        ?.settings || this.composeDefaultClusterSettings();
    this.updateViewResults();
  }

  private composeDefaultClusterSettings(): ClusterSettings {
    return {
      title: this.currentDoc.metadata.name,
      dataItem: 'average',
      chartType: 'line',
      showDataLabel: true,
      showAxisLabel: true,
      showLegend: true,
      showHighlighting: false,
      highlightType: HighlightType.table,
    };
  }

  private setClustersSolutionOptions(
    clustersSolutions: ClusterSolution[]
  ): void {
    const clusterOptionsMap = clustersSolutions.reduce(
      (acc, solution) => ({
        ...acc,
        [solution['num-of-clusters']]: solution.quality.average,
      }),
      {}
    );
    this.clustersSolutionTypeList = this.clustersSolutionTypeList
      .filter((type) => type.key in clusterOptionsMap)
      .map((clusterSolutionType) => ({
        key: clusterSolutionType.key,
        value: `${clusterSolutionType.value
          .split(' ')
          .splice(0, 2)
          .join(' ')} (Q: ${clusterOptionsMap[clusterSolutionType.key].toFixed(
          0
        )}%)`,
      }));
  }

  private setSelectedClustersSolutionNumber(solutionNumber: number): void {
    this.selectedClustersSolutionNumber = solutionNumber;
  }

  private updateViewResults(): void {
    if (this.selectedViewResults === 'chart') {
      this.setChartData(this.clusterData.solutions);
    } else {
      this.chartOptions = null;
      this.recommendedChartOptions = null;
      this.setTableData(this.clusterData);
    }
  }

  private setChartData(clustersSolutions: ClusterSolution[]): void {
    const selectedClusterSolution: ClusterSolution =
      this.getSelectedClusterSolution(clustersSolutions);

    const seriesData = selectedClusterSolution.clusters.map(
      (solution: ClusterSolutionCluster, index: number) => {
        return {
          name: this.getClusterTitle(index),
          data: solution.data[this.settings.dataItem].map((value) =>
            parseFloat(value?.toFixed(2))
          ),
        };
      }
    );

    this.showRecommendedChart === ChartViewMode.recommended
      ? this.setRecommendedChartOptions(
          this.getQualityMetricsForRecommendedChartYAxis()
        )
      : this.setChartOptions(seriesData);
  }

  private getClusterSizeForRecommendedChartXAxis() {
    return this.clusterData.solutions.map(
      (cluster) => cluster['num-of-clusters']
    );
  }

  private getQualityMetricsForRecommendedChartYAxis() {
    let qualityMetrics = Object.keys(
      this.clusterData.solutions[0].quality
    ).reduce((qualityMetrics, metric) => {
      qualityMetrics[metric] = [];
      return qualityMetrics;
    }, {});

    qualityMetrics = this.clusterData.solutions.reduce((prev, solution) => {
      Object.keys(solution.quality).forEach((metric) => {
        prev[metric].push(Number(solution.quality[metric].toFixed(2)));
      });
      return prev;
    }, qualityMetrics);

    return [
      ...this.getColumnSeriesForRecommendedClusters(),
      ...this.getElligibleQualityMetrics(qualityMetrics),
    ];
  }

  private getElligibleQualityMetrics(qualityMetrics) {
    return Object.keys(qualityMetrics)
      .filter((metric) => this.elligibleMetrics.includes(metric))
      .map((metric) => ({
        type: 'line',
        name: metric,
        data: qualityMetrics[metric],
      }));
  }

  private getColumnSeriesForRecommendedClusters() {
    const columnSeriesData = this.clusterData.solutions.map((solution) =>
      solution['num-of-clusters'] === this.recommendedClusterSolutionNumber
        ? 100
        : 0
    );
    return [
      {
        type: 'column',
        name: `Recommended Solution : ${this.recommendedClusterSolutionNumber}`,
        data: columnSeriesData,
      },
    ];
  }

  private composePlotOptionsForRecommendedClusterColumnChart() {
    return {
      groupPadding: 0,
      pointPadding: 0,
      color: RECOMMENDED_SOLUTION_BAR_CHART_COLOR,
    };
  }

  private setRecommendedChartOptions(yAxisSeries): void {
    if (this.recommendedChartOptions) {
      while (this.chartRef?.series?.length) {
        this.chartRef.series[0].remove();
      }
      yAxisSeries.forEach((item) => {
        this.chartRef?.addSeries(item);
      });
    }
    this.recommendedChartOptions = {
      chart: {
        type: 'line',
      },
      exporting: {
        enabled: false,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  textOutline: 'none',
                },
              },
            },
          },
        },
      },
      caption: {
        text: this.getCopyrightHTML(),
      },
      credits: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },

      title: {
        text: this.settings.title,
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        enabled: this.settings.showLegend,
      },

      plotOptions: {
        line: {
          dataLabels: {
            enabled: this.settings.showDataLabel,
          },
        },
        column: this.composePlotOptionsForRecommendedClusterColumnChart(),
        series: {
          label: {
            connectorAllowed: true,
          },
        },
      },
      xAxis: {
        categories: this.getClusterSizeForRecommendedChartXAxis(),
        labels: {
          enabled: this.settings.showAxisLabel,
          rotation: 0,
          style: {
            fontWeight: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '200px',
          },
        },
      },

      series: yAxisSeries,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  private setChartOptions(series): void {
    if (this.chartOptions) {
      while (this.chartRef.series?.length) {
        this.chartRef.series[0].remove();
      }
      series.forEach((item) => {
        this.chartRef?.addSeries(item);
      });
    }
    this.chartOptions = {
      chart: {
        ...(this.settings.chartType === 'polar'
          ? { type: 'area', polar: true }
          : { type: this.settings.chartType, polar: false }),
      },
      exporting: {
        enabled: false,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  textOutline: 'none',
                },
              },
            },
          },
        },
      },
      caption: {
        text: this.getCopyrightHTML(),
      },
      credits: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },

      title: {
        text: this.settings.title,
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        enabled: this.settings.showLegend,
      },

      plotOptions: {
        [this.settings.chartType]: {
          dataLabels: {
            enabled: this.settings.showDataLabel,
          },
        },
        series: {
          label: {
            connectorAllowed: true,
          },
        },
      },
      xAxis: {
        categories: this.getCategoriesForXAxis(this.currentDoc),
        labels: {
          enabled: this.settings.showAxisLabel,
          rotation: this.settings.chartType === 'polar' ? 0 : -45,
          style: {
            fontWeight: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '200px',
          },
        },
      },

      series,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  private setTableData(clustersData: GetClustersResponseBody): void {
    const selectedClusterSolution: ClusterSolution =
      this.getSelectedClusterSolution(clustersData.solutions);
    const clusters = selectedClusterSolution.clusters;
    const clusterVariables = selectedClusterSolution.variables;
    const transposedClustersData = this.clusterService.highlightClustersData(
      this.clusterService.transposeClustersData(
        clusters,
        this.settings.dataItem
      ),
      this.settings.showHighlighting,
      this.settings.highlightType
    );

    this.tableData = [
      {
        rowNumber: '',
        variable: `Audience (${this.targetTitlePipe.transform(
          this.researchActiveTable || this.activeTable,
          this.activeTitleMode,
          this.titleLevels
        )})`,
        type: '-',
        rank: '-',
        determination: '-',
        total: parseInt(clustersData.totals.audience.toFixed(0), 10),
        clusters: clusters.map((item) => ({
          value: parseInt(item.audience.toFixed(0), 10),
        })),
        isSticky: true,
      },
      {
        rowNumber: '',
        variable: 'Respondents',
        type: '-',
        rank: '-',
        determination: '-',
        total: parseInt(clustersData.totals.respondents.toFixed(0), 10),
        clusters: clusters.map((item) => ({
          value: parseFloat(item.respondents.toFixed(2)),
        })),
        isSticky: true,
      },
      ...(
        this.currentDoc.content.apps.clustering as SurveyTimeDocumentClusterApp
      ).features
        .filter((feature: ClusterFeatureItem) => feature.selected)
        .map((feature: ClusterFeatureItem, index: number) => ({
          rowNumber: index + 1,
          variable: feature.title,
          type: clustersData.totals.variables.type[index],
          rank: clusterVariables[index].rank,
          determination: parseInt(
            clusterVariables[index].determination.toFixed(0),
            10
          ),
          total: parseFloat(
            clustersData.totals.data[this.settings.dataItem][index].toFixed(2)
          ),
          clusters: transposedClustersData[index],
          isSticky: false,
        })),
    ];

    if (
      (this.currentDoc.content.apps.clustering as SurveyTimeDocumentClusterApp)
        ?.clusterTitles
    ) {
      this.clusterTitles = [
        ...(
          this.currentDoc.content.apps
            .clustering as SurveyTimeDocumentClusterApp
        ).clusterTitles,
      ];
    }
  }

  private getSelectedClusterSolution(
    clustersSolutions: ClusterSolution[]
  ): ClusterSolution {
    return clustersSolutions.filter(
      (solution: ClusterSolution) =>
        solution['num-of-clusters'] === this.selectedClustersSolutionNumber
    )[0];
  }

  private convertTargetToAudienceGroupItem(
    target: Target
  ): DocumentAudienceGroupItem {
    return {
      title: this.targetTitlePipe.transform(
        target,
        this.activeTitleMode,
        this.titleLevels
      ),
      coding: target.coding,
      options: {
        statement: null,
        target: this.targetService.shallowCopyTarget(target),
      },
    };
  }

  private createClustering(): void {
    const rows: ClusterFeatureItem[] = this.currentDoc.content.rows.map(
      (row) => ({
        ...this.convertTargetToAudienceGroupItem(row),
        selected: true,
      })
    );

    if (rows.length === 0) {
      this.inProgress = false;
      return;
    }

    const clusterData: ClusterData = this.prepareClusterData(rows);

    this.clusterService
      .createClustering(clusterData, this.activeTable)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (result: StartClusteringResponseBody) => {
          this.researchId = result['research-session-id'];
          this.researchActiveTable = this.activeTable;
          this.saveResearchIdToDoc(
            this.researchId,
            clusterData,
            true,
            undefined,
            true
          );
          this.getClusterData(this.researchId);
        },
        (error) => {
          this.inProgress = false;
          if (error !== 'crosstab') {
            this.status = ResearchStatus.failure;
          }
        }
      );
  }

  private updateClustering(researchId: string): void {
    this.inProgress = true;

    const clusterData: ClusterData = this.prepareClusterData();

    this.clusterService
      .updateClustering(researchId, CleaningMethod.cleanDrop, this.preferences)
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe(
        (result: StartClusteringResponseBody) => {
          this.researchId = result['research-session-id'];
          this.saveResearchIdToDoc(
            this.researchId,
            clusterData,
            false,
            this.preferences,
            true
          );
          this.getClusterData(this.researchId);
        },
        (error) => {
          this.inProgress = false;
        }
      );
  }

  private reClustering(
    features: ClusterFeatureItem[],
    isSurveyChanged: boolean = false
  ): void {
    this.inProgress = true;
    this.clusterData = null;
    this.chartOptions = null;
    this.recommendedChartOptions = null;
    this.status = null;
    this.progressMessage = null;
    const clusterData: ClusterData = this.prepareClusterData(features);

    this.clusterService
      .createClustering(clusterData, this.activeTable)
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe(
        (result: StartClusteringResponseBody) => {
          this.researchId = result['research-session-id'];
          this.researchActiveTable = this.activeTable;

          this.prepareSurveyData(this.selectedClusterSurvey);
          this.saveResearchIdToDoc(
            this.researchId,
            clusterData,
            true,
            undefined,
            true
          );
          this.getClusterData(this.researchId);
        },
        (error) => {
          this.inProgress = false;
          isSurveyChanged &&
            this.prepareSurveyData(this.previouslySelectedSurvey);
          if (error !== 'crosstab') {
            this.getClusterData(this.researchId);
          }
        }
      );
  }

  private saveResearchIdToDoc(
    researchId: string,
    clusterData: ClusterData,
    shouldUpdateTablebase: boolean,
    preferences?: CleaningMethodOptionsItem,
    shouldUnsetRestoreDocumentState?: boolean
  ): void {
    const docToSave = cloneDeep(this.currentDoc);
    const { rows, survey } = clusterData;

    const clustering = {
      tablebase: shouldUpdateTablebase
        ? this.activeTable
        : (docToSave.content?.apps?.clustering as SurveyTimeDocumentClusterApp)
            ?.tablebase,
      researchId,
      features: rows,
      survey,
      ...(preferences && {
        cleaningMethodOptions: preferences,
      }),
      version: CLUSTER_VERSION,
      activeTitleMode: this.activeTitleMode,
      titleLevels: this.titleLevels,
      settings: this.settings || this.composeDefaultClusterSettings(),
      clusterTitles: this.clusterTitles,
    };

    if (docToSave.content?.apps) {
      const apps = docToSave.content?.apps;
      apps['clustering'] = clustering;
    } else {
      docToSave.content['apps'] = { clustering };
    }

    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(
      docToSave,
      shouldUnsetRestoreDocumentState
    );
  }

  private saveSettingsToDoc(settings: ClusterSettings): void {
    const docToSave = cloneDeep(this.currentDoc);
    (
      docToSave.content.apps.clustering as SurveyTimeDocumentClusterApp
    ).settings = settings;
    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(docToSave);
  }

  private showFailureDialog(): void {
    const confirmText = 'Back to crosstab';
    this.userMessageService
      .openMessageDialog(
        `Research Session does not exist`,
        'Cluster calculation failed',
        {
          confirmText,
        }
      )
      .afterClosed()
      .subscribe((confirmed) => {
        this.navigateToCrosstab();
      });
  }

  private reClusterOnFailureDialog(): void {
    const confirmText = 'Re-Cluster';
    const cancelText = 'Back to Crosstab';
    this.userMessageService
      .openCustomMessageDialog(
        this.researchIdNotFoundContent,
        'Loading cluster failed',
        {
          confirmText,
          cancelText,
          centered: true,
          width: '600px',
        }
      )
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.onReCluster();
        } else {
          this.resetClusterInDoc();
          this.navigateToCrosstab();
        }
      });
  }

  private resetClusterInDoc(): Observable<Empty> {
    const docToSave = cloneDeep(this.currentDoc);
    docToSave.content.apps = {
      clustering: null,
    };
    return this.tupDocumentService.update(this.container.name, docToSave);
  }

  private getCopyrightHTML(): string {
    const surveyProvider = this.selectedClusterSurvey.meta['survey-provider'];
    const surveyCopyright =
      this.selectedClusterSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `<span>Survey provided by: <b>${surveyProvider}.</b></span> <b>${surveyCopyright}</b>`;
  }

  public exportTo(
    type: ExportingMimeTypeValue,
    exportParams?: ExportParams
  ): void {
    if (this.inProgress || !this.clusterData) {
      return;
    }

    this.chartRef.exportChartLocal(
      {
        type,
        sourceWidth: 1280,
        sourceHeight: 720,
        scale: 3,
        ...(exportParams?.docName ? { filename: exportParams.docName } : {}),
      },
      {}
    );
  }

  private getCategoriesForXAxis(
    document: TupDocument<SurveyTimeDocument>
  ): Array<string> {
    return (
      document &&
      (
        document?.content?.apps?.clustering as SurveyTimeDocumentClusterApp
      )?.features.map((feature) => feature.title)
    );
  }

  exportToCsv(exportParams?: ExportParams) {
    this.clusterTableComponent.exportToCsv(exportParams?.docName);
  }

  exportToXlsx(exportParams?: ExportParams) {
    this.clusterTableComponent.exportToXlsx(exportParams?.docName);
  }

  exportToSheets(exportParams?: ExportParams) {
    this.clusterTableComponent.exportToSheets(exportParams?.docName);
  }

  private prepareClusterData(features?: ClusterFeatureItem[]) {
    let rows: ClusterFeatureItem[] = features
      ? features
      : this.currentDoc.content.rows.map((row) => ({
          ...this.convertTargetToAudienceGroupItem(row),
          selected: true,
        }));

    if (rows.length === 0) {
      this.inProgress = false;
      return;
    }

    rows = unionBy(rows, 'coding');

    return {
      rows,
      survey: this.selectedClusterSurvey,
    };
  }

  private navigateToCrosstab() {
    const url = `../data`;
    const option: NavigationExtras = {
      relativeTo: this.activatedRoute,
      state: {
        isReadonly: this.isReadonly,
      },
      queryParams: {
        tab: ViewType.crossTab,
      },
    };

    this.router.navigate([url], option);
  }

  public updateChartView() {
    this.selectedClustersSolutionNumber = this.recommendedClusterSolutionNumber;
    this.updateViewResults();
  }

  private getEligibleSurveys() {
    return [
      ...this.surveys?.filter(
        (survey) => survey.code !== this.selectedClusterSurvey?.code
      ),
      this.selectedClusterSurvey,
    ];
  }

  exportToPptx(exportParams?: ExportParams) {
    const chartContainer = document.getElementsByClassName(
      'highcharts-container'
    )[0] as HTMLElement;
    html2canvas(chartContainer).then((canvas) => {
      const imageBlob = canvas.toDataURL('image/png');
      const documentName = exportParams?.docName || this.settings.title;
      const builder = new SurveyTimePptxBuilder(this.userMessageService);
      builder.exportStatsAppToPptx(
        null,
        [imageBlob],
        documentName,
        'Cluster',
        this.getSurveyCopyrightText()
      );
      this.pptxService.saveAs(builder, documentName);
    });
  }

  private getSurveyCopyrightText(): string {
    let surveyCodeMap;
    this.documentService.surveyCodeMap$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((map: SurveyCodeMap) => {
        surveyCodeMap = map;
      });
    const { code, title } = this.documentService.activeSurvey;
    const surveyTitle = [surveyCodeMap[code], title].join(' - ');
    const surveyCopyright =
      this.documentService.activeSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `${surveyTitle} - ${surveyCopyright}`;
  }

  private openTitleLevelsDialog(): void {
    this.titleLevelsService
      .openRawDialog({ titleLevels: this.titleLevels })
      .subscribe((dialogResult: TitleLevelsDialogResult) => {
        this.titleLevels = dialogResult?.titleLevels || [];
        this.updateDataTitles();
      });
  }

  private updateDataTitles(): void {
    const docToSave = cloneDeep(this.currentDoc);
    const clusterApp = docToSave.content.apps
      .clustering as SurveyTimeDocumentClusterApp;
    clusterApp.features = clusterApp.features.map((feature) => ({
      ...feature,
      ...this.convertTargetToAudienceGroupItem(feature.options.target),
    }));
    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(docToSave);
    this.updateViewResults();
  }

  public onClusterTitleChanged($event: any): void {
    this.clusterTitles = $event as string[];

    const docToSave = cloneDeep(this.currentDoc);
    const clusterApp = docToSave.content.apps
      .clustering as SurveyTimeDocumentClusterApp;
    clusterApp.clusterTitles = this.clusterTitles;
    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(docToSave);
    this.updateViewResults();
  }

  private getClusterTitle(index: number) {
    if (this.clusterTitles && this.clusterTitles.length - 1 >= index) {
      return this.clusterTitles[index];
    } else {
      return `Cluster ${index + 1}`;
    }
  }
}
