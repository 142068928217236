import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActionDefinition } from './ActionDefinition';
import {
  DialogService,
  DocumentService,
  TitleLevelsService,
  TitleModeService,
} from '../services';
import { DisplayType, DocumentDataState, TargetItem } from '../models';
import { combineLatest } from 'rxjs';

export interface DeleteSeveralTargetsActionContext {
  targets: TargetItem[];
}

@Injectable({
  providedIn: 'root',
})
export class DeleteSeveralTargetsAction extends ActionDefinition<DeleteSeveralTargetsActionContext> {
  constructor(
    private documentService: DocumentService,
    private dialog: DialogService,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService
  ) {
    super();
  }

  public invoke(): void {
    this.documentService.documentState$
      .pipe(first())
      .subscribe((state: DocumentDataState) => {
        combineLatest([
          this.titleModeService.titleMode$,
          this.titleLevelsService.titleLevels$,
        ])
          .pipe(first())
          .subscribe(([titleMode, titleLevels]: [DisplayType, number[]]) => {
            this.dialog
              .manageReportItems(state, titleMode, titleLevels)
              .afterClosed()
              .subscribe((targetItems: TargetItem[]) => {
                if (targetItems) {
                  this.documentService.deleteDocumentData(targetItems, true);
                }
              });
          });
      });
  }
}
