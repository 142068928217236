import { Injectable } from '@angular/core';
import {
  TrendingCalculation,
  Survey,
  TRENDING_CALCULATION_REGEX_DEFAULT,
  TRENDING_CALCULATION_REGEX_PERCENT,
  TrendingCalculationCoding,
  TrendingCalculationItem,
} from '../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TrendingCalculationService {
  private trendingCalculationList: TrendingCalculationItem[] = [];

  private trendingCalculation: BehaviorSubject<TrendingCalculationItem[]> =
    new BehaviorSubject<TrendingCalculationItem[]>([]);
  public trendingCalculation$: Observable<TrendingCalculationItem[]> =
    this.trendingCalculation.asObservable();

  public addTrendingCalculation(
    trendingCalculation: TrendingCalculationItem
  ): void {
    this.trendingCalculationList.push(trendingCalculation);
    this.trendingCalculation.next(this.trendingCalculationList);
  }

  public removeTrendingCalculation(
    trendingCalculation: TrendingCalculationItem
  ): void {
    const index = this.trendingCalculationList.indexOf(trendingCalculation);
    if (index > -1) {
      this.trendingCalculationList.splice(index, 1);
    }
    this.trendingCalculation.next(this.trendingCalculationList);
  }

  public getTrendingCalculations(): TrendingCalculationItem[] {
    return this.trendingCalculationList;
  }

  public reset(previousTrendingCalculations?: TrendingCalculationItem[]): void {
    if (previousTrendingCalculations) {
      this.trendingCalculationList = previousTrendingCalculations;
    } else {
      this.trendingCalculationList = [];
    }

    this.trendingCalculation.next(this.trendingCalculationList);
  }

  public removeInvalidCalculation(
    surveys: Survey[],
    surveyToRemove?: Survey
  ): void {
    const availableSurveyCodes = surveys.map((survey: Survey) => survey.code);

    this.trendingCalculationList = cloneDeep(
      this.trendingCalculationList
    ).filter((calculation: TrendingCalculationItem) => {
      let result = true;
      if (surveyToRemove) {
        result =
          cloneDeep(calculation.surveys).filter((survey: Survey) => {
            return surveyToRemove.code === survey.code;
          }).length === 0;
      } else {
        result =
          cloneDeep(calculation.surveys).filter((survey: Survey) => {
            return availableSurveyCodes.indexOf(survey.code) < 0;
          }).length === 0;
      }

      return result;
    });

    this.trendingCalculation.next(this.trendingCalculationList);
  }

  public convertToTrendingCalculationItem(
    surveys: Survey[],
    trendings: TrendingCalculation[]
  ) {
    const trendingItems = trendings.map((element) => ({
      coding: element.coding,
      surveys: this.filterSurveysFromTrendingsCoding(surveys, element.coding),
    }));

    return trendingItems;
  }

  public filterSurveysFromTrendingsCoding(surveys: Survey[], coding: string) {
    const filteredSurveys = surveys.filter((survey) => {
      let surveysFound = [];
      if (
        coding.toUpperCase().startsWith(TrendingCalculationCoding.percentDiff)
      ) {
        const matches = coding.match(TRENDING_CALCULATION_REGEX_PERCENT);
        if (matches.length === 3)
          surveysFound = [...matches[1].split(','), matches[2].trim()].map(
            (match) => match.trim()
          );
      } else {
        const matches = coding.match(TRENDING_CALCULATION_REGEX_DEFAULT);
        if (matches.length === 2)
          surveysFound = matches[1].split(',').map((match) => match.trim());
      }
      return surveysFound.includes(survey.code);
    });

    return filteredSurveys;
  }

  public getCodings() {
    return this.trendingCalculationList.map((calculation) => ({
      coding: calculation.coding,
    }));
  }
}
