import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import {
  COUNT_CODING_WARNING,
  Operator,
  Survey,
  SurveyProvider,
} from '../../models';
import { isNotNullOrUndefined } from '@telmar-global/tup-document-storage';
import { first } from 'rxjs/operators';
import { DocumentService } from '../../services/document.service';

export interface CountCodingModel {
  operator: Operator;
  value: string;
}

@Component({
  templateUrl: './count-coding-dialog.component.html',
  styleUrls: ['./count-coding-dialog.component.scss'],
})
export class CountCodingDialogComponent implements OnInit {
  public operator: typeof Operator = Operator;

  public selectedParameter = Operator.greaterThanOrEqual;
  public parameters: string[] = [
    Operator.equal,
    Operator.greaterThan,
    Operator.lessThan,
    Operator.greaterThanOrEqual,
    Operator.lessThanOrEqual,
    Operator.notEqual,
    Operator.range,
  ];

  public countValue: number;
  public startValue: number;
  public endValue: number;

  public warning = '';

  constructor(
    public dialogRef: MatDialogRef<CountCodingDialogComponent>,
    private messageService: TupUserMessageService,
    private documentService: DocumentService
  ) {
    this.documentService.selectedSurvey$
      .pipe(isNotNullOrUndefined(), first())
      .subscribe((survey: Survey) => {
        this.warning =
          survey.provider === SurveyProvider.youGov ? COUNT_CODING_WARNING : '';
      });
  }

  ngOnInit(): void {}

  onButtonClick(buttonOption: boolean): void {
    if (!buttonOption) {
      this.close();
      return;
    }

    let countValue;
    if (this.selectedParameter !== Operator.range) {
      if (!this.countValue) {
        this.showEnterValidValueAlert();
        return;
      }
      countValue = `${this.countValue}`;
    } else {
      if (!this.startValue || !this.endValue) {
        this.showEnterValidValueAlert();
        return;
      }

      if (this.startValue > this.endValue) {
        this.showEnterValidRangeAlert();
        return;
      }
      countValue = `(${this.startValue}, ${this.endValue})`;
    }

    this.dialogRef.close({
      operator: this.selectedParameter,
      value: countValue,
    });
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  private showEnterValidValueAlert(): void {
    this.messageService.openMessageDialog('Please enter valid value.', 'Alert');
  }

  private showEnterValidRangeAlert(): void {
    this.messageService.openMessageDialog('Please enter valid range.', 'Alert');
  }
}
