<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Factor Analysis settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content fxLayout="column">
    <h3 class="setting-section-title margin-adjustment">Variable reduction</h3>
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <mat-checkbox
        tupMatCheckbox
        appearance="small"
        color="primary"
        fxFlex
        fxGrow="1"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="settings.isEliminateNegligibleCorrelations"
        >Eliminate variables with negligible correlations to
        others?</mat-checkbox
      >

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="center center"
      >
        <mat-label class="variable-reduction-label"
          >Variable reduction threshold</mat-label
        >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field-without-label reduced-size-input"
        >
          <input
            [disabled]="!settings.isEliminateNegligibleCorrelations"
            [(ngModel)]="variableReductionThreshold"
            matInput
            type="number"
            step="0.1"
          />
        </mat-form-field>
      </div>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">Table highlighting</h3>
    <div
      *ngFor="let option of tableHiglightColors"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <span class="table-highlight-threshold-label">{{
          settings.tableHighlightOptions[option].label
        }}</span>
        <span class="table-highlight-threshold-description">{{
          settings.tableHighlightOptions[option].description
        }}</span>
        <div
          fxLayout="row"
          fxLayoutGap="10px"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            class="input-form-field-without-label reduced-size-input"
          >
            <input
              matInput
              [(ngModel)]="
                option === faTableHighlightOption.small
                  ? settings.tableHighlightOptions[
                      faTableHighlightOption.moderate
                    ].threshold
                  : settings.tableHighlightOptions[option].threshold
              "
              type="number"
              step="0.1"
              max="1"
              [disabled]="option === faTableHighlightOption.small"
            />
          </mat-form-field>
          <span class="highlight-color-dot-container">
            <span
              class="highlight-color-dot"
              [style.background-color]="
                settings.tableHighlightOptions[option].background
              "
            ></span>
          </span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</div>
