<div
  class="manage-chart-styles"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="false"
>
  <tup-mat-title-bar
    title="Chart styles"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <div mat-dialog-content>
    <tup-mat-content-wrapper
      position="middle"
      gap="2"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap=".5rem"
      >
        <mat-card
          class="header-button"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span>
            {{ documents?.dataSource.length || 0 }}
          </span>
          Chart styles
        </mat-card>

        <tup-document-filter
          formFieldAppearance="outline"
          formFieldLabel="Search chart styles"
          [filterFor]="documents"
          fxFlex="1 1 auto"
        ></tup-document-filter>
      </div>
    </tup-mat-content-wrapper>
    <tup-mat-content-wrapper
      position="last"
      gap="2"
      tupDocumentEventEmitter
      tupDocumentEventHandler
      chartStyleDocumentEventHandler
      documentType="Chart styles"
    >
      <mat-divider class="divider-bottom"></mat-divider>
      <tup-document-storage
        [options]="options"
        class="document-storage-table"
        #documents
      ></tup-document-storage>
    </tup-mat-content-wrapper>
  </div>
</div>
