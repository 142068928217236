import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectMenuOption } from 'src/app/models/application.model';
import {
  BASIC_CHART_TYPES,
  ChartTargetMode,
  COLUMN_SORT_ORDERS,
  ColumnSortOrder,
  DataViewMode,
  GlobalChartSettings,
  GraphSelectionValue,
  IGNORED_SINGLE_TARGET_GRAPH_TYPES,
  INVALID_NUMBER_CHARS,
  MULTIPLE_SURVEY_CHART_TYPES,
  NO_SECONDARY_CHART_TYPES,
  SECONDARY_CHART_TYPES,
  SelectMenuOptionChart,
  unsuitableChartMixed,
} from 'src/app/models/charts.model';
import { DisplayType } from 'src/app/models/document.model';
import { TITLE_MODES } from 'src/app/models/crosstab.model';
import { cloneDeep } from 'lodash';
import {
  DataItem,
  DataItemId,
  DataItemType,
} from '../../models/data-item.model';
import { DataItemsService } from '../../services/data-items.service';
import { first } from 'rxjs/operators';
import { TitleLevelsService } from '../../services/title-levels.service';
import { TitleLevelsDialogResult } from '../title-levels-dialog/title-levels-dialog.component';

export interface GlobalChartSettingsDialogDataModel {
  config: GlobalChartSettings;
  targetMode: ChartTargetMode;
}

@Component({
  templateUrl: './global-chart-settings-dialog.component.html',
  styleUrls: ['./global-chart-settings-dialog.component.scss'],
})
export class GlobalChartSettingsDialogComponent implements OnInit, OnDestroy {
  public readonly maxDecimalPlaces = 9;
  public chartTypes: SelectMenuOptionChart<GraphSelectionValue>[] =
    BASIC_CHART_TYPES;
  public dataViewModeType: typeof DataViewMode = DataViewMode;
  public chartDataItems: SelectMenuOption<DataItemId>[] = [];
  public readonly secondaryChartTypes: SelectMenuOptionChart<GraphSelectionValue>[] =
    SECONDARY_CHART_TYPES;
  public readonly columnSortOrders: ColumnSortOrder[] = COLUMN_SORT_ORDERS;

  public chartTargetMode: ChartTargetMode;
  public chartSettings: GlobalChartSettings;

  public isSecondaryChartTypeDisabled = false;
  public isSuitableChartForMixing: boolean;

  public titleModes = TITLE_MODES;

  constructor(
    public dialogRef: MatDialogRef<GlobalChartSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalChartSettingsDialogDataModel,
    private titleLevelsService: TitleLevelsService,
    private dataItemsService: DataItemsService
  ) {
    this.chartTargetMode = data.targetMode;
    this.chartSettings = cloneDeep(data.config);
    this.updateChartTypesByTargetMode(this.chartTargetMode);
  }

  ngOnInit(): void {
    this.isSecondaryChartTypeDisabled =
      this.shouldDisableSecondaryChartType(
        this.chartSettings.primaryChartType
      ) || this.chartSettings.secondaryDataItem === DataItemType.none;
    this.isSuitableChartForMixing = this.isSuitableForChartMixing();

    this.dataItemsService.chartDataItems$
      .pipe(first())
      .subscribe((dataItems: DataItem[]) => {
        this.formatChartDataItem(dataItems);
      });
  }

  ngOnDestroy(): void {}

  public onPrimaryChartTypeChange(): void {
    if (
      this.shouldDisableSecondaryChartType(
        this.chartSettings.primaryChartType
      ) ||
      this.chartSettings.secondaryDataItem === DataItemType.none
    ) {
      this.isSecondaryChartTypeDisabled = true;
      this.chartSettings.secondaryChartType = 'None';
    }

    this.unsetSecondaryDataItemAndChartType();

    if (
      this.chartSettings.primaryChartType === 'tupScatter' &&
      this.chartSettings.secondaryDataItem === DataItemType.none
    ) {
      const secondaryDataItemForScatterChart =
        this.findSecondaryDataItemForScatterChart();
      this.onSecondaryDataItemChange(secondaryDataItemForScatterChart);
      this.chartSettings.secondaryDataItem = secondaryDataItemForScatterChart;
    }

    this.isSuitableChartForMixing = this.isSuitableForChartMixing();
    if (
      !this.isSuitableChartForMixing &&
      this.chartSettings.primaryDataItem !==
        this.chartSettings.secondaryDataItem
    ) {
      this.chartSettings.secondaryDataItem = DataItemType.none;
    }
  }

  public onSecondaryDataItemChange(dataItem: DataItemId): void {
    this.isSecondaryChartTypeDisabled =
      dataItem === DataItemType.none ||
      this.chartSettings.primaryChartType === 'tupScatter';
    if (this.isSecondaryChartTypeDisabled) {
      this.chartSettings.secondaryChartType = 'None';
    }
  }

  public selectTitleMode(event: any): void {
    if (event === DisplayType.levels) {
      this.titleLevelsService
        .openDialog()
        .subscribe((dialogResult: TitleLevelsDialogResult) => {
          this.chartSettings.titleLevels = dialogResult.titleLevels;
        });
    } else {
      this.chartSettings.titleLevels = [];
    }
  }

  public onTopRowsCountChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.topRowsCount)) {
      this.chartSettings.topRowsCount = 0;
    }
  }

  public onDecimalPlacesChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.decimalPlaces)) {
      this.chartSettings.decimalPlaces = 0;
    }

    if (this.chartSettings.decimalPlaces > this.maxDecimalPlaces) {
      this.chartSettings.decimalPlaces = this.maxDecimalPlaces;
    }
  }

  public onKeydown(e: KeyboardEvent): void {
    if (INVALID_NUMBER_CHARS.includes(e.key)) {
      e.preventDefault();
    }
  }

  public onButtonClick(): void {
    this.dialogRef.close(this.chartSettings);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  private formatChartDataItem(dataItems: DataItem[]): void {
    this.chartDataItems = dataItems.map((dataItem: DataItem) => ({
      title:
        dataItem.id === DataItemType.audience
          ? `${dataItem.displayName}/${dataItem.volumetricDisplayName}`
          : dataItem.displayName,
      value: dataItem.id,
    }));
  }

  private updateChartTypesByTargetMode(targetMode: ChartTargetMode): void {
    if (
      targetMode === ChartTargetMode.insightsGroup ||
      targetMode === ChartTargetMode.surveysGroup
    ) {
      this.chartTypes = MULTIPLE_SURVEY_CHART_TYPES;
    }

    if (targetMode === ChartTargetMode.single) {
      this.chartTypes = this.chartTypes.filter(
        (chartType) =>
          typeof chartType.graphType !== 'string' ||
          !IGNORED_SINGLE_TARGET_GRAPH_TYPES.includes(chartType.graphType)
      );
    }
  }

  private shouldDisableSecondaryChartType(
    primaryChartType: GraphSelectionValue | ''
  ): boolean {
    return (
      primaryChartType === '' ||
      NO_SECONDARY_CHART_TYPES.includes(primaryChartType)
    );
  }

  private isInvalidNoneNegativeNumber(value: any): boolean {
    return value < 0 || value === null || isNaN(value);
  }

  private findSecondaryDataItemForScatterChart() {
    let secondaryDataItemForScatterChart = this.chartDataItems[0].value;
    for (const dataItem of this.chartDataItems) {
      if (dataItem.value !== this.chartSettings.primaryDataItem) {
        secondaryDataItemForScatterChart = dataItem.value;
        break;
      }
    }
    return secondaryDataItemForScatterChart;
  }

  private unsetSecondaryDataItemAndChartType(): void {
    this.chartSettings.secondaryDataItem = DataItemType.none;
    this.chartSettings.secondaryChartType = 'None';
    this.isSecondaryChartTypeDisabled = true;
  }

  private isSuitableForChartMixing(): boolean {
    return (
      this.chartSettings.primaryChartType !== '' &&
      !unsuitableChartMixed.includes(this.chartSettings.primaryChartType)
    );
  }
}
