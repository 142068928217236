import { DocumentAudienceGroupItem } from '@telmar-global/tup-audience-groups';

export const FACTOR_VERSION = `v1`;
export const FA_STATUS_POLLING_TIME = 2000;
export const DEFAULT_VARIABLE_REDUCTION_THRESHOLD = 0.4;

export interface StartFactorAnalysisRequestBody {
  'survey-code': string;
  'survey-version': string;
  features: string[];
  'respondent-filter': string;
  'session-description'?: string;
  'cleaning-method': FACleaningMethod;
  'cleaning-method-options'?: any; // todo: tbd
  'variable-options'?: FAVariableOption[];
  'max-num-of-factors': number;
  'min-variance-explained-by-factors': MinVarianceExplainedByFactors;
}

export interface StartFactorAnalysisResponseBody {
  success: boolean;
  message: string;
  'research-session-id': string;
}

export interface GetFactorsRequestBody {
  'research-session-id': string;
  'cleaning-methods': FACleaningMethod[];
}

export interface GetFactorsResponseBody {
  success: boolean;
  message: string;
  factors: Record<FACleaningMethod, FactorAnalysisMetrics>;
  'recommended-num-of-factors': Record<FACleaningMethod, number>;
  features?: Record<FACleaningMethod, FactorAnalysisFeatureMetrics>;
}

export interface SaveFactorRequestBody {
  'research-session-id': string;
  'cleaning-method': FACleaningMethod;
  'num-of-factors': number;
}

export interface SaveFactorResponseBody {
  success: boolean;
  message: string;
  'factor-keys': string[];
}

export enum FACleaningMethod {
  cleanDrop = 'clean_drop',
  cleanImputation = 'clean_imputation',
}

export interface FAVariableOption {
  option: FAVariableOptionType;
  threshold: number;
}
export enum FAVariableOptionType {
  removeCollinearVariables = 'remove-collinear-variables',
}

export interface MinVarianceExplainedByFactors {
  'percentage-type': PercentageType;
  'percentage-value': number; // 0 - 100
}

export enum PercentageType {
  cumulative = 'cumulative',
  individual = 'individual',
}

export enum PercentageTypeLabels {
  cumulative = 'Variance explained cumulative (%)',
  individual = 'Variance explained (%)',
}

export type FactorFeatureItem = DocumentAudienceGroupItem;

export interface FactorAnalysisMetrics {
  'num-of-factors': number;
  'explained-variances': FAExplainedVariances;
  loadings: number[][];
}

export interface FactorAnalysisFeatureMetrics {
  remaining: string[];
  removed: string[];
}

export interface FAExplainedVariances {
  'abs-explained-variances': number[];
  'p-explained-variances': number[];
  'cum-p-explained-variances': number[];
}

export interface FactorAnalysisTableRowData {
  rowNumber?: number | '';
  title: string;
  loadings: number[];
  highlightColors?: string[];
  hasFactorDivider?: boolean;
}

export interface FactorAnalysisSettings {
  tableHighlightOptions: Record<
    FATableHighlightOption,
    FATableHighlightOptionItem
  >;
  isEliminateNegligibleCorrelations: boolean;
  variableOptions: FAVariableOption[];
  defaultSortType: FASortType;
  singleSortSettings?: SingleSortSettings;
  multiSortSettings?: MultiSortSettings;
}

export enum FATableHighlightOption {
  large = 'large',
  moderate = 'moderate',
  small = 'small',
}

export interface FATableHighlightOptionItem {
  threshold: number;
  color: string;
  label: string;
  description: string;
  background: string;
}

export enum FASortType {
  singleSort = 'singleSort',
  multiSort = 'multiSort',
}

export interface SingleSortSettings {
  sortOn: SingleSortOnItem;
}

export enum SingleSortOnItem {
  original = 'original',
  absolute = 'absolute',
}

export interface MultiSortSettings {
  sortThreshold: number;
}

export const DEFAULT_FA_TABLE_HIGHLIGHT_OPTIONS: Record<
  FATableHighlightOption,
  FATableHighlightOptionItem
> = {
  [FATableHighlightOption.large]: {
    threshold: 0.7,
    color: 'green',
    label: 'Large',
    description: 'values greater than',
    background: '#9cd296',
  },
  [FATableHighlightOption.moderate]: {
    threshold: 0.4,
    color: 'yellow',
    label: 'Moderate',
    description: 'values greater than',
    background: '#ffeb98',
  },
  [FATableHighlightOption.small]: {
    threshold: 0,
    color: 'white',
    label: 'Small',
    description: 'values less than',
    background: '#ffffff',
  },
};

export const DEFAULT_FA_SETTINGS = {
  isEliminateNegligibleCorrelations: false,
  tableHighlightOptions: DEFAULT_FA_TABLE_HIGHLIGHT_OPTIONS,
  variableOptions: [
    {
      option: FAVariableOptionType.removeCollinearVariables,
      threshold: DEFAULT_VARIABLE_REDUCTION_THRESHOLD,
    },
  ],
  defaultSortType: FASortType.singleSort,
};
