<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Cluster settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Cluster title</mat-label>
        <input
          matInput
          [(ngModel)]="settings.title"
        />
      </mat-form-field>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">Data preferences</h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="margin-adjustment"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Data item</mat-label>
        <mat-select [(ngModel)]="settings.dataItem">
          <mat-option
            *ngFor="let dataItem of dataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxFlex></div>
    </div>

    <ng-container *ngIf="viewType === 'chart'">
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">Chart view set up</h3>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="margin-adjustment"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Primary chart display</mat-label>
          <mat-select [(ngModel)]="settings.chartType">
            <mat-option
              *ngFor="let chartType of chartTypes"
              [value]="chartType.value"
            >
              {{ chartType.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div fxFlex></div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.showDataLabel"
        >
          Show data label
        </mat-checkbox>

        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.showAxisLabel"
        >
          Show axis label
        </mat-checkbox>

        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.showLegend"
        >
          Show legend
        </mat-checkbox>
      </div>
    </ng-container>

    <ng-container *ngIf="viewType === 'table'">
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">
        Table highlighting preferences
      </h3>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <mat-checkbox
          fxFlex
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox"
          [(ngModel)]="settings.showHighlighting"
        >
          Show highlight
        </mat-checkbox>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <span
            class="highlight-color-dot-container"
            *ngFor="let color of highlightColors"
          >
            <span
              class="highlight-color-dot"
              [style.background-color]="color.background"
            ></span>
            {{ color.label }}
          </span>
          <span>in</span>
        </div>

        <mat-radio-group
          [(ngModel)]="settings.highlightType"
          fxLayout="row"
          fxLayoutGap="5px"
          fxFlex="100"
          [disabled]="!settings.showHighlighting"
        >
          <mat-radio-button
            *ngFor="let highlightType of highlightTypes"
            [value]="highlightType.value"
          >
            {{ highlightType.title }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</div>
