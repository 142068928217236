import { SVGElement } from 'highcharts';
import { cloneDeep } from 'lodash';

export interface Box {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Anchor {
  x: number;
  y: number;
  r: number;
}

export type PlacedBox = Box | Anchor;

export const getAbsoluteBox = (svg: SVGElement): Box => {
  return {
    // @ts-ignore
    x: Number(svg.attr('x')) + (svg.parentGroup?.translateX || 0),
    // @ts-ignore
    y: Number(svg.attr('y')) + (svg.parentGroup?.translateY || 0),
    // @ts-ignore
    width: svg.bBox.width,
    // @ts-ignore
    height: svg.bBox.height,
  };
};

export const getAnchor = (svg: SVGElement): Anchor => {
  return {
    // @ts-ignore
    x: Number(svg.attr('x')) + (svg.parentGroup?.translateX || 0),
    // @ts-ignore
    y: Number(svg.attr('y')) + (svg.parentGroup?.translateY || 0),
    r: Number(svg.attr('radius')),
  };
};

const isColliding = (placedBoxes: PlacedBox[], label: Box): boolean => {
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < placedBoxes.length; i++) {
    const placedBox = placedBoxes[i];
    if (isIntersectRect(placedBox, label)) {
      return true;
    }
  }
  return false;
};

const isIntersectRect = (box1: PlacedBox, box2: Box): boolean => {
  let box1X;
  let box1Y;
  let box1Width;
  let box1Height;
  if ('r' in box1) {
    box1X = box1.x - box1.r;
    box1Y = box1.y - box1.r;
    box1Width = box1.r * 2;
    box1Height = box1.r * 2;
  } else {
    box1X = box1.x;
    box1Y = box1.y;
    box1Width = box1.width;
    box1Height = box1.height;
  }
  return !(
    box2.x >= box1X + box1Width ||
    box2.x + box2.width <= box1X ||
    box2.y >= box1Y + box1Height ||
    box2.y + box2.height <= box1Y
  );
};

/**
 * Inspired by SurveyTime v1 strategy
 */
const findPositions = (labels: Box[], anchors: Anchor[]): Box[] => {
  const placedBoxes: PlacedBox[] = [...anchors];
  const labelSize = labels.length;
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < labelSize; i++) {
    let repositionedLabel: PlacedBox;
    let hasGoodPredictablePosition = false;
    // try to find predictable position, from right, bottom, left and top
    for (let j = 0; j <= 10; j++) {
      const box = checkAvailablePosition(placedBoxes, labels[i], j);
      if (box) {
        repositionedLabel = box;
        hasGoodPredictablePosition = true;
        break;
      }
    }

    if (!hasGoodPredictablePosition) {
      const attempts = labelSize > 500 ? 10 : 500;
      repositionedLabel = cloneDeep(labels[i]);
      // try random position to avoid unnecessary duplication
      for (let attempt = 0; attempt < attempts; attempt++) {
        // move to a new position
        repositionedLabel.x += (Math.random() - 0.5) * 20;
        repositionedLabel.y += (Math.random() - 0.5) * 20;
        if (!isColliding(placedBoxes, repositionedLabel)) {
          break;
        }
      }
    }
    placedBoxes.push(repositionedLabel);
  }
  return placedBoxes.slice(anchors.length, placedBoxes.length) as Box[];
};

const checkAvailablePosition = (
  placedBoxes: PlacedBox[],
  label: Box,
  attempt: number
): Box | null => {
  const clonedLabel = cloneDeep(label);
  const width = label.width;
  const movement = 20;
  const energy = movement * (attempt + 1);

  clonedLabel.x = clonedLabel.x + movement * attempt;
  // default to right
  if (!isColliding(placedBoxes, label)) {
    return clonedLabel;
  } else {
    // move to bottom
    clonedLabel.x = clonedLabel.x - energy;
    clonedLabel.y = clonedLabel.y + energy;
    if (!isColliding(placedBoxes, clonedLabel)) {
      return clonedLabel;
    } else {
      // move to left
      clonedLabel.x = clonedLabel.x - width - energy;
      clonedLabel.y = clonedLabel.y - energy;
      if (!isColliding(placedBoxes, clonedLabel)) {
        return clonedLabel;
      } else {
        // move to top
        clonedLabel.x = clonedLabel.x + width + energy;
        clonedLabel.y = clonedLabel.y - energy;
        if (!isColliding(placedBoxes, clonedLabel)) {
          return clonedLabel;
        } else {
          return null;
        }
      }
    }
  }
};

export const positionLabels = (chart: Highcharts.Chart): void => {
  // @ts-ignore
  const chartAnnotations = chart.annotations.filter(
    (annotation) =>
      annotation.options.visible && annotation.options.checkPosition
  );
  const labels: Box[] = chartAnnotations.map((annotation) =>
    getAbsoluteBox(annotation.labels[0].graphic)
  );
  const clonedLabels = cloneDeep(labels);
  const anchors: Anchor[] = chart.series.reduce((acc, item) => {
    return acc.concat(
      ...item.data
        .filter((point) => point.visible)
        .map((point) => getAnchor(point.graphic))
    );
  }, []);

  const newLabels: Box[] = findPositions(labels, anchors);

  chartAnnotations.forEach((annotation, index) => {
    const label = annotation.labels[0];
    label.options.x += newLabels[index].x - clonedLabels[index].x;
    label.options.y += newLabels[index].y - clonedLabels[index].y;

    annotation.setControlPointsVisibility(true);
  });

  chart.redraw(false);
};
