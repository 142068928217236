import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { DocumentService } from 'src/app/services';
import { Subject, combineLatest } from 'rxjs';
import {
  DEFAULT_WEIGHT_INDEX,
  Survey,
  SurveyMetaDataWeights,
  TargetItem,
  DocumentDataState,
} from 'src/app/models';
import { takeUntil } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-weight-select',
  templateUrl: './weight-select.component.html',
  styleUrls: ['./weight-select.component.scss'],
})
export class WeightSelectComponent implements OnInit, OnDestroy {
  public weightDescriptions: SurveyMetaDataWeights[] = [];
  public filteredWeightDescriptions: SurveyMetaDataWeights[] = [];
  public currentWeightIndex = 1;
  private unsubscribe: Subject<void> = new Subject<void>();

  @Input() surveys: Survey[];
  @Output() weightChange: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();

  constructor(private documentService: DocumentService) {}

  ngOnInit(): void {
    this.listenToSurveyDataWeightsChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onWeightDescriptionChange(item: MatSelectChange) {
    this.currentWeightIndex = item.value;
    if (this.documentService.document?.content) {
      this.documentService.updateCurrentWeight(this.currentWeightIndex);
    }
  }

  public isWeightSelectable(weight: SurveyMetaDataWeights): boolean {
    return !!this.filteredWeightDescriptions.find(
      (filteredWeight) => filteredWeight === weight
    );
  }

  private listenToSurveyDataWeightsChanges(): void {
    combineLatest([
      this.documentService.documentState$,
      this.documentService.surveyDataWeights$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        ([docState, weights]: [DocumentDataState, SurveyMetaDataWeights[]]) => {
          this.weightDescriptions = weights;
          this.filteredWeightDescriptions = weights;

          if (this.documentService.document?.content && weights.length) {
            const existingWeight = weights.find(
              (weight) =>
                weight.index === this.documentService.document.content.weight
            );

            this.currentWeightIndex = existingWeight
              ? existingWeight.index
              : DEFAULT_WEIGHT_INDEX;
          }
          this.updateCompatibleWeights();
        }
      );
  }

  private updateCompatibleWeights() {
    const validWeights = this.documentService.getValidWeightsFromDocument();
    if (validWeights.length) {
      this.filteredWeightDescriptions = this.weightDescriptions.filter(
        (weight) => validWeights.includes(weight.index)
      );
      this.currentWeightIndex = this.documentService.getCompatibleWeight(
        validWeights,
        this.currentWeightIndex
      );
    } else {
      this.filteredWeightDescriptions = this.weightDescriptions;
      this.currentWeightIndex = this.documentService.document.content?.weight
        ? this.documentService.document.content.weight
        : DEFAULT_WEIGHT_INDEX;
    }
  }
}
