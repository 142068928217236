import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { CorrespondenceDataItem, HIGHLIGHT_COLORS } from 'src/app/models';
import {
  CorrespondenceSettings,
  CorrespondenceViewType,
  CORRESPONDENCE_WEIGHT_ITEMS,
} from 'src/app/models/correspondence.model';
import { MatTableDataSource } from '@angular/material/table';

export interface CorrespondenceSettingsDialogModel {
  viewType: CorrespondenceViewType;
  settings: CorrespondenceSettings;
  resultRows: CorrespondenceSettingsRowData[];
}

export interface CorrespondenceSettingsRowData {
  factorTitle: string;
  percent: number;
  percentCum: number;
  chi2: number;
  degreeFree: number;
}

@Component({
  selector: 'app-correspondence-settings-dialog',
  templateUrl: './correspondence-settings-dialog.component.html',
  styleUrls: ['./correspondence-settings-dialog.component.scss'],
})
export class CorrespondenceSettingsDialogComponent
  implements OnInit, OnDestroy
{
  public viewType: CorrespondenceViewType;
  public settings: CorrespondenceSettings;
  public weightItems = CORRESPONDENCE_WEIGHT_ITEMS;
  public readonly dataItems = [
    {
      value: CorrespondenceDataItem.audience,
      title: 'Audience',
    },
    {
      value: CorrespondenceDataItem.resps,
      title: 'Respondent',
    },
    {
      value: CorrespondenceDataItem.percentCol,
      title: '%Col',
    },
    {
      value: CorrespondenceDataItem.percentRow,
      title: '%Row',
    },
    {
      value: CorrespondenceDataItem.index,
      title: 'Index',
    },
  ];

  public factors = [...Array(6).keys()];

  public readonly highlightColors = HIGHLIGHT_COLORS;

  public displayedColumns: string[] = [
    'factor',
    'percent',
    'percent_cum',
    'chi2',
    'deg_free',
  ];
  public dataSource: MatTableDataSource<CorrespondenceSettingsRowData> =
    new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<CorrespondenceSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CorrespondenceSettingsDialogModel
  ) {
    this.viewType = data.viewType;
    this.settings = cloneDeep(data.settings);
    this.setupTable(data.resultRows);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public onButtonClick(): void {
    this.dialogRef.close(this.settings);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  private setupTable(data: CorrespondenceSettingsRowData[]): void {
    this.populateTableData(data);
  }

  private populateTableData(data: CorrespondenceSettingsRowData[]): void {
    this.dataSource.data = data;
  }
}
