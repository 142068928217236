import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subject, forkJoin } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import {
  CHART_SETTING_VERSION,
  ChartSearchInput,
  ChartSettings,
  ChartTargetMode,
  CodingGridSearchField,
  CodingGridSearchHit,
  CodingGridTableRow,
  CrosstabSearchHit,
  DataViewMode,
  DisplayType,
  DocumentDataState,
  DocumentViewType,
  GLOBAL_SETTINGS_KEY,
  SearchHit,
  SearchSettings,
  Target,
  TargetItem,
  TargetType,
  UserTargetCodingResponse,
  ViewType,
} from 'src/app/models';
import { DocumentService } from 'src/app/services/document.service';
import { CodingGridService } from 'src/app/services/coding-grid.service';
import { FindAndReplaceService } from 'src/app/services/find-and-replace.service';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { TitleModeService } from 'src/app/services/title-mode.service';
import { TargetService } from 'src/app/services/target.service';
import { TargetTitlePipe } from 'src/app/pipes';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { ActivatedRoute } from '@angular/router';

const DEFAULT_CODING_GRID_SEARCH_LIMITS = {
  limitToTitle: false,
  limitToCode: false,
  limitToGroupName: false,
};

const DEFAULT_CROSSTAB_SEARCH_LIMITS = {
  limitToRows: false,
  limitToColumns: false,
};

const DEFAULT_CHART_SEARCH_LIMITS = {
  limitToTitle: true,
  limitToInsights: false,
};

@Component({
  templateUrl: './find-and-replace-dialog.component.html',
  styleUrls: ['./find-and-replace-dialog.component.scss'],
})
export class FindAndReplaceDialogComponent implements OnInit, OnDestroy {
  public readonly viewTypeOptions: typeof ViewType = ViewType;
  public readonly documentViewTypeOptions: typeof DocumentViewType =
    DocumentViewType;
  public findKeyword: string = '';
  public lastSavedKeywordOnEnter: string = '';
  public replaceKeyword: string = '';
  public limitsChanged: boolean = false;
  private targetType: TargetType;
  private codingGridRows: CodingGridTableRow[];
  private crosstabRows: Target[];
  private crosstabColumns: Target[];
  private chartList: any[];
  private chartTargetMode: ChartTargetMode;
  private chartDataViewModeType: DataViewMode;
  public replaceInProgress: boolean = false;
  public replaceError: string = '';
  public searchHits: SearchHit[] = [];
  public maxHits: number = 0;
  public currentHit: number = 0;
  public settings: SearchSettings;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FindAndReplaceDialogComponent>,
    private findAndReplaceService: FindAndReplaceService,
    private documentService: DocumentService,
    private codingGridService: CodingGridService,
    private titleModeService: TitleModeService,
    private targetService: TargetService,
    private targetTitlePipe: TargetTitlePipe,
    private messageService: TupUserMessageService,
    private activatedRoute: ActivatedRoute
  ) {
    let searchViewType: ViewType;
    let documentViewType: DocumentViewType;
    if (
      this.activatedRoute.snapshot.queryParams?.tab ===
      String(ViewType.crossTab)
    ) {
      searchViewType = ViewType.crossTab;
      this.crosstabRows = cloneDeep(data.rows);
      this.crosstabColumns = cloneDeep(data.columns);
      documentViewType = DocumentViewType.data;
    } else if (
      data.codingGridSearch ||
      this.activatedRoute.snapshot.queryParams?.tab ===
        String(ViewType.codingGrid)
    ) {
      searchViewType = ViewType.codingGrid;
      this.codingGridRows = cloneDeep(data.rows);
      this.targetType = data.targetType;
      documentViewType = DocumentViewType.data;
    } else {
      this.chartTargetMode = data.targetMode;
      this.chartDataViewModeType = data.dataViewModeType;
      documentViewType = DocumentViewType.graph;
      this.chartList = cloneDeep(data.chartListData);
    }

    this.settings = {
      documentViewType,
      searchViewType,
      matchCases: false,
      codingGridLimits: DEFAULT_CODING_GRID_SEARCH_LIMITS,
      crosstabLimits: DEFAULT_CROSSTAB_SEARCH_LIMITS,
      chartLimits: DEFAULT_CHART_SEARCH_LIMITS,
    };
  }

  ngOnInit(): void {
    this.findAndReplaceService.updateDialogState(true);
    this.listenToTitleModeUpdates();
    this.listenToDocumentDataChanges();
    this.listenToCodingGridChanges();
    this.listenToChartChanges();
    this.listenToDialogState();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: DocumentDataState) => {
        if (state?.changes) {
          const isChangesInCurrentView =
            state.changes.filter((change) => {
              const changedItems = change.items.filter(
                (item) => item.type === this.targetType
              );
              return changedItems.length > 0;
            }).length > 0;

          if (isChangesInCurrentView) {
            state.changes.forEach((change) => {
              change.items.forEach((item) => {
                const searchHit = this.searchHits.filter(
                  (hit) => hit.targetId === item.target.id
                );
                if (searchHit.length > 0) {
                  searchHit[0].replaced = false;
                  this.codingGridRows.filter(
                    (row) => row.targetId === item.target.id
                  )[0].targetItem = item;
                } else {
                  this.resetSearchHits();
                }
              });
            });
          }
        }
      });
  }

  private listenToCodingGridChanges(): void {
    this.codingGridService.activeGrid$
      .pipe(takeUntil(this.unsubscribe))
      .pipe(isNotNullOrUndefined())
      .subscribe((grid: TargetType) => {
        if (grid !== this.targetType) {
          this.resetSearchHits();
          this.targetType = grid;
        }
      });

    this.codingGridService.activeGridRows$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((gridRows: CodingGridTableRow[]) => {
        this.codingGridRows = gridRows;
      });
  }

  private listenToChartChanges(): void {
    this.findAndReplaceService.chartInput$
      .pipe(skip(1), takeUntil(this.unsubscribe))
      .subscribe((chartInput: ChartSearchInput) => {
        if (chartInput && chartInput.chartListData.length > 0) {
          this.chartTargetMode = chartInput.targetMode;
          this.chartDataViewModeType = chartInput.dataViewModeType;
          this.chartList = cloneDeep(chartInput.chartListData);
          this.resetSearchHits();
        }
      });
  }

  private listenToTitleModeUpdates(): void {
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((titleMode) => {
        this.resetSearchHits();
      });
  }

  private listenToDialogState() {
    this.findAndReplaceService.dialogState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: boolean) => {
        setTimeout(() => {
          if (!state && this.settings.searchViewType === ViewType.crossTab)
            this.close();
        }, 0);
      });
  }

  public close(): void {
    this.resetSearchHits();
    this.findAndReplaceService.updateDialogState(false);
    this.dialogRef.close(null);
  }

  private searchGrid(): SearchHit[] {
    const searchHits: SearchHit[] = [];

    if (this.settings.searchViewType === ViewType.codingGrid) {
      let fieldsToMatch = [];

      this.settings.codingGridLimits.limitToTitle &&
        fieldsToMatch.push(CodingGridSearchField.title);
      this.settings.codingGridLimits.limitToCode &&
        fieldsToMatch.push(CodingGridSearchField.code);
      this.settings.codingGridLimits.limitToGroupName &&
        fieldsToMatch.push(CodingGridSearchField.groupName);

      if (fieldsToMatch.length === 0)
        fieldsToMatch = Object.keys(CodingGridSearchField);

      this.codingGridRows.forEach((row) => {
        fieldsToMatch.forEach((field) => {
          if (this.matchesKeyword(row[field])) {
            const searchHitDetails: CodingGridSearchHit = {
              gridView: this.targetType,
              rowId: row.id,
              field: field as CodingGridSearchField,
              focus: searchHits.length === 0 ? true : false,
            };

            searchHits.push({
              documentType: this.settings.documentViewType,
              viewType: this.settings.searchViewType,
              details: searchHitDetails,
              replaced: false,
              targetId: row.targetId,
            });
          }
        });
      });
    } else if (this.settings.searchViewType === ViewType.crossTab) {
      const limitsSet =
        this.settings.crosstabLimits.limitToRows ||
        this.settings.crosstabLimits.limitToColumns;

      if (!limitsSet || this.settings.crosstabLimits.limitToRows) {
        [...this.crosstabRows].forEach((target) => {
          if (this.matchesKeyword(this.targetTitlePipe.transform(target))) {
            const searchHitDetails: CrosstabSearchHit = {
              rowId: target.id,
              focus: searchHits.length === 0 ? true : false,
              type: 'insight',
            };

            searchHits.push({
              documentType: this.settings.documentViewType,
              viewType: this.settings.searchViewType,
              details: searchHitDetails,
              replaced: false,
              targetId: target.id,
            });
          }
        });
      }

      if (!limitsSet || this.settings.crosstabLimits.limitToColumns) {
        [...this.crosstabColumns].forEach((target) => {
          if (this.matchesKeyword(this.targetTitlePipe.transform(target))) {
            const searchHitDetails: CrosstabSearchHit = {
              rowId: target.id,
              focus: searchHits.length === 0 ? true : false,
              type: 'target',
            };

            searchHits.push({
              documentType: this.settings.documentViewType,
              viewType: this.settings.searchViewType,
              details: searchHitDetails,
              replaced: false,
              targetId: target.id,
            });
          }
        });
      }
    }

    return searchHits;
  }

  public searchChart() {
    const searchHits: SearchHit[] = [];

    const allChartSettings = this.getChartSettings();
    this.chartList.forEach((chart, index) => {
      const chartSettings = allChartSettings[index];
      const targetsToCheck = this.getChartItemsToSearch(chart, chartSettings);

      const targetHits: Target[] = targetsToCheck.filter((target) =>
        this.matchesKeyword(
          this.targetTitlePipe.transform(
            target,
            chartSettings.activeTitleMode,
            chartSettings.titleLevels || []
          )
        )
      );

      targetHits.forEach((target) => {
        const details = {
          chartIndex: index,
          rowId: target.id,
          focus: searchHits.length === 0,
        };
        searchHits.push({
          documentType: this.settings.documentViewType,
          details,
          scrollIntoView: searchHits.length === 0,
        });
      });
    });

    return searchHits;
  }

  private getChartItemsToSearch(
    chart: any,
    chartSettings: ChartSettings
  ): Target[] {
    const chartItemsToSearch = [];
    const topRowsCount = chartSettings?.topRowsCount || 0;
    let rowTargets;
    let sortColumnIndex;
    switch (this.chartTargetMode) {
      case ChartTargetMode.single:
        this.settings.chartLimits.limitToTitle &&
          chartItemsToSearch.push(chart.target);

        if (this.settings.chartLimits.limitToInsights) {
          rowTargets =
            topRowsCount === 0
              ? chart.rowTargets
              : this.getSortedTopRowTargets(
                  chart.dataItems[chartSettings.primaryDataItem],
                  chart.rowTargets,
                  chartSettings
                ).splice(0, topRowsCount);

          chartItemsToSearch.push(...rowTargets);
        }
        break;
      case ChartTargetMode.combined:
        this.settings.chartLimits.limitToTitle &&
          chartItemsToSearch.push(...chart.columnTargets);

        if (this.settings.chartLimits.limitToInsights) {
          if (['Primary', 'Secondary'].includes(chartSettings.sortColumn)) {
            sortColumnIndex = chartSettings.sortColumn === 'Primary' ? 0 : 1;
          } else {
            const targetId = chartSettings.sortColumn;
            sortColumnIndex = chart.columnTargets.findIndex(
              (target) => target.id === targetId
            );
          }

          const surveysGroupDataItems =
            chart.dataItems[chartSettings.primaryDataItem];
          rowTargets =
            topRowsCount === 0
              ? chart.rowTargets
              : this.getSortedTopRowTargets(
                  surveysGroupDataItems[sortColumnIndex],
                  chart.rowTargets,
                  chartSettings
                ).splice(0, topRowsCount);
          chartItemsToSearch.push(...rowTargets);
        }
        break;
      case ChartTargetMode.surveysGroup:
        this.settings.chartLimits.limitToTitle &&
          chartItemsToSearch.push(chart.target);

        if (this.settings.chartLimits.limitToInsights) {
          rowTargets =
            topRowsCount === 0
              ? chart.rowTargets
              : chart.rowTargets.splice(0, topRowsCount);
          chartItemsToSearch.push(...rowTargets);
        }

        break;
      case ChartTargetMode.insightsGroup:
        this.settings.chartLimits.limitToTitle &&
          chartItemsToSearch.push(chart.target);

        if (this.settings.chartLimits.limitToInsights) {
          if (['Primary', 'Secondary'].includes(chartSettings.sortColumn)) {
            sortColumnIndex = chartSettings.sortColumn === 'Primary' ? 0 : 1;
          } else {
            const survey = chartSettings.sortColumn.split('#')[1];
            sortColumnIndex = chart.surveyCodes.findIndex(
              (code) => code === survey
            );
          }

          const insightsGroupDataItems =
            chart.dataItems[chartSettings.primaryDataItem];
          rowTargets =
            topRowsCount === 0
              ? chart.rowTargets
              : this.getSortedTopRowTargets(
                  insightsGroupDataItems[sortColumnIndex],
                  chart.rowTargets,
                  chartSettings
                ).splice(0, topRowsCount);
          chartItemsToSearch.push(...rowTargets);
        }
        break;
    }

    return chartItemsToSearch;
  }

  public matchesKeyword(cell: string) {
    const keyword = !this.settings.matchCases
      ? this.findKeyword.toLowerCase()
      : this.findKeyword;

    if (!this.settings.matchCases) {
      cell = cell.toLowerCase();
    }
    return cell.includes(keyword);
  }

  public onFindEnter(event: any) {
    event.key === 'Enter' && this.onFind();
  }

  public onFind(): void {
    if (this.findKeyword.length < 3) return;

    if (this.settings.documentViewType === DocumentViewType.data) {
      this.findInData();
    } else if (this.settings.documentViewType === DocumentViewType.graph) {
      this.findInChart();
    }
  }

  public findInData() {
    if (
      !this.limitsChanged &&
      this.lastSavedKeywordOnEnter === this.findKeyword
    ) {
      this.searchHits.length > 0 && this.shiftFocus('down');
    } else {
      this.limitsChanged = false;
      this.lastSavedKeywordOnEnter = this.findKeyword;
      if (!this.findKeyword) {
        this.resetSearchHits();
        return;
      }

      this.searchHits = this.searchGrid();
      if (this.searchHits.length > 0) {
        this.updateSearchCounts();
      } else {
        this.maxHits = 0;
        this.currentHit = 0;
      }
      this.findAndReplaceService.updateSearchHits(this.searchHits);
    }
  }

  public findInChart() {
    if (
      !this.limitsChanged &&
      this.lastSavedKeywordOnEnter === this.findKeyword
    ) {
      this.searchHits.length > 0 && this.shiftFocus('down');
    } else {
      this.limitsChanged = false;
      this.lastSavedKeywordOnEnter = this.findKeyword;
      if (!this.findKeyword) {
        this.resetSearchHits();
        return;
      }
      this.searchHits = this.searchChart();
      if (this.searchHits.length > 0) {
        this.updateSearchCounts();
      } else {
        this.maxHits = 0;
        this.currentHit = 0;
      }
      this.findAndReplaceService.updateSearchHits(this.searchHits);
    }
  }

  public resetSearchHits(findKeyword = '') {
    this.searchHits = [];
    this.findKeyword = findKeyword;
    this.lastSavedKeywordOnEnter = '';
    this.maxHits = 0;
    this.currentHit = 0;
    this.findAndReplaceService.updateSearchHits([]);
  }

  public onFindKeywordChanged() {
    if (this.findKeyword === '') {
      this.resetSearchHits();
    }
  }

  public shiftFocus(step: string) {
    const currentHitIndex = this.searchHits.findIndex(
      (result) => result.details['focus']
    );
    let newHitIndex = currentHitIndex;
    if (step === 'up') {
      if (currentHitIndex > 0) newHitIndex = currentHitIndex - 1;
    } else if (step === 'down') {
      if (currentHitIndex < this.searchHits.length - 1)
        newHitIndex = currentHitIndex + 1;
    }

    if (currentHitIndex !== newHitIndex) {
      const searchHits = cloneDeep(this.searchHits);
      searchHits[currentHitIndex].details['focus'] = false;
      searchHits[newHitIndex].details['focus'] = true;
      this.updateSearchHits(searchHits);
    }
  }

  private updateSearchHits(searchHits: SearchHit[]) {
    this.findAndReplaceService.updateSearchHits(searchHits);
    this.searchHits = searchHits;
    this.updateSearchCounts();
  }

  private updateSearchCounts() {
    this.maxHits = this.searchHits.length;
    this.currentHit =
      this.searchHits.findIndex((result) => result.details['focus']) + 1;
  }

  public onReplaceKeyword(type: 'one' | 'all') {
    if (this.replaceKeyword) {
      this.replaceInProgress = true;
      type === 'all' ? this.replaceAllMatches() : this.replaceSingleMatch();
    }
  }

  private replaceSingleMatch() {
    this.settings.searchViewType === ViewType.codingGrid
      ? this.replaceSingleMatchInCodingGrid()
      : this.replaceSingleMatchInCrosstab();
  }

  private setSearchHitReplacedState(target: Target, state: boolean) {
    this.searchHits.filter((hit) => hit.targetId === target.id)[0].replaced =
      state;
  }

  private replaceSingleMatchInCodingGrid() {
    const focusedSearchHit = this.searchHits.filter(
      (hit) => (hit.details as CodingGridSearchHit).focus
    );
    if (focusedSearchHit.length > 0) {
      const codingGridToChange = this.codingGridRows.filter(
        (row) =>
          row.id === (focusedSearchHit[0].details as CodingGridSearchHit).rowId
      );

      if (codingGridToChange.length > 0) {
        const field = (focusedSearchHit[0].details as CodingGridSearchHit)
          .field;
        const targetItem = codingGridToChange[0].targetItem;
        this.editTarget(codingGridToChange[0], field, this.replaceKeyword);

        if (field === CodingGridSearchField.code) {
          this.targetService
            .validateCodingStatement(
              this.targetTitlePipe.codeBuilderTransform(
                targetItem.target,
                DisplayType.coding,
                targetItem.target.titleLevels
              )
            )
            .pipe()
            .subscribe((result: UserTargetCodingResponse) => {
              if (
                result.convertedTargets[0] &&
                !result.hasOwnProperty('Warnings') &&
                !result.hasOwnProperty('Errors')
              ) {
                this.documentService.updateDocumentData([targetItem], true);
                this.onReplace();
              } else {
                this.onReplace(
                  `Failed to replace text: ${this.getCodingErrorAsString(
                    result.Errors,
                    result.Warnings
                  )}`
                );
              }
            });
        } else {
          this.setSearchHitReplacedState(targetItem.target, true);
          this.documentService.updateDocumentData([targetItem], true);
          this.onReplace();
        }
      }
    }
  }

  private replaceSingleMatchInCrosstab() {
    const focusedSearchHit: SearchHit[] = this.searchHits.filter(
      (hit) => (hit.details as CrosstabSearchHit).focus
    );

    if (focusedSearchHit.length > 0) {
      const targetItem = this.replaceCrosstabSearchHit(focusedSearchHit[0]);
      if (targetItem) {
        this.documentService.updateDocumentData([targetItem], true);
        this.onReplace();
      }
    }
  }

  private replaceCrosstabSearchHit(searchHit) {
    const hitDetails = searchHit.details as CrosstabSearchHit;

    const targetTypeToCheck =
      hitDetails.type === 'insight' ? this.crosstabRows : this.crosstabColumns;
    const targetToChangeIndex = targetTypeToCheck.findIndex(
      (row) => row.id === hitDetails.rowId
    );
    const srcWord = this.targetTitlePipe.transform(
      targetTypeToCheck[targetToChangeIndex]
    );
    targetTypeToCheck[targetToChangeIndex].ownTitle = srcWord.replace(
      new RegExp(this.findKeyword, `g${this.settings.matchCases ? '' : 'i'}`),
      this.replaceKeyword
    );
    targetTypeToCheck[targetToChangeIndex].activeTitleMode =
      DisplayType.ownTitle;

    return {
      type: hitDetails.type === 'insight' ? 2 : 1,
      target: targetTypeToCheck[targetToChangeIndex],
      index: targetToChangeIndex,
    };
  }

  private replaceAllMatches() {
    this.settings.searchViewType === ViewType.codingGrid
      ? this.replaceAllMatchesInCodingGrid()
      : this.replaceAllMatchesInCrosstab();
  }

  private replaceAllMatchesInCodingGrid() {
    this.messageService
      .openDialog(
        `Are you sure you want to replace these keyword(s)?`,
        `Replace all keywords`,
        {
          cancelText: 'Cancel',
          confirmText: 'OK',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        const targetsItemsReplaced: TargetItem[] = [];
        const targetsToValidate: Target[] = [];
        this.searchHits.forEach((hit) => {
          const matchedField = (hit.details as CodingGridSearchHit).field;
          const codingGridRow = this.codingGridRows.filter(
            (row) => row.id === (hit.details as CodingGridSearchHit).rowId
          )[0];
          const targetItem = codingGridRow.targetItem;
          targetsItemsReplaced.push(targetItem);
          this.editTarget(codingGridRow, matchedField, this.replaceKeyword);
          matchedField === CodingGridSearchField.code &&
            targetsToValidate.push(targetItem.target);
        });

        if (targetsToValidate.length === 0) {
          this.documentService.updateDocumentData(targetsItemsReplaced, true);
          this.resetSearchHits(this.findKeyword);
          this.replaceInProgress = false;
        } else {
          const validateObservables = targetsToValidate.map((target) =>
            this.targetService.validateCodingStatement(
              this.targetTitlePipe.codeBuilderTransform(
                target,
                DisplayType.coding,
                target.titleLevels
              )
            )
          );
          forkJoin(validateObservables)
            .pipe()
            .subscribe((results: UserTargetCodingResponse[]) => {
              const inValidTargetIndices = results.reduce((acc, _, index) => {
                (result.hasOwnProperty('Warnings') ||
                  result.hasOwnProperty('Errors')) &&
                  acc.push(index);
                return acc;
              }, []);

              if (inValidTargetIndices.length === 0) {
                this.documentService.updateDocumentData(
                  targetsItemsReplaced,
                  true
                );
                this.resetSearchHits(this.findKeyword);
                this.onReplace();
              } else {
                this.onReplace(
                  `Failed to replace code for ${
                    inValidTargetIndices.length
                  } rows with ID: ${inValidTargetIndices.join(', ')}`
                );
              }
            });
        }
      });
  }

  private replaceAllMatchesInCrosstab() {
    this.messageService
      .openDialog(
        `Are you sure you want to replace these keyword(s)?`,
        `Replace all keywords`,
        {
          cancelText: 'Cancel',
          confirmText: 'OK',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        const targetItems: TargetItem[] = [];
        this.searchHits.forEach((hit) => {
          const targetItem = this.replaceCrosstabSearchHit(hit);
          if (targetItem) targetItems.push(targetItem);
        });
        if (targetItems.length > 0) {
          this.documentService.updateDocumentData(targetItems, true);
          this.onReplace();
        }
      });
  }

  private editTarget(
    codingGridRow: CodingGridTableRow,
    editField: CodingGridSearchField,
    editValue: string
  ) {
    const target = codingGridRow.targetItem.target;
    const regExp = new RegExp(
      this.findKeyword,
      `g${this.settings.matchCases ? '' : 'i'}`
    );
    switch (editField) {
      case CodingGridSearchField.title:
        const srcWord = this.targetTitlePipe.transform(target);
        target.ownTitle = srcWord.replace(regExp, editValue);
        target.activeTitleMode = DisplayType.ownTitle;
        break;
      case CodingGridSearchField.code:
        target.coding = target.coding.replace(regExp, editValue);
        break;
      case CodingGridSearchField.groupName:
        target.groupName = codingGridRow.groupName.replace(regExp, editValue);
        break;
    }
  }

  private onReplace(message: string = 'Replace successful') {
    this.replaceInProgress = false;
    this.messageService.showSnackBar(message, 'OK', 10000);
  }

  private getCodingErrorAsString(errors: any, warnings: any): string {
    const codingError = warnings
      ? (Object.values(warnings)[0] as string)
      : errors && (Object.values(errors)[0] as string);
    return codingError.replace(/\/r\/n/g, 'Warning: ');
  }

  public setChangeLimitsToTrue() {
    this.limitsChanged = true;
  }

  public getChartSettings() {
    let globalChartSettings = this.documentService.findChartSettings(
      this.chartTargetMode,
      GLOBAL_SETTINGS_KEY,
      GLOBAL_SETTINGS_KEY,
      this.chartDataViewModeType
    );

    const chartSettings = this.chartList.map((chart) => {
      const { associatedTarget, associatedInsight } =
        this.getChartAssociatedTargetAndInsight(chart);
      const localChartSettings = this.documentService.findChartSettings(
        this.chartTargetMode,
        associatedTarget,
        associatedInsight,
        this.chartDataViewModeType
      );
      return localChartSettings || globalChartSettings;
    });

    return chartSettings;
  }

  private getChartAssociatedTargetAndInsight(chart): {
    associatedTarget: string;
    associatedInsight: string;
  } {
    let associatedTarget = '';
    let associatedInsight = '';

    switch (this.chartTargetMode) {
      case ChartTargetMode.single:
        associatedTarget = `${
          this.chartDataViewModeType === DataViewMode.default
            ? chart.target.id
            : chart.target.coding
        }_${CHART_SETTING_VERSION}`;
        associatedInsight = `${chart.title}_${CHART_SETTING_VERSION}`;
        break;
      case ChartTargetMode.combined:
        associatedTarget = `All targets_${CHART_SETTING_VERSION}`;
        associatedInsight = `${chart.title}_${CHART_SETTING_VERSION}`;
        break;
      case ChartTargetMode.insightsGroup:
        associatedTarget = `${chart.targetIds[0]}#insightsGroup_${CHART_SETTING_VERSION}`;
        associatedInsight = `${chart.title}_${CHART_SETTING_VERSION}`;
        break;
      case ChartTargetMode.surveysGroup:
        associatedTarget = `${chart.targetIds[0]}#surveysGroup_${CHART_SETTING_VERSION}`;
        associatedInsight = `${chart.title}_${CHART_SETTING_VERSION}`;
        break;
    }
    return {
      associatedTarget,
      associatedInsight,
    };
  }

  private getSortedTopRowTargets(
    dataItems: number[],
    targets: Target[],
    chartSettings: ChartSettings
  ) {
    const dataItemArray = cloneDeep(dataItems);
    const targetsArray = cloneDeep(targets);
    const sortedDataItemIndicesArray = Array.from(dataItemArray.keys()).sort(
      (a, b) =>
        chartSettings.columnSortOrder === 'Descending'
          ? dataItemArray[b] - dataItemArray[a]
          : dataItemArray[a] - dataItemArray[b]
    );

    return sortedDataItemIndicesArray.map((index) => targetsArray[index]);
  }
}
