import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { Observable, pipe, throwError } from 'rxjs';
import { TupApiServiceBase, TupAuthService } from '@telmar-global/tup-auth';
import { MatDialog } from '@angular/material/dialog';
import { ClusterErrorDialogComponent } from '../dialogs/cluster-error-dialog/cluster-error-dialog.component';
import { NavigationExtras, Router } from '@angular/router';
import { SurveyTimeDocument, ViewType } from '../models';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { cloneDeep } from 'lodash';
import { DocumentService } from './document.service';

const tupApiKeyEnv = environment.production ? environment : environment.engines;

@Injectable({
  providedIn: 'root',
})
export class ClusterApiService extends TupApiServiceBase {
  public currentDoc: TupDocument<SurveyTimeDocument>;
  private isDocReadonly = true;

  constructor(
    http: HttpClient,
    private userMessageService: TupUserMessageService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private router: Router,
    private authService: TupAuthService
  ) {
    super(http, tupApiKeyEnv);
  }

  public request(
    method: string,
    rootUrl: string,
    endPoint: string,
    options?: any
  ): Observable<any> {
    const url = rootUrl + endPoint;
    return super
      .request(method, url, options)
      .pipe(this.handleHttpErrorResponse(method, url, options));
  }

  private handleHttpErrorResponse(method: string, url: string, options?: any) {
    return pipe(
      catchError((error) => {
        let errorMessage =
          'There was an error trying to talk to the application server, please try again later.';
        if (error instanceof HttpErrorResponse) {
          errorMessage = error?.error?.message;
          const errorStatus = String(error.status);

          if (
            error?.url.endsWith(
              environment.api.factorCluster.endpoint.getStatus
            )
          ) {
            return throwError(errorMessage);
          }

          if (errorStatus.startsWith('4') || errorStatus.startsWith('5')) {
            return this.showFailureDialog(errorMessage);
          } else {
            return this.userMessageService
              .openMessageDialog(error?.message, 'Error')
              .afterClosed()
              .pipe(
                switchMap(() => {
                  return throwError(error);
                })
              );
          }
        } else {
          return this.userMessageService
            .openMessageDialog(error?.message, 'Error')
            .afterClosed()
            .pipe(
              switchMap(() => {
                return throwError(error);
              })
            );
        }
      })
    );
  }

  public setCurrentDoc(doc: TupDocument<SurveyTimeDocument>) {
    this.currentDoc = doc;
    this.isDocReadonly = !(
      this.authService.user.attributes.email ===
      this.currentDoc.metadata.by.attributes.email
    );
  }

  private showFailureDialog(errorMessage: string) {
    return this.dialog
      .open(ClusterErrorDialogComponent, {
        data: {
          errorMessage: errorMessage,
          isReadonly: this.isDocReadonly,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return throwError('Cancel');

          const option: NavigationExtras = {
            state: {
              isReadonly: this.isDocReadonly,
            },
            queryParams: {
              tab: ViewType.crossTab,
            },
          };

          if (result.action === ViewType.crossTab) {
            if (!this.isDocReadonly && result?.resetDoc) {
              const docToSave = cloneDeep(this.currentDoc);
              docToSave.content.apps = {
                clustering: null,
              };
              this.documentService.updateDocumentApps(docToSave);
              this.router.navigate(
                [`doc/${this.currentDoc.metadata.id}/data`],
                option
              );
            } else {
              this.router.navigate(
                [`doc/${this.currentDoc.metadata.id}/data`],
                option
              );
            }
          }

          return throwError('crosstab');
        })
      );
  }
}
