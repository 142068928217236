import { Injectable } from '@angular/core';
import {
  GenericOperatorAction,
  GenericOperatorActionContext,
} from './GenericOperatorAction';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import { MatDialog } from '@angular/material/dialog';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { CombinedCodingThresholdService } from '../services/combined-coding-threshold.service';
import { VolumetricCodingDialogComponent } from '../dialogs/volumetric-coding-dialog/volumetric-coding-dialog.component';
import { Operator } from '../models';

@Injectable({
  providedIn: 'root',
})
export class VolumetricOperatorAction extends GenericOperatorAction {
  constructor(
    protected documentService: DocumentService,
    protected targetService: TargetService,
    protected codebookSelectionService: CodebookSelectionService,
    protected combinedCodingThresholdService: CombinedCodingThresholdService,
    private dialog: MatDialog
  ) {
    super(
      documentService,
      targetService,
      codebookSelectionService,
      combinedCodingThresholdService
    );
  }

  public invoke(context: GenericOperatorActionContext): void {
    if (
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        context.selectedTargets
      )
    ) {
      this.combinedCodingThresholdService.showMaxLimitAlert();
      return;
    }

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(
        context.selectedTargets
      )
    ) {
      return;
    }

    this.dialog
      .open(VolumetricCodingDialogComponent, {
        data: {
          selectedTargets: context.selectedTargets,
          targetType: context.targetType,
        },
        width: '900px',
        closeOnNavigation: true,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result) => {
        const target = this.targetService.groupTargets(
          [result.target],
          Operator.and,
          true
        );
        this.documentService.addDocumentData(target, result.targetType, true);
      });
  }
}
