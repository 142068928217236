<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="false"
>
  <tup-mat-title-bar
    title="Create a new coding statement"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      class="builder-container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="display-container"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex="grow"
          class="display-text-field"
        >
          <mat-label>Coding statement title</mat-label>
          <textarea
            [(ngModel)]="title"
            matInput
            required
            class="display-text-field-textarea"
          ></textarea>
        </mat-form-field>
      </div>

      <div
        fxFlex
        fxLayout="column"
        fxGrow="1"
        class="manual-coding-container"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex="grow"
          class="display-text-field"
        >
          <mat-label>Editing coding statement</mat-label>
          <textarea
            [(ngModel)]="coding"
            matInput
            required
            (ngModelChange)="onCodingEdited()"
            class="display-text-field-textarea"
            data-qa="new-coding-container-textarea"
          ></textarea>
        </mat-form-field>
        <mat-error
          *ngIf="codingError"
          data-qa="new-coding-error"
          class="manual-coding-error"
          >{{ codingError }}
        </mat-error>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      class="display-container coding-preview__container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <span class="coding-preview__label">Coding statement preview:</span>
        <mat-icon
          class="coding-preview__hint"
          matTooltipPosition="above"
          matTooltip="Edit coding statement to update preview"
          >help</mat-icon
        >
      </div>

      <div class="coding-preview">
        <span data-qa="new-coding-preview">{{ coding }}</span>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
        class="coding-result"
        *ngIf="targetResult"
      >
        <div>
          <span class="coding-preview__label">Resps: </span>
          <span>{{
            !gettingCodingResult ? targetResult.resps || "null" : "..."
          }}</span>
        </div>

        <div>
          <span class="coding-preview__label">Population: </span>
          <span>{{
            !gettingCodingResult ? targetResult.population || "null" : "..."
          }}</span>
        </div>

        <button
          mat-icon-button
          color="primary"
          tupMatButton
          appearance="tiny"
          [matTooltip]="
            isResultDirty ? 'Sync audience size' : 'Audience size synced'
          "
          (click)="onSyncAudienceSize()"
          [disabled]="!isResultDirty"
        >
          <mat-icon>sync</mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onButtonClick(false)"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick(true)"
      data-qa="new-code-builder-ok-button"
    >
      OK
    </button>
  </mat-dialog-actions>
</div>
