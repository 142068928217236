import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  TitleLevelsDialogComponent,
  TitleLevelsDialogResult,
} from '../dialogs/title-levels-dialog/title-levels-dialog.component';
import { levelSeparator, Target } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TitleLevelsService {
  private numberOfTitleLevels = 0;

  // tslint:disable-next-line:variable-name
  private _titleLevels: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);
  public titleLevels$: Observable<number[]> = this._titleLevels.asObservable();

  constructor(private dialog: MatDialog) {}

  public updateNumberOfTitleLevelsByTargets(targets: Target[]): void {
    const titles: string[] = this.getTitles(targets);
    this.updateNumberOfTitleLevels(titles);
  }

  public updateNumberOfTitleLevels(titles: string[]): void {
    this.numberOfTitleLevels = this.getNumberOfTitleLevels(titles);
  }

  public getNumberOfTitleLevels(titles: string[]): number {
    return titles.reduce((previousValue: number, currentValue: string) => {
      return Math.max(currentValue.split(levelSeparator).length, previousValue);
    }, 0);
  }

  public updateTitleLevels(titleLevels: number[]): void {
    this._titleLevels.next(titleLevels);
  }

  public openDialog(data?: {
    numberOfTitleLevels?: number;
    titleLevels?: number[];
  }): Observable<TitleLevelsDialogResult> {
    return this.openRawDialog().pipe(
      filter(
        (dialogResult: TitleLevelsDialogResult) =>
          !!dialogResult?.titleLevels?.length
      )
    );
  }

  public openRawDialog(data?: {
    numberOfTitleLevels?: number;
    titleLevels?: number[];
  }): Observable<TitleLevelsDialogResult> {
    return this.dialog
      .open(TitleLevelsDialogComponent, {
        data: {
          numberOfTitleLevels:
            data?.numberOfTitleLevels || this.numberOfTitleLevels,
          titleLevels: data?.titleLevels || this._titleLevels.getValue(),
        },
      })
      .afterClosed();
  }

  private getTitles(targets: Target[], titles: string[] = []): string[] {
    return targets.reduce((previousValue: string[], currentValue: Target) => {
      if (currentValue.targets?.length) {
        return this.getTitles(currentValue.targets, previousValue);
      } else {
        previousValue.push(currentValue.title);

        return previousValue;
      }
    }, titles);
  }
}
