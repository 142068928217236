import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  ClusterDashboardComponent,
  CrossTabEditorComponent,
  DashboardComponent,
  CrossTabComponent,
  CrossTabGraphComponent,
  LoginComponent,
  PMapsComponent,
  AudienceEffectsComponent,
} from '../pages';
import { CorrespondenceAnalysisComponent } from '../pages/correspondence-analysis/correspondence-analysis.component';
import { GeografixDashboardComponent } from '../pages/geografix-dashboard/geografix-dashboard.component';
import { TupErrorPageComponent } from '@telmar-global/tup-auth';
import { FactorAnalysisComponent } from '../pages/factor-analysis/factor-analysis.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'doc/:documentId',
    component: CrossTabComponent,
    children: [
      { path: '', redirectTo: 'data', pathMatch: 'full' },
      {
        path: 'data',
        component: CrossTabEditorComponent,
      },
      {
        path: 'chart',
        component: CrossTabGraphComponent,
      },
      {
        path: 'pmaps',
        component: PMapsComponent,
      },
      {
        path: 'fa',
        component: FactorAnalysisComponent,
        data: { permissions: ['Surveytime.STATS_FactorAnalysis'] },
      },
      {
        path: 'ca',
        component: CorrespondenceAnalysisComponent,
        data: { permissions: ['Surveytime.STATS_Correspondence'] },
      },
      {
        path: 'cluster',
        component: ClusterDashboardComponent,
        data: { permissions: ['Surveytime.STATS_Clustering'] },
      },
      {
        path: 'ae',
        component: AudienceEffectsComponent,
        data: { permissions: ['Surveytime.STATS_AudienceEffects'] },
      },
      {
        path: 'geografix',
        component: GeografixDashboardComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: TupErrorPageComponent,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
