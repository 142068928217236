import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TupDocumentMetadata } from '@telmar-global/tup-document-storage';

export interface ConfirmChartStyleActionDialogContentOptions {
  documentType: 'document';
}

@Component({
  templateUrl: './confirm-chart-styles-action-dialog-content.component.html',
})
export class ConfirmChartStylesActionDialogContentComponent {
  @ViewChild('delete', { static: true, read: TemplateRef })
  public delete: TemplateRef<HTMLElement>;

  public metadata: TupDocumentMetadata;
  public options: ConfirmChartStyleActionDialogContentOptions;
}
