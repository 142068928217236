import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import {
  CHART_COLORS,
  CHART_SETTING_VERSION,
  CHART_TYPES,
  ChartFilter,
  ChartFilterOperator,
  ChartSelectState,
  ChartSettings,
  ChartSettingsMode,
  chartsInSingleRowForMultipleDatasets,
  chartsRequireShadedColorsForMultipleDatasets,
  ChartStyleSettings,
  chartsUnsuitableForMultipleDatasets,
  chartsWithTransparentBackgroundFill,
  ChartTargetMode,
  COMBINED_SETTINGS_KEY,
  DataViewMode,
  FinalChartData,
  GraphSelectionValue,
  NO_AXIS_LABEL_CHART_TYPES,
  OPTIMAL_TOP_ROWS_COUNT,
  SECONDARY_CHART_TYPES,
  SelectMenuOptionChart,
  SUPPORTED_CHART_STYLE_SETTINGS,
  SURVEYS_GROUP_DATA_KEY_MAP,
  X_AXIS_LABEL_CHART_TYPES,
  Y_AXIS_LABEL_CHART_TYPES,
} from 'src/app/models/charts.model';
import { DocumentService } from 'src/app/services/document.service';
import {
  DefaultChartStyles,
  DisplayType,
  DocumentDataState,
  Target,
} from 'src/app/models/document.model';
import { hexToRGB, provideColorsToCharts } from 'src/app/utils/colorHelper';
import {
  ChartSettingsService,
  ChartsService,
  CrosstabService,
  DialogService,
  TitleLevelsService,
} from '../../services';
import { Subject } from 'rxjs';
import { cloneDeep, isEqual, uniq } from 'lodash';
import { Sort, SortDirection } from '@angular/material/sort';
import {
  AvailableDataItem,
  DataRow,
  TupChartsComponent,
} from '@telmar-global/tup-charts';
import { ChartTitlePipe, TargetTitlePipe } from '../../pipes';
import { takeUntil } from 'rxjs/operators';
import {
  CrossTabTableDataCellMetaData,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemId,
  DataItemType,
  HAS_DATA_FLAGS_DATA_ITEM_IDS,
  Survey,
  SurveyCodeMap,
} from '../../models';
import { PptxService } from 'src/app/services/pptx.service';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';
import { getSurveyTimeProps } from '../../utils/export-utils';
import { ChartStyleService } from '../../services/chart-style.service';
import { TupUserContainerService } from '@telmar-global/tup-document-storage';
import { TupAuthService } from '@telmar-global/tup-auth';
import { ManageChartStyleService } from 'src/app/services/manage-chart-style-service';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

Chart.register(
  ...registerables,
  ChartDataLabels,
  TreemapController,
  TreemapElement,
  WordCloudController,
  WordElement
);

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {
  public exportSelected = false;
  public note = false;
  public legendWidth: string | undefined = '30%';

  public preSortedChartData;
  public finalData;
  public originalChartData: any;
  public secondaryDataItemValues;
  public multipleDatasets: boolean;
  public tableData: DataRow[] = [];
  public tableDataItemIds: number[] = [];
  public preSelectedTableDataItemIds: number[] = [];
  public availableDataItems: AvailableDataItem[] = [];
  public chartFilterOperator = ChartFilterOperator;
  public dataItemType = DataItemType;
  public shouldShowPercentage: boolean;

  public insightTitle = '';
  public targetTitle = '';
  public unsuitableMultipleCharts = chartsUnsuitableForMultipleDatasets;
  public chartsInSingleRow = chartsInSingleRowForMultipleDatasets;
  public datasetsForUnsuitableCharts = [];

  public targets: Target[] = [];
  public associatedTarget: string;
  public associatedInsight: string;
  private currentTarget: Target;

  private seriesColors: Record<string, string>;

  @Input() targetMode: ChartTargetMode;
  @Input() dataViewMode: DataViewMode;
  @Input() targetColors: Record<string, string>;
  @Input() chartData;
  @Input() isReadonly = true;
  @Input() isQuickReport = false;
  @Input() chartSettings: ChartSettings;
  @Input() dataItemKeys: Record<DataItemId, DataItem>;
  @Input() surveyCodeMap: SurveyCodeMap;
  @Input() highlightSearch: boolean;
  @Input() focusHighlightSearch: boolean;
  @Output() colorChange = new EventEmitter<boolean>();
  @Output() fullWidthNeeded = new EventEmitter<boolean>();
  @Output() scrollToChart = new EventEmitter<any>();
  @Output() selectStateChange = new EventEmitter<ChartSelectState>();

  public localChartSettings: ChartSettings;

  public primaryDataItemSelection: string;
  public primaryDataItemId: number;
  public secondaryDataItemSelection: string;
  public secondaryDataItemId: number;
  public activeGraph: SelectMenuOptionChart<GraphSelectionValue>;
  public secondaryChartType: SelectMenuOptionChart<GraphSelectionValue>;
  public xAxisLabel: string;
  public yAxisLabel: string;
  public x1AxisLabel: string;
  public y1AxisLabel: string;
  public showFlagDataItems = false;
  private hasVolumetricCoding = false;
  public sortColumns: any[];

  public sortActive = '';
  public sortDirection: SortDirection = '';

  public filtersApplied = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  // todo: temp approach to hide chart before updating it to cause tup-charts to rerender itself
  // todo: tup-charts needs changes to render itself instead of using this trick
  public refreshChart = false;
  public tempChartPlaceholderHeight = 0;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  @ViewChildren(TupChartsComponent) charts: QueryList<TupChartsComponent>;

  public defaultChartStyles: DefaultChartStyles;

  public activeSurveys: Survey[];

  constructor(
    private chartsService: ChartsService,
    private documentService: DocumentService,
    private chartSettingsService: ChartSettingsService,
    private dialogService: DialogService,
    private targetTitlePipe: TargetTitlePipe,
    private userMessageService: TupUserMessageService,
    private titleLevelsService: TitleLevelsService,
    private chartTitlePipe: ChartTitlePipe,
    private crosstabService: CrosstabService,
    private pptxService: PptxService,
    private chartStyleService: ChartStyleService,
    private userContainerService: TupUserContainerService,
    private authService: TupAuthService,
    private manageChartStyleService: ManageChartStyleService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.shouldShowPercentage = !!this.localChartSettings?.showPercentages;
    this.setActiveSurveys();
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes?.highlightSearch && delete changes['highlightSearch'];
    changes?.focusHighlightSearch && delete changes['focusHighlightSearch'];

    if (Object.keys(changes).length > 0) {
      this.setUpChart();
      this.refreshView();
    }
  }

  private setUpChart(): void {
    this.listenToDocumentStateChanges();
    this.listenToDefaultChartStylesChanges();
    this.originalChartData = cloneDeep(this.chartData);
    this.seriesColors = this.chartSettings.seriesColor;

    this.multipleDatasets = this.isMultipleDatasets(this.chartData);
    this.insightTitle = this.chartData.title;
    this.associatedInsight = `${this.insightTitle}_${CHART_SETTING_VERSION}`;

    this.handleCurrentTarget();
    this.showFlagDataItems = this.hasFlagDataItems();
  }

  public async exportSingle(): Promise<void> {
    const builder = new SurveyTimePptxBuilder(this.userMessageService);
    const documentName = this.documentService.document.metadata.name;
    const chartNote = this.documentService.findNoteByInsightTarget(
      this.associatedInsight,
      this.associatedTarget
    )[0];
    await builder.init(documentName, [
      getSurveyTimeProps(
        this,
        this.activeSurveys,
        this.surveyCodeMap,
        chartNote
      ),
    ]);

    this.pptxService.saveAs(builder, documentName);
  }

  public onFilterRemove(filter: ChartFilter): void {
    filter.dataItem = DataItemType.none;
    filter.operator = ChartFilterOperator.none;
    filter.target = 'None';
    filter.value[0] = 0;
    filter.value[1] = 0;
    this.chartSettingsService.saveChartSettings(this.localChartSettings);
    this.refreshView();
  }

  public openChartStyles(): void {
    this.manageChartStyleService.manageChartStyleModeChange(true);
    this.chartStyleService
      .openManageDialog({
        chartTargetMode: this.targetMode,
      })
      .afterClosed()
      .subscribe((styleSettings: ChartStyleSettings | null) => {
        this.setMyDrive();
        this.manageChartStyleService.manageChartStyleModeChange(false);

        this.applyChartStyle(styleSettings);
      });
  }

  public openChartFilter(): void {
    const chartSettingsMode = this.multipleDatasets
      ? ChartSettingsMode.combined
      : ChartSettingsMode.single;
    const chartWidth = '900px';
    const relatedTargets =
      this.localChartSettings.targetMode === ChartTargetMode.combined
        ? this.targets
        : [this.currentTarget];
    this.dialogService
      .chartFilters(
        chartSettingsMode,
        this.localChartSettings.filters,
        this.localChartSettings.targetMode,
        chartWidth,
        relatedTargets,
        this.chartData
      )
      .afterClosed()
      .subscribe((chartFilters: ChartFilter[] | null) => {
        if (chartFilters) {
          const chartSettings = {
            ...this.localChartSettings,
            filters: chartFilters,
          };
          this.chartSettingsService.saveChartSettings(chartSettings);
          this.refreshView();
          this.emitChartSelectedStateChange();
        }
      });
  }

  public onChartSelectedStateChanged(): void {
    this.selectStateChange.emit({
      id: `${this.targetMode}_${this.dataViewMode}_${this.associatedInsight}_${this.associatedTarget}`,
      settings: cloneDeep(this.localChartSettings),
      selected: this.exportSelected,
    });
  }

  public openChartSettings(): void {
    const prevColors = this.localChartSettings.seriesColor;
    const chartSettingsMode = this.multipleDatasets
      ? ChartSettingsMode.combined
      : ChartSettingsMode.single;
    const relatedTargets =
      this.localChartSettings.targetMode === ChartTargetMode.combined
        ? this.targets
        : [this.currentTarget];

    const chartTitle = this.chartTitlePipe.transform(
      this.localChartSettings.chartTitle,
      this.targetTitle
    );

    this.dialogService
      .chartSettings(
        chartSettingsMode,
        this.localChartSettings,
        this.targetColors,
        relatedTargets,
        this.chartData,
        this.insightTitle,
        chartTitle,
        this.isReadonly,
        this.defaultChartStyles
      )
      .afterClosed()
      .subscribe((settings: ChartSettings | null) => {
        if (settings) {
          const shouldUpdateChartFromParentView = !Object.keys(
            settings.seriesColor
          ).every((key) => settings.seriesColor[key] === prevColors[key]);
          this.chartSettingsService.saveChartSettings(settings);
          this.shouldShowPercentage =
            settings.showPercentages &&
            (settings.primaryChartType === 'tupPie' ||
              settings.primaryChartType === 'tupDonut');
          if (shouldUpdateChartFromParentView) {
            this.colorChange.emit(true);
          } else {
            this.refreshView();
          }

          this.emitChartSelectedStateChange();
        }
      });
  }

  public toggleNote(): void {
    this.refreshChart = true;
    if (this.localChartSettings.showDataTable) {
      this.localChartSettings.showDataTable = false;
      this.updateDataTableVisibility();
    }
    this.note = !this.note;
    this.notifyChartWidthChanges();
    this.cdr.detectChanges();
    this.scrollToChart.emit();
    setTimeout(() => {
      this.refreshChart = false;
    }, 0);
  }

  public openTable(): void {
    this.refreshChart = true;
    this.note = false;
    this.localChartSettings.showDataTable =
      !this.localChartSettings.showDataTable;
    this.updateDataTableVisibility();
    this.notifyChartWidthChanges();
    this.cdr.detectChanges();
    this.scrollToChart.emit();
    setTimeout(() => {
      this.refreshChart = false;
    }, 0);
  }

  public onSortChange(sort: Sort): void {
    this.tempChartPlaceholderHeight =
      this.chartContainer?.nativeElement.offsetHeight;
    this.refreshChart = true;

    this.sortActive = sort.active;
    this.sortDirection = sort.direction;
    const sortHeaderId = sort.active.split('_');
    const sortDataItemId = sortHeaderId[1]
      ? this.getDataItemKeyByDisplayName(sortHeaderId[1])
      : DataItemType.none;

    this.sortChartData(sortHeaderId[0], sortDataItemId, this.sortDirection);
    this.changeTopRows(this.localChartSettings.topRowsCount);
    this.prepareDatasets();
    this.formatTableData();

    setTimeout(() => {
      this.refreshChart = false;
    }, 0);
  }

  public onDataItemsChange(event): void {
    const selectedDataItems = event.sort();
    const previousSelectedDataItems =
      this.localChartSettings.extraTableSettings.sort();
    if (!isEqual(selectedDataItems, previousSelectedDataItems)) {
      this.localChartSettings.extraTableSettings = selectedDataItems;
      this.updateDataTableVisibility();
    }
  }

  private isMultipleDatasets(chartData: FinalChartData): boolean {
    return this.targetMode !== ChartTargetMode.single;
  }

  private updateTargetTitle(): void {
    switch (this.targetMode) {
      case ChartTargetMode.combined:
        this.targetTitle = COMBINED_SETTINGS_KEY;
        break;
      default:
        this.targetTitle = this.getTitleModeUpdatedLabel(this.chartData.target);
        break;
    }
  }

  private handleCurrentTarget(): void {
    switch (this.targetMode) {
      case ChartTargetMode.combined:
        this.associatedTarget = `${COMBINED_SETTINGS_KEY}_${CHART_SETTING_VERSION}`;
        break;
      case ChartTargetMode.insightsGroup:
      case ChartTargetMode.surveysGroup:
        this.currentTarget = this.chartData.target;
        this.associatedTarget = `${this.currentTarget.id}#${this.targetMode}_${CHART_SETTING_VERSION}`;
        break;
      default:
        this.currentTarget = this.chartData.target;
        this.associatedTarget =
          this.dataViewMode === DataViewMode.default
            ? `${this.currentTarget.id}_${CHART_SETTING_VERSION}`
            : `${this.currentTarget.coding}_${CHART_SETTING_VERSION}`;
        break;
    }
  }

  private refreshView(): void {
    this.tempChartPlaceholderHeight =
      this.chartContainer?.nativeElement.offsetHeight;
    this.refreshChart = true;
    this.formatChartSettingsAndDatasets();
    setTimeout(() => {
      this.refreshChart = false;
      this.notifyChartWidthChanges();
    }, 0);
  }

  private formatChartSettingsAndDatasets(): void {
    this.chartData = this.originalChartData;
    this.localChartSettings = this.composeChartSettings();
    this.updateTargetTitle();
    this.seriesColors = this.localChartSettings.seriesColor;
    this.updateLegendWidth();
    this.checkIfFiltersApplied();
    this.primaryDataItemId = this.localChartSettings.primaryDataItem;
    this.secondaryDataItemId = this.localChartSettings.secondaryDataItem;

    this.handleVolumetricCoding();

    this.activeGraph = this.findChartType(
      this.localChartSettings.primaryChartType
    );
    this.secondaryChartType = this.findChartType(
      this.localChartSettings.secondaryChartType
    );

    this.formatDataFlags();

    this.preSortedChartData = cloneDeep(this.chartData);

    this.sortActive =
      this.localChartSettings.sortColumn + '_' + this.primaryDataItemId;
    this.sortDirection =
      this.localChartSettings.columnSortOrder === 'Ascending'
        ? 'asc'
        : this.localChartSettings.columnSortOrder === 'Descending'
        ? 'desc'
        : '';

    const sortTargetId = this.localChartSettings.sortColumn;

    this.sortChartData(
      sortTargetId,
      this.primaryDataItemId,
      this.sortDirection
    );
    this.changeTopRows(this.localChartSettings.topRowsCount);
    this.filterChartData();
    this.formatDataFlags();
    this.prepareDatasets();
    this.formatTableData();
    this.updateSortColumns(this.localChartSettings.targetMode);
    this.updateAxisLabel();
  }

  private handleVolumetricCoding(): void {
    this.hasVolumetricCoding =
      this.chartData.cellMetadataSets.filter(
        (crossTabTableDataCellMetaData: CrossTabTableDataCellMetaData) =>
          crossTabTableDataCellMetaData.isVolumetricCoding
      ).length > 0;
    if (
      this.hasVolumetricCoding &&
      this.primaryDataItemId === DataItemType.audience
    ) {
      this.primaryDataItemSelection =
        DATA_ITEMS_MAP[this.primaryDataItemId].volumetricDisplayName;
      this.secondaryDataItemSelection =
        DATA_ITEMS_MAP[this.secondaryDataItemId].volumetricDisplayName;
    } else {
      this.primaryDataItemSelection =
        DATA_ITEMS_MAP[this.primaryDataItemId].displayName;
      this.secondaryDataItemSelection =
        DATA_ITEMS_MAP[this.secondaryDataItemId].displayName;
    }
  }

  private findChartType(
    chart: GraphSelectionValue
  ): SelectMenuOptionChart<GraphSelectionValue> {
    const chartsToSearch =
      chart === 'None' ? SECONDARY_CHART_TYPES : CHART_TYPES;
    return chartsToSearch.find(
      (type: SelectMenuOptionChart<GraphSelectionValue>) => type.value === chart
    );
  }

  private composeChartSettings(): ChartSettings {
    let localChartSettings = this.documentService.findChartSettings(
      this.targetMode,
      this.associatedTarget,
      this.associatedInsight,
      this.dataViewMode
    );
    const primaryTargetId =
      this.targetMode === ChartTargetMode.combined
        ? this.targets[0].id
        : this.currentTarget.id;
    const secondaryTargetId =
      this.targetMode === ChartTargetMode.combined
        ? this.targets.length > 1
          ? this.targets[1].id
          : primaryTargetId
        : this.currentTarget.id;

    const maxRowsCount = this.getMaxRowCount();

    if (
      localChartSettings === undefined &&
      this.targetMode in this.defaultChartStyles
    ) {
      const chartStyleSettings = this.defaultChartStyles[this.targetMode];
      if (chartStyleSettings) {
        const chartSettings = cloneDeep(this.chartSettings);
        SUPPORTED_CHART_STYLE_SETTINGS.forEach((setting) => {
          if (setting === 'topRowsCount') {
            const topRowsCount = maxRowsCount
              ? chartStyleSettings[setting] > maxRowsCount
                ? maxRowsCount
                : chartStyleSettings[setting]
              : chartStyleSettings[setting];
            chartSettings[setting] = topRowsCount;
          } else {
            chartSettings[setting] = chartStyleSettings[setting];
          }
        });
        chartSettings['maxRowsCount'] = maxRowsCount;

        const filterTarget = this.getInitialFilterTarget(
          chartSettings,
          primaryTargetId,
          secondaryTargetId
        );

        localChartSettings = {
          ...chartSettings,
          targetMode: this.targetMode,
          associatedTarget: this.associatedTarget,
          associatedInsight: this.associatedInsight,
          maxRowsCount,
          sortColumn: this.getSortColumn(
            chartSettings,
            secondaryTargetId,
            primaryTargetId
          ),
          filters: [
            {
              ...chartSettings.filters[0],
              target: filterTarget.primaryTargetId,
            },
            {
              ...chartSettings.filters[1],
              target: filterTarget.secondaryTargetId,
            },
          ],
          chartTitle: this.targetTitlePipe.transform(
            this.currentTarget,
            chartSettings.activeTitleMode,
            chartSettings.titleLevels
          ),
        };

        this.chartSettingsService.saveChartSettings(localChartSettings);
      }
    }

    if (localChartSettings) {
      let primaryDataItem = localChartSettings.primaryDataItem;
      let secondaryDataItem = localChartSettings.secondaryDataItem;
      let extraTableSettings = localChartSettings.extraTableSettings;

      if (!this.dataItemKeys[primaryDataItem]) {
        const firstAvailableDataItemId: DataItemId = parseInt(
          Object.keys(this.dataItemKeys)[0],
          10
        );
        primaryDataItem = firstAvailableDataItemId;
        extraTableSettings = [firstAvailableDataItemId];
      }

      if (!this.dataItemKeys[secondaryDataItem]) {
        secondaryDataItem = 0;
      }

      const updatedTopRowsCount =
        localChartSettings.topRowsCount <= maxRowsCount
          ? localChartSettings.topRowsCount
          : maxRowsCount;

      const filterTarget = this.getInitialFilterTarget(
        localChartSettings,
        primaryTargetId,
        secondaryTargetId
      );

      return {
        ...localChartSettings,
        topRowsCount: updatedTopRowsCount,
        primaryDataItem,
        extraTableSettings,
        secondaryDataItem,
        maxRowsCount,
        filters: [
          {
            ...localChartSettings.filters[0],
            target: filterTarget.primaryTargetId,
          },
          {
            ...localChartSettings.filters[1],
            target: filterTarget.secondaryTargetId,
          },
        ],
      };
    }

    const globalChartSettings = cloneDeep(this.chartSettings);
    const actualTopRowsCount = globalChartSettings.topRowsCount
      ? globalChartSettings.topRowsCount <= maxRowsCount
        ? globalChartSettings.topRowsCount
        : maxRowsCount
      : this.getStartUpRowCount();

    const filterTarget = this.getInitialFilterTarget(
      globalChartSettings,
      primaryTargetId,
      secondaryTargetId
    );

    return {
      ...globalChartSettings,
      targetMode: this.targetMode,
      associatedTarget: this.associatedTarget,
      associatedInsight: this.associatedInsight,
      topRowsCount: actualTopRowsCount,
      maxRowsCount,
      sortColumn: this.getSortColumn(
        globalChartSettings,
        secondaryTargetId,
        primaryTargetId
      ),
      filters: [
        {
          ...globalChartSettings.filters[0],
          target: filterTarget.primaryTargetId,
        },
        {
          ...globalChartSettings.filters[1],
          target: filterTarget.secondaryTargetId,
        },
      ],
    };
  }

  private updateSortColumns(targetMode: ChartTargetMode): void {
    this.sortColumns = this.targets.map((target: Target) => ({
      id: target.id,
      title: this.targetTitlePipe.transform(target, DisplayType.shortTitle),
    }));

    switch (targetMode) {
      case ChartTargetMode.surveysGroup:
        const surveyCode = this.chartData.surveyCodes[0];
        this.sortColumns = this.chartData.insightIds.map(
          (id: string, index: number) => ({
            id: `${id}#${surveyCode}`,
            title: this.chartData.targetTitles[index],
          })
        );
        break;
      case ChartTargetMode.insightsGroup:
        const targetId = this.chartData.targetIds[0];
        this.sortColumns = this.chartData.surveyCodes.map(
          // tslint:disable-next-line:no-shadowed-variable
          (surveyCode: string, index: number) => ({
            id: `${targetId}#${surveyCode}`,
            title: this.chartData.targetTitles[index],
          })
        );
        break;
      default:
        break;
    }
  }

  private getInitialFilterTarget(
    globalChartSettings: ChartSettings,
    primaryTargetId: string,
    secondaryTargetId: string
  ): Record<string, string> {
    const filterColumnId = this.getSortColumn(
      globalChartSettings,
      secondaryTargetId,
      primaryTargetId
    );

    const filterTarget: Record<string, string> = {};
    if (
      this.targetMode === ChartTargetMode.insightsGroup ||
      this.targetMode === ChartTargetMode.surveysGroup
    ) {
      filterTarget.primaryTargetId = filterColumnId;
      filterTarget.secondaryTargetId = filterColumnId;
    } else {
      filterTarget.primaryTargetId = primaryTargetId;
      filterTarget.secondaryTargetId = secondaryTargetId;
    }

    return filterTarget;
  }

  private getSortColumn(
    globalChartSettings: ChartSettings,
    secondaryTargetId: string,
    primaryTargetId: string
  ): string {
    let sortColumId =
      globalChartSettings.sortColumn === 'Secondary'
        ? secondaryTargetId
        : primaryTargetId;

    if (this.targetMode === ChartTargetMode.surveysGroup) {
      const insight =
        globalChartSettings.sortColumn === 'Secondary'
          ? this.chartData.insightIds.length > 1
            ? this.chartData.insightIds[1]
            : this.chartData.insightIds[0]
          : this.chartData.insightIds[0];
      const surveyCode = this.chartData.surveyCodes[0];
      sortColumId = `${insight}#${surveyCode}`;
    }

    if (this.targetMode === ChartTargetMode.insightsGroup) {
      const surveyCode =
        globalChartSettings.sortColumn === 'Secondary'
          ? this.chartData.surveyCodes.length > 1
            ? this.chartData.surveyCodes[1]
            : this.chartData.surveyCodes[0]
          : this.chartData.surveyCodes[0];
      sortColumId = `${this.chartData.targetIds[0]}#${surveyCode}`;
    }

    return sortColumId;
  }

  private formatDataFlags(): void {
    const respDataItemKey =
      DataItemType.sample in this.chartData.dataItems
        ? DataItemType.sample
        : DataItemType.filterSample in this.chartData.dataItems
        ? DataItemType.filterSample
        : DataItemType.none;
    let dataFlags = [];

    if (respDataItemKey !== DataItemType.none) {
      const respData = this.chartData.dataItems[respDataItemKey];
      dataFlags = this.multipleDatasets
        ? respData.map((dataset, index: number) =>
            this.getDataFlag(respData[index])
          )
        : this.getDataFlag(respData);
    }

    this.chartData = {
      ...this.chartData,
      dataFlags,
    };
  }

  private prepareDatasets(): void {
    this.finalData = this.preparePrimaryDatasets();
    if (this.localChartSettings.primaryChartType === 'tupScatter') {
      this.secondaryDataItemValues = this.multipleDatasets
        ? this.chartData.dataItems[this.secondaryDataItemId]
        : [this.chartData.dataItems[this.secondaryDataItemId]];
    } else if (
      !this.unsuitableMultipleCharts.includes(
        this.localChartSettings.secondaryChartType
      ) &&
      this.secondaryDataItemSelection !== 'None'
    ) {
      this.secondaryDataItemValues = this.prepareSecondaryDatasets();
    } else {
      this.secondaryDataItemValues = null;
    }
  }

  private preparePrimaryDatasets() {
    this.datasetsForUnsuitableCharts = [];
    const data = this.chartData.dataItems[this.primaryDataItemId];
    const dataFlags = this.chartData.dataFlags;
    let labels = uniq(this.chartData.labels);
    const datasets = [];
    const hasSecondaryChartType =
      this.localChartSettings.secondaryChartType !== 'None' &&
      this.localChartSettings.secondaryDataItem !== DataItemType.none;

    if (this.multipleDatasets) {
      const shouldCreateShadedColors =
        chartsRequireShadedColorsForMultipleDatasets.includes(
          this.localChartSettings.primaryChartType
        );

      data.forEach((dataset, index: number) => {
        const targetColorLength = shouldCreateShadedColors ? dataset.length : 1;
        const theBackgroundColor: string[] = [];
        const { backgroundColor } = this.getBackgroundAndCurrentColor(
          targetColorLength,
          index,
          theBackgroundColor
        );

        const formattedDataset = {
          label: this.chartData.targetTitles[index],
          data: dataset,
          backgroundColor:
            hasSecondaryChartType ||
            chartsWithTransparentBackgroundFill.includes(
              this.localChartSettings.primaryChartType
            )
              ? backgroundColor.map((color) => hexToRGB(color, '0.8'))
              : backgroundColor,
          borderColor: backgroundColor,
        };

        if (this.hasFlagDataItems()) {
          formattedDataset['dataFlag'] = dataFlags[index];
        }

        datasets.push(formattedDataset);
      });

      if (this.activeGraph.showAllDatasetLabels) {
        labels = [].concat(...Array(data.length).fill(labels));
      }
    } else {
      const currentColor = this.findTargetColor(this.currentTarget.id);
      const shouldCreateShadedColors =
        chartsUnsuitableForMultipleDatasets.includes(
          this.localChartSettings.primaryChartType
        );
      const datasetBackgroundColors = shouldCreateShadedColors
        ? provideColorsToCharts(currentColor, data.length)
        : [
            hasSecondaryChartType ||
            chartsWithTransparentBackgroundFill.includes(
              this.localChartSettings.primaryChartType
            )
              ? hexToRGB(currentColor, '0.8')
              : currentColor,
          ];
      const datasetBorderColors = shouldCreateShadedColors
        ? datasetBackgroundColors
        : [currentColor];
      const dataset = {
        label: this.chartData.targetTitles,
        data,
        backgroundColor: datasetBackgroundColors,
        borderColor: datasetBorderColors,
      };

      if (this.hasFlagDataItems()) {
        dataset['dataFlag'] = dataFlags;
      }
      datasets.push(dataset);
    }

    datasets.forEach((dataset) => {
      const clonedDataset = cloneDeep(dataset);
      if (clonedDataset.backgroundColor.length > 0) {
        clonedDataset.backgroundColor = provideColorsToCharts(
          clonedDataset.backgroundColor[0],
          dataset.data.length
        );
        clonedDataset.borderColor = provideColorsToCharts(
          clonedDataset.borderColor[0],
          dataset.data.length
        );
      }
      this.datasetsForUnsuitableCharts.push({
        labels,
        datasets: [clonedDataset],
      });
    });

    return {
      labels,
      datasets,
    };
  }

  private hasFlagDataItems(): boolean {
    return (
      HAS_DATA_FLAGS_DATA_ITEM_IDS.filter(
        (dataItemId) => this.dataItemKeys[dataItemId]
      ).length > 0
    );
  }

  private getBackgroundAndCurrentColor(
    targetColorLength: number,
    index: number,
    theBackgroundColor: string[]
  ): any {
    let currentColor;

    let currentTargetId = '';
    let newColors: string[];

    switch (this.targetMode) {
      case ChartTargetMode.surveysGroup:
        currentColor = CHART_COLORS[index % CHART_COLORS.length];
        const insightsCount = this.chartData.insightIds.length;
        theBackgroundColor = chartsUnsuitableForMultipleDatasets.includes(
          this.localChartSettings.primaryChartType
        )
          ? provideColorsToCharts(currentColor, insightsCount)
          : [currentColor];
        break;
      case ChartTargetMode.insightsGroup:
        currentTargetId = this.chartData.targetIds[0];
        currentColor = this.findTargetColor(currentTargetId);
        const surveysCount = this.chartData.surveyCodes.length;
        newColors = provideColorsToCharts(currentColor, surveysCount);
        this.chartData.labels.forEach(() => {
          theBackgroundColor.push(newColors[index]);
        });
        break;
      default:
        currentTargetId = this.chartData.targetIds[index];
        currentColor = this.findTargetColor(currentTargetId);

        theBackgroundColor = provideColorsToCharts(
          currentColor,
          targetColorLength
        );
        break;
    }

    return { backgroundColor: theBackgroundColor, currentColor };
  }

  private prepareSecondaryDatasets() {
    const data = this.chartData.dataItems[this.secondaryDataItemId];
    const dataFlags = this.chartData.dataFlags;
    const datasets = [];
    const labels = this.chartData.labels;
    if (this.multipleDatasets) {
      data.forEach((dataset, index: number) => {
        const datasetItem = {
          label: this.chartData.targetTitles[index],
          data: dataset,
        };

        if (this.hasFlagDataItems()) {
          datasetItem['dataFlag'] = dataFlags[index];
        }
        datasets.push(datasetItem);
      });
    } else {
      const datasetItem = {
        label: this.chartData.targetTitles,
        data,
      };
      if (this.hasFlagDataItems()) {
        datasetItem['dataFlag'] = dataFlags;
      }
      datasets.push(datasetItem);
    }
    return {
      labels,
      datasets,
    };
  }

  private listenToDocumentStateChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        const shouldIncludeTotalsColumn = columns.length < 1;
        this.targets = !shouldIncludeTotalsColumn
          ? columns
          : [this.chartsService.createTotalsTarget(true)];

        const titles: string[] = this.getTitles([...columns, ...rows]);

        this.titleLevelsService.updateNumberOfTitleLevels(titles);
      });
  }

  private getTitles(targets: Target[], titles: string[] = []): string[] {
    return targets.reduce((previousValue: string[], currentValue: Target) => {
      if (currentValue.targets?.length) {
        return this.getTitles(currentValue.targets, previousValue);
      } else {
        previousValue.push(currentValue.title);

        return previousValue;
      }
    }, titles);
  }

  private findTargetColor(targetId: string): string {
    if (this.seriesColors?.[targetId]) {
      return this.seriesColors[targetId];
    }

    if (this.targetColors?.[targetId]) {
      return this.targetColors[targetId];
    }

    return this.dataViewMode === DataViewMode.default
      ? CHART_COLORS[0]
      : this.seriesColors.dynamic;
  }

  private getStartUpRowCount(): number {
    const labelsLength = this.originalChartData
      ? this.originalChartData.labels.length
      : this.chartData.labels.length;
    const targetsLength = this.originalChartData
      ? Array.isArray(this.originalChartData.targetTitles)
        ? this.originalChartData.targetTitles.length
        : 1
      : Array.isArray(this.chartData.targetTitles)
      ? this.chartData.targetTitles.length
      : 1;
    const maxLabels = Math.floor(OPTIMAL_TOP_ROWS_COUNT / targetsLength);
    return maxLabels > labelsLength
      ? labelsLength === 0
        ? 1
        : labelsLength
      : maxLabels === 0
      ? 1
      : maxLabels;
  }

  private getMaxRowCount(): number {
    let maxRowCount = 0;

    switch (this.targetMode) {
      case ChartTargetMode.combined:
      case ChartTargetMode.insightsGroup:
        maxRowCount = this.originalChartData.labels.length;
        break;
      case ChartTargetMode.surveysGroup:
        maxRowCount = this.originalChartData.insightIds.length;
        break;
      default:
        const firstDataItem = Object.values(
          this.originalChartData.dataItems
        )[0] as [];
        maxRowCount = firstDataItem?.length;
        break;
    }

    return maxRowCount;
  }

  private changeTopRows(topRowsCount: number): void {
    switch (this.targetMode) {
      case ChartTargetMode.surveysGroup:
        SURVEYS_GROUP_DATA_KEY_MAP.forEach((key: string) => {
          this.chartData = {
            ...this.chartData,
            [key]: this.chartData[key].slice(0, topRowsCount),
          };
        });
        this.chartData = {
          ...this.chartData,
          dataItems: Object.keys(this.dataItemKeys).reduce(
            (acc, itemKey) => ({
              ...acc,
              [itemKey]: this.chartData.dataItems[itemKey].slice(
                0,
                topRowsCount
              ),
            }),
            {}
          ),
        };
        break;
      case ChartTargetMode.insightsGroup:
      case ChartTargetMode.combined:
        this.chartData = {
          ...this.chartData,
          labels: this.chartData.labels.slice(0, topRowsCount),
          rowTargets: this.chartData.rowTargets.slice(0, topRowsCount),
          dataItems: Object.keys(this.dataItemKeys).reduce(
            (acc, itemKey) => ({
              ...acc,
              [itemKey]: this.chartData.dataItems[itemKey].map(
                (data: number[]) => data.slice(0, topRowsCount)
              ),
            }),
            {}
          ),
        };
        break;
      default:
        Object.keys(this.chartData).forEach((key: string) => {
          if (!Array.isArray(this.chartData[key]) && key !== 'dataItems') {
            return;
          }
          this.chartData = {
            ...this.chartData,
            [key]:
              key !== 'dataItems'
                ? this.chartData[key].slice(0, topRowsCount)
                : Object.keys(this.dataItemKeys).reduce(
                    (acc, itemKey) => ({
                      ...acc,
                      [itemKey]: this.chartData[key][itemKey].slice(
                        0,
                        topRowsCount
                      ),
                    }),
                    {}
                  ),
          };
        });
        break;
    }
  }

  private getDataFlag(respData: number[]): boolean[] | undefined[] {
    return this.localChartSettings.flagRowResps
      ? respData.map(
          (resp) => resp <= this.localChartSettings.flagRowRespsValue
        )
      : respData.map(() => undefined);
  }

  private filterChartData(): void {
    const validFilters = this.getValidFilters();
    if (validFilters.length < 1) {
      return;
    }
    const indices = this.findFilteredDataIndices(validFilters, this.targetMode);
    this.chartData = {
      ...this.chartData,
      labels: indices.map((index) => this.chartData.labels[index]),
    };

    if (this.multipleDatasets) {
      this.chartData = {
        ...this.chartData,
        dataItems: Object.keys(this.dataItemKeys).reduce(
          (acc, itemKey) => ({
            ...acc,
            [itemKey]: this.chartData.dataItems[itemKey].map((dataset) =>
              indices.map((index) => dataset[index])
            ),
          }),
          {}
        ),
      };

      if (this.targetMode === ChartTargetMode.surveysGroup) {
        this.chartData = {
          ...this.chartData,
          surveyCodes: indices.map((index) => this.chartData.labels[index]),
        };
      }
    } else {
      this.chartData = {
        ...this.chartData,
        dataItems: Object.keys(this.dataItemKeys).reduce(
          (acc, itemKey) => ({
            ...acc,
            [itemKey]: indices.map(
              (index) => this.chartData.dataItems[itemKey][index]
            ),
          }),
          {}
        ),
      };
    }
  }

  private findFilteredDataIndices(
    filters: ChartFilter[],
    chartMode: ChartTargetMode
  ): number[] {
    return filters
      .map((filter: ChartFilter) => {
        let filterDateset: [];

        const filterData = filter.dataItem;
        switch (chartMode) {
          case ChartTargetMode.combined:
            filterDateset =
              this.chartData.dataItems[filterData][
                this.getTargetIndex(filter.target)
              ];
            break;
          case ChartTargetMode.insightsGroup:
            const selectedSurveyCode = filter.target.split('#')[1];
            const surveyCodes = this.chartData.surveyCodes;
            const surveyIndex =
              surveyCodes.indexOf(selectedSurveyCode) > 0
                ? surveyCodes.indexOf(selectedSurveyCode)
                : 0;
            filterDateset = this.chartData.dataItems[filterData][surveyIndex];
            break;
          case ChartTargetMode.surveysGroup:
            const insightId = filter.target.split('#')[0];
            const insightIndex = this.chartData.insightIds.indexOf(insightId);
            filterDateset = this.chartData.dataItems[filterData][insightIndex];
            break;
          default:
            filterDateset = this.chartData.dataItems[filterData];
            break;
        }

        return filterDateset.reduce(
          (prev: number[], data: number, index: number) => {
            return this.isFilterCriteriaMatched(
              data,
              filter.operator,
              filter.value
            )
              ? [...prev, index]
              : prev;
          },
          []
        );
      })
      .reduce((prev: number[], current: number[], index: number) => {
        return index > 0
          ? [...prev.filter((i) => current.includes(i))]
          : [...current];
      }, []);
  }

  private getValidFilters(): ChartFilter[] {
    return this.localChartSettings.filters.filter((filter: ChartFilter) => {
      return (
        filter.dataItem !== DataItemType.none &&
        filter.operator !== ChartFilterOperator.none &&
        filter.target !== 'None'
      );
    });
  }

  private isFilterCriteriaMatched(
    value: number,
    filterOperator: ChartFilterOperator,
    filterValues: number[]
  ): boolean {
    switch (filterOperator) {
      case ChartFilterOperator.equal:
        return value === filterValues[0];
      case ChartFilterOperator.notEqual:
        return value !== filterValues[0];
      case ChartFilterOperator.greaterThanOrEqual:
        return value >= filterValues[0];
      case ChartFilterOperator.greaterThan:
        return value > filterValues[0];
      case ChartFilterOperator.lessThanOrEqual:
        return value <= filterValues[0];
      case ChartFilterOperator.lessThan:
        return value < filterValues[0];
      case ChartFilterOperator.between:
        const max = Math.max(filterValues[0], filterValues[1]);
        const min = Math.min(filterValues[0], filterValues[1]);
        return value > min && value < max;
    }
    return false;
  }

  private sortChartData(
    sortTargetId: string,
    sortDataItemId: DataItemId,
    sortDirection: SortDirection
  ): void {
    const { labels, targetTitles } = this.getFormattedLabelsAndTargetTitles();
    if (sortDirection === '') {
      this.chartData = {
        ...this.preSortedChartData,
        labels,
        targetTitles,
      };
      return;
    }

    const sortedDataset = this.getSortedDatasets(
      sortTargetId,
      sortDataItemId,
      sortDirection
    );

    this.chartData = {
      ...this.chartData,
      targetTitles,
      labels: sortedDataset.map((dataItem) => labels[dataItem.index]),
    };

    if (this.targetMode === ChartTargetMode.surveysGroup) {
      this.chartData = {
        ...this.chartData,
        surveyCodes: sortedDataset.map(
          (dataItem) => this.chartData.labels[dataItem.index]
        ),
        labels: sortedDataset.map(
          (dataItem) => this.chartData.labels[dataItem.index]
        ),
      };
    }

    if (this.multipleDatasets) {
      this.chartData = {
        ...this.chartData,
        dataItems: Object.keys(this.dataItemKeys).reduce(
          (acc, itemKey) => ({
            ...acc,
            [itemKey]: this.chartData.dataItems[itemKey].map((dataset) =>
              sortedDataset.map((dataItem) =>
                dataset ? dataset[dataItem.index] || 0 : 0
              )
            ),
          }),
          {}
        ),
      };
      if (this.hasFlagDataItems()) {
        this.chartData['dataFlags'] = this.chartData.dataFlags.map((dataset) =>
          sortedDataset.map((dataItem) =>
            dataset ? dataset[dataItem.index] || 0 : 0
          )
        );
      }
    } else {
      this.chartData = {
        ...this.chartData,
        dataItems: Object.keys(this.dataItemKeys).reduce(
          (acc, itemKey) => ({
            ...acc,
            [itemKey]: sortedDataset.map((dataItem) => {
              return (
                (this.chartData.dataItems[itemKey] &&
                  this.chartData.dataItems[itemKey][dataItem.index]) ||
                0
              );
            }),
          }),
          {}
        ),
      };

      if (this.hasFlagDataItems()) {
        this.chartData['dataFlags'] = sortedDataset.map(
          (dataItem) => this.chartData.dataFlags[dataItem.index]
        );
      }
    }
  }

  private getSortedDatasets(
    sortTargetId: string,
    sortDataItemId: DataItemId,
    sortDirection: SortDirection
  ): { data: string | number; index: number }[] {
    let sortedDataset;
    if (sortTargetId === 'label') {
      const dataset = this.chartData.labels.map(
        (data: string, index: number) => ({
          data,
          index,
        })
      );
      sortedDataset =
        sortDirection === 'asc'
          ? dataset.sort((a, b) => {
              const l = a.data.toLowerCase();
              const m = b.data.toLowerCase();
              return l === m ? 0 : l > m ? 1 : -1;
            })
          : dataset.sort((a, b) => {
              const l = a.data.toLowerCase();
              const m = b.data.toLowerCase();
              return l === m ? 0 : l < m ? 1 : -1;
            });
    } else {
      const selectedData =
        this.getSelectedSortData(sortDataItemId, sortTargetId) || [];
      const dataset = selectedData.map((data, index: number) => ({
        data,
        index,
      }));
      sortedDataset =
        sortDirection === 'asc'
          ? dataset.sort((a, b) => a.data - b.data)
          : dataset.sort((a, b) => b.data - a.data);
    }

    return sortedDataset;
  }

  private getSelectedSortData(
    sortDataItemId: DataItemId,
    sortTargetId: string
  ): [] {
    let selectedData: [] = [];

    switch (this.targetMode) {
      case ChartTargetMode.combined:
        selectedData =
          this.chartData.dataItems[sortDataItemId][
            this.getTargetIndex(sortTargetId)
          ];
        break;
      case ChartTargetMode.surveysGroup:
        const insightId = sortTargetId.split('#')[0];
        const insightIds = this.chartData.insightIds;
        const insightIndex = insightIds.indexOf(insightId);
        selectedData = this.chartData.dataItems[sortDataItemId][insightIndex];
        break;
      case ChartTargetMode.insightsGroup:
        const selectedSurveyCode = sortTargetId.split('#')[1];
        const surveyCodes = this.chartData.surveyCodes;
        const surveyIndex =
          surveyCodes.indexOf(selectedSurveyCode) > 0
            ? surveyCodes.indexOf(selectedSurveyCode)
            : 0;
        selectedData = this.chartData.dataItems[sortDataItemId][surveyIndex];
        break;
      default:
        selectedData = this.chartData.dataItems[sortDataItemId];
        break;
    }

    return selectedData;
  }

  private formatTableData(): void {
    const tableDataColumns = this.formatTableDataColumns();
    const decimalPlaces = this.localChartSettings.decimalPlaces;

    if (!this.localChartSettings.showDataTable) {
      this.tableData = [];
      return;
    }

    if (this.targetMode === ChartTargetMode.insightsGroup) {
      this.tableData = this.chartsService.getInsightsGroupTargetTableData(
        this.chartData,
        tableDataColumns,
        decimalPlaces,
        this.hasFlagDataItems()
      );
      this.addTableOptions(tableDataColumns);
      return;
    }

    if (this.targetMode === ChartTargetMode.surveysGroup) {
      this.tableData = this.chartsService.getSurveysGroupTargetTableData(
        this.chartData,
        tableDataColumns,
        decimalPlaces,
        this.hasFlagDataItems(),
        this.surveyCodeMap
      );
      this.addTableOptions(tableDataColumns);
      return;
    }
    const columnIdVolumetricMap =
      this.crosstabService.getColumnIdVolumetricCodingMap();
    const targets =
      this.targetMode === ChartTargetMode.combined
        ? this.dataViewMode === DataViewMode.default
          ? this.targets
          : this.chartData.columnTargets
        : [this.currentTarget];

    const surveyCode = this.chartData.surveyCode;
    this.addTableOptions(tableDataColumns);

    this.tableData = this.chartData.labels.map(
      (label: string, index: number) => {
        const rowData = tableDataColumns.map((dataItemId: DataItemId) =>
          targets.map((target: Target, targetIndex: number) => {
            const tableDataItem = {
              columnHeader: this.targetTitlePipe.transform(target),
              columnHeaderId: target.id,
              secondaryColumnHeader:
                this.hasVolumetricCoding && dataItemId === DataItemType.audience
                  ? DATA_ITEMS_MAP[dataItemId].volumetricDisplayName
                  : DATA_ITEMS_MAP[dataItemId].displayName,
              value: this.multipleDatasets
                ? this.chartData.dataItems[dataItemId][targetIndex][
                    index
                  ].toFixed(decimalPlaces)
                : this.chartData.dataItems[dataItemId][index].toFixed(
                    decimalPlaces
                  ),
            };

            if (this.hasFlagDataItems()) {
              tableDataItem['flagValue'] = this.multipleDatasets
                ? this.chartData.dataFlags[targetIndex][index]
                : this.chartData.dataFlags[index];
            } else {
              tableDataItem['flagValue'] = null;
            }
            return tableDataItem;
          })
        );
        return {
          data: rowData.reduce(
            (previousValue, currentValue) => [
              ...previousValue,
              ...currentValue,
            ],
            [
              {
                columnHeader: 'Item Name',
                columnHeaderId: 'label',
                value: label,
              },
            ]
          ),
        };
      }
    );
  }

  private addTableOptions(tableDataColumns: number[]): void {
    this.preSelectedTableDataItemIds = [];
    this.availableDataItems = [];
    this.tableDataItemIds = tableDataColumns;
    this.preSelectedTableDataItemIds.push(
      this.localChartSettings.primaryDataItem
    );
    if (this.secondaryDataItemValues) {
      this.preSelectedTableDataItemIds.push(
        this.localChartSettings.secondaryDataItem
      );
    }

    for (const id in this.dataItemKeys) {
      let label = this.dataItemKeys[id].displayName;
      const dataItemId = parseInt(id, 10);
      if (this.hasVolumetricCoding && dataItemId === DataItemType.audience) {
        label = `${this.dataItemKeys[id].displayName}/${this.dataItemKeys[id].volumetricDisplayName}`;
      }

      this.availableDataItems.push({
        id: dataItemId,
        label,
      });
    }
  }

  private formatTableDataColumns(): DataItemId[] {
    const tableDataColumns = [this.localChartSettings.primaryDataItem];
    if (this.secondaryDataItemValues) {
      tableDataColumns.push(this.localChartSettings.secondaryDataItem);
    }
    this.localChartSettings.extraTableSettings.forEach((value: DataItemId) => {
      if (!tableDataColumns.includes(value)) {
        tableDataColumns.push(value);
      }
    });
    return [...new Set(tableDataColumns)];
  }

  private getTargetIndex(targetId: string): number {
    return this.targets.findIndex((target: Target) => target.id === targetId);
  }

  private notifyChartWidthChanges(): void {
    const shouldHaveFullWidth =
      this.note || this.localChartSettings.showDataTable;
    this.fullWidthNeeded.emit(shouldHaveFullWidth);
  }

  private updateLegendWidth(): void {
    this.legendWidth =
      !this.localChartSettings.showChartLegend ||
      (this.localChartSettings.showDataTable && !this.note)
        ? undefined
        : this.note
        ? '25%'
        : '30%';
  }

  private checkIfFiltersApplied(): void {
    this.filtersApplied = this.localChartSettings.filters.some(
      (filter: ChartFilter) => {
        return (
          filter.operator !== ChartFilterOperator.none &&
          filter.dataItem !== DataItemType.none &&
          filter.target !== 'None'
        );
      }
    );
  }

  private updateDataTableVisibility(): void {
    this.formatTableData();
    this.chartSettingsService.saveChartSettings(this.localChartSettings);
    this.emitChartSelectedStateChange();
  }

  private updateAxisLabel(): void {
    this.resetChartAxisLabels();
    if (
      !this.localChartSettings.showAxisLabel ||
      NO_AXIS_LABEL_CHART_TYPES.includes(
        this.localChartSettings.primaryChartType
      )
    ) {
      return;
    }

    const shouldShowSecondaryAxis =
      this.localChartSettings.secondaryChartType !== 'None' &&
      this.localChartSettings.secondaryDataItem !== DataItemType.none;

    if (
      X_AXIS_LABEL_CHART_TYPES.includes(
        this.localChartSettings.primaryChartType
      )
    ) {
      this.xAxisLabel = this.primaryDataItemSelection;
      if (shouldShowSecondaryAxis) {
        this.x1AxisLabel = this.secondaryDataItemSelection;
      }
    }

    if (
      Y_AXIS_LABEL_CHART_TYPES.includes(
        this.localChartSettings.primaryChartType
      )
    ) {
      this.yAxisLabel = this.primaryDataItemSelection;
      if (shouldShowSecondaryAxis) {
        this.y1AxisLabel = this.secondaryDataItemSelection;
      }
    }

    if (this.localChartSettings.primaryChartType === 'tupScatter') {
      this.xAxisLabel = this.primaryDataItemSelection;
      this.yAxisLabel = this.secondaryDataItemSelection;
    }
  }

  private resetChartAxisLabels(): void {
    this.xAxisLabel = undefined;
    this.yAxisLabel = undefined;
    this.x1AxisLabel = undefined;
    this.y1AxisLabel = undefined;
  }

  private getDataItemKeyByDisplayName(displayName: string): DataItemId | null {
    const dataItemKey = Object.keys(this.dataItemKeys).find(
      (key) => this.dataItemKeys[key].displayName === displayName
    );
    return !!dataItemKey ? Number(dataItemKey) : null;
  }

  private getTitleModeUpdatedLabel(target: Target): string {
    return this.targetTitlePipe.transform(
      target,
      this.localChartSettings.activeTitleMode,
      this.localChartSettings.titleLevels
    );
  }

  private getFormattedLabelsAndTargetTitles(): {
    labels: string[];
    targetTitles: string | string[];
  } {
    const rowLabels = this.chartData.rowTargets.map((target) =>
      this.getTitleModeUpdatedLabel(target)
    );
    const columnLabels = this.chartData.columnTargets.map((target) =>
      this.getTitleModeUpdatedLabel(target)
    );
    const labels =
      this.targetMode === ChartTargetMode.surveysGroup
        ? this.chartData.surveyCodes
        : rowLabels;
    let targetTitles;
    if (Array.isArray(this.chartData.targetTitles)) {
      targetTitles =
        this.targetMode === ChartTargetMode.combined
          ? columnLabels
          : this.targetMode === ChartTargetMode.surveysGroup
          ? rowLabels
          : this.targetMode === ChartTargetMode.insightsGroup
          ? this.chartData.surveyCodes.map(
              (code) => `[${code}]${columnLabels[0]}`
            )
          : labels;
    } else {
      targetTitles = this.getTitleModeUpdatedLabel(this.chartData.target);
    }
    return {
      labels,
      targetTitles,
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setMyDrive() {
    const currentContainer = this.userContainerService.getContainer();
    const user = this.authService.user;
    const userEmail = user.attributes.email;
    const usersContainer = this.authService.user.containers.find(
      (container) => container.name === userEmail
    );
    if (currentContainer && usersContainer.name !== currentContainer.name) {
      this.userContainerService.setContainer(usersContainer);
    }
  }

  private emitChartSelectedStateChange(): void {
    if (this.exportSelected) {
      this.onChartSelectedStateChanged();
    }
  }

  private applyChartStyle(chartStyleSettings: ChartStyleSettings) {
    if (chartStyleSettings) {
      const chartSettings = cloneDeep(this.localChartSettings);

      SUPPORTED_CHART_STYLE_SETTINGS.forEach((setting) => {
        if (setting === 'topRowsCount') {
          const topRowsCount = chartSettings.maxRowsCount
            ? chartStyleSettings[setting] > chartSettings.maxRowsCount
              ? chartSettings.maxRowsCount
              : chartStyleSettings[setting]
            : chartStyleSettings[setting];
          chartSettings[setting] = topRowsCount;
        } else {
          chartSettings[setting] = chartStyleSettings[setting];
        }
      });
      this.applyChartSettings(chartSettings);
    }
  }

  private applyChartSettings(settings: ChartSettings | null) {
    if (settings) {
      const prevColors = this.localChartSettings.seriesColor;

      const shouldUpdateChartFromParentView = !Object.keys(
        settings.seriesColor
      ).every((key) => settings.seriesColor[key] === prevColors[key]);
      this.chartSettingsService.saveChartSettings(settings);
      this.shouldShowPercentage =
        settings.showPercentages &&
        (settings.primaryChartType === 'tupPie' ||
          settings.primaryChartType === 'tupDonut');
      if (shouldUpdateChartFromParentView) {
        this.colorChange.emit(true);
      } else {
        this.refreshView();
      }

      this.emitChartSelectedStateChange();
    }
  }

  private listenToDefaultChartStylesChanges() {
    this.chartStyleService.defaultChartStyles$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((defaultChartStyles: DefaultChartStyles) => {
        this.defaultChartStyles = defaultChartStyles;
      });
  }

  private setActiveSurveys() {
    if (
      [ChartTargetMode.single, ChartTargetMode.combined].includes(
        this.targetMode
      )
    ) {
      const surveyCode = this.chartData.surveyCode
        ? this.chartData.surveyCode[0]
        : this.chartData.surveyCodes[0];

      this.activeSurveys = [
        this.documentService
          .getVisibleSurveys()
          .find((survey) => survey.code === surveyCode),
      ];
    } else if (
      [ChartTargetMode.insightsGroup, ChartTargetMode.surveysGroup].includes(
        this.targetMode
      )
    ) {
      this.activeSurveys = this.documentService.getVisibleSurveys();
    }
  }
}
