<ng-container
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Audience effects settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
    >
      close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
        fxFill
        class="input-form-field"
      >
        <mat-label>Chart title</mat-label>
        <input
          *ngIf="viewType === audienceEffectsViewType.variable"
          matInput
          [(ngModel)]="settings.variableSelectionTitle"
        />
        <input
          *ngIf="
            viewType === audienceEffectsViewType.results &&
            resultMode === audienceEffectsResultMode.chaid
          "
          matInput
          [(ngModel)]="settings.chiadAnalysisTitle"
        />
        <input
          *ngIf="
            viewType === audienceEffectsViewType.results &&
            resultMode === audienceEffectsResultMode.gain
          "
          matInput
          [(ngModel)]="settings.gainChartTitle"
        />
      </mat-form-field>

      <mat-checkbox
        fxFlex="100"
        fxFill
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox margin-adjustment"
        [(ngModel)]="settings.generateAndIncludeNotVariables"
      >
        Generate and include ‘NOT’s of all input variables for analysis
      </mat-checkbox>

      <div
        fxFlex="100"
        fxFill
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
      >
        <span> Filter CHAID Tree by </span>

        <mat-form-field
          tupMatFormField
          appearance="outline"
          class="input-form-field-without-label filter-chaid-tree-input"
        >
          <input
            type="number"
            min="0"
            max="100"
            matInput
            [(ngModel)]="settings.filterChaidTreePercent"
          />
          <span matSuffix>%</span>
        </mat-form-field>

        <span> of objective </span>
      </div>
    </div>

    <ng-container>
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">Criteria</h3>

      <div
        fxLayout="row"
        class="margin-adjustment"
      >
        You may change various settings like data items, deviations and maximise
        or minimize optimisations.
      </div>

      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
        >
          <ng-container matColumnDef="objective">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Objective
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ element.variables }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dataItem">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Data item
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                class="input-form-field-without-label"
              >
                <mat-select
                  [(ngModel)]="settings.criteria[element.id - 1].data_item_id"
                  (ngModelChange)="onDataItemChange(element.id - 1)"
                >
                  <ng-container *ngFor="let dataItem of dataItems">
                    <mat-option
                      *ngIf="
                        (element.id - 1 === 0 &&
                          settings.criteria[1].data_item_id !==
                            dataItem.value) ||
                        (element.id - 1 === 1 &&
                          settings.criteria[0].data_item_id !== dataItem.value)
                      "
                      [value]="dataItem.value"
                    >
                      {{ dataItem.title }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="minObserved">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Min observed
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ settings.criteria[element.id - 1].min_observed }}
            </td>
          </ng-container>

          <ng-container matColumnDef="maxObserved">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Max observed
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ settings.criteria[element.id - 1].max_observed }}
            </td>
          </ng-container>

          <ng-container matColumnDef="minCutoff">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Min cut-off
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <mat-form-field
                tupMatFormField
                appearance="outline"
                class="input-form-field-without-label"
              >
                <input
                  type="number"
                  min="0"
                  max="100"
                  matInput
                  [(ngModel)]="settings.criteria[element.id - 1].lower_cutoff"
                  (change)="onInputValueChange(element.id - 1, 'lower_cutoff')"
                  (keydown)="onInputKeydown($event)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="maxCutoff">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Max cut-off
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <mat-form-field
                tupMatFormField
                appearance="outline"
                class="input-form-field-without-label"
              >
                <input
                  type="number"
                  min="0"
                  max="100"
                  matInput
                  [(ngModel)]="settings.criteria[element.id - 1].upper_cutoff"
                  (change)="onInputValueChange(element.id - 1, 'upper_cutoff')"
                  (keydown)="onInputKeydown($event)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="tolerance">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Tolerance
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <mat-form-field
                tupMatFormField
                appearance="outline"
                class="input-form-field-without-label"
              >
                <input
                  type="number"
                  min="0"
                  max="100"
                  matInput
                  [(ngModel)]="settings.criteria[element.id - 1].deviation"
                  (change)="onInputValueChange(element.id - 1, 'deviation')"
                  (keydown)="onInputKeydown($event)"
                />
                <span matSuffix>%</span>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Action
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                class="input-form-field-without-label"
              >
                <mat-select
                  [(ngModel)]="settings.criteria[element.id - 1].action_id"
                >
                  <mat-option
                    *ngFor="let action of actions"
                    [value]="action.value"
                  >
                    {{ action.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </ng-container>

    <ng-container *ngIf="shouldShowChartSetup">
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">Chart view set up</h3>

      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <div
          *ngIf="viewType === audienceEffectsViewType.variable"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          fxFill
        >
          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showDataLabelInVariableSelection"
          >
            Show data label
          </mat-checkbox>

          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showAxisLabelInVariableSelection"
          >
            Show axis label
          </mat-checkbox>

          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showLegendInVariableSelection"
          >
            Show legend
          </mat-checkbox>
        </div>

        <div
          *ngIf="shouldShowGainPlotConfig"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          fxFill
        >
          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showDataLabelInGainChart"
          >
            Show data label
          </mat-checkbox>

          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showAxisLabelInGainChart"
          >
            Show axis label
          </mat-checkbox>

          <mat-checkbox
            *ngIf="settings.gainPlotChartType === gainPlotChartType.bar"
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showLegendInGainChart"
          >
            Show legend
          </mat-checkbox>
        </div>

        <div
          *ngIf="shouldShowHighlightColor"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          fxFill
        >
          <mat-checkbox
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox margin-adjustment"
            [(ngModel)]="settings.showHAccumulatedHighlightColor"
          >
            Show accumulated highlight color
          </mat-checkbox>

          <div
            fxFlex="100"
            fxLayout="row"
            fxLayoutGap="10px"
            class="margin-adjustment"
            [class.is-disabled]="!settings.showHAccumulatedHighlightColor"
          >
            <button
              *ngFor="let color of colors; let colorIndex = index"
              mat-mini-fab
              class="color-dot"
              [ngStyle]="{ 'background-color': color.color }"
              [ngClass]="{ 'color-dot-border': color.selected }"
              (click)="changeColor(colorIndex, color.color)"
              [disableRipple]="true"
            ></button>
            <button
              mat-mini-fab
              (click)="colorPickerDialog()"
              onclick="this.blur()"
              class="color-dot add-color-dot"
              [ngStyle]="{ 'background-color': 'white' }"
            >
              <mat-icon class="tiny-icon">add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="shouldShowGainPlotConfig">
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">
        Gain plot analysis
      </h3>

      <div fxLayout="row">
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Gain plot analysis default chart</mat-label>
          <mat-select [(ngModel)]="settings.gainPlotChartType">
            <mat-option
              *ngFor="let chartType of gainPlotChartTypes"
              [value]="chartType.value"
            >
              {{ chartType.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</ng-container>
