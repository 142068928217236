export const truncate = (
  text: string,
  length: number = 20,
  suffix: string = '...'
): string => {
  return text && text.length > length
    ? text.substring(0, length).trim() + suffix
    : text;
};

export const parseNumberWithThousandSeparator = (input: string): number => {
  return Number(input.replace(/,/g, ''));
};
