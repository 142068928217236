import Highcharts, { SVGElement } from 'highcharts';
import { Survey } from './survey.model';
import { MappingOptions } from '../dialogs';
import { DisplayType, Target } from './document.model';

export const PMAPS_VERSION = `v1`;

export interface PMapsPreferences {
  survey: Survey;
  settings: PMapsSettings;
  mappingOptions: MappingOptions;
  activeTitleMode: DisplayType;
  titleLevels?: number[];
  version: string;
}

export interface PMapsSettings {
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  showWeightedSymbols: boolean;
  showDataLabel: boolean;
  displayGridlines: boolean;
  displayQuadrantTitles: boolean;
  quadrant1: string;
  quadrant2: string;
  quadrant3: string;
  quadrant4: string;
  showPlotLines: boolean;
  data: PMapsSeriesDataItem[];
}

export interface PMapsSeriesDataItem {
  id: number;
  x: number | undefined;
  y: number | undefined;
  z: number | undefined; // used to indicate weight and the size of a bubble
  title: string;
  target: Target;
  color: string;
  visible: boolean;
}

export interface ChartMappingOptionsData {
  xAxisLabel: string;
  yAxisLabel: string;
  data: PMapsSeriesDataItem[];
}

export interface ExtendedPmapChart extends Highcharts.Chart {
  quadrantTitles: SVGElement[];
  options: ExtendedPmapOptions;
}

export interface ExtendedPmapOptions extends Highcharts.Options {
  annotationPositionMap: Record<number, AnnotationPosition>;
  quadrantTitles: {
    enabled: boolean;
    quadrant1: string;
    quadrant2: string;
    quadrant3: string;
    quadrant4: string;
  };
}

export interface AnnotationPosition {
  x: number;
  y: number;
}
