<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Highlight values"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutAlign="space-evenly stretch"
      fxLayoutGap="20px"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="20px"
      >
        <div
          fxLayout="column"
          fxFlex="50"
          fxLayoutAlign="center center"
          fxLayoutGap="20px"
        >
          <div
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="center center"
            class="data-item-container"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex="100"
              class="input-form-field"
              data-qa="highlight-data-item-select"
            >
              <mat-label>Primary data-item</mat-label>
              <mat-select [(ngModel)]="chosenDataItemId">
                <mat-option
                  *ngFor="let dataItem of dataItems"
                  [value]="dataItem.id"
                  data-qa="highlight-data-item-select-option"
                >
                  {{ dataItem.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button
              mat-mini-fab
              [ngStyle]="{ 'background-color': chosenColor }"
              [disableRipple]="true"
              onclick="this.blur()"
              (click)="colorPickerDialog()"
              class="change-color-button"
              matTooltip="Change highlight color"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-hint *ngIf="chosenDataItemId === dataItemType.audience">{{
              dataItemsMap[chosenDataItemId]?.description
            }}</mat-hint>
          </div>
        </div>

        <div
          fxLayout="row"
          fxFlex="50"
          fxLayoutGap="5px"
          fxLayoutAlign="center center"
          class="color-gradient-container"
        >
          <p class="min-max">Min</p>
          <button
            *ngFor="let color of gradientColors"
            class="final-colors"
            mat-mini-fab
            [ngStyle]="{ 'background-color': color }"
            [disableRipple]="true"
          ></button>
          <p class="min-max">Max</p>
        </div>
      </div>

      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">
        Report highlighting preferences
      </h3>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <p class="highlight-text">
          Choose the range of data to highlight the data in:
        </p>
        <mat-radio-group
          [(ngModel)]="type"
          fxLayout="row"
          fxLayoutGap="5px"
        >
          <mat-radio-button
            *ngFor="let highlightType of highlightTypes"
            [value]="highlightType.value"
          >
            {{ highlightType.title }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onApplyClick()"
    >
      Apply
    </button>
  </mat-dialog-actions>
</div>
