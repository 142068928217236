import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { SelectMenuOption } from '../../models';
import {
  CorrespondenceFilterOptions,
  CorrespondenceSortColumnType,
} from '../../models';

@Component({
  templateUrl: './correspondence-filter-dialog.component.html',
  styleUrls: ['./correspondence-filter-dialog.component.scss'],
})
export class CorrespondenceFilterDialogComponent implements OnInit {
  public settings: CorrespondenceFilterOptions;

  public sortColumns: SelectMenuOption<CorrespondenceSortColumnType>[] = [
    {
      title: '%INF',
      value: CorrespondenceSortColumnType.percentINF,
    },
    {
      title: 'Factor1',
      value: CorrespondenceSortColumnType.factor1,
    },
    {
      title: 'Factor2',
      value: CorrespondenceSortColumnType.factor2,
    },
    {
      title: 'Factor3',
      value: CorrespondenceSortColumnType.factor3,
    },
    {
      title: 'Factor4',
      value: CorrespondenceSortColumnType.factor4,
    },
    {
      title: 'Factor5',
      value: CorrespondenceSortColumnType.factor5,
    },
    {
      title: 'Factor6',
      value: CorrespondenceSortColumnType.factor6,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CorrespondenceFilterOptions,
    public dialogRef: MatDialogRef<CorrespondenceFilterDialogComponent>
  ) {
    this.settings = cloneDeep(data);
  }

  ngOnInit(): void {}

  public onVisibleColumnsOrRowsValueChange(
    key: 'visibleColumns' | 'visibleRows'
  ): void {
    if (this.isInvalidNoneNegativeNumber(this.settings[key])) {
      this.settings[key] = 0;
    }
  }

  public onFilterValueInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  private isInvalidNoneNegativeNumber(value: any): boolean {
    return value < 0 || value === null || isNaN(value);
  }

  public onApplyButtonClick(): void {
    this.dialogRef.close(this.settings);
  }

  public close(): void {
    this.dialogRef.close(null);
  }
}
