import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { TupApiServiceBase } from '@telmar-global/tup-auth';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorHandlingDialogComponent } from '../dialogs/error-handling-dialog/error-handling-dialog.component';

const tupApiKeyEnv = environment.production ? environment : environment.engines;

@Injectable({
  providedIn: 'root',
})
export class ApiService extends TupApiServiceBase {
  constructor(
    http: HttpClient,
    private messageService: TupUserMessageService,
    private dialog: MatDialog
  ) {
    super(http, tupApiKeyEnv);
  }

  request(
    method: string,
    rootUrl: string,
    endPoint: string,
    options?: any
  ): Observable<any> {
    const url = rootUrl + endPoint;
    return super
      .request(method, url, options)
      .pipe(this.handleHttpErrorResponse(method, url, options));
  }

  handleHttpErrorResponse(method: string, url: string, options?: any) {
    return pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          let errorMessage = null;

          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: "${error.error.message}"`;
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (error.status === 401) {
              errorMessage = `An authorization error occurred: "${error.error.message}"`;
            }

            // log developer-friendly error message
            errorMessage = `There was an error communicating with app server: "${error}" Triggered while trying to reach: ${url} with ${method}. HttpClient options: ${JSON.stringify(
              options
            )}`;

            console.error(errorMessage);
            this.openErrorHandlingDialog(errorMessage);
          }
        } else {
          this.messageService.openMessageDialog(error, 'Error');
        }
        // return an observable with a user-friendly error message
        return throwError(
          'There was an error trying to talk to the application server, please try again later.'
        );
      }) // catchError
    ); // pipe
  }

  responseTimer(startTime: number = new Date().getTime()): () => number {
    return (): number => new Date().getTime() - startTime;
  }

  public openErrorHandlingDialog(
    message: string
  ): MatDialogRef<ErrorHandlingDialogComponent> {
    return this.dialog.open(ErrorHandlingDialogComponent, {
      data: { message },
      maxWidth: '800px',
      width: '800px',
      closeOnNavigation: true,
      autoFocus: false,
    });
  }
}
