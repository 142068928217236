import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FactorAnalysisSettings,
  FATableHighlightOption,
  FATableHighlightOptionItem,
} from '../../models';

@Component({
  templateUrl: './factor-analysis-multi-sort-dialog.component.html',
  styleUrls: ['./factor-analysis-multi-sort-dialog.component.scss'],
})
export class FactorAnalysisMultiSortDialogComponent implements OnInit {
  public highlightOption: FATableHighlightOptionItem;
  public threshold: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FactorAnalysisSettings,
    public dialogRef: MatDialogRef<FactorAnalysisMultiSortDialogComponent>
  ) {
    this.highlightOption =
      data.tableHighlightOptions[FATableHighlightOption.moderate];
    this.threshold = this.highlightOption.threshold;
  }

  ngOnInit(): void {}

  public onThresholdValueChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.threshold)) {
      this.threshold = 0;
    }
  }

  public onFilterValueInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  private isInvalidNoneNegativeNumber(value: any): boolean {
    return value < 0 || value === null || isNaN(value);
  }

  public onApplyButtonClick(): void {
    this.dialogRef.close(this.threshold);
  }

  public close(): void {
    this.dialogRef.close(null);
  }
}
