<div
  class="table-container"
  #tableCardContainer
>
  <div class="audience-effects-variable-table-viewport-container">
    <div [style.height.px]="scrollViewportHeight">
      <cdk-virtual-scroll-viewport
        tvsItemSize="37"
        headerEnabled="true"
        bufferMultiplier="4"
        headerHeight="48"
        class="virtual-scroll-viewport"
      >
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="non-standard-table audience-effects-variable-table"
          (matSortChange)="sortData($event)"
        >
          <ng-container
            *ngFor="let column of displayedColumns"
            matColumnDef="{{ column }}"
          >
            <ng-container *ngIf="column === 'recommended'">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="table-header-cell text-align-center"
              >
                <mat-checkbox
                  tupMatCheckbox
                  appearance="small"
                  color="primary"
                  [checked]="isAllSelected()"
                  (change)="onAllRowsSelectedChange($event)"
                  [indeterminate]="selectedRows > 0 && !isAllSelected()"
                ></mat-checkbox>
              </th>
            </ng-container>
            <ng-container *ngIf="column !== 'recommended'">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="table-header-cell"
              >
                {{ columnMap[column]["name"] }}
              </th>
            </ng-container>
            <td
              mat-cell
              *matCellDef="let element"
              [title]="column !== 'recommended' ? element[column] : ''"
              [class.text-align-left]="columnMap[column]['align'] === 'left'"
              [class.text-align-center]="
                columnMap[column]['align'] === 'center'
              "
              [class.text-align-right]="columnMap[column]['align'] === 'right'"
              [style.background-color]="
                column === 'rowNumber'
                  ? variableTypeMap[element['type']].backgroundColor
                  : ''
              "
            >
              <div *ngIf="column === 'recommended'; else otherColumn">
                <mat-checkbox
                  tupMatCheckbox
                  appearance="small"
                  color="primary"
                  [checked]="selectedRowVariables[element.coding]"
                  (change)="onSingleRowSelectedChange($event, element)"
                ></mat-checkbox>
              </div>
              <ng-template #otherColumn>
                {{ element[column] | truncate: 60 }}
              </ng-template>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            class="row-container"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (mouseenter)="onRowMouseEnter(row)"
            (mouseleave)="onRowMouseLeave(row)"
            [class.is-hover-row]="hoveringRow?.rowNumber === row.rowNumber"
            [class.is-hidden]="!row.recommended"
          ></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
