import { Injectable } from '@angular/core';

import {
  TupPptxBuilder,
  TupPptxExporterService,
} from '@telmar-global/tup-document-exporter';
import { GoogleDriveFileInfo } from '@telmar-global/tup-google-api';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { DialogService } from './dialog.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PptxService {
  private _isExportingSubject = new BehaviorSubject<boolean>(false);
  public isExporting$: Observable<boolean> =
    this._isExportingSubject.asObservable();

  constructor(
    private exporterService: TupPptxExporterService,
    private dialogService: DialogService,
    private messageService: TupUserMessageService
  ) {}

  public async saveAs(
    builder: TupPptxBuilder,
    filename?: string
  ): Promise<void> {
    await this.exporterService.saveAs(
      this.exporterService.build(builder),
      filename
    );
  }

  public async exportToSlides(
    builder: TupPptxBuilder,
    filename?: string
  ): Promise<void> {
    this._isExportingSubject.next(true);

    this.exporterService
      .exportToSlides(this.exporterService.build(builder), filename)
      .then((fileInfo: GoogleDriveFileInfo) => {
        this.dialogService.exportedGoogleFile(fileInfo.webViewLink);
      })
      .catch((error) => {
        console.error(error);
        this.alertExportError(error);
      })
      .finally(() => {
        this._isExportingSubject.next(false);
      });
  }

  private alertExportError(error: string): void {
    console.log(`error`, error);
    this.messageService.showSnackBar(error, 'OK');
  }
}
