import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { first } from 'rxjs/operators';
import {
  DocumentDataState,
  MAX_COLUMN_TARGET_LIMIT,
  Target,
  APPLICATION_NAME,
} from '../models';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

@Injectable({
  providedIn: 'root',
})
export class SwapRowsColumnsAction extends ActionDefinition<void> {
  constructor(
    private documentService: DocumentService,
    private messageService: TupUserMessageService
  ) {
    super();
  }

  public invoke(): void {
    const rows = this.getDocumentRows();

    const hasReachedMaxColumnsLimit = rows.length > MAX_COLUMN_TARGET_LIMIT;
    if (hasReachedMaxColumnsLimit) {
      this.showMaxLimitAlert(rows.length);
      return;
    }

    this.documentService.swapColumnsAndRows();
  }

  private getDocumentRows(): Target[] {
    let documentRows: Target[];
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ rows }: DocumentDataState) => {
        documentRows = rows;
      });
    return documentRows;
  }

  private showMaxLimitAlert(selectedCount: number): void {
    this.messageService.openMessageDialog(
      `Sorry! You cannot swap ${selectedCount} rows.
          Maximum allowed limit for columns is ${MAX_COLUMN_TARGET_LIMIT}.`,
      APPLICATION_NAME
    );
  }
}
