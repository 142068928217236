import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import {
  ALL_RESPONDENTS_CODING,
  CleaningMethod,
  CleaningMethodOptionsItem,
  ClusterData,
  ClusterDataItemType,
  ClusterFeatureItem,
  ClusterItem,
  ClusterSolutionCluster,
  ClusterTotalVariables,
  ClusterVariableRowData,
  DEFAULT_MAX_NMU_OF_CLUSTERS,
  DEFAULT_NMU_OF_FACTORS,
  GetClustersRequestBody,
  GetClustersResponseBody,
  GetResearchStatusResponseBody,
  HighlightSegment,
  HighlightType,
  ResearchStatus,
  SaveClusteringRequestBody,
  SaveClusteringResponseBody,
  StartClusteringRequestBody,
  StartClusteringResponseBody,
  STATUS_POLLING_TIME,
  Target,
  UpdateClusteringRequestBody,
  UpdateClusteringResponseBody,
} from '../models';
import { environment } from 'src/environments/environment';
import { startWith, switchMap, takeWhile } from 'rxjs/operators';
import { ResearchService } from './research.service';
import { orderBy } from 'lodash';
import { ClusterApiService } from './cluster-api.service';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  private readonly segments = 3;

  constructor(
    private apiService: ClusterApiService,
    private researchService: ResearchService
  ) {}

  public transposeClustersData(
    clusters: ClusterSolutionCluster[],
    dataItem: ClusterDataItemType
  ): ClusterItem[][] {
    return clusters[0].data[dataItem].map((_, columnIndex) => {
      return clusters.map((row, rowIndex) => {
        const rawValue = row.data[dataItem][columnIndex];
        return {
          value: rawValue === null ? 0 : parseFloat(rawValue?.toFixed(2)),
        };
      });
    });
  }

  public highlightClustersData(
    data: ClusterItem[][],
    shouldShowHighlighting: boolean,
    highlightType: HighlightType
  ): ClusterItem[][] {
    if (!shouldShowHighlighting) {
      return data;
    }

    switch (highlightType) {
      case HighlightType.table:
        return this.highlightClustersDataByTable(data);
      case HighlightType.variable:
        return this.highlightClustersDataByVariable(data);
      case HighlightType.cluster:
        return this.highlightClustersDataByCluster(data);
    }
  }

  public createClustering(
    clusterData: ClusterData,
    table: Target
  ): Observable<StartClusteringResponseBody> {
    const fiveAsHighestSurveyProviders = ['Vividata', 'Numeris'];
    const body: StartClusteringRequestBody = {
      'respondent-filter': table?.coding || ALL_RESPONDENTS_CODING,
      'survey-code': clusterData.survey.code,
      'survey-version': clusterData.survey.meta?.[
        'survey-instance-version'
      ] as string,
      features: clusterData.rows
        .filter((row: ClusterFeatureItem) => row.selected)
        .map((row: ClusterFeatureItem) => row.coding),
      'cleaning-method': CleaningMethod.cleanDrop,
      'num-of-factors': DEFAULT_NMU_OF_FACTORS,
      'max-num-of-clusters': DEFAULT_MAX_NMU_OF_CLUSTERS,
      'data-treatment': fiveAsHighestSurveyProviders.includes(
        clusterData.survey.provider
      )
        ? '5 as highest'
        : '1 as highest',
    };
    return this.startClustering(body);
  }

  public updateClustering(
    researchId: string,
    cleaningMethod = CleaningMethod.cleanDrop,
    cleaningMethodOptions?: CleaningMethodOptionsItem
  ): Observable<UpdateClusteringResponseBody> {
    const respondentThreshold =
      cleaningMethodOptions?.cleanDropRespondentsValidValuesThreshold;
    const featuresThreshold =
      cleaningMethodOptions?.cleanDropFeaturesValidValuesThreshold;

    const body: UpdateClusteringRequestBody = {
      'research-session-id': researchId,
      'cleaning-method': cleaningMethod,
      'num-of-factors': DEFAULT_NMU_OF_FACTORS,
      'max-num-of-clusters': DEFAULT_MAX_NMU_OF_CLUSTERS,
      ...(cleaningMethodOptions && {
        'cleaning-method-options': {
          ...(respondentThreshold !== null &&
            respondentThreshold !== undefined && {
              'clean-drop-respondents-valid-values-threshold':
                respondentThreshold,
            }),
          ...(featuresThreshold !== null &&
            featuresThreshold !== undefined && {
              'clean-drop-respondents-valid-values-threshold':
                featuresThreshold,
            }),
        },
      }),
    };
    return this.startClustering(body);
  }

  private startClustering(
    body: StartClusteringRequestBody | UpdateClusteringRequestBody
  ): Observable<StartClusteringResponseBody | UpdateClusteringResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.startClustering,
      { body }
    );
  }

  public getClusterStatus(
    researchId: string
  ): Observable<GetResearchStatusResponseBody> {
    return interval(STATUS_POLLING_TIME).pipe(
      startWith(0),
      switchMap(() => this.researchService.getStatus(researchId)),
      takeWhile(
        (statusResult: GetResearchStatusResponseBody) =>
          statusResult.status === ResearchStatus.onGoing,
        true
      )
    );
  }

  public getClusters(researchId: string): Observable<GetClustersResponseBody> {
    const body: GetClustersRequestBody = {
      'research-session-id': researchId,
      'cleaning-method': CleaningMethod.cleanDrop,
      'num-of-factors': DEFAULT_NMU_OF_FACTORS,
      'max-num-of-clusters': DEFAULT_MAX_NMU_OF_CLUSTERS,
    };
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.getClusters,
      { body }
    );
  }

  public saveClustering(
    researchId: string,
    numberOfClusters: number
  ): Observable<SaveClusteringResponseBody> {
    const body: SaveClusteringRequestBody = {
      'research-session-id': researchId,
      'cleaning-method': CleaningMethod.cleanDrop,
      'num-of-factors': DEFAULT_NMU_OF_FACTORS,
      'num-of-clusters': numberOfClusters,
    };
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.saveClustering,
      { body }
    );
  }

  public formatVariableImportanceData(
    featureItems: ClusterFeatureItem[],
    variableTotal: ClusterTotalVariables
  ): ClusterVariableRowData[] {
    const selectedFormattedData = featureItems
      .filter((feature: ClusterFeatureItem) => feature.selected)
      .map((feature: ClusterFeatureItem, index: number) => ({
        ...feature,
        maxDetermination: variableTotal['max-determinations'][index],
      }));
    const unselectedData = featureItems
      .filter((feature: ClusterFeatureItem) => !feature.selected)
      .map((feature: ClusterFeatureItem) => ({
        ...feature,
        maxDetermination: '',
      }));

    return [
      ...orderBy(selectedFormattedData, ['maxDetermination'], 'desc'),
      ...unselectedData,
    ];
  }

  private highlightClustersDataByTable(data: ClusterItem[][]): ClusterItem[][] {
    const [minimum, maximum] = this.getMinMaxFromClusterItems(data);
    const segmentSize = (maximum - minimum) / this.segments;
    return data.map((row) =>
      row.map((item) => ({
        ...item,
        segment: this.getHighlightSegment(item.value, minimum, segmentSize),
      }))
    );
  }

  private highlightClustersDataByVariable(
    data: ClusterItem[][]
  ): ClusterItem[][] {
    return data.map((row) => {
      const values = row.map((item) => item.value);
      const minimum = Math.min(Infinity, ...values);
      const maximum = Math.max(-Infinity, ...values);
      const segmentSize = (maximum - minimum) / this.segments;
      return row.map((item) => ({
        ...item,
        segment: this.getHighlightSegment(item.value, minimum, segmentSize),
      }));
    });
  }

  private highlightClustersDataByCluster(
    data: ClusterItem[][]
  ): ClusterItem[][] {
    const clusterHighlights = data[0].map((_, index) => {
      const values = data.map((row) => row[index].value);
      const min = Math.min(Infinity, ...values);
      const max = Math.max(-Infinity, ...values);
      return {
        minimum: min,
        maximum: max,
        segmentSize: (max - min) / this.segments,
      };
    });

    return data.map((row) =>
      row.map((item, index) => ({
        ...item,
        segment: this.getHighlightSegment(
          item.value,
          clusterHighlights[index].minimum,
          clusterHighlights[index].segmentSize
        ),
      }))
    );
  }

  private getMinMaxFromClusterItems(data: ClusterItem[][]): [number, number] {
    return data.reduce(
      ([min, max], clusterItem) => {
        const values = clusterItem.map((item) => item.value);
        return [Math.min(min, ...values), Math.max(max, ...values)];
      },
      [Infinity, -Infinity]
    );
  }

  private getHighlightSegment(
    value: number,
    min: number,
    segmentSize: number
  ): HighlightSegment {
    return value <= min + segmentSize
      ? HighlightSegment.min
      : value <= min + 2 * segmentSize
      ? HighlightSegment.mid
      : HighlightSegment.max;
  }
}
