import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CodebookNavigationComponent,
  VisualCodeBuilderComponent,
} from 'src/app/components';
import {
  ContextMenuData,
  SelectionTreeFlatNode,
} from 'src/app/components/tree-view/tree.models';
import {
  CodingData,
  CreateTargetCallback,
  CrossTabTableData,
  DisplayType,
  Operator,
  Statement,
  StatementsToTargetsCallback,
  Survey,
  Target,
  UpdateTitleAndCodingCallback,
  autoGroupTargetsCallback,
  groupTargetsCallback,
  hasMoreThanMaxCombinedTargetsCallback,
  UserTargetCodingResponse,
} from 'src/app/models';
import { DropDataContext } from 'src/app/pages';
import { TargetTitlePipe } from 'src/app/pipes';
import {
  CodebookSelectionService,
  DocumentService,
  LoadingNode,
  LoadingSource,
  TargetService,
} from 'src/app/services';
import { CodingDataMapService } from 'src/app/services/coding-data-map.service';
import { CombinedCodingThresholdService } from 'src/app/services/combined-coding-threshold.service';

@Component({
  selector: 'app-codebook-and-visual-code-builder-panel',
  templateUrl: './codebook-and-visual-code-builder-panel.component.html',
  styleUrls: ['./codebook-and-visual-code-builder-panel.component.scss'],
})
export class CodebookAndVisualCodeBuilderPanelComponent
  implements OnInit, OnDestroy
{
  @Input() selectedSurvey: Survey;
  @Input() isReadonly = true;
  @Output() codebookContextMenu = new EventEmitter<ContextMenuData>();
  @Output() isSearchingOrFiltering = new EventEmitter<boolean>();
  @Output() closePanel = new EventEmitter<null>();
  @Output() applyChanges = new EventEmitter<Target>();
  @Output() dropNode = new EventEmitter<DropDataContext<Operator>>();

  public resultTarget: Target = null;
  public targetUserTitle: string;

  public selectedNodes: Statement[] = [];
  public isLoadingSelectedNodes = false;
  public isLoadingFromDrag = false;
  public manualCodingMode = false;

  public createTarget: CreateTargetCallback;
  public convertStatementsToTargets: StatementsToTargetsCallback;
  public updateTitleAndCoding: UpdateTitleAndCodingCallback;
  public groupTargets: groupTargetsCallback;
  public groupTargetsWithAutoOperator: autoGroupTargetsCallback;
  public hasMoreThanMaxCombinedTargets: hasMoreThanMaxCombinedTargetsCallback;
  public showMaxLimitAlert: () => void;

  public isLoadingNodes = true;
  private loadingSelectedNodes$: Subscription;
  @ViewChild('codebookNavigation')
  private codebookNavigation: CodebookNavigationComponent;

  @ViewChild('visualCodeBuilder')
  private visualCodeBuilder: VisualCodeBuilderComponent;

  private unsubscribe: Subject<void> = new Subject<void>();
  public codingError: string = null;

  public targetResult: CodingData;
  public isResultDirty: boolean;
  public gettingCodingResult: boolean;
  private previousTargetCoding: string;

  public target: Target;
  public isVisualCodeMode = false;

  constructor(
    private targetTitlePipe: TargetTitlePipe,
    private codebookSelectService: CodebookSelectionService,
    private documentService: DocumentService,
    public targetService: TargetService,
    public combinedCodingThresholdService: CombinedCodingThresholdService,
    public codingDataMapService: CodingDataMapService
  ) {
    this.createTarget = (options?: Partial<Target>) =>
      this.targetService.createTarget(options);
    this.convertStatementsToTargets = (statements: Statement[]) =>
      this.targetService.convertStatementsToTargets(statements);
    this.updateTitleAndCoding = (target: Target) =>
      this.targetService.updateTitleAndCoding(target);
    this.groupTargets = (
      targets: Target[],
      operator: Operator,
      forceGrouping: boolean
    ) => this.targetService.groupTargets(targets, operator, forceGrouping);
    this.groupTargetsWithAutoOperator = (targets: Target[]) =>
      this.targetService.groupTargetsWithAutoOperator(targets);
    this.hasMoreThanMaxCombinedTargets = (targets: Target[]) =>
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        targets
      );
    this.showMaxLimitAlert = () =>
      this.combinedCodingThresholdService.showMaxLimitAlert();
  }

  ngOnInit(): void {
    this.loadingSelectedNodes$ =
      this.codebookSelectService.loadingSelectedNodes$.subscribe((loading) => {
        this.isLoadingNodes = loading.inProgress;
      });
    this.listenToSelectedNodesChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoadingSelectedNodes = false;
    this.loadingSelectedNodes$.unsubscribe();
  }

  public onSyncAudienceSize(target: Target): void {
    this.getCodingResult(target);
  }

  public openCodeBuilder(target: Target): void {
    this.isVisualCodeMode = true;
    this.target = target;
    this.previousTargetCoding = this.target?.coding;
    this.targetUserTitle = this.target?.ownTitle;

    this.getCodingResult();
  }

  public closeCodeBuilder(): void {
    this.closePanel.emit();
    this.isVisualCodeMode = false;
    this.target = null;
    this.previousTargetCoding = null;
    this.targetUserTitle = null;
  }

  public onCodebookContextMenu(data: ContextMenuData): void {
    this.codebookContextMenu.emit(data);
  }

  public onCodebookSearchingOrFilteringStateChange(event): void {
    this.isSearchingOrFiltering.emit(event);
  }

  public codebookFetchAndExpandNodes(nodes: Statement[]): void {
    this.codebookNavigation.codebookFetchAndExpandNodes(nodes);
  }

  public deleteCustomAudienceOrMediaNode(node: Statement): void {
    this.codebookNavigation.deleteCustomAudienceOrMediaNode(node);
  }

  public updateCustomAudienceOrMediaNode(
    node: SelectionTreeFlatNode,
    statement: Statement
  ): void {
    this.codebookNavigation.updateCustomAudienceOrMediaNode(node, statement);
  }

  public onCodebookLoadData(node: SelectionTreeFlatNode) {
    this.codebookNavigation.onCodebookLoadData(node);
  }

  public sortCustomAudienceOrMediaNode(
    node: SelectionTreeFlatNode,
    sort: string
  ): void {
    this.codebookNavigation.sortCustomAudienceOrMediaNode(node, sort);
  }

  public loadChildren(
    nodes: Statement[],
    noReturnValue: boolean = false
  ): Promise<Statement[]> {
    return this.codebookNavigation.loadChildren(nodes, noReturnValue);
  }

  public getSelectedNodes() {
    return this.codebookNavigation.getSelectedNodes();
  }

  public unselectAllNodes() {
    this.codebookNavigation.unselectAllNodes();
  }

  private getCodingResult(target?: Target): void {
    const codingTarget = target || this.target;
    this.gettingCodingResult = true;
    this.documentService.fetchCodingResult(codingTarget).subscribe(
      (crossTabData: CrossTabTableData[]) => {
        this.targetResult = this.codingDataMapService.formatCodingData(
          crossTabData[0].data.filter(
            (data) =>
              data.type === 'target' &&
              this.selectedSurvey.code.toLowerCase() ===
                data.surveyCode.toLowerCase()
          )[0]
        );
        this.isResultDirty = false;
        this.gettingCodingResult = false;
      },
      (error) => {
        this.targetResult = {
          resps: null,
          population: null,
        } as CodingData;
        this.isResultDirty = false;
        this.gettingCodingResult = false;
      }
    );
  }

  private listenToSelectedNodesChanges(): void {
    this.codebookSelectService.loadingSelectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isLoading: LoadingNode) => {
        this.isLoadingSelectedNodes = isLoading.inProgress;
        this.isLoadingFromDrag = isLoading.origin === LoadingSource.fromDrag;
      });

    this.codebookSelectService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        this.selectedNodes = selectedNodes;
      });
  }

  public onTargetChange(target: Target): void {
    this.codingError = null;
    this.resultTarget = target;
    this.targetUserTitle = this.resultTarget.ownTitle.trim();

    this.isResultDirty = this.previousTargetCoding !== this.resultTarget.coding;
    this.previousTargetCoding = this.resultTarget.coding;
  }

  public onDropNode(event: DropDataContext<Operator>): void {
    if (this.isLoadingNodes) {
      return;
    }

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(event.selectedTargets)
    ) {
      this.visualCodeBuilder.unsetDrag();
      return;
    }

    this.dropNode.emit(event);
  }

  public onManualCodingMode(mode: boolean): void {
    this.manualCodingMode = mode;
  }

  onButtonClick(saveChanges: boolean): void {
    if (this.isLoadingNodes) {
      return;
    }

    if (this.resultTarget && this.manualCodingMode && saveChanges) {
      this.validCodingStatementOnClose();
    } else {
      if (saveChanges && this.resultTarget) {
        this.applyChanges.emit(this.resultTarget);
      }

      this.closeCodeBuilder();
    }
  }

  public close(): void {
    this.codebookSelectService.unselectNodes();
    if (this.isLoadingNodes) {
      return;
    }
  }

  onUnselectNodes() {
    this.codebookSelectService.unselectNodes();
  }

  private validCodingStatementOnClose(): void {
    const codingStatement = this.targetTitlePipe.codeBuilderTransform(
      this.resultTarget,
      DisplayType.coding,
      this.resultTarget.titleLevels
    );
    this.targetService
      .validateCodingStatement(codingStatement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result: UserTargetCodingResponse) => {
        if (
          result.convertedTargets[0] &&
          !result.hasOwnProperty('Warnings') &&
          !result.hasOwnProperty('Errors')
        ) {
          this.applyChanges.emit(this.resultTarget);
          this.closeCodeBuilder();
        } else {
          this.codingError = result.Warnings
            ? (Object.values(result.Warnings)[0] as string)
            : result.Errors && (Object.values(result.Errors)[0] as string);
          this.codingError = this.codingError.replace(/\/r\/n/g, 'Warning: ');
        }
      });
  }
}
