import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash';
import { DisplayType, Target, TargetItem } from 'src/app/models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CtrlShiftKeyStates } from '../../directives';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

interface ManageReportItem extends Target {
  active: boolean;
}

interface ManageReportData {
  rows: ManageReportItem[];
  columns: ManageReportItem[];
  titleMode: DisplayType;
  titleLevels: number[];
}

interface DataSourceState {
  source: MatTableDataSource<ManageReportItem>;
  activeCount: number;
  shiftPressed: boolean;
  lastSelectedRowId: RowId | null;
  rowIds: RowId[];
}

interface DataTypeItem {
  dataType: DataType;
}

type RowId = string;
type DataType = 'columns' | 'rows';

@Component({
  selector: 'manage-report-items-dialog',
  templateUrl: './manage-report-items-dialog.component.html',
  styleUrls: ['./manage-report-items-dialog.component.scss'],
})
export class ManageReportItemsDialogComponent
  implements OnInit, AfterContentChecked
{
  public displayedColumns: string[] = ['active', 'title'];
  public targetsToDelete: Record<DataType, Target[]> = {
    columns: [],
    rows: [],
  };

  private dataTypeItems: Record<DataType, DataTypeItem> = {
    columns: {
      dataType: 'columns',
    },
    rows: {
      dataType: 'rows',
    },
  };

  public dataSourceState: Record<DataType, DataSourceState> = {
    columns: this.getDefaultSourceState(),
    rows: this.getDefaultSourceState(),
  };

  public titleMode: DisplayType;
  public titleLevels: number[];

  constructor(
    private changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ManageReportItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManageReportData
  ) {
    if (!data) {
      return;
    }

    this.titleMode = data.titleMode;
    this.titleLevels = data.titleLevels;

    this.initialiseDataSourceState();
  }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  public onApply(): void {
    const targetItemsToDelete: TargetItem[] = [];
    Object.keys(this.dataTypeItems).forEach((dataType: DataType) => {
      targetItemsToDelete.push(
        ...this.targetsToDelete[dataType].map(
          (target: Target, index: number) => ({
            target: target,
            type: dataType === 'columns' ? 1 : 2,
            index,
          })
        )
      );
    });

    this.dialogRef.close(
      targetItemsToDelete.length ? targetItemsToDelete : null
    );
  }

  public onActiveToggle(
    event: MatCheckboxChange,
    element: ManageReportItem,
    dataType: DataType
  ): void {
    const selectedRowId = element.coding;
    const dataSourceState = this.dataSourceState[dataType];
    const dataSource = dataSourceState.source.data;
    if (dataSourceState.shiftPressed && dataSourceState.lastSelectedRowId) {
      const lastSelectedRowIndex = dataSourceState.rowIds.indexOf(
        dataSourceState.lastSelectedRowId
      );
      const selectedRowIndex = dataSourceState.rowIds.indexOf(selectedRowId);

      let lowerIndex = selectedRowIndex;
      let upperIndex = lastSelectedRowIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedRowIndex;
        upperIndex = selectedRowIndex;
      }

      for (let i = lowerIndex; i <= upperIndex; i++) {
        this.updateRowItemActiveState(event.checked, dataSource[i]);
      }
    } else {
      this.updateRowItemActiveState(event.checked, element);
    }

    dataSourceState.lastSelectedRowId = selectedRowId;

    this.setActiveCounts();
  }

  public onActiveHeaderToggle(event: MatCheckboxChange, dataType: DataType) {
    const active = event.checked;
    const dataSource: ManageReportItem[] =
      this.dataSourceState[dataType].source.data;
    dataSource.forEach((element) => {
      element.active = active;
    });

    this.setActiveCounts();
    this.dataSourceState[dataType].lastSelectedRowId = null;
  }

  public onCtrlShift(states: CtrlShiftKeyStates, dataType: DataType): void {
    this.dataSourceState[dataType].shiftPressed = states.shiftPressed;
  }

  public onDelete(dataType: DataType): void {
    this.targetsToDelete[dataType].push(
      ...this.dataSourceState[dataType].source.data.filter(
        (item) => item.active
      )
    );

    this.dataSourceState[dataType].source.data = cloneDeep(
      this.dataSourceState[dataType].source.data.filter((item) => !item.active)
    );

    this.dataSourceState[dataType].rowIds = this.dataSourceState[
      dataType
    ].source.data.map((row) => row.coding);
    this.dataSourceState[dataType].lastSelectedRowId = null;
    this.setActiveCounts();
  }

  private getDefaultSourceState(): DataSourceState {
    return {
      source: new TableVirtualScrollDataSource([]),
      activeCount: null,
      shiftPressed: false,
      lastSelectedRowId: null,
      rowIds: [],
    };
  }

  private initialiseDataSourceState(): void {
    Object.keys(this.dataTypeItems).forEach((dataType: DataType) => {
      this.dataSourceState[dataType].source.data = cloneDeep(
        this.data[dataType].map((row) => ({
          ...row,
          active: false,
        }))
      );
      this.dataSourceState[dataType].rowIds = this.dataSourceState[
        dataType
      ].source.data.map((row) => row.coding);
    });

    this.setActiveCounts();
  }

  private setActiveCounts() {
    this.dataSourceState.columns.activeCount =
      this.dataSourceState.columns.source.data.filter(
        (item) => item.active
      ).length;
    this.dataSourceState.rows.activeCount =
      this.dataSourceState.rows.source.data.filter(
        (item) => item.active
      ).length;
  }

  private updateRowItemActiveState(
    isActive: boolean,
    element: ManageReportItem
  ): void {
    element.active = isActive;
  }
}
