<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="{{
      documentViewType === documentViewTypeOptions.factorAnalysis
        ? 'Factor analysis'
        : 'Clustering calculation'
    }} failed"
    class="dialog-title"
    cdkDragHandle
  >
  </tup-mat-title-bar>

  <mat-dialog-content class="no-overflow">
    <br />
    <p>
      There is an issue with the
      {{
        documentViewType === documentViewTypeOptions.factorAnalysis
          ? "factor analysis"
          : "clustering calculation"
      }}
      :
    </p>
    <p>
      <b>{{ errorMessage }}</b>
    </p>

    <mat-checkbox
      tupMatCheckbox
      appearance="small"
      color="primary"
      *ngIf="!isReadonly"
      [(ngModel)]="resetDoc"
    >
      Reset
      {{
        documentViewType === documentViewTypeOptions.factorAnalysis
          ? "factor analysis"
          : "cluster"
      }}
      before returning to crosstab
    </mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <button
      *ngIf="
        !(errorMessage === researchSessionNotFoundErrorMessage && isReadonly)
      "
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="save()"
    >
      Back to crosstab
    </button>
  </mat-dialog-actions>
</div>
