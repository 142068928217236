import { Component, Input, OnInit } from '@angular/core';

import { TupDocument } from '@telmar-global/tup-document-storage';

import { SurveyTimeDocument, Survey } from '../../models';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DocumentService } from '../../services/document.service';

@Component({
  templateUrl: './custom-audiences-editable-cell.component.html',
  styleUrls: ['./custom-audiences-editable-cell.component.scss'],
})
export class CustomAudiencesEditableCell implements OnInit {
  @Input() public document: TupDocument<SurveyTimeDocument>;

  private unsubscribe: Subject<void> = new Subject<void>();
  public selectedSurveyCode: string;
  public documentSurveyCode: string;

  constructor(private documentService: DocumentService) {}

  public ngOnInit(): void {
    this.documentSurveyCode = this.document.content.survey?.code.toLowerCase();

    this.documentService.selectedSurvey$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((survey: Survey) => {
        this.selectedSurveyCode = survey.code.toLowerCase();
      });
  }
}
