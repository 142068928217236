import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { Subject, combineLatest } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import {
  AudienceEffectsViewType,
  ClusterViewType,
  CorrespondenceViewType,
  DocumentDataState,
  DocumentViewType,
  ExportFileType,
  SelectedResultMode,
  Survey,
  SurveyTimeDocument,
  Target,
  ViewType,
} from 'src/app/models';
import {
  DialogService,
  DocumentService,
  TopBarService,
} from 'src/app/services';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IchabodsTabsComponent } from '@telmar-global/tup-material';
import { ExportDialogResult } from 'src/app/dialogs/export-dialog/export-dialog.component';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() ichabodsBody: MatTabGroup;
  @Input() ichabodsSelectedTabIndex: number;
  @Input() isReadonly = true;
  @Input() isQuickReport = false;
  @Input() selectViewType: DocumentViewType;
  @Input() showSurveyInfo = false;
  @Input() canExportCharts = true;
  @Input() canExportHighcharts = true;
  @Input() enableSnapshot = false;
  @Input() selectedClusterViewResult: ClusterViewType;
  @Input() selectedCorrespondenceViewResult: CorrespondenceViewType;
  @Input() selectedAudienceEffectsViewResult: AudienceEffectsViewType;
  @Input() selectedAudienceEffectsViewResultMode: SelectedResultMode;
  @Output() exportToXlsx: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToSheets: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToCsv: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToPptx: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToSlides: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToPNG: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToJPEG: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToPDF: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportToSVG: EventEmitter<any> = new EventEmitter<any>();
  @Output() topBarOpen: EventEmitter<null> = new EventEmitter<null>();
  @Output() convertToNormalReport: EventEmitter<null> =
    new EventEmitter<null>();
  @Output() createSnapshot: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('ichabodsTabs') ichabodsTabs: IchabodsTabsComponent;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;

  public viewType: typeof ViewType = ViewType;
  public selectedTab = this.viewType.crossTab;
  private unsubscribe: Subject<void> = new Subject<void>();
  public docOwnerName: string;
  public docName: string;
  public surveys: Survey[];
  public isLoading = false;
  private tableBases: Target[];
  private activeTablebase: Target;

  constructor(
    private documentService: DocumentService,
    private router: Router,
    private topBarService: TopBarService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
  }

  ngAfterViewInit(): void {
    this.updateActiveTab();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ichabodsSelectedTabIndex && this.ichabodsTabs) {
      this.updateActiveTab();
    }
  }

  ngOnDestroy(): void {
    this.topBarService.setRightSidebarOpenedState(false);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onOpenRightSidebarClick(): void {
    this.topBarService.setRightSidebarOpenedState(true);
    this.topBarOpen.emit();
  }

  public onToggleViewChanged(): void {
    this.documentService.resetRestoreState();

    let url = `../chart`;
    const option: NavigationExtras = {
      relativeTo: this.activatedRoute,
      state: {
        isReadonly: this.isReadonly,
      },
    };

    if (this.selectViewType === this.documentViewType.data) {
      url = `../data`;
      option.queryParams = {
        tab: ViewType.crossTab,
      };
    }
    this.router.navigate([url], option);
  }

  public onTabChange(tab: MatTabChangeEvent): void {
    this.selectedTab = tab.index;
  }

  public convertToReport(): void {
    this.convertToNormalReport.emit();
  }

  public snapshot(): void {
    this.createSnapshot.emit();
  }

  public openExportOptions() {
    this.dialogService
      .exportOptions({
        docName: this.docName,
        tableBases: this.tableBases,
        activeTablebase: this.activeTablebase,
        selectViewType: this.selectViewType,
        selectViewResult: this.getSelectedViewResult(),
        selectedAudienceEffectsViewResultMode:
          this.selectedAudienceEffectsViewResultMode,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: ExportDialogResult) => {
        if (result) {
          this.exportTo(
            result.selectedFormat,
            result.selectedTableBases,
            result.docName
          );
        }
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.docOwnerName = doc.metadata.by.attributes.name;
        this.docName = doc.metadata.name;
        this.surveys = doc.content.surveys;
      });

    this.documentService.restoreDocumentState$
      .pipe(takeUntil(this.unsubscribe), delay(0))
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.updateSurveys();
      });

    combineLatest([
      this.documentService.documentState$,
      this.documentService.tablebases$,
      this.documentService.activeTablebase$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        ([{ tables }, tablebases, activeTablebase]: [
          DocumentDataState,
          Target[],
          Target
        ]) => {
          this.tableBases = [...tables, ...tablebases];
          this.activeTablebase = activeTablebase;
          this.updateSurveys();
        }
      );
  }

  private updateSurveys(): void {
    this.surveys = [...this.documentService.surveys];
  }

  private updateActiveTab(): void {
    if (this.ichabodsSelectedTabIndex) {
      this.ichabodsTabs.activeTabIndex = this.ichabodsSelectedTabIndex;
    }
  }

  private exportTo(
    exportFileType: ExportFileType,
    exportTablebases: Target[],
    docName: string
  ) {
    switch (exportFileType) {
      case ExportFileType.csv:
        this.exportToCsv.emit({ docName, exportTablebases });
        break;
      case ExportFileType.xlsx:
        this.exportToXlsx.emit({ docName, exportTablebases });
        break;
      case ExportFileType.googleSheets:
        this.exportToSheets.emit({ docName, exportTablebases });
        break;
      case ExportFileType.googleSlides:
        this.exportToSlides.emit({ docName });
        break;
      case ExportFileType.pptx:
        this.exportToPptx.emit({ docName });
        break;
      case ExportFileType.jpeg:
        this.exportToJPEG.emit({ docName });
        break;
      case ExportFileType.png:
        this.exportToPNG.emit({ docName });
        break;
      case ExportFileType.svg:
        this.exportToSVG.emit({ docName });
        break;
      case ExportFileType.pdf:
        this.exportToPDF.emit({ docName });
        break;
    }
  }

  private getSelectedViewResult() {
    switch (this.selectViewType) {
      case DocumentViewType.audienceEffects:
        return this.selectedAudienceEffectsViewResult;
      case DocumentViewType.cluster:
        return this.selectedClusterViewResult;
      case DocumentViewType.correspondence:
        return this.selectedCorrespondenceViewResult;
    }
  }
}
