<div class="table-container">
  <div
    *ngIf="isProgressing"
    class="progress-bar-container"
  >
    <mat-progress-bar
      color="primary"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>

  <scroll-top-top
    (scrollToTopClick)="onScrollToTopClicked()"
    [showScrollToTopBtn]="showScrollToTopBtn"
    dragBoundary=".table-container"
  ></scroll-top-top>
  <div class="table-wrapper">
    <div
      *ngIf="dataSource.data.length > 50"
      class="spinner-container-overlay"
    >
      <ngx-skeleton-loader
        [count]="30"
        appearance="line"
        [theme]="{
          height: '30px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div
      #gridTableHeader
      class="grid-table-header"
      [style.padding-right]="scrollbarWidth + 'px'"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        class="non-standard-table"
        matSort
        (matSortChange)="onSortChange($event)"
      >
        <ng-container
          matColumnDef="selected"
          sticky
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-header--checkbox column-width--checkbox"
          >
            <mat-checkbox
              *ngIf="!isReadonly"
              tupMatCheckbox
              appearance="small"
              color="primary"
              [checked]="isAllSelected()"
              (change)="onAllRowsSelectedChange($event)"
              [indeterminate]="selectedRows.length > 0 && !isAllSelected()"
            ></mat-checkbox>
          </th>
        </ng-container>

        <ng-container
          matColumnDef="settings"
          stickyEnd
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--settings"
          ></th>
        </ng-container>

        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--id"
          >
            ID
          </th>
        </ng-container>

        <ng-container matColumnDef="title">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--title"
            mat-sort-header
            [ngClass]="{
              compact: codingColumnExpanded
            }"
          >
            Title
          </th>
        </ng-container>

        <ng-container matColumnDef="code">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--code"
            [ngClass]="{
              expanded: codingColumnExpanded
            }"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <span>Code</span>
              <button
                matSuffix
                mat-icon-button
                [disableRipple]="true"
                class="code-expand"
                (click)="expandCodingColumn()"
                [ngClass]="{
                  'show-expand-icon': codingColumnExpanded
                }"
              >
                <mat-icon
                  [matTooltip]="
                    codingColumnExpanded ? 'Reset width' : 'Expand code column'
                  "
                  class="material-symbols-outlined"
                  >width</mat-icon
                >
              </button>
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="resps">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--resps"
            mat-sort-header
          >
            Resps
          </th>
        </ng-container>

        <ng-container matColumnDef="population">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--population"
            mat-sort-header
          >
            Population
          </th>
        </ng-container>

        <ng-container matColumnDef="group">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header column-width--group-name"
            mat-sort-header
          >
            Group name
          </th>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
      </table>
      <div
        class="grid-table-header-scroll-padding"
        [style.width]="scrollbarWidth + 'px'"
      ></div>
    </div>

    <cdk-virtual-scroll-viewport
      [tvsItemSize]="codingColumnExpanded ? 300 : 50"
      headerEnabled="false"
      [headerHeight]="-60"
      class="grid-table-body"
      bufferMultiplier="2"
      #virtualScroll
    >
      <table
        ctrlShift
        (ctrlShift)="onCtrlShift($event)"
        mat-table
        [dataSource]="dataSource"
        class="non-standard-table"
      >
        <ng-container
          matColumnDef="selected"
          sticky
        >
          <td
            mat-cell
            *matCellDef="let element"
            class="column-width--checkbox"
          >
            <mat-checkbox
              *ngIf="!element.isEmptyRow && !isReadonly"
              tupMatCheckbox
              appearance="small"
              color="primary"
              [checked]="selectedRowIds[element.id]"
              (change)="onSingleRowSelectedChange($event, element)"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="settings"
          stickyEnd
        >
          <td
            mat-cell
            class="column-cell--setting column-width--settings"
            *matCellDef="let element"
          >
            <button
              *ngIf="!element.isEmptyRow"
              [matMenuTriggerFor]="settingMenu"
              mat-icon-button
              [disabled]="selectedRows.length > 0"
              color="primary"
              class="column-cell--setting-btn"
            >
              <mat-icon matTooltip="Settings">more_vert</mat-icon>
            </button>
            <mat-menu #settingMenu="matMenu">
              <div data-qa="row-setting-menu">
                <button
                  mat-menu-item
                  (click)="onEditClick(element)"
                >
                  <mat-icon>code</mat-icon>
                  <span>Edit title and code</span>
                </button>
                <button
                  *ngIf="!isAddToRowsOnly"
                  mat-menu-item
                  [matMenuTriggerFor]="sendToMenu"
                  [matMenuTriggerData]="{ row: element }"
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  data-qa="send-to-action"
                >
                  <mat-icon class="material-symbols-outlined">send</mat-icon>
                  <span>Move to</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  (click)="onNTileSettingActionClicked(element)"
                  data-qa="ntile-settings-action"
                >
                  <mat-icon class="material-icons-outlined">grid_on</mat-icon>
                  <span>N-Tiles</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  (click)="onSeparateCountActionClicked(element)"
                  data-qa="separate-count-action"
                >
                  <mat-icon class="material-icons-outlined">calculate</mat-icon>
                  <span>Separate count</span>
                </button>
                <button
                  *ngIf="!isAddToRowsOnly"
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  [matMenuTriggerFor]="changeTitleModeMenu"
                  [matMenuTriggerData]="{ row: element }"
                  data-qa="change-title-mode-action"
                >
                  <mat-icon class="material-icons-outlined">title</mat-icon>
                  <span>Title mode</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  (click)="onDuplicateClick(element)"
                  data-qa="duplicate-action"
                >
                  <mat-icon class="material-symbols-outlined"
                    >content_copy</mat-icon
                  >
                  <span>Duplicate</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  (click)="onRenameGroupName(element)"
                  data-qa="group-name-action"
                >
                  <mat-icon class="material-symbols-outlined"
                    >group_work</mat-icon
                  >
                  <span>Assign group name</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  (click)="onDeleteClick(element)"
                >
                  <mat-icon class="material-icons-outlined">delete</mat-icon>
                  <span>Delete this row</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="
                    element.targetItem?.target.coding === ALL_RESPONDENTS_CODING
                  "
                  (click)="onSaveCustomAudience(element)"
                >
                  <mat-icon class="material-symbols-outlined"
                    >bookmark</mat-icon
                  >
                  <span>Save custom audience</span>
                </button>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <td
            mat-cell
            *matCellDef="let element"
            class="column-width--id"
            (mouseenter)="onSourceRowMouseEnter($event, element)"
            [ngClass]="{
              'is-drag-handle-active':
                dragRow &&
                dragRow.id === element.id &&
                draggingRows === null &&
                shouldShowDragHandle
            }"
          >
            <span class="material-symbols-outlined drag-handle">
              drag_indicator
            </span>
            {{ element.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <td
            mat-cell
            class="column-cell--title column-width--title"
            [ngClass]="{
              'coding-grid-table__row': !isReadonly,
              compact: codingColumnExpanded,
              'expand-full-text': codingColumnExpanded,
              'highlight-search':
                element.searchFields.length > 0 &&
                element.searchFields.includes('title'),
              'highlight-focused-search-item':
                element.searchFields.length > 0 &&
                element.searchFocus &&
                element.searchFocus === 'title'
            }"
            (click)="!isReadonly && onRowClicked(element)"
            [matTooltipDisabled]="isReadonly || element.isEmptyRow"
            [matTooltip]="
              'Edit \'' + element.title + '\' in visual code builder'
            "
            *matCellDef="let element"
          >
            <mat-icon
              matTooltipPosition="below"
              matTooltip="Rename title"
              class="material-symbols-outlined coding-grid-table__title-icon"
              (click)="
                $event.stopPropagation();
                !isReadonly && onClickRenameRowTitle(element)
              "
            >
              edit
            </mat-icon>
            <span
              class="text-span"
              [ngClass]="codingColumnExpanded ? 'expanded-lines' : 'two-lines'"
            >
              {{ element.title }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="code">
          <td
            mat-cell
            class="column-width--code column-cell--code"
            [ngClass]="{
              'coding-grid-table__row': !isReadonly,
              expanded: codingColumnExpanded,
              'expand-full-text': codingColumnExpanded,
              'highlight-search':
                element.searchFields.length > 0 &&
                element.searchFields.includes('code'),
              'highlight-focused-search-item':
                element.searchFields.length > 0 &&
                element.searchFocus &&
                element.searchFocus === 'code'
            }"
            (click)="!isReadonly && onRowClicked(element)"
            [matTooltipDisabled]="isReadonly || element.isEmptyRow"
            [matTooltip]="
              'Edit \'' + element.code + '\' in visual code builder'
            "
            *matCellDef="let element"
          >
            <span
              class="text-span"
              [ngClass]="codingColumnExpanded ? 'expanded-lines' : 'two-lines'"
            >
              {{ element.code }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="resps">
          <td
            mat-cell
            class="column-width--resps"
            *matCellDef="let element"
          >
            {{
              element.targetId && codingDataMap[element.targetId]
                ? codingDataMap[element.targetId].resps
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="population">
          <td
            mat-cell
            class="column-width--population"
            *matCellDef="let element"
          >
            {{
              element.targetId && codingDataMap[element.targetId]
                ? codingDataMap[element.targetId].population
                : ""
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="group">
          <td
            mat-cell
            *matCellDef="let element"
            class="column-cell--group-name column-width--group-name"
            [ngClass]="{
              'expand-full-text': codingColumnExpanded,
              'highlight-search':
                element.searchFields.length > 0 &&
                element.searchFields.includes('groupName'),
              'highlight-focused-search-item':
                element.searchFields.length > 0 &&
                element.searchFocus &&
                element.searchFocus === 'groupName'
            }"
            [matTooltip]="element.groupName"
          >
            {{
              codingColumnExpanded
                ? (element.groupName | truncate: 80)
                : element.groupName
            }}
          </td>
        </ng-container>

        <tr
          mat-row
          (contextmenu)="!isReadonly && onContextMenuTrigger($event, row)"
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            'is-empty-row': row.isEmptyRow,
            'is-selected': selectedRowIds[row.id],
            'is-dragging': draggingRowIds.includes(row.id),
            'is-dragging-over':
              dropTargetId === row.id &&
              draggingRows !== null &&
              !row.isEmptyRow &&
              row.targetItem?.target.coding !== ALL_RESPONDENTS_CODING,
            'is-dragging-over-top': draggingOverTopOrBottom === 'top',
            'is-dragging-over-bottom': draggingOverTopOrBottom === 'bottom'
          }"
          [dndDropzone]
          [dndDisableIf]="
            row.targetItem?.target.coding === ALL_RESPONDENTS_CODING
          "
          [dndDisableDropIf]="
            (row.isEmptyRow && draggingRows !== null) ||
            row.targetItem?.target.coding === ALL_RESPONDENTS_CODING
          "
          (dndDragover)="onDropzoneOver($event)"
          (dragenter)="
            onDropzoneEnter($event, dropZoneMenuTrigger, tableTargetType, row)
          "
          (dragleave)="onDropzoneLeave(row)"
          (dndDrop)="onDrop($event, tableTargetType, row)"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<span
  style="visibility: hidden; position: fixed"
  [style.left]="dropzoneMenuPosition.x"
  [style.top]="dropzoneMenuPosition.y"
  [matMenuTriggerFor]="dropzoneMenu"
  #dropZoneMenuTrigger="matMenuTrigger"
></span>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  #gridContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="gridContextMenu"
></div>
<mat-menu #gridContextMenu="matMenu">
  <div data-qa="grid-context-menu">
    <button
      mat-menu-item
      (click)="onEditClick()"
      [disabled]="selectedRows.length > 1"
    >
      <mat-icon>code</mat-icon>
      <span>Edit title and code</span>
    </button>
    <button
      *ngIf="!isAddToRowsOnly"
      mat-menu-item
      [matMenuTriggerFor]="sendToMenu"
      [disabled]="!isDeletable"
      data-qa="send-to-context-action"
    >
      <mat-icon class="material-symbols-outlined">send</mat-icon>
      <span>Move to</span>
    </button>
    <button
      mat-menu-item
      [disabled]="selectedRows.length !== 1 || !isDeletable"
      (click)="onNTileSettingActionClicked()"
      data-qa="ntile-settings-action"
    >
      <mat-icon class="material-icons-outlined">grid_on</mat-icon>
      <span>N-Tiles</span>
    </button>
    <button
      mat-menu-item
      [disabled]="selectedRows.length < 2"
      [matMenuTriggerFor]="combineMenu"
      data-qa="combine-rows-context-action"
    >
      <mat-icon class="material-symbols-outlined">join</mat-icon>
      <span>Combine</span>
    </button>
    <button
      mat-menu-item
      (click)="onSeparateCountActionClicked()"
      [disabled]="!isDeletable"
      data-qa="separate-count-context-action"
    >
      <mat-icon class="material-symbols-outlined">calculate</mat-icon>
      <span>Separate count</span>
    </button>
    <button
      mat-menu-item
      *ngIf="!isAddToRowsOnly"
      [disabled]="!isDeletable"
      [matMenuTriggerFor]="changeTitleModeMenu"
      data-qa="change-title-mode-context-action"
    >
      <mat-icon class="material-symbols-outlined">title</mat-icon>
      <span>Title mode</span>
    </button>
    <button
      mat-menu-item
      [disabled]="!isDeletable"
      data-qa="duplicate-context-action"
      (click)="onDuplicateClick()"
    >
      <mat-icon class="material-symbols-outlined">content_copy</mat-icon>
      <span>Duplicate</span>
    </button>
    <button
      mat-menu-item
      [disabled]="!isDeletable"
      data-qa="group-name-context-action"
      (click)="onRenameGroupName()"
    >
      <mat-icon class="material-symbols-outlined">group_work</mat-icon>
      <span>Assign group name</span>
    </button>
    <button
      mat-menu-item
      (click)="onSaveCustomAudience()"
      [disabled]="!isDeletable"
    >
      <span
        [matTooltipDisabled]="!isDeletable"
        matTooltip="Save selected row(s) to custom audiences"
      >
        <mat-icon class="material-symbols-outlined">bookmark</mat-icon>
        <span>Save custom audience</span>
      </span>
    </button>
    <button
      mat-menu-item
      [disabled]="!isDeletable"
      (click)="onDeleteClick()"
    >
      <mat-icon class="material-icons-outlined">delete</mat-icon>
      <span>Delete</span>
    </button>
    <button
      mat-menu-item
      [disabled]="!isDeletable"
      (click)="onDeleteCodingGridClick()"
    >
      <mat-icon class="material-icons-outlined">delete_sweep</mat-icon>
      <span>Delete all {{ targetTypeMap[tableTargetType] }}</span>
    </button>
  </div>
</mat-menu>

<mat-menu #combineMenu="matMenu">
  <div data-qa="combine-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of combineActionItems"
      [matTooltip]="action.tooltip"
      matTooltipPosition="right"
      (click)="onCombineActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #sendToMenu="matMenu">
  <ng-template
    matMenuContent
    let-row="row"
  >
    <div data-qa="send-to-action-menu">
      <ng-container *ngFor="let sendToAction of sendToActionItems">
        <button
          *ngIf="sendToAction.currentTargetType !== tableTargetType"
          mat-menu-item
          (click)="onSendToClick(sendToAction, row)"
        >
          {{ sendToAction.name }}
        </button>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #changeTitleModeMenu="matMenu">
  <ng-template
    matMenuContent
    let-row="row"
  >
    <div data-qa="change-title-mode-action-menu">
      <button
        mat-menu-item
        *ngFor="let action of changeTitleModeItems"
        (click)="onChangeTargetTitleModeActionClicked(action, row)"
      >
        {{ action.name }}
      </button>
    </div>
  </ng-template>
</mat-menu>

<div
  #idHover
  class="id-hover-handle-container"
>
  <div
    *ngIf="dragRow"
    class="id-hover-handle"
    [dndDropzone]
    [dndDraggable]="dragRow"
    [dndDisableDragIf]="
      dragRow.isEmptyRow ||
      dragRow.targetItem?.target.coding === ALL_RESPONDENTS_CODING
    "
    (dndStart)="onDragRowStart($event, dragRow)"
    (dndEnd)="onDragRowEnd()"
    [ngClass]="{
      'is-draggable':
        !dragRow.isEmptyRow &&
        dragRow.targetItem?.target.coding !== ALL_RESPONDENTS_CODING
    }"
    (mouseenter)="onDragHandleMouseEnter()"
    (mouseleave)="onDragHandleMouseLeave()"
  >
    &nbsp;
  </div>
</div>

<div
  #dndDragImage
  class="dnd-image"
>
  <div
    *ngFor="let draggingRow of draggingRows"
    class="dragging-row"
  >
    {{ draggingRow.id }}. {{ draggingRow.title }}
  </div>
</div>
