<div
  fxFill
  fxLayout="column"
  class="container"
>
  <app-top-bar
    [selectedAudienceEffectsViewResult]="selectedView"
    [selectedAudienceEffectsViewResultMode]="selectedResultMode"
    [selectViewType]="selectViewType"
    [showSurveyInfo]="false"
    [isReadonly]="isReadonly"
    [canExportHighcharts]="!inProgress"
    (exportToPNG)="exportTo('image/png', $event)"
    (exportToJPEG)="exportTo('image/jpeg', $event)"
    (exportToPDF)="exportTo('application/pdf', $event)"
    (exportToSVG)="exportTo('image/svg+xml', $event)"
    (exportToCsv)="exportToCsv($event)"
    (exportToXlsx)="exportToXlsx($event)"
    (exportToSheets)="exportToSheets($event)"
    (exportToPptx)="exportToPptx($event)"
  ></app-top-bar>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="10px"
    class="audience-effects-control-bar"
    *ngIf="hasValidDataset"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        class="input-form-field select-small"
        style="width: 180px"
        tupMatFormField
        matTooltip="View results as"
        *ngIf="chaidAnalysis && variableTableData.length > 0"
      >
        <mat-select
          [(ngModel)]="selectedView"
          (ngModelChange)="onSelectedViewChange()"
          [disabled]="inProgress"
        >
          <mat-option
            *ngFor="let type of selectedViewTypes"
            [value]="type.value"
          >
            {{ type.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-button-toggle-group
        *ngIf="selectedView === 'results'"
        [disabled]="inProgress"
        [(ngModel)]="selectedResultMode"
        (change)="onSelectedResultModeChange()"
      >
        <mat-button-toggle
          class="toggle-padding"
          *ngFor="let mode of resultViewModes"
          [disableRipple]="true"
          [value]="mode.value"
        >
          {{ mode.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field
        *ngIf="selectedView === 'results' && selectedResultMode === 'gain'"
        appearance="outline"
        class="input-form-field select-small"
        style="width: 100px"
        tupMatFormField
        matTooltip="View results as"
      >
        <mat-select
          [(ngModel)]="selectedGainAnalysisMode"
          [disabled]="inProgress"
          (selectionChange)="onSelectedGainAnalysisModeChange()"
        >
          <mat-option value="table"> Table</mat-option>
          <mat-option value="chart"> Chart</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="surveys.length > 1"
        appearance="outline"
        class="input-form-field select-small"
        tupMatFormField
        style="width: 200px"
        matTooltip="Change survey"
      >
        <mat-select
          [(ngModel)]="activeSurvey"
          (ngModelChange)="onSelectedSurveyChanged()"
          [disabled]="inProgress || isReadonly"
        >
          <mat-option
            *ngFor="let survey of surveys"
            [value]="survey"
          >
            {{ survey.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        appearance="tiny"
        class="data-control-button"
        *ngIf="!isReadonly"
        tupMatButton
        matTooltip="Re-run audience effects"
        [disabled]="inProgress || isReadonly"
        (click)="onRerun()"
      >
        <mat-icon class="tiny-icon">autorenew</mat-icon>
      </button>
      <button
        *ngIf="!isReadonly && selectedView === 'variable'"
        mat-stroked-button
        onclick="this.blur()"
        appearance="tiny"
        class="data-control-button"
        color="primary"
        tupMatButton
        matTooltip="Calculate CHAID"
        [disabled]="inProgress || !variablesUpdated"
        (click)="onCalculateChaid()"
      >
        Calculate CHAID
      </button>

      <button
        onclick="this.blur()"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Change title mode"
        [matMenuTriggerFor]="changeTitleModeMenu"
        [disabled]="inProgress"
      >
        <mat-icon class="material-symbols-outlined"> title </mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Settings"
        [disabled]="inProgress"
        *ngIf="chaidAnalysis && variableTableData.length > 0"
        (click)="openSettings()"
      >
        <mat-icon class="tiny-icon">settings</mat-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="chaidAnalysis && variableTableData.length > 0">
    <div *ngIf="hasValidDataset && selectedView === 'results'">
      <div
        *ngIf="selectedResultMode === 'chaid'"
        class="audience-effects-chaid-chart-container"
      >
        <tup-highcharts
          *ngIf="chartOptions"
          [Highcharts]="Highcharts"
          [callbackFunction]="chartCallback"
          [options]="chartOptions"
          class="audience-effects-chart"
          [ngStyle]="{ height: chaidChartHeight, width: chaidChartWidth }"
        >
        </tup-highcharts>
      </div>

      <div *ngIf="selectedResultMode === 'gain'">
        <app-audience-effects-gain-analysis-view
          [chaidAnalysis]="chaidAnalysis"
          [selectedGainAnalysisMode]="selectedGainAnalysisMode"
          [settings]="activeAppDoc?.settings"
          [survey]="activeSurvey"
          [activeTableTitle]="activeTable.title"
        ></app-audience-effects-gain-analysis-view>
      </div>
    </div>

    <app-audience-effects-variable-view
      *ngIf="selectedView === 'variable' && !inProgress"
      [survey]="activeSurvey"
      [settings]="activeAppDoc?.settings"
      [activeTableTitle]="activeTable.title"
      [totalPopulation]="chaidAnalysis.totalPopulation"
      [totalSample]="chaidAnalysis.totalSample"
      [(variableTableData)]="variableTableData"
      (variableTableDataChange)="onVariableTableDataChange()"
    >
    </app-audience-effects-variable-view>
  </ng-container>

  <tup-mat-card
    class="empty-copy"
    fxLayout="column"
    *ngIf="!hasValidDataset && !inProgress"
  >
    <span *ngIf="!isReadonly">
      Audience effects cannot be performed with the current dataset.<br />
      Please ensure that there are a minimum of one row and one column in the
      dataset, and review the coding statements or values within the columns and
      rows in the crosstab.
    </span>
    <span *ngIf="isReadonly"> No audience effects data. </span>
  </tup-mat-card>

  <div
    class="spinner-container-overlay"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="inProgress"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<ng-template #reAudienceEffectsConfirmation>
  <p>
    Performing a re-analysis will
    <b>reset the current analysis, settings and selected variables</b> and start
    a new calculation by using the currently active survey and current rows &
    columns in the crosstab.
  </p>
</ng-template>

<ng-template #changeSurveyConfirmation>
  <p>
    Changing the survey will trigger a re-analysis which will reset the current
    analysis, settings and selected variables and start a new calculation by
    using current rows & columns in the crosstab.
  </p>
</ng-template>

<mat-menu #changeTitleModeMenu="matMenu">
  <div
    fxLayout="column"
    fxLayoutGap="5px"
    class="title-mode-menu"
  >
    <mat-radio-group
      [(ngModel)]="activeTitleMode"
      fxLayout="column"
      fxLayoutGap="15px"
      (ngModelChange)="selectTitleMode()"
    >
      <mat-radio-button
        *ngFor="let mode of titleModes"
        [value]="mode.displayType"
        (click)="clickTitleMode(mode.displayType)"
      >
        {{ mode.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-menu>
