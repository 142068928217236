import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AudienceEffectsService } from 'src/app/services/audience-effects.service';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsVenn from 'highcharts/modules/venn';
import Fullscreen from 'highcharts/modules/full-screen';
import Exporting from 'highcharts/modules/exporting';
import ExportingLocal from 'highcharts/modules/offline-exporting';
import {
  AudienceEffectsSettings,
  ChaidAnalysisResponseBody,
  GAIN_ANALYSIS_PRIMARY_COLOR,
  GainTableRowItem,
  SelectedGainAnalysisMode,
  Survey,
} from 'src/app/models';
import { shadeColor } from 'src/app/utils/colorHelper';
import { TruncatePipe } from '../../pipes';
import Boost from 'highcharts/modules/boost';
import { GainAnalysisTableComponent } from '../gain-analysis-table/gain-analysis-table.component';
import { DocumentService, PptxService, XlsxService } from 'src/app/services';
import { Canvg } from 'canvg';
import { CrosstabTableXlsxBuilder } from 'src/app/builders/crosstab-table-xlsx.builder';
import pptxgen from 'pptxgenjs';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

Boost(Highcharts);
HighchartsVenn(Highcharts);
Fullscreen(Highcharts);
Exporting(Highcharts);
ExportingLocal(Highcharts);

@Component({
  selector: 'app-audience-effects-gain-analysis-view',
  templateUrl: './audience-effects-gain-analysis-view.component.html',
  styleUrls: ['./audience-effects-gain-analysis-view.component.scss'],
})
export class AudienceEffectsGainAnalysisViewComponent
  implements OnInit, OnChanges
{
  @Input() chaidAnalysis: ChaidAnalysisResponseBody;
  @Input() selectedGainAnalysisMode: SelectedGainAnalysisMode;
  @Input() settings: AudienceEffectsSettings;
  @Input() survey: Survey;
  @Input() activeTableTitle: string;

  @ViewChild('gainTable')
  gainTableComponent: GainAnalysisTableComponent;

  public Highcharts: typeof Highcharts = Highcharts;
  public selectedRow: GainTableRowItem;
  public chartSelectedRow: GainTableRowItem;
  public gainData: GainTableRowItem[];

  public scatterChartOptions: any;
  public columnChartOptions: any;
  public accumulatedChartOptions: any;

  private chartRef: Highcharts.Chart;

  public accDataSource: any;
  private accChartRef: Highcharts.Chart;

  public chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  public accChartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.accChartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  constructor(
    private audienceEffectsService: AudienceEffectsService,
    private documentService: DocumentService,
    private userMessageService: TupUserMessageService,
    private xlsxService: XlsxService,
    private pptxService: PptxService,
    private truncatePipe: TruncatePipe
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chaidAnalysis) {
      this.gainData = this.buildGainData();
      this.selectedRow = this.gainData[this.gainData.length - 1];
      this.chartSelectedRow = undefined;
    }
    this.setChartOptions();
  }

  private setChartOptions() {
    this.scatterChartOptions = this.buildGainScatterChartOptions(this.gainData);
    this.columnChartOptions = this.buildGainColumnChartOptions(this.gainData);

    if (this.chartSelectedRow) {
      const gainData = this.isLastRow(
        this.chartSelectedRow.populationPercentage,
        this.chartSelectedRow.targetsPercentage
      )
        ? this.gainData
        : this.getSelectedGainData(
            this.chartSelectedRow.populationPercentage,
            this.chartSelectedRow.targetsPercentage
          );
      this.accumulatedChartOptions =
        this.buildGainAnalysisAccumulatedChartOptions(gainData, false);
    } else {
      this.accumulatedChartOptions =
        this.buildGainAnalysisAccumulatedChartOptions([
          this.gainData[0],
          this.gainData[this.gainData.length - 1],
        ]);
    }
  }

  private buildGainScatterChartOptions(rowItems: GainTableRowItem[]) {
    const data = this.formatGainData(rowItems);

    const series = [
      {
        type: 'line',
        name: '',
        data: [
          [0, 0],
          [100, 100],
        ],
        showInLegend: false,
        marker: {
          enabled: true,
        },
      },
      {
        type: 'scatter',
        name: '',
        events: {
          click: (event) => {
            this.onScatterPlotClick(event);
          },
          render: (event) => {
            event.target?.points.forEach((point) => {
              point.selected =
                point.options.x === this.selectedRow.populationPercentage &&
                point.options.y === this.selectedRow.targetsPercentage;
            });
          },
        },
        data,
        showInLegend: false,
        allowPointSelect: true,
        marker: {
          symbol: 'circle',
          radius: 4,
          states: {
            select: {
              fillColor: this.getHighlightColor(true),
              radius: 8,
              lineWidth: 0,
            },
          },
        },
      },
    ];

    this.updateChartSeries(series);

    return {
      title: {
        text: this.settings?.gainChartTitle,
        align: 'left',
      },
      tooltip: {
        useHTML: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><td><h3>{point.title}</h3></td></tr>' +
          '<tr><td>Cume % population: {point.x}</td></tr>' +
          '<tr><td>Cume % targeted respondents: {point.y}</td></tr>',
        footerFormat: '</table>',
        followPointer: true,
      },
      xAxis: {
        title: {
          text: 'Cume % population',
        },
        labels: {
          format: '{value}',
          enabled: this.settings?.showAxisLabelInGainChart,
        },
      },
      yAxis: {
        title: {
          text: 'Cume % targeted respondents',
        },
        labels: {
          format: '{value}',
          enabled: this.settings?.showAxisLabelInGainChart,
        },
      },
      plotOptions: {
        series: {
          turboThreshold: 100000,
          dataLabels: {
            align: 'left',
            style: {
              color: 'black',
            },
            enabled: this.settings?.showDataLabelInGainChart,
            format: '{point.shortTitle}',
            x: 10,
          },
        },
      },
      series,
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },
    };
  }

  private buildGainColumnChartOptions(rowItems: GainTableRowItem[]) {
    const data = this.formatGainData(rowItems);

    const series = [
      {
        name: 'Cume % population',
        data: data.map((ele) => ({
          y: ele.x,
          title: ele.title,
          type: 'population',
        })),
      },
      {
        name: 'Cume % target respondents',
        data: data.map((ele) => ({
          y: ele.y,
          title: ele.title,
          type: 'respondents',
        })),
      },
    ];

    this.updateChartSeries(series);

    return {
      chart: {
        type: 'column',
      },
      title: {
        text: this.settings?.gainChartTitle,
        align: 'left',
      },
      xAxis: {
        categories: data.map((_, index) => index + 1),
        title: {
          text: 'Sequence no.',
        },
        labels: {
          format: '{value}',
          enabled: this.settings?.showAxisLabelInGainChart,
        },
      },
      yAxis: {
        title: {
          text: 'Cume %',
        },
        labels: {
          format: '{value}',
          enabled: this.settings?.showAxisLabelInGainChart,
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
        },
        series: {
          turboThreshold: 100000,
          dataLabels: {
            align: 'left',
            style: {
              color: 'black',
            },
            enabled: this.settings?.showDataLabelInGainChart,
            format: '{point.y}',
            x: 10,
          },
        },
      },
      series,
      legend: {
        enabled: this.settings?.showLegendInGainChart,
      },
      tooltip: {
        useHTML: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><td><h3>{point.title}</h3></td></tr>' +
          '<tr><td>Cume % {point.type}: {point.y}</td></tr>',
        footerFormat: '</table>',
        followPointer: true,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },
    };
  }

  private formatGainData(rowItems: GainTableRowItem[]) {
    return rowItems.map((row) => ({
      x: row.populationPercentage,
      y: row.targetsPercentage,
      title: row.longTitle,
      shortTitle: this.truncatePipe.transform(row.longTitle),
    }));
  }

  private buildGainAnalysisAccumulatedChartOptions(
    chaidData: any,
    animate: boolean = true
  ) {
    const selectedRow = this.chartSelectedRow || chaidData[1];
    this.accDataSource = [
      {
        label: 'Title',
        value: selectedRow.longTitle,
      },
      {
        label: 'Fullcode',
        value: selectedRow.fullCode,
      },
      {
        label: 'Population accum. (000)',
        value: selectedRow.population,
      },
      {
        label: 'Targets accum. (000)',
        value: selectedRow.targets,
      },
      {
        label: 'Index',
        value: selectedRow.index,
      },
    ];

    const highlightColor = this.getHighlightColor(false);
    const data = chaidData.map((ele, index) => ({
      sets: [`${index + 1}`],
      value: ele.targetsPercentage,
      color:
        ele.targetsPercentage === 100 &&
        this.chartSelectedRow?.targetsPercentage !== 100
          ? '#ffffff'
          : shadeColor(
              highlightColor,
              index === 1 && this.chartSelectedRow?.targetsPercentage !== 100
                ? 0
                : 100 - ele.targetsPercentage
            ),
      longTitle: ele.longTitle,
      rowIndex: ele.index,
      targets: ele.targets,
    }));

    const dataSetCombinations = data
      .reduce(
        (acc, v, i) => acc.concat(data.slice(i + 1).map((w) => [v, w])),
        []
      )
      .map((ele) => ({
        sets: [ele[0].sets[0], ele[1].sets[0]],
        value: ele[0].value > ele[1].value ? ele[0].value : ele[1].value,
      }));

    const series = [
      {
        type: 'venn',
        name: '',
        tooltip: {
          outside: true,
          pointFormat:
            '<b>Title:</b> {point.longTitle}<br/></br><b>Targets:</b> {point.targets}</br><b>Index:</b> {point.rowIndex}',
        },
        opacity: 1,
        animation: animate,
        dataLabels: {
          enabled: false,
        },
        data: [...data, ...dataSetCombinations],
        turboThreshold: 100000,
      },
    ];

    while (this.accChartRef?.series?.length) {
      this.accChartRef.series[0].remove();
    }
    series.forEach((item) => {
      this.accChartRef?.addSeries(item as SeriesOptionsType);
    });

    return {
      chart: {
        boost: {
          useGPUTranslations: true,
          seriesThreshold: 100,
        },
        events: {
          render() {
            this.setTitle({ text: '' });
            const points = this.series[0]?.points;
            const point1R = points?.[0].shapeArgs?.r;
            if (points) {
              const singleSetsLength = points.filter(
                (point) => point.sets.length > 1
              ).length;
              const pointsToReshape =
                singleSetsLength === 1
                  ? points
                  : points.splice(0, singleSetsLength);
              pointsToReshape.forEach((point) => {
                point.graphic?.attr({
                  y: point.shapeArgs.y + (point1R - point.shapeArgs.r),
                });
              });
            }
          },
        },
      },
      series,
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },
    };
  }

  public onSelectedRowChange(row: GainTableRowItem) {
    this.selectedRow = row;
    this.updateChartSelectedRow(
      row.populationPercentage,
      row.targetsPercentage,
      row.population,
      row.targets
    );

    this.updateAccumulatedChart(
      row.populationPercentage,
      row.targetsPercentage
    );
  }

  public onScatterPlotClick(event: any) {
    this.updateChartSelectedRow(event.point.options.x, event.point.options.y);
    this.selectedRow = this.chartSelectedRow;
    this.updateAccumulatedChart(event.point.options.x, event.point.options.y);
  }

  private updateChartSelectedRow(
    x: number,
    y: number,
    population?: number,
    targets?: number
  ) {
    if (population && targets) {
      this.chartSelectedRow = this.gainData.filter(
        (row) => row.population === population && row.targets === targets
      )[0];
    } else {
      this.chartSelectedRow = this.gainData.filter(
        (row) => row.populationPercentage === x && row.targetsPercentage === y
      )[0];
    }
  }

  private updateAccumulatedChart(x: number, y: number) {
    if (this.isLastRow(x, y)) {
      this.buildGainAnalysisAccumulatedChartOptions(this.gainData, false);
      return;
    }

    const gainData = this.getSelectedGainData(x, y);

    this.buildGainAnalysisAccumulatedChartOptions(gainData, false);
  }

  private getSelectedGainData(x: number, y: number): GainTableRowItem[] {
    let rowSelected = false;
    return this.gainData.filter((row) => {
      if (this.isLastRow(row.populationPercentage, row.targetsPercentage)) {
        return true;
      }
      if (row.populationPercentage === x && row.targetsPercentage === y) {
        rowSelected = true;
      }
      return rowSelected;
    });
  }

  private isLastRow(x: number, y: number): boolean {
    return x === 100 && y === 100;
  }

  private buildGainData(): GainTableRowItem[] {
    return this.audienceEffectsService.calculateGainData(this.chaidAnalysis);
  }

  private updateChartSeries(series: any): void {
    if (
      !(this.scatterChartOptions && this.columnChartOptions) ||
      this.selectedGainAnalysisMode !== SelectedGainAnalysisMode.chart
    ) {
      return;
    }

    try {
      while (this.chartRef?.series?.length) {
        this.chartRef.series[0].remove();
      }
      series.forEach((item) => {
        this.chartRef?.addSeries(item);
      });
    } catch (error) {}
  }

  private getHighlightColor(shouldUseDefaultColor: boolean = true): string {
    return this.settings.showHAccumulatedHighlightColor
      ? this.settings.accumulatedHighlightColor
      : shouldUseDefaultColor
      ? GAIN_ANALYSIS_PRIMARY_COLOR
      : '#ffffff';
  }

  public exportToPptx(docName: string, copyrightText: string): void {
    const images = [];
    Highcharts.charts
      .filter((chart) => !!chart)
      .forEach((chart, index) => {
        images.push({
          data: this.getBase64FromChart(chart),
          x: 0.5,
          y: 0.5,
          w: 5,
          h: 5 * (chart.chartHeight / chart.chartWidth),
        });
      });
    const documentName = docName || this.getExportDocumentName();
    const builder = new SurveyTimePptxBuilder(this.userMessageService);
    builder.exportStatsAppToPptx(
      {
        tableRows: this.gainTableComponent.exportToPptxTableRows(),
        options: {
          autoPage: true,
          autoPageRepeatHeader: true,
          border: { type: 'solid' },
          colW: [0.6, 3, 1, 1, 1, 1, 1],
        },
      },
      images,
      documentName,
      'Audience effects - Gain analysis',
      copyrightText
    );
    this.pptxService.saveAs(builder, documentName);
  }

  public exportToXlsx(docName?: string): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExport(docName);
    this.xlsxService.saveAs(
      crossTabTableBuilder as CrosstabTableXlsxBuilder,
      documentName as string
    );
  }

  public exportToSheets(docName?: string): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExport(docName);
    this.xlsxService.exportToSheets(
      crossTabTableBuilder as CrosstabTableXlsxBuilder,
      `${documentName}.xlsx`
    );
  }

  private getXlsxExport(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.gainTableComponent.exportToXlsx(docName);

    Highcharts.charts
      .filter((chart) => !!chart)
      .forEach((chart, index) => {
        crossTabTableBuilder.addAudienceEffectsChart(
          'Audience Effects chart ' + (index + 1),
          {
            extension: 'png',
            base64: this.getBase64FromChart(chart),
          },
          chart.chartWidth,
          chart.chartHeight
        );
      });

    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  public exportToCSV(docName?: string): void {
    this.gainTableComponent.exportToCSV(docName);
  }

  private getExportDocumentName() {
    return `${this.documentService.document.metadata.name} - Audience Effects Gain Analysis`;
  }

  private getBase64FromChart(chart: Highcharts.Chart) {
    let svg = chart.getSVG();

    let canvas = document.createElement('canvas');
    canvas.setAttribute(
      'style',
      `width: ${chart.chartWidth}px, height:${chart.chartHeight}px`
    );

    let context = canvas.getContext('2d');

    let v = Canvg.fromString(context, svg);
    v.start();

    return canvas.toDataURL('image/png');
  }
}
