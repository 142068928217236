import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  AudienceEffectsSettings,
  AudienceEffectsVariableSeriesItem,
  AudienceEffectsVariableTableRowData,
  SelectedValuable,
  Survey,
  VariableType,
} from '../../models';
import { AudienceEffectsVariableSelectionTableComponent } from '../audience-effects-variable-selection-table/audience-effects-variable-selection-table.component';
import { AudienceEffectsService } from '../../services/audience-effects.service';
import Highcharts from 'highcharts';
import { DocumentService, PptxService, XlsxService } from 'src/app/services';
import html2canvas from 'html2canvas';
import pptxgen from 'pptxgenjs';
import Boost from 'highcharts/modules/boost';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

Boost(Highcharts);

@Component({
  selector: 'app-audience-effects-variable-view',
  templateUrl: './audience-effects-variable-selection-view.component.html',
  styleUrls: ['./audience-effects-variable-selection-view.component.scss'],
})
export class AudienceEffectsVariableSelectionViewComponent
  implements OnInit, OnChanges, OnDestroy
{
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: any;
  private chartRef: Highcharts.Chart;

  @Input() survey: Survey;
  @Input() settings: AudienceEffectsSettings;
  @Input() activeTableTitle: string;
  @Input() totalPopulation: number;
  @Input() totalSample: number;
  @Input() variableTableData: AudienceEffectsVariableTableRowData[] = [];
  @Output() variableTableDataChange: EventEmitter<
    AudienceEffectsVariableTableRowData[]
  > = new EventEmitter<AudienceEffectsVariableTableRowData[]>();
  public hoveringVariableRow: AudienceEffectsVariableTableRowData;

  @ViewChild('variableTable')
  variableTableComponent: AudienceEffectsVariableSelectionTableComponent;

  private unsubscribe: Subject<void> = new Subject<void>();

  @Output()
  selectedValuablesUpdate: EventEmitter<SelectedValuable[]> = new EventEmitter<
    SelectedValuable[]
  >();

  public chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  constructor(
    private audienceEffectsService: AudienceEffectsService,
    private documentService: DocumentService,
    private userMessageService: TupUserMessageService,
    private xlsxService: XlsxService,
    private pptxService: PptxService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    const series = this.audienceEffectsService.formatChartSeries(
      this.variableTableData
    );
    setTimeout(() => {
      this.setChartOptions(series);
    }, 0);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onTableRowChange(row: AudienceEffectsVariableTableRowData): void {
    Highcharts.fireEvent(this.chartRef, 'unselectAll');
    const rowIndex = this.variableTableData.findIndex(
      (item) => item.rowNumber === row.rowNumber
    );
    this.variableTableData[rowIndex] = row;
    this.fireVariableTableDataChanged([...this.variableTableData]);
  }

  public onTableRowsChange(rows: AudienceEffectsVariableTableRowData[]): void {
    Highcharts.fireEvent(this.chartRef, 'unselectAll');
    this.fireVariableTableDataChanged(rows);
  }

  public onTableRowMouseEnter(row: AudienceEffectsVariableTableRowData): void {
    const pointIndex = this.findSeriesPointIndex(row, row.type);
    this.chartRef.series[row.type].points[pointIndex]?.setState('hover');
  }

  public onTableRowMouseLeave(row: AudienceEffectsVariableTableRowData): void {
    const pointIndex = this.findSeriesPointIndex(row, row.type);
    this.chartRef.series[row.type].points[pointIndex]?.setState('');
  }

  private handleVariableRowHover(
    point: Highcharts.Point & AudienceEffectsVariableTableRowData
  ): void {
    this.hoveringVariableRow = point
      ? this.variableTableData.find((row) => row.rowNumber === point.rowNumber)
      : undefined;
  }

  private handleSelectOrDeselectPoints(
    action: string,
    points: Highcharts.Point & AudienceEffectsVariableTableRowData[]
  ): void {
    points.forEach((point) => {
      const tableRow = this.variableTableData.find(
        (row) => row.rowNumber === point.rowNumber
      );
      if (action === 'Select') {
        tableRow.recommended = true;
        tableRow.type =
          point.originalType === VariableType.recommended
            ? VariableType.recommended
            : VariableType.useSelected;
      } else {
        tableRow.recommended = false;
        tableRow.type = VariableType.variables;
      }
    });
    this.fireVariableTableDataChanged([...this.variableTableData]);
  }

  private findSeriesPointIndex(
    row: AudienceEffectsVariableTableRowData,
    type: VariableType
  ): number {
    return this.chartRef.series[type].points.findIndex(
      (p: Highcharts.Point & AudienceEffectsVariableTableRowData) =>
        p.rowNumber === row.rowNumber
    );
  }

  private setChartOptions(series: AudienceEffectsVariableSeriesItem[]): void {
    this.updateChartSeries(series);
    this.chartOptions = {
      exporting: {
        enabled: false,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  textOutline: 'none',
                },
              },
            },
          },
        },
      },

      credits: {
        enabled: false,
      },

      stockTools: {
        gui: {
          enabled: false,
        },
      },

      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy',
        boost: {
          useGPUTranslations: true,
          seriesThreshold: 100,
        },
        events: {
          selection(e) {
            Highcharts.fireEvent(this, 'unselectAll');

            let pointSelected = false;
            // tslint:disable-next-line:no-shadowed-variable
            this.series.forEach((series) => {
              series.points.forEach((point) => {
                if (
                  point.x >= e.xAxis[0].min &&
                  point.x <= e.xAxis[0].max &&
                  point.y >= e.yAxis[0].min &&
                  point.y <= e.yAxis[0].max
                ) {
                  pointSelected = true;
                  point.select(true, true);
                }
              });
            });

            if (pointSelected) {
              Highcharts.fireEvent(this, 'addSelectionButton', {
                x: e.x,
                y: e.y,
              });
            }

            return false;
          },
          unselectAll() {
            const points = this.getSelectedPoints();
            if (points.length > 0) {
              points.forEach((point) => {
                point.setState('');
                point.select(false);
              });
              Highcharts.fireEvent(this, 'destroySelectionButton');
            }
          },
          addSelectionButton(event) {
            Highcharts.fireEvent(this, 'destroySelectionButton');
            const points = this.getSelectedPoints();
            const action = points.some((point) => !point.recommended)
              ? 'Select'
              : 'Deselect';
            this.variableSelectionButton = this.renderer
              .button(action, event.x, event.y, () => {
                Highcharts.fireEvent(this, 'unselectAll');
                this.options.handleSelectOrDeselectPoints(action, points);
                Highcharts.fireEvent(this, 'destroySelectionButton');
              })
              .attr({
                zIndex: 3,
              })
              .add();
          },
          destroySelectionButton() {
            try {
              this.variableSelectionButton?.destroy();
            } catch (e) {}
          },
          click() {
            Highcharts.fireEvent(this, 'unselectAll');
          },
        },
      },

      handleSelectOrDeselectPoints:
        this.handleSelectOrDeselectPoints.bind(this),

      handlePointHover: this.handleVariableRowHover.bind(this),

      legend: {
        enabled: this.settings?.showLegendInVariableSelection,
      },

      title: {
        text: this.settings?.variableSelectionTitle,
      },

      subtitle: {
        text: '',
      },

      xAxis: {
        maxPadding: 0,
        minPadding: 0,
        startOnTick: true,
        endOnTick: true,
        crosshair: true,
        gridLineWidth: 0,
        tickLength: 0,
        title: {
          text: 'Index',
        },
        labels: {
          format: '{value}',
          enabled: this.settings?.showAxisLabelInVariableSelection,
        },
      },

      yAxis: {
        crosshair: true,
        maxPadding: 0,
        minPadding: 0,
        startOnTick: true,
        endOnTick: true,
        gridLineWidth: 0,
        tickLength: 0,
        title: {
          text: 'Targets',
        },
        labels: {
          format: '{value}',
          enabled: this.settings?.showAxisLabelInVariableSelection,
        },
      },

      tooltip: {
        useHTML: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><td><h3>{point.title}</h3></td></tr>' +
          '<tr><td>Index: {point.x:.1f}</td></tr>' +
          '<tr><td>Targets: {point.y:.0f}</td></tr>',
        footerFormat: '</table>',
        followPointer: true,
      },

      plotOptions: {
        series: {
          animation: {
            duration: 0,
          },
          allowPointSelect: true,
          stickyTracking: false,
          point: {
            events: {
              select(event) {
                if (event.accumulate) {
                  return;
                }

                Highcharts.fireEvent(this.series.chart, 'addSelectionButton', {
                  x: event.target.dlBox.x,
                  y: event.target.dlBox.y,
                });
              },
              click(event) {
                if (event.point.selected) {
                  Highcharts.fireEvent(
                    this.series.chart,
                    'destroySelectionButton'
                  );
                }
              },
              mouseOver() {
                this.series.chart.options.handlePointHover(this);
              },
              mouseOut() {
                this.series.chart.options.handlePointHover(null);
              },
            },
          },
          dataLabels: {
            align: 'left',
            style: {
              color: 'black',
            },
            enabled: this.settings?.showDataLabelInVariableSelection,
            format: '{point.shortTitle}',
            x: 10,
          },
        },
        bubble: {
          maxSize: 10,
          minSize: 10,
        },
      },

      series,
    };
  }

  private updateChartSeries(series: any): void {
    if (!this.chartOptions) {
      return;
    }

    while (this.chartRef.series?.length) {
      this.chartRef.series[0].remove();
    }
    series.forEach((item) => {
      this.chartRef?.addSeries(item);
    });
  }

  private fireVariableTableDataChanged(
    data: AudienceEffectsVariableTableRowData[]
  ): void {
    this.variableTableDataChange.emit(data);
  }

  public exportToXlsx(docName?: string): void {
    const [documentName, crossTabTableBuilder] =
      this.variableTableComponent.exportToXlsx(docName);

    const chartContainer = document.getElementsByClassName(
      'highcharts-container'
    )[0] as HTMLElement;
    html2canvas(chartContainer).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');
      crossTabTableBuilder.addAudienceEffectsChart(
        'Audience Effects chart',
        {
          extension: 'png',
          base64: imageData,
        },
        chartContainer.offsetWidth,
        chartContainer.offsetHeight
      );
      crossTabTableBuilder.build();
      this.xlsxService.saveAs(crossTabTableBuilder, documentName);
    });
  }

  public exportToSheets(docName?: string): void {
    const [documentName, crossTabTableBuilder] =
      this.variableTableComponent.exportToXlsx(docName);

    const chartContainer = document.getElementsByClassName(
      'highcharts-container'
    )[0] as HTMLElement;
    html2canvas(chartContainer).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');
      crossTabTableBuilder.addAudienceEffectsChart(
        'Audience Effects chart',
        {
          extension: 'png',
          base64: imageData,
        },
        chartContainer.offsetWidth,
        chartContainer.offsetHeight
      );
      crossTabTableBuilder.build();
      this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
    });
  }

  public exportToPptx(docName: string, copyrightText: string): void {
    const rows = [
      [
        { text: 'SL.No' },
        { text: 'Title' },
        { text: 'Targets' },
        { text: 'Index' },
      ],
      ...this.variableTableData
        .filter((row) => row.recommended)
        .map((row) => [
          { text: String(row.rowNumber) },
          { text: row.title },
          { text: String(row.y) },
          { text: String(row.x) },
        ]),
    ];

    const chartContainer = document.getElementsByClassName(
      'highcharts-container'
    )[0] as HTMLElement;

    html2canvas(chartContainer).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');

      const documentName = docName || this.getExportDocumentName();
      const builder = new SurveyTimePptxBuilder(this.userMessageService);
      builder.exportStatsAppToPptx(
        {
          tableRows: rows,
          options: {
            autoPage: true,
            autoPageRepeatHeader: true,
            border: { type: 'solid' },
            colW: [0.6, 5, 1, 1],
          },
        },
        [
          {
            data: imageData,
            x: 0.5,
            y: 0.5,
            w: 5,
            h: 5 * (chartContainer.offsetHeight / chartContainer.offsetWidth),
          },
        ],
        documentName,
        'Audience effects - Gain analysis',
        copyrightText
      );
      this.pptxService.saveAs(builder, documentName);
    });
  }

  public exportToCSV(docName?: string): void {
    this.variableTableComponent.exportToCSV(docName);
  }

  private getExportDocumentName() {
    return `${this.documentService.document.metadata.name} - Audience Effects Variable Selection`;
  }
}
