import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import {
  Target,
  TargetType,
  TargetItem,
  ALL_RESPONDENTS_CODING,
} from '../models';
import {
  AddCodeToMultipleDialogComponent,
  AddCodeToMultipleDialogResult,
} from '../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { CombinedCodingThresholdService } from '../services/combined-coding-threshold.service';

export interface AddToMultipleActionContext {
  targetType: TargetType;
  selectedTargets: Target[];
  selectableTargets: Target[];
}

@Injectable({
  providedIn: 'root',
})
export class AddToMultipleAction extends ActionDefinition<AddToMultipleActionContext> {
  constructor(
    private documentService: DocumentService,
    private targetService: TargetService,
    private codebookSelectionService: CodebookSelectionService,
    private dialog: MatDialog,
    private combinedCodingThresholdService: CombinedCodingThresholdService
  ) {
    super();
  }

  public invoke(context: AddToMultipleActionContext): void {
    if (
      this.combinedCodingThresholdService.hasMoreThanMaxCombinedTargets(
        context.selectedTargets
      )
    ) {
      this.combinedCodingThresholdService.showMaxLimitAlert();
      return;
    }

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(
        context.selectedTargets
      )
    ) {
      return;
    }

    this.dialog
      .open(AddCodeToMultipleDialogComponent, {
        data: {
          targetType: context.targetType,
          selectedNodeTargets: context.selectedTargets,
          selectableTargets: context.selectableTargets,
        },
        width: '800px',
        closeOnNavigation: true,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: AddCodeToMultipleDialogResult) => {
        const selectedTargetItems = this.normaliseAddCodeToMultipleDialogResult(
          context,
          result
        );

        if (result.addSeparately) {
          result.formattedTargets
            .slice(0, selectedTargetItems.length)
            .forEach((target: Target, index: number) => {
              this.targetService.addChildTarget(
                selectedTargetItems[index].target,
                target,
                result.operator
              );
            });
        } else {
          selectedTargetItems.forEach((targetItem: TargetItem) => {
            this.targetService.addChildTarget(
              targetItem.target,
              result.formattedTargets[0],
              result.operator
            );
          });
        }
        this.documentService.updateDocumentData(selectedTargetItems, true);
        this.codebookSelectionService.unselectNodes();
      });
  }

  private normaliseAddCodeToMultipleDialogResult(
    context: AddToMultipleActionContext,
    result: AddCodeToMultipleDialogResult
  ): TargetItem[] {
    let selectedTargetItems = result.targetItems;
    // handle all respondents exception
    if (context.targetType === TargetType.tables) {
      const allRespondentsTargetItem = selectedTargetItems.find(
        (targetItem: TargetItem) =>
          targetItem.target.coding === ALL_RESPONDENTS_CODING &&
          targetItem.index === 0
      );
      if (allRespondentsTargetItem) {
        const newTargetItem = {
          type: context.targetType,
          target: this.targetService.shallowCopyTarget(
            allRespondentsTargetItem.target
          ),
          index: context.selectableTargets.length,
        };
        selectedTargetItems = selectedTargetItems.filter(
          (targetItem: TargetItem) =>
            targetItem.target.coding !== ALL_RESPONDENTS_CODING &&
            targetItem.index !== 0
        );

        selectedTargetItems.push(newTargetItem);
      }
    }

    return selectedTargetItems;
  }
}
