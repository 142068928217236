<div
  class="view-container"
  fxFlex
  fxLayout="column"
  #viewContainer
  *ngIf="!isLoading && !isInitialising"
  [ngClass]="targetMode"
  [class.is-trending]="surveys.length > 1"
>
  <div
    #chartSettingsRow
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="
      chartList.length > 0 || selectedDataViewMode === dataViewModeType.dynamic
    "
  >
    <div
      *ngIf="targetMode === chartTargetModeType.single"
      class="data-view-toggle-button-control"
    >
      <mat-button-toggle-group
        [(ngModel)]="selectedDataViewMode"
        (change)="onDataViewModeChanged()"
      >
        <mat-button-toggle
          *ngFor="let mode of dataViewModes"
          [attr.data-tracking]="'single-target-' + mode.title"
          [disableRipple]="true"
          [value]="mode.value"
          [matTooltip]="mode.tooltip"
        >
          {{ mode.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div
      *ngIf="targetMode === chartTargetModeType.combined && !isQuickReport"
      class="data-view-toggle-button-control"
    >
      <mat-button-toggle-group
        [(ngModel)]="selectedDataViewMode"
        (change)="onDataViewModeChanged()"
      >
        <mat-button-toggle
          [attr.data-tracking]="'combined-targets-' + mode.title"
          *ngFor="let mode of dataViewModes"
          [disableRipple]="true"
          [value]="mode.value"
          [matTooltip]="mode.tooltip"
        >
          {{ mode.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="survey-toggle-wrapper">
      <ng-template [ngIf]="surveys.length > 1">
        <mat-button-toggle-group
          *ngIf="targetMode === chartTargetModeType.single"
          [(ngModel)]="selectedSurveysInSingleTarget"
          (change)="onSingleTargetChanged()"
          multiple
        >
          <mat-button-toggle
            *ngFor="let survey of surveys"
            [disableRipple]="true"
            [value]="surveyCodeMap[survey.code]"
            [disabled]="
              selectedSurveysInSingleTarget.length < 2 &&
              selectedSurveysInSingleTarget.includes(surveyCodeMap[survey.code])
            "
          >
            {{ surveyCodeMap[survey.code] }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group
          *ngIf="
            targetMode === chartTargetModeType.combined &&
            selectedSurveysInCombinedTargets
          "
          [(ngModel)]="selectedSurveysInCombinedTargets"
          (change)="onSelectedSurveysInCombinedTargetChanged()"
          multiple
        >
          <mat-button-toggle
            *ngFor="let survey of surveys"
            [disableRipple]="true"
            [value]="surveyCodeMap[survey.code]"
            [disabled]="
              selectedSurveysInCombinedTargets.length < 2 &&
              selectedSurveysInCombinedTargets.includes(
                surveyCodeMap[survey.code]
              )
            "
          >
            {{ surveyCodeMap[survey.code] }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </ng-template>

      <ng-template [ngIf]="surveys.length === 1 && isQuickReport">
        <div
          class="additional-chart-setting-row"
          #additionalChartSettingsRow
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start start"
        >
          <ng-container
            *ngTemplateOutlet="
              tablebaseSelectTemplate;
              context: {
                currentTablebase: currentTablebase,
                filteredTablebases: filteredTablebases
              }
            "
          >
          </ng-container>

          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="target-control--compact"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
              class="input-form-field"
              floatLabel="always"
            >
              <mat-label>Custom audiences</mat-label>
              <mat-select
                class="select-placeholder"
                [(ngModel)]="selectedCombinedTargetsCustomAudiencesOption"
                (openedChange)="onSelectOpenedChange($event)"
                placeholder="None"
                multiple
              >
                <mat-option
                  *ngFor="let target of customAudienceTargets"
                  [value]="target"
                  [disabled]="
                    selectedCombinedTargetsCustomAudiencesOption.length >=
                      maxQuickReportCustomAudiences &&
                    !selectedCombinedTargetsCustomAudiencesOption.includes(
                      target
                    )
                  "
                >
                  {{
                    target
                      | targetTitle: target.activeTitleMode:target.titleLevels
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-template>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      class="global-chart-control"
    >
      <div>
        <button
          *ngIf="isQuickReport"
          mat-stroked-button
          tupMatButton
          appearance="tiny"
          color="primary"
          onclick="this.blur()"
          matTooltip="Add insights"
          class="chart-global-settings-button"
          (click)="onAddInsights()"
        >
          Add insights
        </button>

        <span class="chart-number">
          Showing:
          <b>{{ chartNumber }} chart{{ chartNumber > 1 ? "s" : "" }}</b>
          <ng-container *ngIf="numbOfSelectedCharts > 0">
            &nbsp;<b>({{ numbOfSelectedCharts }} selected)</b>
          </ng-container>
        </span>
      </div>

      <button
        data-tracking="global-chart-filter"
        mat-stroked-button
        tupMatButton
        appearance="tiny"
        color="primary"
        onclick="this.blur()"
        [matTooltip]="
          numbOfSelectedCharts === 0
            ? 'Apply filters to all charts'
            : 'Apply filters to selected charts'
        "
        (click)="openChartFilter()"
      >
        <mat-icon> filter_list</mat-icon>
      </button>

      <button
        [disabled]="numbOfSelectedCharts === 0"
        fxFlex="0 0 auto"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        (click)="onUnselectAllCharts()"
        class="control-button chart-unselect-all-btn"
      >
        <mat-icon
          matTooltip="Unselect all charts"
          class="material-icons-outlined"
        >
          remove_done
        </mat-icon>
      </button>
      <button
        data-tracking="global-chart-settings"
        class="chart-global-settings-button"
        color="primary"
        tupMatButton
        mat-stroked-button
        appearance="tiny"
        onclick="this.blur()"
        [matTooltip]="
          numbOfSelectedCharts === 0
            ? 'Apply settings to all charts'
            : 'Apply settings to selected charts'
        "
        (click)="openChartSettings()"
      >
        Global chart settings
      </button>
    </div>
  </div>

  <div
    *ngIf="
      !isQuickReport &&
      (chartList.length > 0 ||
        selectedDataViewMode === dataViewModeType.dynamic) &&
      (targetMode === chartTargetModeType.single ||
        targetMode === chartTargetModeType.combined)
    "
    class="additional-chart-setting-row"
    #additionalChartSettingsRow
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="start start"
  >
    <ng-container *ngIf="selectedDataViewMode === dataViewModeType.default">
      <div
        *ngIf="targetMode === chartTargetModeType.single"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact"
        matTooltip="Target"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
          data-tracking="charts-target-selector-dropdown"
        >
          <mat-label>Target</mat-label>
          <mat-select
            [(ngModel)]="selectedTargetOption"
            multiple
          >
            <mat-option
              #matOption
              *ngFor="let option of defaultTargetOptions"
              [value]="option"
              (click)="selectionChanged(matOption)"
            >
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedDataViewMode === dataViewModeType.dynamic">
      <ng-container
        *ngTemplateOutlet="
          tablebaseSelectTemplate;
          context: {
            currentTablebase: currentTablebase,
            filteredTablebases: filteredTablebases
          }
        "
      >
      </ng-container>

      <div
        *ngIf="targetMode === chartTargetModeType.single"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact target-control--divider-prefix"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Custom audiences</mat-label>
          <mat-select
            [(ngModel)]="selectedSingleTargetCustomAudiencesOption"
            (ngModelChange)="onCustomAudienceTargetChanged()"
          >
            <mat-option value="none"> None</mat-option>
            <mat-option
              *ngFor="let target of customAudienceTargets"
              [value]="target"
            >
              {{
                target | targetTitle: target.activeTitleMode:target.titleLevels
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container
        *ngIf="
          targetMode === chartTargetModeType.single &&
          !shouldIncludeTotalsColumn
        "
      >
        <div
          *ngFor="let groupName of defaultTargetGroupNames"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="target-control--compact"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field"
          >
            <mat-label>{{ groupName }}</mat-label>
            <mat-select
              [(ngModel)]="selectedSingleTargetGroupTarget[groupName]"
              (ngModelChange)="onGroupTargetChanged()"
            >
              <mat-option value="none"> None</mat-option>
              <mat-option
                *ngFor="let target of defaultTargetGroups[groupName]"
                [value]="target"
              >
                {{
                  target
                    | targetTitle: target.activeTitleMode:target.titleLevels
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="
        targetMode === chartTargetModeType.combined &&
        selectedDataViewMode === dataViewModeType.dynamic
      "
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact target-control--divider-prefix"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
          floatLabel="always"
        >
          <mat-label>Custom audiences</mat-label>
          <mat-select
            class="select-placeholder"
            [(ngModel)]="selectedCombinedTargetsCustomAudiencesOption"
            (openedChange)="onSelectOpenedChange($event)"
            placeholder="None"
            multiple
          >
            <mat-option
              *ngFor="let target of customAudienceTargets"
              [value]="target"
            >
              {{
                target | targetTitle: target.activeTitleMode:target.titleLevels
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        *ngFor="let groupName of defaultTargetGroupNames"
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
          floatLabel="always"
        >
          <mat-label>{{ groupName }}</mat-label>
          <mat-select
            class="select-placeholder"
            [(ngModel)]="selectedCombinedTargetsGroupTarget[groupName]"
            (openedChange)="onGroupSelectOpenedChange($event, groupName)"
            placeholder="None"
            multiple
          >
            <mat-option
              *ngFor="let target of defaultTargetGroups[groupName]"
              [value]="target"
            >
              {{
                target | targetTitle: target.activeTitleMode:target.titleLevels
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <ng-template [ngIf]="isQuickReport && surveys.length > 1">
    <div
      class="additional-chart-setting-row"
      #additionalChartSettingsRow
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start start"
    >
      <ng-container
        *ngTemplateOutlet="
          tablebaseSelectTemplate;
          context: {
            currentTablebase: currentTablebase,
            filteredTablebases: filteredTablebases
          }
        "
      >
      </ng-container>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="target-control--compact"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
          floatLabel="always"
        >
          <mat-label>Custom audiences</mat-label>
          <mat-select
            class="select-placeholder"
            [(ngModel)]="selectedCombinedTargetsCustomAudiencesOption"
            (openedChange)="onSelectOpenedChange($event)"
            placeholder="None"
            multiple
          >
            <mat-option
              *ngFor="let target of customAudienceTargets"
              [value]="target"
              [disabled]="
                selectedCombinedTargetsCustomAudiencesOption.length >=
                  maxQuickReportCustomAudiences &&
                !selectedCombinedTargetsCustomAudiencesOption.includes(target)
              "
            >
              {{
                target | targetTitle: target.activeTitleMode:target.titleLevels
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-template>

  <div
    *ngIf="
      selectedDataViewMode !== dataViewModeType.dynamic || !isLoadingDynamicData
    "
    class="chart-container"
    [class.is-additional-settings-row-expanded]="
      isAdditionalChartSettingsRowExpanded
    "
    [style.height.px]="chartContainerHeight"
  >
    <div
      *ngIf="targetMode !== chartTargetModeType.single"
      class="chart-scrollbar"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="loadMoreCharts()"
      fxLayout="row wrap"
      #chartScrollbar
    >
      <chart
        fxFlex="auto"
        [isReadonly]="isReadonly"
        [isQuickReport]="isQuickReport"
        class="chart combined-chart"
        *ngFor="let chartData of chartList; let i = index"
        [id]="i"
        [ngClass]="{
          isQuickReport: isQuickReport
        }"
        [targetMode]="targetMode"
        [dataViewMode]="selectedDataViewMode"
        [targetColors]="targetColors"
        [chartData]="chartData"
        [chartSettings]="chartSettings"
        [dataItemKeys]="dataItemKeys"
        [surveyCodeMap]="surveyCodeMap"
        (colorChange)="refreshGlobalSettings()"
        (scrollToChart)="scrollToGraph(i)"
        [highlightSearch]="highlightSearchChartIndices.includes(i)"
        [focusHighlightSearch]="focusedHighlightSearchChartIndex === i"
        (selectStateChange)="onChartSelectStateChanged($event)"
      ></chart>
      <tup-mat-card
        class="empty-copy"
        fxLayout="column"
        *ngIf="
          (!isQuickReport && chartList.length === 0) ||
          (isQuickReport && customAudiencesLoaded && chartList.length === 0)
        "
      >
        <span>
          No charts are available. Please add some columns and rows in the
          crosstab
        </span>
      </tup-mat-card>

      <scroll-top-top
        (scrollToTopClick)="scrollToTop()"
        [showScrollToTopBtn]="showScrollToTopBtn"
        dragBoundary=".chart-container"
      ></scroll-top-top>
    </div>

    <div
      *ngIf="targetMode === chartTargetModeType.single"
      class="chart-scrollbar"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="loadMoreCharts()"
      fxLayout="row wrap"
      #chartScrollbar
    >
      <chart
        class="chart"
        [isReadonly]="isReadonly"
        [isQuickReport]="isQuickReport"
        *ngFor="let chartData of chartList; let i = index"
        [id]="i"
        [targetMode]="targetMode"
        [dataViewMode]="selectedDataViewMode"
        [targetColors]="targetColors"
        [chartData]="chartData"
        [chartSettings]="chartSettings"
        [dataItemKeys]="dataItemKeys"
        [surveyCodeMap]="surveyCodeMap"
        (colorChange)="refreshGlobalSettings()"
        [ngClass]="{
          full: singleChartFullWidthStatus[
            chartData.target.id + '_' + chartData.title
          ]
        }"
        (fullWidthNeeded)="increaseWidthChartContainer($event, chartData)"
        (scrollToChart)="scrollToGraph(i)"
        [highlightSearch]="highlightSearchChartIndices.includes(i)"
        [focusHighlightSearch]="focusedHighlightSearchChartIndex === i"
        (selectStateChange)="onChartSelectStateChanged($event)"
      ></chart>
      <tup-mat-card
        class="empty-copy"
        fxLayout="column"
        *ngIf="chartList.length === 0"
      >
        <span *ngIf="selectedDataViewMode === dataViewModeType.default">
          No charts are available. Please add some columns and rows in the
          crosstab
        </span>
        <span *ngIf="selectedDataViewMode === dataViewModeType.dynamic">
          It appears that no charts are available for viewing. <br />Please
          select your intended audience from the custom audiences dropdown list
          or create a custom audience from the data view if none are currently
          available.
        </span>
      </tup-mat-card>

      <scroll-top-top
        (scrollToTopClick)="scrollToTop()"
        [showScrollToTopBtn]="showScrollToTopBtn"
        dragBoundary=".chart-container"
      ></scroll-top-top>
    </div>
  </div>
</div>

<div
  class="spinner-container-overlay"
  *ngIf="
    isLoading ||
    isInitialising ||
    (isLoadingDynamicData &&
      selectedDataViewMode === dataViewModeType.dynamic) ||
    isExporting ||
    (isQuickReport && !customAudiencesLoaded)
  "
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

<ng-template
  #tablebaseSelectTemplate
  let-currentTablebase="currentTablebase"
  let-filteredTablebases="filteredTablebases"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="target-control--compact"
  >
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
      class="input-form-field"
      floatLabel="always"
    >
      <mat-label>Tablebase</mat-label>
      <mat-select
        class="select-placeholder"
        (selectionChange)="onTableBaseChanged()"
        [(ngModel)]="currentTablebaseTarget"
        placeholder="None"
      >
        <mat-option
          *ngFor="let tableItem of filteredTablebases"
          [value]="tableItem.target"
          matTooltip="{{ tableItem.target | targetTitle }}"
        >
          {{
            tableItem.target
              | targetTitle
                : tableItem.target.activeTitleMode
                : tableItem.target.titleLevels
              | truncate: 27
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
