import { Component, OnDestroy, OnInit } from '@angular/core';
import { TupDocument } from '@telmar-global/tup-document-storage';
import {
  CrossTabTableData,
  DEFAULT_SURVEY_COPYRIGHT,
  DataItem,
  DataItemType,
  DataItemsSelection,
  DocumentViewType,
  ReportMode,
  Survey,
  SurveyTimeDocument,
  Target,
} from '../../models';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import {
  GeoCodingMap,
  GeoSeriesDataItem,
  HEATMAP_OPTION_ITEMS,
  HEATMAP_OPTIONS,
  NO_HEATMAP_COLOR,
  HEATMAP_QUINTILES,
  HEATMAP_QUARTILES,
} from '../../models/geografix.model';
import { Router } from '@angular/router';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  CrosstabService,
  DataItemsService,
  DocumentService,
} from '../../services';
import { GeografixService } from '../../services/geografix.service';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { takeUntil } from 'rxjs/operators';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts/highmaps';
import Fullscreen from 'highcharts/modules/full-screen';
import Exporting from 'highcharts/modules/exporting';
import ExportingLocal from 'highcharts/modules/offline-exporting';

Fullscreen(Highcharts);
Exporting(Highcharts);
ExportingLocal(Highcharts);

@Component({
  selector: 'app-geografix',
  templateUrl: './geografix-dashboard.component.html',
  styleUrls: ['./geografix-dashboard.component.scss'],
})
export class GeografixDashboardComponent implements OnInit, OnDestroy {
  public dataItems: DataItem[];
  public selectViewType: DocumentViewType = DocumentViewType.geografix;
  public isReadonly = true;
  public currentDoc: TupDocument<SurveyTimeDocument>;
  private activeSurvey: Survey;
  public surveys: Survey[] = [];
  public crosstabData: CrossTabTableData[];

  public inProgress = true;

  private unsubscribe: Subject<void> = new Subject<void>();

  public hasValidDataset = false;
  private geoCodingMap: GeoCodingMap;
  public columns: Target[];
  public selectedColumn: Target;
  public selectedDataItem: DataItem;
  // tslint:disable-next-line:ban-types
  public topojson: Object;

  public isHeatMapEnabled: boolean = false;
  public selectedHeatmapOption = HEATMAP_OPTIONS.quartiles;
  public readonly heatMapOptions = HEATMAP_OPTION_ITEMS;
  public heatMapQuantiles = HEATMAP_QUARTILES;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'mapChart';
  chartOptions: Highcharts.Options;
  private chartRef: Highcharts.Chart;
  chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  constructor(
    private router: Router,
    private authService: TupAuthService,
    private documentService: DocumentService,
    private crosstabService: CrosstabService,
    private geografixService: GeografixService,
    private dataItemsService: DataItemsService
  ) {
    this.isReadonly =
      !!this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public openFullScreen(): void {
    this.chartRef.fullscreen.toggle();
  }

  public onSelectedColumnChange(): void {
    this.renderChart();
  }

  public onSelectedDataItemChange() {
    this.renderChart();
  }

  private listenToDocumentDataChanges(): void {
    combineLatest([
      this.documentService.selectedSurvey$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.activeTablebase$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.crosstabService.crossTabData$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.dataItemsService.actualDataItems$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
    ]).subscribe(
      ([survey, tablebase, crosstabData, dataItems]: [
        Survey,
        Target,
        CrossTabTableData[],
        DataItemsSelection
      ]): void => {
        if (crosstabData.length > 0 && crosstabData[0].isPlaceholder) {
          return;
        }
        this.activeSurvey = survey;
        this.currentDoc = this.documentService.document;
        this.surveys = this.documentService.document.content?.surveys;
        this.isReadonly = !(
          this.authService.user.attributes.email ===
          this.currentDoc?.metadata?.by?.attributes?.email
        );
        this.crosstabData = crosstabData;
        this.dataItems = this.dataItemsService.getActiveDataItems(
          ReportMode.crossTab
        );
        this.selectedDataItem = this.dataItems.filter(
          (item) => item.id === DataItemType.audience
        )[0];
        this.columns = this.currentDoc.content.columns;
        this.selectedColumn = this.columns[0];
        this.loadGeoCodingData();
      }
    );
  }

  private loadGeoCodingData(): void {
    this.hasValidDataset =
      this.currentDoc.content?.rows.length > 0 &&
      this.currentDoc.content?.columns.length > 0;

    if (!this.hasValidDataset) {
      this.inProgress = false;
      return;
    }

    forkJoin([
      this.geografixService.loadTopoJson(this.documentService.topojsonUrl),
      this.geografixService.getAllGeoCodings({
        surveyVersion: this.activeSurvey.code,
        authorizationGroup: this.activeSurvey.authorizationGroup,
      }),
    ]).subscribe(
      ([topojson, geoCodingMap]: [any, GeoCodingMap]) => {
        this.topojson = topojson;
        this.geoCodingMap = geoCodingMap;
        this.renderChart();
        this.inProgress = false;
      },
      (error) => {
        this.inProgress = false;
      }
    );
  }

  public renderChart(): void {
    const data = this.geografixService.formatSeriesData(
      this.crosstabData,
      this.activeSurvey,
      this.selectedColumn,
      this.selectedDataItem,
      this.geoCodingMap
    );

    this.hasValidDataset = data.length > 0;
    if (this.hasValidDataset) {
      this.setChartOptions(
        this.topojson,
        data,
        this.selectedDataItem.displayName
      );
    }
  }

  private setChartOptions(
    topojson: any,
    data: GeoSeriesDataItem[],
    dataItemName: string
  ): void {
    data = this.setSeriesColor(data);
    this.setHeatmapIndicators();

    this.chartOptions = {
      exporting: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },
      caption: {
        text: this.getCopyrightHTML(),
      },
      credits: {
        enabled: false,
      },
      chart: {
        map: topojson,
      },
      title: {
        text: '',
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          alignTo: 'spacingBox',
        },
      },
      legend: {
        enabled: false,
      },
      colorAxis: {
        min: 0,
      },
      series: [
        {
          type: 'map',
          data,
          joinBy: ['geoKey', 'key'],
          name: dataItemName,
          states: {
            hover: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          tooltip: {
            pointFormat: '{point.options.key}: {point.options.value}',
          },
          dataLabels: {
            enabled: true,
            formatter() {
              return this.point.properties.geoKey;
            },
            style: {
              fontWeight: '100',
              fontSize: '10px',
              textOutline: 'none',
            },
          },
        },
      ],
    };
  }

  public exportTo(type: ExportingMimeTypeValue, docName?: string): void {
    this.chartRef.exportChartLocal(
      {
        type,
        sourceWidth: 1280,
        sourceHeight: 720,
        scale: 3,
        ...(docName ? { filename: docName } : {}),
      },
      {}
    );
  }

  private setSeriesColor(data: GeoSeriesDataItem[]) {
    const colors = HEATMAP_OPTION_ITEMS.filter(
      (item) => item.value === this.selectedHeatmapOption
    )[0].colors;
    const quantiles = this.createQuantiles(data, colors.length);

    return data.map((area) => {
      let color = NO_HEATMAP_COLOR;
      if (this.isHeatMapEnabled) {
        const foundQuantile = quantiles.findIndex(
          (quantile) => area.value <= quantile[1] && area.value >= quantile[0]
        );
        if (foundQuantile !== -1) color = colors[foundQuantile].background;
      }
      return {
        ...area,
        color: color,
      };
    });
  }

  private createQuantiles(data: GeoSeriesDataItem[], numberOfSegments: number) {
    const { max, min } = this.getMaxMinValues(data);
    const binPercent = (max - min) / numberOfSegments;
    const quantiles = [[min, min + binPercent]];
    const lastSegment = numberOfSegments - 1;

    for (let i = 0; i < lastSegment; i++) {
      quantiles.push([quantiles[i][1], quantiles[i][1] + binPercent]);
    }

    quantiles[lastSegment][1] = quantiles[lastSegment][1] + 2;

    return quantiles;
  }

  private getMaxMinValues(data: GeoSeriesDataItem[]) {
    return {
      max: Math.max(...data.map((item) => item.value)),
      min: Math.min(...data.map((item) => item.value)),
    };
  }

  private setHeatmapIndicators() {
    this.heatMapQuantiles =
      this.selectedHeatmapOption === HEATMAP_OPTIONS.quartiles
        ? HEATMAP_QUARTILES
        : HEATMAP_QUINTILES;
  }

  public onHeatMapDistributionChange() {
    this.setHeatmapIndicators();
    this.renderChart();
  }

  private getCopyrightHTML(): string {
    const surveyName = this.activeSurvey.meta['survey-name'];
    const surveyProvider = this.activeSurvey.meta['survey-provider'];
    const surveyCopyright =
      this.activeSurvey.meta['copyright-info'] || DEFAULT_SURVEY_COPYRIGHT;
    return `<span>Survey: <b>${surveyName}</b> provided by: <b>${surveyProvider}.</b></span>  <b>${surveyCopyright}</b>`;
  }
}
