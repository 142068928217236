import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Survey } from '../../models';
import { cloneDeep } from 'lodash';

export interface SurveyCodeEditorDialogDataModel {
  surveys: Survey[];
}

@Component({
  templateUrl: './survey-code-editor-dialog.component.html',
  styleUrls: ['./survey-code-editor-dialog.component.scss'],
})
export class SurveyCodeEditorDialogComponent implements OnInit {
  public surveys: Survey[];
  public canSave = false;

  constructor(
    public dialogRef: MatDialogRef<
      SurveyCodeEditorDialogComponent,
      SurveyCodeEditorDialogDataModel
    >,
    @Inject(MAT_DIALOG_DATA) public data: SurveyCodeEditorDialogDataModel
  ) {
    this.surveys = cloneDeep(
      data.surveys.map((survey) => ({
        ...survey,
        customCode: survey.customCode || survey.code,
      }))
    );
  }

  public ngOnInit(): void {}

  public onRefreshSurveyCode(survey: Survey): void {
    survey.customCode = survey.code;
    this.updateSaveButtonState();
  }

  public onSurveyCodeChanged(): void {
    this.updateSaveButtonState();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public saveAs(): void {
    this.dialogRef.close({
      surveys: this.surveys,
    });
  }

  private updateSaveButtonState(): void {
    this.canSave = this.surveys.every(
      (survey: Survey) => survey.customCode.trim() !== ''
    );
  }
}
