<div
  class="dialog-content-container manage-report-dialog"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Manage report items"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxFlex
      fxLayout="column grid"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="split-wrapper"
    >
      <ui-split
        unit="pixel"
        direction="horizontal"
        #split="uiSplit"
      >
        <ui-split-area
          [size]="510"
          [minSize]="300"
          [maxSize]="700"
        >
          <ng-container
            *ngTemplateOutlet="
              activeTable;
              context: {
                dataSource: dataSourceState.columns.source,
                displayedColumns: displayedColumns,
                dataType: 'columns'
              }
            "
          ></ng-container>
        </ui-split-area>

        <ui-split-area>
          <ng-container
            *ngTemplateOutlet="
              activeTable;
              context: {
                dataSource: dataSourceState.rows.source,
                displayedColumns: displayedColumns,
                dataType: 'rows'
              }
            "
          ></ng-container>
        </ui-split-area>
      </ui-split>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      [disabled]="
        targetsToDelete.columns.length === 0 &&
        targetsToDelete.rows.length === 0
      "
      (click)="onApply()"
    >
      Apply
    </button>
  </mat-dialog-actions>
</div>

<ng-template
  #activeTable
  let-dataSource="dataSource"
  let-displayedColumns="displayedColumns"
  let-dataType="dataType"
>
  <div class="table-panel">
    <div class="table-panel-header">
      {{ dataType.toUpperCase() }}
    </div>
    <div class="table-container">
      <cdk-virtual-scroll-viewport
        [tvsItemSize]="50"
        headerEnabled="true"
        headerHeight="56"
        class="virtual-scroll-viewport"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          ctrlShift
          (ctrlShift)="onCtrlShift($event, dataType)"
        >
          <ng-container matColumnDef="active">
            <th
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [checked]="
                  dataSourceState[dataType].activeCount ===
                  dataSourceState[dataType].source.data.length
                "
                (change)="onActiveHeaderToggle($event, dataType)"
                [indeterminate]="
                  dataSourceState[dataType].activeCount > 0 &&
                  dataSourceState[dataType].activeCount !==
                    dataSourceState[dataType].source.data.length
                "
              ></mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [checked]="element.active"
                [attr.data-tracking]="
                  'manage-report-items-' + dataType + '-checkbox'
                "
                (change)="onActiveToggle($event, element, dataType)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <span class="title-font">Select all</span>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="5px"
                  class="count-font"
                >
                  <span class="count-font"
                    >Selected:
                    <b>{{ dataSourceState[dataType].activeCount }}</b></span
                  >
                  <span class="count-font"> | </span>
                  <span class="count-font"
                    >Total:
                    <b>{{ dataSourceState[dataType].source.data.length }}</b>
                  </span>
                </div>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltip]="element.title"
            >
              {{ element | targetTitle: titleMode:titleLevels | truncate: 100 }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div class="table-actions">
    <button
      mat-flat-button
      color="primary"
      appearance="small"
      tupMatButton
      [disabled]="dataSourceState[dataType].activeCount === 0"
      [attr.data-tracking]="'delete-selected-' + dataType + '-button'"
      (click)="onDelete(dataType)"
    >
      Delete selected item(s)
    </button>
  </div>
</ng-template>
