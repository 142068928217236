<div
  class="view-container"
  fxFlex
  fxLayout="column"
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    class="report-view-container"
  >
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="end center"
      class="data-controls-container"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="report-type-group-control-container"
      >
        <mat-button-toggle-group
          class="view-toggle-group-control"
          [(ngModel)]="tabReportMode"
          (change)="onChangeReport()"
        >
          <mat-button-toggle
            data-tracking="crosstab-report-toggle"
            [value]="reportMode.crossTab"
            data-qa="crosstab"
          >
            <mat-icon
              matTooltip="Crosstab"
              class="material-symbols-outlined report-icon"
              >width_normal</mat-icon
            >
            <span class="report-label">Crosstab</span>
          </mat-button-toggle>
          <mat-button-toggle
            data-tracking="separated-report-toggle"
            [value]="reportMode.separatedRank"
            data-qa="separate-rank"
          >
            <mat-icon
              matTooltip="Separated rank"
              class="material-symbols-outlined report-icon"
              >bottom_sheets</mat-icon
            >
            <span class="report-label">Separated rank</span>
          </mat-button-toggle>
          <mat-button-toggle
            data-tracking="combined-report-toggle"
            [value]="reportMode.combinedRank"
            data-qa="combined-rank"
          >
            <mat-icon
              matTooltip="Combined rank"
              class="material-symbols-outlined report-icon"
              >view_cozy</mat-icon
            >
            <span class="report-label">Combined rank</span>
          </mat-button-toggle>
          <mat-button-toggle
            data-tracking="affinity-report-toggle"
            [value]="reportMode.affinity"
            [disabled]="!canUseAffinityReport"
            data-qa="affinity"
            [matTooltip]="affinityReportTooltip"
          >
            <mat-icon class="material-symbols-outlined report-icon"
              >atr</mat-icon
            >
            <span class="report-label">Affinity</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <mat-chip-list
          class="crosstab-formatting-chip-list"
          aria-label="Crosstab formatting"
        >
          <mat-chip
            *ngIf="tableStyleStatus === 'highlight'"
            [disableRipple]="false"
            (removed)="clearStyles()"
            [popper]="gradientHighlightTooltip"
            [popperTrigger]="'hover'"
            [popperPlacement]="'bottom'"
            [popperAppendTo]="
              activeReportMode === reportMode.separatedRank
                ? 'app-separated-rank-report-table'
                : activeReportMode === reportMode.combinedRank
                ? 'app-combined-rank-report-table'
                : 'app-crosstab-table'
            "
            [popperStyles]="{ 'background-color': 'white' }"
            data-qa="highlight-on"
            (click)="openHighlightSettings()"
            onclick="this.blur()"
          >
            <mat-icon
              MatChipAvatar
              [ngStyle]="{
                color: highlightColors[highlightColors.length - 1]
              }"
              >palette
            </mat-icon>
            <popper-content
              #gradientHighlightTooltip
              fxLayout="column"
              data-qa="highlight-popper"
            >
              <p
                class="gradientHighlightTooltip"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <b>Highlight:</b>
              </p>
              <div
                fxLayout="row"
                fxFlex="100"
                fxLayoutGap="5px"
                fxLayoutAlign="center center"
              >
                <p class="inline-gradient">Gradient</p>
                <button
                  *ngFor="let color of highlightColors"
                  mat-mini-fab
                  class="color-circles-tooltip"
                  [ngStyle]="{
                    'background-color': color,
                    height: '20px',
                    width: '20px'
                  }"
                  [disableRipple]="true"
                ></button>
                <p class="inline-gradient">applied</p>
              </div>
              <p
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                on {{ chosenDataItem }} across&nbsp;<b>{{
                  chosenHighlightType
                }}</b>
              </p>
            </popper-content>
            <mat-icon
              matChipRemove
              data-tracking="remove-highlight"
              matTooltipPosition="above"
              matTooltip="Remove highlight"
              >cancel
            </mat-icon>
          </mat-chip>

          <mat-chip
            *ngIf="tableStyleStatus.startsWith('heatmap')"
            [disableRipple]="false"
            (removed)="clearStyles()"
            [popper]="heatMapPopper"
            [popperTrigger]="'hover'"
            [popperPlacement]="'bottom'"
            [popperAppendTo]="
              activeReportMode === reportMode.separatedRank
                ? 'app-separated-rank-report-table'
                : activeReportMode === reportMode.combinedRank
                ? 'app-combined-rank-report-table'
                : 'app-crosstab-table'
            "
            [popperStyles]="{ 'background-color': 'white' }"
            (click)="openHeatmapSettings()"
            onclick="this.blur()"
            data-qa="heatmap-on"
          >
            <mat-icon
              MatChipAvatar
              class="material-icons-outlined"
              color="primary"
            >
              local_fire_department
            </mat-icon>
            <popper-content
              #heatMapPopper
              fxLayout="column"
              data-qa="heatmap-popper"
            >
              <div
                class="heatmap-popper"
                fxLayout="row"
                fxLayoutGap="10px"
                fxLayoutAlign="center center"
              >
                <ng-container *ngIf="tableStyleStatus === 'heatmapQuartiles'">
                  <div
                    fxLayout="row"
                    fxLayoutGap="0.5rem"
                    fxLayoutAlign="center center"
                  >
                    <p class="heatmap-text">
                      <b>Heatmap quartiles applied on Index:</b>
                    </p>
                    <span
                      *ngFor="let heatmapTile of heatmapValues.heatmapQuartiles"
                      fxLayout="row"
                      fxLayoutGap="2px"
                      fxLayoutAlign="center center"
                    >
                      <span
                        class="dot"
                        [style.background]="heatmapTile.background"
                      ></span>
                      <p class="index">{{ heatmapTile.label }}</p>
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="tableStyleStatus === 'heatmapQuintiles'">
                  <div
                    fxLayout="row"
                    fxLayoutGap="0.5rem"
                    fxLayoutAlign="center center"
                  >
                    <p class="heatmap-text">
                      <b>Heatmap quintiles applied on Index:</b>
                    </p>
                    <span
                      *ngFor="let heatmapTile of heatmapValues.heatmapQuintiles"
                      fxLayout="row"
                      fxLayoutGap="2px"
                      fxLayoutAlign="center center"
                    >
                      <span
                        class="dot"
                        [style.background]="heatmapTile.background"
                      ></span>
                      <p class="index">{{ heatmapTile.label }}</p>
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="tableStyleStatus === 'heatmap'">
                  <div
                    fxLayout="row"
                    fxLayoutGap="0.5rem"
                    fxLayoutAlign="center center"
                  >
                    <p class="heatmap-text"><b>Heatmap applied on Index:</b></p>
                    <div
                      fxLayout="row"
                      fxLayoutGap="2px"
                      fxLayoutAlign="center center"
                    >
                      <span
                        class="dot"
                        [style.background]="heatmapValues.heatmap[0].background"
                      ></span>
                      <p class="index">
                        {{ heatmapValues.heatmapIndexPercentage }}% under index
                      </p>
                    </div>
                    <div
                      fxLayout="row"
                      fxLayoutGap="2px"
                      fxLayoutAlign="center center"
                    >
                      <span
                        class="dot"
                        [style.background]="heatmapValues.heatmap[1].background"
                      ></span>
                      <p class="index">
                        {{ heatmapValues.heatmapIndexPercentage }}% above index
                      </p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </popper-content>
            <mat-icon
              matChipRemove
              matTooltipPosition="above"
              matTooltip="Reset heatmap"
              >cancel
            </mat-icon>
          </mat-chip>

          <mat-chip
            *ngIf="completeCasesOn"
            [disableRipple]="false"
            (removed)="turnOffCompleteCasesMode()"
            onclick="this.blur()"
          >
            <mat-icon
              MatChipAvatar
              class="material-icons-outlined"
              color="primary"
            >
              dynamic_form
            </mat-icon>
            <mat-icon
              matChipRemove
              matTooltipPosition="above"
              matTooltip="Turn complete case mode off"
            >
              cancel
            </mat-icon>
          </mat-chip>

          <mat-chip
            *ngIf="zScoreHighlight"
            [disableRipple]="false"
            (removed)="clearStyles()"
            [popper]="zScoreHighlightPopper"
            [popperTrigger]="'hover'"
            [popperPlacement]="'bottom'"
            [popperAppendTo]="
              activeReportMode === reportMode.separatedRank
                ? 'app-separated-rank-report-table'
                : activeReportMode === reportMode.combinedRank
                ? 'app-combined-rank-report-table'
                : 'app-crosstab-table'
            "
            [popperStyles]="{ 'background-color': 'white' }"
            onclick="this.blur()"
          >
            <mat-icon
              MatChipAvatar
              class="material-icons-outlined"
              color="primary"
            >
              sports_score
            </mat-icon>
            <popper-content
              #zScoreHighlightPopper
              fxLayout="column"
            >
              <div
                class="zScore-popper"
                fxLayout="row"
                fxLayoutGap="10px"
                fxLayoutAlign="start center"
              >
                <p class="heatmap-text">
                  <b>Z-score filtered highlight applied:</b>
                </p>
                <div
                  fxLayout="row"
                  fxLayoutGap="2px"
                  fxLayoutAlign="center center"
                >
                  <span class="dot red"></span>
                  <p class="index">&lt; {{ zScoreHighlightProb }}</p>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutGap="2px"
                  fxLayoutAlign="center center"
                >
                  <span class="dot green"></span>
                  <p class="index">&gt; {{ zScoreHighlightProb }}</p>
                </div>
              </div>
            </popper-content>
            <mat-icon
              matChipRemove
              matTooltipPosition="above"
              matTooltip="Reset Z-score filtered highlight"
            >
              cancel
            </mat-icon>
          </mat-chip>
          <mat-chip
            *ngIf="significanceTestingOn !== null"
            [disableRipple]="false"
            (removed)="clearStyles()"
            [popper]="significanceTestingPopper"
            [popperTrigger]="'hover'"
            [popperPlacement]="'bottom'"
            [popperAppendTo]="'app-crosstab-table'"
            [popperStyles]="{ 'background-color': 'white' }"
            data-qa="significance-on"
          >
            <mat-icon
              MatChipAvatar
              color="primary"
            >
              online_prediction
            </mat-icon>
            <popper-content
              #significanceTestingPopper
              fxLayout="column"
              data-qa="significance-popper"
            >
              <div
                class="heatmap-popper"
                fxLayout="row"
                fxLayoutGap="10px"
                fxLayoutAlign="start center"
              >
                <p class="heatmap-text">
                  <b
                    >Significance Testing Applied on
                    {{ significanceTestingOn }}</b
                  >
                </p>
                <div
                  fxLayout="row"
                  fxLayoutGap="2px"
                  fxLayoutAlign="center center"
                >
                  <span class="dot green"></span>
                  <p class="index">&gt; 99%</p>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutGap="2px"
                  fxLayoutAlign="center center"
                >
                  <span class="dot orange"></span>
                  <p class="index">&gt; 95%</p>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutGap="2px"
                  fxLayoutAlign="center center"
                >
                  <span class="dot red"></span>
                  <p class="index">&gt; 90%</p>
                </div>
              </div>
            </popper-content>
            <mat-icon
              matChipRemove
              matTooltipPosition="above"
              matTooltip="Remove significance testing"
              data-tracking="remove-significance-testing"
              >cancel
            </mat-icon>
          </mat-chip>
        </mat-chip-list>

        <app-tablebase-info-popper
          [popperAppendTo]="
            activeReportMode === reportMode.separatedRank
              ? 'app-separated-rank-report-table'
              : activeReportMode === reportMode.combinedRank
              ? 'app-combined-rank-report-table'
              : 'app-crosstab-table'
          "
        ></app-tablebase-info-popper>
        <app-tablebase-select
          [isReadonly]="isReadonly"
          (tablebaseEdit)="onEditTablebase($event)"
        ></app-tablebase-select>

        <app-weight-select [surveys]="surveys"></app-weight-select>
      </div>
    </div>
  </div>

  <app-crosstab-table
    #reportTable
    [isReadonly]="isReadonly"
    *ngIf="
      activeReportMode === reportMode.crossTab ||
      activeReportMode === reportMode.affinity
    "
    fxFlex
    [surveys]="surveys"
    [searchHits]="searchHits"
    (targetClick)="!isReadonly && onTargetClick($event)"
    (editTitleClick)="!isReadonly && onEditTitleClick($event)"
    [dropzoneMenu]="dropzoneMenu"
    (dropzoneEnter)="
      onDropzoneEnter(
        $event.trigger,
        menuType.table,
        $event.targetType,
        $event.targetItem
      )
    "
    (dropzoneLeave)="onDropzoneLeave()"
    (dropzoneDrop)="onTableDropzoneDrop($event)"
    [isViewActive]="isViewActive"
    (contextMenuTrigger)="
      !isReadonly && onMenuTrigger($event, menuEventType.context)
    "
    (columnHeaderMenuTrigger)="
      onMenuTrigger($event, menuEventType.columnHeader)
    "
    (columnFilterClick)="onColumnFilterClick($event)"
  ></app-crosstab-table>
  <app-separated-rank-report-table
    [isViewActive]="isViewActive"
    #reportTable
    *ngIf="activeReportMode === reportMode.separatedRank"
    fxFlex
  ></app-separated-rank-report-table>
  <app-combined-rank-report-table
    #reportTable
    *ngIf="activeReportMode === reportMode.combinedRank"
    fxFlex
    [isViewActive]="isViewActive"
  ></app-combined-rank-report-table>
</div>

<button
  #virtualButton
  class="hidden-virtual-button"
></button>

<mat-menu
  #dropzoneMenu="matMenu"
  [hasBackdrop]="false"
  class="action-menu"
>
  <div
    class="action-menu-button-container"
    [class.action-menu-button-container--fixed-width]="
      dropzoneMenuType === menuType.table
    "
  >
    <button
      mat-menu-item
      *ngFor="let dropzoneActionItem of dropzoneActionItems"
      [dndDropzone]
      [dndDragoverClass]="menuItemDragoverClass"
      [dndDisableDropIf]="isLoadingSelectedNodes"
      [disabled]="isLoadingSelectedNodes"
      (dragenter)="onActionMenuEnter($event)"
      (dragleave)="onActionMenuLeave($event)"
      (dndDrop)="onDrop(dropzoneActionItem)"
    >
      {{ dropzoneActionItem.name }}
    </button>
  </div>
</mat-menu>

<div
  style="visibility: hidden; position: fixed"
  [style.left.px]="menuPosition.x"
  [style.top.px]="menuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu
  #contextMenu="matMenu"
  [hasBackdrop]="false"
  class="action-menu"
>
  <ng-template
    matMenuContent
    let-items="items"
  >
    <ng-container *ngFor="let item of items">
      <button
        *ngIf="!item.actions && !item.isDivider"
        mat-menu-item
        (click)="onMenuItemClick(item)"
        class="context-menu-item"
        [attr.data-tracking]="item.dataTracking"
        [matTooltip]="
          item.name === SWAP_ROWS_COLUMNS_ACTION_NAME ? swapActionWarning : ''
        "
      >
        {{ item.name }}
      </button>
      <button
        *ngIf="item.actions"
        mat-menu-item
        [matMenuTriggerFor]="contextSubMenu"
        [matMenuTriggerData]="{ items: item.actions }"
        class="context-menu-item"
      >
        {{ item.name }}
      </button>
      <mat-divider *ngIf="item.isDivider"></mat-divider>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu
  #contextSubMenu="matMenu"
  class="action-menu"
>
  <ng-template
    matMenuContent
    let-items="items"
  >
    <ng-container *ngFor="let item of items">
      <button
        mat-menu-item
        (click)="onMenuItemClick(item)"
        class="context-menu-item"
        [class.is-active]="item.isActive"
      >
        {{ item.name }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
