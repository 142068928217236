import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { merge } from 'lodash';
import { filter } from 'rxjs/operators';
import {
  DocumentsDataSource,
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventType,
  TupDocumentStorageComponent,
  TupDocumentStorageOptions,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { SurveyTimeDocument } from 'src/app/models';
import { dashboardTabOptions } from '../dashboard-tab-options';

@Component({
  selector: 'app-quick-report-list',
  templateUrl: './quick-report-list.component.html',
  styleUrls: ['./quick-report-list.component.scss'],
})
export class QuickReportListComponent implements OnInit, AfterViewInit {
  public readonly options: TupDocumentStorageOptions = merge(
    {},
    dashboardTabOptions,
    {
      types: [TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE],
      color: 'accent',
      documentReplacementText: 'latest quick report',
    }
  );

  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  public quickReportDataSources: DocumentsDataSource;

  @ViewChild('myQuickReports') public quickReports: TupDocumentStorageComponent;

  @Output()
  navigateToReportsTab: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  createQuickReport: EventEmitter<null> = new EventEmitter<null>();

  public eventType: TupDocumentEventType = TupDocumentEventType.CLICK;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.documentEventEmitter.click
      .pipe(
        filter(
          (event: TupDocumentEvent) => typeof event.data?.action === 'undefined'
        )
      )
      .subscribe((event: TupDocumentEvent) => {
        this.openExisting(event.document);
      });
  }

  public ngAfterViewInit(): void {
    this.quickReportDataSources = this.quickReports?.dataSource;
  }

  public openExisting(doc: TupDocument<unknown>): void {
    const currentDoc = Object.assign(doc) as TupDocument<SurveyTimeDocument>;
    this.router.navigate([`doc/${currentDoc.metadata.id}/chart`], {
      state: {
        isQuickReport: true,
      },
    });
    return;
  }
}
