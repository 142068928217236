import { CrossTabTableDataCellMetaData, Target } from '.';
import { DataItemId, DataItemType } from './data-item.model';
import { SelectMenuOption } from './application.model';
import { DisplayType } from './document.model';

export interface SelectMenuOptionChart<T> {
  title: string;
  value: T;
  graphType: string | boolean;
  percentBarChart?: boolean;
  waterfall?: boolean;
  areaChart?: boolean;
  pyramidChart?: boolean;
  aspectRatio?: number;
  showAllDatasetLabels?: boolean;
}

export const MULTIPLE_SURVEY_CHART_TYPES: SelectMenuOptionChart<GraphSelectionValue>[] =
  [
    {
      title: 'Bar Chart (Horizontal)',
      value: 'tupHorizontalBar',
      graphType: 'tupHorizontalBar',
    },
    {
      title: 'Bar Chart (Vertical)',
      value: 'tupVerticalBar',
      graphType: 'tupVerticalBar',
    },
    {
      title: 'Stacked Bar Chart (Horizontal)',
      value: 'tupStackedHorizontalBar',
      graphType: 'tupStackedHorizontalBar',
    },
    {
      title: 'Stacked Horizontal Bar Charts (100%)',
      value: 'tupPercentageHorizontal',
      graphType: 'tupStackedHorizontalBar',
      percentBarChart: true,
    },
    {
      title: 'Stacked Bar Chart (Vertical)',
      value: 'tupStackedVerticalBar',
      graphType: 'tupStackedVerticalBar',
    },
    {
      title: 'Stacked Vertical Bar Charts (100%)',
      value: 'tupPercentageVertical',
      graphType: 'tupStackedVerticalBar',
      percentBarChart: true,
    },
    {
      title: 'Waterfall',
      value: 'tupWaterfall',
      graphType: 'tupVerticalBar',
      waterfall: true,
    },
    {
      title: 'Line Chart',
      value: 'tupLine',
      graphType: 'tupLine',
    },
    {
      title: 'Pie Chart',
      value: 'tupPie',
      graphType: 'tupPie',
      aspectRatio: 1,
    },
    {
      title: 'Donut Chart',
      value: 'tupDonut',
      graphType: 'tupDonut',
      aspectRatio: 1,
    },
    {
      title: 'Multi-Series Donut Chart',
      value: 'tupMultiSeriesDonut',
      graphType: 'tupMultiSeriesDonut',
      aspectRatio: 1,
      showAllDatasetLabels: true,
    },
    {
      title: 'Radar Chart',
      value: 'tupRadar',
      graphType: 'tupRadar',
      aspectRatio: 1,
    },
    {
      title: 'Filled Radar Chart',
      value: 'tupFilledRadar',
      graphType: 'tupFilledRadar',
      aspectRatio: 1,
    },
    {
      title: 'Radar Column Chart',
      value: 'tupPolar',
      graphType: 'tupPolar',
      aspectRatio: 1,
    },
    {
      title: 'Scatter Chart',
      value: 'tupScatter',
      graphType: 'tupScatter',
    },
    {
      title: 'Area Chart',
      value: 'tupArea',
      graphType: 'tupLine',
      areaChart: true,
    },
    {
      title: 'Treemap',
      value: 'tupTreemap',
      graphType: 'tupTreemap',
    },
    {
      title: 'Funnel',
      value: 'tupFunnel',
      graphType: 'funnel',
    },
    {
      title: 'Pyramid Chart',
      value: 'tupPyramid',
      graphType: 'funnel',
      pyramidChart: true,
    },
  ];

export const IGNORED_SINGLE_TARGET_GRAPH_TYPES: string[] = [
  'tupStackedHorizontalBar',
  'tupStackedHorizontalBar',
  'tupStackedVerticalBar',
  'tupStackedVerticalBar',
  'tupMultiSeriesDonut',
];

export const BASIC_CHART_TYPES: SelectMenuOptionChart<GraphSelectionValue>[] = [
  ...MULTIPLE_SURVEY_CHART_TYPES,
  {
    title: 'WordCloud',
    value: 'tupWordcloud',
    graphType: 'tupWordcloud',
    aspectRatio: 1,
  },
];

export const CHART_TYPES: SelectMenuOptionChart<GraphSelectionValue>[] = [
  ...BASIC_CHART_TYPES,
  {
    title: 'Dot Chart',
    value: 'tupDot',
    graphType: 'tupDot',
  },
];

export const SECONDARY_CHART_TYPES: SelectMenuOptionChart<GraphSelectionValue>[] =
  [
    {
      title: 'None',
      value: 'None',
      graphType: false,
    },
    {
      title: 'Area Chart',
      value: 'tupArea',
      graphType: 'tupLine',
      areaChart: true,
    },
    {
      title: 'Line Chart',
      value: 'tupLine',
      graphType: 'tupLine',
    },
    {
      title: 'Dot Chart',
      value: 'tupDot',
      graphType: 'tupDot',
    },
  ];

export const chartsUnsuitableForMultipleDatasets: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
  'tupTreemap',
  'tupWordcloud',
  'tupFunnel',
  'tupPyramid',
  'None',
];

export const chartsWithTransparentBackgroundFill: GraphSelectionValue[] = [
  'tupPolar',
  'tupFilledRadar',
];

export const unsuitableChartMixed: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
  'tupMultiSeriesDonut',
  'tupRadar',
  'tupFilledRadar',
  'tupPolar',
  'tupTreemap',
  'tupWordcloud',
  'tupFunnel',
  'tupPyramid',
];

export const chartsRequireShadedColorsForMultipleDatasets: GraphSelectionValue[] =
  ['tupMultiSeriesDonut', 'tupPolar'];

export const chartsInSingleRowForMultipleDatasets: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
];

export type GraphSelectionValue =
  | 'None'
  | 'tupVerticalBar'
  | 'tupHorizontalBar'
  | 'tupStackedHorizontalBar'
  | 'tupPercentageVertical'
  | 'tupPercentageHorizontal'
  | 'tupWaterfall'
  | 'tupStackedVerticalBar'
  | 'tupLine'
  | 'tupPie'
  | 'tupDonut'
  | 'tupMultiSeriesDonut'
  | 'tupRadar'
  | 'tupFilledRadar'
  | 'tupPolar'
  | 'tupScatter'
  | 'tupArea'
  | 'tupTreemap'
  | 'tupWordcloud'
  | 'tupFunnel'
  | 'tupPyramid'
  | 'tupDot';

export const SURVEYS_GROUP_DATA_KEY_MAP = [
  'dataFlags',
  'insightIds',
  'targetTitles',
];

export enum ChartTargetMode {
  single = 'single',
  combined = 'combined',
  insightsGroup = 'insightsGroup',
  surveysGroup = 'surveysGroup',
}

export type TargetMode =
  | ChartTargetMode.single
  | ChartTargetMode.combined
  | ChartTargetMode.insightsGroup
  | ChartTargetMode.surveysGroup;

export const TARGET_MODES: SelectMenuOption<TargetMode>[] = [
  {
    title: 'Single target',
    value: ChartTargetMode.single,
    tooltip: 'One target in one chart',
  },
  {
    title: 'Combined targets',
    value: ChartTargetMode.combined,
    tooltip: 'Targets are combined in one chart',
  },
];

export const TARGET_MODES_TRENDING_SURVEY: SelectMenuOption<TargetMode>[] = [
  ...TARGET_MODES,
  {
    title: 'Surveys group',
    value: ChartTargetMode.surveysGroup,
    tooltip: 'One target with multiple surveys grouped in one chart',
  },
  {
    title: 'Insights group',
    value: ChartTargetMode.insightsGroup,
    tooltip: 'One target with multiple insights grouped in one chart',
  },
];

export enum DataViewMode {
  default,
  dynamic,
}

export const DATA_VIEW_MODES: SelectMenuOption<DataViewMode>[] = [
  {
    title: 'Default view',
    value: DataViewMode.default,
    tooltip: '',
  },
  {
    title: 'Dynamic view',
    value: DataViewMode.dynamic,
    tooltip: '',
  },
];

export const CHART_COLORS = [
  '#0054FF',
  '#FC66B4',
  '#FF8B34',
  '#DD7DF9',
  '#FFDE54',
  '#62F0CC',
  '#F65354',
  '#D7AA0B',
  '#CC5800',
  '#A5D40B',
  '#FFA300',
  '#1898B5',
  '#3BEC13',
  '#C114F5',
  '#F55714',
  '#00DBEB',
  '#BA9018',
  '#BD7800',
  '#00BCEB',
  '#FF326B',
  '#7559FF',
  '#C29B31',
];

export const HighlightColors = [
  '#0054FF',
  '#FC66B4',
  '#FF8B34',
  '#FFDE54',
  '#62F0CC',
  '#F65354',
  '#D7AA0B',
  '#CC5800',
  '#A5D40B',
  '#00BCEB',
  '#FF326B',
  '#7559FF',
];

export type SortColumn = 'Primary' | 'Secondary' | string;

export enum ColumnSortOrder {
  ascending = 'Ascending',
  descending = 'Descending',
  inputOrder = 'Input Order',
}

export enum ChartFilterOperator {
  none = 'None',
  equal = '=',
  notEqual = '!=',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  between = 'between',
}

export const DEFAULT_TOP_ROWS_COUNT = 10;

export const OPTIMAL_TOP_ROWS_COUNT = 24;

export const CHART_SETTING_VERSION = 'v8';

export const GLOBAL_SETTINGS_KEY_PREFIX = 'global_';

export const GLOBAL_SETTINGS_KEY = `${GLOBAL_SETTINGS_KEY_PREFIX}${CHART_SETTING_VERSION}`;

export const COMBINED_SETTINGS_KEY = 'All targets';

export interface ChartFilter {
  target: 'None' | string;
  dataItem: DataItemId;
  operator: ChartFilterOperator;
  value: number[];
}

export const GLOBAL_CHART_SETTING_KEYS = [
  'activeTitleMode',
  'titleLevels',
  'primaryDataItem',
  'primaryChartType',
  'secondaryDataItem',
  'secondaryChartType',
  'topRowsCount',
  'columnSortOrder',
  'decimalPlaces',
];

export interface GlobalChartSettings {
  activeTitleMode: DisplayType | '';
  titleLevels?: number[];
  primaryDataItem: DataItemId | '';
  primaryChartType: GraphSelectionValue | '';
  secondaryDataItem: DataItemId | '';
  secondaryChartType: GraphSelectionValue | '';
  topRowsCount: number | '';
  columnSortOrder: ColumnSortOrder | '';
  decimalPlaces: number | '';
}

export interface ChartSettings {
  targetMode: ChartTargetMode;
  dataViewMode: DataViewMode;
  activeTitleMode: DisplayType;
  titleLevels?: number[];
  associatedTarget: string;
  associatedInsight: string;
  primaryDataItem: DataItemId;
  primaryChartType: GraphSelectionValue;
  secondaryDataItem: DataItemId;
  secondaryChartType: GraphSelectionValue;
  chartTitle: string;
  numberOfChartInAxisLabel: number;
  topRowsCount: number;
  maxRowsCount?: number;
  axisLabelAngle: number;
  sortColumn: SortColumn;
  columnSortOrder: ColumnSortOrder;
  showChartLabel: boolean;
  showAxisLabel: boolean;
  flagRowResps: boolean;
  flagRowRespsValue: number;
  showDataTable: boolean;
  showChartLegend: boolean;
  decimalPlaces: number;
  swapData: boolean;
  extraTableSettings: DataItemId[];
  filters: ChartFilter[];
  seriesColor: Record<string, string>;
  showPercentages?: boolean;
}

export declare class DocumentChartStyle {
  default: boolean;
  chartStyleSettings: ChartStyleSettings;
}

export interface ChartStyleSettings {
  primaryDataItem: DataItemId;
  primaryChartType: GraphSelectionValue;
  columnSortOrder: ColumnSortOrder;
  secondaryDataItem: DataItemId;
  secondaryChartType: GraphSelectionValue;
  activeTitleMode: DisplayType;
  titleLevels?: number[];
  showChartLabel: boolean;
  showAxisLabel: boolean;
  showChartLegend: boolean;
  showPercentages?: boolean;
  flagRowResps: boolean;
  flagRowRespsValue: number;
  topRowsCount: number;
  decimalPlaces: number;
}

export const DEFAULT_CHART_SETTINGS: ChartSettings = {
  targetMode: ChartTargetMode.single,
  dataViewMode: DataViewMode.default,
  activeTitleMode: DisplayType.shortTitle,
  associatedTarget: GLOBAL_SETTINGS_KEY,
  associatedInsight: GLOBAL_SETTINGS_KEY,
  primaryDataItem: DataItemType.none,
  primaryChartType: CHART_TYPES[0].value,
  secondaryDataItem: DataItemType.none,
  secondaryChartType: SECONDARY_CHART_TYPES[0].value,
  chartTitle: '',
  numberOfChartInAxisLabel: 45,
  topRowsCount: DEFAULT_TOP_ROWS_COUNT,
  axisLabelAngle: 90,
  sortColumn: 'Primary',
  columnSortOrder: ColumnSortOrder.descending,
  showChartLabel: true,
  showAxisLabel: true,
  flagRowResps: false,
  flagRowRespsValue: 50,
  showDataTable: false,
  showChartLegend: true,
  decimalPlaces: 0,
  swapData: false,
  extraTableSettings: [],
  filters: [
    {
      target: 'None',
      dataItem: DataItemType.none,
      operator: ChartFilterOperator.none,
      value: [0, 0],
    },
    {
      target: 'None',
      dataItem: DataItemType.none,
      operator: ChartFilterOperator.none,
      value: [0, 0],
    },
  ],
  seriesColor: {},
  showPercentages: false,
};

export enum ChartSettingsMode {
  global = 0,
  single = 1,
  combined = 2,
}

export interface ChartColor {
  title: string;
  colors: {
    color: string;
    isSelected: boolean;
  }[];
  targetId: string;
}

export const NO_SECONDARY_CHART_TYPES: GraphSelectionValue[] = [
  'tupPie',
  'tupRadar',
  'tupPolar',
  'tupFilledRadar',
  'tupTreemap',
  'tupFunnel',
  'tupPyramid',
  'tupDonut',
  'tupWordcloud',
  'tupScatter',
];

export const NO_AXIS_LABEL_CHART_TYPES: GraphSelectionValue[] = [
  'tupPie',
  'tupDonut',
  'tupMultiSeriesDonut',
  'tupPolar',
  'tupTreemap',
  'tupFunnel',
  'tupPyramid',
];

export const X_AXIS_LABEL_CHART_TYPES: GraphSelectionValue[] = [
  'tupHorizontalBar',
  'tupStackedHorizontalBar',
  'tupPercentageHorizontal',
];

export const Y_AXIS_LABEL_CHART_TYPES: GraphSelectionValue[] = [
  'tupVerticalBar',
  'tupPercentageVertical',
  'tupWaterfall',
  'tupStackedVerticalBar',
  'tupLine',
  'tupArea',
  'tupDot',
];

export const COLUMN_SORT_ORDERS: ColumnSortOrder[] = [
  ColumnSortOrder.ascending,
  ColumnSortOrder.descending,
  ColumnSortOrder.inputOrder,
];

export const FILTER_OPERATORS: ChartFilterOperator[] = [
  ChartFilterOperator.equal,
  ChartFilterOperator.notEqual,
  ChartFilterOperator.greaterThanOrEqual,
  ChartFilterOperator.greaterThan,
  ChartFilterOperator.lessThanOrEqual,
  ChartFilterOperator.lessThan,
  ChartFilterOperator.between,
];

export const INITIAL_CHART_COUNT = 6;

export type ChartStatusId = string;
export type ChartStatusFullWidth = boolean;
export type ChartStatus = Record<ChartStatusId, ChartStatusFullWidth>;

export interface TargetData {
  title: string;
  targetTitles: string[];
  originalTargetTitle: string;
  labels: string[];
  rowTargets?: Target[];
  columnTargets?: Target[];
  targetIds: string[];
  insightIds?: string[];
  surveyCodes: string[];
  target?: Target;
  dataItems: DataItemChartData;
  cellMetadataSets: CrossTabTableDataCellMetaData[];
}

export interface FinalChartData {
  title: string;
  targetTitles: string[];
  originalTargetTitle: string;
  labels: string[];
  rowTargets?: Target[];
  columnTargets?: Target[];
  targetIds: string[];
  insightIds?: string[];
  surveyCodes: string[];
  dataItems: DataItemChartData;
  cellMetadataSets?: CrossTabTableDataCellMetaData[];
}

export interface MultipleSurveyChartData extends FinalChartData {
  target: Target;
}

export interface FormattedMultipleSurveysChartData
  extends MultipleSurveyChartData {
  dataFlags: boolean[][];
}

export type DataItemChartData = Record<DataItemId, number[][] | number[]>;

export interface ChartSearchInput {
  targetMode: ChartTargetMode;
  dataViewModeType?: DataViewMode;
  chartListData: any[];
}

export interface ChartSearchHit {
  chartIndex: number;
  rowId: string;
  focus: boolean;
}

export const MAX_EXPORT_CHARTS_NUMBER = 150;

export interface ChartSearchLimitSettings {
  limitToTitle: boolean;
  limitToInsights: boolean;
}

export const MAX_QUICK_REPORT_CUSTOM_AUDIENCES = 3;

export const SUPPORTED_CHART_STYLE_SETTINGS = [
  'primaryDataItem',
  'primaryChartType',
  'columnSortOrder',
  'secondaryDataItem',
  'secondaryChartType',
  'activeTitleMode',
  'titleLevels',
  'showChartLabel',
  'showAxisLabel',
  'showChartLegend',
  'showPercentages',
  'flagRowResps',
  'flagRowRespsValue',
  'topRowsCount',
  'decimalPlaces',
];

export interface ChartSelectState {
  id: string;
  settings: ChartSettings;
  selected: boolean;
}

export const INVALID_NUMBER_CHARS = ['-', '+', 'e', '.'];
