import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CellColor,
  CellColorId,
  CellColors,
  CellStyleStatus,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DataItem,
  DataItemCellKey,
  DecimalPointFormat,
  DEFAULT_SORT_OPTIONS,
  DEFAULT_WEIGHT_INDEX,
  HeatmapValues,
  HighlightValues,
  ReportHighlightType,
  ReportMode,
  ReportPreference,
  SortSettings,
  StabilityLevels,
  SurveyCodeMap,
  SurveyMetaDataWeights,
  Target,
  TargetColumn,
  TargetId,
} from 'src/app/models';
import { combineLatest, Subject } from 'rxjs';
import {
  CrosstabService,
  DataItemsService,
  DocumentService,
  HighlightCellsService,
  RequestLoadingService,
  TargetLoading,
  XlsxService,
} from '../../services';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import {
  CrosstabTableXlsxBuilder,
  CrosstabTableXlsxData,
} from '../../builders/crosstab-table-xlsx.builder';
import { TargetTitlePipe } from 'src/app/pipes';
import { cloneDeep, sortBy } from 'lodash';
import { CrosstabTableCsvBuilder } from 'src/app/builders/crosstab-table-csv.builder';
import { CsvService } from 'src/app/services/csv.service';
import { FormatReportUnitsPipe } from 'src/app/pipes/format-report-units.pipe';
import {
  SortChangeData,
  SortResult,
} from './single-target-table/single-target-table.component';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { ColumnHeaderFiltersService } from 'src/app/services/column-header-filters.service';
import { AddOrEditFilterAction } from 'src/app/actions/AddOrEditFilterAction';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { DecimalPointService } from '../../services/decimal-point.service';

const INITIAL_SEPARATED_RANK_TABLE_COUNT = 3;

@Component({
  selector: 'app-separated-rank-report-table',
  templateUrl: './separated-rank-report-table.component.html',
  styleUrls: ['./separated-rank-report-table.component.scss'],
})
export class SeparatedRankReportTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  public displayedColumns: string[] = ['title'];
  public dataItems: DataItem[] = [];
  public dataItemMap: Record<DataItemCellKey, DataItem> = {};
  public decimalPointMap: Record<string, DecimalPointFormat>;
  public crossTabTableData: CrossTabTableData[];
  public separatedRankData: {
    columnId: string;
    targetData: CrossTabTableDataCell;
    tableData: CrossTabTableDataCell[];
    columnPosition: number;
  }[] = [];
  public isLoading = false;
  public cellColors: CellColors = {};
  public highlightValues: HighlightValues;
  public heatmapValues: HeatmapValues;
  public cellStyleStatus: CellStyleStatus;
  public zScoreHighlight: boolean;
  public stabilityFlagStatus: StabilityLevels;
  public reportUnits: number;
  public sortResult: SortResult = {};

  private unsubscribe: Subject<void> = new Subject<void>();
  public targetColumns: TargetColumn[] = [];

  public columnHeaderFilters: ColumnHeaderFilter[] = [];
  public sortSettings: Record<TargetId, SortSettings>;
  public shouldResetFilters: boolean;
  public clearFiltersId: string;

  public activeTablebase: Target;
  public weightDescriptions: SurveyMetaDataWeights[] = [];
  public currentWeightIndex = DEFAULT_WEIGHT_INDEX;

  private unloadedTableList = [];
  public separatedRankTableList: {
    columnId: string;
    targetData: CrossTabTableDataCell;
    tableData: CrossTabTableDataCell[];
    columnPosition: number;
  }[] = [];
  public tableNumber = 0;

  @ViewChild('scrollContainer', { static: false })
  public scrollViewport: ElementRef;
  public showScrollToTopBtn = false;

  @Input() isViewActive: boolean;

  public surveyCodeMap: SurveyCodeMap;

  constructor(
    private requestLoadingService: RequestLoadingService,
    private colorCellsService: HighlightCellsService,
    private documentService: DocumentService,
    private crossTabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private targetTitlePipe: TargetTitlePipe,
    private csvService: CsvService,
    private formatReportUnitsPipe: FormatReportUnitsPipe,
    private reportPreferencesService: ReportPreferencesService,
    private xlsxService: XlsxService,
    private injector: Injector,
    private columnHeaderFiltersService: ColumnHeaderFiltersService,
    private decimalPointsService: DecimalPointService
  ) {}

  ngOnInit(): void {
    this.listenToLoadingState();
    this.listenToReportUnitsChanges();
    this.listenToSurveyDataWeightsChanges();
    this.listenToActiveTablebaseChanges();
    this.listenToReportModeChanges();
    this.listenToDataItemsChanges();
    this.listenToCrossTabDataChanges();
    this.listenToReportPreferencesChanges();
    this.listenToClearFilterChanges();
    this.listenToSurveyCodeMapChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isViewActive?.currentValue) {
      setTimeout(() => {
        this.addScrollListener();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
    this.removeScrollListener();
  }

  public exportToCsv(
    docName?: string,
    tablebase?: Target,
    data?: CrossTabTableData[]
  ): void {
    const documentName = docName || this.documentService.document.metadata.name;
    const currentWeightIndex =
      this.documentService.document.content?.weight || DEFAULT_WEIGHT_INDEX;
    const currentWeight = this.weightDescriptions.filter(
      (weight) => weight.index === currentWeightIndex
    );

    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);

    const separatedRankData = data
      ? this.sortData(cloneDeep(data), this.sortResult)
      : cloneDeep(this.getRawTableData());
    crossTabTableCsvBuilder.addTableData({
      documentName,
      targetColumns: this.crossTabService.getTargetColumns(separatedRankData),
      data: separatedRankData,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: { ...cloneDeep(DEFAULT_SORT_OPTIONS) },
      filters: [],
      surveys: [this.documentService.activeSurvey],
      surveyCodeMap: this.surveyCodeMap,
      defaultDecimalPoints:
        this.decimalPointsService.getActiveReportDecimalPoint(),
      tablebase: tablebase,
      ...(currentWeight.length > 0 ? { weight: currentWeight[0] } : {}),
    });

    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheets(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  public exportToXlsxV2(
    docName: string,
    tablebases: Target[],
    data: CrossTabTableData[][]
  ): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportDataV2(
      docName,
      tablebases,
      data
    );
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheetsV2(
    docName: string,
    tablebases: Target[],
    data: CrossTabTableData[][]
  ): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportDataV2(
      docName,
      tablebases,
      data
    );
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  public onSortChange(sortChangeData: SortChangeData): void {
    if (sortChangeData.sortedTargetData.sortState.direction === '') {
      delete this.sortResult[sortChangeData.targetId];
    } else {
      this.sortResult[sortChangeData.targetId] =
        sortChangeData.sortedTargetData;
    }
  }

  public onFilterChange(columnId: string): void {
    this.injector.get(AddOrEditFilterAction).invoke({
      surveyCode: this.documentService.activeSurvey.code,
      showColumnOptions: false,
      columnId,
      filterSingleColumn: true,
      canApplyGlobalFilter: true,
    });
  }

  public onRemoveFilter(columnId: string): void {
    this.reportPreferencesService.removeColumnHeaderFilters(columnId);
  }

  public onSortGlobalSettingChange(sortSettings: SortSettings): void {
    const columnIds = this.targetColumns.map((column) => column.columnId);
    this.reportPreferencesService.sortSeparateColumn(sortSettings, columnIds);
  }

  public onScrollToTopClicked() {
    this.scrollViewport.nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public loadMoreTable() {
    if (!this.canLoadMoreTables()) {
      return;
    }
    this.updateTableList();
  }

  private canLoadMoreTables(): boolean {
    return this.separatedRankTableList.length < this.tableNumber;
  }

  private updateTableList() {
    this.separatedRankTableList.push(...this.unloadedTableList.slice(0, 1));
    this.unloadedTableList = [...this.unloadedTableList.slice(1)];
  }

  private addScrollListener() {
    const scrollOffset = 400;
    this.showScrollToTopBtn = false;
    const container: HTMLElement = this.scrollViewport.nativeElement;
    if (!container) {
      return;
    }
    container.addEventListener('scroll', (event) => {
      this.showScrollToTopBtn = container.scrollTop > scrollOffset;
    });
  }

  private removeScrollListener(): void {
    this.scrollViewport.nativeElement.removeEventListener('scroll', () => {});
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private listenToReportUnitsChanges(): void {
    this.documentService.reportUnits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((units: number) => {
        this.reportUnits = units;
      });
  }

  private listenToSurveyDataWeightsChanges(): void {
    this.documentService.surveyDataWeights$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((weights: SurveyMetaDataWeights[]) => {
        this.weightDescriptions = weights;
      });
  }

  private listenToActiveTablebaseChanges(): void {
    this.documentService.activeTablebase$
      .pipe(isNotNullOrUndefined())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((tablebase) => {
        this.activeTablebase = tablebase;
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        if (mode !== ReportMode.separatedRank) {
          this.unsubscribeAll();
        }
      });
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CrossTabTableData[]) => {
        this.setupTable(data);
      });
  }

  private listenToReportPreferencesChanges(): void {
    combineLatest([
      this.reportPreferencesService.preference$,
      this.documentService.surveyStabilityLevels$,
    ])
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(
        ([preference, surveyStabilityLevels]: [
          ReportPreference,
          StabilityLevels
        ]) => {
          this.stabilityFlagStatus = preference.stabilityFlagOn
            ? surveyStabilityLevels
            : null;
          this.heatmapValues = preference.heatMapValues;
          this.highlightValues = preference.highlightValues;
          this.cellStyleStatus = preference.cellStyleStatus;
          this.zScoreHighlight =
            preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;
          this.updateCellColors();

          this.columnHeaderFilters = preference.filters.map(
            (columnFilter: ColumnHeaderFilter) => ({
              ...columnFilter,
              filterConditions:
                this.getColumnHeaderFilterConditions(columnFilter),
            })
          );
          this.sortSettings = (
            preference.sortSettings as SortSettings[]
          ).reduce(
            (acc, sortSettings) => ({
              ...acc,
              [sortSettings.columnId]: sortSettings,
            }),
            {}
          );
        }
      );
  }

  private listenToClearFilterChanges() {
    this.columnHeaderFiltersService.clearAllFilters$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((data) => {
        if (data?.resetFilters && data.resetFilters === true) {
          this.separatedRankData.forEach((separatedRank) => {
            if (separatedRank.columnId === data.columnId) {
              separatedRank.tableData.forEach(
                (item) => (item.filteredOutCell = false)
              );
            }
          });
        }
      });
  }

  private listenToSurveyCodeMapChanges(): void {
    this.documentService.surveyCodeMap$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((map: SurveyCodeMap) => {
        this.surveyCodeMap = map;
      });
  }

  private getColumnHeaderFilterConditions(
    columnHeaderFilter: ColumnHeaderFilter
  ): string {
    const column = this.targetColumns.find(
      (targetColumn: TargetColumn) =>
        targetColumn.columnId === columnHeaderFilter.columnId
    );
    return this.columnHeaderFiltersService.formatFilterConditions(
      columnHeaderFilter,
      column,
      true
    );
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.actualDataItems$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        const shouldRerenderTable = this.dataItems.length > 0;
        this.updateTableDataPointsAndCell();
        if (shouldRerenderTable) {
          this.forceRenderTable();
        }
      });
  }

  private forceRenderTable(): void {
    this.crossTabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe), take(1))
      .subscribe((data: CrossTabTableData[]) => {
        this.setupTable(data);
      });
  }

  private setupTable(data: CrossTabTableData[]): void {
    this.clearCurrentData();
    this.populateTableData(cloneDeep(data));
    this.addTableColumns();
    this.updateCellColors();
    this.targetColumns = this.crossTabService.getTargetColumns(cloneDeep(data));
  }

  private populateTableData(data: CrossTabTableData[]): void {
    const separatedRankDataSet = {};
    this.crossTabTableData = data.map((crossTabData: CrossTabTableData) => {
      return {
        ...crossTabData,
        data: crossTabData.data.filter(
          (column: CrossTabTableDataCell) =>
            column.surveyCode === this.documentService.activeSurvey.code
        ),
      };
    });

    const currentColumns = this.crossTabService.getTargetColumns(
      cloneDeep(this.crossTabTableData)
    );

    currentColumns.forEach((column) => {
      separatedRankDataSet[column.columnId] = {
        columnId: column.columnId,
        targetData: column.element,
        tableData: [],
        columnPosition: column.element.columnPosition,
      };

      // tslint:disable-next-line:no-shadowed-variable
      this.crossTabTableData.forEach((data: CrossTabTableData) => {
        let tableData: CrossTabTableDataCell;
        if (column.columnId.startsWith('totals')) {
          tableData = data.data.find(
            (columnData) => columnData.type === 'insight'
          );
        } else {
          tableData = data.data.find(
            (columnData) =>
              columnData.title === column.title && columnData.type === 'target'
          );
        }
        separatedRankDataSet[column.columnId].tableData.push({
          ...tableData,
          title: data.title,
        });
      });
    });
    this.crossTabService.setTargetColumns(this.targetColumns);

    this.separatedRankData = sortBy(separatedRankDataSet, ['columnPosition']);

    const loadedTableCount = INITIAL_SEPARATED_RANK_TABLE_COUNT;
    this.unloadedTableList = [
      ...this.separatedRankData.slice(loadedTableCount),
    ];
    this.separatedRankTableList = this.separatedRankData.slice(
      0,
      loadedTableCount
    );
    this.tableNumber = this.separatedRankData.length;
  }

  private addTableColumns(): void {
    if (this.dataItems.length > 0) {
      const dataItems = this.dataItems.map(
        (data: DataItem) => `${data.cellKey}#${data.id}`
      );
      this.displayedColumns.push(...dataItems);

      this.dataItems.forEach((dataItem: DataItem) => {
        this.dataItemMap[`${dataItem.cellKey}#${dataItem.id}`] = dataItem;
      });

      this.updateTableDataPointsAndCell();
    }
  }

  private clearCurrentData(): void {
    this.displayedColumns = ['title'];
    this.crossTabTableData = [];
    this.separatedRankData = [];
    this.dataItemMap = {};
  }

  private updateTableDataPointsAndCell(): void {
    this.dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.separatedRank
    );

    this.decimalPointMap = this.decimalPointsService.getDataItemDecimalPoints(
      this.dataItems
    );
  }

  private updateCellColors(): void {
    if (this.cellStyleStatus !== CellStyleStatus.none) {
      this.cellColors = this.colorCellsService.updateCellColors(
        this.crossTabTableData
      );
    }
  }

  private sortData(
    data: CrossTabTableData[],
    sortResult: SortResult
  ): CrossTabTableData[] {
    const sortedData: CrossTabTableData[] = cloneDeep(data);
    sortedData.forEach((insightData, insightIndex) => {
      // first row is total and will stay at the top
      if (insightIndex > 0) {
        // tslint:disable-next-line:no-shadowed-variable
        insightData.data = insightData.data.map((insightData, index) => {
          const columnId = this.crossTabService.formatTargetColumnId(
            insightData.columnTarget,
            insightData.surveyCode
          );
          let newData = { ...insightData };
          if (sortResult[columnId]) {
            if (
              sortResult[columnId].sortedData[insightIndex - 1] !== undefined
            ) {
              newData = sortResult[columnId].sortedData[insightIndex - 1];
            } else {
              newData.filteredOutCell = true;
            }
          }
          return newData;
        });
      }
    });

    return sortedData;
  }

  private getSortSettings(): SortSettings[] {
    const crossTabTableData = cloneDeep(this.crossTabTableData);
    const sortSettings: SortSettings[] = [];
    crossTabTableData[0].data.forEach((insightData, targetIndex) => {
      const columnId = this.crossTabService.formatTargetColumnId(
        insightData.columnTarget,
        insightData.surveyCode
      );
      if (this.sortResult[columnId]) {
        sortSettings.push(this.sortResult[columnId].sortSettings);
      } else {
        sortSettings.push({ ...cloneDeep(DEFAULT_SORT_OPTIONS) });
      }
    });
    return sortSettings;
  }

  private getXlsxExportData(): [string, CrosstabTableXlsxBuilder] {
    const rawData = cloneDeep(this.crossTabTableData);
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');

    const crossTabTableData: CrosstabTableXlsxData = {
      documentName,
      targetColumns: this.crossTabService.getTargetColumns(rawData),
      data: this.sortData(rawData, this.sortResult),
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: this.getSortSettings(),
      filters: this.columnHeaderFilters,
      surveys: [this.documentService.activeSurvey],
      surveyColors: this.crossTabService.getSurveyColors(
        rawData,
        this.documentService.surveys
      ),
      cellStyleStatus: this.cellStyleStatus,
      highlightValues: this.highlightValues,
      bins: this.colorCellsService.getBins(),
      cellColors: this.colorCellsService.updateCellColors(rawData),
      heatMapValues: {
        heatmapIndexPercentage: this.heatmapValues.heatmapIndexPercentage,
        heatmapQuartiles: this.heatmapValues.heatmapQuartiles,
        heatmapQuintiles: this.heatmapValues.heatmapQuintiles,
        heatmap: this.heatmapValues.heatmap,
      },
      surveyCodeMap: this.surveyCodeMap,
      defaultDecimalPoints:
        this.decimalPointsService.getActiveReportDecimalPoint(),
    };
    const entireTableRawData = cloneDeep(this.getRawTableData());
    const crossTabTableDataForEntireTable = {
      ...crossTabTableData,
      data: entireTableRawData,
      cellColors: this.colorCellsService.updateCellColors(entireTableRawData),
      filters: [],
      sortSettings: [{ ...cloneDeep(DEFAULT_SORT_OPTIONS) }],
    };
    crossTabTableBuilder.addSeparateColumnTableSheets(crossTabTableData);
    crossTabTableBuilder.addEntireTableSheet(crossTabTableDataForEntireTable);
    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private getXlsxExportDataV2(
    docName: string,
    tablebases: Target[],
    data: CrossTabTableData[][]
  ): [string, CrosstabTableXlsxBuilder] {
    const documentName = docName || this.documentService.document.metadata.name;
    const currentWeightIndex =
      this.documentService.document.content?.weight || DEFAULT_WEIGHT_INDEX;
    const currentWeight = this.weightDescriptions.filter(
      (weight) => weight.index === currentWeightIndex
    );

    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');

    tablebases.forEach((tablebase, index) => {
      const rawData = cloneDeep(data[index]);
      const crossTabTableData: CrosstabTableXlsxData = {
        documentName,
        targetColumns: this.crossTabService.getTargetColumns(rawData),
        data:
          this.activeTablebase.id === tablebase.id
            ? this.sortData(rawData, this.sortResult)
            : rawData,
        dataItems: this.dataItems,
        reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
        sortSettings:
          this.activeTablebase.id === tablebase.id
            ? this.getSortSettings()
            : rawData[0].data.map((_) => ({
                ...cloneDeep(DEFAULT_SORT_OPTIONS),
              })),
        filters: this.columnHeaderFilters,
        surveys: [this.documentService.activeSurvey],
        surveyColors: this.crossTabService.getSurveyColors(
          rawData,
          this.documentService.surveys
        ),
        cellStyleStatus: this.cellStyleStatus,
        highlightValues: this.highlightValues,
        bins:
          this.cellStyleStatus !== CellStyleStatus.none &&
          this.highlightValues?.type === ReportHighlightType.table
            ? this.colorCellsService.getBinsForCrosstabDataByTable(
                rawData,
                this.highlightValues.dataItemId,
                this.highlightValues.colors.length
              )
            : this.colorCellsService.getBins(),
        cellColors: this.getCellColorsForCrosstabData(rawData),
        heatMapValues: {
          heatmapIndexPercentage: this.heatmapValues.heatmapIndexPercentage,
          heatmapQuartiles: this.heatmapValues.heatmapQuartiles,
          heatmapQuintiles: this.heatmapValues.heatmapQuintiles,
          heatmap: this.heatmapValues.heatmap,
        },
        surveyCodeMap: this.surveyCodeMap,
        defaultDecimalPoints:
          this.decimalPointsService.getActiveReportDecimalPoint(),
        tablebase: tablebase,
        ...(currentWeight.length > 0 ? { weight: currentWeight[0] } : {}),
      };

      crossTabTableBuilder.addSeparateColumnTableSheets(
        crossTabTableData,
        crossTabTableBuilder?.['workbook']?.worksheets?.length
      );
    });

    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private getCellColorsForCrosstabData(
    data: CrossTabTableData[]
  ): Record<CellColorId, CellColor> {
    return this.colorCellsService.updateCellColors(data);
  }

  private getRawTableData(): CrossTabTableData[] {
    let rawData;
    this.crossTabService.crossTabData$
      .pipe(first())
      .subscribe((data: CrossTabTableData[]) => {
        rawData = data.map((crossTabData: CrossTabTableData) => {
          return {
            ...crossTabData,
            data: crossTabData.data.filter(
              (column: CrossTabTableDataCell) =>
                column.surveyCode === this.documentService.activeSurvey.code
            ),
          };
        });
      });
    return rawData;
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
