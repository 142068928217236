import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UserContainer } from '@telmar-global/tup-auth';
import { TupUserContainerSelectorComponent } from '@telmar-global/tup-document-storage';

export interface SaveFactorCodesDialogResult {
  container: UserContainer;
  name: string;
  exportGroup: {
    saveForCrosstab: boolean;
  };
}

@Component({
  templateUrl: './save-factor-codes-dialog.component.html',
  styleUrls: ['./save-factor-codes-dialog.component.scss'],
})
export class SaveFactorCodesDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;

  public container: UserContainer;
  public formGroup: FormGroup;

  public get disabled(): boolean {
    return this.formGroup.invalid;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SaveFactorCodesDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      exportGroup: new FormGroup(
        {
          saveForCrosstab: new FormControl(false),
        },
        this.requireCheckboxesToBeCheckedValidator()
      ),
    });
  }

  public ngAfterViewInit(): void {
    this.container = this.userContainerSelector.container;
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public saveCodes(): void {
    const dialogResult: SaveFactorCodesDialogResult = {
      container: this.container,
      ...this.formGroup.value,
    };

    this.dialogRef.close(dialogResult);
  }

  private requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }

      return null;
    };
  }
}
