import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDeleteSnapshotDialogDataModel {
  snapshotName: string;
}

@Component({
  templateUrl: './confirm-delete-snapshot-dialog.component.html',
})
export class ConfirmDeleteSnapshotDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteSnapshotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteSnapshotDialogDataModel
  ) {}

  public ngOnInit(): void {}

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }
}
