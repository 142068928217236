import { Injectable } from '@angular/core';
import { ReportPreferencesService } from './report-preferences.service';
import {
  DataItem,
  DecimalPointFormat,
  DEFAULT_DECIMAL_POINTS,
  ReportPreference,
} from '../models';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';

@Injectable({
  providedIn: 'root',
})
export class DecimalPointService {
  private decimalPoints: number;

  constructor(private reportPreferencesService: ReportPreferencesService) {
    this.reportPreferencesService.preference$
      .pipe(isNotNullOrUndefined())
      .subscribe((preference: ReportPreference) => {
        this.decimalPoints =
          preference.decimalPoints !== undefined
            ? preference.decimalPoints
            : DEFAULT_DECIMAL_POINTS;
      });
  }

  public getActiveReportDecimalPoint(): number {
    return this.decimalPoints;
  }

  public getDecimalPoints(dataItem: DataItem): number {
    return this.decimalPoints === DEFAULT_DECIMAL_POINTS
      ? dataItem.decimalPoints
      : this.decimalPoints;
  }

  public getDecimalPointFormat(dataItem: DataItem): DecimalPointFormat {
    return this.decimalPoints === DEFAULT_DECIMAL_POINTS
      ? this.formatDataItemDecimalPointFormat(dataItem)
      : this.formatDefaultDecimalPointFormat();
  }

  public getDataItemDecimalPoints(
    dataItems: DataItem[]
  ): Record<string, DecimalPointFormat> {
    return this.decimalPoints === DEFAULT_DECIMAL_POINTS
      ? dataItems.reduce(
          (prev: Record<string, DecimalPointFormat>, dataItem: DataItem) => ({
            ...prev,
            [dataItem.displayName]:
              this.formatDataItemDecimalPointFormat(dataItem),
          }),
          {}
        )
      : dataItems.reduce(
          (prev: Record<string, DecimalPointFormat>, dataItem: DataItem) => ({
            ...prev,
            [dataItem.displayName]: this.formatDefaultDecimalPointFormat(),
          }),
          {}
        );
  }

  private formatDataItemDecimalPointFormat(
    dataItem: DataItem
  ): DecimalPointFormat {
    return {
      decimalPoints: dataItem.decimalPoints,
      digitsInfo: dataItem.digitsInfo,
    };
  }

  private formatDefaultDecimalPointFormat(): DecimalPointFormat {
    return {
      decimalPoints: this.decimalPoints,
      digitsInfo: `1.${this.decimalPoints}-${this.decimalPoints}`,
    };
  }
}
