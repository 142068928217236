import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import {
  DocumentService,
  RequestLoadingService,
  TargetLoading,
  TargetService,
} from 'src/app/services';
import { FormControl } from '@angular/forms';
import {
  ALL_RESPONDENTS_CODING,
  ALL_RESPONDENTS_TITLE,
  DocumentDataState,
  EMPTY_TARGET_ITEM_INDEX,
  Target,
  TargetItem,
  TargetType,
} from '../../models';
import { MatSelect } from '@angular/material/select';
import { filter, takeUntil } from 'rxjs/operators';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

@Component({
  selector: 'app-tablebase-select',
  templateUrl: './tablebase-select.component.html',
  styleUrls: ['./tablebase-select.component.scss'],
})
export class TablebaseSelectComponent implements OnInit, OnDestroy {
  public readonly noneDeletableIndex = EMPTY_TARGET_ITEM_INDEX;

  // tslint:disable-next-line:variable-name
  private _tables: TargetItem[] = [];

  public tablebaseControl: FormControl = new FormControl();
  public tablebaseFilterControl: FormControl = new FormControl();
  public filteredTablebases: ReplaySubject<TargetItem[]> = new ReplaySubject<
    TargetItem[]
  >(1);

  @ViewChild('select', { static: true }) select: MatSelect;

  private unsubscribe: Subject<void> = new Subject<void>();

  @Output() tablebaseEdit: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Input() isReadonly = true;

  public isLoading = false;

  constructor(
    private documentService: DocumentService,
    private targetService: TargetService,
    private messageService: TupUserMessageService,
    private requestLoadingService: RequestLoadingService
  ) {}

  ngOnInit(): void {
    this.listenToTablebaseDataChanges();
    this.listenToActiveTablebaseChanges();
    this.listenToTablebaseSelectChanges();
    this.listenToTablebaseFilterChanges();
    this.listenToLoadingState();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public resetFilterText(): void {
    this.tablebaseFilterControl.setValue('');
  }

  public onNewTablebaseClicked(): void {
    const innerTarget = this.targetService.createTarget({
      title: ALL_RESPONDENTS_TITLE,
      coding: ALL_RESPONDENTS_CODING,
    });
    this.tablebaseEdit.emit({
      type: TargetType.tables,
      target: {
        ...innerTarget,
        targets: [innerTarget],
      },
      index: EMPTY_TARGET_ITEM_INDEX,
    });
    this.select.close();
  }

  public onEditTablebaseClicked(targetItem: TargetItem): void {
    this.tablebaseEdit.emit(
      targetItem.index === this.noneDeletableIndex
        ? {
            type: TargetType.tables,
            target: this.targetService.shallowCopyTarget(targetItem.target),
            index: EMPTY_TARGET_ITEM_INDEX,
          }
        : targetItem
    );
    this.select.close();
  }

  public onRemoveTablebaseClicked(targetItem: TargetItem): void {
    this.messageService
      .openDialog(
        'Are you sure you want to delete this table?',
        'Delete tablebase item',
        { confirmText: 'OK', cancelText: 'Cancel' }
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: boolean) => {
        if (result) {
          this.documentService.deleteDocumentData(
            [targetItem],
            targetItem.target === this.tablebaseControl.value
          );
        }
      });
    this.select.close();
  }

  public onSelectPanelOpenedChanged(): void {
    this.resetFilterText();
  }

  private listenToTablebaseDataChanges(): void {
    combineLatest([
      this.documentService.documentState$,
      this.documentService.tablebases$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([{ tables }, tablebases]: [DocumentDataState, Target[]]) => {
        this._tables = [
          ...tables.map((target: Target, index: number) => ({
            type: TargetType.tables,
            target,
            index:
              target.coding !== ALL_RESPONDENTS_CODING
                ? index
                : this.noneDeletableIndex,
          })),
          ...tablebases.map((target: Target) => ({
            type: TargetType.tables,
            target,
            index: this.noneDeletableIndex,
          })),
        ];
        this.filteredTablebases.next(this._tables);
      });
  }

  private listenToActiveTablebaseChanges(): void {
    this.documentService.activeTablebase$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((target: Target) => {
        if (this.tablebaseControl.value !== target) {
          this.tablebaseControl.setValue(target);
        }
      });
  }

  private listenToTablebaseSelectChanges(): void {
    this.tablebaseControl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((target: Target) => {
        if (target) {
          this.documentService.setActiveTable(target);
        }
      });
  }

  private listenToTablebaseFilterChanges(): void {
    this.tablebaseFilterControl.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.filterTablebases();
      });
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private filterTablebases() {
    if (!this._tables) {
      return;
    }

    let search = this.tablebaseFilterControl.value;
    if (!search) {
      this.filteredTablebases.next(this._tables);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTablebases.next(
      this._tables.filter(
        (targetItem: TargetItem) =>
          targetItem.target.title.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
