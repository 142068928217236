import {
  getColumn,
  TupDocument,
  TupDocumentStorageOptions,
} from '@telmar-global/tup-document-storage';

import { environment } from 'src/environments/environment';

export const dashboardTabOptions: TupDocumentStorageOptions = {
  actions: [
    'click:duplicate:file_copy::true',
    'click:delete:highlight_off',
    'click:export:file_download::true',
  ],
  columns: [
    getColumn(environment, 'actions'),
    getColumn(environment, 'metadata.name.keyword', {
      header: 'My quick reports',
      sortable: false,
      tooltip: (document: TupDocument<unknown>) => document.metadata.name,
    }),
    getColumn(environment, 'metadata.modified', { sortable: false }),
    getColumn(environment, 'document.content.surveys'),
  ],
  pageSize: 5,
  panel: false,
} as TupDocumentStorageOptions;
