<ng-template #delete>
  <p>
    Deleting
    <b
      ><i>{{ metadata.name }}</i></b
    >
    is a permanent action and cannot be undone. Are you sure you want to delete
    <b
      ><i>{{ metadata.name }}</i></b
    >?
  </p>
</ng-template>
