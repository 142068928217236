import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import {
  CodingGridHideColumnMap,
  CodingGridTableRow,
  EMPTY_GRID_ROW_SIZE,
  MoreMenuVisibility,
  MoreMenuVisibilityOptions,
  SendCodingGridTableRow,
  TargetType,
  TitleModeGridTableRow,
} from 'src/app/models';
import {
  COMBINE_BUTTON_ACTION_ITEMS,
  ColumnRowActionItem,
  CombineActionItem,
  SEND_TO_ACTION_ITEMS,
  TITLE_ACTIONS,
} from 'src/app/models/action.model';

@Component({
  selector: 'app-coding-grid-action-controls',
  templateUrl: './coding-grid-action-controls.component.html',
  styleUrls: ['./coding-grid-action-controls.component.scss'],
})
export class CodingGridActionControlsComponent {
  public sendToActionItems = SEND_TO_ACTION_ITEMS;
  public combineActionItems = COMBINE_BUTTON_ACTION_ITEMS;
  public readonly emptyGridRowSize = EMPTY_GRID_ROW_SIZE;
  public readonly targetType: typeof TargetType = TargetType;
  public changeTitleModeItems = (TITLE_ACTIONS[0] as ColumnRowActionItem)
    .actions;
  public readonly moreMenuVisibilityOptions = MoreMenuVisibilityOptions;

  @Input() isDeletable = false;
  @Input() isReadonly = false;
  @Input() isAddToRowsOnly = false;
  @Input() swapDisabled = false;
  @Input() findAndReplaceDialogOpen = false;
  @Input() findAndReplaceDialogState = false;
  @Input() swapActionWarning = '';
  @Input() selectedRows: CodingGridTableRow[] = [];
  @Input() selectedTab = this.targetType.tables;
  @Input() isColumnHidden = false;
  @Input() hideColumnItems: CodingGridHideColumnMap =
    {} as CodingGridHideColumnMap;
  @Input() moreMenuVisibility: MoreMenuVisibility;

  @Output() openFindAndReplaceClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() deleteSelectedRowsClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() swapRowsAndColumnsClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() separateCountActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() openNTileSettingsActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() openNewCodeBuilderActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() duplicateSelectedRowsActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() renameGroupNameActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() saveCustomAudienceActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() deleteCodingGridActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() openMoreMenuActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() hideColumnsActionClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() combineActionClicked: EventEmitter<CombineActionItem> =
    new EventEmitter<CombineActionItem>();
  @Output()
  changeTargetTitleModeActionClicked: EventEmitter<TitleModeGridTableRow> = new EventEmitter<TitleModeGridTableRow>();
  @Output() sendToActionClicked: EventEmitter<SendCodingGridTableRow> =
    new EventEmitter<SendCodingGridTableRow>();

  @ViewChild('hideColumnSelectionList', { static: true })
  public hideColumnSelectionList: MatSelectionList;

  public onOpenFindAndReplace(): void {
    this.openFindAndReplaceClicked.emit();
  }

  public onDeleteSelectedRows(): void {
    this.deleteSelectedRowsClicked.emit();
  }

  public onSwapRowsAndColumns(): void {
    this.swapRowsAndColumnsClicked.emit();
  }

  public onSeparateCountActionClicked(): void {
    this.separateCountActionClicked.emit();
  }

  public onOpenNTileSettings(): void {
    this.openNTileSettingsActionClicked.emit();
  }

  public onOpenNewCodeBuilder(): void {
    this.openNewCodeBuilderActionClicked.emit();
  }

  public onDuplicateSelectedRows(): void {
    this.duplicateSelectedRowsActionClicked.emit();
  }

  public onRenameGroupName(): void {
    this.renameGroupNameActionClicked.emit();
  }

  public onSaveCustomAudience(): void {
    this.saveCustomAudienceActionClicked.emit();
  }

  public onDeleteCodingGrid(): void {
    this.deleteCodingGridActionClicked.emit();
  }

  public onOpenMoreMenu(): void {
    this.openMoreMenuActionClicked.emit();
  }

  public onHideColumns(): void {
    this.hideColumnsActionClicked.emit();
  }

  public onCombineActionClicked(actionItem: CombineActionItem): void {
    this.combineActionClicked.emit(actionItem);
  }

  public onChangeTargetTitleModeActionClicked(
    actionItem: TitleModeGridTableRow
  ): void {
    this.changeTargetTitleModeActionClicked.emit(actionItem);
  }

  public onSendTo(sendRow: SendCodingGridTableRow): void {
    this.sendToActionClicked.emit(sendRow);
  }
}
