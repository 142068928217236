import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { CHART_COLORS } from 'src/app/models/charts.model';

@Component({
  selector: 'chart-color-dialog',
  templateUrl: './chart-color-dialog.component.html',
  styleUrls: ['./chart-color-dialog.component.scss'],
})
export class ChartColorDialogComponent implements OnInit {
  public chosenColor = '#0054ff';
  public targetTitle;
  public changed = false;
  public colors = CHART_COLORS.slice(0, -1);

  constructor(
    public dialogRef: MatDialogRef<ChartColorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private analyticsService: TupAnalyticsService
  ) {
    this.targetTitle = data.target;
    this.colors = data.colors || this.colors;
  }

  ngOnInit(): void {
    this.analyticsService.e('Color picker', 'Color picker');
  }

  onClose() {
    this.dialogRef.close(this.changed ? this.chosenColor : null);
  }

  handleColorChange($event) {
    this.changed = true;
    this.chosenColor = $event.color.hex;
  }

  changeColor() {
    this.dialogRef.close(this.chosenColor);
  }
}
