import { Injectable } from '@angular/core';
import {
  ColumnFilter,
  ColumnHeaderFilter,
  DATA_ITEMS_MAP,
  TargetColumn,
} from '../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClearGloballyFilter } from '../models/separated-rank.model';

@Injectable({
  providedIn: 'root',
})
export class ColumnHeaderFiltersService {
  private clearAllFiltersSubject: BehaviorSubject<ClearGloballyFilter> =
    new BehaviorSubject<ClearGloballyFilter>(null);
  public clearAllFilters$: Observable<ClearGloballyFilter> =
    this.clearAllFiltersSubject.asObservable();

  public formatFilterConditions(
    columnHeaderFilter: ColumnHeaderFilter,
    targetColumn: TargetColumn,
    conditionOnly?: boolean
  ): string {
    const columnTitle = targetColumn ? targetColumn.title : '';
    const filterConditions = columnHeaderFilter.filters.map(
      (columnFilter: ColumnFilter, index: number) => {
        const dataItemName = DATA_ITEMS_MAP[columnFilter.dataItem].displayName;
        const conditionalOperator =
          columnFilter.conditionalOperator.toLowerCase();
        const value =
          conditionalOperator === 'is between'
            ? '(' + columnFilter.value.join(', ') + ')'
            : columnFilter.value[0];
        const filterOperator = index > 0 ? ` ${columnFilter.operator}` : '';

        return `${filterOperator} ${dataItemName} ${conditionalOperator} ${value}`;
      }
    );

    let filterCondition = `${columnTitle}: ${filterConditions.join(' ')}`;
    if (conditionOnly) {
      filterCondition = `${filterConditions.join(' ')}`;
    }

    return filterCondition;
  }

  public clearFilters(data: ClearGloballyFilter) {
    this.clearAllFiltersSubject.next(data);
  }
}
