<div
  fxFill
  fxLayout="column"
>
  <app-top-bar
    [selectViewType]="selectViewType"
    [showSurveyInfo]="false"
    [isReadonly]="isReadonly"
    [canExportHighcharts]="hasValidDataset"
    (exportToPNG)="exportTo('image/png', $event)"
    (exportToJPEG)="exportTo('image/jpeg', $event)"
    (exportToPDF)="exportTo('application/pdf', $event)"
    (exportToSVG)="exportTo('image/svg+xml', $event)"
  ></app-top-bar>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="10px"
    class="geografix-control-bar"
    *ngIf="hasValidDataset"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        class="input-form-field select-small"
        style="width: 180px"
        matTooltip="Select column"
        tupMatFormField
        [class.is-disabled]="inProgress"
      >
        <mat-select
          [(ngModel)]="selectedColumn"
          (ngModelChange)="onSelectedColumnChange()"
        >
          <mat-option
            [value]="column"
            *ngFor="let column of columns"
          >
            {{ column | targetTitle }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="input-form-field select-small"
        style="width: 120px"
        tupMatFormField
        matTooltip="Select data item"
        [class.is-disabled]="inProgress"
      >
        <mat-label>Select Data item</mat-label>
        <mat-select
          [(ngModel)]="selectedDataItem"
          (ngModelChange)="onSelectedDataItemChange()"
          [disabled]="inProgress"
        >
          <mat-option
            *ngFor="let dataItem of dataItems"
            [value]="dataItem"
          >
            {{ dataItem.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="end start"
      fxLayoutGap="10px"
    >
      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Full screen"
        (click)="openFullScreen()"
      >
        <mat-icon class="tiny-icon">open_in_full</mat-icon>
      </button>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayouGap="10px"
    *ngIf="chartOptions"
    class="top-border"
  >
    <tup-highcharts
      fxFlex="80"
      id="container"
      [Highcharts]="Highcharts"
      [constructorType]="chartConstructor"
      [options]="chartOptions"
      [callbackFunction]="chartCallback"
      class="geografix-map"
    >
    </tup-highcharts>

    <div
      fxLayout="column"
      fxLayoutGap="10px"
      fxFlex="20"
      class="geografix-heatmap-section"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <mat-label class="heatmap-title">Heatmap</mat-label>
        <mat-slide-toggle
          class="coding-method-toggle"
          color="primary"
          [(ngModel)]="isHeatMapEnabled"
          (change)="renderChart()"
        >
        </mat-slide-toggle>
      </div>

      <mat-divider></mat-divider>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        class="input-form-field more-margin"
      >
        <mat-label>Heatmap Distribution</mat-label>
        <mat-select
          [(ngModel)]="selectedHeatmapOption"
          (selectionChange)="onHeatMapDistributionChange()"
          [disabled]="!isHeatMapEnabled"
        >
          <mat-option
            *ngFor="let option of heatMapOptions"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-list *ngIf="isHeatMapEnabled">
        <mat-list-item *ngFor="let option of heatMapQuantiles">
          <div
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="center center"
          >
            <span
              class="dot"
              [style.backgroundColor]="option.background"
            ></span>
            <mat-label> {{ option.label }} </mat-label>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <tup-mat-card
    class="empty-copy"
    fxLayout="column"
    *ngIf="!hasValidDataset && !inProgress"
  >
    <span *ngIf="!isReadonly">
      Geografix isn't available. Please review the coding statements in the
      columns and rows in the crosstab.
    </span>
    <span *ngIf="isReadonly"> No Geografix data. </span>
  </tup-mat-card>

  <div
    class="spinner-container-overlay"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="inProgress"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>
