import {
  ChartSearchHit,
  ChartSearchLimitSettings,
  ChartSettings,
  ChartStyleSettings,
  ChartTargetMode,
} from './charts.model';
import { Survey } from './survey.model';
import { DefaultDataItem } from './data-item.model';
import {
  CellStyleStatus,
  ColumnHeaderFilter,
  CrosstabSearchHit,
  CrosstabSearchLimitSettings,
  HeatmapValues,
  HighlightValues,
  ReportMode,
  SortSettings,
} from './crosstab.model';
import { PMapsPreferences } from './p-maps.model';
import {
  CleaningMethodOptionsItem,
  CleaningMethodType,
  ClusterFeatureItem,
  ClusterSettings,
} from './cluster.model';
import {
  CorrespondenceFeatureItem,
  CorrespondenceFilterOptions,
  CorrespondenceSettings,
} from './correspondence.model';
import {
  AudienceEffectsSettings,
  AudienceEffectsVariableTableRowData,
  TargetColumnDataItemMap,
} from './audience-effects.model';
import {
  CodingGridSearchHit,
  CodingGridSearchLimitSettings,
} from './coding-grid.model';
import {
  FactorAnalysisSettings,
  FactorFeatureItem,
} from './factor-analysis.model';

export const SURVEYTIME_DOCUMENT_VERSION = 1;
export const SURVEYTIME_TARGET_VERSION = 1;
export const APPLICATION_NAME = 'Explore';
export const REPORT_PREFERENCE_VERSION = 'v1';

export enum Operator {
  or = 'OR',
  and = 'AND',
  andNot = 'AND NOT',
  orNot = 'OR NOT',
  separate = 'SEPARATE',
  separator = '|',
  count = 'COUNT',
  auto = 'AUTO', // note: is actually OR operator
  plus = '+',
  divide = '/',
  vind = 'VIND',
  vdiv = 'VDIV',
  vmul = 'VMUL',
  ampersand = '&',
  dot = '.',
  comma = ',',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  equal = '=',
  notEqual = '<>',
  range = 'RAN',
  any = 'ANY',
  all = 'ALL',
  solus = 'SOLUS',
  sum = 'SUM',
  ntiles = 'NTILES',
  volumetric = 'VOLUMETRIC',
}

export interface Note {
  title: string;
  description: string;
  associatedTarget: string;
  associatedInsight: string;
}

export interface Target {
  id: string;
  fileVersion: number;
  title: string;
  titleLevels?: number[];
  shortTitle?: string;
  ownTitle?: string;
  activeTitleMode?: DisplayType;
  titlePrefix?: Operator; // note: only for solus/ntiles operator and the target cannot be expanded
  coding: string;
  validWeights?: number[];
  countCoding?: {
    operator: Operator;
    value: string;
  };
  nTilesCoding?: boolean;
  volumetricCoding?: {
    calculationMethod: string;
    decimal: string;
  };
  operator: Operator;
  created: number;
  audience?: number;
  resps?: number;
  percent?: number;
  targets: Target[];
  groupName?: string;
  selectedForPlan?: boolean;
  isDefaultTablebase?: boolean;
}

export interface ReportPreference {
  sortSettings: SortSettings | SortSettings[];
  filters: ColumnHeaderFilter[];
  heatMapValues: HeatmapValues;
  highlightValues: HighlightValues;
  cellStyleStatus: CellStyleStatus;
  stabilityFlagOn: boolean;
  completeCasesOn: boolean;
  dataItems?: DefaultDataItem[];
  decimalPoints?: number;
  version: string;
}

export interface CodingGridPreference {
  hiddenColumns: string[];
}

export type ReportPreferences = Record<ReportMode, ReportPreference>;

export type CodingGridPreferences = Record<TargetType, CodingGridPreference>;

export type DefaultChartStyles = Record<
  ChartTargetMode,
  ChartStyleSettings | null
>;

export enum SurveyTimeDocumentAppType {
  cluster = 'clustering',
  fa = 'factorAnalysis',
  ca = 'correspondence',
  ae = 'audienceEffects',
}

export type SurveyTimeDocumentApp =
  | SurveyTimeDocumentClusterApp
  | SurveyTimeDocumentCorrespondenceApp
  | SurveyTimeDocumentAudienceEffectsApp
  | SurveyTimeDocumentFactorApp;

export interface SurveyTimeDocumentFactorApp {
  tablebase: Target;
  researchId: string;
  features: FactorFeatureItem[];
  survey: Survey;
  version: string;
  factorTitles?: string[];
  settings: FactorAnalysisSettings;
  multiSort: boolean;
}

export interface SurveyTimeDocumentClusterApp {
  tablebase: Target;
  researchId: string;
  features: ClusterFeatureItem[];
  survey: Survey;
  numOfFactors?: number;
  maxNumOfClusters?: number;
  cleaningMethod?: CleaningMethodType;
  cleaningMethodOptions?: CleaningMethodOptionsItem;
  settings?: ClusterSettings;
  activeTitleMode: DisplayType;
  titleLevels?: number[];
  version: string;
  clusterTitles?: string[];
}

export interface SurveyTimeDocumentCorrespondenceApp {
  survey: Survey;
  rows: CorrespondenceFeatureItem[];
  columns: CorrespondenceFeatureItem[];
  settings: CorrespondenceSettings;
  filterOptions: CorrespondenceFilterOptions;
  activeTitleMode: DisplayType;
  titleLevels?: number[];
  version: string;
}

export interface SurveyTimeDocumentAudienceEffectsApp {
  tablebase: Target;
  weight: number;
  survey: Survey;
  variableTableData: AudienceEffectsVariableTableRowData[];
  rows: Target[];
  column: Target;
  targetMinMaxDataMap: TargetColumnDataItemMap;
  settings: AudienceEffectsSettings;
  activeTitleMode: DisplayType;
  titleLevels?: number[];
  version: string;
}

export type SurveyTimeDocumentApps = Partial<
  Record<SurveyTimeDocumentAppType, SurveyTimeDocumentApp>
>;

export class SurveyTimeDocument {
  fileVersion = SURVEYTIME_DOCUMENT_VERSION;
  surveys: Survey[];
  activeSurveyId?: string;
  audience = 0;
  resps = 0;
  percent = 0;
  weight = DEFAULT_WEIGHT_INDEX;
  status: string;
  tables: Target[];
  columns: Target[];
  rows: Target[];
  notes: Note[];
  chartSettings: ChartSettings[];
  activeTableId?: string;
  activeTitleMode?: DisplayType;
  titleLevels?: number[];
  reportPreferences?: ReportPreferences;
  pMapsPreferences?: PMapsPreferences | null;
  trendings?: TrendingCalculation[];
  apps?: SurveyTimeDocumentApps | null;
  survey?: Survey;
  codingGridPreferences?: CodingGridPreferences;
  defaultChartStyles?: DefaultChartStyles;
  snapshots?: Snapshot[];

  constructor() {
    this.surveys = [];
    this.tables = [];
    this.columns = [];
    this.rows = [];
    this.notes = [];
    this.chartSettings = [];
  }
}

export interface DocumentDataState {
  tables: Target[];
  columns: Target[];
  rows: Target[];
  changes?: DocumentDataChange[];
}

export enum TargetAction {
  create = 0,
  update = 1,
  delete = 2,
}

export interface DocumentDataChange {
  action: TargetAction;
  items: TargetItem[];
}

export interface TargetItem {
  type: TargetType;
  target: Target;
  index: number;
}

export enum TargetType {
  tables = 0,
  columns = 1,
  rows = 2,
}

export const TARGET_TYPE_MAP: Record<TargetType, string> = {
  [TargetType.tables]: 'tables',
  [TargetType.columns]: 'columns',
  [TargetType.rows]: 'rows',
};

export enum ViewType {
  crossTab = 0,
  codingGrid = 1,
}

export enum DocumentViewType {
  data = 0,
  graph = 1,
  pmaps = 2,
  cluster = 3,
  correspondence = 4,
  geografix = 5,
  audienceEffects = 6,
  factorAnalysis = 7,
}

export enum DisplayType {
  title = 'title',
  shortTitle = 'shortTitle',
  ownTitle = 'ownTitle',
  coding = 'coding',
  levels = 'levels',
  group = 'group',
}

export const levelSeparator = '~>';

export const titleSeparator = '~';

export const ALL_RESPONDENTS_CODING = '#1';

export const ALL_RESPONDENTS_TITLE = 'All Respondents';

export const EMPTY_TARGET_ITEM_INDEX = -1;

export const NEW_TARGET_TITLE = 'My coding';
export const NEW_TARGET_CODING = 'NEW_CODE';

export const MAX_COLUMN_TARGET_LIMIT = 150;

export const MAX_ROW_TARGET_LIMIT = 5000;

export const MAX_COMBINED_TARGETS = 300;

export const MAX_NUMBER_TREND_SURVEYS = 5;

export const DEFAULT_SURVEY_COLOR = '#ffffff';

export const CODEBOOK_INITIAL_SIZE_IN_PIXEL = 405;
export const CODEBOOK_MIN_SIZE_IN_PIXEL = 270;
export const CODEBOOK_EDITING_MODE_SIZE_IN_PIXEL = 1000;

export const DEFAULT_WEIGHT_INDEX = 1;

export enum TrendingCalculationType {
  diff = 0,
  percentDiff = 1,
  sum = 3,
  average = 2,
}

export enum TrendingCalculationCoding {
  diff = 'DIF',
  percentDiff = 'PER',
  sum = 'SUM',
  average = 'AVG',
}

export const TRENDING_CALCULATION_REGEX_DEFAULT = /\(([^)]+)\)/;

export const TRENDING_CALCULATION_REGEX_PERCENT = /\(([^)]+)\):\s*(.+)/;

export interface TrendingCalculationItem {
  coding: string;
  surveys: Survey[];
}

export interface TrendingCalculation {
  coding?: string;
}

export interface SearchHit {
  documentType: DocumentViewType;
  viewType?: ViewType;
  details: CrosstabSearchHit | CodingGridSearchHit | ChartSearchHit;
  targetId?: string;
  replaced?: boolean;
  scrollIntoView?: boolean;
}

export interface SearchSettings {
  documentViewType: DocumentViewType;
  searchViewType: ViewType;
  matchCases: boolean;
  crosstabLimits: CrosstabSearchLimitSettings;
  codingGridLimits: CodingGridSearchLimitSettings;
  chartLimits: ChartSearchLimitSettings;
}

export interface GetSurveyResponse {
  success: boolean;
  survey: Survey;
}

export interface Snapshot {
  docId: string;
  name: string;
  createdTime: Date;
}

export const MAX_SNAPSHOTS_LIMIT: number = 20;
