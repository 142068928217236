<div class="table-container">
  <div class="correspondence-table-viewport-container">
    <cdk-virtual-scroll-viewport
      [tvsItemSize]="33"
      headerEnabled="true"
      bufferMultiplier="4"
      headerHeight="44"
      class="virtual-scroll-viewport"
    >
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
        class="non-standard-table correspondence-table"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          matColumnDef="{{ column }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!columnMap[column]['sortable']"
            class="table-header-cell"
          >
            <div *ngIf="column.startsWith(factorAbsRelRowPrefix)">
              <div>
                {{ columnMap[column]["name"].split("\n")[0] }}
              </div>
              <div>
                {{ columnMap[column]["name"].split("\n")[1] }}
              </div>
            </div>
            <div *ngIf="!column.startsWith(factorAbsRelRowPrefix)">
              {{ columnMap[column]["name"] }}
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-align-left]="columnMap[column]['align'] === 'left'"
            [class.text-align-center]="columnMap[column]['align'] === 'center'"
            [class.text-align-right]="columnMap[column]['align'] === 'right'"
            [ngClass]="[
              column.startsWith(factorAbsRelRowPrefix)
                ? (selectedTableDataMode === correspondenceTableDataMode.factor
                    ? element.segments[
                        correspondenceTableDataModeKeys.coordinates
                      ]
                    : element.segments[
                        correspondenceTableDataModeKeys.absContribution
                      ])[column.split('-')[2]]?.segment || ''
                : '',
              column.startsWith('inf')
                ? (selectedTableDataMode === correspondenceTableDataMode.factor
                    ? element.segments[
                        correspondenceTableDataModeKeys.coordinates
                      ]
                    : element.segments[
                        correspondenceTableDataModeKeys.absContribution
                      ])[0]?.segment || ''
                : '',
              isTableHighlighted && !element.visible
                ? 'active-text passive-row'
                : element['visible']
                ? 'active-text'
                : 'passive-text'
            ]"
          >
            <div *ngIf="column === 'visible'">
              <button
                class="visibility-button"
                [style.background-color]="
                  !element['visible'] ? '#e2e2e2' : '#f9f9f9'
                "
                color="primary"
                tupMatButton
                mat-stroked-button
                appearance="tiny"
                (click)="updateVisibility(element)"
              >
                <mat-icon>{{
                  element["visible"] ? "visibility" : "visibility_off"
                }}</mat-icon>
              </button>
            </div>

            <div *ngIf="column === 'rowNumber'">
              <span class="row-number">{{ element[column] }}</span>
            </div>

            <span *ngIf="column === 'type'">{{
              getCorrespondenceRowType(element[column])
            }}</span>
            <span *ngIf="!['visible', 'rowNumber', 'type'].includes(column)">{{
              element[column]
            }}</span>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          class="row-container"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.is-sticky-row]="row.isSticky"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
