<div
  fxLayout="row"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
  class="audience-effects-gain-layout"
>
  <div class="audience-effects-gain-section">
    <div [hidden]="selectedGainAnalysisMode !== 'table'">
      <app-gain-analysis-table
        #gainTable
        [selectedGainAnalysisMode]="selectedGainAnalysisMode"
        (selectedRowChange)="onSelectedRowChange($event)"
        [chaidTableData]="gainData"
        [chaidAnalysis]="chaidAnalysis"
        [chartSelectedRow]="chartSelectedRow"
        [settings]="settings"
        [survey]="survey"
        [activeTableTitle]="activeTableTitle"
        id="gain-table"
      >
      </app-gain-analysis-table>
    </div>
    <div
      [hidden]="
        !(
          selectedGainAnalysisMode === 'chart' &&
          settings.gainPlotChartType === 0
        )
      "
      id="audience-effects-gain-scatter-chart"
    >
      <tup-highcharts
        [Highcharts]="Highcharts"
        [callbackFunction]="chartCallback"
        [options]="scatterChartOptions"
        class="audience-effects-gain-chart"
      >
      </tup-highcharts>
    </div>

    <div
      [hidden]="
        !(
          selectedGainAnalysisMode === 'chart' &&
          settings.gainPlotChartType === 1
        )
      "
      id="audience-effects-gain-column-chart"
    >
      <tup-highcharts
        [Highcharts]="Highcharts"
        [callbackFunction]="chartCallback"
        [options]="columnChartOptions"
        class="audience-effects-gain-chart"
      >
      </tup-highcharts>
    </div>
  </div>

  <div
    fxLayout="column"
    class="audience-effects-acc-section"
  >
    <tup-highcharts
      *ngIf="accumulatedChartOptions"
      [Highcharts]="Highcharts"
      [callbackFunction]="accChartCallback"
      [options]="accumulatedChartOptions"
      class="audience-effects-acc-chart"
    >
    </tup-highcharts>

    <div
      *ngIf="accDataSource"
      class="scrollable-table"
    >
      <table>
        <tr *ngFor="let element of accDataSource">
          <td>
            <span class="acc-list-title">{{ element.label }}</span>
          </td>
          <td>
            <span class="acc-list-text">{{ element.value }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
