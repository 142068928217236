import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CtrlShiftKeyStates } from '../../directives';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ClusterVariableRowData } from 'src/app/models';
import { cloneDeep, isEqual } from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

type RowCoding = string;
type IsSelected = boolean;

@Component({
  templateUrl: './variable-importance-dialog.component.html',
  styleUrls: ['./variable-importance-dialog.component.scss'],
})
export class VariableImportanceDialogComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['selected', 'title', 'maxDetermination'];
  public dataSource: MatTableDataSource<ClusterVariableRowData> =
    new TableVirtualScrollDataSource([]);
  private shiftPressed: boolean;

  public selectedRowCodings: Record<RowCoding, IsSelected> = {};
  private originalSelectedRowCodings: Record<RowCoding, IsSelected> = {};
  public selectedRows = 0;
  private rowCodings: RowCoding[] = [];

  private lastSelectedRowCoding: RowCoding;
  private tableData: ClusterVariableRowData[];

  public isReClusterEnabled = false;

  constructor(
    public dialogRef: MatDialogRef<VariableImportanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClusterVariableRowData[]
  ) {
    this.originalSelectedRowCodings = data.reduce(
      (acc, row) => ({
        ...acc,
        [row.coding]: row.selected,
      }),
      {}
    );
    this.tableData = cloneDeep(data);
    this.setupTable(this.tableData);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.clearCurrentData();
  }

  private setupTable(data: ClusterVariableRowData[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.selectAllCodings(data);
  }

  private selectAllCodings(data: ClusterVariableRowData[]): void {
    data.forEach((row: ClusterVariableRowData) => {
      this.selectedRowCodings[row.coding] = row.selected;
    });
    this.updateSelectedRows();
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
  }

  public onCtrlShift(states: CtrlShiftKeyStates): void {
    this.shiftPressed = states.shiftPressed;
  }

  public isAllSelected() {
    return this.selectedRows === this.dataSource.data.length;
  }

  public onAllRowsSelectedChange(event: MatCheckboxChange): void {
    this.setAllRowsSelected(event.checked);
    this.lastSelectedRowCoding = null;
  }

  public onSingleRowSelectedChange(
    event: MatCheckboxChange,
    element: ClusterVariableRowData
  ): void {
    const selectedRowCoding = element.coding;

    if (this.shiftPressed && this.lastSelectedRowCoding) {
      const lastSelectedRowIndex = this.rowCodings.indexOf(
        this.lastSelectedRowCoding
      );
      const selectedRowIndex = this.rowCodings.indexOf(selectedRowCoding);

      let lowerIndex = selectedRowIndex;
      let upperIndex = lastSelectedRowIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedRowIndex;
        upperIndex = selectedRowIndex;
      }

      for (let i = lowerIndex; i <= upperIndex; i++) {
        const coding = this.rowCodings[i];

        this.selectedRowCodings[coding] = event.checked;
      }
    } else {
      this.selectedRowCodings[selectedRowCoding] = event.checked;
    }

    this.lastSelectedRowCoding = selectedRowCoding;
    this.updateSelectedRows();
  }

  public onReClusterClick(): void {
    const variables: ClusterVariableRowData[] = this.tableData.map((row) => ({
      ...row,
      selected: this.selectedRowCodings[row.coding] || false,
    }));
    this.dialogRef.close(variables);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  private populateTableData(data: ClusterVariableRowData[]): void {
    this.dataSource.data = data;
  }

  private setAllRowsSelected(isSelected: boolean): void {
    this.dataSource.data.forEach((row: any) => {
      this.selectedRowCodings[row.coding] = isSelected;
    });
    this.updateSelectedRows();
  }

  private updateSelectedRows(): void {
    this.selectedRows = Object.keys(this.selectedRowCodings).filter(
      (key: string) => this.selectedRowCodings[key] === true
    ).length;
    this.isReClusterEnabled = !isEqual(
      this.selectedRowCodings,
      this.originalSelectedRowCodings
    );
  }
}
