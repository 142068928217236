import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentViewType, ViewType } from 'src/app/models';

export const RESEARCH_NOT_FOUND_ERROR_MESSAGE = 'research session not found';

@Component({
  templateUrl: './cluster-error-dialog.component.html',
  styleUrls: ['./cluster-error-dialog.component.scss'],
})
export class ClusterErrorDialogComponent implements OnInit {
  public researchSessionNotFoundErrorMessage = RESEARCH_NOT_FOUND_ERROR_MESSAGE;
  public errorMessage: string;
  public isReadonly: boolean = true;
  public resetDoc: boolean = false;
  public documentViewType: DocumentViewType = DocumentViewType.cluster;
  public readonly documentViewTypeOptions: typeof DocumentViewType =
    DocumentViewType;

  constructor(
    public dialogRef: MatDialogRef<ClusterErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClusterErrorDialogComponent
  ) {
    if (!data) return;
    this.errorMessage = data.errorMessage;
    this.isReadonly = data.isReadonly;
    this.documentViewType = data?.documentViewType || DocumentViewType.cluster;
  }

  ngOnInit(): void {}

  public save() {
    this.dialogRef.close({
      action: ViewType.crossTab,
      resetDoc: this.resetDoc,
    });
  }

  public close() {
    this.dialogRef.close('');
  }
}
