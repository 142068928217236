import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LoadingNode, LoadingSource } from '@telmar-global/tup-audience-groups';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Statement, TargetType } from 'src/app/models';
import {
  CombineActionItem,
  COMBINE_BUTTON_ACTION_ITEMS,
} from 'src/app/models/action.model';
import { CodebookSelectionService } from 'src/app/services';
import { QuickReportService } from 'src/app/services/quick-report.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { CodingGridService } from '../../../services/coding-grid.service';

@Component({
  selector: 'app-action-controls',
  templateUrl: './action-controls.component.html',
  styleUrls: ['./action-controls.component.scss'],
})
export class ActionControlsComponent implements OnInit, OnDestroy {
  public actionItems: CombineActionItem[] = COMBINE_BUTTON_ACTION_ITEMS;
  public isLoadingFromButtons = false;
  public isLoadingSelectedNodes = false;
  public selectedNodes: Statement[] = [];
  private isDraggingCodingGridRow = false;
  public targetType = TargetType;

  @Output() separateClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() separateCountClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() addToMultipleClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() expandAllClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() combineActionClick: EventEmitter<{
    actionItem: CombineActionItem;
    type: TargetType;
  }> = new EventEmitter<{
    actionItem: CombineActionItem;
    type: TargetType;
  }>();

  private unsubscribe: Subject<void> = new Subject<void>();
  public addToRowsOnly = false;

  private activeMenuTrigger: MatMenuTrigger;
  private combinedMenuTrigger: MatMenuTrigger = null;
  private isOverActiveMenu = false;
  private menuTarget: EventTarget;
  public dropzoneEnterCount = 0;
  private isOverActionMenu = false;
  public isDropzoneMenuOpen = false;
  public readonly menuItemDragoverClass = 'drag-over-menu-button';

  @ViewChild('virtualButton') public virtualButton;

  constructor(
    private codebookSelectionService: CodebookSelectionService,
    private codingGridService: CodingGridService,
    private quickReportService: QuickReportService
  ) {}

  ngOnInit(): void {
    this.listenToSelectedNodesChanges();
    this.listenToCodingGridSelectRowChanges();
    this.listenToQuickReportChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoadingSelectedNodes = false;
    this.isLoadingFromButtons = false;
  }

  public onUnselectAll(): void {
    this.codebookSelectionService.unselectNodes();
  }

  public clickSeparateButton(type: TargetType): void {
    this.separateClick.emit(type);
    this.unsetDraggingState();
  }

  public clickSeparateCountButton(type: TargetType): void {
    this.separateCountClick.emit(type);
    this.unsetDraggingState();
  }

  public expandAllClicked(): void {
    this.expandAllClick.emit();
  }

  public onCombineActionClicked(
    actionItem: CombineActionItem,
    type: TargetType
  ): void {
    this.combineActionClick.emit({
      actionItem,
      type,
    });
    this.unsetDraggingState();
  }

  public addToMultiple(type: TargetType): void {
    this.addToMultipleClick.emit(type);
    this.unsetDraggingState();
  }

  public onDropzoneEnter(trigger: MatMenuTrigger): void {
    if (this.isDraggingCodingGridRow) {
      return;
    }
    this.dropzoneEnterCount++;
    setTimeout(() => {
      if (this.dropzoneEnterCount === 0 || this.activeMenuTrigger === trigger) {
        return;
      }
      if (
        this.activeMenuTrigger &&
        (this.isDropzoneMenuOpen || this.dropzoneEnterCount > 1)
      ) {
        this.closeActionMenu();
      }
      this.activeMenuTrigger = trigger;
      this.isDropzoneMenuOpen = true;
      this.activeMenuTrigger.openMenu();
      this.virtualButton.nativeElement.focus();
    }, 300);
  }

  public onDropzoneLeave(): void {
    if (this.isDraggingCodingGridRow) {
      return;
    }
    this.dropzoneEnterCount--;
    setTimeout(() => {
      this.closeActionMenu();
    }, 200);
  }

  public onDropzoneDrop(): void {
    this.closeActionMenu();
  }

  public onActionMenuEnter(
    event: DragEvent,
    isOverActiveMenu: boolean,
    trigger?: MatMenuTrigger
  ): void {
    const target = event.currentTarget;
    if (this.menuTarget !== target && isOverActiveMenu && !trigger) {
      this.combinedMenuTrigger?.closeMenu();
      this.combinedMenuTrigger = null;
    }
    this.menuTarget = target;
    this.isOverActiveMenu = isOverActiveMenu;
    this.isOverActionMenu = true;
    if (trigger) {
      this.combinedMenuTrigger = trigger;
      this.combinedMenuTrigger?.openMenu();
    }

    if (!this.combinedMenuTrigger) {
      this.virtualButton.nativeElement.focus();
    }
  }

  public onActionMenuLeave(event: DragEvent, trigger?: MatMenuTrigger): void {
    const target = event.currentTarget;
    // note: dndDragoverClass doesn't always get removed after leaving the target
    if (target && target instanceof Element) {
      target.classList.remove(this.menuItemDragoverClass);
    }
    if (this.menuTarget === target) {
      this.isOverActionMenu = false;
      this.closeActionMenu();
    }
  }

  private closeActionMenu(): void {
    if (this.isDropzoneMenuOpen && !this.isOverActionMenu) {
      this.isDropzoneMenuOpen = false;
      this.activeMenuTrigger.closeMenu();
      this.activeMenuTrigger = null;
      this.virtualButton.nativeElement.focus();
      this.isDraggingCodingGridRow = false;
    }
  }

  private unsetDraggingState(): void {
    this.isOverActionMenu = false;
    this.dropzoneEnterCount = 0;
    this.closeActionMenu();
  }

  private listenToSelectedNodesChanges(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        this.selectedNodes = selectedNodes;
      });

    this.codebookSelectionService.loadingSelectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isLoading: LoadingNode) => {
        this.isLoadingSelectedNodes = isLoading.inProgress;
        this.isLoadingFromButtons =
          isLoading.origin === LoadingSource.fromButton;
      });
  }

  private listenToCodingGridSelectRowChanges(): void {
    this.codingGridService.draggingGridRow$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isDragging: boolean) => {
        this.isDraggingCodingGridRow = isDragging;
      });
  }

  private listenToQuickReportChanges(): void {
    this.quickReportService.addToRowsOnly$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((addToRowsOnly: boolean) => {
        this.addToRowsOnly = addToRowsOnly;
      });
  }
}
