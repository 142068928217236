<div
  *ngIf="!isReadonly"
  fxFlex
  fxLayout="row"
  fxLayoutGap="10px"
>
  <button
    data-tracking="coding-grid-delete-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-sm-visible"
    [disabled]="!isDeletable"
    (click)="onDeleteSelectedRows()"
  >
    <mat-icon matTooltip="Delete selected items"> delete </mat-icon>
  </button>

  <button
    data-tracking="coding-grid-find-and-replace-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-sm-visible"
    (click)="onOpenFindAndReplace()"
    [disabled]="findAndReplaceDialogOpen || findAndReplaceDialogState"
  >
    <mat-icon matTooltip="Search">search</mat-icon>
  </button>

  <button
    *ngIf="!isAddToRowsOnly"
    data-tracking="coding-grid-swap-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-sm-visible"
    (click)="onSwapRowsAndColumns()"
    [disabled]="swapDisabled"
    data-qa="swap-top-menu-action"
    [matTooltip]="'Swap rows/columns' + swapActionWarning"
  >
    <mat-icon>sync_alt</mat-icon>
  </button>

  <button
    data-tracking="coding-grid-count-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-md-visible"
    [disabled]="!isDeletable"
    (click)="onSeparateCountActionClicked()"
    data-qa="separate-count-top-menu-action"
  >
    <mat-icon
      matTooltip="Separate count"
      class="material-symbols-outlined"
    >
      calculate
    </mat-icon>
  </button>

  <button
    data-tracking="coding-grid-ntiles-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-md-visible"
    (click)="onOpenNTileSettings()"
    [disabled]="selectedRows.length !== 1 || !isDeletable"
  >
    <mat-icon matTooltip="N-Tiles">grid_on</mat-icon>
  </button>

  <button
    data-tracking="coding-grid-new-code-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-md-visible"
    (click)="onOpenNewCodeBuilder()"
    [disabled]="selectedRows.length > 0"
  >
    <mat-icon matTooltip="Create new coding statement"
      >integration_instructions</mat-icon
    >
  </button>

  <button
    *ngIf="!isAddToRowsOnly"
    data-tracking="coding-grid-title-mode-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-md-visible"
    [disabled]="!isDeletable"
    [matMenuTriggerFor]="changeTitleModeMenu"
    data-qa="title-mode-top-menu-action"
  >
    <mat-icon
      matTooltip="Change title mode"
      class="material-symbols-outlined"
    >
      title
    </mat-icon>
  </button>

  <button
    data-tracking="coding-grid-duplicate-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-md-visible"
    [disabled]="!isDeletable"
    data-qa="duplicate-top-menu-action"
    (click)="onDuplicateSelectedRows()"
  >
    <mat-icon
      matTooltip="Duplicate selected items"
      class="material-symbols-outlined"
    >
      content_copy
    </mat-icon>
  </button>

  <button
    *ngIf="!isAddToRowsOnly"
    data-tracking="coding-grid-sent-to-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-md-visible"
    [matMenuTriggerFor]="sendToMenu"
    [disabled]="!isDeletable"
    data-qa="send-to-top-menu-action"
  >
    <mat-icon
      matTooltip="Move to"
      class="material-symbols-outlined"
    >
      send
    </mat-icon>
  </button>

  <button
    data-tracking="coding-grid-combine-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-xl-visible"
    [matMenuTriggerFor]="combineMenu"
    [disabled]="selectedRows.length < 2"
    data-qa="combine-top-menu-action"
  >
    <mat-icon
      matTooltip="Combine selected items"
      class="material-symbols-outlined"
    >
      join
    </mat-icon>
  </button>

  <button
    data-tracking="coding-grid-group-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-xl-visible"
    [disabled]="!isDeletable"
    data-qa="group-name-top-menu-action"
    (click)="onRenameGroupName()"
  >
    <mat-icon
      matTooltip="Assign group name"
      class="material-symbols-outlined"
    >
      group_work
    </mat-icon>
  </button>

  <button
    data-tracking="coding-grid-hide-columns-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-xl-visible"
    [ngClass]="
      isColumnHidden ? 'hide-column-flat-button' : 'hide-column-stroked-button'
    "
    [matMenuTriggerFor]="hideColumns"
  >
    <mat-icon
      matTooltip="Hide columns"
      class="material-symbols-outlined"
      >hide</mat-icon
    >
  </button>

  <button
    data-tracking="coding-grid-save-custom-audience-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-xl-visible"
    (click)="onSaveCustomAudience()"
    [disabled]="!isDeletable"
  >
    <mat-icon
      matTooltip="Save selected items to custom audiences"
      class="material-symbols-outlined"
      >bookmark</mat-icon
    >
  </button>

  <button
    *ngIf="!isAddToRowsOnly"
    data-tracking="coding-grid-delete-all-btn"
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-xl-visible"
    (click)="onDeleteCodingGrid()"
  >
    <mat-icon matTooltip="Reset Explore"> restart_alt </mat-icon>
  </button>

  <button
    onclick="this.blur()"
    fxFlex="0 0 auto"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    class="control-button is-more-visible"
    [matMenuTriggerFor]="moreMenu"
    (click)="onOpenMoreMenu()"
    data-qa="more-top-menu-action"
  >
    <mat-icon
      matTooltip="See more actions"
      class="material-icons-outlined"
      >more_vert</mat-icon
    >
  </button>
</div>

<mat-menu #moreMenu="matMenu">
  <div
    data-qa="more-action-menu"
    class="more-action-menu"
  >
    <button
      data-tracking="coding-grid-delete-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      (click)="onDeleteSelectedRows()"
    >
      <mat-icon
        matTooltip="Delete selected items, all items or coding grid"
        class="material-icons-outlined"
      >
        delete
      </mat-icon>
      Delete
    </button>

    <button
      data-tracking="coding-grid-find-and-replace-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onOpenFindAndReplace()"
      [disabled]="findAndReplaceDialogOpen || findAndReplaceDialogState"
    >
      <mat-icon matTooltip="Search">search</mat-icon>
      Search
    </button>

    <button
      *ngIf="!isAddToRowsOnly"
      data-tracking="coding-grid-swap-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onSwapRowsAndColumns()"
      [disabled]="swapDisabled"
      data-qa="swap-top-menu-action"
      [matTooltip]="'Swap rows/columns' + swapActionWarning"
    >
      <mat-icon>sync_alt</mat-icon>
      Swap rows/columns
    </button>

    <button
      data-tracking="coding-grid-count-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      (click)="onSeparateCountActionClicked()"
      data-qa="separate-count-top-menu-action"
    >
      <mat-icon
        matTooltip="Separate count"
        class="material-symbols-outlined"
      >
        calculate
      </mat-icon>
      Separate count
    </button>

    <button
      data-tracking="coding-grid-ntiles-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onOpenNTileSettings()"
      [disabled]="selectedRows.length !== 1 || !isDeletable"
    >
      <mat-icon matTooltip="N-Tiles">grid_on</mat-icon>
      N-Tiles
    </button>

    <button
      data-tracking="coding-grid-new-code-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      (click)="onOpenNewCodeBuilder()"
      [disabled]="selectedRows.length > 0"
    >
      <mat-icon matTooltip="Create new coding statement"
        >integration_instructions</mat-icon
      >
      New coding statement
    </button>

    <button
      *ngIf="!isAddToRowsOnly"
      data-tracking="coding-grid-title-mode-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      [matMenuTriggerFor]="changeTitleModeMenu"
      data-qa="title-mode-top-menu-action"
    >
      <mat-icon
        matTooltip="Change title mode"
        class="material-symbols-outlined"
      >
        title
      </mat-icon>
      Title mode
    </button>

    <button
      data-tracking="coding-grid-duplicate-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      data-qa="duplicate-top-menu-action"
      (click)="onDuplicateSelectedRows()"
    >
      <mat-icon
        matTooltip="Duplicate selected items"
        class="material-symbols-outlined"
      >
        content_copy
      </mat-icon>
      Duplicate
    </button>

    <button
      *ngIf="!isAddToRowsOnly"
      data-tracking="coding-grid-sent-to-btn"
      mat-menu-item
      class="menu-control-button"
      [matMenuTriggerFor]="sendToMenu"
      [ngClass]="
        moreMenuVisibility === moreMenuVisibilityOptions.xs ||
        moreMenuVisibility === moreMenuVisibilityOptions.sm
          ? 'show-menu-button'
          : 'hide-menu-button'
      "
      [disabled]="!isDeletable"
      data-qa="send-to-top-menu-action"
    >
      <mat-icon
        matTooltip="Send to"
        class="material-symbols-outlined"
      >
        send
      </mat-icon>
      Send to
    </button>

    <button
      data-tracking="coding-grid-combine-btn"
      mat-menu-item
      class="menu-control-button"
      [matMenuTriggerFor]="combineMenu"
      [disabled]="selectedRows.length < 2"
      data-qa="combine-top-menu-action"
    >
      <mat-icon
        matTooltip="Combine selected items"
        class="material-symbols-outlined"
      >
        join
      </mat-icon>
      Combine
    </button>

    <button
      data-tracking="coding-grid-group-btn"
      mat-menu-item
      class="menu-control-button"
      [disabled]="!isDeletable"
      data-qa="group-name-top-menu-action"
      (click)="onRenameGroupName()"
    >
      <mat-icon
        matTooltip="Assign group name"
        class="material-symbols-outlined"
      >
        group_work
      </mat-icon>
      Assign group name
    </button>

    <button
      data-tracking="coding-grid-hide-columns-btn"
      mat-menu-item
      class="menu-control-button"
      [ngClass]="
        isColumnHidden
          ? 'hide-column-flat-button'
          : 'hide-column-stroked-button'
      "
      [matMenuTriggerFor]="hideColumns"
    >
      <mat-icon
        matTooltip="Hide columns"
        class="material-symbols-outlined"
      >
        hide
      </mat-icon>
      Hide columns
    </button>

    <button
      data-tracking="coding-grid-save-custom-audience-btn"
      mat-menu-item
      class="menu-control-button"
      (click)="onSaveCustomAudience()"
      [disabled]="!isDeletable"
    >
      <mat-icon
        matTooltip="Save selected items to custom audiences"
        class="material-symbols-outlined"
      >
        bookmark
      </mat-icon>
      Save custom audiences
    </button>

    <button
      *ngIf="!isAddToRowsOnly"
      data-tracking="coding-grid-delete-all-btn"
      mat-menu-item
      class="menu-control-button"
      (click)="onDeleteCodingGrid()"
    >
      <mat-icon matTooltip="Reset Explore"> restart_alt </mat-icon>
      Reset Explore
    </button>
  </div>
</mat-menu>

<mat-menu #sendToMenu="matMenu">
  <div data-qa="send-to-action-menu">
    <ng-container *ngFor="let sendToAction of sendToActionItems">
      <button
        *ngIf="sendToAction.currentTargetType !== selectedTab"
        mat-menu-item
        (click)="onSendTo({ actionItem: sendToAction })"
      >
        {{ sendToAction.name }}
      </button>
    </ng-container>
  </div>
</mat-menu>

<mat-menu #changeTitleModeMenu="matMenu">
  <div data-qa="change-title-mode-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of changeTitleModeItems"
      (click)="onChangeTargetTitleModeActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #combineMenu="matMenu">
  <div data-qa="combine-action-menu">
    <button
      mat-menu-item
      *ngFor="let action of combineActionItems"
      [matTooltip]="action.tooltip"
      matTooltipPosition="right"
      (click)="onCombineActionClicked(action)"
    >
      {{ action.name }}
    </button>
  </div>
</mat-menu>

<mat-menu #hideColumns="matMenu">
  <div
    data-qa="hide-column-mode-action-menu"
    (click)="$event.stopPropagation()"
  >
    <mat-selection-list
      #hideColumnSelectionList
      (selectionChange)="onHideColumns()"
    >
      <mat-list-option
        color="primary"
        data-tracking="data-context-option"
        *ngFor="let item of hideColumnItems[selectedTab]"
        [value]="item.value"
        [selected]="item.show"
        class="list-option-background"
      >
        {{ item.label }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-menu>
