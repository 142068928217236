<div
  fxLayout="column"
  class="builder-container"
  fxLayoutAlign="space-between stretch"
>
  <div
    class="spinner-container-overlay"
    *ngIf="isLoadingSelectedNodes && isLoadingFromDrag"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>

  <div
    class="title-modes"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <mat-button-toggle-group
      class="view-toggle-group-control"
      [(ngModel)]="dialogTitleMode"
      (ngModelChange)="onDialogTitleModeChange()"
    >
      <mat-button-toggle
        [disableRipple]="true"
        [value]="titleDisplayType.ownTitle"
        matTooltip="User title"
        matTooltipPosition="above"
      >
        User title
      </mat-button-toggle>
      <mat-button-toggle
        [disableRipple]="true"
        [value]="titleDisplayType.title"
        matTooltip="Long title"
        matTooltipPosition="above"
      >
        Long title
      </mat-button-toggle>
      <mat-button-toggle
        [disableRipple]="true"
        [value]="titleDisplayType.shortTitle"
        matTooltip="Short title"
        matTooltipPosition="above"
      >
        Short title
      </mat-button-toggle>

      <mat-button-toggle
        [disableRipple]="true"
        matTooltip="Data context"
        [value]="titleDisplayType.levels"
        matTooltipPosition="above"
      >
        Data context
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    class="display-container"
  >
    <p
      class="title-display-wrap"
      *ngIf="dialogTitleMode !== titleDisplayType.ownTitle"
    >
      {{ title }}
    </p>
    <textarea
      [ngModel]="title"
      hidden
      class="display-text-field-textarea"
    ></textarea>
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="grow"
      class="display-text-field"
      *ngIf="dialogTitleMode === titleDisplayType.ownTitle"
    >
      <mat-label>Coding statement title</mat-label>
      <textarea
        [(ngModel)]="displayText"
        matInput
        required
        (ngModelChange)="onDisplayTextChange()"
        class="display-text-field-textarea"
      ></textarea>
    </mat-form-field>
  </div>

  <div
    class="coding-method"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <mat-slide-toggle
      class="coding-method-toggle"
      color="primary"
      data-qa="coding-method-toggle"
      (change)="onManualCodingModeChange()"
      [(ngModel)]="manualCodingMode"
      [disabled]="manualCodingMode"
    >
      Manual coding
    </mat-slide-toggle>

    <button
      *ngIf="manualCodingMode"
      mat-icon-button
      color="primary"
      tupMatButton
      appearance="tiny"
      matTooltip="Reset manual coding"
      (click)="resetManualCoding()"
      data-qa="manual-coding-reset"
    >
      <mat-icon>restart_alt</mat-icon>
    </button>
  </div>

  <div
    fxFlex
    fxLayout="column"
    fxGrow="1"
    class="target-list-container"
    *ngIf="!manualCodingMode"
  >
    <div
      class="target-list"
      *ngFor="let parentTarget of targets; let rowIndex = index"
    >
      <div
        class="target-box"
        *ngFor="let target of parentTarget.targets; let columnIndex = index"
      >
        <mat-chip-list>
          <span
            class="drop-zone-menu-trigger"
            [matMenuTriggerFor]="dropZoneMenu"
            #dropZoneMenuTrigger="matMenuTrigger"
          ></span>
          <mat-chip
            #dropZoneChip
            class="code-chip"
            [class.code-chip--expanded]="isTargetExpanded(target, rowIndex)"
            [ngClass]="{
              'is-grey':
                selectedNodes.length === 0 && !isTargetExpandable(target)
            }"
            selectable="false"
            [removable]="isChipRemovable(parentTarget, rowIndex)"
            (removed)="
              onRemoveTarget(parentTarget, target, rowIndex, columnIndex)
            "
            [matTooltip]="formatChipTooltip(target)"
            [dndDropzone]
            dndDragoverClass="drag-over-chip"
            [dndDisableDropIf]="
              !isChipDroppable(parentTarget, target, rowIndex)
            "
            (dndDrop)="onDrop()"
            (dragenter)="
              onChipEnter(target, rowIndex, dropZoneMenuTrigger, dropZoneChip)
            "
            (dragleave)="onChipLeave()"
            [dndDraggable]="target"
            [dndEffectAllowed]="'copy'"
            (dndStart)="
              onDragStart(parentTarget, target, rowIndex, columnIndex)
            "
            (dndEnd)="onDragEnd()"
            (click)="onTargetClick(target, rowIndex)"
            disableRipple
          >
            <mat-icon
              *ngIf="isTargetExpandable(target)"
              fontSet="material-icons-outlined"
              class="code-chip-expand-icon"
              >{{
                isTargetExpanded(target, rowIndex)
                  ? "expand_less_outline"
                  : "expand_more_outline"
              }}
            </mat-icon>
            {{
              target
                | targetTitle
                  : displayTypeType.shortTitle
                  : rootTarget.titleLevels
                  : target.title
                | truncate: 30
            }}
            <mat-icon
              *ngIf="isChipRemovable(parentTarget, rowIndex)"
              class="code-chip-remove-icon"
              matChipRemove
              >cancel</mat-icon
            >
          </mat-chip>
        </mat-chip-list>

        <mat-form-field
          class="operator-field"
          *ngIf="target.countCoding"
        >
          <mat-select
            class="operator-text"
            [(ngModel)]="target.countCoding.operator"
          >
            <mat-option [value]="target.countCoding.operator">
              {{ target.countCoding.operator }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-chip-list *ngIf="target.countCoding">
          <mat-chip
            class="code-chip"
            selectable="false"
            [removable]="true"
            (removed)="onRemoveCountCoding(target)"
            disableRipple
          >
            {{ target.countCoding.value }}
            <mat-icon
              matChipRemove
              class="code-chip-remove-icon"
              >cancel</mat-icon
            >
          </mat-chip>
        </mat-chip-list>

        <mat-form-field class="operator-field">
          <mat-select
            class="operator-text"
            [(ngModel)]="target.operator"
            [disabled]="false"
            (selectionChange)="onTargetOperatorChange()"
          >
            <mat-option
              *ngFor="let targetOperator of targetOperators"
              [value]="targetOperator"
            >
              {{ targetOperator }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="target-box">
        <mat-chip-list>
          <span
            class="drop-zone-menu-trigger"
            [matMenuTriggerFor]="dropZoneMenu"
            #dropZoneMenuTrigger="matMenuTrigger"
          ></span>
          <mat-chip
            #dropZoneChip
            class="drop-zone-chip"
            selectable="false"
            [removable]="false"
            [dndDropzone]
            dndDragoverClass="drag-over-chip"
            [dndDisableDropIf]="!isEmptyChipDroppable(parentTarget, rowIndex)"
            (dndDrop)="onDrop()"
            (dragenter)="
              onChipEnter(
                parentTarget,
                rowIndex,
                dropZoneMenuTrigger,
                dropZoneChip
              )
            "
            (dragleave)="onChipLeave()"
            disableRipple
            [matTooltip]="'Drop item(s) here or to existing item'"
            [ngClass]="{
              'is-grey': selectedNodes.length === 0
            }"
          >
            Drop codings here
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <div
      *ngIf="targets.length === 0"
      class="target-list"
    >
      <div class="target-box">
        <mat-chip-list>
          <span
            class="drop-zone-menu-trigger"
            [matMenuTriggerFor]="dropZoneMenu"
            #dropZoneMenuTrigger="matMenuTrigger"
          ></span>
          <mat-chip
            class="drop-zone-chip"
            selectable="false"
            [removable]="false"
            [dndDropzone]
            dndDragoverClass="drag-over-chip"
            #dropZoneChip
            [dndDisableDropIf]="isLoadingSelectedNodes"
            (dndDrop)="onDrop()"
            (dragenter)="
              onChipEnter(null, 0, dropZoneMenuTrigger, dropZoneChip)
            "
            (dragleave)="onChipLeave()"
            disableRipple
            [matTooltip]="'Drop item(s) here or to existing item'"
            [ngClass]="{
              'is-grey': selectedNodes.length === 0
            }"
          >
            Drop codings here
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <div
    fxFlex
    fxLayout="column"
    fxGrow="1"
    class="manual-coding-container"
    *ngIf="manualCodingMode"
  >
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex="grow"
      class="display-text-field"
    >
      <mat-label>Editing coding statement</mat-label>
      <textarea
        [(ngModel)]="displayCoding"
        matInput
        required
        (ngModelChange)="onCodingEdited()"
        class="manual-coding-container-textarea"
        data-qa="manual-coding-container-textarea"
      ></textarea>
    </mat-form-field>
    <mat-error
      *ngIf="codingError"
      data-qa="coding-error"
      class="manual-coding-error"
      >{{ codingError }}
    </mat-error>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    class="display-container coding-preview__container"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <span class="coding-preview__label">Coding statement preview:</span>
      <mat-icon
        class="coding-preview__hint"
        matTooltipPosition="above"
        matTooltip="Select item(s) from codebook and drop on chip to update the coding statement"
        >help</mat-icon
      >
    </div>

    <div class="coding-preview">
      <span data-qa="coding-preview">{{ displayCoding }}</span>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="20px"
      class="coding-result"
      *ngIf="targetResult"
    >
      <div>
        <span class="coding-preview__label">Resps: </span>
        <span>{{
          !gettingCodingResult ? targetResult.resps || "null" : "..."
        }}</span>
      </div>

      <div>
        <span class="coding-preview__label">Population: </span>
        <span>{{
          !gettingCodingResult ? targetResult.population || "null" : "..."
        }}</span>
      </div>

      <button
        mat-icon-button
        color="primary"
        tupMatButton
        appearance="tiny"
        [matTooltip]="
          isResultDirty ? 'Sync audience size' : 'Audience size synced'
        "
        (click)="onSyncAudienceSize()"
        [disabled]="!isResultDirty"
      >
        <mat-icon>sync</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu
  #dropZoneMenu="matMenu"
  [hasBackdrop]="false"
  class="target-list-menu"
>
  <ng-container *ngIf="menuType === dropzoneMenuType.single">
    <button
      mat-menu-item
      *ngFor="let dropZoneMenuItem of targetOperators"
      [dndDropzone]
      (dndDrop)="onDrop(dropZoneMenuItem)"
      [dndDragoverClass]="menuItemDragoverClass"
      (dragenter)="onOperatorMenuEnter($event)"
      (dragleave)="onOperatorMenuLeave($event)"
      [dndDisableDropIf]="isLoadingSelectedNodes"
      [disabled]="isLoadingSelectedNodes"
    >
      {{ dropZoneMenuItem }}
    </button>
  </ng-container>
  <ng-container *ngIf="menuType === dropzoneMenuType.multiple">
    <button
      mat-menu-item
      *ngFor="let dropZoneMenuItem of dropZoneMenuItems"
      [dndDropzone]
      (dndDrop)="onDrop(dropZoneMenuItem)"
      [dndDragoverClass]="menuItemDragoverClass"
      (dragenter)="onOperatorMenuEnter($event)"
      (dragleave)="onOperatorMenuLeave($event)"
      [dndDisableDropIf]="isLoadingSelectedNodes"
      [disabled]="isLoadingSelectedNodes"
    >
      {{ dropZoneMenuItem }}
    </button>
  </ng-container>
</mat-menu>
