<tup-mat-title-bar
  title="Select data context to display"
  class="dialog-title"
>
</tup-mat-title-bar>

<mat-dialog-content>
  <mat-selection-list #selectionList>
    <mat-list-option
      data-tracking="data-context-option"
      *ngFor="let i of indices; let first = first; let last = last"
      [value]="last ? -1 : i"
    >
      {{ i | titleLevel: first:last }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button
    mat-stroked-button
    color="primary"
    appearance="medium"
    tupMatButton
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    appearance="medium"
    tupMatButton
    cdkFocusRegionstart
    (click)="ok(selectionList.selectedOptions.selected)"
    data-tracking="data-context-ok-button"
  >
    OK
  </button>
</mat-dialog-actions>
