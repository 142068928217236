import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FactorAnalysisService } from '../../services/factor-analysis.service';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import {
  ALL_RESPONDENTS_CODING,
  CrossTabTableData,
  DisplayType,
  DocumentDataState,
  DocumentViewType,
  FACleaningMethod,
  FactorFeatureItem,
  GetFactorsResponseBody,
  GetResearchStatusResponseBody,
  Operator,
  PercentageType,
  ResearchStatus,
  SaveFactorResponseBody,
  StartFactorAnalysisResponseBody,
  Survey,
  SurveyTimeDocument,
  SurveyTimeDocumentAppType,
  SurveyTimeDocumentFactorApp,
  Target,
  TITLE_MODES,
  FACTOR_VERSION,
  FAExplainedVariances,
  FactorAnalysisMetrics,
  FactorAnalysisTableRowData,
  FactorAnalysisSettings,
  DEFAULT_FA_SETTINGS,
  FAVariableOptionType,
  FATableHighlightOption,
  ExportParams,
} from '../../models';
import {
  CrosstabService,
  DialogService,
  DocumentService,
  TargetService,
  TitleLevelsService,
  TitleModeService,
} from '../../services';
import {
  Empty,
  TupDocument,
  TupDocumentService,
} from '@telmar-global/tup-document-storage';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesType,
} from '@telmar-global/tup-audience-groups';
import { TargetTitlePipe } from '../../pipes';
import { cloneDeep } from 'lodash';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { FactorAnalysisApiService } from 'src/app/services/factor-analysis-api.service';
import { v4 as uuidv4 } from 'uuid';
import { HttpResponse } from '@angular/common/http';
import { SaveFactorCodesDialogResult } from '../../dialogs';
import { FactorAnalysisTableComponent } from 'src/app/components/factor-analysis-table/factor-analysis-table.component';

@Component({
  selector: 'app-factor-analysis',
  templateUrl: './factor-analysis.component.html',
  styleUrls: ['./factor-analysis.component.scss'],
})
export class FactorAnalysisComponent implements OnInit, OnDestroy {
  public readonly researchStatus = ResearchStatus;
  public isReadonly = true;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;
  public inProgress = true;
  public status: string;
  public statusValue: number;
  public progressMessage: string;
  public statusMessage: any;
  public hasValidDataset = true;
  public researchId = '';
  public surveys: Survey[] = [];
  private previouslySelectedSurvey: Survey;
  public activeSurvey: Survey;
  public currentDoc: TupDocument<SurveyTimeDocument>;
  public crosstabData: CrossTabTableData[];
  private unsubscribe: Subject<void> = new Subject<void>();

  private defaultAppData: SurveyTimeDocumentFactorApp;
  public activeAppData: SurveyTimeDocumentFactorApp;

  public readonly titleModes = TITLE_MODES;
  public activeTitleMode: DisplayType;
  private titleLevels: number[];

  public tableData: FactorAnalysisTableRowData[];
  public explainedVariances: FAExplainedVariances;
  public factorData: FactorAnalysisMetrics;
  public totalAudienceSize: number;

  @ViewChild('resetConfirmation') resetConfirmation: TemplateRef<any>;
  @ViewChild('changeSurveyConfirmation')
  changeSurveyConfirmation: TemplateRef<any>;

  public exportingToCustomCodes = false;

  @ViewChild(FactorAnalysisTableComponent, { static: false })
  faTableComponent: FactorAnalysisTableComponent;

  constructor(
    private router: Router,
    private authService: TupAuthService,
    private faService: FactorAnalysisService,
    private faApiService: FactorAnalysisApiService,
    private tupDocumentService: TupDocumentService,
    private documentService: DocumentService,
    private crosstabService: CrosstabService,
    private targetTitlePipe: TargetTitlePipe,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private targetService: TargetService,
    private dialogService: DialogService,
    private userMessageService: TupUserMessageService
  ) {
    this.isReadonly =
      !!this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.listenToTitleModeAndLevelsChanges();
    this.listenToDocumentDataChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onReset(): void {
    this.userMessageService
      .openCustomMessageDialog(
        this.resetConfirmation,
        'Reset Factor Analysis?',
        {
          confirmText: 'Reset',
          centered: true,
          width: '400px',
        }
      )
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.resetAnalysis();
        }
      });
  }

  public onMultiSort(): void {
    this.dialogService
      .factorAnalysisMultiSort(this.activeAppData.settings)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: number) => {
        this.activeAppData = {
          ...this.activeAppData,
          settings: {
            ...this.activeAppData.settings,
            tableHighlightOptions: {
              ...this.activeAppData.settings.tableHighlightOptions,
              [FATableHighlightOption.moderate]: {
                ...this.activeAppData.settings.tableHighlightOptions[
                  FATableHighlightOption.moderate
                ],
                threshold: result,
              },
            },
          },
          multiSort: true,
        };
        this.saveAnalysisToDoc(false);
      });
  }

  public clearMultiSort(): void {
    this.activeAppData = {
      ...this.activeAppData,
      multiSort: false,
    };
    this.saveAnalysisToDoc(false);
  }

  public onSelectedSurveyChanged(): void {
    this.userMessageService
      .openCustomMessageDialog(this.changeSurveyConfirmation, 'Change survey', {
        cancelText: 'Cancel',
        confirmText: 'OK',
        width: '400px',
      })
      .afterClosed()
      .subscribe((result: boolean | undefined) => {
        if (!result) {
          this.prepareSurveyData(this.previouslySelectedSurvey);
          return;
        }

        this.resetAnalysis(this.activeSurvey);
      });
  }

  public openSaveCodesDialog(): void {
    this.dialogService
      .saveFactorCodes()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((dialogResult: SaveFactorCodesDialogResult) => {
        this.exportingToCustomCodes = true;
        const fileName = dialogResult.name;
        const containerName = dialogResult.container.name;
        const numOfFactors = 5;
        // const exportGroup = dialogResult.exportGroup;
        this.faService
          .saveFactor({
            'research-session-id': this.researchId,
            'cleaning-method': FACleaningMethod.cleanDrop,
            'num-of-factors': numOfFactors,
          })
          .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
          .subscribe(
            (result: SaveFactorResponseBody) => {
              const audienceGroupItems: DocumentAudienceGroupItem[] =
                this.formatAudienceGroupItem(
                  result['factor-keys'],
                  numOfFactors
                );
              const docsToCreate: TupDocument<DocumentAudienceGroup>[] = [];
              docsToCreate.push(
                this.formatAudienceGroupDocument(fileName, audienceGroupItems)
              );

              this.saveToDocumentAudienceGroup(docsToCreate, containerName);
            },
            () => {
              this.exportingToCustomCodes = false;
            }
          );
      });
  }

  private listenToTitleModeAndLevelsChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.titleLevelsService.updateNumberOfTitleLevelsByTargets([
          ...columns,
          ...rows,
        ]);
      });
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((activeTitleMode: DisplayType) => {
        this.activeTitleMode = activeTitleMode;
      });

    this.titleLevelsService.titleLevels$
      .pipe(
        takeUntil(this.unsubscribe),
        filter((titleLevels: number[]) => !!titleLevels?.length)
      )
      .subscribe((titleLevels: number[]) => {
        this.titleLevels = titleLevels;
      });
  }

  private listenToDocumentDataChanges(): void {
    combineLatest([
      this.documentService.selectedSurvey$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.activeTablebase$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.crosstabService.crossTabData$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
    ]).subscribe(
      ([survey, tablebase, crosstabData]: [
        Survey,
        Target,
        CrossTabTableData[]
      ]): void => {
        if (crosstabData.length > 0 && crosstabData[0].isPlaceholder) {
          return;
        }
        this.crosstabData = crosstabData;
        this.totalAudienceSize = crosstabData
          .filter((row) => row.isTotalRow)[0]
          .data.filter((row) => row.isTotalsColumn)[0].projected;
        this.activeSurvey = survey;
        this.currentDoc = this.documentService.document;
        if (this.currentDoc.content.rows.length < 1) {
          this.hasValidDataset = false;
          this.inProgress = false;
          return;
        }

        this.surveys = this.currentDoc.content?.surveys;
        this.prepareSurveyData(survey);
        this.isReadonly = !(
          this.authService.user.attributes.email ===
          this.currentDoc?.metadata?.by?.attributes?.email
        );
        this.faApiService.setCurrentDoc(this.currentDoc);
        this.defaultAppData = {
          survey: this.activeSurvey,
          tablebase,
          features: this.formatFeatureRows(this.currentDoc.content.rows),
          researchId: '',
          version: FACTOR_VERSION,
          factorTitles: [],
          settings: DEFAULT_FA_SETTINGS,
          multiSort: false,
        };
        this.activeAppData = this.currentDoc.content.apps?.factorAnalysis
          ? (this.currentDoc.content.apps[
              SurveyTimeDocumentAppType.fa
            ] as SurveyTimeDocumentFactorApp)
          : cloneDeep(this.defaultAppData);
        this.loadAnalysis();
      }
    );
  }

  private prepareSurveyData(survey: Survey): void {
    this.activeSurvey =
      this.surveys.find(
        (surveyItem: Survey) =>
          surveyItem.code === survey.code &&
          surveyItem.authorizationGroup === survey.authorizationGroup
      ) || survey;
    this.updatePreviouslySelectedSurvey(this.activeSurvey);
    this.surveys = this.getEligibleSurveys();
  }

  private updatePreviouslySelectedSurvey(survey: Survey): void {
    this.previouslySelectedSurvey = survey;
  }

  private getEligibleSurveys() {
    return [
      ...this.surveys?.filter(
        (survey) => survey.code !== this.activeSurvey?.code
      ),
      this.activeSurvey,
    ];
  }

  private loadAnalysis(canUndo: boolean = false): void {
    this.inProgress = true;
    if (this.hasResearchId(this.currentDoc)) {
      this.hasValidDataset = true;
      this.researchId = this.activeAppData.researchId;
      this.activeSurvey = this.activeAppData.survey;
      this.prepareSurveyData(this.activeSurvey);
      this.getFactorAnalysisData(this.researchId);
    } else if (!this.isReadonly) {
      this.hasValidDataset = this.currentDoc.content?.rows.length > 0;
      if (this.hasValidDataset) {
        this.performAnalysis(canUndo);
      } else {
        this.inProgress = false;
      }
    } else {
      this.inProgress = false;
    }
  }

  private hasResearchId(doc: TupDocument<SurveyTimeDocument>): boolean {
    const app = doc.content.apps?.factorAnalysis as SurveyTimeDocumentFactorApp;
    return app?.version === FACTOR_VERSION && app?.researchId?.length > 0;
  }

  private formatFeatureRows(rows: Target[]): FactorFeatureItem[] {
    return rows.map((row) => this.convertTargetToAudienceGroupItem(row));
  }

  private convertTargetToAudienceGroupItem(
    target: Target
  ): DocumentAudienceGroupItem {
    return {
      title: this.targetTitlePipe.transform(
        target,
        this.activeTitleMode,
        this.titleLevels
      ),
      coding: target.coding,
      options: {
        statement: null,
        target: this.targetService.shallowCopyTarget(target),
      },
    };
  }

  private performAnalysis(canUndo: boolean, saveDoc: boolean = true): void {
    this.faService
      .startFactorAnalysis({
        'survey-code': this.activeAppData.survey.code,
        'survey-version': this.activeAppData.survey.meta?.[
          'survey-instance-version'
        ] as string,
        features: this.activeAppData.features.map((feature) => feature.coding),
        'respondent-filter':
          this.activeAppData.tablebase?.coding || ALL_RESPONDENTS_CODING,
        'cleaning-method': FACleaningMethod.cleanDrop,
        'max-num-of-factors': 5,
        'min-variance-explained-by-factors': {
          'percentage-type': PercentageType.individual,
          'percentage-value': 5,
        },
        ...(this.activeAppData.settings?.isEliminateNegligibleCorrelations
          ? { 'variable-options': this.activeAppData.settings.variableOptions }
          : {}),
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (result: StartFactorAnalysisResponseBody) => {
          this.researchId = result['research-session-id'];
          this.activeAppData = {
            ...this.activeAppData,
            researchId: this.researchId,
          };
          if (saveDoc) {
            this.saveAnalysisToDoc(!canUndo);
          }
          this.getFactorAnalysisData(this.researchId);
        },
        (error) => {
          this.inProgress = false;
          this.statusMessage = error;
          this.status = ResearchStatus.failure;
        }
      );
  }

  private resetAnalysis(survey?: Survey): void {
    this.unsetFactorAnalysisAppData();
    this.activeAppData = cloneDeep(this.defaultAppData);
    if (survey) {
      this.activeAppData.survey = survey;
    }
    this.prepareSurveyData(this.activeAppData.survey);
    this.status = null;
    this.progressMessage = null;
    this.loadAnalysis();
  }

  private unsetFactorAnalysisAppData(): void {
    this.currentDoc.content.apps.factorAnalysis = null;
  }

  private saveAnalysisToDoc(shouldUnsetRestoreDocumentState?: boolean): void {
    const docToSave = cloneDeep(this.currentDoc);
    const factorAnalysis = this.activeAppData;

    if (docToSave.content?.apps) {
      const apps = docToSave.content?.apps;
      apps.factorAnalysis = factorAnalysis;
    } else {
      docToSave.content.apps = { factorAnalysis };
    }

    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(
      docToSave,
      shouldUnsetRestoreDocumentState
    );
  }

  private getFactorAnalysisData(researchId: string): void {
    this.faService
      .getFactorAnalysisStatus(researchId)
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe(
        (result: GetResearchStatusResponseBody) => {
          this.status = result.status;
          this.statusValue = result.progress;
          this.progressMessage = result.phase;
          this.statusMessage = result.message;

          if (result.status === ResearchStatus.finished) {
            this.faService
              .getFactors({
                'research-session-id': researchId,
                'cleaning-methods': [FACleaningMethod.cleanDrop],
              })
              .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
              .subscribe(
                (factorData: GetFactorsResponseBody) => {
                  if (factorData.success) {
                    this.factorData = factorData.factors.clean_drop;
                    this.explainedVariances =
                      this.factorData['explained-variances'];
                    this.populateTableData(factorData);
                  }
                  this.inProgress = false;
                },
                (getFactorsAPIError) => {
                  this.inProgress = false;
                  this.statusMessage = getFactorsAPIError;
                  this.status = ResearchStatus.failure;
                }
              );
          }
        },
        (error) => {
          this.inProgress = false;
          this.statusMessage = error;
          this.status = ResearchStatus.failure;
        }
      );
  }

  public populateTableData(data: GetFactorsResponseBody) {
    const factors = data.factors.clean_drop;
    const features = data.features.clean_drop;
    const featureToLoadingsMap = features.remaining.reduceRight(
      (acc, coding, index) => {
        acc[coding] = factors.loadings[index].map((row) => Number(row));
        return acc;
      },
      {}
    );

    this.tableData = Object.keys(featureToLoadingsMap).map((feature, index) => {
      const row = this.crosstabData.filter(
        // tslint:disable-next-line:no-shadowed-variable
        (row) => !row.isTotalRow && row.data[0].rowTarget.coding === feature
      )[0];
      return {
        title: this.targetTitlePipe.transform(
          row.data[0].rowTarget,
          this.activeTitleMode,
          this.titleLevels
        ),
        rowNumber: index + 1,
        loadings: featureToLoadingsMap[feature],
      };
    });
  }

  public onFactorTitleChanged($event: any) {
    this.activeAppData.factorTitles = $event;
    this.saveAnalysisToDoc();
  }

  private formatAudienceGroupItem(
    keys: string[],
    numOfFactors: number
  ): DocumentAudienceGroupItem[] {
    const keyQuinTiles = ['Lowest', 'Low', 'Middle', 'High', 'Highest'];
    return keys.map((key: string, itemIndex: number) => {
      const factorIndex = Math.floor(itemIndex / numOfFactors);
      const quinIndex = itemIndex % numOfFactors;
      const factorTitle =
        this.activeAppData.factorTitles[factorIndex] ||
        `Factor ${factorIndex + 1}`;
      const title = `${factorTitle} - ${keyQuinTiles[quinIndex]}`;
      return {
        title,
        coding: key,
        options: {
          statement: null,
          target: {
            activeTitleMode: this.activeTitleMode,
            ownTitle: title,
            id: uuidv4(),
            fileVersion: 1,
            title,
            coding: key,
            operator: Operator.and,
            created: new Date().getTime(),
            targets: [
              {
                activeTitleMode: this.activeTitleMode,
                ownTitle: title,
                id: uuidv4(),
                fileVersion: 1,
                title,
                coding: key,
                operator: Operator.and,
                created: new Date().getTime(),
                targets: [],
              },
            ],
          },
        },
      };
    });
  }

  private formatAudienceGroupDocument(
    fileName: string,
    audienceGroupItems: DocumentAudienceGroupItem[]
  ): TupDocument<DocumentAudienceGroup> {
    const title = `Factors for crosstab - ${fileName}`;
    return this.documentService.createOwnCodesDocumentObject(title, {
      hasVehicles: false,
      survey: this.activeSurvey,
      type: SaveOwnCodesType.audience,
      targets: audienceGroupItems,
      vehicles: null,
    });
  }

  private saveToDocumentAudienceGroup(
    docsToCreate: TupDocument<DocumentAudienceGroup>[],
    containerName: string
  ): void {
    const observables: Observable<HttpResponse<Empty>>[] = [];
    docsToCreate.forEach((doc) => {
      observables.push(
        this.tupDocumentService
          .create(containerName, doc)
          .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      );
    });

    forkJoin(observables).subscribe(
      (result) => {
        const info = `Factor analysis solution has been saved`;
        this.userMessageService.showSnackBar(
          `${info} into ${this.activeSurvey.title}`,
          'OK'
        );
        this.exportingToCustomCodes = false;
      },
      () => {
        this.exportingToCustomCodes = false;
      }
    );
  }

  public openSettings() {
    this.dialogService
      .factorAnalysisSettings({ settings: this.activeAppData.settings })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((settings: FactorAnalysisSettings) => {
        const isVariableReductionThresholdChanged =
          this.activeAppData.settings.variableOptions.find(
            (variableOption) =>
              variableOption.option ===
              FAVariableOptionType.removeCollinearVariables
          )?.threshold !==
          settings.variableOptions.find(
            (variableOption) =>
              variableOption.option ===
              FAVariableOptionType.removeCollinearVariables
          )?.threshold;

        const isEliminateNegligibleCorrelationsChanged =
          settings.isEliminateNegligibleCorrelations !==
          this.activeAppData.settings.isEliminateNegligibleCorrelations;

        this.activeAppData.settings = settings;
        this.saveAnalysisToDoc(true);

        if (
          isVariableReductionThresholdChanged ||
          isEliminateNegligibleCorrelationsChanged
        ) {
          this.inProgress = true;

          this.hasValidDataset = this.currentDoc.content?.rows.length > 0;
          if (this.hasValidDataset) {
            this.activeAppData.features = this.formatFeatureRows(
              this.currentDoc.content.rows
            );
            this.prepareSurveyData(this.activeSurvey);
            this.activeAppData.survey = this.activeSurvey;
            this.activeAppData.factorTitles = [];
            this.performAnalysis(false);
          } else {
            this.inProgress = false;
          }
        }
      });
  }

  exportToCsv(exportParams?: ExportParams) {
    this.faTableComponent.exportToCsv(exportParams?.docName);
  }

  exportToXlsx(exportParams?: ExportParams) {
    this.faTableComponent.exportToXlsx(exportParams?.docName);
  }

  exportToSheets(exportParams?: ExportParams) {
    this.faTableComponent.exportToSheets(exportParams?.docName);
  }
}
