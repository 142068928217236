<div
  class="table-container-reports"
  tupDocumentEventEmitter
  tupDocumentEventHandler
  customDocumentEventHandler
  documentType="report"
>
  <tup-document-storage
    [options]="options"
    #myReports
  >
    <ng-template tupDocumentStorageCardContent>
      <ng-container *ngTemplateOutlet="filter"></ng-container>
    </ng-template>

    <ng-template tupDocumentStorageCardNoContent>
      <div
        class="no-search-results"
        *ngIf="myReports.nameFilterService.value; else getStarted"
      >
        <ng-container *ngTemplateOutlet="filter"></ng-container>
        <p>No search results.</p>
      </div>

      <ng-template #getStarted>
        <div class="error-message">
          <h3>
            Get started:
            <span
              (click)="(createReport.emit)"
              class="create-report-link"
              >Start crosstabing</span
            >
          </h3>
          <p>
            As you generate new reports, they will be conveniently displayed
            here, providing you with quick access to your most recent data
            analysis. Embark on your analytical adventure and transform this
            space into a valuable resource for informed decision-making.
          </p>
        </div>
      </ng-template>
    </ng-template>

    <ng-template
      *ngIf="myReports.dataSource?.length"
      tupDocumentStorageCardActions
    >
      <div
        class="all-reports"
        (click)="navigateToReportsTab.emit(1)"
      >
        See all reports ({{ myReports?.dataSource?.length }})
      </div>
    </ng-template>
  </tup-document-storage>
</div>

<ng-template #filter>
  <tup-document-filter
    data-tracking="report-search"
    formFieldAppearance="outline"
    formFieldLabel="Search reports"
    [filterFor]="myReports"
  >
  </tup-document-filter>
</ng-template>
