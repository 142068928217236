import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { Sort } from '@angular/material/sort';
import {
  DocumentAudienceGroup,
  SaveOwnCodesType,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { AddToMultipleAction } from 'src/app/actions/AddToMultipleAction';
import { AssignGroupNameAction } from 'src/app/actions/AssignGroupNameAction';
import { ChangeTargetTitleModeAction } from 'src/app/actions/ChangeTargetTitleModeAction';
import { DuplicateTargetsAction } from 'src/app/actions/DuplicateTargetsAction';
import {
  MoveTargetItemsAction,
  MoveTargetItemsActionContext,
} from 'src/app/actions/MoveTargetItemsAction';
import { SaveCustomAudienceAction } from 'src/app/actions/SaveCustomAudienceAction';
import { SeparateCountAction } from 'src/app/actions/SeparateCountAction';
import { SeparateOperatorAction } from 'src/app/actions/SeparateOperatorAction';
import { CodingGridTableComponent } from 'src/app/components';
import {
  ContextMenuData,
  SelectionTreeFlatNode,
} from 'src/app/components/tree-view/tree.models';
import {
  ALL_RESPONDENTS_CODING,
  CodingGridHideColumnMap,
  CodingGridPreferences,
  CodingGridSearchInput,
  CodingGridTableRow,
  DisplayType,
  DocumentDataChange,
  DocumentDataState,
  EMPTY_GRID_ROW_SIZE,
  EMPTY_TARGET_ITEM_INDEX,
  EXTRA_LARGE_BREAKPOINT,
  EXTRA_SMALL_BREAKPOINT,
  LARGE_BREAKPOINT,
  MEDIUM_BREAKPOINT,
  MoreMenuVisibility,
  MoreMenuVisibilityOptions,
  Operator,
  PERSISTENT_CODING_GRID_COLUMNS,
  READONLY_CODING_GRID_COLUMNS,
  SMALL_BREAKPOINT,
  SearchHit,
  Statement,
  Survey,
  Target,
  TargetAction,
  TargetItem,
  TargetType,
  TitleModeGridTableRow,
} from 'src/app/models';
import {
  COMBINE_BUTTON_ACTION_ITEMS,
  ColumnRowActionItem,
  CombineActionItem,
  DEFAULT_BASE_TABLE_ACTION_ITEMS,
  DEFAULT_CODING_GRID_ACTION_ITEMS,
  DeleteTargetTypesActionItem,
  DropActionItem,
  TITLE_ACTIONS,
} from 'src/app/models/action.model';
import { DropDataContext } from 'src/app/pages';
import { CodebookAndVisualCodeBuilderPanelComponent } from 'src/app/panels/codebook-and-visual-code-builder-panel/codebook-and-visual-code-builder-panel.component';
import { TargetTitlePipe } from 'src/app/pipes';
import {
  CodebookSelectionService,
  DialogService,
  DocumentService,
  LoadingSource,
  RequestLoadingService,
  TargetLoading,
  TargetService,
} from 'src/app/services';
import { CodingDataMapService } from 'src/app/services/coding-data-map.service';
import { CodingGridService } from 'src/app/services/coding-grid.service';
import { CustomAudiencesService } from 'src/app/services/custom-audiences.service';
import { FindAndReplaceService } from 'src/app/services/find-and-replace.service';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { NTileDialogResult } from '../ntile-dialog/ntile-dialog.component';
import { QuickReportService } from 'src/app/services/quick-report.service';
import { IAreaSize } from 'ngx-split';
import { CodingGridActionControlsComponent } from 'src/app/components/coding-grid-view/coding-grid-action-controls/coding-grid-action-controls.component';

const CODEBOOK_IN_DIALOG_INITIAL_SIZE_IN_PIXEL: number = 350;
const CODEBOOK_EDITING_MODE_IN_DIALOG_SIZE_IN_PIXEL: number = 850;
const ACTION_CONTROLS_BREAKING_POINT_OFFSET = 600;

export interface AddInsightsDialogDataModel {
  selectedSurvey: Survey;
}

@Component({
  templateUrl: './add-insights-dialog.component.html',
  styleUrls: ['./add-insights-dialog.component.scss'],
})
export class AddInsightsDialogComponent implements OnInit {
  private unsubscribe: Subject<void> = new Subject<void>();

  public splitSizes: Record<string, IAreaSize> = {
    codebook: CODEBOOK_IN_DIALOG_INITIAL_SIZE_IN_PIXEL,
    maxSize: CODEBOOK_EDITING_MODE_IN_DIALOG_SIZE_IN_PIXEL,
    minSize: 280,
  };

  public readonly emptyGridRowSize = EMPTY_GRID_ROW_SIZE;
  public combineActionItems = COMBINE_BUTTON_ACTION_ITEMS;

  public isDeletable = false;
  public isProgressing = false;
  public selectedSurvey: Survey;
  public rowsTabLabel = '(0)';

  public readonly targetType: typeof TargetType = TargetType;
  public rows: CodingGridTableRow[] = [];
  public gridSort: Sort = { active: '', direction: '' };
  public searchHits: SearchHit[];
  public findAndReplaceDialogState: boolean;
  public findAndReplaceDialogOpen = false;

  public isLoadingSelectedNodes = false;
  private isOverActionMenu = false;
  private isDropzoneMenuOpen = false;
  private menuTarget: EventTarget;
  private activeMenuTrigger: MatMenuTrigger;
  public readonly menuItemDragoverClass = 'drag-over-menu-button';
  public contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('codebookMenuTrigger') private codebookContextMenu: MatMenuTrigger;

  @ViewChild('codebookVisualCodeBuilderPanel', { static: false })
  private codebookVisualCodeBuilderPanel: CodebookAndVisualCodeBuilderPanelComponent;

  @ViewChild('rowCodingGrid') private rowCodingGrid: CodingGridTableComponent;

  public isCodebookSearchingOrFiltering = false;
  private clickedTarget: TargetItem;
  public selectedRows: CodingGridTableRow[] = [];

  public changeTitleModeItems = (TITLE_ACTIONS[0] as ColumnRowActionItem)
    .actions;

  @ViewChild('gridViewContainer') public gridViewContainer: ElementRef;
  public moreMenuVisibility: MoreMenuVisibility = '';
  public readonly moreMenuVisibilityOptions = MoreMenuVisibilityOptions;

  public hideColumnItems: CodingGridHideColumnMap =
    {} as CodingGridHideColumnMap;
  public isColumnHidden = false;

  @ViewChild('appCodingGridActionControls', { static: true })
  public appCodingGridActionControls: CodingGridActionControlsComponent;

  public codingGridPreferences: CodingGridPreferences;

  public sortedRows: Record<TargetType, CodingGridTableRow[]> = {
    [TargetType.tables]: [],
    [TargetType.columns]: [],
    [TargetType.rows]: [],
  };

  private dropzoneTargetType: TargetType = TargetType.rows;
  private dropzoneTargetItem: TargetItem;
  public dropzoneActionItems: DropActionItem[] =
    DEFAULT_CODING_GRID_ACTION_ITEMS;

  private dropzoneEnterCount = 0;

  private selectedCodebookNodes: Target[] = [];

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('virtualButton') public virtualButton;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddInsightsDialogDataModel,
    public dialogRef: MatDialogRef<AddInsightsDialogComponent>,
    private codebookSelectionService: CodebookSelectionService,
    private audienceGroupsService: TupAudienceGroupsService,
    private customAudiencesService: CustomAudiencesService,
    private separateOperatorAction: SeparateOperatorAction,
    private messageService: TupUserMessageService,
    private requestLoadingService: RequestLoadingService,
    private targetService: TargetService,
    private documentService: DocumentService,
    private addToMultipleAction: AddToMultipleAction,
    private injector: Injector,
    private codingGridService: CodingGridService,
    private moveTargetItemActionService: MoveTargetItemsAction,
    private findAndReplaceService: FindAndReplaceService,
    private targetTitlePipe: TargetTitlePipe,
    public quickReportService: QuickReportService,
    private codingDataMapService: CodingDataMapService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.selectedSurvey = this.data.selectedSurvey;
    this.listenToDocumentDataChanges();
    this.listenToSelectedNodes();
    this.listenToCodingGridPreferences();
    this.listenToSearchHitChanges();
    this.listenToLoadingState();

    this.quickReportService.isAddToRowsOnly(true);
  }

  @HostListener('window:keydown', ['$event'])
  listenToSearchEvent(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
      if (!(this.findAndReplaceDialogOpen || this.findAndReplaceDialogState)) {
        event.preventDefault();
        this.onOpenFindAndReplace();
      }
    }
  }

  public onOpenFindAndReplace(): void {
    if (this.findAndReplaceDialogOpen) {
      return;
    }

    this.findAndReplaceDialogOpen = true;
    setTimeout(() => {
      this.findAndReplaceDialogOpen = false;
    }, 1000);

    this.onFindAndReplaceClick({
      rows: this.sortedRows[TargetType.rows],
      targetType: TargetType.rows,
      codingGridSearch: true,
    });
  }

  public onFindAndReplaceClick(data: CodingGridSearchInput): void {
    this.dialog
      .findAndReplace(data)
      .afterClosed()
      .subscribe(() => {
        this.findAndReplaceService.updateDialogState(false);
        this.findAndReplaceService.updateSearchHits([]);
      });
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public onActionMenuEnter(event: DragEvent): void {
    this.menuTarget = event.currentTarget;
    this.isOverActionMenu = true;
  }

  public onActionMenuLeave(event: DragEvent): void {
    const target = event.currentTarget;
    // note: dndDragoverClass doesn't always get removed after leaving the target
    if (target && target instanceof Element) {
      target.classList.remove(this.menuItemDragoverClass);
    }
    if (this.menuTarget === target) {
      this.isOverActionMenu = false;
      this.closeActionMenu();
    }
  }

  private closeActionMenu(): void {
    if (this.isDropzoneMenuOpen && !this.isOverActionMenu) {
      this.isDropzoneMenuOpen = false;
      this.activeMenuTrigger.closeMenu();
      this.activeMenuTrigger = null;
      this.dropzoneActionItems = DEFAULT_CODING_GRID_ACTION_ITEMS;
    }
  }

  public onDrop(actionItem: DropActionItem): void {
    if (this.isLoadingSelectedNodes) {
      return;
    }
    // this.dropNode.emit();

    this.onDropNodeToGrid({
      selectedNodes: this.codebookSelectionService.getSelectedNodes(),
      context: actionItem,
      handleDropNode: (action: DropActionItem, selectedNodes: Statement[]) =>
        this.handleDropNode(action, selectedNodes),
    });
    this.unsetDraggingState();
  }

  private onDropNodeToGrid(event: DropDataContext<any>) {
    const selectedNodes = event.selectedNodes;
    if (selectedNodes) {
      this.codebookSelectionService.setLoadingChildrenNodes(
        true,
        LoadingSource.fromDrag
      );
      this.codebookVisualCodeBuilderPanel
        .loadChildren(selectedNodes)
        .then((result) => {
          this.codebookSelectionService.setLoadingChildrenNodes(false);
          this.codebookSelectionService.setSelectedNodes(result);
          event.handleDropNode(event.context, result);
        });
    }
  }

  public handleDropNode(
    actionItem: DropActionItem,
    selectedNodes: Statement[]
  ): void {
    const dropzoneTargetRows = this.rows;
    this.injector.get(actionItem.action).invoke({
      targetType: this.dropzoneTargetType,
      selectedTargets:
        this.targetService.convertStatementsToTargets(selectedNodes),
      selectableTargets: dropzoneTargetRows
        .filter((row) => !row.isEmptyRow)
        .map((row) => row.targetItem.target),
      targetItem: this.dropzoneTargetItem,
      actionItem,
    });
  }

  private unsetDraggingState(): void {
    this.isOverActionMenu = false;
    this.dropzoneEnterCount = 0;
    this.closeActionMenu();
  }

  public onCodebookContextMenu(data: ContextMenuData): void {
    this.contextMenuPosition.x = data.event.clientX + 'px';
    this.contextMenuPosition.y = data.event.clientY + 'px';
    this.codebookContextMenu.menuData = {
      node: data.node,
      path: data.path,
      level: data.level,
      selectedNodes: data.selectedNodes,
      selectionTreeNode: data.selectionTreeNode,
    };
    this.codebookContextMenu.openMenu();
  }

  public onCodebookSearchingOrFilteringStateChange(state: boolean): void {
    this.isCodebookSearchingOrFiltering = state;
  }

  public addToRows(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.rows,
      LoadingSource.fromMenu
    );
  }

  public expandAllCodebook(node: Statement) {
    this.codebookVisualCodeBuilderPanel.codebookFetchAndExpandNodes([node]);
  }

  public updateCustomAudiencesNodeContainer(node: Statement): void {
    this.audienceGroupsService.updateOwnCodesContainer(node.document).subscribe(
      () => {
        this.customAudiencesService.notifyOwnCodesContainerUpdated(
          SaveOwnCodesType.audience
        );
      },
      () => {}
    );
  }

  public sort(node: SelectionTreeFlatNode, sort: string): void {
    this.codebookVisualCodeBuilderPanel.sortCustomAudienceOrMediaNode(
      node,
      sort
    );
  }

  public refreshCustomNodes(node: SelectionTreeFlatNode): void {
    this.codebookVisualCodeBuilderPanel.onCodebookLoadData(node);
  }

  public editCustomNodeTitle(node: SelectionTreeFlatNode): void {
    this.audienceGroupsService
      .editOwnCodesTargetTitle(
        node.data.customData.document,
        node.data.customData.index
      )
      .subscribe((document: TupDocument<DocumentAudienceGroup>) => {
        this.messageService.showSnackBar(
          'Custom audiences/media updated successfully',
          'OK',
          10000
        );
        const audienceGroupItem =
          document.content.targets[node.data.customData.index];
        const paths = node.data.path.split('|');
        paths.pop();
        this.codebookVisualCodeBuilderPanel.updateCustomAudienceOrMediaNode(
          node,
          {
            description: audienceGroupItem.title,
            path: paths.join('|') + '|' + audienceGroupItem.title,
            customData: {
              index: node.data.customData.index,
              target: audienceGroupItem.options.target,
              document,
            },
          }
        );
      });
  }

  public addToMultiple(nodes: Statement[], type: TargetType): void {
    this.codebookSelectionService.setLoadingChildrenNodes(
      true,
      LoadingSource.fromMenu
    );

    this.codebookVisualCodeBuilderPanel.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.documentService.documentState$
        .pipe(first())
        .subscribe(({ rows }: DocumentDataState) => {
          this.addToMultipleAction.invoke({
            targetType: type,
            selectedTargets:
              this.targetService.convertStatementsToTargets(result),
            selectableTargets: rows,
          });
        });
    });
  }

  public onClickRow(row: CodingGridTableRow): void {
    const targetItem: TargetItem = cloneDeep(row.targetItem);
    this.clickedTarget = targetItem;
    this.openVisualCodeBuilder(targetItem.target);
  }

  public onClickEmptyRow(): void {
    this.openNewCodeDialog();
  }

  public onOpenCodeBuilder(row?: CodingGridTableRow): void {
    const targetItem: TargetItem = row
      ? cloneDeep(row.targetItem)
      : cloneDeep(this.selectedRows[0].targetItem);

    this.clickedTarget = targetItem;
    this.openVisualCodeBuilder(targetItem.target);
  }

  private openVisualCodeBuilder(target: Target): void {
    const offsetWidth = 60;
    this.splitSizes.codebook =
      CODEBOOK_EDITING_MODE_IN_DIALOG_SIZE_IN_PIXEL + offsetWidth;
    this.codebookVisualCodeBuilderPanel.openCodeBuilder(target);
  }

  public onDropzoneEnter(
    trigger: MatMenuTrigger,
    targetType: TargetType,
    targetItem?: TargetItem
  ): void {
    this.dropzoneTargetType = targetType;
    this.dropzoneTargetItem = targetItem;

    this.dropzoneEnterCount++;
    setTimeout(() => {
      if (this.dropzoneEnterCount === 0 || this.activeMenuTrigger === trigger) {
        return;
      }
      if (
        this.activeMenuTrigger &&
        (this.isDropzoneMenuOpen || this.dropzoneEnterCount > 1)
      ) {
        this.closeActionMenu();
      }
      this.activeMenuTrigger = trigger;
      this.isDropzoneMenuOpen = true;

      if (!this.hasDropTargetItem()) {
        this.dropzoneActionItems = DEFAULT_BASE_TABLE_ACTION_ITEMS;
      }

      if (this.canOpenCombineMenuOptions()) {
        trigger.openMenu();
      }
      // need to focus on something else, otherwise the first item of the menu gets focused for some reason
      this.virtualButton.nativeElement.focus();
    }, 300);
  }

  public onDropzoneLeave(): void {
    this.dropzoneEnterCount--;
    setTimeout(() => {
      this.closeActionMenu();
    }, 200);
  }

  public onTableDropzoneDrop(targetItem: TargetItem): void {
    this.dropzoneTargetItem = targetItem;
    this.onDrop(
      !this.hasDropTargetItem()
        ? DEFAULT_BASE_TABLE_ACTION_ITEMS[1]
        : DEFAULT_BASE_TABLE_ACTION_ITEMS[0]
    );
  }

  private hasDropTargetItem(): boolean {
    return !!this.dropzoneTargetItem;
  }

  private canOpenCombineMenuOptions(): boolean {
    return this.hasDropTargetItem() || this.hasMultipleSelectedNodes();
  }

  private hasMultipleSelectedNodes(): boolean {
    return this.selectedCodebookNodes.length > 1;
  }

  public onVisualCodeBuilderClose(): void {
    this.clickedTarget = null;
    this.splitSizes.codebook = CODEBOOK_IN_DIALOG_INITIAL_SIZE_IN_PIXEL;
  }

  public onApplyCodingChanges(target: Target): void {
    if (this.clickedTarget.index !== EMPTY_TARGET_ITEM_INDEX) {
      this.documentService.updateDocumentData([
        {
          ...this.clickedTarget,
          target,
        },
      ]);
    } else {
      this.documentService.addDocumentData([target], TargetType.rows, true);
    }
  }

  public onDropNode(event: DropDataContext<any>) {
    const selectedNodes = event.selectedNodes;
    if (selectedNodes) {
      this.codebookSelectionService.setLoadingChildrenNodes(
        true,
        LoadingSource.fromDrag
      );
      this.codebookVisualCodeBuilderPanel
        .loadChildren(selectedNodes)
        .then((result) => {
          this.codebookSelectionService.setLoadingChildrenNodes(false);
          this.codebookSelectionService.setSelectedNodes(result);
          event.handleDropNode(event.context, result);
        });
    }
  }

  private listenToSelectedNodes(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        if (selectedNodes.length < 1) {
          this.codebookVisualCodeBuilderPanel?.unselectAllNodes();
        }

        this.selectedCodebookNodes =
          selectedNodes.length > 0
            ? this.targetService.convertStatementsToTargets(selectedNodes)
            : [];
      });
  }

  private addSelectedNodesToDocument(
    nodes: Statement[],
    type: TargetType,
    origin: LoadingSource
  ): void {
    this.codebookSelectionService.setLoadingChildrenNodes(true, origin);
    this.codebookVisualCodeBuilderPanel.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.separateOperatorAction.invoke({
        targetType: type,
        selectedTargets: this.targetService.convertStatementsToTargets(result),
      });
    });
  }

  public editCustomAudiences(node: Statement): void {
    this.customAudiencesService.editCustomAudiences(node.document).subscribe(
      () => {
        this.customAudiencesService.notifyOwnCodesAdded(
          SaveOwnCodesType.audience,
          node.document
        );
        this.messageService.showSnackBar(
          'Custom audiences/media saved successfully',
          'OK',
          10000
        );
      },
      (error) => {
        console.log(`error`, error);
        this.messageService.showSnackBar(error, 'OK', 10000);
      }
    );
  }

  public deleteCustomAudiencesNode(node: Statement): void {
    this.audienceGroupsService
      .deleteOwnCodesWithConfirmation(node.document)
      .subscribe(() => {
        if (this.isCodebookSearchingOrFiltering) {
          this.codebookVisualCodeBuilderPanel.deleteCustomAudienceOrMediaNode(
            node
          );
        } else {
          this.customAudiencesService.notifyOwnCodesContainerUpdated(
            SaveOwnCodesType.audience
          );
        }
      });
  }

  public renameCustomAudiencesNode(node: SelectionTreeFlatNode): void {
    this.audienceGroupsService
      .renameOwnCodesDocument(node.data.document)
      .subscribe((document: TupDocument<DocumentAudienceGroup>) => {
        this.messageService.showSnackBar(
          'Custom audiences/media updated successfully',
          'OK',
          10000
        );
        const paths = node.data.path.split('|');
        paths.pop();
        this.codebookVisualCodeBuilderPanel.updateCustomAudienceOrMediaNode(
          node,
          {
            description: document.metadata.name,
            path: paths.join('|') + '|' + document.metadata.name,
            document,
          }
        );
      });
  }

  public onDeleteSelectedRows(row?: CodingGridTableRow): void {
    if (this.isAllSelected()) {
      this.showDeleteMessageBeforeDelete();
    } else {
      this.deleteSelectedRows(row);
    }
  }

  public onSeparateCountActionClicked(row?: CodingGridTableRow): void {
    const selectedTab = TargetType.rows;
    this.injector.get(SeparateCountAction).invoke({
      targetType: selectedTab,
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onOpenNTileSettings(row?: CodingGridTableRow): void {
    const targetItem: TargetItem = row
      ? cloneDeep(row.targetItem)
      : cloneDeep(this.selectedRows[0].targetItem);
    this.openNtileDialog(targetItem);
  }

  private openNtileDialog(targetClick: TargetItem): void {
    this.dialog
      .nTileSettings(targetClick.target, targetClick.type)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: NTileDialogResult) => {
        const targets = this.targetService.createNTileTargets(
          result.target,
          result.nTileBuckets,
          result.nTileFunction
        );
        const createdTargets: Target[] = [];
        targets.forEach((target) => {
          const createdTarget = this.targetService.groupTargets(
            [target],
            Operator.ntiles,
            true
          );
          createdTargets.push(...createdTarget);
        });

        if (targetClick.type === result.targetType) {
          const insertIndex =
            this.documentService.document.content[
              this.documentService.getTargetKey(targetClick.type)
            ].findIndex((row: Target) => row.id === targetClick.target.id) + 1;

          this.documentService.addDocumentData(
            createdTargets,
            result.targetType,
            true,
            insertIndex
          );
        } else {
          this.documentService.addDocumentData(
            createdTargets,
            result.targetType,
            true
          );
        }
      });
  }

  public onOpenNewCodeBuilder(): void {
    this.openNewCodeDialog();
  }

  private openNewCodeDialog(): void {
    this.dialog
      .newCodeBuilder(
        TargetType.rows,
        this.documentService,
        this.targetService,
        this.codingDataMapService,
        this.documentService.activeSurvey
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: Target) => {
        this.documentService.addDocumentData([result], TargetType.rows, true);
      });
  }

  public onChangeTargetTitleModeActionClicked(
    actionItem: TitleModeGridTableRow
  ): void {
    if (actionItem?.row) {
      this.injector.get(ChangeTargetTitleModeAction).invoke({
        targetItem: [actionItem.row.targetItem],
        actionItem,
      });
    } else if (this.selectedRows?.length >= 1) {
      this.injector.get(ChangeTargetTitleModeAction).invoke({
        targetItem: this.selectedRows.map((row) => row.targetItem),
        actionItem,
      });
    }
  }

  public onDuplicateSelectedRows(row?: CodingGridTableRow): void {
    this.injector.get(DuplicateTargetsAction).invoke({
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onCombineActionClicked(actionItem: CombineActionItem): void {
    this.injector.get(actionItem.action).invoke({
      targetType: TargetType.rows,
      selectedTargets: this.selectedRows.map(
        (selectedRow: CodingGridTableRow) => ({
          ...cloneDeep(selectedRow.targetItem.target.targets[0]),
          activeTitleMode: undefined,
          titleLevels: undefined,
        })
      ),
      actionItem,
    });
    this.resetGrids();
  }

  public onRenameGroupName(row?: CodingGridTableRow): void {
    this.injector.get(AssignGroupNameAction).invoke({
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onSaveCustomAudience(row?: CodingGridTableRow): void {
    this.injector.get(SaveCustomAudienceAction).invoke({
      targetType: TargetType.rows,
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onDeleteAllRows(actionItem: DeleteTargetTypesActionItem): void {
    this.injector.get(actionItem.action).invoke({
      actionItem,
    });
    this.resetGrids();
  }

  public onOpenMoreMenu() {
    if (this.gridViewContainer?.nativeElement?.offsetWidth) {
      const containerWidth = this.gridViewContainer.nativeElement.offsetWidth;

      if (
        containerWidth >=
        EXTRA_LARGE_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.none;
      } else if (
        containerWidth >=
          LARGE_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET &&
        containerWidth <
          EXTRA_LARGE_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.xl;
      } else if (
        containerWidth >=
          MEDIUM_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET &&
        containerWidth <
          LARGE_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.lg;
      } else if (
        containerWidth >=
          SMALL_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET &&
        containerWidth <
          MEDIUM_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.md;
      } else if (
        containerWidth >=
          EXTRA_SMALL_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET &&
        containerWidth <
          SMALL_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.sm;
      } else if (
        containerWidth <
        EXTRA_SMALL_BREAKPOINT - ACTION_CONTROLS_BREAKING_POINT_OFFSET
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.xs;
      }
    }
  }

  public onHideColumns() {
    const hiddenColumns = [];
    this.appCodingGridActionControls.hideColumnSelectionList.options.forEach(
      (option) => {
        !option.selected && hiddenColumns.push(option.value);
        this.hideColumnItems[TargetType.rows].filter(
          (item) => item.value === option.value
        )[0].show = option.selected;
      }
    );

    this.codingGridPreferences[TargetType.rows] = { hiddenColumns };
    this.codingGridPreferences = cloneDeep(this.codingGridPreferences);
    this.codingGridService.updateCodingGridPreferences(
      this.codingGridPreferences
    );
    this.isColumnHidden =
      this.codingGridPreferences[TargetType.rows].hiddenColumns.length > 0;
  }

  public onSelectedRowsChange(rows: CodingGridTableRow[]): void {
    this.selectedRows = rows;
    this.updateTabLabels();
    this.updateDeleteAbility();
  }

  public onMoveItems(moveItem: MoveTargetItemsActionContext): void {
    this.moveTargetItemActionService.invoke(moveItem);
    this.resetGrids();
  }

  public onSortChange(sort: Sort): void {
    this.gridSort = sort;
  }

  public onSortedRowsChange(
    rows: CodingGridTableRow[],
    type: TargetType
  ): void {
    this.sortedRows[type] = rows.filter((row) => !row.isEmptyRow);
    this.codingGridService.updateActiveGridRows(
      this.sortedRows[TargetType.rows]
    );
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: DocumentDataState) => {
        this.handleDocumentStateChange(state);
      });
  }

  private handleDocumentStateChange({
    columns,
    rows,
    tables,
    changes,
  }: DocumentDataState): void {
    this.rows = this.formatGridData(rows, TargetType.rows);
    this.updateTabLabels();

    if (changes && changes[0].action !== TargetAction.delete) {
      this.selectUpdatedDocumentRows(changes);
    }
  }

  private selectUpdatedDocumentRows(changes: DocumentDataChange[]): void {
    const tabRows = this.rows;
    const addedRows = changes[0].items
      .map((item: TargetItem) => item.index)
      .map((index: number) => tabRows[index]);

    setTimeout(() => this.updateSelectedTableRows(addedRows), 200);
  }

  private updateSelectedTableRows(addedRows: CodingGridTableRow[]): void {
    this.rowCodingGrid.setSelectedRows(addedRows);
  }

  private formatGridData(
    targets: Target[],
    type: TargetType
  ): CodingGridTableRow[] {
    return [
      ...targets.map(
        (target: Target, index: number): CodingGridTableRow => ({
          id: index + 1,
          title: this.targetTitlePipe.transform(target),
          code: target.coding,
          groupName: this.targetTitlePipe.transform(target, DisplayType.group),
          selected: false,
          isEmptyRow: false,
          targetId: target.id,
          targetItem: {
            type,
            target,
            index,
          },
        })
      ),
      ...this.createEmptyGridData(targets.length),
    ];
  }

  private createEmptyGridData(startIndex: number): CodingGridTableRow[] {
    return Array(this.emptyGridRowSize)
      .fill(0)
      .map((v, i) => ({
        id: startIndex + i + 1,
        title: '',
        code: '',
        resps: '-',
        population: '-',
        groupName: '',
        selected: false,
        isEmptyRow: true,
      }));
  }

  private listenToCodingGridPreferences(): void {
    this.codingGridService.codingGridPreferences$
      .pipe()
      .subscribe((preferences: CodingGridPreferences) => {
        this.codingGridPreferences = cloneDeep(preferences);
        this.loadGridPreferences();
      });
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isProgressing = targetLoading.isLoading;
      });
  }

  private loadGridPreferences() {
    Object.keys(this.codingGridPreferences).forEach((targetType) => {
      const codingGridPreference = this.codingGridPreferences[targetType];
      const preferences = this.getCodingGridHideableColumns().map((column) => ({
        value: column,
        label:
          column === 'group'
            ? 'Group name'
            : column.charAt(0).toUpperCase() + column.slice(1),
        show: !codingGridPreference.hiddenColumns.includes(column),
      }));
      this.hideColumnItems[targetType] = cloneDeep(preferences);
    });

    this.isColumnHidden =
      this.codingGridPreferences[TargetType.rows].hiddenColumns.length > 0;
    this.setHideColumnSelectionList();
  }

  private setHideColumnSelectionList() {
    if (this.appCodingGridActionControls?.hideColumnSelectionList?.options) {
      this.appCodingGridActionControls.hideColumnSelectionList.selectedOptions.clear();
      const selectedTabShowCol = this.hideColumnItems[TargetType.rows]
        .filter((option) => option.show)
        .map((option) => option.value);
      this.appCodingGridActionControls.hideColumnSelectionList.options.forEach(
        (option) => {
          if (selectedTabShowCol.includes(option.value)) {
            this.appCodingGridActionControls.hideColumnSelectionList.selectedOptions.select(
              option
            );
          }
        }
      );
    }
  }

  private getCodingGridHideableColumns() {
    return READONLY_CODING_GRID_COLUMNS.filter(
      (column) => !PERSISTENT_CODING_GRID_COLUMNS.includes(column)
    );
  }

  private isAllSelected(): boolean {
    let isAll = false;
    const activeRowsNumber = this.rows.length - this.emptyGridRowSize;
    if (this.selectedRows.length === activeRowsNumber) {
      isAll = true;
    }
    return isAll;
  }

  private showDeleteMessageBeforeDelete(): void {
    this.messageService
      .openDialog(
        `You selected to clear your entire grid. This action cannot be undone. Are you sure you want to delete your entire grid? `,
        'Delete',
        {
          cancelText: 'Cancel',
          confirmText: 'Delete',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.deleteSelectedRows();
      });
  }

  private deleteSelectedRows(row?: CodingGridTableRow): void {
    const selectedTargetItems: TargetItem[] = row
      ? [row.targetItem]
      : // tslint:disable-next-line:no-shadowed-variable
        this.selectedRows.map((row: CodingGridTableRow) => row.targetItem);

    this.documentService.deleteDocumentData(selectedTargetItems, true);
    this.resetGrids();
  }

  private resetGrids(): void {
    this.rowCodingGrid.resetSelectedRowIds();
  }

  private listenToSearchHitChanges(): void {
    this.findAndReplaceService.dialogState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: boolean) => {
        setTimeout(() => {
          this.findAndReplaceDialogState = state;
        }, 0);
      });

    this.findAndReplaceService.searchHits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((hits: SearchHit[]) => {
        this.searchHits = hits;
      });
  }

  private updateDeleteAbility(): void {
    this.isDeletable =
      !this.isAllRespondentsSelected() && this.selectedRows.length > 0;
  }

  private isAllRespondentsSelected(): boolean {
    let isSelected = false;
    this.selectedRows.forEach((row) => {
      if (
        row.targetItem.type === TargetType.tables &&
        row.targetItem.target.coding === ALL_RESPONDENTS_CODING
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  }

  private updateTabLabels(): void {
    const selectedRowLength = this.selectedRows.length;
    this.rowsTabLabel = `(${selectedRowLength} / ${
      this.rows.length - this.emptyGridRowSize
    })`;
  }
}
