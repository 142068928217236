import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Snapshot } from 'src/app/models';
import {
  RenameDialogComponent,
  RenameDialogResult,
} from '../rename-dialog/rename-dialog.component';
import {
  TupDocument,
  TupDocumentEventEmitterDirective,
  TupDocumentEventType,
  TupDocumentService,
  isNotNullOrUndefined,
} from '@telmar-global/tup-document-storage';
import { concatMap, tap } from 'rxjs/operators';
import { ConfirmDeleteSnapshotDialogComponent } from '../confirm-delete-snapshot-dialog/confirm-delete-snapshot-dialog.component';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { SnapshotService } from '../../services/snapshot.service';

@Component({
  templateUrl: './manage-snapshots-dialog.component.html',
  styleUrls: ['./manage-snapshots-dialog.component.scss'],
})
export class ManageSnapshotsDialogComponent implements OnInit, OnDestroy {
  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;

  @ViewChild('applyConfirmation')
  applyConfirmation: TemplateRef<any>;

  public dataSource: MatTableDataSource<Snapshot[]>;
  public displayedColumns: string[] = ['action', 'name', 'createdTime'];
  public progressing = false;

  constructor(
    public dialogRef: MatDialogRef<ManageSnapshotsDialogComponent>,
    private tupDocumentService: TupDocumentService,
    private userMessageService: TupUserMessageService,
    private snapshotService: SnapshotService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  public ngOnInit(): void {
    this.dataSource = this.data.snapshots;
  }

  public ngOnDestroy(): void {}

  public onClick(snapshot: Snapshot): void {
    this.userMessageService
      .openCustomMessageDialog(this.applyConfirmation, 'Apply snapshot', {
        confirmText: 'Apply',
        centered: true,
        width: '400px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.snapshotService.applySnapshot(
            this.data.currentDocument,
            snapshot.docId
          );
        }
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public onRenameClicked(snapshot: Snapshot): void {
    let _dialogResult: RenameDialogResult;
    const dialogTitle = 'Rename snapshot';
    const inputTitle = 'Snapshot name';
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle,
          inputTitle,
          inputValue: snapshot.name,
        },
        closeOnNavigation: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        tap(
          (dialogResult: RenameDialogResult) => (_dialogResult = dialogResult)
        ),
        concatMap(() => {
          this.progressing = true;
          return this.tupDocumentService.get(
            this.data.containerName,
            snapshot.docId
          );
        })
      )
      .subscribe((document: TupDocument<unknown>) => {
        this.updateSnapshot(document, _dialogResult);
        this.updateCurrentDocument(snapshot, _dialogResult.name);
      });
  }

  public onDeleteClicked(snapshot: Snapshot): void {
    this.dialog
      .open(ConfirmDeleteSnapshotDialogComponent, {
        data: {
          snapshotName: snapshot.name,
        },
        closeOnNavigation: true,
        autoFocus: false,
        width: '400px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result) => {
        if (result) {
          this.progressing = true;
          this.tupDocumentService
            .delete(this.data.containerName, snapshot.docId)
            .subscribe(() => {
              this.updateCurrentDocument(snapshot);
            });
        }
      });
  }

  private updateSnapshot(
    snapshot: TupDocument<unknown>,
    dialogResult: RenameDialogResult
  ) {
    const updatedSnapshot: TupDocument<unknown> = {
      ...snapshot,
      metadata: {
        ...snapshot.metadata,
        name: dialogResult.name,
      },
    };

    this.documentEventEmitter.emit(
      updatedSnapshot,
      TupDocumentEventType.UPDATE
    );
  }

  private updateCurrentDocument(
    snapshot: Snapshot,
    updatedName?: string
  ): void {
    const currentDoc = this.data.currentDocument;
    const snapshotId = snapshot.docId;
    if (updatedName) {
      currentDoc.content.snapshots.find(
        (snapshot: Snapshot) => snapshot.docId === snapshotId
      ).name = updatedName;
    } else {
      currentDoc.content.snapshots = currentDoc.content.snapshots.filter(
        (snapshot: Snapshot) => snapshot.docId !== snapshotId
      );
    }

    this.tupDocumentService
      .update(this.data.containerName, currentDoc)
      .subscribe(
        () => {
          this.updateUI(snapshot, updatedName);
        },
        (error) => {
          console.log(`error`, error);
        }
      );
  }

  private updateUI(snapshot: Snapshot, updatedName?: string): void {
    const snapshotId = snapshot.docId;
    if (updatedName) {
      this.data.snapshots.find(
        (snapshot: Snapshot) => snapshot.docId === snapshotId
      ).name = updatedName;
    } else {
      this.data.snapshots = this.data.snapshots.filter(
        (snapshot: Snapshot) => snapshot.docId !== snapshotId
      );

      this.dataSource = this.data.snapshots;
      this.userMessageService.showSnackBar(
        `Snapshot "${snapshot.name}" has been deleted.`,
        'OK'
      );
    }
    this.progressing = false;
  }
}
