import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { TupApiServiceBase, TupAuthService } from '@telmar-global/tup-auth';
import { catchError, switchMap } from 'rxjs/operators';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { NavigationExtras, Router } from '@angular/router';
import { ViewType } from '../models';
import { DocumentService } from './document.service';
import { MatDialog } from '@angular/material/dialog';
import { AppApiErrorDialogComponent } from '../dialogs';

const tupApiKeyEnv = environment.production ? environment : environment.engines;

@Injectable({
  providedIn: 'root',
})
export class CorrespondenceApiService extends TupApiServiceBase {
  constructor(
    http: HttpClient,
    private documentService: DocumentService,
    private authService: TupAuthService,
    private userMessageService: TupUserMessageService,
    private dialog: MatDialog,
    private router: Router
  ) {
    super(http, tupApiKeyEnv);
  }

  public request(
    method: string,
    rootUrl: string,
    endPoint: string,
    options?: any
  ): Observable<any> {
    const url = rootUrl + endPoint;
    return super.request(method, url, options).pipe(
      catchError((error) => {
        const errorMessage =
          error instanceof HttpErrorResponse
            ? error?.error?.message
            : 'There was an error trying to talk to the application server, please try again later.';

        const errorStatus = String(error.status);
        if (errorStatus.startsWith('4') || errorStatus.startsWith('5')) {
          return this.showFailureDialog(errorMessage);
        }

        return this.userMessageService
          .openMessageDialog(errorMessage, 'Error')
          .afterClosed()
          .pipe(
            switchMap(() => {
              return throwError(error);
            })
          );
      })
    );
  }

  private showFailureDialog(errorMessage: string): Observable<never> {
    const appMessage = 'There is an issue with correspondence analysis:';
    const appTitle = 'Correspondence analysis failed';
    return this.dialog
      .open(AppApiErrorDialogComponent, {
        data: {
          errorMessage,
          appMessage,
          appTitle,
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) {
            return throwError('Cancel');
          }

          this.documentService.navToCrosstab();

          return throwError('crosstab');
        })
      );
  }
}
