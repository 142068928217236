import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  CellStyleStatus,
  ColumnFilter,
  ColumnHeaderFilter,
  DataItemType,
  DEFAULT_INDEX_PERCENTAGE,
  DEFAULT_SORT_OPTIONS,
  DefaultDataItem,
  DefaultDataItems,
  FreezeTotals,
  HEATMAP_CUSTOM,
  HEATMAP_QUARTILES,
  HEATMAP_QUINTILES,
  HeatmapValues,
  HighlightColors,
  HighlightValues,
  REPORT_PREFERENCE_VERSION,
  ReportHighlightType,
  ReportMode,
  ReportPreference,
  ReportPreferences,
  SortSettings,
  Survey,
  Target,
  TargetType,
} from '../models';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportPreferencesService {
  private reportMode = new BehaviorSubject<ReportMode>(ReportMode.crossTab);
  public reportMode$ = this.reportMode.asObservable();

  private completeCases = new BehaviorSubject<boolean>(null);
  public completeCases$ = this.completeCases.asObservable();

  // tslint:disable-next-line:variable-name
  private _affinityRow: Target = null;

  private freezeTotals = new BehaviorSubject<FreezeTotals>({
    column: false,
    row: false,
  });
  public freezeTotals$ = this.freezeTotals.asObservable();

  private preference: BehaviorSubject<ReportPreference> =
    new BehaviorSubject<ReportPreference>(null);
  public preference$ = this.preference.asObservable();

  private preferences: BehaviorSubject<ReportPreferences> =
    new BehaviorSubject<ReportPreferences>(this.getDefaultPreferences());
  public preferences$ = this.preferences.asObservable();

  private affinityRankColumnId: string;

  public initialisePreferences(
    preferences: ReportPreferences,
    shouldResetPreferences: boolean
  ): void {
    // If old style heatmap preference, convert to new
    this.migrateHeatMapData(preferences);
    this.emitPreferences(
      shouldResetPreferences ? this.getDefaultPreferences() : preferences
    );
  }

  public resetStates(): void {
    this._affinityRow = null;
    this.reportMode.next(ReportMode.crossTab);
    this.emitPreferences(this.getDefaultPreferences());
    this.resetFreezeTotals();
  }

  public getSavedDefaultDataItems(): DefaultDataItems | null {
    return this.preferences.value[ReportMode.crossTab].dataItems
      ? Object.keys(this.preferences.value).reduce(
          (acc, mode) => ({
            ...acc,
            [mode]: this.preferences.value[mode].dataItems,
          }),
          {} as DefaultDataItems
        )
      : null;
  }

  public updateDefaultDataItems(
    defaultDataItems: DefaultDataItems,
    decimalPoints: number | null,
    updateDefaultFilters = true,
    updateDefaultSortSettings = true,
    emitReportPreferences = true
  ): void {
    const newReportPreferences = this.getReportModes().reduce(
      (acc, mode) => ({
        ...acc,
        [mode]: {
          ...this.preferences.value[mode],
          dataItems: defaultDataItems[mode],
        },
      }),
      {} as ReportPreferences
    );
    if (decimalPoints !== null) {
      newReportPreferences[this.reportMode.value].decimalPoints = decimalPoints;
    }
    // reset filters and sort settings for any changes on data items
    if (updateDefaultFilters) {
      newReportPreferences[this.reportMode.value].filters = [];
    }
    if (updateDefaultSortSettings) {
      newReportPreferences[this.reportMode.value].sortSettings =
        this.hasMultipleSortSettings(this.reportMode.value)
          ? []
          : cloneDeep(DEFAULT_SORT_OPTIONS);
    }
    if (emitReportPreferences) {
      this.emitPreferences(newReportPreferences);
    }
  }

  public updateReportMode(mode: ReportMode, row?: Target) {
    this._affinityRow = row;
    this.reportMode.next(mode);

    if (this.shouldSortByAffinityRank()) {
      this.emitPreferences({
        ...this.preferences.value,
        [mode]: {
          ...this.preferences.value[mode],
          sortSettings: {
            ...cloneDeep(DEFAULT_SORT_OPTIONS),
            columnId: this.affinityRankColumnId,
            dataItem: DataItemType.affinityRank,
          },
        },
      });
    } else {
      this.emitActiveReportModePreference();
    }
  }

  public getActiveReportMode(): ReportMode {
    return this.reportMode.value;
  }

  public isInAffinityReportMode(): boolean {
    return this.getActiveReportMode() === ReportMode.affinity;
  }

  public setFirstColumnId(columnId: string): void {
    this.affinityRankColumnId = columnId;
  }

  public get affinityRow(): Target {
    return this._affinityRow;
  }

  public updateAffinityRow(rows: Target[]): void {
    if (!this._affinityRow) {
      return;
    }
    const row = rows.find(
      (target: Target) => target.id === this._affinityRow.id
    );
    if (row) {
      this._affinityRow = row;
    } else if (this.reportMode.value === ReportMode.affinity) {
      this.updateReportMode(ReportMode.crossTab, null);
    }
  }

  public getColumnHeaderFilters(columnId: string): ColumnFilter[] {
    return (
      this.preference.value.filters.find(
        (filter: ColumnHeaderFilter) => filter.columnId === columnId
      )?.filters || []
    );
  }

  public hasColumnHeaderFilters(): boolean {
    return this.preference.value.filters.length > 0;
  }

  public isCompleteCasesOn(): boolean {
    const preference = this.preferences.value[this.reportMode.value];
    return preference.completeCasesOn;
  }

  public hasSingleColumnFilter(): boolean {
    return (
      this.preference.value.filters.filter(
        (filter: ColumnHeaderFilter) => filter.filterSingleColumn
      ).length > 0
    );
  }

  public getColumnFilterMap(): string[] {
    return this.preference.value.filters.map(
      (filter: ColumnHeaderFilter) => filter.columnId.split('_')[0]
    );
  }

  public getSortSettings(): SortSettings | SortSettings[] {
    return this.preference.value.sortSettings;
  }

  public getSortColumnId(): string | null {
    const sortSettings = this.preference.value.sortSettings;
    return this.instanceOfSortSettings(sortSettings)
      ? sortSettings.columnId
      : null;
  }

  public instanceOfSortSettings(
    sortSettings: SortSettings | SortSettings[]
  ): sortSettings is SortSettings {
    return typeof sortSettings === 'object' && 'columnId' in sortSettings;
  }

  public isSortActive(): boolean {
    return !!this.getSortColumnId();
  }

  public sortColumn(sortSettings: SortSettings): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        sortSettings,
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public sortSeparateColumn(
    sortSettings?: SortSettings,
    columnIds?: string[]
  ): void {
    let updatedSortSettings: SortSettings[] = [];

    if (sortSettings && columnIds) {
      const separateRankSortSettings = this.preference.value
        .sortSettings as SortSettings[];

      if (columnIds.length === 1) {
        updatedSortSettings = [
          ...separateRankSortSettings.filter(
            (settings: SortSettings) => !(settings.columnId === columnIds[0])
          ),
          sortSettings,
        ];
      } else {
        updatedSortSettings = columnIds.map((id) => ({
          ...sortSettings,
          columnId: id,
        }));
      }
    }

    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        sortSettings: updatedSortSettings,
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public unsortColumn(): void {
    if (!this.getSortColumnId()) {
      return;
    }

    this.sortColumn(cloneDeep(DEFAULT_SORT_OPTIONS));
  }

  public unsetColumnSortAndFilters(columnTargetIds: string[]): void {
    const columnIdsToBeUnset = new Set(columnTargetIds);
    const newReportPreferences = this.getReportModes().reduce(
      (acc, mode) => ({
        ...acc,
        [mode]: {
          ...this.preferences.value[mode],
          filters: this.preferences.value[mode].filters.filter(
            (headerFilter: ColumnHeaderFilter) =>
              !columnIdsToBeUnset.has(headerFilter.columnId.split('_')[0])
          ),
          sortSettings: this.getUpdatedSortSettings(
            columnIdsToBeUnset,
            'targetId',
            mode
          ),
        },
      }),
      {} as ReportPreferences
    );
    this.emitPreferences(newReportPreferences);
  }

  public unsetColumnSortAndFiltersBySurveys(surveys: Set<Survey>): void {
    const newReportPreferences = this.getReportModes().reduce((acc, mode) => {
      const columnIdsToBeUnset = this.getSurveyColumnIds(surveys, mode);
      return {
        ...acc,
        [mode]: {
          ...this.preferences.value[mode],
          filters: this.preferences.value[mode].filters.filter(
            (headerFilter: ColumnHeaderFilter) =>
              !columnIdsToBeUnset.has(headerFilter.columnId)
          ),
          sortSettings: this.getUpdatedSortSettings(
            columnIdsToBeUnset,
            'columnId',
            mode
          ),
        },
      };
    }, {} as ReportPreferences);
    this.emitPreferences(newReportPreferences);
  }

  public addOrUpdateColumnHeaderFilters(
    columnId: string,
    columnTarget: Target | null,
    filters: ColumnFilter[],
    applyGlobally: boolean,
    filterSingleColumn?: boolean
  ): void {
    const hasColumFilters = filters.length > 0;
    if (hasColumFilters) {
      const columnFilters = cloneDeep(this.preference.value.filters);
      const columnHeaderFilters = columnFilters.find(
        (columnFilter: ColumnHeaderFilter) => columnFilter.columnId === columnId
      );
      if (columnHeaderFilters) {
        columnHeaderFilters.filters = filters;
        columnHeaderFilters.applyGlobally = applyGlobally;
      } else {
        columnFilters.push({
          columnId,
          columnTarget,
          filters,
          filterSingleColumn,
          applyGlobally,
        });
      }
      const newReportPreferences = {
        ...this.preferences.value,
        [this.reportMode.value]: {
          ...this.preferences.value[this.reportMode.value],
          filters: columnFilters,
        },
      };
      this.emitPreferences(newReportPreferences);
    } else {
      this.removeColumnHeaderFilters(columnId);
    }
  }

  public removeColumnHeaderFilters(columnId: string): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        filters: this.preferences.value[this.reportMode.value].filters.filter(
          (headerFilter: ColumnHeaderFilter) =>
            headerFilter.columnId !== columnId
        ),
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public resetColumnSortAndFiltersWhenChangingActiveSurvey(): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [ReportMode.separatedRank]: {
        ...this.preferences.value[ReportMode.separatedRank],
        filters: [],
        sortSettings: [],
      },
      [ReportMode.combinedRank]: {
        ...this.preferences.value[ReportMode.combinedRank],
        filters: [],
        sortSettings: cloneDeep(DEFAULT_SORT_OPTIONS),
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public resetAllColumnSortAndFilters(): void {
    const newReportPreferences = this.getReportModes().reduce(
      (acc, mode) => ({
        ...acc,
        [mode]: {
          ...this.preferences.value[mode],
          filters: [],
          sortSettings: this.hasMultipleSortSettings(mode)
            ? []
            : cloneDeep(DEFAULT_SORT_OPTIONS),
        },
      }),
      {} as ReportPreferences
    );
    this.emitPreferences(newReportPreferences);
  }

  public toggleFreezeTotals(type: TargetType): void {
    this.freezeTotals.next({
      ...this.freezeTotals.value,
      ...(type === TargetType.columns
        ? { column: !this.freezeTotals.value.column }
        : { row: !this.freezeTotals.value.row }),
    });
  }

  public getFreezeTotals(): FreezeTotals {
    return this.freezeTotals.value;
  }

  public updateHeatmapPercentageValue(
    cellStyleStatus: CellStyleStatus,
    heatmapValues: HeatmapValues
  ): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        heatMapValues: {
          heatmapIndexPercentage: heatmapValues.heatmapIndexPercentage,
          heatmapQuartiles: heatmapValues.heatmapQuartiles,
          heatmapQuintiles: heatmapValues.heatmapQuintiles,
          heatmap: heatmapValues.heatmap,
        },
        cellStyleStatus,
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public updateHighlightValues(highlightValues: HighlightValues): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        highlightValues,
        cellStyleStatus: CellStyleStatus.highlight,
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public updateCellStatus(cellStyleStatus: CellStyleStatus): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        cellStyleStatus,
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public updateStabilityFlagStatus(stabilityFlagOn: boolean): void {
    const newReportPreferences = {
      ...this.preferences.value,
      [this.reportMode.value]: {
        ...this.preferences.value[this.reportMode.value],
        stabilityFlagOn,
      },
    };
    this.emitPreferences(newReportPreferences);
  }

  public updateGlobalCompleteCasesStatus(completeCasesOn: boolean): void {
    const newReportPreferences = this.getReportModes().reduce(
      (acc, mode) => ({
        ...acc,
        [mode]: {
          ...this.preferences.value[mode],
          completeCasesOn,
        },
      }),
      {} as ReportPreferences
    );

    this.emitPreferences(newReportPreferences);
    this.completeCases.next(completeCasesOn);
  }

  public getDefaultPreferences(): ReportPreferences {
    return this.getReportModes().reduce(
      (acc, mode: ReportMode) => ({
        ...acc,
        [mode]: this.getDefaultReportModePreference(
          this.hasMultipleSortSettings(mode)
        ),
      }),
      {} as ReportPreferences
    );
  }

  private getDefaultReportModePreference(
    hasMultipleSortSettings: boolean
  ): ReportPreference {
    return {
      sortSettings: hasMultipleSortSettings
        ? []
        : cloneDeep(DEFAULT_SORT_OPTIONS),
      filters: [],
      heatMapValues: {
        heatmapIndexPercentage: DEFAULT_INDEX_PERCENTAGE,
        heatmapQuartiles: HEATMAP_QUARTILES,
        heatmapQuintiles: HEATMAP_QUINTILES,
        heatmap: HEATMAP_CUSTOM,
      },
      highlightValues: {
        chosenColor: HighlightColors[1],
        colors: [],
        dataItemId: DataItemType.none,
        type: ReportHighlightType.table,
      },
      cellStyleStatus: CellStyleStatus.none,
      stabilityFlagOn: true,
      dataItems: undefined,
      decimalPoints: -1,
      completeCasesOn: false,
      version: REPORT_PREFERENCE_VERSION,
    };
  }

  private hasMultipleSortSettings(mode: ReportMode): boolean {
    return mode === ReportMode.separatedRank;
  }

  private emitPreferences(preferences: ReportPreferences): void {
    this.preferences.next(preferences);
    this.emitActiveReportModePreference();
  }

  private emitActiveReportModePreference(): void {
    this.preference.next(this.preferences.value[this.reportMode.value]);
  }

  private resetFreezeTotals(): void {
    this.freezeTotals.next({
      column: false,
      row: false,
    });
  }

  private shouldSortByAffinityRank(): boolean {
    if (this.reportMode.value !== ReportMode.affinity) {
      return false;
    }

    const preference = this.preferences.value[this.reportMode.value];
    const sortSettings = preference.sortSettings as SortSettings;
    const isSortActive = !!sortSettings.columnId;
    if (isSortActive) {
      return false;
    }

    const dataItems = preference.dataItems;
    if (!dataItems) {
      return true;
    }

    const affinityRankDataItem = dataItems.find(
      (dataItem: DefaultDataItem) => dataItem.id === DataItemType.affinityRank
    );

    return !affinityRankDataItem || affinityRankDataItem.selected;
  }

  private getReportModes(): ReportMode[] {
    return [
      ReportMode.crossTab,
      ReportMode.affinity,
      ReportMode.separatedRank,
      ReportMode.combinedRank,
    ];
  }

  private getSurveyColumnIds(
    surveys: Set<Survey>,
    mode: ReportMode | string
  ): Set<string> {
    const columnIdsToBeUnset = new Set<string>();
    surveys.forEach((survey: Survey) => {
      const sortColumnId = this.preferences.value[mode].sortSettings.columnId;
      if (sortColumnId && sortColumnId.endsWith(`_${survey.code}`)) {
        columnIdsToBeUnset.add(sortColumnId);
      }
      this.preferences.value[mode].filters
        .filter((filter: ColumnHeaderFilter) =>
          filter.columnId.endsWith(`_${survey.code}`)
        )
        .forEach((filter: ColumnHeaderFilter) =>
          columnIdsToBeUnset.add(filter.columnId)
        );
    });
    return columnIdsToBeUnset;
  }

  private getUpdatedSortSettings(
    idsToBeUnset: Set<string>,
    type: 'columnId' | 'targetId',
    mode: ReportMode
  ): SortSettings | SortSettings[] {
    let updatedSortSettings;
    const sortSettings = this.preferences.value[mode].sortSettings;
    if (this.instanceOfSortSettings(sortSettings)) {
      updatedSortSettings = idsToBeUnset.has(
        this.getFormattedSortColumnId(
          sortSettings.columnId,
          type === 'targetId'
        )
      )
        ? cloneDeep(DEFAULT_SORT_OPTIONS)
        : sortSettings;
    } else {
      updatedSortSettings = sortSettings.filter(
        (settings: SortSettings) =>
          !idsToBeUnset.has(
            this.getFormattedSortColumnId(
              settings.columnId,
              type === 'targetId'
            )
          )
      );
    }
    return updatedSortSettings;
  }

  private getFormattedSortColumnId(
    columnId: string,
    isTargetId: boolean
  ): string {
    return isTargetId ? columnId.split('_')[0] : columnId;
  }

  private migrateHeatMapData(preferences: ReportPreferences) {
    Object.values(preferences).forEach((preference) => {
      if (
        preference?.['heatmapIndexPercentage'] &&
        !preference?.heatMapValues?.heatmapIndexPercentage
      ) {
        preference.heatMapValues = {
          heatmapIndexPercentage: preference['heatmapIndexPercentage'],
          heatmapQuartiles: HEATMAP_QUARTILES,
          heatmapQuintiles: HEATMAP_QUINTILES,
          heatmap: HEATMAP_CUSTOM,
        };
        delete preference['heatmapIndexPercentage'];
      }
      preference.version = REPORT_PREFERENCE_VERSION;
    });
    return preferences;
  }
}
