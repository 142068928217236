<div class="table-container">
  <scroll-top-top
    (scrollToTopClick)="onScrollToTopClicked()"
    [showScrollToTopBtn]="showScrollToTopBtn"
    dragBoundary=".table-container"
  ></scroll-top-top>
  <div
    infiniteScroll
    (scrolled)="loadMoreTable()"
    [scrollWindow]="false"
    class="separated-rank-report-container"
    #scrollContainer
  >
    <div
      class="single-report-container"
      *ngFor="
        let singleTargetTableData of separatedRankTableList;
        let iCol = index
      "
    >
      <app-single-target-table
        [surveyCodeMap]="surveyCodeMap"
        [singleTableData]="singleTargetTableData.tableData"
        [targetData]="singleTargetTableData.targetData"
        [displayedColumns]="displayedColumns"
        [dataItemMap]="dataItemMap"
        [decimalPointMap]="decimalPointMap"
        [reportUnits]="reportUnits"
        [stabilityFlagStatus]="stabilityFlagStatus"
        [cellStyleStatus]="cellStyleStatus"
        [highlightValues]="highlightValues"
        [zScoreHighlight]="zScoreHighlight"
        [cellColors]="cellColors"
        [sortSettings]="sortSettings[singleTargetTableData.columnId]"
        [heatmapIndexPercentage]="heatmapValues.heatmapIndexPercentage"
        [iCol]="iCol"
        [isViewActive]="isViewActive"
        [columnHeaderFilters]="columnHeaderFilters"
        (sortChange)="onSortChange($event)"
        (sortGlobalSettingChange)="onSortGlobalSettingChange($event)"
        (filterChange)="onFilterChange($event)"
        (removeFilter)="onRemoveFilter($event)"
      ></app-single-target-table>
    </div>
  </div>

  <div
    class="spinner-container-overlay"
    *ngIf="isLoading"
    data-qa="rank-loading"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>
