import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Statement } from 'src/app/models/codebook.model';
import {
  ContextMenuData,
  SelectionTreeFlatNode,
} from 'src/app/components/tree-view/tree.models';
import {
  CodebookSelectionService,
  DocumentService,
  LoadingSource,
  SnapshotService,
  TargetService,
  TrendingCalculationService,
} from '../../services';
import {
  CODEBOOK_EDITING_MODE_SIZE_IN_PIXEL,
  CODEBOOK_INITIAL_SIZE_IN_PIXEL,
  CODEBOOK_MIN_SIZE_IN_PIXEL,
  CodingGridSearchInput,
  CrosstabSearchInput,
  DEFAULT_WEIGHT_INDEX,
  DocumentDataState,
  DocumentViewType,
  EMPTY_TARGET_ITEM_INDEX,
  MAX_NUMBER_TREND_SURVEYS,
  MAX_SNAPSHOTS_LIMIT,
  Operator,
  Snapshot,
  Survey,
  SurveyTimeDocument,
  Target,
  TargetItem,
  TargetType,
  ViewType,
} from '../../models';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { SeparateOperatorAction } from '../../actions/SeparateOperatorAction';
import { delay, first, takeUntil } from 'rxjs/operators';
import { TupDocument } from '@telmar-global/tup-document-storage';
import {
  ChangeSurveysResult,
  DocumentAudienceGroup,
  SaveOwnCodesType,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { AddToMultipleAction } from '../../actions/AddToMultipleAction';
import { getAreaSize, IAreaSize } from 'ngx-split';
import { CrosstabViewComponent } from '../../components';
import { cloneDeep, omit } from 'lodash';
import {
  NtileDialogComponent,
  NTileDialogResult,
} from 'src/app/dialogs/ntile-dialog/ntile-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomAudiencesService } from '../../services/custom-audiences.service';
import { CodingDataMapService } from 'src/app/services/coding-data-map.service';
import { FindAndReplaceService } from 'src/app/services/find-and-replace.service';
import { CodebookAndVisualCodeBuilderPanelComponent } from 'src/app/panels/codebook-and-visual-code-builder-panel/codebook-and-visual-code-builder-panel.component';
import { TupAuthService, UserDetails } from '@telmar-global/tup-auth';

export interface DropDataContext<T> {
  selectedNodes: Statement[];
  selectedTargets?: Target[];
  context: T;
  handleDropNode: (context: T, selectedNodes: Statement[]) => void;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cross-tab-editor',
  templateUrl: './cross-tab-editor.component.html',
  styleUrls: ['./cross-tab-editor.component.scss'],
})
export class CrossTabEditorComponent implements OnInit, OnDestroy {
  public showExpandBtn = false;
  public splitSizes: Record<string, IAreaSize> = {
    codebook: CODEBOOK_INITIAL_SIZE_IN_PIXEL,
    maxSize: CODEBOOK_EDITING_MODE_SIZE_IN_PIXEL,
    table: '*',
  };
  public targetType: typeof TargetType = TargetType;
  public viewType: typeof ViewType = ViewType;
  public isReadonly = true;
  public selectedTab: ViewType = this.viewType.crossTab;
  public surveys: Survey[];
  public visibleSurveys: Survey[];
  public selectedSurvey: Survey;
  public hiddenSurveys: Set<Survey> = new Set<Survey>();
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;

  // contextual menu (contextMenuPosition is shared by both menu)
  contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('codebookMenuTrigger') private codebookContextMenu: MatMenuTrigger;

  @ViewChild('codebookVisualCodeBuilderPanel', { static: false })
  private codebookVisualCodeBuilderPanel: CodebookAndVisualCodeBuilderPanelComponent;

  public maxNumberTrendSurveys = MAX_NUMBER_TREND_SURVEYS;

  @ViewChild(CrosstabViewComponent, { static: false })
  crosstabViewComponent: CrosstabViewComponent;

  private nTileDialog: MatDialogRef<NtileDialogComponent>;

  private unsubscribe: Subject<void> = new Subject<void>();

  public isCodebookSearchingOrFiltering = false;
  public isEditingCodeMode = false;
  private clickedTarget: TargetItem;
  private currentDoc: TupDocument<SurveyTimeDocument>;
  public user: UserDetails;

  constructor(
    private documentService: DocumentService,
    private codingDataMapService: CodingDataMapService,
    private targetService: TargetService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private codebookSelectionService: CodebookSelectionService,
    private separateOperatorAction: SeparateOperatorAction,
    private dialog: DialogService,
    private audienceGroupsService: TupAudienceGroupsService,
    private trendingCalculationService: TrendingCalculationService,
    private addToMultipleAction: AddToMultipleAction,
    private messageService: TupUserMessageService,
    private customAudiencesService: CustomAudiencesService,
    private snapshotService: SnapshotService,
    private authService: TupAuthService,
    private findAndReplaceService: FindAndReplaceService
  ) {
    this.isReadonly =
      this.router.getCurrentNavigation().extras?.state?.isReadonly;
    this.selectedTab = this.activatedRoute.snapshot.queryParams?.tab;
  }

  ngOnInit() {
    this.listenToUrlQueryChanges();
    this.listenToDocumentDataChanges();
    this.listenToSelectedNodes();
    this.listenToActiveSurveyChanges();
    this.user = this.authService.user;
  }

  public onDragEnd(event): void {
    this.splitSizes.codebook = getAreaSize(event.sizes[0]);
    this.splitSizes.table = getAreaSize(event.sizes[1]);

    this.showExpandBtn = event.sizes[0] < CODEBOOK_MIN_SIZE_IN_PIXEL;
  }

  public onExpandCodebook(): void {
    this.splitSizes.codebook = this.isEditingCodeMode
      ? CODEBOOK_EDITING_MODE_SIZE_IN_PIXEL
      : CODEBOOK_INITIAL_SIZE_IN_PIXEL;
    this.splitSizes.table = '*';
    this.showExpandBtn = false;
  }

  public onTabChange(tab: MatTabChangeEvent): void {
    this.selectedTab = tab.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        tab: this.selectedTab,
      },
    });
    this.codebookVisualCodeBuilderPanel.closeCodeBuilder();
  }

  public onDropNode(event: DropDataContext<any>) {
    const selectedNodes = event.selectedNodes;
    if (selectedNodes) {
      this.codebookSelectionService.setLoadingChildrenNodes(
        true,
        LoadingSource.fromDrag
      );
      this.codebookVisualCodeBuilderPanel
        .loadChildren(selectedNodes)
        .then((result) => {
          this.codebookSelectionService.setLoadingChildrenNodes(false);
          this.codebookSelectionService.setSelectedNodes(result);
          event.handleDropNode(event.context, result);
        });
    }
  }

  public onCodebookContextMenu(data: ContextMenuData): void {
    this.contextMenuPosition.x = data.event.clientX + 'px';
    this.contextMenuPosition.y = data.event.clientY + 'px';
    this.codebookContextMenu.menuData = {
      node: data.node,
      path: data.path,
      level: data.level,
      selectedNodes: data.selectedNodes,
      selectionTreeNode: data.selectionTreeNode,
    };
    this.codebookContextMenu.openMenu();
  }

  public onCodebookSearchingOrFilteringStateChange(state: boolean): void {
    this.isCodebookSearchingOrFiltering = state;
  }

  ngOnDestroy(): void {
    this.unsubscribeLoadingSubscription();
  }

  public onControlClick(type: TargetType): void {
    const selectedNodes =
      this.codebookVisualCodeBuilderPanel.getSelectedNodes();
    if (!selectedNodes || selectedNodes.length < 1) {
      return;
    }

    this.addSelectedNodesToDocument(
      selectedNodes,
      type,
      LoadingSource.fromButton
    );
  }

  public onTargetClick(targetClick: TargetItem): void {
    this.clickedTarget = targetClick;
    this.expandVisualCodeBuilder(targetClick.target);
  }

  public expandVisualCodeBuilder(selectedTarget: Target): void {
    this.splitSizes.codebook = CODEBOOK_EDITING_MODE_SIZE_IN_PIXEL;
    this.isEditingCodeMode = true;
    this.codebookVisualCodeBuilderPanel.openCodeBuilder(selectedTarget);
  }

  public onVisualCodeBuilderClose(): void {
    this.clickedTarget = null;
    this.isEditingCodeMode = false;
    this.splitSizes.codebook = CODEBOOK_INITIAL_SIZE_IN_PIXEL;
  }

  public onApplyCodingChanges(target: Target): void {
    if (this.clickedTarget.index !== EMPTY_TARGET_ITEM_INDEX) {
      this.documentService.updateDocumentData([
        {
          ...this.clickedTarget,
          target,
        },
      ]);
    } else {
      this.documentService.addDocumentData(
        [target],
        this.clickedTarget.type,
        true
      );
    }
  }

  public onNtileClick(targetClick: TargetItem): void {
    this.nTileDialog = this.dialog.nTileSettings(
      targetClick.target,
      targetClick.type
    );

    this.nTileDialog
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: NTileDialogResult) => {
        const targets = this.targetService.createNTileTargets(
          result.target,
          result.nTileBuckets,
          result.nTileFunction
        );
        const createdTargets: Target[] = [];
        targets.forEach((target) => {
          const createdTarget = this.targetService.groupTargets(
            [target],
            Operator.ntiles,
            true
          );
          createdTargets.push(...createdTarget);
        });

        if (targetClick.type === result.targetType) {
          const insertIndex =
            this.documentService.document.content[
              this.documentService.getTargetKey(targetClick.type)
            ].findIndex((row: Target) => row.id === targetClick.target.id) + 1;

          this.documentService.addDocumentData(
            createdTargets,
            result.targetType,
            true,
            insertIndex
          );
        } else {
          this.documentService.addDocumentData(
            createdTargets,
            result.targetType,
            true
          );
        }
      });
  }

  public onNewCodeClick(targetType: TargetType): void {
    this.dialog
      .newCodeBuilder(
        targetType,
        this.documentService,
        this.targetService,
        this.codingDataMapService,
        this.documentService.activeSurvey
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: Target) => {
        this.documentService.addDocumentData([result], targetType, true);
      });
  }

  public onFindAndReplaceClick(
    data: CodingGridSearchInput | CrosstabSearchInput
  ): void {
    this.dialog
      .findAndReplace(data)
      .afterClosed()
      .subscribe((_) => {
        this.findAndReplaceService.updateDialogState(false);
        this.findAndReplaceService.updateSearchHits([]);
      });
  }

  public onOpenTrendingCalculation(): void {
    const previousTrendingCalculations = cloneDeep(
      this.trendingCalculationService.getTrendingCalculations()
    );
    this.dialog
      .trendingCalc(this.visibleSurveys, this.trendingCalculationService)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.documentService.updateTrendingCalculation(result.trendings);
        } else {
          this.trendingCalculationService.reset(previousTrendingCalculations);
        }
      });
  }

  public onTopBarOpen(): void {
    this.codebookVisualCodeBuilderPanel?.closeCodeBuilder();
  }

  public onCreateSnapshot(): void {
    if (
      this.currentDoc.content.snapshots &&
      this.currentDoc.content.snapshots.length >= MAX_SNAPSHOTS_LIMIT
    ) {
      const snapshots = cloneDeep(this.currentDoc.content.snapshots);
      this.showSnapshotLimitAlert(snapshots);
    } else {
      this.snapshotService.createSnapshot(this.currentDoc);
    }
  }

  private showSnapshotLimitAlert(snapshots: Snapshot[]): void {
    this.dialog
      .showSnapshotLimit()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result) => {
        if (result) {
          const sortedSnapshots = snapshots.reverse();
          const containerName = this.currentDoc.metadata.container.name;
          this.dialog
            .manageSnapshots(sortedSnapshots, containerName, this.currentDoc)
            .afterClosed()
            .subscribe(() => {});
        }
      });
  }

  private listenToSelectedNodes(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        if (selectedNodes.length < 1) {
          this.codebookVisualCodeBuilderPanel?.unselectAllNodes();
        }
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.surveys = doc.content.surveys;
        this.codebookVisualCodeBuilderPanel?.closeCodeBuilder();
        this.currentDoc = doc;
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
        if (readonly) {
          this.splitSizes.codebook = 0;
          this.showExpandBtn = true;
        }
      });
    this.documentService.restoreDocumentState$
      .pipe(takeUntil(this.unsubscribe), delay(0))
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.updateSurveys();
      });
  }

  private updateSurveys(): void {
    this.surveys = this.documentService.surveys;
    this.selectedSurvey = this.documentService.activeSurvey;
    this.visibleSurveys = this.documentService.getVisibleSurveys();
  }

  private listenToUrlQueryChanges(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((query) => {
        this.selectedTab = query.tab;
        this.codebookVisualCodeBuilderPanel?.closeCodeBuilder();
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.selectedSurvey = survey;
        this.visibleSurveys = this.documentService.getVisibleSurveys();
        this.hiddenSurveys = this.documentService.getHiddenSurveys();
      });
  }

  public onChangeSurveysClicked(): void {
    const surveysWithoutCustomCodes = this.documentService.surveys.map(
      (survey) => ({
        ...survey,
        customCode: undefined,
      })
    );
    this.audienceGroupsService
      .surveySelector(
        surveysWithoutCustomCodes,
        null,
        null,
        null,
        MAX_NUMBER_TREND_SURVEYS
      )
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((result: ChangeSurveysResult) => {
        const selectedSurvey = this.removeSurveysSearchInfo(result.surveys);
        this.documentService.setTempDocumentState();
        this.resetHiddenSurveys();
        this.documentService.initDocument(
          selectedSurvey,
          !result.clearExisting,
          true,
          true,
          true
        );
        this.updateTrendingCalculation(result.clearExisting, selectedSurvey);
        this.surveys = this.documentService.surveys;
      });
  }

  public onSelectedSurveyChanged(survey: Survey): void {
    this.documentService.activeSurvey = survey;
    this.documentService.document.content.weight = DEFAULT_WEIGHT_INDEX;

    this.codebookVisualCodeBuilderPanel.closeCodeBuilder();
  }

  public toggleSurveyVisibility(survey: Survey): void {
    if (!this.hiddenSurveys.has(survey)) {
      this.hiddenSurveys.add(survey);
      this.trendingCalculationService.removeInvalidCalculation(
        this.surveys,
        survey
      );
    } else {
      this.hiddenSurveys.delete(survey);
    }
    this.notifyHiddenSurveysChanged(true);
    this.visibleSurveys = this.documentService.getVisibleSurveys();
  }

  public addToRows(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.rows,
      LoadingSource.fromMenu
    );
  }

  public addToColumns(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.columns,
      LoadingSource.fromMenu
    );
  }

  public addToTable(nodes: Statement[]) {
    this.addSelectedNodesToDocument(
      nodes,
      TargetType.tables,
      LoadingSource.fromMenu
    );
  }

  public expandAllCodebook(node: Statement) {
    this.codebookVisualCodeBuilderPanel.codebookFetchAndExpandNodes([node]);
  }

  public updateCustomAudiencesNodeContainer(node: Statement): void {
    this.audienceGroupsService.updateOwnCodesContainer(node.document).subscribe(
      () => {
        this.customAudiencesService.notifyOwnCodesContainerUpdated(
          SaveOwnCodesType.audience
        );
      },
      () => {}
    );
  }

  public editCustomAudiences(node: Statement): void {
    this.customAudiencesService.editCustomAudiences(node.document).subscribe(
      () => {
        this.customAudiencesService.notifyOwnCodesAdded(
          SaveOwnCodesType.audience,
          node.document
        );
        this.messageService.showSnackBar(
          'Custom audiences/media saved successfully',
          'OK',
          10000
        );
      },
      (error) => {
        console.log(`error`, error);
        this.messageService.showSnackBar(error, 'OK', 10000);
      }
    );
  }

  public deleteCustomAudiencesNode(node: Statement): void {
    this.audienceGroupsService
      .deleteOwnCodesWithConfirmation(node.document)
      .subscribe(() => {
        if (this.isCodebookSearchingOrFiltering) {
          this.codebookVisualCodeBuilderPanel.deleteCustomAudienceOrMediaNode(
            node
          );
        } else {
          this.customAudiencesService.notifyOwnCodesContainerUpdated(
            SaveOwnCodesType.audience
          );
        }
      });
  }

  public renameCustomAudiencesNode(node: SelectionTreeFlatNode): void {
    this.audienceGroupsService
      .renameOwnCodesDocument(node.data.document)
      .subscribe((document: TupDocument<DocumentAudienceGroup>) => {
        this.messageService.showSnackBar(
          'Custom audiences/media updated successfully',
          'OK',
          10000
        );
        const paths = node.data.path.split('|');
        paths.pop();
        this.codebookVisualCodeBuilderPanel.updateCustomAudienceOrMediaNode(
          node,
          {
            description: document.metadata.name,
            path: paths.join('|') + '|' + document.metadata.name,
            document,
          }
        );
      });
  }

  public refreshCustomNodes(node: SelectionTreeFlatNode): void {
    this.codebookVisualCodeBuilderPanel.onCodebookLoadData(node);
  }

  public sort(node: SelectionTreeFlatNode, sort: string): void {
    this.codebookVisualCodeBuilderPanel.sortCustomAudienceOrMediaNode(
      node,
      sort
    );
  }

  public editCustomNodeTitle(node: SelectionTreeFlatNode): void {
    this.audienceGroupsService
      .editOwnCodesTargetTitle(
        node.data.customData.document,
        node.data.customData.index
      )
      .subscribe((document: TupDocument<DocumentAudienceGroup>) => {
        this.messageService.showSnackBar(
          'Custom audiences/media updated successfully',
          'OK',
          10000
        );
        const audienceGroupItem =
          document.content.targets[node.data.customData.index];
        const paths = node.data.path.split('|');
        paths.pop();
        this.codebookVisualCodeBuilderPanel.updateCustomAudienceOrMediaNode(
          node,
          {
            description: audienceGroupItem.title,
            path: paths.join('|') + '|' + audienceGroupItem.title,
            customData: {
              index: node.data.customData.index,
              target: audienceGroupItem.options.target,
              document,
            },
          }
        );
      });
  }

  public addToMultiple(nodes: Statement[], type: TargetType): void {
    this.codebookSelectionService.setLoadingChildrenNodes(
      true,
      LoadingSource.fromMenu
    );

    this.codebookVisualCodeBuilderPanel.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.documentService.documentState$
        .pipe(first(), takeUntil(this.unsubscribe))
        .subscribe(({ columns, rows, tables }: DocumentDataState) => {
          this.addToMultipleAction.invoke({
            targetType: type,
            selectedTargets:
              this.targetService.convertStatementsToTargets(result),
            selectableTargets:
              type === TargetType.tables
                ? tables
                : type === TargetType.columns
                ? columns
                : rows,
          });
        });
    });
  }

  private notifyHiddenSurveysChanged(
    shouldRefreshCrossTab: boolean = true
  ): void {
    this.documentService.setHiddenSurveys(
      this.hiddenSurveys,
      shouldRefreshCrossTab
    );
  }

  private removeSurveysSearchInfo(surveys: Survey[]): Survey[] {
    return surveys.map((survey: Survey) => omit(survey, 'occurrenceCount'));
  }

  private updateTrendingCalculation(
    clearExisting: boolean,
    surveys: Survey[]
  ): void {
    if (clearExisting) {
      this.trendingCalculationService.reset();
    } else {
      this.trendingCalculationService.removeInvalidCalculation(surveys);
    }
    this.documentService.updateTrendingCalculation(
      this.trendingCalculationService.getCodings()
    );
  }

  private resetHiddenSurveys(): void {
    this.hiddenSurveys.clear();
    this.notifyHiddenSurveysChanged(false);
  }

  private unsubscribeLoadingSubscription(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private addSelectedNodesToDocument(
    nodes: Statement[],
    type: TargetType,
    origin: LoadingSource
  ): void {
    this.codebookSelectionService.setLoadingChildrenNodes(true, origin);
    this.codebookVisualCodeBuilderPanel.loadChildren(nodes).then((result) => {
      this.codebookSelectionService.setLoadingChildrenNodes(false);
      this.separateOperatorAction.invoke({
        targetType: type,
        selectedTargets: this.targetService.convertStatementsToTargets(result),
      });
    });
  }

  public exportToXlsx(exportParams?: any): void {
    exportParams
      ? this.crosstabViewComponent.exportToXlsx(
          exportParams.docName,
          exportParams.exportTablebases
        )
      : this.crosstabViewComponent.exportToXlsx();
  }

  public exportToSheets(exportParams?: any): void {
    exportParams
      ? this.crosstabViewComponent.exportToSheets(
          exportParams.docName,
          exportParams.exportTablebases
        )
      : this.crosstabViewComponent.exportToSheets();
  }

  public exportToCsv(exportParams?: any): void {
    exportParams
      ? this.crosstabViewComponent.exportToCsv(
          exportParams.docName,
          exportParams.exportTablebases
        )
      : this.crosstabViewComponent.exportToCsv();
  }
}
