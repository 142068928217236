<h2 mat-dialog-title>Save chart style</h2>

<div
  mat-dialog-content
  class="save-as-content"
>
  <div
    fxLayout="row"
    fxLayoutGap="20px"
  >
    <div
      fxLayout="column"
      fxFlex="50"
      fxLayoutAlign="start start"
    >
      <form
        [formGroup]="formGroup"
        class="save-as-form-field"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFill
            class="display-text-field"
          >
            <mat-label>Chart style name</mat-label>
            <input
              type="text"
              required
              matInput
              formControlName="name"
            />
            <mat-error
              *ngIf="formGroup.get('name').errors?.required"
              class="save-as__name-error"
              >Please enter name</mat-error
            >
          </mat-form-field>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="save-as-form-field"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFill
            class="display-text-field"
          >
            <mat-label>Chart style description</mat-label>
            <textarea
              matInput
              formControlName="description"
              class="display-text-field-textarea"
            ></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div
      fxLayout="column"
      fxFlex="50"
    >
      <tup-user-container-selector
        #userContainerSelector
        [options]="{ showSubheader: true, showDivider: true }"
        (selectionChange)="selectionChange($event)"
      >
      </tup-user-container-selector>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
  >
    <mat-checkbox
      tupMatCheckbox
      appearance="small"
      color="primary"
      [(ngModel)]="defaultChartStyle"
      >Save as default</mat-checkbox
    >
    <mat-icon
      matTooltip="Applies default style to new charts and charts with no customised changes"
      class="material-icons-outlined"
      >info</mat-icon
    >
  </div>
</div>

<div mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="cancel()"
  >
    Cancel
  </button>

  <button
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    cdkFocusRegionstart
    (click)="saveAs()"
    [disabled]="disabled"
  >
    OK
  </button>
</div>
