import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SnapshotLimitAlertDialogDataModel {
  maxLimitNumber: number;
}

@Component({
  templateUrl: './snapshot-limit-alert-dialog.component.html',
})
export class SnapshotLimitAlertDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SnapshotLimitAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SnapshotLimitAlertDialogDataModel
  ) {}

  public ngOnInit(): void {}

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }
}
