<ng-template #loading>
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-spinner
      class="results-spinner"
      diameter="30"
    ></mat-spinner>
  </div>
</ng-template>

<div class="mat-tree-container">
  <div
    class="mat-tree-scrollview"
    fxFlex
  >
    <mat-tree
      *ngIf="!searching; else loading"
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      ctrlShift
      (ctrlShift)="onCtrlShift($event)"
    >
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
        [matTreeNodePaddingIndent]="treeNodePadding"
        [dndDraggable]="node"
        [dndDisableDragIf]="isLoadingNodes"
        [dndEffectAllowed]="'copy'"
        (dndStart)="onDragStart($event)"
        (dndEnd)="onDragEnd()"
        [class.is-bold-node]="node.data.shouldHighlight"
        [class.is-survey-response-100]="isSurveyResponseRate100(node)"
      >
        <button
          mat-icon-button
          disabled
        ></button>
        <mat-checkbox
          *ngIf="!isReadonly"
          tupMatCheckbox
          appearance="small"
          color="primary"
          [checked]="node.data.selected === true && hasCompatibleWeights(node)"
          [disabled]="!hasCompatibleWeights(node)"
          (change)="treeNodeSelectionToggle(node)"
          (contextmenu)="onContextMenu($event, node)"
          (mousedown)="onMouseDown(node)"
          [matTooltip]="getToolTipMessages(node)"
          matTooltipClass="multiple-tooltips"
          [title]="node.description"
        >
          {{ node.description }}
        </mat-checkbox>
        <ng-template [ngIf]="isReadonly"
          ><span
            class="readonly-node"
            [title]="node.description"
            >{{ node.description }}</span
          ></ng-template
        >
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef="let node; when: isHidden"
        matTreeNodePadding
        [matTreeNodePaddingIndent]="treeNodePadding"
        style="display: none"
      >
      </mat-tree-node>
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        [matTreeNodePaddingIndent]="treeNodePadding"
        (contextmenu)="onContextMenu($event, node)"
        [dndDraggable]="node"
        [dndDisableDragIf]="isLoadingNodes"
        [dndEffectAllowed]="'copy'"
        (dndStart)="onDragStart($event)"
        (dndEnd)="onDragEnd()"
        [class.is-bold-node]="node.data.shouldHighlight"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.filename"
        >
          <mat-icon
            class="mat-icon-rtl-mirror"
            matTooltipPosition="left"
          >
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
        <mat-checkbox
          *ngIf="!isReadonly"
          [checked]="descendantsAllSelected(node)"
          [class.data-loading]="node.isLoading"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="treeNodeSelectionToggle(node)"
          (mousedown)="onMouseDown(node)"
          tupMatCheckbox
          appearance="small"
          color="primary"
          [title]="node.description"
        >
          <mat-spinner
            *ngIf="node.isLoading"
            diameter="15"
            mode="indeterminate"
            class="tree-spinner"
          ></mat-spinner>
          {{ node.description }}
        </mat-checkbox>

        <ng-template [ngIf]="isReadonly">
          <mat-spinner
            *ngIf="node.isLoading"
            diameter="15"
            mode="indeterminate"
            class="tree-spinner"
            title="node.description"
          ></mat-spinner>
          <span class="readonly-node">{{ node.description }}</span>
        </ng-template>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>

<div
  #dndDragImage
  class="dnd-image"
>
  <span
    *ngFor="let item of draggedItems"
    class="dnd-image-item"
    >{{ item }}</span
  >
  <span
    *ngIf="draggedItemNumber > 3"
    class="dnd-image-item-count"
  >
    ... {{ draggedItemNumber }} items selected
  </span>
</div>
