import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import {
  FactorAnalysisSettings,
  FATableHighlightOption,
  FAVariableOptionType,
} from 'src/app/models';

export interface FactorAnalysisSettingsDialogModel {
  settings: FactorAnalysisSettings;
}

@Component({
  selector: 'app-factor-analysis-settings-dialog',
  templateUrl: './factor-analysis-settings-dialog.component.html',
  styleUrls: ['./factor-analysis-settings-dialog.component.scss'],
})
export class FactorAnalysisSettingsDialogComponent implements OnInit {
  public readonly tableHiglightColors: string[] = Object.values(
    FATableHighlightOption
  );
  public readonly faTableHighlightOption: typeof FATableHighlightOption =
    FATableHighlightOption;
  public settings: FactorAnalysisSettings;
  public variableReductionThreshold: number;

  constructor(
    public dialogRef: MatDialogRef<FactorAnalysisSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FactorAnalysisSettingsDialogModel
  ) {
    this.settings = cloneDeep(data.settings);
    this.setVariableReductionThreshold();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public setVariableReductionThreshold() {
    this.variableReductionThreshold = 0.4;

    const variableOption = this.settings.variableOptions.filter(
      (variableOption) =>
        variableOption.option === FAVariableOptionType.removeCollinearVariables
    );

    if (variableOption.length > 0) {
      this.variableReductionThreshold = Number(variableOption[0].threshold);
    }
  }

  public onButtonClick(): void {
    if (this.settings.isEliminateNegligibleCorrelations) {
      const variableOption = this.settings.variableOptions.filter(
        (variableOption) =>
          variableOption.option ===
          FAVariableOptionType.removeCollinearVariables
      );

      if (variableOption.length > 0) {
        variableOption[0].threshold = Number(this.variableReductionThreshold);
      }
    }

    this.dialogRef.close(this.settings);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }
}
