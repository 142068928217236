import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentStorageOptions,
  TupDocumentTypeId,
  getColumn,
} from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { ChartStylesEditableCellComponent } from 'src/app/components/chart-styles-editable-cell/chart-styles-editable-cell.component';
import {
  APPLICATION_NAME,
  ChartTargetMode,
  DocumentChartStyle,
  IGNORED_SINGLE_TARGET_GRAPH_TYPES,
  MULTIPLE_SURVEY_CHART_TYPES,
} from 'src/app/models';
import { environment } from 'src/environments/environment';

export interface ManageChartStyleDialogData {
  chartTargetMode: ChartTargetMode;
}
@Component({
  selector: 'app-manage-chart-styles-dialog',
  templateUrl: './manage-chart-styles-dialog.component.html',
  styleUrls: ['./manage-chart-styles-dialog.component.scss'],
})
export class ManageChartStylesDialogComponent implements OnInit {
  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  public readonly options: TupDocumentStorageOptions = {
    types: [TupDocumentTypeId.SURVEYTIME_CHART_STYLE_TEMPLATE],
    documentReplacementText: 'Chart styles',

    columns: [
      {
        columnDef: 'editable.indicator',
        header: '',
        columnType: 'component',
        component: ChartStylesEditableCellComponent,
      },
      getColumn(environment, 'actions'),
      getColumn(environment, 'metadata.name.keyword', {
        header: 'Name',
        sortable: true,
        tooltip: (document: TupDocument<unknown>) => document.metadata.name,
      }),
      getColumn(environment, 'metadata.description', {
        header: 'Description',
        sortable: false,
        tooltip: (document: TupDocument<unknown>) =>
          document.metadata.description,
      }),
      getColumn(environment, 'metadata.by.attributes.name.keyword', {
        sortable: true,
      }),
      getColumn(environment, 'metadata.modified', { sortable: true }),
      {
        columnDef: 'content.chartStyleSettings?.primaryChartType',
        sortable: false,
        header: 'Chart type',
        columnType: 'string',
        cell: (document: TupDocument<any>) =>
          document.content.chartStyleSettings?.primaryChartType
            ? MULTIPLE_SURVEY_CHART_TYPES.filter(
                (chart) =>
                  chart.value ===
                  document.content.chartStyleSettings?.primaryChartType
              )[0]?.title || ''
            : '',
      },
    ],
    panel: true,
    pageSize: 8,
    _source: ['metadata', 'content'],
    actions: [
      'click:delete:highlight_off',
      'click:move_to:drive_file_move:Move to',
      'click:rename:abc',
    ],
  } as TupDocumentStorageOptions;

  public chartTargetMode: ChartTargetMode;

  constructor(
    public dialogRef: MatDialogRef<ManageChartStylesDialogComponent>,
    private userMessageService: TupUserMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.chartTargetMode = data.chartTargetMode;
  }

  public ngOnInit(): void {
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      const document = event.document as TupDocument<DocumentChartStyle>;
      const primaryChartType =
        document.content.chartStyleSettings.primaryChartType;

      if (!event?.data) {
        if (
          this.chartTargetMode === ChartTargetMode.single &&
          IGNORED_SINGLE_TARGET_GRAPH_TYPES.includes(primaryChartType)
        ) {
          this.showUnableToApplyChartStyleWarning();
        } else {
          this.dialogRef.close(document.content.chartStyleSettings);
        }
      }
    });
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public showUnableToApplyChartStyleWarning(): void {
    this.userMessageService.openMessageDialog(
      'Sorry! You cannot apply this style since the chart type is incompatible with Single target mode.',
      APPLICATION_NAME
    );
  }
}
