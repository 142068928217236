import { Injectable } from '@angular/core';
import { DocumentService } from './document.service';
import {
  CHART_COLORS,
  CHART_TYPES,
  ChartFilter,
  ChartSettings,
  ChartTargetMode,
  DataViewMode,
  DEFAULT_CHART_SETTINGS,
  GLOBAL_SETTINGS_KEY,
  GLOBAL_SETTINGS_KEY_PREFIX,
  GlobalChartSettings,
} from '../models';
import { cloneDeep } from 'lodash';
import { DataItem, DataItemId, DataItemType, Survey, Target } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChartSettingsService {
  constructor(private documentService: DocumentService) {}

  public saveGlobalChartSettings(
    chartSettings: ChartSettings[],
    globalChartSettings: GlobalChartSettings,
    shouldUpdateGlobalSettings: boolean = false
  ): void {
    this.documentService.setTempDocumentState();
    chartSettings.forEach((settings) => {
      if (shouldUpdateGlobalSettings || !this.isGlobalChartSettings(settings)) {
        const newSettings = {
          ...settings,
          ...Object.keys(globalChartSettings).reduce(
            (acc, key) => ({
              ...acc,
              ...(globalChartSettings[key] !== ''
                ? { [key]: globalChartSettings[key] }
                : {}),
            }),
            {}
          ),
        };
        newSettings.extraTableSettings = [
          newSettings.primaryDataItem,
          newSettings.secondaryDataItem,
        ].filter((dataItem) => dataItem !== DataItemType.none);
        this.saveChartSettings(newSettings);
      }
    });
    this.documentService.setRestoreDocumentStateFromTempDocumentState();
  }

  public saveGlobalChartFilters(
    chartSettings: ChartSettings[],
    chartFilters: ChartFilter[]
  ): void {
    chartSettings.forEach((settings: ChartSettings) => {
      const newSettings = {
        ...settings,
        filters: chartFilters,
      };
      this.saveChartSettings(newSettings);
    });
  }

  public saveChartSettings(settings: ChartSettings): void {
    if (this.isGlobalChartSettings(settings)) {
      this.documentService.resetAllChartSettings(
        settings.targetMode,
        settings.dataViewMode,
        false
      );
    } else {
      this.updateGlobalSeriesColors(settings);
    }

    if (this.isNewChartSettings(settings)) {
      this.documentService.addChartSettings(settings);
    } else {
      this.documentService.updateChartSettings(settings);
    }
  }

  public getGlobalChartSettings(
    surveys: Survey[],
    dataItemKeys: Record<DataItemId, DataItem>,
    targetMode: ChartTargetMode,
    targets: Target[],
    dataViewMode: DataViewMode = DataViewMode.default
  ): ChartSettings {
    let globalChartSettings = this.documentService.findChartSettings(
      targetMode,
      GLOBAL_SETTINGS_KEY,
      GLOBAL_SETTINGS_KEY,
      dataViewMode
    );
    if (!globalChartSettings) {
      globalChartSettings = cloneDeep(DEFAULT_CHART_SETTINGS);
      globalChartSettings.targetMode = targetMode;
      globalChartSettings.dataViewMode = dataViewMode;
      globalChartSettings.seriesColor = this.getChartSeriesColours(
        targets,
        targetMode,
        dataViewMode
      );
      this.documentService.addChartSettings(globalChartSettings, true);

      const defaultPrimaryDataItem =
        DataItemType.index in dataItemKeys
          ? DataItemType.index
          : DataItemType.filterIndex in dataItemKeys
          ? DataItemType.filterIndex
          : Number(Object.keys(dataItemKeys)[0]);
      globalChartSettings.primaryDataItem = defaultPrimaryDataItem;
      globalChartSettings.extraTableSettings = [defaultPrimaryDataItem];
      if (surveys.length > 1) {
        globalChartSettings.primaryChartType = CHART_TYPES[7].value;
      }
    }

    return globalChartSettings;
  }

  public getChartSeriesColours(
    targets: Target[],
    targetMode: ChartTargetMode,
    viewType: DataViewMode = DataViewMode.default
  ): Record<string, string> {
    if (targetMode === ChartTargetMode.combined) {
      return targets.reduce(
        (acc, target: Target, index: number) => ({
          ...acc,
          [target.id]:
            CHART_COLORS[
              index >= CHART_COLORS.length ? index % CHART_COLORS.length : index
            ],
        }),
        {}
      );
    } else {
      return viewType === DataViewMode.default
        ? targets.reduce(
            (acc, target: Target, index: number) => ({
              ...acc,
              [target.id]:
                CHART_COLORS[
                  index >= CHART_COLORS.length
                    ? index % CHART_COLORS.length
                    : index
                ],
            }),
            {}
          )
        : {
            dynamic: CHART_COLORS[0],
          };
    }
  }

  public getChartSettings(
    targetMode: ChartTargetMode,
    dataViewMode: DataViewMode,
    shouldIgnoreGlobalSettings: boolean = false
  ): ChartSettings[] {
    const settings = this.documentService
      .getAllChartSettings(targetMode, dataViewMode)
      .filter(
        (settingItem) => !this.isDeprecatedGlobalChartSettings(settingItem)
      );
    return !shouldIgnoreGlobalSettings
      ? settings
      : settings.filter(
          (settingItem) => !this.isGlobalChartSettings(settingItem)
        );
  }

  public isGlobalChartSettings(settings: ChartSettings): boolean {
    return (
      settings.associatedInsight === GLOBAL_SETTINGS_KEY &&
      settings.associatedTarget === GLOBAL_SETTINGS_KEY
    );
  }

  private isDeprecatedGlobalChartSettings(settings: ChartSettings): boolean {
    return (
      settings.associatedInsight.startsWith(GLOBAL_SETTINGS_KEY_PREFIX) &&
      settings.associatedInsight !== GLOBAL_SETTINGS_KEY &&
      settings.associatedTarget.startsWith(GLOBAL_SETTINGS_KEY_PREFIX) &&
      settings.associatedTarget !== GLOBAL_SETTINGS_KEY
    );
  }

  private isNewChartSettings(settings: ChartSettings): boolean {
    return !this.documentService.findChartSettings(
      settings.targetMode,
      settings.associatedTarget,
      settings.associatedInsight,
      settings.dataViewMode
    );
  }

  private updateGlobalSeriesColors(localSettings: ChartSettings): void {
    if (localSettings.dataViewMode === DataViewMode.dynamic) {
      return;
    }
    const chartSettings = this.getChartSettings(
      localSettings.targetMode,
      localSettings.dataViewMode
    );
    chartSettings.forEach((settings: ChartSettings) => {
      Object.keys(localSettings.seriesColor).forEach((targetKey: string) => {
        settings.seriesColor[targetKey] = localSettings.seriesColor[targetKey];
      });
    });
  }
}
