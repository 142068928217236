import { cloneDeep } from 'lodash';
import { TupCsvBuilder } from '@telmar-global/tup-document-exporter';
import {
  APPLICATION_NAME,
  ClusterTableRowData,
  ColumnFilter,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemType,
  DEFAULT_SURVEY_COPYRIGHT,
  SortSettings,
  Survey,
  SurveyCodeMap,
  Target,
  TargetColumn,
  VOLUMETRIC_DATA_ITEM_IDS,
  CorrespondenceDataRowType,
  CorrespondenceTableDataMode,
  CorrespondenceTableRowData,
  DEFAULT_DECIMAL_POINTS,
  AudienceEffectsViewType,
  GainTableRowItem,
  AudienceEffectsVariableTableRowData,
  FactorAnalysisTableRowData,
  SurveyMetaDataWeights,
} from '../models';
import { TargetTitlePipe } from '../pipes';

export interface CrosstabTableCsvData {
  documentName: string;
  targetColumns: TargetColumn[];
  data: CrossTabTableData[];
  dataItems: DataItem[];
  reportUnits: string;
  sortSettings: SortSettings;
  filters: ColumnHeaderFilter[];
  surveys: Survey[];
  surveyCodeMap: SurveyCodeMap;
  defaultDecimalPoints: number;
  tablebase?: Target;
  weight?: SurveyMetaDataWeights;
}

export interface ClusterTableCsvData {
  documentName: string;
  displayedColumns: string[];
  data: ClusterTableRowData[];
  survey: Survey;
  surveyCodeMap: SurveyCodeMap;
}

export interface ClusterTableXlsxData {
  documentName: string;
  displayedColumns: string[];
  data: ClusterTableRowData[];
  survey: Survey;
  surveyCodeMap: SurveyCodeMap;
}

export interface CorrespondenceTableXlsxData {
  documentName: string;
  factorTabDisplayedColumns: string[];
  absRelTabDisplayedColumns: string[];
  data: CorrespondenceTableRowData[];
  survey: Survey;
  primaryTabTableDataMode: CorrespondenceTableDataMode;
  sort?: CorrespondenceTableExportSortData;
  isTableHighlighted?: boolean;
  surveyCodeMap: SurveyCodeMap;
}

export interface CorrespondenceTableCsvData {
  documentName: string;
  displayedColumns: string[];
  data: CorrespondenceTableRowData[];
  survey: Survey;
  primaryTabTableDataMode: CorrespondenceTableDataMode;
  sort?: CorrespondenceTableExportSortData;
  surveyCodeMap: SurveyCodeMap;
}

export interface AudienceEffectsTableXlsxData {
  documentName: string;
  displayedColumns: string[];
  data: GainTableRowItem[] | AudienceEffectsVariableTableRowData[];
  survey: Survey;
  surveyCodeMap: SurveyCodeMap;
  totalPopulation: string;
  totalSample: string;
  activeTableTitle: string;
}

export interface AudienceEffectsTableCsvData {
  documentName: string;
  displayedColumns: string[];
  data: GainTableRowItem[] | AudienceEffectsVariableTableRowData[];
  survey: Survey;
  surveyCodeMap: SurveyCodeMap;
}

export interface CorrespondenceTableExportSortData {
  surveyCode?: string;
  sortColumn: string;
  sortOrder: string;
}

export interface FactorAnalysisTableXlsxData {
  documentName: string;
  displayedColumns: string[];
  data: FactorAnalysisTableRowData[];
  explainedVariances: string[][];
  survey: Survey;
  surveyCodeMap: SurveyCodeMap;
  activeTableTitle: string;
  audienceSize: string;
  sort?: FactorAnalysisExportSortData;
  isMultiSort?: boolean;
}

export interface FactorAnalysisTableCsvData {
  documentName: string;
  displayedColumns: string[];
  data: FactorAnalysisTableRowData[];
  explainedVariances: string[][];
  survey: Survey;
  surveyCodeMap: SurveyCodeMap;
  sort?: FactorAnalysisExportSortData;
}

export interface FactorAnalysisExportSortData {
  surveyCode?: string;
  sortColumn: string;
  sortOrder: string;
}

export class CrosstabTableCsvBuilder extends TupCsvBuilder {
  constructor(private targetTitlePipe: TargetTitlePipe) {
    super();
  }

  public addTableData(data: CrosstabTableCsvData): void {
    this.addHeaderOrFooter(this.getHeaderRows(data))
      .addTableHeader(data.data, data.surveyCodeMap)
      .addTableBody(data)
      .addHeaderOrFooter(this.getFooterRows(data));
  }

  public addClusterTableData(data: ClusterTableCsvData): void {
    this.addHeaderOrFooter(this.getClusterHeaderRows(data)).addClusterTableBody(
      data
    );
    this.addHeaderOrFooter(this.getClusterFooterRows(data));
  }

  public addCorrespondenceTableData(data: CorrespondenceTableCsvData): void {
    this.addHeaderOrFooter(
      this.getCorrespondenceHeaderRows(data)
    ).addCorrespondenceTableBody(data);
    this.addHeaderOrFooter(this.getCorrespondenceFooterRows(data));
  }

  public addAudienceEffectsTableData(
    data: AudienceEffectsTableCsvData,
    mode: AudienceEffectsViewType
  ): void {
    this.addHeaderOrFooter(
      this.getAudienceEffectsHeaderRows(data)
    ).addAudienceEffectsTableBody(data, mode);
    this.addHeaderOrFooter(this.getAudienceEffectsFooterRows(data));
  }

  public addFactorAnalysisTableData(data: FactorAnalysisTableCsvData): void {
    this.addHeaderOrFooter(
      this.getFactorAnalysisHeaderRows(data)
    ).addFactorAnalysisBody(data);
    this.addHeaderOrFooter(this.getFactorAnalysisFooterRows(data));
  }

  private getHeaderRows(
    data: CrosstabTableCsvData,
    cell?: CrossTabTableDataCell
  ): string[][] {
    const rows = [
      [`${APPLICATION_NAME} Report:`, data.documentName],
      data?.tablebase ? ['Tablebase:', data.tablebase.title] : [],
      data?.weight ? ['Weights:', data.weight.description] : [],
      ...this.getSourceParts(
        data.surveys,
        cell?.surveyCode,
        data.surveyCodeMap
      ),
    ];
    const sortSettingsParts = this.getSortSettingsHeaderParts(
      data.sortSettings,
      data.targetColumns,
      data.surveys,
      data.surveyCodeMap
    );
    if (sortSettingsParts.length > 0) {
      rows.push(...sortSettingsParts);
    }
    if (data.filters.length > 0) {
      rows.push(
        this.getColumnFiltersHeaderParts(
          data.filters,
          data.targetColumns,
          data.surveys,
          data.surveyCodeMap
        )
      );
    }

    return rows;
  }

  private getClusterHeaderRows(data): string[][] {
    const rows = [
      [`${APPLICATION_NAME} Report:`, data.documentName],
      this.getClusterSourceParts(data.survey, data.surveyCodeMap),
    ];
    return rows;
  }

  private getCorrespondenceHeaderRows(
    data: CorrespondenceTableCsvData
  ): string[][] {
    return [
      [`${APPLICATION_NAME} Report:`, data.documentName],
      this.getCorrespondenceSourceParts(data.survey, data.surveyCodeMap),
      ...this.getCorrespondenceSortingHeaderParts(data),
    ];
  }

  private getAudienceEffectsHeaderRows(
    data: AudienceEffectsTableCsvData
  ): string[][] {
    const rows = [
      [`${APPLICATION_NAME} Report:`, data.documentName],
      this.getAudienceEffectsSourceParts(data.survey, data.surveyCodeMap),
    ];
    return rows;
  }

  private getFactorAnalysisHeaderRows(
    data: FactorAnalysisTableCsvData
  ): string[][] {
    return [
      [`${APPLICATION_NAME} Report:`, data.documentName],
      this.getFactorAnalysisSourceParts(data.survey, data.surveyCodeMap),
      ...this.getFactorAnalysisSortingHeaderParts(data),
    ];
  }

  private getCorrespondenceSortingHeaderParts(
    data: CorrespondenceTableCsvData
  ): string[][] {
    if (!data?.sort) {
      return [];
    }

    return [
      ['Sort Survey Code:', data.sort.surveyCode],
      ['Sort Column:', data.sort.sortColumn],
      ['Sort Order:', data.sort.sortOrder],
    ];
  }

  private getFactorAnalysisSortingHeaderParts(
    data: FactorAnalysisTableCsvData
  ): string[][] {
    if (!data?.sort) {
      return [];
    }

    return [
      ['Sort Survey Code:', data.sort.surveyCode],
      ['Sort Column:', data.sort.sortColumn],
      ['Sort Order:', data.sort.sortOrder],
    ];
  }

  private getFooterRows(
    data: CrosstabTableCsvData,
    cell?: CrossTabTableDataCell
  ): string[][] {
    return [
      ...this.getSourcesAndCopyrights(
        data.surveys,
        cell?.surveyCode,
        data.surveyCodeMap
      ),
      [''],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
    ];
  }

  private getClusterFooterRows(data: ClusterTableCsvData): string[][] {
    return [
      [],
      this.getClusterSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getClusterCopyrightParts(data.survey),
    ];
  }

  private getCorrespondenceFooterRows(
    data: CorrespondenceTableCsvData
  ): string[][] {
    return [
      [],
      this.getCorrespondenceSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getCorrespondenceCopyrightParts(data.survey),
    ];
  }

  private getAudienceEffectsFooterRows(
    data: AudienceEffectsTableCsvData
  ): string[][] {
    return [
      [],
      this.getAudienceEffectsSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getAudienceEffectsCopyrightParts(data.survey),
    ];
  }

  private getFactorAnalysisFooterRows(
    data: FactorAnalysisTableCsvData
  ): string[][] {
    return [
      [],
      this.getFactorAnalysisSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getFactorAnalysisCopyrightParts(data.survey),
    ];
  }

  private getSourcesAndCopyrights(
    surveys: Survey[],
    surveyCode: string | undefined,
    surveyCodeMap: SurveyCodeMap
  ): string[][] {
    let parts: string[][] = [];
    if (surveyCode) {
      const survey = surveys.find(
        (surveyItem) => surveyItem.code === surveyCode
      );

      parts = parts.concat(
        this.getSourceAndCopyrightLines(survey, surveyCodeMap)
      );

      return parts;
    } else {
      surveys.forEach((survey) => {
        parts = parts.concat(
          this.getSourceAndCopyrightLines(survey, surveyCodeMap)
        );
      });
      return parts;
    }
  }

  private getClusterCopyrightParts(survey: Survey): string[] {
    const source = this.getClusterSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getCorrespondenceCopyrightParts(survey: Survey): string[] {
    const source = this.getCorrespondenceSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getAudienceEffectsCopyrightParts(survey: Survey): string[] {
    const source = this.getAudienceEffectsSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getFactorAnalysisCopyrightParts(survey: Survey): string[] {
    const source = this.getFactorAnalysisSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getClusterSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getCorrespondenceSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getAudienceEffectsSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getFactorAnalysisSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getSourceAndCopyrightLines(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ) {
    const source = `${surveyCodeMap[survey.code]} - ${survey.title}`;
    const copyright = survey.meta['copyright-info']
      ? `${survey.meta['copyright-info']?.toString()}`
      : DEFAULT_SURVEY_COPYRIGHT;
    return [
      ['Source: ', source],
      ['Copyright: ', copyright],
    ];
  }

  private getSourceParts(
    surveys: Survey[],
    surveyCode: string | undefined,
    surveyCodeMap: SurveyCodeMap
  ): string[][] {
    const sources = this.getSources(surveys, surveyCode, surveyCodeMap);
    const sourceParts = [[]];
    sources.forEach((source: string, index: number) => {
      if (index === 0) {
        sourceParts.push(['Source:', source]);
      } else {
        sourceParts.push(['', source]);
      }
    });
    return sourceParts;
  }

  private getClusterSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getCorrespondenceSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getAudienceEffectsSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getFactorAnalysisSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getSources(
    surveys: Survey[],
    surveyCode: string | undefined,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    if (surveyCode) {
      const { code, title } = surveys.find(
        (survey) => survey.code === surveyCode
      );
      return [[surveyCodeMap[code], title].join(' - ')];
    }

    return surveys.map(({ code, title }: Survey) =>
      [surveyCodeMap[code], title].join(' - ')
    );
  }

  private getSortSettingsHeaderParts(
    sortSettings: SortSettings,
    targetColumns: TargetColumn[],
    surveys: Survey[],
    surveyCodeMap: SurveyCodeMap
  ): string[][] {
    if (sortSettings.columnId === '') {
      return [];
    }

    const targetColumn = targetColumns.find(
      (column: TargetColumn) => column.columnId === sortSettings.columnId
    );

    const surveyCount = surveys.length;
    const sortSurveyCode =
      sortSettings.survey || sortSettings.columnId.split('_')[1];
    const sortColumn = this.formatTitle(
      targetColumn.target,
      targetColumn.title
    );
    const sortOrder =
      sortSettings.order[0].toUpperCase() + sortSettings.order.slice(1);
    const sortDataItem = DATA_ITEMS_MAP[sortSettings.dataItem].displayName;

    const sortSettingsHeaderParts = [];
    if (surveyCount > 1) {
      sortSettingsHeaderParts.push([
        'Sort Survey Code:',
        surveyCodeMap[sortSurveyCode],
      ]);
    }
    return [
      ...sortSettingsHeaderParts,
      ['Sort Column:', sortColumn],
      ['Sort Order:', sortOrder],
      ['Sort Data Item:', sortDataItem],
    ];
  }

  private getColumnFiltersHeaderParts(
    filters: ColumnHeaderFilter[],
    targetColumns: TargetColumn[],
    surveys: Survey[],
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    const surveyCount = surveys.length;
    const filterParts = filters.map((columnFilter: ColumnHeaderFilter) => {
      const column = targetColumns.find(
        (targetColumn: TargetColumn) =>
          targetColumn.columnId === columnFilter.columnId
      );
      const columnTitle = this.formatTitle(column.target, column.title);
      const surveyCode =
        surveyCount > 1
          ? ` (${surveyCodeMap[columnFilter.columnId.split('_')[1]]})`
          : '';
      const filterCount = columnFilter.filters.length;
      const filterConditions = columnFilter.filters.map(
        (filter: ColumnFilter, index: number) => {
          const dataItemName = DATA_ITEMS_MAP[filter.dataItem].displayName;
          const conditionalOperator = filter.conditionalOperator.toLowerCase();
          const value =
            conditionalOperator === 'is between'
              ? '(' + filter.value.join(', ') + ')'
              : filter.value[0];
          const filterOperator =
            index < filterCount - 1 ? ` ${filter.operator}` : '';
          return `${dataItemName} ${conditionalOperator} ${value}${filterOperator}`;
        }
      );

      return `${columnTitle}${surveyCode} - ${filterConditions.join(' ')}`;
    });
    return ['Filter(s):', filterParts.join(', ')];
  }

  private addHeaderOrFooter(rows: string[][]): CrosstabTableCsvBuilder {
    rows.forEach((row: string[]) => this.addLine(row));
    this.addBlankLine();
    return this;
  }

  private addTableHeader(
    data: CrossTabTableData[],
    surveyCodeMap: SurveyCodeMap
  ): CrosstabTableCsvBuilder {
    let cells: string[] = [];
    const totalsColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'insight'
    );
    const targetColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'target'
    );
    const shouldAddSurveyCodeHeader = totalsColumnCells.length > 1;
    if (shouldAddSurveyCodeHeader) {
      cells.push('', '');
      const surveyCodeCells = data[0].data.map(
        (cell: CrossTabTableDataCell) =>
          `${cell.surveyCode ? surveyCodeMap[cell.surveyCode] : ''}`
      );
      cells.push(...surveyCodeCells);
      this.addLine(cells);
    }

    cells = ['', ''];
    cells.push(
      ...totalsColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );

    cells.push(
      ...targetColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );
    this.addLine(cells);

    return this;
  }

  private addTableBody(data: CrosstabTableCsvData): CrosstabTableCsvBuilder {
    data.data.forEach((row: CrossTabTableData) => {
      const insights: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'insight'
      );
      const targets: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'target'
      );

      const cells: CrossTabTableDataCell[] = [
        ...cloneDeep(insights),
        ...cloneDeep(targets),
      ];
      data.dataItems.forEach((dataItem: DataItem) => {
        this.addCombinedRow(data, row, dataItem, cells);
      });

      this.addBlankLine();
    });

    return this;
  }

  private addClusterTableBody(data: ClusterTableCsvData) {
    this.addLine(data.displayedColumns.slice(1));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.data.length; i++) {
      // tslint:disable-next-line:no-shadowed-variable
      const clusters = data.data[i].clusters.map((data) => data.value);
      this.addLine([
        data.data[i].variable,
        data.data[i].type,
        data.data[i].rank as any,
        data.data[i].determination,
        data.data[i].total,
        ...clusters,
      ]);
    }
    this.addBlankLine();

    return this;
  }

  private addCorrespondenceTableBody(data: CorrespondenceTableCsvData) {
    this.addLine(data.displayedColumns.slice(2));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.data.length; i++) {
      const absRelData = data.data[i].absContribution.map(
        (value, index) =>
          `${value} ${data.data[i].relSign[index]} ${data.data[i].relContribution[index]}`
      );
      this.addLine([
        data.data[i].title,
        CorrespondenceDataRowType[data.data[i].type] as any,
        data.data[i].inf,
        ...(data.primaryTabTableDataMode === CorrespondenceTableDataMode.absRel
          ? [...absRelData, ...data.data[i].coordinates]
          : [...data.data[i].coordinates, ...absRelData]),
      ]);
    }
    this.addBlankLine();

    return this;
  }

  private addFactorAnalysisBody(data: any) {
    this.addLine(data.displayedColumns.slice(1));
    data.explainedVariances.forEach((row: any) => {
      this.addLine(row);
    });
    data.data.forEach((row: any) => {
      this.addLine([row.title, ...row.loadings]);
    });
    this.addBlankLine();

    return this;
  }

  private addAudienceEffectsTableBody(
    data: AudienceEffectsTableCsvData,
    mode: AudienceEffectsViewType
  ) {
    if (mode === AudienceEffectsViewType.variable) {
      this.addLine(
        data.displayedColumns.slice(1).map((row) => {
          switch (row) {
            case 'rowNumber':
              return 'No';
            case 'title':
              return 'Title';
            case 'y':
              return 'Targets';
            case 'x':
              return 'Index';
          }
          return row;
        })
      );
      for (let i = 0; i < data.data.length; i++) {
        const variableData = data.data[
          i
        ] as AudienceEffectsVariableTableRowData;
        this.addLine([
          String(i + 1),
          variableData.title,
          String(variableData.y),
          String(variableData.x),
        ]);
      }
    } else {
      this.addLine(
        data.displayedColumns.map((row) => {
          switch (row) {
            case 'rowNumber':
              return 'Sl No.';
            case 'segments':
              return 'Segments';
            case 'population':
              return 'Population accum. (000)';
            case 'populationPercentage':
              return 'Population accum. (%)';
            case 'targets':
              return 'Targets accum. (000)';
            case 'targetsPercentage':
              return 'Targets accum. (%)';
            case 'index':
              return 'Index accum.';
          }
          return row;
        })
      );
      for (let i = 0; i < data.data.length; i++) {
        const gainData = data.data[i] as GainTableRowItem;
        this.addLine([
          i + 1,
          gainData['segments'],
          gainData.population,
          gainData.populationPercentage,
          gainData.targets,
          gainData.targetsPercentage,
          gainData.index,
        ]);
      }
    }
    this.addBlankLine();

    return this;
  }

  private addCombinedRow(
    data: CrosstabTableCsvData,
    rowData: CrossTabTableData,
    dataItem: DataItem,
    dataCells: CrossTabTableDataCell[]
  ): void {
    const hasVolumetricCoding = rowData.metadata?.hasVolumetricCoding;
    const cells: string[] = [
      this.formatTitle(rowData.data[0].rowTarget, rowData.title),
      this.formatDataItemName(dataItem, data.reportUnits, hasVolumetricCoding),
    ];
    const cellKey = dataItem.cellKey;
    const decimalPoints = this.formatDecimalPoints(
      dataItem,
      data,
      hasVolumetricCoding
    );

    const targetValues = dataCells.map((cell: CrossTabTableDataCell) => {
      let value =
        cell[cellKey] !== undefined && cell[cellKey] !== null
          ? `${cell[cellKey]?.toFixed(decimalPoints)}`
          : '';

      if (
        cell.metadata?.isVolumetricCoding &&
        !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
      ) {
        value = '';
      }

      return value;
    });
    cells.push(...targetValues);

    this.addLine(cells);
  }

  private formatTitle(target: Target, fallbackTitle: string): string {
    return target ? this.targetTitlePipe.transform(target) : fallbackTitle;
  }

  private formatDecimalPoints(
    dataItem: DataItem,
    data: CrosstabTableCsvData,
    hasVolumetricCoding: boolean
  ): number {
    if (hasVolumetricCoding && dataItem.id === DataItemType.audience) {
      return dataItem.volumetricDecimalPoints;
    }
    return data.defaultDecimalPoints === DEFAULT_DECIMAL_POINTS
      ? dataItem.decimalPoints
      : data.defaultDecimalPoints;
  }

  private formatDataItemName(
    dataItem: DataItem,
    reportUnits: string,
    hasVolumetricCoding: boolean
  ): string {
    if (hasVolumetricCoding && dataItem.id === DataItemType.audience) {
      return dataItem.volumetricDisplayName;
    }
    return (
      dataItem.displayName +
      (dataItem.id === DataItemType.audience ? reportUnits : '')
    );
  }
}
