<div
  class="manage-snapshot"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="false"
>
  <tup-mat-title-bar
    title="Manage snapshots"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <div mat-dialog-content>
    <tup-mat-content-wrapper
      position="last"
      gap="2"
      tupDocumentEventEmitter
      tupDocumentEventHandler
    >
      <mat-divider class="divider-bottom"></mat-divider>
      <div
        *ngIf="progressing"
        class="progress-bar-container"
      >
        <mat-progress-bar
          color="primary"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>
      <mat-table
        [dataSource]="dataSource"
        class="snapshot-table"
      >
        <ng-container matColumnDef="action">
          <mat-header-cell
            *matHeaderCellDef
            class="mat-column-actions"
          >
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="mat-column-actions"
          >
            <ng-container
              *ngTemplateOutlet="actionsCellDef; context: { row: element }"
            ></ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell
            class="table-header mat-column-name"
            *matHeaderCellDef
          >
            Name
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="mat-column-name"
          >
            <span
              [attr.data-docId]="element.docId"
              matTooltipPosition="above"
              [matTooltip]="element.name"
              >{{ element.name }}</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdTime">
          <mat-header-cell
            class="table-header mat-column-date"
            *matHeaderCellDef
          >
            Date created
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="mat-column-date"
          >
            {{ element.createdTime | date: "HH:mm, d MMM, y" }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="clickable"
          (click)="onClick(row)"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </mat-table>
    </tup-mat-content-wrapper>
  </div>
</div>

<ng-template
  #actionsCellDef
  let-row="row"
>
  <button
    mat-icon-button
    color="primary"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerRestoreFocus]="false"
    (click)="$event.stopPropagation(); $event.preventDefault()"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="onRenameClicked(row)"
    >
      <mat-icon>abc</mat-icon>
      <span>Rename</span>
    </button>

    <button
      mat-menu-item
      (click)="onDeleteClicked(row)"
    >
      <mat-icon>highlight_off</mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #applyConfirmation>
  <p>Apply snapshot to replace the current report.</p>
</ng-template>
