<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Select audiences for Plan"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-divider class="full-width-divider"></mat-divider>

  <mat-dialog-content fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutGap="10px"
      class="target-groups-container"
    >
      <mat-tab-group
        animationDuration="0ms"
        class="table-target-groups"
        mat-stretch-tabs="false"
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onSelectedTabChanged($event)"
      >
        <mat-tab
          *ngFor="let targetGroup of data.targetGroups; let groupIndex = index"
          [label]="targetGroup.title"
        >
          <ng-container
            *ngTemplateOutlet="
              targetGroupContainer;
              context: { targetGroup: targetGroup, groupIndex: groupIndex }
            "
          ></ng-container>
        </mat-tab>
        <mat-tab
          [label]="'Selected audiences (' + selectedAudiences.length + ')'"
        >
          <div class="target-group-container">
            <div class="target-group-list">
              <div
                fxLayout="column"
                class="target-group-item-container"
              >
                <div
                  *ngFor="
                    let audienceItem of selectedAudiences;
                    let index = index
                  "
                  fxLayoutAlign="start center"
                  class="target-group-item has-delete-button"
                  [class.has-error]="errors.hasOwnProperty(index + 1)"
                  [matTooltip]="
                    errors.hasOwnProperty(index + 1) ? errors[index + 1] : ''
                  "
                >
                  <span
                    class="target-group-item-label"
                    [title]="
                      audienceItem.target
                        | targetTitle
                          : audienceItem.target?.activeTitleMode
                          : audienceItem.target?.titleLevels
                    "
                  >
                    {{
                      audienceItem.target
                        | targetTitle
                          : audienceItem.target?.activeTitleMode
                          : audienceItem.target?.titleLevels
                        | truncate: 110
                    }}
                  </span>
                  <button
                    mat-icon-button
                    class="target-group-item-delete-button"
                    (click)="onDeleteSelectedAudience(audienceItem)"
                  >
                    <mat-icon
                      color="primary"
                      class="tiny-icon"
                      matTooltip="Delete selected audience"
                      >delete_outline
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-dialog-content>

  <mat-divider class="full-width-divider"></mat-divider>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-button
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusRegionstart
      [disabled]="isLoading || selectedAudiences.length === 0"
      (click)="onSend()"
    >
      Send to Plan
    </button>
  </mat-dialog-actions>

  <div
    class="spinner-container-overlay"
    *ngIf="isLoading"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<ng-template
  #targetGroupContainer
  let-targetGroup="targetGroup"
  let-groupIndex="groupIndex"
>
  <div class="target-group-container">
    <div class="target-group-list">
      <div
        fxLayout="column"
        class="target-group-item-container"
      >
        <cdk-virtual-scroll-viewport
          itemSize="40"
          class="target-group-item-scroll-viewport"
        >
          <mat-checkbox
            *cdkVirtualFor="
              let targetGroupItem of targetGroup.items;
              let itemIndex = index
            "
            tupMatCheckbox
            appearance="small"
            color="primary"
            [(ngModel)]="targetGroupItem.selected"
            (ngModelChange)="toggleTargetGroupItem(groupIndex, itemIndex)"
            fxLayoutAlign="start center"
            class="tiny-checkbox target-group-item"
            [disabled]="selectedAudiences.length === maxTargets"
          >
            <span
              class="target-group-item-label"
              [title]="
                targetGroupItem.target
                  | targetTitle
                    : targetGroupItem.target?.activeTitleMode
                    : targetGroupItem.target?.titleLevels
              "
            >
              {{
                targetGroupItem.target
                  | targetTitle
                    : targetGroupItem.target?.activeTitleMode
                    : targetGroupItem.target?.titleLevels
                  | truncate: 110
              }}
            </span>
          </mat-checkbox>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</ng-template>
