<div
  class="dialog-content-container"
  fxLayout="column"
  fxLayoutGap="10px"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Cluster preferences"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content class="visible-area">
    <div fxlayout="column">
      <h2>Respondent exclusion</h2>

      <h4>
        You may choose to exclude such respondent based on the percentage of the
        questions they answered as "Not Applicable" or "Not Answered".
        <b>
          Select an option about how you would like to handle respondent
          exclusions.</b
        >
      </h4>

      <div>
        <mat-radio-group
          fxLayout="column"
          fxLayoutGap="20px"
          [(ngModel)]="respondentsValidValuesThreshold"
        >
          <mat-radio-button [value]="0">
            Don't exclude respondents who may have answered "not applicable" or
            "not answered".
          </mat-radio-button>
          <mat-radio-button [value]="100">
            Do exclude respondents who may have answered "not applicable" or
            "not answered".
          </mat-radio-button>
          <div
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="start start"
          >
            <mat-radio-button value="custom">
              Do exclude respondents who may have answered
            </mat-radio-button>

            <mat-form-field
              tupMatFormField
              appearance="none"
            >
              <input
                type="number"
                min="0"
                max="100"
                matInput
                [(ngModel)]="customRespondentsValidValuesThreshold"
                class="threshold-filter"
                (keyup)="updateRespondentThreshold()"
                (change)="updateRespondentThreshold()"
                ng-cut="updateRespondentThreshold()"
                ng-paste="updateRespondentThreshold()"
              />
              <span matSuffix>%</span>
            </mat-form-field>

            <span class="padding-span">
              or more as "not applicable" or "not answered"</span
            >
          </div>
        </mat-radio-group>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="save()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
