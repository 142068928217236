import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { AudienceEffectsService } from '../../services/audience-effects.service';
import {
  CrosstabService,
  DialogService,
  DocumentService,
  PptxService,
  TitleLevelsService,
  TitleModeService,
} from '../../services';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  AE_VERSION,
  ALL_RESPONDENTS_CODING,
  AudienceEffectsSettings,
  AudienceEffectsVariableTableRowData,
  AudienceEffectsViewType,
  CHAID_ANALYSIS_CHART_LEVEL_COLORS,
  CHAID_CHART_CONTAINER_HEIGHT,
  CHAID_CHART_CONTAINER_WIDTH,
  CHAID_CHART_NODE_HEIGHT,
  CHAID_CHART_NODE_SPACING,
  CHAID_CHART_NODE_WIDTH,
  ChaidAnalysisBaseItem,
  ChaidAnalysisItem,
  ChaidAnalysisResponseBody,
  CriteriaPreferencesResponseBody,
  CrossTabTableData,
  DEFAULT_SURVEY_COPYRIGHT,
  DisplayType,
  DocumentDataState,
  DocumentViewType,
  ExportParams,
  GAIN_ANALYSIS_PRIMARY_COLOR,
  GainPlotChartType,
  SELECTED_RESULT_MODES,
  SelectedGainAnalysisMode,
  SelectedResultMode,
  SelectedValuable,
  SelectMenuOption,
  Survey,
  SurveyCodeMap,
  SurveyTimeDocument,
  SurveyTimeDocumentAppType,
  SurveyTimeDocumentAudienceEffectsApp,
  Target,
  TargetColumnDataItemMap,
  TITLE_MODES,
  VariableType,
  VIEW_TYPES,
} from '../../models';
import { Router } from '@angular/router';
import { TupDocument } from '@telmar-global/tup-document-storage';
import Highcharts, { ExportingMimeTypeValue } from 'highcharts';
import HighchartsTreeMap from 'highcharts/modules/treemap';
import HighchartsTreeGraph from 'highcharts/modules/treegraph';
import Fullscreen from 'highcharts/modules/full-screen';
import Exporting from 'highcharts/modules/exporting';
import ExportingLocal from 'highcharts/modules/offline-exporting';
import { cloneDeep, isEqual } from 'lodash';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import {
  AudienceEffectsGainAnalysisViewComponent,
  AudienceEffectsVariableSelectionViewComponent,
} from 'src/app/components';
import {
  AppApiErrorDialogComponent,
  TitleLevelsDialogResult,
} from 'src/app/dialogs';
import { MatDialog } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';

HighchartsTreeMap(Highcharts);
HighchartsTreeGraph(Highcharts);
Fullscreen(Highcharts);
Exporting(Highcharts);
ExportingLocal(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.roundedRect = function (x, y, w, h) {
  const r = 10;
  return [
    'M',
    x,
    y + r,
    'a',
    r,
    r,
    0,
    0,
    1,
    r,
    -r,
    'l',
    w - 2 * r,
    0,
    'a',
    r,
    r,
    0,
    0,
    1,
    r,
    r,
    'l',
    0,
    h - 2 * r,
    'a',
    r,
    r,
    0,
    0,
    1,
    -r,
    r,
    'l',
    -w + 2 * r,
    0,
    'a',
    r,
    r,
    0,
    0,
    1,
    -r,
    -r,
    'z',
  ];
};

@Component({
  selector: 'app-audience-effects',
  templateUrl: './audience-effects.component.html',
  styleUrls: ['./audience-effects.component.scss'],
})
export class AudienceEffectsComponent implements OnInit, OnDestroy {
  public selectViewType: DocumentViewType = DocumentViewType.audienceEffects;
  public selectedView: AudienceEffectsViewType =
    AudienceEffectsViewType.variable;
  public selectedViewTypes: SelectMenuOption<AudienceEffectsViewType>[] =
    VIEW_TYPES;
  public selectedResultMode: SelectedResultMode = SelectedResultMode.chaid;
  public resultViewModes: SelectMenuOption<SelectedResultMode>[] =
    SELECTED_RESULT_MODES;
  public selectedGainAnalysisMode: SelectedGainAnalysisMode =
    SelectedGainAnalysisMode.table;
  public isReadonly = true;
  public currentDoc: TupDocument<SurveyTimeDocument>;
  private previouslySelectedSurvey: Survey;
  public activeSurvey: Survey;
  public surveys: Survey[] = [];
  public activeTable: Target;
  private selectedValuables: SelectedValuable[];
  private updatedSelectedValuables: SelectedValuable[];
  public variableTableData: AudienceEffectsVariableTableRowData[] = [];
  public crosstabData: CrossTabTableData[];

  private defaultAppDoc: SurveyTimeDocumentAudienceEffectsApp;
  public activeAppDoc: SurveyTimeDocumentAudienceEffectsApp;
  public chaidAnalysis: ChaidAnalysisResponseBody;

  public variablesUpdated = false;

  public inProgress = true;

  public hasValidDataset = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(AudienceEffectsVariableSelectionViewComponent, { static: false })
  aeVariableSelectionViewComponent: AudienceEffectsVariableSelectionViewComponent;

  @ViewChild(AudienceEffectsGainAnalysisViewComponent, { static: false })
  aeGainAnalysisViewComponent: AudienceEffectsGainAnalysisViewComponent;

  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: any;
  public chaidChartHeight = CHAID_CHART_CONTAINER_HEIGHT;
  public chaidChartWidth = CHAID_CHART_CONTAINER_WIDTH;

  @ViewChild('reAudienceEffectsConfirmation')
  reAudienceEffectsConfirmation: TemplateRef<any>;

  @ViewChild('changeSurveyConfirmation')
  changeSurveyConfirmation: TemplateRef<any>;

  public readonly titleModes = TITLE_MODES;
  public activeTitleMode: DisplayType;
  private titleLevels: number[];

  private chartRef: Highcharts.Chart;
  public chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  constructor(
    private router: Router,
    private authService: TupAuthService,
    private userMessageService: TupUserMessageService,
    private audienceEffectsService: AudienceEffectsService,
    private documentService: DocumentService,
    private crosstabService: CrosstabService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private pptxService: PptxService
  ) {
    this.isReadonly =
      !!this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.listenToTitleModeAndLevelsChanges();
    this.listenToDocumentDataChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public clickTitleMode(displayType: DisplayType): void {
    if (displayType === DisplayType.levels) {
      this.openTitleLevelsDialog();
    }
  }

  public selectTitleMode(): void {
    if (this.activeTitleMode !== DisplayType.levels) {
      this.titleLevels = [];
      this.updateDataTitles();
    }
  }

  public onSelectedViewChange(): void {
    this.renderChart();
  }

  public onSelectedGainAnalysisModeChange(): void {
    if (this.selectedGainAnalysisMode === SelectedGainAnalysisMode.chart) {
      this.renderChart();
    }
  }

  public onRerun(): void {
    this.userMessageService
      .openCustomMessageDialog(
        this.reAudienceEffectsConfirmation,
        'Re-analysis confirmation',
        {
          confirmText: 'Re-analyse',
          centered: true,
          width: '400px',
        }
      )
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.resetAudienceEffects();
        }
      });
  }

  public onSelectedSurveyChanged(): void {
    this.userMessageService
      .openCustomMessageDialog(this.changeSurveyConfirmation, 'Change survey', {
        cancelText: 'Cancel',
        confirmText: 'OK',
        width: '400px',
      })
      .afterClosed()
      .subscribe((result: boolean | undefined) => {
        if (!result) {
          this.prepareSurveyData(this.previouslySelectedSurvey);
          return;
        }
        this.resetAudienceEffects();
      });
  }

  public onCalculateChaid(): void {
    this.selectedValuables = this.updatedSelectedValuables;
    this.variablesUpdated = false;
    this.activeAppDoc = {
      ...this.activeAppDoc,
      variableTableData: this.variableTableData,
    };
    this.fetchChaidAnalysis(this.selectedValuables);
    this.saveAnalysisToDoc(false);
  }

  public onVariableTableDataChange(): void {
    this.variablesUpdated = true;
    this.updatedSelectedValuables =
      this.audienceEffectsService.formatTableDataToSelectedValuables(
        this.variableTableData
      );
  }

  public openSettings(): void {
    this.dialogService
      .audienceEffectsSettings(
        this.selectedView,
        this.selectedResultMode,
        this.selectedGainAnalysisMode,
        this.activeAppDoc.settings,
        this.activeAppDoc.targetMinMaxDataMap
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: AudienceEffectsSettings) => {
        if (!isEqual(this.activeAppDoc.settings, result)) {
          const shouldRunChaidAnalysis =
            result.filterChaidTreePercent !==
            this.activeAppDoc.settings.filterChaidTreePercent;
          const shouldFetchRecommendedVariables =
            result.generateAndIncludeNotVariables !==
              this.activeAppDoc.settings.generateAndIncludeNotVariables ||
            !isEqual(this.activeAppDoc.settings.criteria, result.criteria);
          this.activeAppDoc.settings = result;
          if (shouldFetchRecommendedVariables) {
            this.fetchRecommendedVariables(true);
          } else {
            if (shouldRunChaidAnalysis) {
              this.fetchChaidAnalysis(this.selectedValuables);
            } else {
              this.renderChart();
            }
            this.saveAnalysisToDoc(false);
          }
        }
      });
  }

  private listenToTitleModeAndLevelsChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.titleLevelsService.updateNumberOfTitleLevelsByTargets([
          ...columns,
          ...rows,
        ]);
      });
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((activeTitleMode: DisplayType) => {
        this.activeTitleMode = activeTitleMode;
      });

    this.titleLevelsService.titleLevels$
      .pipe(
        takeUntil(this.unsubscribe),
        filter((titleLevels: number[]) => !!titleLevels?.length)
      )
      .subscribe((titleLevels: number[]) => {
        this.titleLevels = titleLevels;
      });
  }

  private listenToDocumentDataChanges(): void {
    combineLatest([
      this.documentService.selectedSurvey$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.activeTablebase$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.crosstabService.crossTabData$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.restoreTimestampState$.pipe(
        takeUntil(this.unsubscribe)
      ),
    ])
      .pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current))
      )
      .subscribe(
        ([survey, tablebase, crosstabData, _]: [
          Survey,
          Target,
          CrossTabTableData[],
          number
        ]): void => {
          if (crosstabData.length > 0 && crosstabData[0].isPlaceholder) {
            return;
          }
          this.crosstabData = crosstabData;
          this.activeTable = tablebase;
          this.currentDoc = this.documentService.document;

          if (
            this.currentDoc.content.rows.length < 1 ||
            this.currentDoc.content.columns.length < 1
          ) {
            this.hasValidDataset = false;
            this.inProgress = false;
            return;
          }
          this.surveys = this.currentDoc.content?.surveys;
          this.prepareSurveyData(survey);
          this.isReadonly = !(
            this.authService.user.attributes.email ===
            this.currentDoc?.metadata?.by?.attributes?.email
          );

          const firstColumn = cloneDeep(this.currentDoc.content.columns[0]);
          const targetMinMaxDataMap =
            this.audienceEffectsService.getTargetMinMaxDataMap(
              this.activeSurvey,
              this.crosstabData
            );

          this.defaultAppDoc = {
            survey: this.activeSurvey,
            tablebase,
            weight: this.currentDoc.content.weight,
            column: firstColumn,
            rows: cloneDeep(this.currentDoc.content.rows),
            variableTableData: this.variableTableData,
            targetMinMaxDataMap,
            settings: this.getDefaultSettings(firstColumn, targetMinMaxDataMap),
            activeTitleMode: this.activeTitleMode,
            titleLevels: this.titleLevels,
            version: AE_VERSION,
          };
          this.activeAppDoc = this.currentDoc.content.apps?.audienceEffects
            ? (this.currentDoc.content.apps[
                SurveyTimeDocumentAppType.ae
              ] as SurveyTimeDocumentAudienceEffectsApp)
            : cloneDeep(this.defaultAppDoc);
          this.loadAudienceEffects();
        }
      );
  }

  private loadAudienceEffects(canUndo: boolean = false): void {
    if (this.currentDoc.content.apps?.audienceEffects) {
      this.hasValidDataset = true;
      this.activeSurvey = this.activeAppDoc.survey;
      this.activeTitleMode =
        this.activeAppDoc.activeTitleMode || this.activeTitleMode;
      this.titleLevels = this.activeAppDoc.titleLevels || this.titleLevels;
      this.prepareSurveyData(this.activeSurvey);
      this.variableTableData = cloneDeep(this.activeAppDoc.variableTableData);
      this.loadChaidAnalysis();
    } else if (!this.isReadonly) {
      this.hasValidDataset =
        this.currentDoc.content?.rows.length > 0 &&
        this.currentDoc.content?.columns.length > 0;
      if (this.hasValidDataset) {
        this.fetchRecommendedVariables(canUndo);
      } else {
        this.inProgress = false;
      }
    } else {
      this.inProgress = false;
    }
  }

  private fetchRecommendedVariables(
    canUndo: boolean,
    savedRecommendedMap?: Record<
      string,
      { recommended: boolean; type: VariableType }
    >
  ): void {
    this.inProgress = true;
    this.audienceEffectsService
      .getRecommendedVariables({
        authorizationGroup: this.activeAppDoc.survey.authorizationGroup,
        surveyCode: this.activeAppDoc.survey.code,
        ...this.audienceEffectsService.formatTargetVariables(
          this.activeAppDoc.column,
          this.activeTitleMode,
          this.titleLevels
        ),
        ...this.audienceEffectsService.formatRowVariables(
          this.activeAppDoc.rows,
          this.activeTitleMode,
          this.titleLevels
        ),
        table: this.activeAppDoc.tablebase?.coding || ALL_RESPONDENTS_CODING,
        weightIndex: this.activeAppDoc.weight,
        criteriaList: this.activeAppDoc.settings.criteria,
        includeComplements:
          this.activeAppDoc.settings.generateAndIncludeNotVariables,
      })
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(
        (result: CriteriaPreferencesResponseBody) => {
          if (result.success) {
            const variables =
              this.audienceEffectsService.formatAllVariables(result);
            let variableTableDataToBeSaved;
            if (savedRecommendedMap) {
              this.variableTableData =
                this.audienceEffectsService.formatSelectedVariablesToTableData(
                  variables,
                  this.getRecommendedMap(this.variableTableData)
                );
              variableTableDataToBeSaved =
                this.audienceEffectsService.formatSelectedVariablesToTableData(
                  variables,
                  savedRecommendedMap
                );
            } else {
              this.variableTableData =
                this.audienceEffectsService.formatSelectedVariablesToTableData(
                  variables,
                  savedRecommendedMap
                );
              variableTableDataToBeSaved = this.variableTableData;
            }
            this.activeAppDoc.variableTableData = cloneDeep(
              variableTableDataToBeSaved
            );
            this.loadChaidAnalysis();
            this.saveAnalysisToDoc(!canUndo);
          } else {
            this.openErrorDialog(result);
          }
        },
        (error) => {
          this.inProgress = false;
        }
      );
  }

  private openErrorDialog(
    result: CriteriaPreferencesResponseBody | ChaidAnalysisResponseBody
  ): void {
    const appMessage = 'There is an issue with the Chaid analysis:';
    const appTitle = 'Chaid analysis failed';
    this.dialog
      .open(AppApiErrorDialogComponent, {
        data: {
          errorMessage: result.message,
          appMessage,
          appTitle,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.documentService.navToCrosstab();
        } else {
          this.inProgress = false;
        }
      });
  }

  private loadChaidAnalysis(): void {
    this.selectedValuables =
      this.audienceEffectsService.formatTableDataToSelectedValuables(
        this.variableTableData
      );
    this.fetchChaidAnalysis(this.selectedValuables);
  }

  private fetchChaidAnalysis(selectedVariables: SelectedValuable[]): void {
    this.inProgress = true;
    this.audienceEffectsService
      .getChaidAnalysis({
        authorizationGroup: this.activeAppDoc.survey.authorizationGroup,
        surveyCode: this.activeAppDoc.survey.code,
        ...this.audienceEffectsService.formatTargetVariables(
          this.activeAppDoc.column,
          this.activeTitleMode,
          this.titleLevels
        ),
        variables: selectedVariables.map((item) => item.variable),
        variableTitles: selectedVariables.map((item) => item.title),
        table: this.activeAppDoc.tablebase?.coding || ALL_RESPONDENTS_CODING,
        weightIndex: this.activeAppDoc.weight,
        treeFilterValue: this.activeAppDoc.settings.filterChaidTreePercent,
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (result: ChaidAnalysisResponseBody) => {
          if (result.success) {
            this.chaidAnalysis = result;
            this.renderChart();
            this.inProgress = false;
          } else {
            this.openErrorDialog(result);
          }
        },
        (error) => {
          this.inProgress = false;
        }
      );
  }

  private setChartOptions() {
    this.chartOptions = this.buildChaidAnalysisChartOptions();
  }

  private renderChart() {
    this.setChartOptions();
  }

  private prepareCHAIDAnalysisChartData() {
    const nodes = [];

    const treeLevelWidthArray = this.getTreeWidthForEveryLevel(
      this.chaidAnalysis.chaidTree
    ).map((element) => element.length);
    this.buildChaidData(this.chaidAnalysis.chaidTree, '', nodes);

    const levels = treeLevelWidthArray.map((level, index) => ({
      level: index + 1,
      marker: {
        fillColor:
          CHAID_ANALYSIS_CHART_LEVEL_COLORS[
            (index + 1) % CHAID_ANALYSIS_CHART_LEVEL_COLORS.length
          ],
      },
      dataLabels: {
        style: {
          color:
            (index + 1) % CHAID_ANALYSIS_CHART_LEVEL_COLORS.length === 1
              ? 'white'
              : 'black',
        },
      },
    }));

    const minTreeWidth = this.getMinTreeWidth(
      this.chaidAnalysis.chaidTree,
      CHAID_CHART_NODE_HEIGHT,
      CHAID_CHART_NODE_SPACING
    );

    this.setChaidChartDimensions(treeLevelWidthArray.length, minTreeWidth);
    return { nodes, levels };
  }

  private getTreeWidthForEveryLevel(
    tree: ChaidAnalysisBaseItem | ChaidAnalysisItem
  ) {
    if (!tree) {
      return [];
    }

    const treeLevelWidthArray = [];
    const queue = [tree];

    while (queue.length) {
      const arr = [];
      const queueLength = queue.length;
      for (let i = 0; i < queueLength; i++) {
        const node = queue.pop();
        arr.push(node.code);
        if (node?.children) {
          node.children.forEach((child) => {
            queue.unshift(child);
          });
        }
      }
      treeLevelWidthArray.push(arr);
    }
    return treeLevelWidthArray;
  }

  private getMinTreeWidth(
    tree: ChaidAnalysisBaseItem | ChaidAnalysisItem,
    width: number,
    spacing: number
  ): number {
    if (!tree) {
      return 0;
    }
    let totalWidth = 0;

    if (tree?.children) {
      tree.children.forEach((child) => {
        totalWidth = totalWidth + this.getMinTreeWidth(child, width, spacing);
      });
    }

    return Math.max(totalWidth + spacing, width);
  }

  private setChaidChartDimensions(noOfNodes: number, maxNodeWidth: number) {
    const chaidChartProposedWidth = maxNodeWidth;
    const chaidChartProposedHeight =
      noOfNodes <= 2
        ? 300
        : noOfNodes * (CHAID_CHART_NODE_WIDTH + CHAID_CHART_NODE_SPACING);

    const containerWidth = window.innerWidth - 100;
    const containerHeight = window.innerHeight - 120;

    this.chaidChartHeight =
      containerHeight > chaidChartProposedHeight
        ? `${containerHeight}px`
        : `${chaidChartProposedHeight}px`;
    this.chaidChartWidth =
      containerWidth > chaidChartProposedWidth
        ? `${containerWidth}px`
        : `${chaidChartProposedWidth}px`;
  }

  private buildChaidData(
    chaidData: ChaidAnalysisBaseItem | ChaidAnalysisItem,
    parentCode: string,
    nodes: any[]
  ) {
    const code = chaidData?.['fullCode'] || chaidData?.code || '';
    if (chaidData?.code) {
      nodes.push({
        id: code,
        title: chaidData.title,
        clippedTitle: this.clipTitle(chaidData.title),
        audience: chaidData.pop,
        indexValue: chaidData?.['index']
          ? Number(chaidData['index']).toFixed(2)
          : 'None',
        parent: parentCode,
      });
    }

    if (chaidData?.children?.length > 0) {
      chaidData.children.forEach((element, index) => {
        this.buildChaidData(element, code, nodes);
      });
    }

    return;
  }

  private clipTitle(title: string, maxRows = 2) {
    const maxDataLabelLength = 20;
    const words = title.split(' ');
    const clippedTitles = [];
    let currentIndex = 0;
    words.forEach((word) => {
      if (clippedTitles.length < currentIndex + 1) {
        clippedTitles.push([]);
      }

      if (
        clippedTitles[currentIndex].join(' ').length + word.length >=
        maxDataLabelLength
      ) {
        currentIndex = currentIndex + 1;
        if (clippedTitles.length < currentIndex + 1) {
          clippedTitles.push([]);
        }
        clippedTitles[currentIndex].push(word);
      } else {
        clippedTitles[currentIndex].push(word);
      }
    });

    const clippedTitle = clippedTitles
      .splice(0, maxRows)
      .map((title) => title.join(' '))
      .join('<br/>');

    return clippedTitle + (clippedTitle.length > 40 ? '...' : '');
  }

  private buildChaidAnalysisChartOptions() {
    const { nodes, levels } = this.prepareCHAIDAnalysisChartData();

    return {
      chart: {
        inverted: true,
      },
      title: {
        text: this.activeAppDoc.settings.chiadAnalysisTitle,
        align: 'left',
      },
      series: [
        {
          type: 'treegraph',
          data: nodes,
          tooltip: {
            outside: true,
            pointFormat:
              '{point.title}<br/><br/>Index: {point.indexValue}</br>Audience: {point.audience}',
          },
          link: {
            type: 'straight',
          },
          marker: {
            symbol: 'roundedRect',
            width: String(CHAID_CHART_NODE_WIDTH),
            height: String(CHAID_CHART_NODE_HEIGHT),
          },
          dataLabels: {
            align: 'left',
            enabled: true,
            useHTML: true,
            inside: true,
            allowOverlap: false,

            formatter() {
              return (
                '<span style="font-size: 8px; line-height: 10px">' +
                this.point.clippedTitle +
                '</span>' +
                '</br><br/>' +
                '<span style="font-size: 10px; line-height: 10px">' +
                `Index: ${this.point.indexValue}` +
                '</br>' +
                `Audience: ${this.point.audience}` +
                '</span>'
              );
            },
            style: {
              textOutline: 0,
              color: 'black',
            },
          },
          levels,
        },
      ],
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },
    };
  }

  private getDefaultSettings(
    firstColumn: Target,
    targetMinMaxDataMap: TargetColumnDataItemMap
  ): AudienceEffectsSettings {
    const title = this.audienceEffectsService.formatTargetVariables(
      firstColumn,
      this.activeTitleMode,
      this.titleLevels
    ).targetTitles[0];
    return {
      variableSelectionTitle: title,
      chiadAnalysisTitle: title,
      gainChartTitle: title,
      showDataLabelInVariableSelection: false,
      showAxisLabelInVariableSelection: true,
      showLegendInVariableSelection: true,
      showDataLabelInGainChart: false,
      showAxisLabelInGainChart: true,
      showLegendInGainChart: true,
      showHAccumulatedHighlightColor: true,
      accumulatedHighlightColor: GAIN_ANALYSIS_PRIMARY_COLOR,
      generateAndIncludeNotVariables: true,
      filterChaidTreePercent: 10,
      criteria: this.audienceEffectsService.formatDefaultCriteriaList(
        firstColumn,
        targetMinMaxDataMap,
        this.activeTitleMode,
        this.titleLevels
      ),
      gainPlotChartType: GainPlotChartType.scatter,
    };
  }

  private prepareSurveyData(survey: Survey): void {
    this.activeSurvey =
      this.surveys.find(
        (surveyItem: Survey) =>
          surveyItem.code === survey.code &&
          surveyItem.authorizationGroup === survey.authorizationGroup
      ) || survey;
    this.updatePreviouslySelectedSurvey(this.activeSurvey);
    this.surveys = this.getEligibleSurveys();
  }

  private updatePreviouslySelectedSurvey(survey: Survey): void {
    this.previouslySelectedSurvey = survey;
  }

  private getEligibleSurveys() {
    return [
      ...this.surveys?.filter(
        (survey) => survey.code !== this.activeSurvey?.code
      ),
      this.activeSurvey,
    ];
  }

  private saveAnalysisToDoc(shouldUnsetRestoreDocumentState?: boolean): void {
    const docToSave = cloneDeep(this.currentDoc);
    const audienceEffects = this.activeAppDoc;

    if (docToSave.content?.apps) {
      docToSave.content.apps.audienceEffects = audienceEffects;
    } else {
      docToSave.content.apps = { audienceEffects };
    }

    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(
      docToSave,
      shouldUnsetRestoreDocumentState
    );
  }

  private resetAudienceEffects(): void {
    this.currentDoc.content.apps.audienceEffects = null;
    const targetMinMaxDataMap =
      this.audienceEffectsService.getTargetMinMaxDataMap(
        this.activeSurvey,
        this.crosstabData
      );
    this.activeAppDoc = {
      ...cloneDeep(this.defaultAppDoc),
      survey: this.activeSurvey,
      targetMinMaxDataMap,
      settings: this.getDefaultSettings(
        this.defaultAppDoc.column,
        targetMinMaxDataMap
      ),
      variableTableData: [],
    };
    this.activeTitleMode =
      this.activeAppDoc.activeTitleMode || this.activeTitleMode;
    this.titleLevels = this.activeAppDoc.titleLevels || this.titleLevels;
    this.prepareSurveyData(this.activeSurvey);
    this.loadAudienceEffects(true);
  }

  public exportTo(
    type: ExportingMimeTypeValue,
    exportParams?: ExportParams
  ): void {
    if (this.inProgress) {
      return;
    }

    if (
      this.selectedView === AudienceEffectsViewType.results &&
      this.selectedResultMode === SelectedResultMode.chaid
    ) {
      const chartContainer = document.getElementsByClassName(
        'highcharts-container'
      )[0] as HTMLElement;
      html2canvas(chartContainer).then((canvas) => {
        if (type.startsWith('image')) {
          if (type.includes('svg')) {
            const svg = this.convertCanvasToSVGElement(canvas);

            const blob = new Blob(
              [new XMLSerializer().serializeToString(svg)],
              { type: 'image/svg+xml' }
            );

            const svgDownloadLink = document.createElement('a');
            svgDownloadLink.href = URL.createObjectURL(blob);
            svgDownloadLink.download = `${
              exportParams?.docName ||
              this.documentService.document.metadata.name
            }.svg`;
            svgDownloadLink.click();
          } else {
            const fileExtension = type.split('/')[1];
            const a = document.createElement('a');
            a.href = canvas.toDataURL(type);
            a.download = `${
              exportParams?.docName ||
              this.documentService.document.metadata.name
            }.${fileExtension}`;
            a.click();
          }
        } else if (type === 'application/pdf') {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('l');
          const dimensions = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (dimensions.height * pdfWidth) / dimensions.width;
          pdf.addImage(imgData, 'png', 0, 0, pdfWidth, pdfHeight);
          pdf.save(
            `${
              exportParams?.docName ||
              this.documentService.document.metadata.name
            }.pdf`
          );
        }
      });
    }
  }

  exportToCsv(exportParams?: ExportParams) {
    if (this.inProgress) {
      return;
    }

    if (this.selectedView === AudienceEffectsViewType.variable) {
      this.aeVariableSelectionViewComponent.exportToCSV(exportParams?.docName);
    } else if (
      this.selectedView === AudienceEffectsViewType.results &&
      this.selectedResultMode === SelectedResultMode.gain
    ) {
      this.aeGainAnalysisViewComponent.exportToCSV(exportParams?.docName);
    }
  }

  exportToXlsx(exportParams?: ExportParams) {
    if (this.inProgress) {
      return;
    }

    if (this.selectedView === AudienceEffectsViewType.variable) {
      this.aeVariableSelectionViewComponent.exportToXlsx(exportParams?.docName);
    } else if (this.selectedView === AudienceEffectsViewType.results) {
      this.aeGainAnalysisViewComponent.exportToXlsx(exportParams?.docName);
    }
  }

  exportToSheets(exportParams?: ExportParams) {
    if (this.inProgress) {
      return;
    }

    if (this.selectedView === AudienceEffectsViewType.variable) {
      this.aeVariableSelectionViewComponent.exportToSheets(
        exportParams?.docName
      );
    } else if (this.selectedView === AudienceEffectsViewType.results) {
      this.aeGainAnalysisViewComponent.exportToSheets(exportParams?.docName);
    }
  }

  exportToPptx(exportParams?: ExportParams) {
    if (this.inProgress) {
      return;
    }

    const copyrightText = this.getSurveyCopyrightText();
    if (this.selectedView === AudienceEffectsViewType.variable) {
      this.aeVariableSelectionViewComponent.exportToPptx(
        exportParams?.docName,
        copyrightText
      );
    } else if (this.selectedView === AudienceEffectsViewType.results) {
      if (this.selectedResultMode === SelectedResultMode.chaid) {
        const builder = new SurveyTimePptxBuilder(this.userMessageService);
        const chartContainer = document.getElementsByClassName(
          'audience-effects-chart'
        )[0] as HTMLElement;
        html2canvas(chartContainer).then((canvas) => {
          const imageData = canvas.toDataURL('image/png');
          builder.exportStatsAppToPptx(
            null,
            [
              {
                data: imageData,
                x: 0,
                y: 0,
                w: 10,
                h:
                  10 *
                  (chartContainer.offsetHeight / chartContainer.offsetWidth),
              },
            ],
            exportParams?.docName,
            'Audience effects - CHAID analysis',
            copyrightText
          );
          this.pptxService.saveAs(builder, exportParams?.docName);
        });
      } else if (this.selectedResultMode === SelectedResultMode.gain) {
        this.aeGainAnalysisViewComponent.exportToPptx(
          exportParams?.docName,
          copyrightText
        );
      }
    }
  }

  public onSelectedResultModeChange() {
    this.selectedResultMode === SelectedResultMode.gain && this.renderChart();
  }

  private convertCanvasToSVGElement(canvas: any) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', canvas.width);
    svg.setAttribute('height', canvas.height);

    const image = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'image'
    );
    image.setAttribute('x', '0');
    image.setAttribute('y', '0');
    image.setAttribute('width', canvas.width);
    image.setAttribute('height', canvas.height);
    image.setAttribute('href', canvas.toDataURL('image/png'));

    svg.appendChild(image);

    return svg;
  }

  private openTitleLevelsDialog(): void {
    this.titleLevelsService
      .openRawDialog({ titleLevels: this.titleLevels })
      .subscribe((dialogResult: TitleLevelsDialogResult) => {
        this.titleLevels = dialogResult?.titleLevels || [];
        this.updateDataTitles();
      });
  }

  private updateDataTitles(): void {
    this.activeAppDoc.activeTitleMode = this.activeTitleMode;
    this.activeAppDoc.titleLevels = this.titleLevels;
    const targetTitle = this.audienceEffectsService.formatTargetVariables(
      this.activeAppDoc.column,
      this.activeTitleMode,
      this.titleLevels
    ).targetTitles[0];
    this.activeAppDoc.settings.criteria.forEach((item) => {
      item.variables = targetTitle;
    });
    const savedRecommendedMap = this.getRecommendedMap(
      this.activeAppDoc.variableTableData
    );
    this.fetchRecommendedVariables(true, savedRecommendedMap);
  }

  private getRecommendedMap(
    data: AudienceEffectsVariableTableRowData[]
  ): Record<string, { recommended: boolean; type: VariableType }> {
    return data.reduce(
      (acc, row) => ({
        ...acc,
        [row.coding]: {
          recommended: row.recommended,
          type: row.type,
        },
      }),
      {}
    );
  }

  private getSurveyCopyrightText(): string {
    let surveyCodeMap;
    this.documentService.surveyCodeMap$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((map: SurveyCodeMap) => {
        surveyCodeMap = map;
      });
    const { code, title } = this.documentService.activeSurvey;
    const surveyTitle = [surveyCodeMap[code], title].join(' - ');
    const surveyCopyright =
      this.documentService.activeSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `${surveyTitle} - ${surveyCopyright}`;
  }
}
