import { Pipe, PipeTransform } from '@angular/core';
import { UserDetails } from '@telmar-global/tup-auth';

@Pipe({
  name: 'isAdminUser',
})
export class AdminUserPipe implements PipeTransform {
  public transform(user: UserDetails): boolean {
    const adminContainer = user.containers.filter(
      (container) =>
        container.label === 'Telmar Main' &&
        container.name === 'a2D6g0000001eWWEAY'
    );
    return adminContainer.length > 0;
  }
}
