import { DisplayType, Target, TargetItem, TargetType } from './document.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { DataItemId, DataItemType } from './data-item.model';

export enum CellStyleStatus {
  none = 'none',
  heatmap = 'heatmap',
  heatmapQuartiles = 'heatmapQuartiles',
  heatmapQuintiles = 'heatmapQuintiles',
  highlight = 'highlight',
  significanceTestingRow = 'significanceTestingRow',
  significanceTestingColumn = 'significanceTestingColumn',
  zScoreHighlight = 'zScoreHighlight',
}

export interface HeatmapTile {
  color: string;
  label: string;
  background: string;
}

export const HEATMAP_RED = '#fecce6';
export const HEATMAP_GREEN = '#9cd296';

export const HEATMAP_OPTIONS = [
  {
    label: 'Quartiles',
    value: 'heatmapQuartiles',
  },
  {
    label: 'Quintiles',
    value: 'heatmapQuintiles',
  },
  {
    label: 'Customize',
    value: 'heatmap',
  },
];

export const HEATMAP_QUARTILES: HeatmapTile[] = [
  {
    color: 'lightblue',
    label: 'Q1 < 25%',
    background: '#66d6f3',
  },
  {
    color: 'green',
    label: 'Q2 (25-50%)',
    background: HEATMAP_GREEN,
  },
  {
    color: 'yellow',
    label: 'Q3 (50-75%)',
    background: '#ffeb98',
  },
  {
    color: 'red',
    label: 'Q4 > 75%',
    background: HEATMAP_RED,
  },
];

export const HEATMAP_QUINTILES: HeatmapTile[] = [
  {
    color: 'lightblue',
    label: 'Q1 < 20%',
    background: '#66d6f3',
  },
  {
    color: 'blue',
    label: 'Q2 (20-40%)',
    background: '#6698ff',
  },
  {
    color: 'green',
    label: 'Q3 (40-60%)',
    background: HEATMAP_GREEN,
  },
  {
    color: 'yellow',
    label: 'Q4 (60-80%)',
    background: '#ffeb98',
  },
  {
    color: 'red',
    label: 'Q5 > 80%',
    background: HEATMAP_RED,
  },
];

export const HEATMAP_CUSTOM: HeatmapTile[] = [
  {
    color: 'red',
    label: 'Index <',
    background: HEATMAP_RED,
  },
  {
    color: 'green',
    label: 'Index >',
    background: HEATMAP_GREEN,
  },
];

export type CellColorId = string;

export type CellColor = string;

export type CellColors = Record<CellColorId, CellColor>;

export type SurveyCode = string;

export type SurveyCodeBackgroundColor = string;

export interface CrossTabRequestCell {
  title: string;
  coding: string | TargetStatement;
}

export interface CrossTabRequestBody {
  surveyCode: string;
  authorizationGroup: string;
  columns: (string | TargetStatement)[];
  rows: (string | TargetStatement)[];
  table: string;
  weightIndex: number;
  adiOptions?: string[];
  failOnMnemonicNotFound: boolean;
  affinityBaseTarget?: string;
  completeCaseMode?: boolean;
  ignoreZeroWeightedResps?: boolean;
}

export interface TrendingRequestBody {
  surveyCodes: string[];
  authorizationGroups: string[];
  columns: (string | TargetStatement)[];
  rows: (string | TargetStatement)[];
  table: string;
  trendingOps: string[];
  weightIndex: number;
  adiOptions?: string[];
  failOnMnemonicNotFound: boolean;
  affinityBaseTarget?: string;
  completeCaseMode?: boolean;
  ignoreZeroWeightedResps?: boolean;
}

export interface StabilityLevels {
  firstLevel: number;
  secondLevel: number;
}

export interface TargetStatement {
  Operator?: string;
  Mne?: string;
  Val?: number;
  Operands?: TargetStatement[];
  Operand1?: TargetStatement;
  Operand2?: TargetStatement;
}

export interface CrossTabTargetRequestBody {
  surveyCode: string;
  codingStatementJson: TargetStatement;
  weightIndex: number;
}

export interface RawTargetResponse {
  success: boolean;
  proj: number;
  sample: number;
  validPerc: number;
  adis?: number[];
}

export interface RawMaxCell {
  proj: number;
  sample: number;
  stability: number;
  validPerc: number;
  index: number;
  rowindex: number;
  colindex: number;
  affinityScore: number;
  adis?: number[];
}

export interface AdditionalOptionToAdditionalIndexData {
  [key: string]: number;
}

export interface RawCrossTabulationResponse {
  success?: boolean;
  message?: string;
  matrix: RawMaxCell[][];
  chunkTokens?: string[];
  adiOptionToAdiIndex?: AdditionalOptionToAdditionalIndexData;
  firstRow?: number;
  endRow?: number;
}

export interface RawSurveyMatrixData {
  op: string;
  matrix: RawMaxCell[][];
  adiOptionToAdiIndex?: AdditionalOptionToAdditionalIndexData;
}

export interface RawTrendingResponse {
  success?: boolean;
  message?: string;
  data: RawSurveyMatrixData[];
  chunkTokenLists?: string[][];
  adiOptionToAdiIndex?: AdditionalOptionToAdditionalIndexData;
  firstRow?: number;
  endRow?: number;
}

export interface StabilityLevelsResponse {
  firstLevel: number;
  secondLevel: number;
  success: boolean;
}

export interface GetReportUnitsResponse {
  reportUnits: number;
  message: string;
  success: boolean;
}

export interface SurveyMetaData {
  weights: SurveyMetaDataWeights[];
  geoJson?: string;
}

export interface SurveyMetaDataWeights {
  index: number;
  description: string;
}

export interface GetSurveyMetaDataResponse {
  surveyCode?: string;
  reportUnitText?: string;
  success: boolean;
  message: string;
  weightsDescription: SurveyMetaDataWeights[];
  weightsCount: number;
  reportUnits: number;
  respondentCount: number;
  firstLevelStability: number;
  secondLevelStability: number;
  geoJson?: string;
}

export interface CrossTabTableDataMetaData {
  hasVolumetricCoding?: boolean;
}

export interface CrossTabTableData {
  position: number;
  rowIndex: number;
  index: number;
  title: string;
  isTotalRow: boolean;
  isPlaceholder?: boolean;
  data: CrossTabTableDataCell[];
  filteredOut?: boolean;
  metadata?: CrossTabTableDataMetaData;
}

export interface CrossTabTableDataCellMetaData {
  isVolumetricCoding?: boolean;
}

export interface CrossTabTableDataCell {
  isTotalsColumn?: boolean;
  columnPosition?: number;
  rowIndex?: number;
  surveyCode?: string;
  type: 'insight' | 'target';
  title?: string;
  projected: number | null;
  sample: number | null;
  stability: number | null;
  validPerc: number | null;
  row: number | null;
  column: number | null;
  index: number | null;
  columnTarget?: Target;
  rowTarget?: Target;
  affinityScore?: number;
  affinityRank?: number;
  CORRELATION_PP?: number;
  CORRELATION_PL?: number;
  FILTER_PROJECTION?: number;
  FILTER_SAMPLE?: number;
  FILTER_PERCENTAGE_COLUMN_PROJECTION?: number;
  FILTER_PERCENTAGE_ROW_PROJECTION?: number;
  FILTER_INDEX?: number;
  FILTER_BASE_PERCENTAGE?: number;
  FILTER_ZSCORE?: number;
  FILTER_MARKET_SIZE?: number;
  FILTER_COLUMN_SAMPLE?: number;
  FILTER_ROW_SAMPLE?: number;
  projectedRank?: number;
  columnRank?: number;
  rowRank?: number;
  indexRank?: number;
  isAffinityRow?: boolean;
  color?: string;
  surveyColor?: string;
  filteredOutCell?: boolean;
  metadata?: CrossTabTableDataCellMetaData;
}

export interface TargetColumn {
  columnId: string;
  name: string;
  target?: Target;
  title: string;
  position: number;
  element: CrossTabTableDataCell;
}

export const DEFAULT_CROSSTAB_COLUMN_POSITIONS = [
  'position-position',
  'position-insight',
  'position-dataPoints',
];

export const DEFAULT_CROSSTAB_COLUMNS = ['position', 'insight', 'dataPoints'];

export interface DropzoneEnterData {
  trigger: MatMenuTrigger;
  targetType: TargetType;
  targetItem: TargetItem;
}

export enum DropzoneMenuType {
  table = 0,
  button = 1,
}

export enum MenuEventType {
  context = 0,
  columnHeader = 1,
}

export interface MenuEventTrigger {
  event: MouseEvent;
  targetItem?: TargetItem;
  surveyCode?: string;
}

export interface ColumnFilterClick {
  targetItem: TargetItem;
  surveyCode?: string;
}

export enum SortDirection {
  none = '',
  asc = 'asc',
  desc = 'desc',
}

export const StabilityLevelsColor = {
  green: '#9cd296',
  red: '#ff9686',
  orange: '#ffb374',
  white: '',
};

export const StabilityLevelsProbs = {
  percent99: 2.576,
  percent95: 1.96,
  percent90: 1.645,
};

export const Z_SCORE_FILTERED_HIGHLIGHT_PROB = 1.96;

export interface ColumnHeaderFilter {
  columnId: string;
  columnTarget: Target;
  filters: ColumnFilter[];
  tooltip?: string;
  filterConditions?: string;
  filterSingleColumn?: boolean;
  applyGlobally?: boolean;
}

export interface ColumnFilter {
  operator: 'AND' | 'OR';
  dataItem: DataItemType;
  conditionalOperator: ColumnFilterOperator;
  value: number[];
}

export enum ColumnFilterOperator {
  equal = '=',
  greaterThan = '>',
  greaterThanOrEqual = '>=',
  lessThan = '<',
  lessThanOrEqual = '<=',
  between = 'Is Between',
}

export interface SortSettings {
  topRows: number;
  bottomRows: number;
  showTopOrBottomRows: 'top' | 'bottom' | '';
  survey?: string;
  columnId: '' | 'totals' | string;
  dataItem: DataItemType;
  startingRow: number;
  order: SortDirection;
  setAsDefault: boolean;
}

export const DEFAULT_SORT_OPTIONS: SortSettings = {
  topRows: 10,
  bottomRows: 10,
  showTopOrBottomRows: '',
  columnId: '',
  dataItem: DataItemType.audience,
  startingRow: 1,
  order: SortDirection.asc,
  setAsDefault: false,
};

export interface FreezeTotals {
  column: boolean;
  row: boolean;
}

export const TITLE_MODES = [
  {
    name: 'Short title',
    displayType: DisplayType.shortTitle,
  },
  {
    name: 'Long title',
    displayType: DisplayType.title,
  },
  {
    name: 'User title',
    displayType: DisplayType.ownTitle,
  },
  {
    name: 'Data context',
    displayType: DisplayType.levels,
  },
];

export const DEFAULT_CROSSTAB_NAME = 'New crosstab';

export const DEFAULT_QUICK_REPORT_NAME = 'New quick report';

export const CELL_LINE_HEIGHT = 15;

export const CELL_LINE_MARGIN_BOTTOM = 5;

export const CELL_PADDING_TOP = 8;

export const CELL_PADDING_BOTTOM = 3;

export const CELL_BORDER_HEIGHT = 1;

export enum ReportMode {
  crossTab = 0,
  affinity = 1,
  separatedRank = 2,
  combinedRank = 3,
}

export enum ReportHighlightType {
  table = 0,
  rows = 1,
  columns = 2,
}

export interface HighlightValues {
  chosenColor: string;
  colors: string[];
  dataItemId: DataItemId;
  type?: ReportHighlightType;
}

export interface HeatmapValues {
  heatmapIndexPercentage: number;
  heatmapQuartiles: HeatmapTile[];
  heatmapQuintiles: HeatmapTile[];
  heatmap: HeatmapTile[];
}

export interface CrosstabSearchHit {
  rowId: string;
  focus: boolean;
  type: 'insight' | 'target';
}

export interface CrosstabSearchInput {
  rows: any[];
  columns: any[];
}

export interface CrosstabSearchLimitSettings {
  limitToRows: boolean;
  limitToColumns: boolean;
}

export const DEFAULT_INDEX_PERCENTAGE = 20;

export const DEFAULT_CROSSTAB_CHUNKED_RETRIES = 7;

export interface IgnoreZeroWeightedResps {
  editable: boolean;
  enabled: boolean;
}
