import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, isEqual } from 'lodash';
import {
  AudienceEffectsSettings,
  AudienceEffectsViewType,
  CHART_COLORS,
  CriteriaAction,
  CriteriaDataItem,
  CriteriaItem,
  GainPlotChartType,
  SelectedGainAnalysisMode,
  SelectedResultMode,
  SelectMenuOption,
  TargetColumnDataItemMap,
} from '../../models';
import { ColorPickerService } from '../../services/color-picker.service';
import { MatTableDataSource } from '@angular/material/table';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { isInvalidNoneNegativeNumber } from '../../utils/validationHelper';

@Component({
  templateUrl: './audience-effects-settings-dialog.component.html',
  styleUrls: ['./audience-effects-settings-dialog.component.scss'],
})
export class AudienceEffectsSettingsDialogComponent
  implements OnInit, OnDestroy
{
  public audienceEffectsViewType: typeof AudienceEffectsViewType =
    AudienceEffectsViewType;
  public audienceEffectsResultMode: typeof SelectedResultMode =
    SelectedResultMode;
  public gainPlotChartType: typeof GainPlotChartType = GainPlotChartType;
  public viewType: AudienceEffectsViewType;
  public resultMode: SelectedResultMode;
  public gainAnalysisMode: SelectedGainAnalysisMode;
  private originalSettings: AudienceEffectsSettings;
  public settings: AudienceEffectsSettings;
  public shouldShowChartSetup = true;
  public shouldShowHighlightColor = true;
  public shouldShowChartConfigForVariableSelection = true;
  public shouldShowGainPlotConfig = true;
  public readonly dataItems: SelectMenuOption<CriteriaDataItem>[] = [
    {
      value: CriteriaDataItem.sample,
      title: 'Sample',
    },
    {
      value: CriteriaDataItem.audience,
      title: 'Audience',
    },
    {
      value: CriteriaDataItem.index,
      title: 'Index',
    },
    {
      value: CriteriaDataItem.percentage,
      title: 'Percentage',
    },
  ];
  public readonly actions: SelectMenuOption<CriteriaAction>[] = [
    {
      value: CriteriaAction.min,
      title: 'Minimize',
    },
    {
      value: CriteriaAction.max,
      title: 'Maximize',
    },
  ];
  public readonly gainPlotChartTypes: SelectMenuOption<GainPlotChartType>[] = [
    {
      value: GainPlotChartType.scatter,
      title: 'Scatter chart',
    },
    {
      value: GainPlotChartType.bar,
      title: 'Bar chart',
    },
  ];

  public displayedColumns: string[] = [
    'objective',
    'dataItem',
    'minObserved',
    'maxObserved',
    'minCutoff',
    'maxCutoff',
    'tolerance',
    'action',
  ];
  public dataSource: MatTableDataSource<CriteriaItem> = new MatTableDataSource(
    []
  );

  public readonly placeholderColor = '#f65354';
  public colors: {
    color: string;
    selected: boolean;
  }[];

  private targetMinMaxDataMap: TargetColumnDataItemMap;

  constructor(
    public dialogRef: MatDialogRef<AudienceEffectsSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private colorPicker: ColorPickerService,
    private userMessageService: TupUserMessageService
  ) {
    this.viewType = data.viewType;
    this.resultMode = data.resultMode;
    this.gainAnalysisMode = data.gainAnalysisMode;
    this.shouldShowGainPlotConfig =
      this.viewType === AudienceEffectsViewType.results &&
      this.resultMode === SelectedResultMode.gain &&
      this.gainAnalysisMode === SelectedGainAnalysisMode.chart;
    this.shouldShowChartSetup =
      this.viewType === AudienceEffectsViewType.variable ||
      this.resultMode === SelectedResultMode.gain;
    this.shouldShowHighlightColor =
      this.viewType === AudienceEffectsViewType.results &&
      this.resultMode === SelectedResultMode.gain;
    this.shouldShowChartConfigForVariableSelection =
      this.viewType === AudienceEffectsViewType.variable;
    this.originalSettings = data.settings;
    this.settings = cloneDeep(data.settings);
    this.formatColors();
    this.targetMinMaxDataMap = data.targetMinMaxDataMap;
    this.setupTable(this.settings.criteria);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public onDataItemChange(criteriaItemIndex: number): void {
    const dataItemId = this.settings.criteria[criteriaItemIndex].data_item_id;
    this.settings.criteria[criteriaItemIndex].min_observed =
      this.targetMinMaxDataMap[dataItemId].min;
    this.settings.criteria[criteriaItemIndex].max_observed =
      this.targetMinMaxDataMap[dataItemId].max;
    this.settings.criteria[criteriaItemIndex].lower_cutoff =
      this.targetMinMaxDataMap[dataItemId].min;
    this.settings.criteria[criteriaItemIndex].upper_cutoff =
      this.targetMinMaxDataMap[dataItemId].max;
  }

  public onInputValueChange(criteriaIndex: number, key: string): void {
    if (
      isInvalidNoneNegativeNumber(this.settings.criteria[criteriaIndex][key])
    ) {
      this.settings.criteria[criteriaIndex][key] = 0;
    }
  }

  public onInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  public colorPickerDialog(): void {
    this.colorPicker
      .chartColor({
        target: '',
        colors: undefined,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((color: string) => {
        this.changeColor(this.colors.length - 1, color.toUpperCase());
      });
  }

  public changeColor(colorIndex: number, color: string): void {
    const prevSelectedColorIndex = this.colors.findIndex(
      (colorItem) => colorItem.selected
    );
    this.colors[prevSelectedColorIndex].selected = false;
    this.colors[colorIndex].color = color;
    this.colors[colorIndex].selected = true;
    this.settings.accumulatedHighlightColor = color;
  }

  public onButtonClick(): void {
    const shouldShowAlert =
      this.originalSettings.generateAndIncludeNotVariables !==
        this.settings.generateAndIncludeNotVariables ||
      !isEqual(this.originalSettings.criteria, this.settings.criteria);
    if (shouldShowAlert) {
      this.userMessageService
        .openDialog(
          'Changing these values will recalculate the recommended variables',
          'Warning',
          {
            cancelText: 'Cancel',
            confirmText: 'OK',
          }
        )
        .afterClosed()
        .subscribe((result: boolean | undefined) => {
          if (result) {
            this.dialogRef.close(this.settings);
          }
        });
      return;
    }

    this.dialogRef.close(this.settings);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  private formatColors(): void {
    const colors: string[] = CHART_COLORS.slice(0, 6);
    const highlightColor = this.settings.accumulatedHighlightColor;
    const isDefaultColor = colors.includes(highlightColor);
    this.colors = colors
      .map((color: string) => ({
        selected: highlightColor === color,
        color,
      }))
      .concat({
        selected: !(isDefaultColor && highlightColor !== this.placeholderColor),
        color: isDefaultColor ? this.placeholderColor : highlightColor,
      });
  }

  private setupTable(data: CriteriaItem[]): void {
    this.populateTableData(data);
  }

  private populateTableData(data: CriteriaItem[]): void {
    this.dataSource.data = data;
  }
}
