export interface GeoCodingsRequest {
  authorizationGroup: string;
  surveyVersion: string;
  scrollId?: string;
}

export interface GeoCodingsResponse {
  success: boolean;
  message: string;
  childCount: number;
  hits: number;
  scrollId: string;
  children: GeoCodingItem[];
}

export interface GeoCodingItem {
  key: string;
  'data-reference': string;
  geoData: string;
}

export type GeoCodingReference = string;
export type GeoCodingData = string;

export type GeoCodingMap = Record<GeoCodingReference, GeoCodingData>;

export const NO_HEATMAP_COLOR = '#EEEEEE';
export const HEATMAP_RED = '#fecce6';
export const HEATMAP_GREEN = '#9cd296';

export const HEATMAP_QUARTILES: HeatmapTile[] = [
  {
    color: 'lightblue',
    label: 'Q1 < 25%',
    background: '#66d6f3',
  },
  {
    color: 'green',
    label: 'Q2 (25-50%)',
    background: HEATMAP_GREEN,
  },
  {
    color: 'yellow',
    label: 'Q3 (50-75%)',
    background: '#ffeb98',
  },
  {
    color: 'red',
    label: 'Q4 > 75%',
    background: HEATMAP_RED,
  },
];

export const HEATMAP_QUINTILES: HeatmapTile[] = [
  {
    color: 'lightblue',
    label: 'Q1 < 20%',
    background: '#66d6f3',
  },
  {
    color: 'blue',
    label: 'Q2 (20-40%)',
    background: '#6698ff',
  },
  {
    color: 'green',
    label: 'Q3 (40-60%)',
    background: HEATMAP_GREEN,
  },
  {
    color: 'yellow',
    label: 'Q4 (60-80%)',
    background: '#ffeb98',
  },
  {
    color: 'red',
    label: 'Q5 > 80%',
    background: HEATMAP_RED,
  },
];

export interface GeoSeriesDataItem {
  key: string;
  value: number;
}

export interface HeatmapTile {
  color: string;
  label: string;
  background: string;
}

export enum HEATMAP_OPTIONS {
  quartiles,
  quintiles,
}
export const HEATMAP_OPTION_ITEMS = [
  {
    value: HEATMAP_OPTIONS.quartiles,
    label: 'Quartiles',
    colors: HEATMAP_QUARTILES,
  },
  {
    value: HEATMAP_OPTIONS.quintiles,
    label: 'Quintiles',
    colors: HEATMAP_QUINTILES,
  },
];
