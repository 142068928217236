<div class="cross-tab-content">
  <tup-mat-rightsidebar
    fxFlex
    [closeButton]="true"
    [(opened)]="rightSidebarOpened"
  >
    <tup-mat-rightsidebar-menuitem
      menuName="Crosstab setup"
      matIconName="table_view"
      *ngIf="+selectedTab === +viewType.crossTab"
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-title-mode"
          mat-button
          tupSearchableItem
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="titleModeMenu"
          *ngIf="
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
        >
          Title mode
        </button>
        <mat-menu
          #titleModeMenu="matMenu"
          class="mat-menus-rhs"
        >
          <div
            fxLayout="column"
            fxLayoutGap="5px"
            class="menu-content-popup"
            data-tracking="title-mode-radiogroup"
          >
            <mat-radio-group
              [(ngModel)]="titleMode"
              fxLayout="column"
              fxLayoutGap="5px"
              (ngModelChange)="selectTitleMode()"
            >
              <mat-radio-button
                *ngFor="let mode of titleModes"
                [value]="mode.displayType"
                (click)="!mode.disabled && clickTitleMode(mode.displayType)"
                [disabled]="mode.disabled"
                >{{ mode.name }}</mat-radio-button
              >
            </mat-radio-group>
            <button
              mat-flat-button
              color="primary"
              tupMatButton
              class="menu-flat-button"
              (click)="resetTitleMode()"
            >
              Reset
            </button>
          </div>
        </mat-menu>
        <button
          data-tracking="menu-data-item"
          mat-button
          tupSearchableItem
          (click)="openDataItemsDialog()"
        >
          Data items
        </button>
        <button
          data-tracking="menu-manage-report-item"
          mat-button
          tupSearchableItem
          *ngIf="!isReadonly"
          (click)="openManageReportItemsDialog()"
        >
          Manage report items
        </button>
        <button
          data-tracking="menu-data-context"
          mat-button
          tupSearchableItem
          *ngIf="
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          (click)="openTitleLevelsDialog()"
          [disabled]="!hasRowsAndColumns"
        >
          Data context
        </button>
        <button
          data-tracking="menu-swap"
          mat-button
          tupSearchableItem
          *ngIf="
            activeReportMode !== reportMode.affinity &&
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          (click)="swapRowsAndColumns()"
          [matTooltip]="swapActionWarning"
        >
          Swap rows/columns
        </button>
        <button
          data-tracking="menu-ntile"
          mat-button
          tupSearchableItem
          *ngIf="
            activeReportMode !== reportMode.affinity &&
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          (click)="openNTileSettingsDialog()"
        >
          N-Tiles
        </button>
      </div>
    </tup-mat-rightsidebar-menuitem>

    <tup-mat-rightsidebar-menuitem
      menuName="Crosstab formatting"
      matIconName="backup_table"
      *ngIf="+selectedTab === +viewType.crossTab"
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-highlight"
          mat-button
          tupSearchableItem
          (click)="openHighlightDialog()"
        >
          Highlight values
        </button>
        <button
          data-tracking="menu-heatmap"
          mat-button
          tupSearchableItem
          (click)="openHeatmapDialog()"
        >
          Heatmap
        </button>
        <button
          *ngIf="selectedSurvey?.provider === surveyProvider.youGov"
          mat-button
          tupSearchableItem="Z-score filtered highlight"
          (click)="toggleZScoreHighlight()"
          data-tracking="menu-zscore-filtered-highlight"
        >
          Z-score filtered highlight
          <mat-icon class="formatting-switch-flag-icon">{{
            zScoreHighlight ? "toggle_on" : "toggle_off"
          }}</mat-icon>
        </button>
        <button
          mat-button
          *ngIf="selectedSurvey?.provider === surveyProvider.youGov"
          [matTooltip]="
            completeCasesOn
              ? 'Complete cases mode is on'
              : 'Complete cases mode is off'
          "
          tupSearchableItem="Complete cases"
          (click)="showCompleteCasesFlag()"
        >
          Complete cases
          <mat-icon class="formatting-switch-flag-icon">{{
            completeCasesOn ? "toggle_on" : "toggle_off"
          }}</mat-icon>
        </button>

        <span
          class="stability-flag"
          matTooltip="The stability flag cannot be turned off, please contact your Telmar representative for more details."
        >
          <button
            mat-button
            tupSearchableItem="Stability flag"
            [disabled]="true"
            (click)="showStabilityFlag()"
          >
            Stability flag
            <mat-icon class="formatting-switch-flag-icon">{{
              stabilityFlagOn ? "toggle_on" : "toggle_off"
            }}</mat-icon>
          </button>
        </span>
        <span
          class="stability-flag"
          [matTooltip]="
            ignoreZeroWeightedRespondents.editable
              ? ''
              : 'Ignore zero weighted respondents is turned on, if you need this turned off please speak to your Telmar representative for more details.'
          "
        >
          <button
            mat-button
            tupSearchableItem="Ignore zero weighted respondents"
            [disabled]="!ignoreZeroWeightedRespondents.editable"
            (click)="showIgnoreZeroWeightedRespondents()"
          >
            Ignore zero weighted respondents
            <mat-icon class="ignore-weighted-respondents-icon">{{
              ignoreZeroWeightedRespondents.enabled ? "toggle_on" : "toggle_off"
            }}</mat-icon>
          </button>
        </span>
        <button
          *ngIf="
            activeReportMode !== reportMode.separatedRank &&
            activeReportMode !== reportMode.combinedRank
          "
          mat-button
          tupSearchableItem
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="significanceTestingMenu"
          data-tracking="menu-significance"
        >
          Significance testing
        </button>
        <mat-menu
          #significanceTestingMenu="matMenu"
          class="mat-menus-rhs"
        >
          <div
            fxLayout="column"
            fxLayoutGap="5px"
            class="menu-content-popup"
          >
            <mat-radio-group
              [(ngModel)]="significanceTestingVar"
              fxLayout="column"
              fxLayoutGap="5px"
              (ngModelChange)="turnOnSignificanceTesting()"
              data-qa="significance-testing-radio-group"
            >
              <mat-radio-button [value]="'column'">Columns</mat-radio-button>
              <mat-radio-button [value]="'row'">Rows</mat-radio-button>
            </mat-radio-group>
          </div>
        </mat-menu>
      </div>
    </tup-mat-rightsidebar-menuitem>

    <tup-mat-rightsidebar-menuitem
      menuName="Custom audiences/media"
      matIconName="bookmark"
      *ngIf="!isReadonly"
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-manage-custom-audience"
          mat-button
          tupSearchableItem
          (click)="openCustomAudienceManager()"
        >
          Manage custom audiences
        </button>
        <button
          *ngIf="!isReadonly && ('CrossMediaPlanner' | hasPermission)"
          data-tracking="menu-send-to-plan"
          mat-button
          tupSearchableItem
          (click)="sendToPlan()"
          [disabled]="!selectedSurvey.hasVehicles || !hasRowsAndColumns"
          [matTooltip]="
            !selectedSurvey.hasVehicles
              ? 'This survey is not compatible with Plan'
              : !hasRowsAndColumns
              ? 'No data available for Plan. Please add some columns and rows in the crosstab'
              : ''
          "
        >
          Send audiences to Plan
        </button>
        <button
          data-tracking="menu-save-audience"
          mat-button
          tupSearchableItem
          (click)="saveOwnCodes(saveOwnCodesType.audience)"
        >
          Save audiences
        </button>
        <button
          mat-button
          tupSearchableItem
          (click)="saveOwnCodes(saveOwnCodesType.media)"
          [disabled]="true"
        >
          Save media
        </button>
      </div>
    </tup-mat-rightsidebar-menuitem>

    <tup-mat-rightsidebar-menuitem
      menuName="Snapshots"
      matIconName="add_a_photo"
      *ngIf="
        !isReadonly &&
        currentDocument.content.snapshots &&
        currentDocument.content.snapshots.length
      "
    >
      <div
        class="menu-content"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5px"
      >
        <button
          data-tracking="menu-manage-custom-audience"
          mat-button
          tupSearchableItem
          (click)="openSnapshotManager()"
        >
          Snapshot history
        </button>
      </div>
    </tup-mat-rightsidebar-menuitem>
    <app-main>
      <router-outlet></router-outlet>
    </app-main>
  </tup-mat-rightsidebar>
</div>

<div
  class="spinner-container-overlay"
  *ngIf="isLoading"
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
