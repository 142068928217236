<div
  class="manage-custom-audiences"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="false"
>
  <tup-mat-title-bar
    title="Manage custom audiences"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <div mat-dialog-content>
    <tup-mat-content-wrapper
      position="middle"
      gap="2"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap=".5rem"
      >
        <mat-card
          class="header-button"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span>
            {{ documents?.dataSource.length || 0 }}
          </span>
          Custom audiences
        </mat-card>

        <tup-document-filter
          formFieldAppearance="outline"
          formFieldLabel="Search custom audiences"
          [filterFor]="documents"
          fxFlex="1 1 auto"
        ></tup-document-filter>

        <button
          class="import-button header-button"
          mat-flat-button
          tupMatButton
          appearance="small"
          color="primary"
          (click)="fileInput.click($event)"
        >
          <mat-icon>file_upload</mat-icon>
          Import local file
        </button>

        <input
          type="file"
          accept=".json"
          hidden
          #fileInput
          onclick="this.value = null"
          (change)="import($event)"
        />
      </div>
    </tup-mat-content-wrapper>
    <tup-mat-content-wrapper
      position="last"
      gap="2"
      tupDocumentEventEmitter
      tupDocumentEventHandler
      customDocumentEventHandler
      documentType="Custom audiences"
    >
      <mat-divider class="divider-bottom"></mat-divider>
      <tup-document-storage
        [options]="options"
        class="document-storage-table"
        #documents
      ></tup-document-storage>
    </tup-mat-content-wrapper>

    <div
      class="spinner-container-overlay"
      *ngIf="isLoading"
    >
      <div class="center-spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>
