import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UserContainer } from '@telmar-global/tup-auth';
import {
  TupTagEditorComponent,
  TupUserContainerSelectorComponent,
} from '@telmar-global/tup-document-storage';

const DEFAULT_NEW_REPORT_NAME = 'New report';

export interface NameReportDialogDataModel {
  isEditMode: boolean;
  name: string;
  description: string;
}

export interface NameReportDialogResult {
  container: UserContainer;
  name: string;
  description: string;
}

@Component({
  templateUrl: './name-report-dialog.component.html',
  styleUrls: ['./name-report-dialog.component.scss'],
})
export class NameReportDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;

  public container: UserContainer;
  public isTemplateDocType = false;

  @ViewChild(TupTagEditorComponent, { static: true })
  public tagEditor: TupTagEditorComponent;

  public formGroup: FormGroup;

  public get disabled(): boolean {
    return this.formGroup.invalid || !this.formGroup.dirty;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NameReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NameReportDialogDataModel
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: new FormControl(this.data.name, Validators.required),
      description: new FormControl(this.data.description),
    });
  }

  public ngAfterViewInit(): void {
    this.container = this.userContainerSelector.container;
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public saveAs(skip: boolean): void {
    const dialogResult: NameReportDialogResult = {
      container: this.container,
      ...(skip
        ? { name: DEFAULT_NEW_REPORT_NAME, description: '' }
        : this.formGroup.value),
    };

    this.dialogRef.close(dialogResult);
  }

  public onEnter(): void {
    if (this.formGroup.value.name.length >= 1) {
      this.saveAs(false);
    }
  }
}
