import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageCustomAudiencesService {
  private manageCustomAudiencesModeOn = new BehaviorSubject<boolean>(false);
  public manageCustomAudiencesModeOn$ =
    this.manageCustomAudiencesModeOn.asObservable();

  public manageCustomAudiencesModeChange(modeOn: boolean): void {
    this.manageCustomAudiencesModeOn.next(modeOn);
  }
}
