<div class="dialog-content-container">
  <tup-mat-title-bar
    title="Heatmap"
    class="dialog-title"
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
        data-qa="heatmap-select"
      >
        <mat-label>Heatmap options</mat-label>
        <mat-select [(ngModel)]="selectedHeatmapOption">
          <mat-option
            *ngFor="let option of HEATMAP_OPTIONS"
            [value]="option.value"
            data-qa="heatmap-select-option"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="selectedHeatmapOption === 'heatmapQuartiles'">
      <div
        fxLayout="row"
        fxLayoutGap="0.5rem"
        fxLayoutAlign="start center"
        class="heatmap-info"
      >
        <span>Index: </span>
        <span
          *ngFor="let tile of heatmapValues.heatmapQuartiles; let i = index"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span
            class="dot"
            [style.background]="tile.background"
            (click)="colorPickerDialog(selectedHeatmapOption, i)"
          >
            <mat-icon class="tiny-icon show-on-hover">add</mat-icon>
          </span>
          {{ tile.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedHeatmapOption === 'heatmapQuintiles'">
      <div
        fxLayout="row"
        fxLayoutGap="0.5rem"
        fxLayoutAlign="start center"
        class="heatmap-info"
      >
        <span>Index: </span>
        <span
          *ngFor="let tile of heatmapValues.heatmapQuintiles; let i = index"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <span
            class="dot"
            [style.background]="tile.background"
            (click)="colorPickerDialog(selectedHeatmapOption, i)"
          >
            <mat-icon class="tiny-icon show-on-hover">add</mat-icon>
          </span>
          {{ tile.label }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedHeatmapOption === 'heatmap'">
      <mat-slider
        color="primary"
        [max]="100"
        [min]="5"
        [step]="1"
        [(ngModel)]="heatmapValues.heatmapIndexPercentage"
        [thumbLabel]="true"
      >
      </mat-slider>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="slider-labels"
      >
        <label>Index percentage</label>
        <div class="index-percentage-value-container">
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field-without-label index-percentage-form-field"
          >
            <input
              matInput
              type="number"
              min="min"
              max="max"
              (change)="onIndexPercentageInputChange()"
              [(ngModel)]="heatmapValues.heatmapIndexPercentage"
              (keydown)="onIndexPercentageInputKeydown($event)"
              (keyup)="onIndexPercentageInputKeyup()"
            />
          </mat-form-field>
          <span class="index-percentage-label">%</span>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="2rem"
        class="heatmap-info"
      >
        <span
          fxLayout="row"
          fxLayoutAlign="center center"
          ><span
            class="dot"
            [style.background]="heatmapValues.heatmap[0].background"
            (click)="colorPickerDialog(selectedHeatmapOption, 0)"
          >
            <mat-icon class="tiny-icon show-on-hover">add</mat-icon>
          </span>
          Index value < {{ 100 - heatmapValues.heatmapIndexPercentage }}</span
        >
        <span
          fxLayout="row"
          fxLayoutAlign="center center"
          ><span
            class="dot"
            [style.background]="heatmapValues.heatmap[1].background"
            (click)="colorPickerDialog(selectedHeatmapOption, 1)"
          >
            <mat-icon class="tiny-icon show-on-hover">add</mat-icon>
          </span>
          Index value > {{ 100 + heatmapValues.heatmapIndexPercentage }}</span
        >
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>

    <div>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        cdkFocusRegionstart
        (click)="onResetClick()"
        data-qa="reset-heatmap-button"
      >
        Reset
      </button>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        cdkFocusRegionstart
        (click)="onApplyClick()"
        data-qa="apply-heatmap-button"
      >
        Apply
      </button>
    </div>
  </mat-dialog-actions>
</div>
