import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UserContainer } from '@telmar-global/tup-auth';
import { TupUserContainerSelectorComponent } from '@telmar-global/tup-document-storage';
import { ClustersSolutionType, Survey } from 'src/app/models';

export interface SaveClusterCodesDialogData {
  clustersSolutionTypeList: ClustersSolutionType[];
  selectedClustersSolutionNumber: number;
  title: string;
  survey: Survey;
}

export interface SaveClusterCodesDialogResult {
  container: UserContainer;
  name: string;
  selectedSolution: number;
  saveVariables: boolean;
}

@Component({
  templateUrl: './save-cluster-codes-dialog.component.html',
  styleUrls: ['./save-cluster-codes-dialog.component.scss'],
})
export class SaveClusterCodesDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;

  public clustersSolutionTypeList: ClustersSolutionType[];
  public selectedClustersSolutionNumber: number;
  public survey: Survey;
  private title: string;

  public container: UserContainer;
  public formGroup: FormGroup;

  public get disabled(): boolean {
    return this.formGroup.invalid;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SaveClusterCodesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveClusterCodesDialogData
  ) {
    this.clustersSolutionTypeList = data.clustersSolutionTypeList;
    this.selectedClustersSolutionNumber = data.selectedClustersSolutionNumber;
    this.title = data.title;
    this.survey = data.survey;
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: new FormControl(this.title, Validators.required),
      selectedSolution: new FormControl(this.selectedClustersSolutionNumber),
      saveVariables: new FormControl(true),
    });
  }

  public ngAfterViewInit(): void {
    this.container = this.userContainerSelector.container;
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public saveCodes(): void {
    const dialogResult: SaveClusterCodesDialogResult = {
      container: this.container,
      ...this.formGroup.value,
    };

    this.dialogRef.close(dialogResult);
  }

  public onSelectedSolutionChange(): void {}
}
