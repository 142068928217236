import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { MatSort, Sort } from '@angular/material/sort';
import { cloneDeep } from 'lodash';
import { compare } from '../../utils/sortHelper';
import {
  AUDIENCE_EFFECTS_VARIABLE_TYPE_MAP,
  AudienceEffectsVariableTableRowData,
  AudienceEffectsViewType,
  Survey,
  TargetColumn,
  VariableType,
} from '../../models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DocumentService } from 'src/app/services';
import { TargetTitlePipe } from 'src/app/pipes';
import { CrosstabTableXlsxBuilder } from 'src/app/builders/crosstab-table-xlsx.builder';
import { CrosstabTableCsvBuilder } from 'src/app/builders/crosstab-table-csv.builder';
import { CsvService } from 'src/app/services/csv.service';

@Component({
  selector: 'app-audience-effects-variable-table',
  templateUrl: './audience-effects-variable-selection-table.component.html',
  styleUrls: ['./audience-effects-variable-selection-table.component.scss'],
})
export class AudienceEffectsVariableSelectionTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() survey: Survey;
  @Input() activeTableTitle: string;
  @Input() totalPopulation: number;
  @Input() totalSample: number;
  @Input() tableData: AudienceEffectsVariableTableRowData[];
  @Input() hoveringRow: AudienceEffectsVariableTableRowData;
  @Output()
  tableRowChange: EventEmitter<AudienceEffectsVariableTableRowData> = new EventEmitter<AudienceEffectsVariableTableRowData>();
  @Output()
  tableRowsChange: EventEmitter<AudienceEffectsVariableTableRowData[]> =
    new EventEmitter<AudienceEffectsVariableTableRowData[]>();
  @Output()
  tableRowMouseEnter: EventEmitter<AudienceEffectsVariableTableRowData> = new EventEmitter<AudienceEffectsVariableTableRowData>();
  @Output()
  tableRowMouseLeave: EventEmitter<AudienceEffectsVariableTableRowData> = new EventEmitter<AudienceEffectsVariableTableRowData>();
  @ViewChild('tableCardContainer') tableCardContainer: ElementRef;
  public scrollViewportHeight: number = 0;
  public readonly variableTypeMap = AUDIENCE_EFFECTS_VARIABLE_TYPE_MAP;

  public readonly columnMap = {
    recommended: {
      name: '',
      align: 'center',
      sortable: false,
    },
    rowNumber: {
      name: '',
      align: 'center',
      sortable: false,
    },
    title: {
      name: 'Selected target(s)',
      align: 'left',
      sortable: true,
    },
    y: {
      name: 'Targets',
      align: 'right',
      sortable: true,
    },
    x: {
      name: 'Index',
      align: 'right',
      sortable: true,
    },
  };
  private readonly defaultColumns = [
    'recommended',
    'rowNumber',
    'title',
    'y',
    'x',
  ];
  public displayedColumns: string[] = [...this.defaultColumns];

  public dataSource: MatTableDataSource<AudienceEffectsVariableTableRowData> =
    new TableVirtualScrollDataSource([]);

  private unsubscribe: Subject<void> = new Subject<void>();
  public targetColumns: TargetColumn[] = [];

  public selectedRows = 0;
  public selectedRowVariables: Record<string, boolean> = {};

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private documentService: DocumentService,
    private csvService: CsvService,
    private targetTitlePipe: TargetTitlePipe
  ) {}

  @HostListener('window:resize')
  public onResize() {
    this.updateScrollViewportHeight();
  }
  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData) {
      this.setupTable(cloneDeep(this.tableData));
      this.sortData(this.sort);
    }

    this.triggerFakeWindowResizeEvent();
  }

  public ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  public isAllSelected() {
    return this.selectedRows === this.dataSource.data.length;
  }

  public onAllRowsSelectedChange(event: MatCheckboxChange): void {
    this.setAllRowsSelected(event.checked);
    this.tableRowsChange.emit(this.dataSource.data);
  }

  public onSingleRowSelectedChange(
    event: MatCheckboxChange,
    element: AudienceEffectsVariableTableRowData
  ): void {
    this.selectedRowVariables[element.coding] = event.checked;
    this.updateSelectedRows();
    this.updateRowState(element, event.checked);
    this.tableRowChange.emit(element);
    this.sortData(this.sort);
  }

  public onRowMouseEnter(element: AudienceEffectsVariableTableRowData): void {
    this.tableRowMouseEnter.emit(element);
  }

  public onRowMouseLeave(element: AudienceEffectsVariableTableRowData): void {
    this.tableRowMouseLeave.emit(element);
  }

  public sortData(sort: Sort) {
    let sortedData = cloneDeep(this.tableData);
    let dataKey = 'rowNumber';
    let isAsc = true;
    if (!(!sort.active || sort.direction === '')) {
      dataKey = sort.active;
      isAsc = sort.direction === 'asc';
    }
    sortedData = sortedData.sort((a, b) =>
      compare(a[dataKey], b[dataKey], isAsc)
    );

    const { recommended, userSelected, variables } = sortedData.reduce(
      (acc, value) => {
        if (value.type === VariableType.recommended) {
          acc.recommended.push(value);
        } else if (value.type === VariableType.useSelected) {
          acc.userSelected.push(value);
        } else {
          acc.variables.push(value);
        }
        return acc;
      },
      {
        recommended: [],
        userSelected: [],
        variables: [],
      }
    );

    this.populateTableData([...recommended, ...userSelected, ...variables]);
  }

  private setupTable(data: AudienceEffectsVariableTableRowData[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.selectRecommendedVariables(data);
  }

  private populateTableData(data: AudienceEffectsVariableTableRowData[]): void {
    this.dataSource.data = data;
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.displayedColumns = [...this.defaultColumns];
  }

  private selectRecommendedVariables(
    data: AudienceEffectsVariableTableRowData[]
  ): void {
    data.forEach((row: AudienceEffectsVariableTableRowData) => {
      this.selectedRowVariables[row.coding] = row.recommended;
    });
    this.updateSelectedRows();
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setAllRowsSelected(isSelected: boolean): void {
    this.dataSource.data.forEach((row: AudienceEffectsVariableTableRowData) => {
      this.selectedRowVariables[row.coding] = isSelected;
      this.updateRowState(row, isSelected);
    });
    this.updateSelectedRows();
  }

  private updateSelectedRows(): void {
    this.selectedRows = Object.keys(this.selectedRowVariables).filter(
      (key: string) => this.selectedRowVariables[key] === true
    ).length;
  }

  private updateRowState(
    element: AudienceEffectsVariableTableRowData,
    isChecked: boolean
  ): void {
    if (element.recommended === isChecked) {
      return;
    }
    if (element.originalType === VariableType.recommended) {
      element.type = element.recommended
        ? VariableType.variables
        : VariableType.recommended;
    } else if (element.originalType === VariableType.variables) {
      element.type = element.recommended
        ? VariableType.variables
        : VariableType.useSelected;
    }

    element.recommended = !element.recommended;
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  private updateScrollViewportHeight(): void {
    if (this.tableCardContainer) {
      this.scrollViewportHeight =
        this.tableCardContainer.nativeElement.offsetHeight - 14;
    }
  }

  public exportToXlsx(docName?: string) {
    return this.getXlsxExportData(docName);
  }

  private getXlsxExportData(
    docName?: string
  ): [string, CrosstabTableXlsxBuilder] {
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');
    const crossTabTableData = this.prepareXlsxExportData(docName);
    crossTabTableBuilder.addAudienceEffectsTable(
      crossTabTableData,
      AudienceEffectsViewType.variable
    );
    crossTabTableBuilder.build();

    return [crossTabTableData.documentName, crossTabTableBuilder];
  }

  private prepareXlsxExportData(docName?: string): any {
    return {
      documentName: this.getExportDocumentName(docName),
      data: this.dataSource.data.filter((row) => row.recommended),
      displayedColumns: this.displayedColumns,
      survey: this.survey,
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
      totalPopulation: this.totalPopulation,
      totalSample: this.totalSample,
      activeTableTitle: this.activeTableTitle,
    };
  }

  public exportToCSV(docName?: string) {
    const documentName = docName || this.getExportDocumentName();
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addAudienceEffectsTableData(
      {
        documentName: documentName,
        displayedColumns: this.displayedColumns,
        data: this.dataSource.data.filter((row) => row.recommended),
        survey: this.survey,
        surveyCodeMap: this.documentService.getSurveyCodeMap(),
      },
      AudienceEffectsViewType.variable
    );
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  private getExportDocumentName(docName?: string) {
    return `${
      docName || this.documentService.document.metadata.name
    } - Audience Effects Variable Selection`;
  }
}
