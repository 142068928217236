<div
  class="table-container-quick-reports"
  tupDocumentEventEmitter
  tupDocumentEventHandler
  customDocumentEventHandler
  documentType="quick report"
>
  <tup-document-storage
    [options]="options"
    #myQuickReports
  >
    <ng-template tupDocumentStorageCardContent>
      <ng-container *ngTemplateOutlet="filter"></ng-container>
    </ng-template>

    <ng-template tupDocumentStorageCardNoContent>
      <div
        class="no-search-results"
        *ngIf="myQuickReports.nameFilterService.value; else getStarted"
      >
        <ng-container *ngTemplateOutlet="filter"></ng-container>
        <p>No search results.</p>
      </div>

      <ng-template #getStarted>
        <div class="error-message">
          <h3>
            Get started:
            <span
              (click)="createQuickReport.emit()"
              class="create-report-link"
              >Create a quick report</span
            >
          </h3>
          <p>
            Start crafting your own personalized quick reports. Once available,
            this space will display a neatly organized table of your accessible
            quick reports, making data analysis and decision-making a breeze.
          </p>
        </div>
      </ng-template>
    </ng-template>

    <ng-template
      *ngIf="myQuickReports.dataSource?.length"
      tupDocumentStorageCardActions
    >
      <div
        class="all-reports"
        (click)="navigateToReportsTab.emit(2)"
      >
        See all quick reports ({{ myQuickReports.dataSource.length }})
      </div>
    </ng-template>
  </tup-document-storage>
</div>

<ng-template #filter>
  <tup-document-filter
    data-tracking="report-search"
    formFieldAppearance="outline"
    formFieldLabel="Search reports"
    [filterFor]="myQuickReports"
  >
  </tup-document-filter>
</ng-template>
