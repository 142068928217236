<div
  fxFlex
  fxLayout="column grid"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
  class="split-wrapper"
>
  <ui-split
    unit="pixel"
    direction="horizontal"
    #split="uiSplit"
  >
    <ui-split-area
      [size]="420"
      [minSize]="50"
      [maxSize]="615"
    >
      <app-codebook-navigation
        #codebookNavigation
        fxFlex
        [inCodeBuilder]="isVisualCodeMode"
        [survey]="selectedSurvey"
        [isReadonly]="isReadonly"
        (codebookContextMenu)="onCodebookContextMenu($event)"
        (isSearchingOrFiltering)="
          onCodebookSearchingOrFilteringStateChange($event)
        "
      >
      </app-codebook-navigation>
    </ui-split-area>

    <ui-split-area
      *ngIf="isVisualCodeMode"
      class="split-panel-right"
    >
      <mat-icon
        title="Close panel"
        class="close-icon"
        matTooltip="Close"
        (click)="onButtonClick(false)"
        >close</mat-icon
      >
      <div
        fxLayout="column"
        class="split-panel-right__content"
        fxLayoutAlign="space-between stretch"
      >
        <app-visual-code-builder
          #visualCodeBuilder
          [isLoadingSelectedNodes]="isLoadingSelectedNodes"
          [isLoadingFromDrag]="isLoadingFromDrag"
          [selectedNodes]="selectedNodes"
          [target]="target"
          [targetResult]="targetResult"
          [isResultDirty]="isResultDirty"
          [gettingCodingResult]="gettingCodingResult"
          [codingError]="codingError"
          [createTarget]="createTarget"
          [convertStatementsToTargets]="convertStatementsToTargets"
          [updateTitleAndCoding]="updateTitleAndCoding"
          [groupTargets]="groupTargets"
          [groupTargetsWithAutoOperator]="groupTargetsWithAutoOperator"
          [hasMoreThanMaxCombinedTargets]="hasMoreThanMaxCombinedTargets"
          [showMaxLimitAlert]="showMaxLimitAlert"
          (targetChange)="onTargetChange($event)"
          (dropNode)="onDropNode($event)"
          (unselectNodes)="onUnselectNodes()"
          (manualCodingChange)="onManualCodingMode($event)"
          (syncAudienceSize)="onSyncAudienceSize($event)"
        ></app-visual-code-builder>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="panel-actions"
        >
          <button
            mat-stroked-button
            color="primary"
            appearance="medium"
            tupMatButton
            (click)="onButtonClick(false)"
          >
            Close
          </button>
          <button
            mat-flat-button
            color="primary"
            appearance="medium"
            tupMatButton
            cdkFocusRegionstart
            (click)="onButtonClick(true)"
            [disabled]="!targetUserTitle || (manualCodingMode && codingError)"
            data-qa="visual-code-builder-ok-button"
          >
            Apply
          </button>
        </div>
      </div>
    </ui-split-area>
  </ui-split>
</div>
