<div fxLayout="column">
  <tup-mat-title-bar
    title="Export success"
    class="export-title"
  ></tup-mat-title-bar>

  <div class="export-content">
    Your file has been successfully exported to&nbsp;
    <ng-container *ngIf="exportedFileLink">
      <a
        href="{{ exportedFileLink }}"
        target="_blank"
        class="export-link"
        >{{ exportedFileLink }}</a
      >
    </ng-container>
    <ng-container *ngIf="!exportedFileLink">
      Google Drive <b>explore</b> folder.
    </ng-container>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="end flex-end"
    class="action-buttons"
  >
    <button
      mat-flat-button
      color="primary"
      appearance="large"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      OK
    </button>
  </div>
</div>
