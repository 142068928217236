import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  autoGroupTargetsCallback,
  CodingData,
  CreateTargetCallback,
  DisplayType,
  groupTargetsCallback,
  hasMoreThanMaxCombinedTargetsCallback,
  Operator,
  Statement,
  StatementsToTargetsCallback,
  Target,
  UpdateTitleAndCodingCallback,
} from '../../models';
import { cloneDeep, slice } from 'lodash';
import { Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { CountCodingDialogComponent, CountCodingModel } from '../../dialogs';
import { StatementFactory } from '../tree-view/tree.models';
import { DropDataContext } from '../../pages';
import { TargetTitlePipe } from '../../pipes';

enum DropzoneMenuType {
  single,
  multiple,
}

@Component({
  selector: 'app-visual-code-builder',
  templateUrl: './visual-code-builder.component.html',
  styleUrls: ['./visual-code-builder.component.scss'],
})
export class VisualCodeBuilderComponent
  implements OnChanges, OnInit, OnDestroy
{
  public readonly menuItemDragoverClass = 'drag-over-menu-button';

  public displayText = '';
  public displayCoding = '';

  public displayTypeType: typeof DisplayType = DisplayType;
  public dropzoneMenuType: typeof DropzoneMenuType = DropzoneMenuType;

  public operator: typeof Operator = Operator;
  public manualCodingMode = false;

  @Input() readonly target: Target;
  @Input() codingError: string;
  @Input() isLoadingSelectedNodes: boolean;
  @Input() isLoadingFromDrag: boolean;
  @Input() selectedNodes: Statement[];

  @Input() createTarget: CreateTargetCallback;
  @Input() convertStatementsToTargets: StatementsToTargetsCallback;
  @Input() updateTitleAndCoding: UpdateTitleAndCodingCallback;
  @Input() groupTargets: groupTargetsCallback;
  @Input() groupTargetsWithAutoOperator: autoGroupTargetsCallback;

  @Input() hasMoreThanMaxCombinedTargets: hasMoreThanMaxCombinedTargetsCallback;
  @Input() showMaxLimitAlert: () => void;

  @Output() targetChange = new EventEmitter<Target>();
  @Output() dropNode = new EventEmitter<DropDataContext<Operator>>();
  @Output() unselectNodes = new EventEmitter<any>();
  @Output() manualCodingChange = new EventEmitter<boolean>();
  @Output() syncAudienceSize = new EventEmitter<Target>();
  @Input() targetResult: CodingData;
  @Input() isResultDirty = false;
  @Input() gettingCodingResult = false;
  @Input() decimalPoints: number;

  public rootTarget: Target;
  public targets: Target[] = [];
  public dialogTitleMode: DisplayType;
  public titleDisplayType = DisplayType;
  public title: string;

  public targetOperators: Operator[] = [
    Operator.or,
    Operator.and,
    Operator.andNot,
    Operator.orNot,
    Operator.plus,
    Operator.divide,
    Operator.vind,
    Operator.vdiv,
    Operator.vmul,
    Operator.ampersand,
    Operator.separator,
    Operator.dot,
    Operator.comma,
    Operator.greaterThan,
  ];

  public dropZoneMenuItems: Operator[] = [
    Operator.count,
    Operator.auto,
    Operator.and,
    Operator.or,
    Operator.andNot,
    Operator.plus,
  ];

  public menuType: DropzoneMenuType = DropzoneMenuType.single;
  private dropTarget: Target;
  private dropRowIndex: number;

  private chipOverIntentCount = 0;

  private menuTarget: EventTarget;
  public isDraggingTarget = false;
  public draggingTarget: {
    parentTarget: Target;
    target: Target;
    rowIndex: number;
    columnIndex: number;
    expand: boolean;
  };

  private isOverOperatorMenu = false;
  private isDropzoneMenuOpen = false;
  private activeMenuTrigger: MatMenuTrigger;

  private changesFromWithin = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private targetTitlePipe: TargetTitlePipe
  ) {}

  ngOnInit(): void {
    this.dialogTitleMode = this.target.activeTitleMode || DisplayType.title;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.target && changes.target.currentValue) {
      this.buildTargetList();
      this.updateDisplayText();
      this.changesFromWithin = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.isLoadingSelectedNodes = false;
  }

  public onDisplayTextChange(): void {
    this.rootTarget.activeTitleMode = DisplayType.ownTitle;
    this.rootTarget.ownTitle = this.displayText;
    this.emitTargetChange();
  }

  public isChipRemovable(parentTarget: Target, rowIndex: number): boolean {
    return rowIndex !== 0 || parentTarget.targets.length > 1;
  }

  public formatChipTooltip(target: Target): string {
    const targetCoding = this.targetTitlePipe.codeBuilderTransform(
      target,
      DisplayType.coding,
      this.target.titleLevels
    );
    return this.draggingTarget ? '' : targetCoding;
  }

  public isChipDroppable(
    parentTarget: Target,
    target: Target,
    rowIndex: number
  ): boolean {
    if (this.isLoadingSelectedNodes) {
      return false;
    }

    if (!this.draggingTarget) {
      return true;
    }

    if (
      this.draggingTarget.target === target ||
      this.draggingTarget.target === parentTarget
    ) {
      return false;
    }

    return !(
      this.draggingTarget.expand && this.draggingTarget.rowIndex < rowIndex
    );
  }

  public isEmptyChipDroppable(parentTarget: Target, rowIndex: number): boolean {
    if (this.isLoadingSelectedNodes) {
      return false;
    }

    if (!this.draggingTarget) {
      return true;
    }

    if (this.draggingTarget.target === parentTarget) {
      return false;
    }

    return !(
      rowIndex === 0 &&
      this.draggingTarget.rowIndex === 0 &&
      parentTarget.targets.length < 2
    );
  }

  public onTargetClick(target: Target, rowIndex: number): void {
    if (!this.isTargetExpandable(target)) {
      return;
    }

    const isCurrentTargetExpanded = this.isTargetExpanded(target, rowIndex);
    // the expanded children targets may be from different parent target
    if (this.hasExpandedTarget(rowIndex)) {
      this.collapseChildrenTargets(rowIndex);
    }

    if (!isCurrentTargetExpanded) {
      this.expandTarget(target);
    }
  }

  public onDrop(operator?: Operator) {
    if (this.isLoadingSelectedNodes) {
      return;
    }

    this.dropNode.emit({
      selectedNodes: this.selectedNodes,
      selectedTargets: this.convertStatementsToTargets(this.selectedNodes),
      context: operator,
      handleDropNode: (dropOperator: Operator) =>
        this.handleDropNode(dropOperator),
    });
  }

  public handleDropNode(operator?: Operator) {
    const nodesFromCodeBuilder = this.draggingTarget;
    if (!nodesFromCodeBuilder) {
      const selectedTargets = this.convertStatementsToTargets(
        this.selectedNodes
      );

      if (this.hasMoreThanMaxCombinedTargets(selectedTargets)) {
        this.unsetDraggingState();
        this.showMaxLimitAlert();
        return;
      }
    }

    if (operator !== Operator.count) {
      this.handleDrop(operator);
    } else {
      this.showCountCodingDialog(operator);
    }
  }

  public onRemoveTarget(
    parentTarget: Target,
    target: Target,
    rowIndex: number,
    columnIndex: number,
    shouldRemoveFromParent = true
  ): void {
    this.handleRemoveTarget(
      parentTarget,
      target,
      rowIndex,
      columnIndex,
      shouldRemoveFromParent
    );
    this.updateTargetChange();
  }

  public onRemoveCountCoding(target: Target): void {
    target.countCoding = undefined;
    this.updateTargetChange();
  }

  public isTargetExpanded(target: Target, rowIndex: number): boolean {
    return (
      this.hasExpandedTarget(rowIndex) && this.targets[rowIndex + 1] === target
    );
  }

  public onTargetOperatorChange() {
    this.updateTargetChange();
  }

  public onChipEnter(
    dropTarget: Target,
    rowIndex: number,
    trigger: MatMenuTrigger,
    dropZoneChip: HTMLElement
  ): void {
    this.chipOverIntentCount++;
    this.dropTarget = dropTarget;
    this.dropRowIndex = rowIndex;
    setTimeout(() => {
      if (
        this.chipOverIntentCount === 0 ||
        this.activeMenuTrigger === trigger ||
        !this.shouldShowOperatorMenu()
      ) {
        return;
      }
      if (
        this.activeMenuTrigger &&
        (this.isDropzoneMenuOpen || this.chipOverIntentCount > 1)
      ) {
        this.closeOperatorMenu();
      }
      this.menuType =
        this.convertStatementsToTargets(this.selectedNodes).length > 1
          ? DropzoneMenuType.multiple
          : DropzoneMenuType.single;
      this.activeMenuTrigger = trigger;
      this.isDropzoneMenuOpen = true;
      trigger.openMenu();
      // need to focus on something else, otherwise the first item of the menu gets focused for some reason
      dropZoneChip.focus();
    }, 300);
  }

  public onChipLeave(): void {
    this.chipOverIntentCount--;
    setTimeout(() => {
      if (this.shouldShowOperatorMenu()) {
        this.closeOperatorMenu();
      }
    }, 200);
  }

  public onOperatorMenuEnter(event: DragEvent): void {
    this.menuTarget = event.currentTarget;
    this.isOverOperatorMenu = true;
  }

  public onOperatorMenuLeave(event: DragEvent): void {
    const target = event.currentTarget;
    // note: dndDragoverClass doesn't always get removed after leaving the target
    if (target && target instanceof Element) {
      target.classList.remove(this.menuItemDragoverClass);
    }
    if (this.menuTarget === target) {
      this.isOverOperatorMenu = false;
      this.closeOperatorMenu();
    }
  }

  public onDragStart(
    parentTarget: Target,
    target: Target,
    rowIndex: number,
    columnIndex: number
  ): void {
    this.isDraggingTarget = true;
    this.draggingTarget = {
      parentTarget,
      target,
      rowIndex,
      columnIndex,
      expand: this.isTargetExpanded(target, rowIndex),
    };
  }

  public onDragEnd(): void {
    this.isDraggingTarget = false;
    this.draggingTarget = null;
  }

  public unsetDrag(): void {
    this.unsetDraggingState();
  }

  public isTargetExpandable(target: Target): boolean {
    const numberOfChildren = target.targets.length;
    const hasChildrenTargets = numberOfChildren > 0;
    if (!hasChildrenTargets) {
      return false;
    }

    return !this.hasUneditableChildTarget(target);
  }

  public onCodingEdited(): void {
    this.rootTarget.targets = slice(this.rootTarget.targets.slice(0, 1));
    this.rootTarget.coding = this.displayCoding.replace(/[\n\t]+/g, ' ');
    this.rootTarget.targets[0].coding = this.displayCoding.replace(
      /[\n\t]+/g,
      ' '
    );
    this.rootTarget.targets[0].targets = [];
    this.emitTargetChange();
  }

  public resetManualCoding(): void {
    this.manualCodingMode = false;
    this.buildTargetList();
    this.updateTargetChange();
    this.manualCodingChange.emit(this.manualCodingMode);
  }

  public onManualCodingModeChange(): void {
    this.manualCodingChange.emit(this.manualCodingMode);
  }

  public onSyncAudienceSize(): void {
    this.syncAudienceSize.emit(this.rootTarget);
  }

  public onDialogTitleModeChange(): void {
    this.updateDisplayTitle();
  }

  private buildTargetList(): void {
    const sameTarget =
      this.targets.length > 1 && this.targets[0].id === this.target.id;
    if (this.changesFromWithin && sameTarget) {
      return;
    }
    this.targets = [cloneDeep(this.target)];
    this.rootTarget = this.targets[0];
    this.expandTheFirstExpandableTarget();
  }

  private expandTheFirstExpandableTarget() {
    const theFirstExpandableTarget = this.rootTarget.targets.find((target) =>
      this.isTargetExpandable(target)
    );
    if (theFirstExpandableTarget) {
      this.expandTarget(theFirstExpandableTarget);
    }
  }

  private updateDisplayText(): void {
    this.displayText = this.targetTitlePipe.codeBuilderTransform(
      this.rootTarget,
      DisplayType.ownTitle,
      this.rootTarget.titleLevels
    );

    this.displayCoding = this.targetTitlePipe.codeBuilderTransform(
      this.rootTarget,
      DisplayType.coding,
      this.rootTarget.titleLevels
    );

    this.updateDisplayTitle();
  }

  private updateDisplayTitle(): void {
    this.title = this.targetTitlePipe.codeBuilderTransform(
      this.rootTarget,
      this.dialogTitleMode,
      this.rootTarget.titleLevels
    );
  }

  private handleDrop(
    operator: Operator = Operator.and,
    countCoding?: CountCodingModel
  ): void {
    const targets: Target[] = this.formatDraggedTargets(operator);

    const isDroppedToParentTarget =
      this.dropTarget === this.targets[this.dropRowIndex];
    // when a none-root node has no children nodes, reuse the node but add itself to the children targets
    if (!isDroppedToParentTarget && this.dropTarget.targets.length === 0) {
      this.dropTarget.targets.push(cloneDeep(this.dropTarget));
      if (this.dropTarget.countCoding) {
        this.dropTarget.countCoding = undefined;
      }
    }

    // to handle SOLUS operator exception
    if (this.hasUneditableChildTarget(this.dropTarget)) {
      this.dropTarget.targets[0] = cloneDeep(this.dropTarget);
    }

    if (countCoding) {
      // countCoding happens in a group target
      targets[0].countCoding = countCoding;
      this.dropTarget.targets.push(this.createTarget({ targets }));
    } else {
      if (this.menuType === DropzoneMenuType.single) {
        this.dropTarget.targets[this.dropTarget.targets.length - 1].operator =
          operator;
      }
      this.dropTarget.targets.push(...targets);
    }
    this.updateTitleAndCoding(this.dropTarget);
    if (
      !isDroppedToParentTarget &&
      this.targets[this.dropRowIndex] !== undefined
    ) {
      this.updateTitleAndCoding(this.targets[this.dropRowIndex]);
    }
    this.unsetDraggingState();
    this.updateTargetChange();
  }

  private handleRemoveTarget(
    parentTarget: Target,
    target: Target,
    rowIndex: number,
    columnIndex: number,
    shouldRemoveFromParent = true
  ): void {
    const onlyOneChildTargetLeft = parentTarget.targets.length === 1;
    if (onlyOneChildTargetLeft) {
      const parentRowIndex = rowIndex - 1;
      this.handleRemoveTarget(
        this.targets[parentRowIndex],
        parentTarget,
        parentRowIndex,
        this.targets[parentRowIndex].targets.indexOf(parentTarget),
        shouldRemoveFromParent
      );
    } else {
      if (this.isTargetExpanded(target, rowIndex)) {
        this.collapseChildrenTargets(rowIndex);
      }
      parentTarget.targets.splice(columnIndex, 1);

      if (shouldRemoveFromParent) {
        this.removeLastChildTargetFromParent(parentTarget, rowIndex);
      }

      if (parentTarget.targets.length === 1) {
        parentTarget.targets[0].operator = Operator.and;
      }
    }
  }

  private removeLastChildTargetFromParent(
    parentTarget: Target,
    rowIndex: number
  ): void {
    const onlyOneChildTargetLeft = parentTarget.targets.length === 1;
    const hasCountCoding =
      parentTarget.countCoding || parentTarget.targets[0].countCoding;
    const isRootRow = rowIndex === 0;
    if (onlyOneChildTargetLeft && !hasCountCoding && !isRootRow) {
      const parentTargetOperator = parentTarget.operator;
      parentTarget = Object.assign(parentTarget, parentTarget.targets[0]);
      parentTarget.operator = parentTargetOperator;

      const parentRowIndex = rowIndex - 1;
      this.collapseChildrenTargets(parentRowIndex);
      this.removeLastChildTargetFromParent(
        this.targets[parentRowIndex],
        parentRowIndex
      );
    }
  }

  private formatDraggedTargets(operator: Operator): Target[] {
    let targets: Target[];
    if (this.isDraggingTarget) {
      this.handleRemoveTarget(
        this.draggingTarget.parentTarget,
        this.draggingTarget.target,
        this.draggingTarget.rowIndex,
        this.draggingTarget.columnIndex,
        this.draggingTarget.rowIndex !== this.dropRowIndex
      );
      targets = [this.draggingTarget.target];
    } else {
      const selectedOperator =
        operator === Operator.count ? Operator.plus : operator;
      const selectedTargets = this.convertStatementsToTargets(
        this.selectedNodes
      );

      if (selectedOperator === Operator.auto) {
        targets = this.groupTargetsWithAutoOperator(selectedTargets);
      } else {
        targets = this.groupTargets(selectedTargets, selectedOperator);
      }
    }
    return targets;
  }

  private shouldShowOperatorMenu(): boolean {
    return (
      !this.isDraggingTarget &&
      (this.convertStatementsToTargets(this.selectedNodes).length > 0 ||
        StatementFactory.getMissingNodes(this.selectedNodes).length > 0)
    );
  }

  private unsetDraggingState(): void {
    this.isDraggingTarget = false;
    this.draggingTarget = null;
    this.isOverOperatorMenu = false;
    this.chipOverIntentCount = 0;
    this.closeOperatorMenu();
    this.unselectNodes.emit(null);
    this.selectedNodes = [];
  }

  private closeOperatorMenu(): void {
    if (this.isDropzoneMenuOpen && !this.isOverOperatorMenu) {
      this.isDropzoneMenuOpen = false;
      this.activeMenuTrigger.closeMenu();
      this.activeMenuTrigger = null;
    }
  }

  private showCountCodingDialog(operator?: Operator): void {
    this.dialog
      .open(CountCodingDialogComponent, {})
      .afterClosed()
      .subscribe((result: CountCodingModel | null) => {
        if (result) {
          this.handleDrop(operator, result);
        } else {
          this.unsetDraggingState();
        }
      });
  }

  private hasExpandedTarget(rowIndex: number): boolean {
    return this.targets[rowIndex + 1] !== undefined;
  }

  private collapseChildrenTargets(rowIndex: number): void {
    while (this.targets.length > 1 && this.targets.length !== rowIndex + 1) {
      this.targets.pop();
    }
  }

  private expandTarget(target: Target): void {
    this.targets.push(target);
  }

  private updateTargetChange(): void {
    this.updateTargetListWithNewTitleAndCodingStatement();
    this.emitTargetChange();
  }

  private emitTargetChange(): void {
    this.changesFromWithin = true;
    this.targetChange.emit(this.rootTarget);
  }

  private updateTargetListWithNewTitleAndCodingStatement(): void {
    for (let i = this.targets.length - 1; i >= 0; i--) {
      this.updateTitleAndCoding(this.targets[i]);
    }
    this.updateDisplayText();
  }

  private hasUneditableChildTarget(target: Target): boolean {
    const numberOfChildren = target.targets.length;
    if (numberOfChildren === 1) {
      if (
        target.targets[0].countCoding ||
        target.targets[0].nTilesCoding ||
        target.targets[0].volumetricCoding
      ) {
        return !!target.targets[0].titlePrefix;
      }
    }
    return false;
  }
}
