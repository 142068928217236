import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import {
  ClusterSettings,
  ClusterViewType,
  HIGHLIGHT_COLORS,
  HighlightType,
} from '../../models';

@Component({
  templateUrl: './cluster-settings-dialog.component.html',
  styleUrls: ['./cluster-settings-dialog.component.scss'],
})
export class ClusterSettingsDialogComponent implements OnInit, OnDestroy {
  public viewType: ClusterViewType;
  public settings: ClusterSettings;
  public readonly dataItems = [
    {
      value: 'average',
      title: 'Averages',
    },
    {
      value: 'index',
      title: 'Index',
    },
    {
      value: 'standardized-score',
      title: 'Standard Scores',
    },
    {
      value: 'standard-deviation',
      title: 'Standard Deviation',
    },
  ];

  public readonly chartTypes = [
    {
      value: 'line',
      title: 'Line chart',
    },
    {
      value: 'polar',
      title: 'Radar chart',
    },
  ];

  public readonly highlightTypes = [
    {
      value: HighlightType.table,
      title: 'Table',
    },
    {
      value: HighlightType.variable,
      title: 'Variable',
    },
    {
      value: HighlightType.cluster,
      title: 'Cluster',
    },
  ];

  public readonly highlightColors = HIGHLIGHT_COLORS;

  constructor(
    public dialogRef: MatDialogRef<ClusterSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.viewType = data.viewType;
    this.settings = cloneDeep(data.settings);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public onButtonClick(): void {
    this.dialogRef.close(this.settings);
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }
}
