<h1 mat-dialog-title>Delete snapshot?</h1>

<div mat-dialog-content>
  <p>
    Deleting
    <b
      ><i>{{ data.snapshotName }} </i></b
    >
    is an action that cannot be undone. Are you sure?
  </p>
</div>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="confirm()"
  >
    Delete
  </button>
</mat-dialog-actions>
