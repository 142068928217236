import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';

import { isNil, merge, omitBy, pick } from 'lodash';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import { TupFileSaverService } from '@telmar-global/tup-document-exporter';
import {
  isNotNullOrUndefined,
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventHandlerDirective,
  TupDocumentEventType,
  TupDocumentMetadata,
  TupDocumentService,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import {
  getSaveDocumentDialogData,
  TupSaveDocumentDialogData,
  TupSaveDocumentDialogOptions,
  TupSaveDocumentDialogResult,
  TupUserMessageService,
} from '@telmar-global/tup-user-message';

import { AppPackage } from '../app.package';
import {
  ConfirmDeleteDialogContentComponent,
  RenameDialogComponent,
  RenameDialogResult,
} from '../dialogs';
import {
  DuplicateResearchResponseBody,
  Snapshot,
  SurveyTimeDocument,
} from '../models';
import { ResearchService } from '../services';
import { TupAuthService } from '@telmar-global/tup-auth';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';

@Directive({
  selector: '[customDocumentEventHandler]',
})
export class CustomDocumentEventHandlerDirective implements OnInit {
  @Input() documentType: 'document' | 'report' | 'quick report' = 'document';
  @Output() duplicateLoading: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private documentService: TupDocumentService,
    private fileSaverService: TupFileSaverService,
    private userMessageService: TupUserMessageService,
    private documentEventEmitter: TupDocumentEventEmitterDirective,
    private documentEventHandler: TupDocumentEventHandlerDirective,
    private researchService: ResearchService,
    private authService: TupAuthService,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      if (event.data) {
        switch (event.data.action) {
          case 'duplicate':
            this.duplicate(event.document);
            break;
          case 'delete':
            this.delete(event);
            break;
          case 'export':
            this.export(event.document);
            break;
          case 'rename':
            this.rename(event.document);
            break;
        }
      }
    });
  }

  private delete(documentEvent: TupDocumentEvent): void {
    const metadata: TupDocumentMetadata = documentEvent.document.metadata;

    const componentFactory: ComponentFactory<ConfirmDeleteDialogContentComponent> =
      this.componentFactoryResolver.resolveComponentFactory(
        ConfirmDeleteDialogContentComponent
      );
    const componentRef: ComponentRef<ConfirmDeleteDialogContentComponent> =
      this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.metadata = metadata;
    componentRef.instance.options = { documentType: this.documentType };

    const data: any = merge({}, documentEvent.data, {
      options: { centered: true, width: '400px' },
      title: 'Delete document',
    });

    // Why are we using all these different button labels? ¯\_(ツ)_/¯
    if (metadata.container.name === metadata.by.attributes.email) {
      merge(data, {
        templateRef: componentRef.instance.own,
        options: { confirmText: 'Delete', cancelText: 'Keep' },
      });
    } else {
      merge(data, {
        templateRef: componentRef.instance.shared,
        options: { confirmText: 'Yes, delete', cancelText: 'Cancel' },
      });
    }

    this.userMessageService
      .openCustomMessageDialog(
        data.templateRef,
        'Delete document',
        data.options
      )
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.documentService
            .delete(metadata.container.name, metadata.id)
            .pipe(
              switchMap(() => {
                this.documentEventHandler.reload.emit(true);
                if (
                  metadata.type.id === TupDocumentTypeId.SURVEYTIME_CAMPAIGN
                ) {
                  const document =
                    documentEvent.document as TupDocument<SurveyTimeDocument>;
                  const snapshots = document.content.snapshots || [];
                  return this.cleanUpSnapshots(
                    snapshots,
                    metadata.container.name
                  );
                } else {
                  return EMPTY;
                }
              })
            )
            .subscribe(
              () => {},
              (error) => {
                console.error(
                  'Error occurred during document deletion:',
                  error
                );
                this.userMessageService.showSnackBar(
                  `Error occurred during document deletion: ${error}`,
                  'OK',
                  10000
                );
              }
            );
        }
      });
  }

  private cleanUpSnapshots(
    snapshots: Snapshot[],
    containerName: string
  ): Observable<unknown[]> {
    const deletionObservables = snapshots.map((snapshot) =>
      this.documentService.delete(containerName, snapshot.docId).pipe(
        catchError((error) => {
          console.error(
            `Error occurred during deletion of snapshot ${snapshot.docId}:`,
            error
          );

          this.userMessageService.showSnackBar(
            `Error occurred during deletion of snapshot ${snapshot.docId}: ${error}`,
            'OK',
            10000
          );

          return of();
        })
      )
    );

    return combineLatest(deletionObservables);
  }

  private duplicate({ metadata }: TupDocument<unknown>): void {
    const dialogOptions: TupSaveDocumentDialogOptions = {
      nameFormControlValue: metadata.name,
      descriptionFormControlValue: metadata.description,
      enableSaveOnInit: true,
    };

    const data: TupSaveDocumentDialogData = getSaveDocumentDialogData(
      'Duplicate',
      'Document',
      dialogOptions
    );

    const isDocumentOwner =
      this.authService.user.attributes.email === metadata.by.attributes.email;

    let _dialogResult: TupSaveDocumentDialogResult;

    this.userMessageService
      .openSaveDocumentDialog({ data })
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        tap(
          (dialogResult: TupSaveDocumentDialogResult) =>
            (_dialogResult = dialogResult)
        ),
        // AE-168 Fetch the entire document before duplicating it
        concatMap(() =>
          this.documentService.get(metadata.container.name, metadata.id)
        )
      )
      .subscribe((document: TupDocument<unknown>) => {
        const existingResearchId =
          document.content?.['apps']?.clustering?.researchId;
        if (!isDocumentOwner && existingResearchId) {
          this.duplicateLoading.emit(true);
          this.createDocWithDuplicateResearchId(
            existingResearchId,
            document,
            _dialogResult
          );
        } else {
          this.createDoc(document, _dialogResult);
        }
      });
  }

  private rename({ metadata }: TupDocument<unknown>): void {
    // tslint:disable-next-line:variable-name
    let _dialogResult: RenameDialogResult;
    const documentTypeId = metadata.type.id;
    const dialogTitle =
      documentTypeId === TupDocumentTypeId.SURVEYTIME_CAMPAIGN
        ? 'Rename report'
        : 'Rename quick report';
    const inputTitle =
      documentTypeId === TupDocumentTypeId.SURVEYTIME_CAMPAIGN
        ? 'Report name'
        : 'Quick report name';
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle,
          inputTitle,
          inputValue: metadata.name,
        },
        closeOnNavigation: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        tap(
          (dialogResult: RenameDialogResult) => (_dialogResult = dialogResult)
        ),
        concatMap(() =>
          this.documentService.get(metadata.container.name, metadata.id)
        )
      )
      .subscribe((document: TupDocument<unknown>) => {
        this.updateDoc(document, _dialogResult);
      });
  }

  private createDocWithDuplicateResearchId(
    existingResearchId: string,
    document: TupDocument<unknown>,
    dialogResult: TupSaveDocumentDialogResult
  ) {
    this.researchService
      .duplicateResearch(existingResearchId)
      .subscribe((result: DuplicateResearchResponseBody) => {
        if (result?.success) {
          document.content['apps'].clustering.researchId =
            result['research-session-id'];
        }
        this.duplicateLoading.emit(false);
        this.createDoc(document, dialogResult);
      });
  }

  private createDoc(
    document: TupDocument<unknown>,
    dialogResult: TupSaveDocumentDialogResult
  ) {
    const clone = merge(
      pick(document, ['metadata.type', 'metadata.status', 'content']),
      { metadata: omitBy(dialogResult, isNil) }
    ) as TupDocument<SurveyTimeDocument>;

    if (clone.content.snapshots) {
      clone.content.snapshots = [];
    }

    this.documentEventEmitter.emit(clone, TupDocumentEventType.CREATE);
  }

  private updateDoc(
    document: TupDocument<unknown>,
    dialogResult: RenameDialogResult
  ) {
    const updatedDoc: TupDocument<unknown> = {
      ...document,
      metadata: {
        ...document.metadata,
        name: dialogResult.name,
      },
    };

    this.documentEventEmitter.emit(updatedDoc, TupDocumentEventType.UPDATE);
  }

  private export({ metadata }: TupDocument<unknown>): void {
    const type: string = 'text/plain;charset=utf-8';
    const ext: string = '.json';

    const documentName: string = metadata.name
      .replace(/\s+/g, '_')
      .toLowerCase();
    const documentType: string = this.documentType.replace(/\s+/g, '_');

    this.documentService
      .get(metadata.container.name, metadata.id)
      .subscribe((document: TupDocument<SurveyTimeDocument>) => {
        merge(document, { version: AppPackage.version });
        const blob: Blob = new Blob([JSON.stringify(document, null, 2)], {
          type,
        });
        this.fileSaverService.saveAs(
          blob,
          `${documentName}_${documentType}${ext}`
        );
      });
  }
}
