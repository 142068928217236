import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import {
  Target,
  TargetType,
  DocumentDataState,
  ALL_RESPONDENTS_CODING,
} from '../../models';
import {
  NTileBucketElement,
  NTiles,
  NTILE_FUNCTION_WITH_ZERO_EXCLUDED,
  NTILE_FUNCTION_WITH_ZERO_INCLUDED,
  DEFAULT_NTILES,
  NTileBoundaryTypes,
} from 'src/app/models/n-tiles.model';
import { DocumentService } from '../../services/document.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import {
  CodeListItem,
  CodeListItemDataSource,
  LargeListSelectorMode,
  DefaultItemMode,
} from '@telmar-global/tup-large-list-selector';
import { TargetListService } from '../../services/target-list.service';
import { TitleModeService } from '../../services/title-mode.service';
import { DisplayType } from '@telmar-global/tup-audience-groups';
import { QuickReportService } from 'src/app/services/quick-report.service';

export interface NTileDialogResult {
  target: Target;
  sourceTargetType: TargetType;
  targetType: TargetType;
  nTileBuckets: NTileBucketElement[];
  nTileFunction: string;
}

@Component({
  selector: 'app-ntile-dialog',
  templateUrl: './ntile-dialog.component.html',
  styleUrls: ['./ntile-dialog.component.scss'],
})
export class NtileDialogComponent implements OnInit {
  public titleMode: DisplayType;
  public displayedColumns = [NTileBoundaryTypes.start, NTileBoundaryTypes.end];
  public dataSource: NTileBucketElement[] = [];
  public nTileOptions = Object.keys(NTiles).filter((key) => isNaN(Number(key)));
  public targetTypeOptionsForNTileTargets = Object.keys(TargetType).filter(
    (key) => isNaN(Number(key))
  );
  public targetTypeForNTileTemplate = Object.keys(TargetType).filter((key) =>
    isNaN(Number(key))
  );
  public excludeZeroes = true;
  public target: Target;
  public targetUserTitle: string;
  public targetTitle: string;
  public targetCoding: string;
  public selectedNtile: string;
  public selectedTargetTypeForNTileTargets: TargetType;
  public selectedTargetTypeForNTileTemplate: TargetType = TargetType.rows;
  public readonly LargeListSelectorMode = LargeListSelectorMode;
  public readonly DefaultItemMode = DefaultItemMode;
  public readonly TargetTypeColumns = TargetType[TargetType.columns];
  public readonly TargetTypeRows = TargetType[TargetType.rows];
  public readonly TargetTypeTables = TargetType[TargetType.tables];
  private unsubscribe: Subject<void> = new Subject<void>();
  public codeListItemDataSources = {
    columns: new CodeListItemDataSource(),
    rows: new CodeListItemDataSource(),
    tables: new CodeListItemDataSource(),
  };
  public codeListItemArrays = {
    columns: [],
    rows: [],
    tables: [],
  };
  public targetLists = {
    columns: [],
    rows: [],
    tables: [],
  };
  public targetListServices: {
    [key: string]: TargetListService<CodeListItem>;
  } = {
    columns: new TargetListService<CodeListItem>([]),
    rows: new TargetListService<CodeListItem>([]),
    tables: new TargetListService<CodeListItem>([]),
  };
  public defaultCodeListItems = {};
  public enableCodeLIstItemSelector = false;
  public selectedCodeListToTargetItem = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NtileDialogComponent>,
    private documentService: DocumentService,
    private targetTitlePipe: TargetTitlePipe,
    private quickReportService: QuickReportService,
    private titleModeService: TitleModeService
  ) {
    if (!data) return;

    if (data?.target) {
      this.setNTilesTargetTemplate(this.data.target);
      this.selectedTargetTypeForNTileTargets = this.data.type;
    } else {
      this.enableCodeLIstItemSelector = true;
      this.selectedTargetTypeForNTileTargets =
        this.selectedTargetTypeForNTileTemplate;
    }
  }

  ngOnInit(): void {
    this.titleModeService.titleMode$.pipe(first()).subscribe((titleMode) => {
      this.titleMode = titleMode;
    });

    this.enableCodeLIstItemSelector && this.prepareTargetLists();
    this.selectedNtile = DEFAULT_NTILES;
    this.onNTileSelectionAction();
    this.listenToQuickReportChanges();
  }

  private listenToQuickReportChanges(): void {
    this.quickReportService.addToRowsOnly$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((addToRowsOnly: boolean) => {
        if (addToRowsOnly) {
          this.targetTypeOptionsForNTileTargets = ['rows'];
        }
      });
  }
  private setNTilesTargetTemplate(target: Target) {
    this.target = cloneDeep(target);
    this.targetCoding = this.target.coding;
    this.targetUserTitle = this.target.ownTitle;
    this.targetTitle = this.targetTitlePipe.transform(
      this.target,
      this.target.activeTitleMode,
      this.target.titleLevels
    );
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public onGenerateClick(): void {
    this.dialogRef.close({
      target: {
        ...this.target,
        ownTitle: this.targetUserTitle,
        activeTitleMode: this.titleMode,
      },
      sourceTargetType: this.selectedTargetTypeForNTileTemplate,
      targetType: this.selectedTargetTypeForNTileTargets,
      nTileBuckets: this.dataSource,
      nTileFunction: this.getNtileFunction(),
    });
  }

  public isGeneratable(): boolean {
    return (
      this.targetCoding && this.targetTitle && this.selectedNtile in NTiles
    );
  }

  public onNTileSelectionAction(): void {
    const ranges = this.splitIntoTiles(NTiles[this.selectedNtile]);
    this.dataSource = ranges.map((element: NTileBucketElement) => ({
      start: element[0],
      end: element[1],
    }));
  }

  private splitIntoTiles(noOfSplits: number, value: number = 100): any {
    const splitValue = Math.ceil(value / noOfSplits);
    const ranges = [[0, splitValue]];
    let start = splitValue;
    while (start < value) {
      const end = start + splitValue;
      if (end <= value) {
        ranges.push([start, end]);
      } else {
        ranges.push([start, value]);
      }
      start = end;
    }

    return ranges;
  }

  public getTargetTypeValue(type: string): string {
    return TargetType[type];
  }

  public getNtileFunction(): string {
    return this.excludeZeroes
      ? NTILE_FUNCTION_WITH_ZERO_EXCLUDED
      : NTILE_FUNCTION_WITH_ZERO_INCLUDED;
  }

  private prepareTargetLists() {
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns, rows, tables }: DocumentDataState) => {
        this.targetLists = {
          columns: this.filterOutAllRespondentsTargets(columns),
          rows: this.filterOutAllRespondentsTargets(rows),
          tables: this.filterOutAllRespondentsTargets(tables),
        };
        Object.keys(this.targetLists).forEach((type) => {
          this.codeListItemArrays[type] = [
            ...this.targetLists[type].map((target: Target) =>
              this.mapTargetToCodeListItem(target)
            ),
          ];

          this.targetListServices[type].updateData(
            this.codeListItemArrays[type]
          );
          this.defaultCodeListItems[type] =
            this.codeListItemArrays[type].length > 0
              ? this.codeListItemArrays[type][0]
              : null;

          this.selectedCodeListToTargetItem[type] =
            this.defaultCodeListItems[type];

          this.codeListItemDataSources[type]
            .connect()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((listItem: CodeListItem[]) => {
              this.setCodeListItemAsNTilesTargetTemplate(listItem[0]);
              this.selectedCodeListToTargetItem[type] = listItem[0];
            });
        });

        this.setCodeListItemAsNTilesTargetTemplate(
          this.defaultCodeListItems[this.getTargetTypeForTargetsName()]
        );
      });
  }

  private filterOutAllRespondentsTargets(targets: Target[]) {
    return targets.filter(
      (target: Target) => target.coding !== ALL_RESPONDENTS_CODING
    );
  }

  private mapTargetToCodeListItem(target: Target) {
    return {
      id: target.id,
      name: `${this.targetTitlePipe.transform(target)} (${target.coding})`,
      description: `${target.coding} - ${
        target.title
      } - ${this.targetTitlePipe.transform(target)}`,
    };
  }

  private setCodeListItemAsNTilesTargetTemplate(listItem: CodeListItem | null) {
    if (listItem === null) {
      this.targetTitle = '';
      this.targetCoding = '';
      return;
    }

    const target: Target[] = this.targetLists[
      this.getTargetTypeForTargetsName()
    ].filter((target) => target.id === listItem.id);

    target.length > 0 && this.setNTilesTargetTemplate(target[0]);
  }

  public getTargetTypeForTargetsName() {
    return this.getTargetTypeValue(
      String(this.selectedTargetTypeForNTileTemplate)
    );
  }

  public onTargetTypeForTargetsChange() {
    this.setCodeListItemAsNTilesTargetTemplate(
      this.selectedCodeListToTargetItem[this.getTargetTypeForTargetsName()]
    );
  }

  public onTargetTitleChanged() {
    this.targetUserTitle = this.targetTitle.trim();
    this.titleMode = DisplayType.ownTitle;
  }

  public isTargetTitleEditable() {
    return this.enableCodeLIstItemSelector
      ? this.selectedCodeListToTargetItem[this.getTargetTypeForTargetsName()]
        ? true
        : false
      : true;
  }
}
