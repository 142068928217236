<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="document-controls-container"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="edit-name"
    data-tracking="document-edit-name"
  >
    <div
      fxFlex
      class="edit-name__copy"
      *ngIf="isReadonly"
    >
      <span>{{ docName }}</span>
    </div>

    <app-edit-name
      fxFlex
      inputId="0"
      *ngIf="!isReadonly"
    ></app-edit-name>

    <app-surveys-info
      *ngIf="showSurveyInfo"
      [surveys]="surveys"
    ></app-surveys-info>
  </div>

  <div
    fxLayout="row"
    fxFlex
    fxLayoutAlign="end center"
  >
    <div
      fxLayout="row"
      fxFlex
      fxLayoutAlign="start center"
    >
      <tup-mat-ichabods-tabs
        #ichabodsTabs
        *ngIf="ichabodsBody"
        [ichabodsBody]="ichabodsBody"
        class="ichabods-tabs"
      ></tup-mat-ichabods-tabs>
    </div>
    <div
      class="toolbar-buttons"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
    >
      <button
        *ngIf="isQuickReport"
        color="primary"
        tupMatButton
        mat-stroked-button
        appearance="tiny"
        class="explore-button"
        (click)="convertToReport()"
        matTooltip="Convert to normal report"
      >
        Explore
      </button>

      <app-undo-redo-controls *ngIf="!isReadonly"></app-undo-redo-controls>
      <mat-chip-list
        *ngIf="isReadonly"
        class="readonly"
      >
        <mat-chip
          [matTooltip]="
            'This document is owned by ' +
            docOwnerName +
            ' and has been granted view-only access to you. Any modifications will not be saved. To save the changes, please create a copy of the document.'
          "
        >
          <mat-icon class="tiny-icon"> lock</mat-icon>
          View-only
        </mat-chip>
      </mat-chip-list>

      <button
        data-tracking="document-export-btn"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Export"
        (click)="openExportOptions()"
        [disabled]="!canExportHighcharts"
      >
        <mat-icon>file_download</mat-icon>
      </button>

      <button
        *ngIf="enableSnapshot"
        color="primary"
        tupMatButton
        onclick="this.blur()"
        mat-stroked-button
        appearance="tiny"
        (click)="snapshot()"
        matTooltip="Create a snapshot"
      >
        <mat-icon>add_a_photo</mat-icon>
      </button>
      <save-menu></save-menu>

      <ng-template [ngIf]="!isQuickReport">
        <mat-button-toggle-group
          [(ngModel)]="selectViewType"
          (ngModelChange)="onToggleViewChanged()"
        >
          <mat-button-toggle
            data-tracking="data-view"
            [disableRipple]="true"
            [value]="documentViewType.data"
          >
            Data
          </mat-button-toggle>
          <mat-button-toggle
            data-tracking="charts-view"
            [disableRipple]="true"
            [value]="documentViewType.graph"
          >
            Charts
          </mat-button-toggle>
          <mat-button-toggle
            *ngIf="selectViewType === documentViewType.pmaps"
            data-tracking="pmaps-view"
            [disableRipple]="true"
            [value]="documentViewType.pmaps"
          >
            PMaps
          </mat-button-toggle>
        </mat-button-toggle-group>
      </ng-template>

      <tup-mat-rightsidebar-button
        *ngIf="
          selectViewType === documentViewType.data &&
          !(isReadonly && selectedTab === viewType.codingGrid)
        "
        (click)="onOpenRightSidebarClick()"
      ></tup-mat-rightsidebar-button>
    </div>
  </div>
</div>
