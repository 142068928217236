import { flatten } from 'lodash';

import {
  TupChartData,
  TupChartType,
  TupComboChartData,
  TupTextPropsOptions,
} from '@telmar-global/tup-document-exporter';

import {
  isData,
  ChartSettings,
  Data,
  Dataset,
  DataItemId,
  ExportProps,
  Survey,
  SurveyTimeProps,
  DATA_ITEMS_MAP,
  Y_AXIS_LABEL_CHART_TYPES,
  X_AXIS_LABEL_CHART_TYPES,
  DEFAULT_SURVEY_COPYRIGHT,
  SurveyCodeMap,
  Note,
  ColumnSortOrder,
  ExportFileType,
} from '../models';
import { ChartComponent } from '../components';
import { ChartTitlePipe } from '../pipes';

export const CHART_FIRST_SLIDE_TITLE: TupTextPropsOptions = {
  x: '5%',
  y: '28%',
  w: '90%',
  h: '10%',
  align: 'left',
  color: 'FFFFFF',
  fontSize: 34,
  fontFace: 'DM Sans',
};

export const CHART_FIRST_SLIDE_SUBTITLE: TupTextPropsOptions = {
  x: '5%',
  y: '40%',
  w: '90%',
  h: '2%',
  align: 'left',
  color: 'FFFFFF',
  fontSize: 15,
  fontFace: 'DM Sans',
};

export const CHART_LAST_SLIDE_COPYRIGHT_TITLE: TupTextPropsOptions = {
  x: '5%',
  y: '12%',
  w: '90%',
  h: '10%',
  align: 'left',
  fontSize: 30,
  fontFace: 'DM Sans',
};

export const CHART_LAST_SLIDE_COPYRIGHT_CONTENT: TupTextPropsOptions = {
  x: '5%',
  y: '25%',
  w: '90%',
  h: '60%',
  align: 'left',
  valign: 'top',
  fontSize: 10,
  fontFace: 'DM Sans',
};

export const CHART_NOTE_TITLE_TEXT_PROPS: TupTextPropsOptions = {
  x: '5%',
  y: '82.5%',
  w: '45%',
  h: '3%',
  align: 'left',
  fontSize: 10,
};

export const CHART_NOTE_DESC_TEXT_PROPS: TupTextPropsOptions = {
  x: '5%',
  y: '85%',
  w: '45%',
  h: '10%',
  align: 'left',
  valign: 'top',
  fontSize: 8,
};

export const CHART_SOURCE_TEXT_PROPS: TupTextPropsOptions = {
  x: '5%',
  y: '90%',
  w: '90%',
  h: '5%',
  align: 'center',
  valign: 'top',
  color: '0D0D0D',
  fontSize: 6,
  fontFace: 'Arial',
};

export const CHART_SOURCE_TEXT_WITH_NOTE_PROPS: TupTextPropsOptions = {
  ...CHART_SOURCE_TEXT_PROPS,
  align: 'right',
  x: '50%',
  w: '45%',
};

export const getSurveyTimeProps = (
  chart: ChartComponent,
  surveys: Survey[],
  surveyCodeMap: SurveyCodeMap,
  chartNote?: Note
): SurveyTimeProps => {
  const pipe: ChartTitlePipe = new ChartTitlePipe();

  const chartTitle = pipe.transform(
    chart.localChartSettings.chartTitle,
    chart.targetTitle
  );

  const chartSubtitle: string = chart.chartData.title;

  const chartSource = surveys.map(({ code, title }) =>
    [surveyCodeMap[code], title].join(' - ')
  );
  const copyright = surveys.map((survey) =>
    survey.meta['copyright-info']
      ? survey.meta['copyright-info'].toString()
      : DEFAULT_SURVEY_COPYRIGHT
  );

  return {
    settings: chart.localChartSettings,
    primaryData: chart.finalData,
    secondaryData: chart.secondaryDataItemValues,
    charts: chart.charts,
    title: chartTitle,
    subtitle: chartSubtitle,
    source: chartSource,
    selected: chart.exportSelected,
    copyright,
    chartNote,
  };
};

/**
 * Converts Chart.js settings and data into PptxGenJS core chart data that can be consumed by the export library.
 */
export const getExportProps = (
  settings: ChartSettings,
  primaryData: Data,
  secondaryData: number[][] | Data,
  exportFileType?: ExportFileType
): ExportProps => {
  const primaryChartType: TupChartType =
    TupChartType[settings.primaryChartType];

  const primaryChartData: TupChartData[] =
    settings.primaryChartType === TupChartType.tupScatter
      ? getCoreChartData(
          primaryData,
          DATA_ITEMS_MAP[settings.primaryDataItem].displayName
        )
      : getCoreChartData(primaryData);

  const secondaryChartType: TupChartType =
    settings.secondaryChartType !== 'None'
      ? TupChartType[settings.secondaryChartType]
      : undefined;

  const secondaryChartData: TupChartData[] = secondaryData
    ? settings.primaryChartType === TupChartType.tupScatter
      ? getCoreChartData(
          formatSecondaryData(primaryData, secondaryData),
          DATA_ITEMS_MAP[settings.secondaryDataItem].displayName
        )
      : getCoreChartData(formatSecondaryData(primaryData, secondaryData))
    : undefined;

  if (primaryChartType === TupChartType.tupScatter) {
    primaryChartData.push(...secondaryChartData);
  }

  const chartColors: string[] = getChartColors(primaryData);

  const chartOpts: { [key: string]: any } = getChartOpts(
    chartColors,
    settings,
    secondaryChartType
  );

  if (primaryChartType === TupChartType.tupScatter) {
    chartOpts.catAxisOrientation =
      exportFileType === ExportFileType.googleSheets &&
      settings.columnSortOrder === ColumnSortOrder.descending
        ? 'maxMin'
        : 'minMax';
    chartOpts.catAxisMinVal = findChartDataMinValue(
      primaryChartData[0].values,
      settings.decimalPlaces
    );
  }

  return {
    primaryChartType,
    primaryChartData,
    secondaryChartType,
    secondaryChartData,
    chartColors,
    chartOpts,
  };
};

export const findChartDataMinValue = (
  values: number[],
  decimalPlaces: number
) => {
  const normalisedValues = values.map((value) =>
    parseFloat(value.toFixed(decimalPlaces))
  );
  return Math.min(...normalisedValues);
};

/**
 * Converts secondary data into a consistent format.
 *
 * Secondary data (ChartComponent.secondaryDataValues) does not have a type.
 *
 * Sometimes it's in the same format as the primary data, other times, it's a 2D Array of numbers ¯\_(ツ)_/¯.
 */
export const formatSecondaryData = (
  primaryData: Data,
  secondaryData: number[][] | Data
): Data => {
  const callbackFn = (dataset: Dataset, index: number): Dataset =>
    ({
      label: dataset.label,
      data: flatten(secondaryData[index]),
    } as Dataset);

  return isData(secondaryData)
    ? secondaryData
    : {
        labels: primaryData.labels,
        datasets: primaryData.datasets.map(callbackFn),
      };
};

/**
 * Converts Chart.js data into PptxGenJS core chart data that can be consumed by the export library.
 *
 * @param data The Chart.js data.
 */
export const getCoreChartData = (
  data: Data,
  dataItemName?: string
): TupChartData[] => {
  return data.datasets.map((dataset: Dataset) => ({
    name: dataItemName ? `${dataset.label} - ${dataItemName}` : dataset.label,
    labels: data.labels,
    values: dataset.data,
  }));
};

/**
 * Converts PptxGenJS core chart data into combo chart data (the core chart and combo chart function signatures are slightly different)
 *
 * @param settings The chart settings
 * @param primaryChartData The primary data
 * @param secondaryChartData The secondary data
 * @param chartColors An Array of chart colours
 */
export const getComboChartData = (
  settings: ChartSettings,
  primaryChartData: TupChartData[],
  secondaryChartData: TupChartData[],
  chartColors: string[]
): TupComboChartData[] => {
  return [
    {
      type: TupChartType[settings.primaryChartType],
      data: primaryChartData,
      options: {
        chartColors,
      },
    },
    {
      type: TupChartType[settings.secondaryChartType],
      data: secondaryChartData,
      options: {
        chartColors,
        secondaryCatAxis: true,
        secondaryValAxis: true,
      },
    },
  ];
};

/**
 * Extracts chartColors PptxGenJS IChartOpt from Chart.js settings.
 *
 * @param data The data.
 */
export const getChartColors = (data: Data): string[] => {
  const chartColors: string[] =
    data.datasets.length === 1
      ? data.datasets[0].backgroundColor
      : data.datasets.map((dataset: Dataset) => dataset.backgroundColor[0]);

  return chartColors.map((chartColor: string) =>
    chartColor.toUpperCase().replace('#', '')
  );
};

/**
 * Extracts PptxGenJS IChartOpts from Chart.js settings.
 *
 * @param secondaryChartType The secondary chart type.
 * @param settings The chart settings.
 */
export const getChartOpts = (
  chartColors: string[],
  settings: ChartSettings,
  secondaryChartType: TupChartType
): { [key: string]: any } => {
  return {
    chartColors,
    dataLabelColor: getTextColorFromContrast(chartColors[0]),
    showLabel: settings.showChartLabel,
    showValue: settings.showChartLabel,
    showDataTable: settings.showDataTable,
    showLegend: settings.showChartLegend,
    ...getAxisTitles(null, settings),
    ...getAxisTitles(secondaryChartType, settings),
    dataLabelFormatCode:
      '#,##0' +
      (settings.decimalPlaces > 0
        ? '.' + '0'.repeat(settings.decimalPlaces)
        : ''),
  };
};

export const getTextColorFromContrast = (backgroundColor) => {
  const white = '#ffffff';
  return checkCorrectContrast(white, backgroundColor) ? white : '#000000';
};

export const checkCorrectContrast = (rgb1, rgb2): boolean => {
  return rgb1 && rgb2 ? calculateContrast(rgb1, rgb2) > 4.5 : false;
};

export const calculateContrast = (hex1, hex2) => {
  const rgbComponents1 = getRGBAcomponents(hex1);
  const rgbComponents2 = getRGBAcomponents(hex2);

  const lum1 = calculateLuminance(
    rgbComponents1[0],
    rgbComponents1[1],
    rgbComponents1[2]
  );
  const lum2 = calculateLuminance(
    rgbComponents2[0],
    rgbComponents2[1],
    rgbComponents2[2]
  );
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

export const getRGBAcomponents = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

export const calculateLuminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

/**
 * Extracts catAxisTitle / valAxisTitle PptxGenJS IChartOpts from Chart.js settings.
 *
 * @param secondaryChartType The secondary chart type.
 * @param settings The chart settings.
 */
export const getAxisTitles = (
  secondaryChartType: TupChartType | null,
  settings: ChartSettings
): any => {
  // Combo charts
  if (secondaryChartType) {
    return {
      valAxes: [
        {
          valAxisTitle: getAxisTitle(settings.primaryDataItem, settings),
        },
        {
          valAxisTitle: getAxisTitle(settings.secondaryDataItem, settings),
          valAxisTitleRotate: 90,
        },
      ],
    };
    // Core charts
  } else {
    return X_AXIS_LABEL_CHART_TYPES.includes(settings.primaryChartType) ||
      Y_AXIS_LABEL_CHART_TYPES.includes(settings.primaryChartType)
      ? {
          showValAxisTitle: true,
          valAxisTitle: getAxisTitle(settings.primaryDataItem, settings),
        }
      : {
          catAxisTitle: getAxisTitle(settings.primaryDataItem, settings),
          valAxisTitle: getAxisTitle(settings.secondaryDataItem, settings),
        };
  }
};

/**
 * Extracts the axis title (DataItem displayName) according to the DataItemId.
 *
 * If showAxisLabel is false, returns a space (not an empty string!).
 *
 * @param dataItemId The DataItemId
 * @returns The axis title (DataItem displayName)
 */
export const getAxisTitle = (
  dataItemId: DataItemId,
  settings: ChartSettings
): string => {
  return settings.showAxisLabel ? DATA_ITEMS_MAP[dataItemId].displayName : ' ';
};
