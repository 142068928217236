import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  CodingGridPreference,
  CodingGridPreferences,
  CodingGridTableRow,
  TargetType,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class CodingGridService {
  private activeGrid = new BehaviorSubject<TargetType>(null);
  public activeGrid$ = this.activeGrid.asObservable();

  public activeGridRows = new BehaviorSubject<CodingGridTableRow[]>([]);
  public activeGridRows$ = this.activeGridRows.asObservable();

  public codingGridPreferences: BehaviorSubject<CodingGridPreferences> =
    new BehaviorSubject<CodingGridPreferences>(
      this.getDefaultCodingGridPreferences()
    );
  public codingGridPreferences$ = this.codingGridPreferences.asObservable();

  private draggingGridRowSubject = new BehaviorSubject<boolean>(false);
  public draggingGridRow$ = this.draggingGridRowSubject.asObservable();

  public updateActiveGrid(grid: TargetType) {
    this.activeGrid.next(grid);
  }

  public updateActiveGridRows(gridRows: CodingGridTableRow[]) {
    this.activeGridRows.next(gridRows);
  }

  public getDefaultCodingGridPreferences(): CodingGridPreferences {
    const preference: CodingGridPreference = {
      hiddenColumns: [],
    };

    return {
      [TargetType.rows]: preference,
      [TargetType.columns]: preference,
      [TargetType.tables]: preference,
    };
  }

  public updateCodingGridPreferences(preferences: CodingGridPreferences) {
    this.codingGridPreferences.next(preferences);
  }

  public setDraggingGridRow(isDragging: boolean): void {
    this.draggingGridRowSubject.next(isDragging);
  }
}
