import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UserContainer } from '@telmar-global/tup-auth';
import { TupUserContainerSelectorComponent } from '@telmar-global/tup-document-storage';
import { cloneDeep } from 'lodash';
import {
  ChartSettings,
  ChartStyleSettings,
  SUPPORTED_CHART_STYLE_SETTINGS,
} from 'src/app/models';

export interface SaveChartStyleDialogData {
  chartSettings: ChartSettings;
}

export interface SaveChartStyleDialogResult {
  container: UserContainer;
  name: string;
  description: string;
  defaultChartStyle: boolean;
  chartStyleSettings: ChartStyleSettings;
}

@Component({
  selector: 'app-save-chart-style-dialog',
  templateUrl: './save-chart-style-dialog.component.html',
  styleUrls: ['./save-chart-style-dialog.component.scss'],
})
export class SaveChartStyleDialogComponent implements OnInit {
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;
  public container: UserContainer;

  public formGroup: FormGroup;

  public get disabled(): boolean {
    return this.formGroup.invalid;
  }

  private chartStyleSettings: ChartStyleSettings;

  public defaultChartStyle: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SaveChartStyleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveChartStyleDialogData
  ) {
    this.populateChartStyleSettings(cloneDeep(data.chartSettings));
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
    });
    this.defaultChartStyle = false;
  }

  public ngAfterViewInit(): void {
    this.container = this.userContainerSelector.container;
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public saveAs(): void {
    const dialogResult: SaveChartStyleDialogResult = {
      container: this.container,
      chartStyleSettings: this.chartStyleSettings,
      defaultChartStyle: this.defaultChartStyle,
      ...this.formGroup.value,
    };

    this.dialogRef.close(dialogResult);
  }

  private populateChartStyleSettings(settings: ChartSettings) {
    this.chartStyleSettings = SUPPORTED_CHART_STYLE_SETTINGS.reduce(
      (acc, setting) => {
        acc[setting] = settings[setting];
        return acc;
      },
      {}
    ) as ChartStyleSettings;
  }
}
