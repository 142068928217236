import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

@Injectable({
  providedIn: 'root',
})
export class DeleteAllTargetsAction extends ActionDefinition<void> {
  constructor(
    private documentService: DocumentService,
    private userMessageService: TupUserMessageService
  ) {
    super();
  }

  public invoke(): void {
    this.userMessageService
      .openDialog(
        `You are about to clear all rows and columns. Please confirm this action. `,
        'Delete all rows & columns',
        {
          cancelText: 'Cancel',
          confirmText: 'Confirm',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.deleteAllTargets();
      });
  }

  private deleteAllTargets(): void {
    this.documentService.deleteAllColumnsAndRows();
  }
}
