import { Pipe, PipeTransform } from '@angular/core';
import { truncate } from '../utils/stringHelper';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  public transform(
    text: string,
    length: number = 20,
    suffix: string = '...'
  ): string {
    return truncate(text, length, suffix);
  }
}
