import { Injectable } from '@angular/core';
import { ChartSearchInput, SearchHit } from '../models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FindAndReplaceService {
  private searchHits = new BehaviorSubject<SearchHit[]>([]);
  public searchHits$ = this.searchHits.asObservable();

  private dialogState = new BehaviorSubject<Boolean>(false);
  public dialogState$ = this.dialogState.asObservable();

  private chartInput = new BehaviorSubject<ChartSearchInput>(null);
  public chartInput$ = this.chartInput.asObservable();

  public updateSearchHits(results: SearchHit[]) {
    this.searchHits.next(results);
  }

  public updateDialogState(state: boolean) {
    this.dialogState.next(state);
  }

  public updateChartInput(chartInput: ChartSearchInput) {
    this.chartInput.next(chartInput);
  }
}
