<div
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div
    class="undo-redo-container"
    fxLayout="row"
    fxLayoutGap="7px"
    fxLayoutAlign="start center"
  >
    <ng-container *ngIf="!isSaving">
      <span data-qa="document-saved">Saved</span>
    </ng-container>
    <ng-container *ngIf="isSaving">
      <span data-qa="document-saving"
        >{{ isUndoing ? "Undoing last edit" : "Saving" }}...</span
      >
      <mat-icon>sync</mat-icon>
    </ng-container>
  </div>
  <button
    data-tracking="document-undo-btn"
    class="undo-btn"
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="tiny"
    matTooltip="Undo"
    [disabled]="!canUndo"
    (click)="undo()"
  >
    <mat-icon>undo</mat-icon>
  </button>
</div>
