<tup-mat-title-bar
  title="Multi-Sort"
  class="dialog-title"
>
  <mat-icon
    title="Close dialog"
    class="close-icon"
    (click)="close()"
    >close
  </mat-icon>
</tup-mat-title-bar>

<mat-dialog-content>
  <div class="multi-sort-content">
    <p>
      Activating multi-sort will sort the table by the loadings of all the
      factors, with each factor having its own table. This allows for a simpler
      analysis of the data. Factors lower than the moderate threshold will not
      be shown.
    </p>
    <br />
    <br />
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <span class="highlight-threshold-label">
        {{ highlightOption.label }}
      </span>
      <div
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          class="input-form-field-without-label reduced-size-input"
        >
          <input
            matInput
            [(ngModel)]="threshold"
            type="number"
            step="0.1"
            max="1"
            min="0"
            (change)="onThresholdValueChange()"
            (keydown)="onFilterValueInputKeydown($event)"
          />
        </mat-form-field>
        <span class="color-dot-container">
          <span
            class="color-dot"
            [style.background-color]="highlightOption.background"
          ></span>
        </span>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="dialog-actions"
>
  <button
    mat-stroked-button
    color="primary"
    appearance="medium"
    tupMatButton
    (click)="close()"
  >
    Cancel
  </button>
  <div>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onApplyButtonClick()"
    >
      OK
    </button>
  </div>
</mat-dialog-actions>
