<div
  fxFill
  fxLayout="column"
>
  <app-top-bar
    [selectViewType]="documentViewType.factorAnalysis"
    [showSurveyInfo]="false"
    [isReadonly]="isReadonly"
    (exportToCsv)="exportToCsv($event)"
    (exportToXlsx)="exportToXlsx($event)"
    (exportToSheets)="exportToSheets($event)"
  ></app-top-bar>
  <mat-progress-bar
    *ngIf="exportingToCustomCodes"
    color="primary"
    mode="indeterminate"
  >
  </mat-progress-bar>
  <mat-progress-bar
    *ngIf="status === researchStatus.onGoing"
    color="primary"
    [value]="statusValue"
    mode="determinate"
  >
  </mat-progress-bar>

  <div
    fxLayout="column"
    *ngIf="hasValidDataset"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="10px"
      class="fa-control-bar"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
      >
        <mat-form-field
          *ngIf="surveys.length > 1"
          appearance="outline"
          class="input-form-field select-small"
          tupMatFormField
          style="width: 200px"
          matTooltip="Change survey"
        >
          <mat-select
            [(ngModel)]="activeSurvey"
            (ngModelChange)="onSelectedSurveyChanged()"
            [disabled]="inProgress || isReadonly"
          >
            <mat-option
              *ngFor="let survey of surveys"
              [value]="survey"
            >
              {{ survey.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
      >
        <mat-chip-list>
          <mat-chip
            *ngIf="activeAppData && activeAppData.multiSort"
            [ngClass]="{ 'is-disabled': inProgress }"
            [disabled]="inProgress"
            [popperDisabled]="inProgress"
            [disableRipple]="false"
            (removed)="clearMultiSort()"
            [popper]="multiSortTooltip"
            [popperTrigger]="'hover'"
            [popperPlacement]="'bottom-start'"
            [popperAppendTo]="'app-factor-analysis-table'"
            [popperStyles]="{ 'background-color': 'white' }"
            (click)="onMultiSort()"
            onclick="this.blur()"
            class="multi-sort-threshold-chip"
          >
            <mat-icon MatChipAvatar> flip_to_front </mat-icon>
            <popper-content
              #multiSortTooltip
              fxLayout="column"
            >
              <p
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                Multi-sort threshold is&nbsp;<b>{{
                  activeAppData.settings.tableHighlightOptions.moderate
                    .threshold
                }}</b>
              </p>
            </popper-content>
            <mat-icon
              matChipRemove
              matTooltipPosition="above"
              matTooltip="Remove multi-sort"
              >cancel
            </mat-icon>
          </mat-chip>
        </mat-chip-list>

        <button
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          appearance="tiny"
          class="data-control-button"
          *ngIf="!isReadonly"
          tupMatButton
          matTooltip="Reset Factor Analysis"
          [disabled]="inProgress"
          (click)="onReset()"
        >
          <mat-icon class="tiny-icon">autorenew</mat-icon>
        </button>
        <button
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          tupMatButton
          appearance="tiny"
          *ngIf="!isReadonly"
          class="data-control-button"
          matTooltip="Save cluster solution"
          [disabled]="inProgress"
          (click)="openSaveCodesDialog()"
        >
          <mat-icon class="tiny-icon">calculate</mat-icon>
        </button>
        <button
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          appearance="tiny"
          class="data-control-button"
          *ngIf="!isReadonly"
          tupMatButton
          matTooltip="Multi-Sort"
          [disabled]="inProgress"
          (click)="onMultiSort()"
        >
          <mat-icon class="tiny-icon">sort</mat-icon>
        </button>
        <button
          *ngIf="!isReadonly"
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          tupMatButton
          appearance="tiny"
          class="data-control-button"
          matTooltip="Settings"
          [disabled]="inProgress"
          (click)="openSettings()"
        >
          <mat-icon class="tiny-icon">settings</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <app-factor-analysis-table
    *ngIf="status === researchStatus.finished && hasValidDataset"
    [survey]="activeSurvey"
    [activeTableTitle]="activeAppData.tablebase.title"
    [audienceSize]="totalAudienceSize"
    [tableData]="tableData"
    [explainedVariances]="explainedVariances"
    [factorTitles]="activeAppData.factorTitles"
    (factorTitleChanged)="onFactorTitleChanged($event)"
    [settings]="activeAppData.settings"
    [multiSortThreshold]="
      activeAppData.multiSort
        ? activeAppData.settings.tableHighlightOptions.moderate.threshold
        : -1
    "
  ></app-factor-analysis-table>

  <tup-mat-card
    class="empty-copy"
    fxLayout="column"
    *ngIf="
      status === researchStatus.failure ||
      !hasValidDataset ||
      (!researchId && isReadonly)
    "
  >
    <span *ngIf="!isReadonly">
      Factor analysis isn't available. Please review the coding statements in
      rows in the crosstab.
    </span>
    <span *ngIf="isReadonly"> No factor analysis data. </span>
  </tup-mat-card>
  <div
    class="spinner-container-overlay"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="inProgress && hasValidDataset"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div
      class="spinner-message"
      *ngIf="progressMessage"
    >
      <p>
        {{ progressMessage }} - <b>{{ statusValue }}% finished</b>
      </p>
    </div>
  </div>
</div>

<ng-template #resetConfirmation>
  <p>
    Performing a rest will remove all settings, variables and edited columns and
    start a new Factor Analysis calculation by using current rows in the
    crosstab.
  </p>
</ng-template>

<ng-template #changeSurveyConfirmation>
  <p>
    Changing the survey will trigger a re-analysis which will reset the current
    analysis, settings and selected variables and start a new calculation by
    using current rows in the crosstab.
  </p>
</ng-template>
