import { SelectMenuOption } from './application.model';

export const GAIN_ANALYSIS_PRIMARY_COLOR = '#008000';

export const AE_VERSION = 'v1';

export enum AudienceEffectsViewType {
  variable = 'variable',
  results = 'results',
}

export const VIEW_TYPES: SelectMenuOption<AudienceEffectsViewType>[] = [
  {
    title: 'Variable selection',
    value: AudienceEffectsViewType.variable,
  },
  {
    title: 'Results',
    value: AudienceEffectsViewType.results,
  },
];

export enum SelectedResultMode {
  chaid = 'chaid',
  gain = 'gain',
}

export const SELECTED_RESULT_MODES: SelectMenuOption<SelectedResultMode>[] = [
  {
    title: 'CHAID analysis',
    value: SelectedResultMode.chaid,
  },
  {
    title: 'Gain analysis',
    value: SelectedResultMode.gain,
  },
];

export enum SelectedGainAnalysisMode {
  chart = 'chart',
  table = 'table',
}

export const SELECTED_GAIN_ANALYSIS_MODES: SelectMenuOption<SelectedGainAnalysisMode>[] =
  [
    {
      title: 'GAIN analysis table',
      value: SelectedGainAnalysisMode.chart,
    },
    {
      title: 'Gain analysis chart',
      value: SelectedGainAnalysisMode.table,
    },
  ];

export enum GainPlotChartType {
  scatter,
  bar,
}

export const CHAID_ANALYSIS_CHART_LEVEL_COLORS = [
  '#2caffe',
  '#544fc5',
  '#00e272',
  '#fe6a35',
  '#6b8abc',
  '#d568fb',
  '#2ee0ca',
  '#fa4b42',
  '#feb56a',
];

export const CHAID_CHART_CONTAINER_WIDTH = `calc(100vw - 80px)`;
export const CHAID_CHART_CONTAINER_HEIGHT = `calc(100vh - 100px)`;

export const CHAID_CHART_NODE_WIDTH = 80;
export const CHAID_CHART_NODE_HEIGHT = 130;
export const CHAID_CHART_NODE_SPACING = 25;

export interface CriteriaPreferencesRequestBody {
  authorizationGroup: string;
  surveyCode: string;
  targets: string[];
  targetTitles: string[];
  variables: string[];
  variableTitles: string[];
  table: string;
  weightIndex: number;
  criteriaList: CriteriaItem[];
  split?: boolean;
  failOnMnemonicNotFound?: boolean;
  includeComplements: boolean;
}

export interface CriteriaItem {
  id: number;
  upper_cutoff: number;
  lower_cutoff: number;
  deviation: number;
  variables: string;
  data_item_id: CriteriaDataItem;
  action_id: CriteriaAction;
  min_observed: number;
  max_observed: number;
}

export enum CriteriaDataItem {
  sample = 1,
  audience = 2,
  index = 3,
  percentage = 4, // %col?
}

export const CRITERIA_DATA_ITEM_MAP = {
  [CriteriaDataItem.sample]: {
    cellKey: 'sample',
    decimalPoints: 0,
  },
  [CriteriaDataItem.audience]: {
    cellKey: 'projected',
    decimalPoints: 0,
  },
  [CriteriaDataItem.index]: {
    cellKey: 'index',
    decimalPoints: 1,
  },
  [CriteriaDataItem.percentage]: {
    cellKey: 'column',
    decimalPoints: 1,
  },
};

export enum CriteriaAction {
  min = 0,
  max = 1,
}

export interface CriteriaPreferencesResponseBody {
  success: boolean;
  message: string;
  dataItemsNames: string[];
  dataItemRanges: number[][];
  nonLayered: ParetoLayerItem[];
  paretoLayers: ParetoLayerItem[];
}

export interface ParetoLayerItem {
  layer_name: string;
  pareto_layer_color: string[];
  pareto_layer_status: string[];
  pareto_optimal: number[];
  pareto_plot: number[];
  plot_index: number[];
  selected_code: string[];
  selected_var: string[];
}

export interface ChaidAnalysisRequestBody {
  authorizationGroup: string;
  surveyCode: string;
  targets: string[];
  targetTitles: string[];
  variables: string[];
  variableTitles: string[];
  table: string;
  weightIndex: number;
  split?: boolean;
  failOnMnemonicNotFound?: boolean;
  treeFilterValue: number;
}

export interface ChaidAnalysisResponseBody {
  success: boolean;
  message: string;
  title: string;
  totalPopulation: number;
  totalSample: number;
  chunkTokens: [];
  chaidTree: ChaidAnalysisBaseItem;
  gainsTable: GainTableItem[];
}

export interface GainTableItem {
  index: number;
  pop: number;
  sample: number;
  segment: string[];
  total_pop_perc: number;
  total_sample_perc: number;
  full_code: string;
}

export interface ChaidAnalysisBaseItem {
  code: string;
  title: string;
  pop: number;
  sample: number;
  children: ChaidAnalysisItem[];
}

export interface ChaidAnalysisItem {
  code: string;
  title: string;
  fullCode: string;
  pop: number;
  sample: number;
  index: number;
  zvalue: number;
  children: ChaidAnalysisItem[];
}

export interface GainTableRowItem {
  rowNumber: number;
  longTitle: string;
  shortTitle: string;
  population: number;
  populationPercentage: number;
  targets: number;
  targetsPercentage: number;
  index: number;
}

export interface AudienceEffectsVariable {
  variable: string;
  title: string;
  index: number;
  targets: number;
  type: VariableType;
}

export interface SelectedValuable {
  variable: string;
  title: string;
}

export enum VariableType {
  variables,
  recommended,
  useSelected,
}

export interface AudienceEffectsVariableTableRowData {
  coding: string;
  title: string;
  shortTitle: string;
  originalType: VariableType;
  type: VariableType;
  rowNumber: number;
  recommended: boolean;
  x: number; // index
  y: number; // targets
}

export interface AudienceEffectsVariableSeriesItem {
  type: string;
  name: string;
  color: string;
  data: AudienceEffectsVariableTableRowData[];
}

export const AUDIENCE_EFFECTS_VARIABLE_TYPE_MAP = {
  [VariableType.variables]: {
    displayName: 'Variables',
    color: '#ccc4f6',
    backgroundColor: '#B3CCFF',
  },
  [VariableType.recommended]: {
    displayName: 'Recommended variables',
    color: '#22b14c',
    backgroundColor: '#DEFCDB',
  },
  [VariableType.useSelected]: {
    displayName: 'User selected variables',
    color: '#ffff00',
    backgroundColor: '#FFFBD7',
  },
};

export type TargetColumnDataItemMap = Record<
  CriteriaDataItem,
  {
    min: number;
    max: number;
  }
>;

export interface AudienceEffectsSettings {
  variableSelectionTitle: string;
  chiadAnalysisTitle: string;
  gainChartTitle: string;
  showDataLabelInVariableSelection: boolean;
  showAxisLabelInVariableSelection: boolean;
  showLegendInVariableSelection: boolean;
  showDataLabelInGainChart: boolean;
  showAxisLabelInGainChart: boolean;
  showLegendInGainChart: boolean;
  showHAccumulatedHighlightColor: boolean;
  accumulatedHighlightColor: string;
  generateAndIncludeNotVariables: boolean;
  filterChaidTreePercent: number;
  criteria: CriteriaItem[];
  gainPlotChartType: GainPlotChartType;
}
