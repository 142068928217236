import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'scroll-top-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent {
  @Output() scrollToTopClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() showScrollToTopBtn = false;
  @Input() dragBoundary: string = '';

  public onClick(): void {
    this.scrollToTopClick.emit();
  }
}
