<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Export options"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="5px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Title</mat-label>
        <input
          matInput
          [(ngModel)]="docName"
        />
      </mat-form-field>

      <ng-template>
        <h3 fxFlex>{{ docName }}</h3>
      </ng-template>
    </div>

    <div
      fxLayout="row"
      fxLayoutGap="10px"
      class="format-container"
    >
      <mat-label>Choose format</mat-label>
      <mat-radio-group
        aria-label="Select an option"
        fxLayout="row"
        fxLayoutGap="10px"
        [(ngModel)]="selectedFormat"
        (change)="onFormatChange()"
        class="tiny-radio-group"
      >
        <mat-radio-button
          *ngFor="let formatOption of exportFormatOptions"
          [value]="formatOption.name"
          [disabled]="formatOption.name === exportFileType.asc"
        >
          {{ formatOption.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div
      *ngIf="selectViewType === documentViewType.data"
      class="target-list-container"
    >
      <div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="target-list-header"
        >
          <h2 class="target-list-title">Select tablebase for export</h2>
          <span
            *ngIf="
              ![exportFileType.csv, exportFileType.asc].includes(selectedFormat)
            "
            class="count-font"
          >
            {{ selectedTablebases.length }} of {{ this.maxTablebase }} selected
          </span>
        </div>

        <div
          *ngIf="
            ![exportFileType.csv, exportFileType.asc].includes(selectedFormat)
          "
          class="target-list"
        >
          <div
            fxLayout="column"
            class="target-list-item-container"
          >
            <div fxLayout="column">
              <mat-checkbox
                *ngFor="let tableBaseOption of tableBaseOptions"
                tupMatCheckbox
                appearance="small"
                color="primary"
                [(ngModel)]="tableBaseOption.selected"
                (ngModelChange)="updateSelectedTablebases()"
                class="tiny-checkbox selectable-target-list-item"
                [disabled]="
                  selectedTablebases.length === maxTablebase &&
                  !tableBaseOption.selected
                "
              >
                {{ tableBaseOption.tableBase.title }}
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div
          *ngIf="
            [exportFileType.csv, exportFileType.asc].includes(selectedFormat)
          "
          class="target-list"
        >
          <div
            fxLayout="column"
            class="target-list-item-container"
          >
            <mat-radio-group
              aria-label="Select an option"
              fxLayout="column"
              (change)="onTablebaseSelect($event)"
              class="tiny-radio-group"
            >
              <mat-radio-button
                *ngFor="let tableBaseOption of tableBaseOptions"
                [value]="tableBaseOption.tableBase"
                [checked]="tableBaseOption.selected"
                class="selectable-target-list-item"
              >
                {{ tableBaseOption.tableBase.title }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
    [class.top-margin]="selectViewType !== documentViewType.data"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onExport()"
    >
      Export
    </button>
  </mat-dialog-actions>
</div>
