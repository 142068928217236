<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  [cdkDragDisabled]="false"
>
  <tup-mat-title-bar
    [title]="
      settings.documentViewType === documentViewTypeOptions.graph
        ? 'Find'
        : 'Find and replace'
    "
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      class="builder-container"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <div
        fxLayout="column"
        class="find-container"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="10px"
          class="find-section"
        >
          <div
            fxLayout="column"
            fxFlex
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              class="display-text-field"
              fxFlex
            >
              <mat-label>Find</mat-label>
              <div
                fxLayout="row"
                class="text-container"
                fxLayoutGap="2px"
              >
                <input
                  [(ngModel)]="findKeyword"
                  (ngModelChange)="onFindKeywordChanged()"
                  (keyup)="onFindEnter($event)"
                  [ngClass]="{
                    'input-min-characters': findKeyword.length < 3
                  }"
                  type="text"
                  cdkFocusInitial
                  required
                  matInput
                  fxFlex="75"
                  placeholder="Type at least 3 characters"
                />
                <span
                  class="hit-counts"
                  fxFlex="25"
                >
                  {{ currentHit }} / {{ maxHits }}
                </span>
              </div>
            </mat-form-field>

            <mat-form-field
              *ngIf="settings.documentViewType === documentViewTypeOptions.data"
              appearance="outline"
              tupMatFormField
              class="display-text-field"
              fxFlex
            >
              <mat-label>Replace</mat-label>
              <div
                fxLayout="row"
                class="text-container"
                fxLayoutGap="2px"
              >
                <input
                  [(ngModel)]="replaceKeyword"
                  type="text"
                  matInput
                  fxFlex="75"
                />
              </div>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayoutGap="5px"
            fxLayoutAlign="center center"
          >
            <button
              mat-stroked-button
              tupMatButton
              color="primary"
              matTooltip="Up"
              (click)="shiftFocus('up')"
              [disabled]="!maxHits || currentHit === 1"
            >
              <mat-icon>arrow_upward</mat-icon>
            </button>

            <button
              mat-stroked-button
              tupMatButton
              color="primary"
              matTooltip="down"
              (click)="shiftFocus('down')"
              [disabled]="!maxHits || currentHit === maxHits"
            >
              <mat-icon>arrow_downward</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <div
          fxLayout="column"
          fxLayoutGap="10px"
          *ngIf="settings.documentViewType === documentViewTypeOptions.data"
        >
          <ng-container
            *ngIf="settings.searchViewType === viewTypeOptions.codingGrid"
          >
            <mat-checkbox
              fxFlex="100"
              tupMatCheckbox
              appearance="small"
              color="primary"
              class="tiny-checkbox save-as-template"
              [(ngModel)]="this.settings.codingGridLimits.limitToTitle"
              (ngModelChange)="setChangeLimitsToTrue()"
            >
              <span matTooltip="Search limited to title"> Search in Title</span>
            </mat-checkbox>

            <mat-checkbox
              fxFlex="100"
              tupMatCheckbox
              appearance="small"
              color="primary"
              class="tiny-checkbox save-as-template"
              [(ngModel)]="this.settings.codingGridLimits.limitToCode"
              (ngModelChange)="setChangeLimitsToTrue()"
            >
              <span matTooltip="Search limited to code"> Search in Code</span>
            </mat-checkbox>

            <mat-checkbox
              fxFlex="100"
              tupMatCheckbox
              appearance="small"
              color="primary"
              class="tiny-checkbox save-as-template"
              [(ngModel)]="this.settings.codingGridLimits.limitToGroupName"
              (ngModelChange)="setChangeLimitsToTrue()"
            >
              <span matTooltip="Search limited to group name">
                Search in Group name</span
              >
            </mat-checkbox>
          </ng-container>

          <ng-container
            *ngIf="settings.searchViewType === viewTypeOptions.crossTab"
          >
            <mat-checkbox
              fxFlex="100"
              tupMatCheckbox
              appearance="small"
              color="primary"
              class="tiny-checkbox save-as-template"
              [(ngModel)]="this.settings.crosstabLimits.limitToRows"
              (ngModelChange)="setChangeLimitsToTrue()"
            >
              <span matTooltip="Search limited to rows"> Search in rows</span>
            </mat-checkbox>

            <mat-checkbox
              fxFlex="100"
              tupMatCheckbox
              appearance="small"
              color="primary"
              class="tiny-checkbox save-as-template"
              [(ngModel)]="this.settings.crosstabLimits.limitToColumns"
              (ngModelChange)="setChangeLimitsToTrue()"
            >
              <span matTooltip="Search limited to columns">
                Search in columns</span
              >
            </mat-checkbox>
          </ng-container>
        </div>

        <ng-container
          *ngIf="settings.documentViewType === documentViewTypeOptions.graph"
        >
          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox save-as-template"
            [(ngModel)]="this.settings.chartLimits.limitToTitle"
            (ngModelChange)="setChangeLimitsToTrue()"
          >
            <span matTooltip="Search limited to titles"> Search in titles</span>
          </mat-checkbox>

          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            class="tiny-checkbox save-as-template"
            [(ngModel)]="this.settings.chartLimits.limitToInsights"
            (ngModelChange)="setChangeLimitsToTrue()"
          >
            <span matTooltip="Search limited to insights">
              Search in insights</span
            >
          </mat-checkbox>
        </ng-container>

        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox save-as-template"
          [(ngModel)]="this.settings.matchCases"
          (ngModelChange)="setChangeLimitsToTrue()"
        >
          <span matTooltip="Match case"> Match cases</span>
        </mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>

  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="medium"
      (click)="onFind()"
      [disabled]="findKeyword.length < 3"
    >
      Find
    </button>
    <div *ngIf="settings.documentViewType === documentViewTypeOptions.data">
      <button
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="medium"
        (click)="onReplaceKeyword('one')"
        [disabled]="replaceKeyword.length === 0 || maxHits === 0"
      >
        Replace
      </button>
      <button
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="medium"
        (click)="onReplaceKeyword('all')"
        [disabled]="replaceKeyword.length === 0 || maxHits === 0"
      >
        Replace all
      </button>
    </div>
    <button
      mat-flat-button
      color="primary"
      tupMatButton
      appearance="medium"
      matDialogClose
      cdkFocusRegionstart
      (click)="close()"
    >
      Close
    </button>
  </div>
</div>
