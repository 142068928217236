import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { TargetType } from '../models';
import { DeleteTargetTypesActionItem } from '../models/action.model';

export interface DeleteTargetTypesActionContext {
  actionItem: DeleteTargetTypesActionItem;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteTargetTypesAction extends ActionDefinition<DeleteTargetTypesActionContext> {
  constructor(
    private documentService: DocumentService,
    private userMessageService: TupUserMessageService
  ) {
    super();
  }

  public invoke(context: DeleteTargetTypesActionContext): void {
    const targetTypes = context.actionItem.targetTypes;
    if (targetTypes.length === 3) {
      this.userMessageService
        .openDialog(
          `This will delete all items in your tables, rows and columns. Do you want to proceed?`,
          'Delete all items',
          {
            cancelText: 'Cancel',
            confirmText: 'Confirm',
          }
        )
        .afterClosed()
        .subscribe((result) => {
          if (!result) {
            return;
          }
          this.deleteTargetTypes(targetTypes);
        });
    } else {
      this.deleteTargetTypes(targetTypes);
    }
  }

  private deleteTargetTypes(targetTypes: TargetType[]): void {
    this.documentService.deleteAllTargets(targetTypes);
  }
}
