import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService, TargetService } from '../services';
import { DisplayType, TargetItem, APPLICATION_NAME } from '../models';
import { RenameDialogComponent, RenameDialogResult } from '../dialogs';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { MatDialog } from '@angular/material/dialog';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

export interface RenameTargetActionContext {
  targetItem: TargetItem;
}

@Injectable({
  providedIn: 'root',
})
export class RenameTargetAction extends ActionDefinition<RenameTargetActionContext> {
  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private messageService: TupUserMessageService
  ) {
    super();
  }

  public invoke(context: RenameTargetActionContext): void {
    const target = context.targetItem.target;
    if (target.activeTitleMode === DisplayType.coding) {
      this.messageService.openMessageDialog(
        'You cannot rename the code',
        APPLICATION_NAME
      );
      return;
    }

    const title = target.ownTitle;
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle: 'Rename',
          inputTitle: 'Title',
          inputValue: title,
        },
        closeOnNavigation: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: RenameDialogResult) => {
        if (result.name === title) {
          return;
        }
        target.activeTitleMode = DisplayType.ownTitle;
        target.ownTitle = result.name;
        this.documentService.updateDocumentData([context.targetItem], true);
      });
  }
}
