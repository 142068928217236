import { Component, OnDestroy, OnInit } from '@angular/core';
import Highcharts, { ExportingMimeTypeValue, SVGElement } from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import ExportingLocal from 'highcharts/modules/offline-exporting';
import Fullscreen from 'highcharts/modules/full-screen';
import {
  CrosstabService,
  DataItemsService,
  DecimalPointService,
  DialogService,
  DocumentService,
  TitleLevelsService,
  TitleModeService,
} from '../../services';
import {
  CrossTabTableData,
  DataItem,
  DataItemId,
  DataItemType,
  DEFAULT_SURVEY_COPYRIGHT,
  DisplayType,
  DocumentDataState,
  DocumentViewType,
  ExportParams,
  ExtendedChartOptions,
  ReportMode,
  Survey,
  SurveyProvider,
  SurveyTimeDocument,
  Target,
  TITLE_MODES,
} from '../../models';
import {
  isNotEmpty,
  isNotNullOrUndefined,
} from '../../utils/pipeable-operators';
import { MappingOptions, TitleLevelsDialogResult } from '../../dialogs';
import { PMapsService } from '../../services/p-maps.service';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { Router } from '@angular/router';
import {
  AnnotationPosition,
  ChartMappingOptionsData,
  ExtendedPmapChart,
  ExtendedPmapOptions,
  PMAPS_VERSION,
  PMapsPreferences,
  PMapsSeriesDataItem,
  PMapsSettings,
} from '../../models/p-maps.model';
import { isEqual } from 'lodash';
import { TargetTitlePipe } from '../../pipes';
import { positionLabels } from '../../utils/chart-annotation-label-helper';

More(Highcharts);
Exporting(Highcharts);
Fullscreen(Highcharts);
ExportingLocal(Highcharts);

@Component({
  selector: 'app-p-maps',
  templateUrl: './p-maps.component.html',
  styleUrls: ['./p-maps.component.scss'],
})
export class PMapsComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  public Highcharts: typeof Highcharts = Highcharts;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;
  public isReadonly = true;
  public isLoading = true;
  public chartOptions: ExtendedPmapOptions;
  private maxX: number;
  private minX: number;
  private maxY: number;
  private minY: number;
  private offsetX: number;
  private offsetY: number;

  private maxOffsetX: number;
  private maxOffsetY: number;
  private referenceAxis: number = 100;

  public canResetZoom = false;

  public surveys: Survey[];
  public selectedSurvey: Survey;

  private chartRef: ExtendedPmapChart;
  private crosstabData: CrossTabTableData[];

  public surveyData: CrossTabTableData[];

  public mappingOptions: MappingOptions;
  private columnData: Target[];

  private settings: PMapsSettings;

  public pMapsPreferences: PMapsPreferences;

  public readonly titleModes = TITLE_MODES;
  public activeTitleMode: DisplayType;
  private titleLevels: number[];
  public isBestFit = false;
  private annotationPositionMap: Record<number, AnnotationPosition> = {};

  chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as ExtendedPmapChart;
      }
    }, 0);
  };

  constructor(
    private router: Router,
    private pMapsService: PMapsService,
    private documentService: DocumentService,
    private crosstabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private dialogService: DialogService,
    private decimalPointService: DecimalPointService,
    private titleModeService: TitleModeService,
    private targetTitlePipe: TargetTitlePipe,
    private titleLevelsService: TitleLevelsService
  ) {
    this.isReadonly =
      this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.listenToTitleModeAndLevelsChanges();
    this.listenToDocumentDataChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public selectTitleMode(): void {
    if (this.activeTitleMode !== DisplayType.levels) {
      this.titleLevels = [];
      this.updateDataTitles();
    }
  }

  public clickTitleMode(displayType: DisplayType): void {
    if (displayType === DisplayType.levels) {
      this.openTitleLevelsDialog();
    }
  }

  public changeMappingOptions(): void {
    this.openMappingOptionsDialog(this.mappingOptions);
  }

  public openSettings(): void {
    this.dialogService
      .pMapsSettings(this.settings)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: PMapsSettings) => {
        if (!isEqual(this.settings, result)) {
          this.setChartSettings(result);
        }
      });
  }

  public onSelectedSurveyChanged(): void {
    this.prepareSelectedSurveyData(this.selectedSurvey);
    this.setMappingOptions(this.mappingOptions);
  }

  public exportTo(
    type: ExportingMimeTypeValue,
    exportParams?: ExportParams
  ): void {
    this.chartRef.exportChartLocal(
      {
        type,
        sourceWidth: 1280,
        sourceHeight: 720,
        scale: 3,
        ...(exportParams?.docName ? { filename: exportParams.docName } : {}),
      },
      {}
    );
  }

  public openFullScreen(): void {
    this.chartRef.fullscreen.toggle();
  }

  public zoomOut(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min - this.offsetX,
      this.chartRef.xAxis[0].max + this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min - this.offsetY,
      this.chartRef.yAxis[0].max + this.offsetY
    );

    this.canResetZoom = true;
  }

  public bestFit(): void {
    this.resetZoom(false);

    this.isBestFit = true;
    this.updateChartOptions(this.settings, true);
    this.canResetZoom = true;
  }

  public zoomIn(): void {
    this.offStick();

    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min + this.offsetX,
      this.chartRef.xAxis[0].max - this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min + this.offsetY,
      this.chartRef.yAxis[0].max - this.offsetY
    );
    this.canResetZoom = true;
  }

  public moveLeft(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min - this.offsetX,
      this.chartRef.xAxis[0].max - this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveRight(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min + this.offsetX,
      this.chartRef.xAxis[0].max + this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveUp(): void {
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min + this.offsetY,
      this.chartRef.yAxis[0].max + this.offsetY
    );
    this.chartRef.xAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveDown(): void {
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min - this.offsetY,
      this.chartRef.yAxis[0].max - this.offsetY
    );
    this.chartRef.xAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public resetZoom(shouldUpdateOptions = true): void {
    this.chartRef.xAxis[0].setExtremes();
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = false;
    this.chartRef.zoomOut();

    if (this.isBestFit) {
      this.isBestFit = false;
      if (shouldUpdateOptions) {
        this.updateChartOptions(this.settings, true);
      }
    }
  }

  private offStick(): void {
    this.chartRef.xAxis[0].options.startOnTick = false;
    this.chartRef.xAxis[0].options.endOnTick = false;
    this.chartRef.yAxis[0].options.startOnTick = false;
    this.chartRef.yAxis[0].options.endOnTick = false;
  }

  public changeData(): void {
    this.dialogService
      .changePMapsData(this.settings.data)
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: PMapsSeriesDataItem[]) => {
        if (!isEqual(this.settings.data, result)) {
          this.setChartSettings({
            ...this.settings,
            data: result,
          });
        }
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.surveys = doc.content.surveys;
        this.columnData = doc.content.columns;
      });
    this.crosstabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe), isNotEmpty())
      .subscribe((data: CrossTabTableData[]) => {
        if (data[0].isPlaceholder) {
          return;
        }
        this.crosstabData = data;
        this.initialiseChart();
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private initialiseChart(): void {
    this.pMapsPreferences = this.documentService.getPMapsPreferences();
    if (
      this.pMapsPreferences &&
      this.pMapsPreferences.version === PMAPS_VERSION
    ) {
      this.selectedSurvey = this.surveys.find(
        (survey: Survey) =>
          survey.code === this.pMapsPreferences.survey.code &&
          survey.authorizationGroup ===
            this.pMapsPreferences.survey.authorizationGroup
      );
      this.prepareSelectedSurveyData(this.selectedSurvey);
      this.mappingOptions = this.pMapsPreferences.mappingOptions;
      this.settings = this.pMapsPreferences.settings;
      this.activeTitleMode = this.pMapsPreferences.activeTitleMode;
      this.titleLevels = this.pMapsPreferences.titleLevels;
      this.setChartOptions(this.settings, false);
    } else {
      this.selectedSurvey = this.documentService.activeSurvey;
      this.prepareSelectedSurveyData(this.selectedSurvey);
      this.renderChartWithDefaultChartOptions();
    }
  }

  private prepareSelectedSurveyData(survey: Survey): void {
    this.surveyData = this.pMapsService.formatCrosstabDataForSingleSurvey(
      this.surveys,
      survey,
      this.crosstabData
    );
  }

  private renderChartWithDefaultChartOptions(): void {
    this.setMappingOptions(this.getDefaultChartOptions());
  }

  private getDefaultChartOptions(): MappingOptions {
    const dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.crossTab
    );
    let firstColumnTargetId;
    let secondColumnTargetId;
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ columns }: DocumentDataState) => {
        firstColumnTargetId = columns.length > 0 ? columns[0].id : 'totals';
        secondColumnTargetId =
          columns.length > 1 ? columns[1].id : firstColumnTargetId;
      });
    const defaultDataItemToGraph = !this.isYouGovSurvey()
      ? DataItemType.index
      : DataItemType.filterIndex;
    return {
      graphType: 'dataItem',
      variableToGraph:
        dataItems.find(
          (dataItem: DataItem) => dataItem.id === defaultDataItemToGraph
        )?.id ?? dataItems[0].id,
      xAxis: firstColumnTargetId,
      yAxis: secondColumnTargetId,
    };
  }

  private openMappingOptionsDialog(options?: MappingOptions): void {
    this.dialogService
      .mappingOptions(
        options,
        this.isYouGovSurvey(),
        this.activeTitleMode,
        this.titleLevels
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: MappingOptions) => {
        if (!isEqual(result, options)) {
          this.unsetAnnotationMap();
          this.setMappingOptions(result);
        }
      });
  }

  private unsetAnnotationMap(): void {
    this.annotationPositionMap = {};
  }

  private setMappingOptions(options: MappingOptions): void {
    this.isLoading = true;
    this.mappingOptions = options;
    const mappingOptionsData = this.pMapsService.formatChartMappingOptionsData(
      this.mappingOptions,
      this.surveyData,
      this.selectedSurvey,
      this.activeTitleMode,
      this.titleLevels
    );
    this.settings = this.composeChartSettings(mappingOptionsData);
    this.setChartOptions(this.settings);
  }

  private setChartSettings(settings: PMapsSettings): void {
    this.isLoading = true;
    this.settings = settings;
    this.setChartOptions(this.settings);
  }

  private composeChartSettings(
    chartData: ChartMappingOptionsData
  ): PMapsSettings {
    const showPlotLines =
      this.mappingOptions.graphType === 'dataItem' &&
      (this.mappingOptions.variableToGraph as DataItemId) ===
        DataItemType.index;
    return {
      title: 'All respondents',
      xAxisLabel: chartData.xAxisLabel,
      yAxisLabel: chartData.yAxisLabel,
      showWeightedSymbols: false,
      showDataLabel: true,
      displayGridlines: true,
      displayQuadrantTitles: false,
      quadrant1: '',
      quadrant2: '',
      quadrant3: '',
      quadrant4: '',
      showPlotLines,
      data: chartData.data,
    };
  }

  private getXYRange(data): void {
    const xArray = data.map((d) => d.x);
    const yArray = data.map((d) => d.y);
    this.maxX = Math.max(...xArray);
    this.minX = Math.min(...xArray);

    this.maxY = Math.max(...yArray);
    this.minY = Math.min(...yArray);

    this.offsetX = (this.maxX - this.minX) / 10;
    this.offsetY = (this.maxY - this.minY) / 10;

    this.maxOffsetX =
      Math.abs(this.maxX - this.referenceAxis) >
      Math.abs(this.minX - this.referenceAxis)
        ? Math.abs(this.maxX - this.referenceAxis)
        : Math.abs(this.minX - this.referenceAxis);
    this.maxOffsetY =
      Math.abs(this.maxY - this.referenceAxis) >
      Math.abs(this.minY - this.referenceAxis)
        ? Math.abs(this.maxY - this.referenceAxis)
        : Math.abs(this.minY - this.referenceAxis);
  }

  private getCopyrightHTML(): string {
    const surveyProvider =
      this.documentService.activeSurvey.meta['survey-provider'];
    const surveyCopyright =
      this.documentService.activeSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `<span>Survey provided by: <b>${surveyProvider}.</b></span> <b>${surveyCopyright}</b>`;
  }

  private setChartOptions(
    settings: PMapsSettings,
    shouldPersistSettings: boolean = true
  ): void {
    setTimeout(() => {
      this.updateChartOptions(settings, true);
    }, 0);
    if (shouldPersistSettings) {
      this.persistSettings(settings);
    }
  }

  private updateChartOptions(
    settings: PMapsSettings,
    shouldReload = false
  ): void {
    const {
      title,
      xAxisLabel,
      yAxisLabel,
      showWeightedSymbols,
      showDataLabel,
      displayGridlines,
      displayQuadrantTitles,
      quadrant1,
      quadrant2,
      quadrant3,
      quadrant4,
      data,
      showPlotLines,
    } = settings;
    this.getXYRange(data.filter((row) => row.visible));

    const annotations = data.map((point, index) => {
      return {
        visible: showDataLabel && point.visible,
        checkPosition: true,
        labelOptions: {
          allowOverlap: true,
          align: 'left',
          verticalAlign: 'top',
          backgroundColor: 'white',
          borderWidth: 0.2,
          shape: 'connector',
          x:
            point.id in this.annotationPositionMap
              ? this.annotationPositionMap[point.id].x
              : 15,
          y:
            point.id in this.annotationPositionMap
              ? this.annotationPositionMap[point.id].y
              : -12,
        },
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: point.x,
              y: point.y,
            },
            text: point.title,
          },
        ],
        events: {
          drag() {
            this.chart.userOptions.annotationPositionMap[point.id] = {
              x: this.labels[0].options.x,
              y: this.labels[0].options.y,
            };
          },
        },
      };
    });

    if (shouldReload) {
      this.chartOptions = null;
    }

    setTimeout(() => {
      this.chartOptions = {
        annotationPositionMap: this.annotationPositionMap,

        exporting: {
          enabled: false,
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    textOutline: 'none',
                  },
                },
              },
            },
          },
        },

        credits: {
          enabled: false,
        },

        stockTools: {
          gui: {
            enabled: false,
          },
        },

        chart: {
          type: 'bubble',
          plotBorderWidth: 1,
          zoomType: 'xy',
          events: {
            render() {
              const chart = this as ExtendedPmapChart;
              chart.quadrantTitles?.forEach((textElement: SVGElement) => {
                textElement.destroy();
              });

              if (!chart.options.quadrantTitles.enabled) {
                chart.quadrantTitles = [];
                return;
              }

              const offset = 10;
              const fontSize = 12;
              const width = chart.plotLeft + chart.plotWidth;
              const height = chart.plotTop + chart.plotHeight;
              const left = chart.plotLeft + offset;
              const right = width - offset;
              const top = chart.plotTop + fontSize + offset;
              const bottom = height - offset;
              chart.quadrantTitles = [
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant1, right, top)
                  .add(),
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant2, left, top)
                  .add(),
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant3, left, bottom)
                  .add(),
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant4, right, bottom)
                  .add(),
              ];
              [chart.quadrantTitles[0], chart.quadrantTitles[3]].forEach(
                (element: SVGElement) => {
                  element.attr({
                    x: right - element.getBBox().width,
                  });
                }
              );
            },
            load() {
              if (showDataLabel) {
                positionLabels(this);
              }
            },
          },
        } as ExtendedChartOptions,
        caption: {
          text: this.getCopyrightHTML(),
        },

        quadrantTitles: {
          enabled: displayQuadrantTitles,
          quadrant1,
          quadrant2,
          quadrant3,
          quadrant4,
        },

        legend: {
          enabled: false,
        },

        title: {
          text: title,
        },

        xAxis: {
          maxPadding: 0,
          minPadding: 0,
          startOnTick: true,
          endOnTick: true,
          crosshair: true,
          gridLineWidth: displayGridlines ? 1 : 0,
          max: this.isBestFit
            ? this.maxX + this.offsetX / 20
            : this.referenceAxis + this.maxOffsetX + this.offsetX / 10,
          min: this.isBestFit
            ? this.minX - this.offsetX / 20
            : this.referenceAxis - this.maxOffsetX - this.offsetX / 10,
          title: {
            text: yAxisLabel,
          },
          labels: {
            format: '{value}',
          },
          plotLines: showPlotLines
            ? [
                {
                  color: 'black',
                  dashStyle: 'Dot',
                  width: 2,
                  value: this.referenceAxis,
                  label: {
                    rotation: 0,
                    y: 15,
                    style: {
                      fontStyle: 'italic',
                    },
                    text: xAxisLabel,
                  },
                  zIndex: 3,
                },
              ]
            : [],
        },

        yAxis: {
          crosshair: true,
          maxPadding: 0,
          minPadding: 0,
          startOnTick: true,
          endOnTick: true,
          max: this.isBestFit
            ? this.maxY + this.offsetY / 20
            : this.referenceAxis + this.maxOffsetY + this.offsetY / 10,
          min: this.isBestFit
            ? this.minY - this.offsetY / 20
            : this.referenceAxis - this.maxOffsetY - this.offsetY / 10,
          gridLineWidth: displayGridlines ? 1 : 0,
          title: {
            text: xAxisLabel,
          },
          labels: {
            format: '{value}',
          },
          plotLines: showPlotLines
            ? [
                {
                  color: 'black',
                  dashStyle: 'Dot',
                  width: 2,
                  value: this.referenceAxis,
                  label: {
                    align: 'right',
                    style: {
                      fontStyle: 'italic',
                    },
                    text: yAxisLabel,
                    x: -10,
                  },
                  zIndex: 3,
                },
              ]
            : [],
        },

        tooltip: {
          useHTML: true,
          headerFormat: '<table>',
          pointFormat:
            '<tr><td><h3>{point.title}</h3></td></tr>' +
            '<tr><td>x: {point.x}</td></tr>' +
            '<tr><td>y: {point.y}</td></tr>' +
            '<tr><td>weight (' +
            this.getWeightDataItemName() +
            `): {point.z:${this.getWeightDecimalPointFormat()}}</td></tr>`,
          footerFormat: '</table>',
          followPointer: true,
        },

        plotOptions: {
          bubble: {
            maxSize: showWeightedSymbols ? undefined : 10,
            minSize: showWeightedSymbols ? undefined : 10,
          },
        },

        series: [
          {
            data,
          } as any,
        ],

        annotations: annotations as any[],
      };

      this.isLoading = false;
    }, 0);
  }

  private getWeightDecimalPointFormat(): string {
    const weightDataItem = this.pMapsService.getWeightDataItem(
      this.selectedSurvey
    );
    return `.${this.decimalPointService.getDecimalPoints(weightDataItem)}f`;
  }

  private persistSettings(settings: PMapsSettings): void {
    this.pMapsPreferences = {
      ...this.pMapsPreferences,
      survey: this.selectedSurvey,
      settings,
      mappingOptions: this.mappingOptions,
      activeTitleMode: this.activeTitleMode,
      titleLevels: this.titleLevels,
      version: PMAPS_VERSION,
    };
    this.documentService.savePMapsPreferences(this.pMapsPreferences);
  }

  private getWeightDataItemName(): string {
    return !this.isYouGovSurvey() ? 'Audience' : 'Market size';
  }

  private isYouGovSurvey(): boolean {
    return this.selectedSurvey.provider === SurveyProvider.youGov;
  }

  private updateDataTitles(): void {
    this.settings.data = this.settings.data.map((item) => ({
      ...item,
      title: this.targetTitlePipe.transform(
        item.target,
        this.activeTitleMode,
        this.titleLevels
      ),
    }));
    this.updateAxisLabel();
    this.setChartOptions(this.settings);
  }

  private updateAxisLabel(): void {
    const xAxisTarget = this.columnData.find(
      (col) => col.id === this.mappingOptions.xAxis
    );
    const yAxisTarget = this.columnData.find(
      (col) => col.id === this.mappingOptions.yAxis
    );
    if (xAxisTarget) {
      this.settings.xAxisLabel = this.targetTitlePipe.transform(
        xAxisTarget,
        this.activeTitleMode,
        this.titleLevels
      );
    }

    if (yAxisTarget) {
      this.settings.yAxisLabel = this.targetTitlePipe.transform(
        yAxisTarget,
        this.activeTitleMode,
        this.titleLevels
      );
    }
  }

  private listenToTitleModeAndLevelsChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.titleLevelsService.updateNumberOfTitleLevelsByTargets([
          ...columns,
          ...rows,
        ]);
      });
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((activeTitleMode: DisplayType) => {
        this.activeTitleMode = activeTitleMode;
      });

    this.titleLevelsService.titleLevels$
      .pipe(
        takeUntil(this.unsubscribe),
        filter((titleLevels: number[]) => !!titleLevels?.length)
      )
      .subscribe((titleLevels: number[]) => {
        this.titleLevels = titleLevels;
      });
  }

  private openTitleLevelsDialog(): void {
    this.titleLevelsService
      .openRawDialog({ titleLevels: this.titleLevels })
      .subscribe((dialogResult: TitleLevelsDialogResult) => {
        this.titleLevels = dialogResult?.titleLevels || [];
        this.updateDataTitles();
      });
  }
}
