import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  private undoError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public undoError$: Observable<boolean> = this.undoError.asObservable();

  constructor() {}

  public shouldUndoError(shouldUndoError: boolean): void {
    this.undoError.next(shouldUndoError);
  }
}
