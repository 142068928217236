import { Pipe, PipeTransform } from '@angular/core';
import { SurveyCodeMap } from '../models';

@Pipe({
  name: 'trendingCoding',
})
export class TrendingCodingPipe implements PipeTransform {
  public transform(coding: string, surveyCodeMap: SurveyCodeMap): string {
    const percentDiffMatches = coding.split(':');
    const calculationTypeMatches = percentDiffMatches[0].split(/\(|\)/);
    const surveyCodeMatches = calculationTypeMatches[1].split(',');
    return (
      calculationTypeMatches[0] +
      '(' +
      (surveyCodeMap[surveyCodeMatches[0]] || '') +
      ',' +
      (surveyCodeMap[surveyCodeMatches[1]] || '') +
      ')' +
      (percentDiffMatches.length > 1
        ? ':' + (surveyCodeMap[percentDiffMatches[1]] || '')
        : '')
    );
  }
}
