<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Variable management"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
    >
      close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <p>
      Explore has evaluated different cluster solutions from 2 - 10 clusters and
      the table below is sorted based on each variable's maximum determination
      value across all solutions. Determination gives an indication of how
      effective the variable is in the selected cluster solution.
    </p>
    <p>
      When a variable is deselected and not used in a cluster solution, it is
      greyed out and appears at the bottom of the list.
    </p>
    <br />
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <p>
        <b>You can select/unselect variables from this table and re-cluster.</b>
      </p>
      <p>
        Variable used:
        <b>{{ selectedRows }} out of {{ dataSource.data.length }}</b>
      </p>
    </div>
    <div class="table-container">
      <cdk-virtual-scroll-viewport
        [tvsItemSize]="50"
        headerEnabled="true"
        [headerHeight]="56"
        class="virtual-scroll-viewport"
      >
        <table
          ctrlShift
          (ctrlShift)="onCtrlShift($event)"
          mat-table
          [dataSource]="dataSource"
        >
          <ng-container
            matColumnDef="selected"
            sticky
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header column-header--checkbox"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [checked]="isAllSelected()"
                (change)="onAllRowsSelectedChange($event)"
                [indeterminate]="selectedRows > 0 && !isAllSelected()"
              ></mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [checked]="selectedRowCodings[element.coding]"
                (change)="onSingleRowSelectedChange($event, element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Variable title
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltip]="element.title"
            >
              {{ element.title | truncate: 100 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="maxDetermination">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Max determination %
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [title]="element.maxDetermination"
            >
              {{
                element.maxDetermination !== ""
                  ? element.maxDetermination.toFixed(0)
                  : ""
              }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              'is-selected': selectedRowCodings[row.coding],
              'is-hidden':
                row.maxDetermination === '' && !selectedRowCodings[row.coding]
            }"
          ></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      [disabled]="!isReClusterEnabled || selectedRows === 0"
      (click)="onReClusterClick()"
    >
      Re-cluster
    </button>
  </mat-dialog-actions>
</div>
