<div class="dialog-content-container">
  <tup-mat-title-bar
    title="Name your report"
    class="dialog-title"
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content class="name-report-content">
    <div
      fxLayout="row"
      fxLayoutGap="20px"
    >
      <div
        fxLayout="column"
        [fxFlex]="!this.data.isEditMode ? 60 : 100"
        fxLayoutAlign="start start"
      >
        <form
          [formGroup]="formGroup"
          class="name-report-form-field"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFill
              class="display-text-field"
            >
              <mat-label>Report name</mat-label>
              <input
                type="text"
                required
                matInput
                formControlName="name"
                (keydown.enter)="onEnter()"
                (focus)="$event.target.select()"
              />
              <mat-error
                *ngIf="formGroup.get('name').errors?.required"
                class="name-report__name-error"
                >Please enter name</mat-error
              >
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="name-report-form-field"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFill
              class="display-text-field"
            >
              <mat-label>Report description (optional)</mat-label>
              <textarea
                matInput
                formControlName="description"
                class="display-text-field-textarea"
              ></textarea>
            </mat-form-field>
          </div>
        </form>
      </div>

      <mat-divider
        *ngIf="!this.data.isEditMode"
        [vertical]="true"
      ></mat-divider>
      <div
        [class.is-hidden]="this.data.isEditMode"
        fxFlex="40"
      >
        <tup-user-container-selector
          #userContainerSelector
          [options]="{ showSubheader: true, showDivider: true }"
          (selectionChange)="selectionChange($event)"
        >
        </tup-user-container-selector>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="medium"
      matDialogClose
      (click)="cancel()"
    >
      Cancel
    </button>

    <div>
      <button
        data-tracking="skip-name-report-btn"
        mat-flat-button
        color="primary"
        tupMatButton
        appearance="medium"
        matDialogClose
        (click)="saveAs(true)"
        [disabled]="!disabled"
        [class.is-hidden]="this.data.isEditMode"
      >
        Skip
      </button>

      <button
        mat-flat-button
        color="primary"
        tupMatButton
        appearance="medium"
        matDialogClose
        (click)="saveAs(false)"
        [disabled]="disabled"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
