import { TargetItem, TargetType } from './document.model';
import { MoveToActionItem, TitleModeActionItem } from './action.model';

export const MAX_TITLE_LENGTH = 160;

export const READONLY_CODING_GRID_COLUMNS = [
  'selected',
  'id',
  'title',
  'code',
  'resps',
  'population',
  'group',
];

export const DEFAULT_CODING_GRID_COLUMNS = [
  ...READONLY_CODING_GRID_COLUMNS,
  'settings',
];

export const PERSISTENT_CODING_GRID_COLUMNS = ['title', 'id', 'selected'];

export interface CodingGridTableRow {
  id: number;
  title: string;
  code: string;
  groupName: string;
  selected: boolean;
  isEmptyRow: boolean;
  targetId?: string;
  targetItem?: TargetItem;
}

export interface SendCodingGridTableRow {
  actionItem: MoveToActionItem;
  row?: CodingGridTableRow;
}

export interface TitleModeGridTableRow extends TitleModeActionItem {
  row?: CodingGridTableRow;
}

export interface CodingButton {
  text: string;
  tooltip: string;
  icon?: string;
}

export enum CodingGridSearchField {
  title = 'title',
  code = 'code',
  groupName = 'groupName',
}

export interface CodingGridSearchHit {
  gridView: TargetType;
  rowId: number;
  field: CodingGridSearchField;
  focus: boolean;
}

export const DEFAULT_CODING_BUTTONS: CodingButton[] = [
  {
    text: 'Tables',
    tooltip:
      'Add selected codes to the Table base. Usually All Respondents in a survey.',
  },
  {
    text: 'Columns',
    tooltip: 'Add selected codes to the Columns. Usually the "Target"',
  },
  {
    text: 'Rows',
    tooltip:
      'Add selected codes to the Rows. Usually what is to be learned about the Target.',
  },
  {
    text: '>>',
    tooltip: 'Adds selected codes to the coding grid as individual rows.',
  },
  {
    text: 'Any',
    tooltip:
      'Will combine all selected codes with OR, and enclose codes in brackets.',
  },
  {
    text: 'AND',
    tooltip: 'Add single AND statement',
  },
  {
    text: 'NOT',
    tooltip: 'Allows for coding mutually exclusive groups',
  },
  {
    text: 'Count',
    tooltip:
      'Combines all chosen codes with a (+), and allows for qualifiers to be added. ' +
      'Usually used with Volume coding and attitude statements.',
  },
  {
    text: 'OR',
    tooltip: 'Add single OR statement',
  },
  {
    text: 'SUM',
    tooltip: 'Join codes using + operator',
  },
  {
    text: 'All',
    tooltip:
      'Will combine all selected codes with AND, and enclose codes in brackets.',
  },
  {
    text: '*',
    tooltip: 'Add value to multiply coding',
  },
  {
    text: '/',
    tooltip: 'Add value to divide coding',
  },
  {
    text: '+',
    tooltip: 'Join codes using + operator',
  },
  {
    text: '>',
    tooltip: '', // todo: tooltip is missing for this action
  },
  {
    text: '(..)',
    tooltip: 'Quickly enclose a series of coding statements.',
  },
  {
    text: '(',
    tooltip:
      'Single coding bracket for manually coding a string from the beginning.',
  },
  {
    text: ')',
    tooltip: 'Single coding bracket for manually coding a string from the end.',
  },
  {
    text: 'Switch',
    icon: 'sync_alt',
    tooltip: 'Switch the codes',
  },
  {
    text: 'SOLUS',
    tooltip: 'Calculate the Exclusive Reach',
  },
  {
    text: 'VIND',
    tooltip: 'Index comparison',
  },
  /*{
    text: 'VAUD', // deprecated
    tooltip: '',
  },*/
  {
    text: 'VMUL',
    tooltip: '', // todo: missing tooltip for this action
  },
  {
    text: 'VDIV',
    tooltip: 'Divides two codes',
  },
  {
    text: 'MEAN',
    tooltip: '', // todo: missing tooltip for this action
  },
  {
    text: 'MEANZ',
    tooltip: 'Computes average, excluding non-users',
  },
  {
    text: 'MEDIAN',
    tooltip: '', // todo: missing tooltip for this action
  },
  {
    text: 'MEDIANZ',
    tooltip: '', // todo: missing tooltip for this action
  },
];

export type TargetId = string;

export type CodingDataMap = Record<TargetId, CodingData>;

export type CodingData = {
  resps: string;
  population: string;
};

export interface CodingGridSearchInput {
  rows: CodingGridTableRow[];
  targetType: TargetType;
  codingGridSearch?: boolean;
}

export interface CodingGridSearchLimitSettings {
  limitToTitle: boolean;
  limitToCode: boolean;
  limitToGroupName: boolean;
}

export interface CodingGridHideColumnItem {
  label: string;
  value: string;
  show: boolean;
}

export type CodingGridHideColumnMap = Record<
  TargetType,
  CodingGridHideColumnItem[]
>;

export enum MoreMenuVisibilityOptions {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  none = '',
}

export const MORE_MENU_VISIBILITY_VALUES: string[] = Object.values(
  MoreMenuVisibilityOptions
);
export type MoreMenuVisibility = typeof MORE_MENU_VISIBILITY_VALUES[number];

export const EXTRA_LARGE_BREAKPOINT = 1680;
export const LARGE_BREAKPOINT = 1440;
export const MEDIUM_BREAKPOINT = 1200;
export const SMALL_BREAKPOINT = 960;
export const EXTRA_SMALL_BREAKPOINT = 840;

export const EMPTY_GRID_ROW_SIZE = 10;
