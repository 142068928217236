import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SurveysInfoDialogComponent } from '../components/surveys-info-dialog/surveys-info-dialog.component';
import {
  ChartSettingsDialogComponent,
  SaveAsDialogComponent,
  AffinityReportDialogComponent,
  ChartFiltersDialogComponent,
  MappingOptionsDialogComponent,
  MappingOptions,
  NtileDialogComponent,
  ClusterSettingsDialogComponent,
  VariableImportanceDialogComponent,
  CorrespondenceFilterDialogComponent,
  AudienceEffectsSettingsDialogComponent,
  FindAndReplaceDialogComponent,
  ManageCustomAudiencesDialogComponent,
  NameReportDialogComponent,
  SaveFactorCodesDialogComponent,
  FactorAnalysisMultiSortDialogComponent,
  GlobalChartSettingsDialogComponent,
  SendToPlanDialogDataModel,
  SendToPlanDialogComponent,
  SendToPlanDialogResultDataModel,
  NameReportDialogDataModel,
} from '../dialogs';
import { DataItemsDialogComponent } from '../dialogs/data-items-dialog/data-items-dialog.component';
import { ManageReportItemsDialogComponent } from '../dialogs/manage-report-items-dialog/manage-report-items-dialog.component';
import {
  ChartSettings,
  ChartSettingsMode,
  CleaningMethodOptionsItem,
  ClusterSettings,
  ClusterVariableRowData,
  ClusterViewType,
  DataItem,
  DocumentDataState,
  Target,
  TargetType,
  CorrespondenceFilterOptions,
  CorrespondenceData,
  AudienceEffectsSettings,
  AudienceEffectsViewType,
  TargetColumnDataItemMap,
  SelectedResultMode,
  SelectedGainAnalysisMode,
  CodingGridSearchInput,
  CrosstabSearchInput,
  DisplayType,
  ChartSearchInput,
  FactorAnalysisSettings,
  GlobalChartSettings,
  ChartTargetMode,
  ChartFilter,
  DefaultChartStyles,
  Snapshot,
  SurveyTimeDocument,
  MAX_SNAPSHOTS_LIMIT,
} from '../models';
import { Survey, SurveyInfoType } from '../models/survey.model';
import { ExportedGoogleFileDialogComponent } from '../dialogs/exported-google-file-dialog/exported-google-file-dialog.component';
import { TrendingCalculationDialogComponent } from '../dialogs/trending-calculation-dialog/trending-calculation-dialog.component';
import { TrendingCalculationService } from './trending-calculation.service';
import { DocumentService } from './document.service';
import { TargetService } from './target.service';
import { PMapsSettingsDialogComponent } from '../dialogs/p-maps-settings-dialog/p-maps-settings-dialog.component';
import { PMapsDataDialogComponent } from '../dialogs/p-maps-data-dialog/p-maps-data-dialog.component';
import { PMapsSeriesDataItem, PMapsSettings } from '../models/p-maps.model';
import { ClusterPreferencesDialogComponent } from '../dialogs/cluster-preferences-dialog/cluster-preferences-dialog.component';
import {
  SaveClusterCodesDialogComponent,
  SaveClusterCodesDialogData,
} from '../dialogs/save-cluster-codes-dialog/save-cluster-codes-dialog.component';
import {
  CorrespondenceSettingsDialogComponent,
  CorrespondenceSettingsDialogModel,
} from '../dialogs/correspondence-settings-dialog/correspondence-settings-dialog.component';
import { CorrespondenceActivePassiveDialogComponent } from '../dialogs/correspondence-active-passive-dialog/correspondence-active-passive-dialog.component';
import { CodingDataMapService } from './coding-data-map.service';
import { NewCodeBuilderDialogComponent } from '../dialogs/new-code-builder-dialog/new-code-builder-dialog.component';
import {
  FactorAnalysisSettingsDialogComponent,
  FactorAnalysisSettingsDialogModel,
} from '../dialogs/factor-analysis-settings-dialog/factor-analysis-settings-dialog.component';
import {
  ExportDialogComponent,
  ExportDialogDataModel,
} from '../dialogs/export-dialog/export-dialog.component';
import { ManageSnapshotsDialogComponent } from '../dialogs/manage-snapshots-dialog/manage-snapshots-dialog.component';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { SnapshotLimitAlertDialogComponent } from '../dialogs/snapshot-limit-alert-dialog/snapshot-limit-alert-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  public globalChartSettings(
    config: GlobalChartSettings,
    targetMode: ChartTargetMode
  ): MatDialogRef<
    GlobalChartSettingsDialogComponent,
    GlobalChartSettings | null
  > {
    return this.dialog.open(GlobalChartSettingsDialogComponent, {
      data: {
        config,
        targetMode,
      },
      width: '500px',
    });
  }

  public chartSettings(
    chartSettingsMode: ChartSettingsMode,
    config: ChartSettings,
    targetColors: Record<string, string>,
    targets: Target[] = [],
    chartData?: any,
    groupName?: string,
    chartTitle?: string,
    isReadonly?: boolean,
    defaultChartStyles?: DefaultChartStyles
  ): MatDialogRef<ChartSettingsDialogComponent, ChartSettings | null> {
    return this.dialog.open(ChartSettingsDialogComponent, {
      data: {
        chartSettingsMode,
        config,
        targetColors,
        targets,
        chartData,
        groupName,
        chartTitle,
        isReadonly,
        defaultChartStyles,
      },
    });
  }

  public chartFilters(
    chartSettingsMode: ChartSettingsMode,
    chartFilters: ChartFilter[],
    targetMode: ChartTargetMode,
    width: string,
    targets?: Target[],
    chartData?: any
  ): MatDialogRef<ChartFiltersDialogComponent, ChartFilter[] | null> {
    return this.dialog.open(ChartFiltersDialogComponent, {
      data: {
        chartSettingsMode,
        chartFilters,
        targetMode,
        targets,
        chartData,
      },
      width,
      closeOnNavigation: true,
      autoFocus: false,
    });
  }

  public manageReportItems(
    data: DocumentDataState,
    titleMode: DisplayType,
    titleLevels: number[]
  ): MatDialogRef<ManageReportItemsDialogComponent> {
    const dialogData = {
      rows: data.rows,
      columns: data.columns,
      titleMode,
      titleLevels,
    };

    return this.dialog.open(ManageReportItemsDialogComponent, {
      data: dialogData,
      width: '1100px',
      closeOnNavigation: true,
      autoFocus: false,
    });
  }

  public dataItems(
    dataItems: DataItem[],
    decimalPoints: number
  ): MatDialogRef<DataItemsDialogComponent> {
    return this.dialog.open(DataItemsDialogComponent, {
      data: {
        dataItems,
        decimalPoints,
      },
      width: '500px',
    });
  }

  public newCodeBuilder(
    gridType: TargetType,
    documentService: DocumentService,
    targetService: TargetService,
    codingDataMapService: CodingDataMapService,
    survey?: Survey
  ): MatDialogRef<NewCodeBuilderDialogComponent, any> {
    return this.dialog.open(NewCodeBuilderDialogComponent, {
      data: {
        gridType,
        documentService,
        targetService,
        codingDataMapService,
        survey,
      },
      width: '600px',
      closeOnNavigation: true,
      disableClose: true,
    });
  }

  public nTileSettings(
    target?: Target,
    type?: TargetType
  ): MatDialogRef<NtileDialogComponent, any> {
    return this.dialog.open(NtileDialogComponent, {
      data: {
        target,
        type,
      },
      width: '900px',
      closeOnNavigation: true,
    });
  }

  public findAndReplace(
    data: CodingGridSearchInput | CrosstabSearchInput | ChartSearchInput
  ): MatDialogRef<FindAndReplaceDialogComponent, any> {
    return this.dialog.open(FindAndReplaceDialogComponent, {
      data,
      width: '440px',
      closeOnNavigation: true,
      hasBackdrop: false,
      position: {
        top: '5%',
        right: '5%',
      },
    });
  }

  public manageCustomAudiences(): MatDialogRef<ManageCustomAudiencesDialogComponent> {
    return this.dialog.open(ManageCustomAudiencesDialogComponent, {
      width: '1200px',
      maxWidth: '90vw',
      closeOnNavigation: true,
    });
  }

  public showSnapshotLimit(): MatDialogRef<SnapshotLimitAlertDialogComponent> {
    return this.dialog.open(SnapshotLimitAlertDialogComponent, {
      data: {
        maxLimitNumber: MAX_SNAPSHOTS_LIMIT,
      },
      closeOnNavigation: true,
      autoFocus: false,
      width: '400px',
    });
  }

  public manageSnapshots(
    snapshots: Snapshot[],
    containerName: string,
    currentDocument: TupDocument<SurveyTimeDocument>
  ): MatDialogRef<ManageSnapshotsDialogComponent> {
    return this.dialog.open(ManageSnapshotsDialogComponent, {
      data: {
        snapshots,
        containerName,
        currentDocument,
      },
      autoFocus: false,
      width: '800px',
      maxWidth: '90vw',
      closeOnNavigation: true,
    });
  }

  public saveAs(): MatDialogRef<SaveAsDialogComponent> {
    return this.dialog.open(SaveAsDialogComponent, {
      width: '1100px',
      closeOnNavigation: true,
    });
  }

  public nameReport(
    data: NameReportDialogDataModel = {
      isEditMode: false,
      name: '',
      description: '',
    }
  ): MatDialogRef<NameReportDialogComponent> {
    return this.dialog.open(NameReportDialogComponent, {
      data,
      width: '700px',
      closeOnNavigation: true,
    });
  }

  public affinityReport(): MatDialogRef<AffinityReportDialogComponent> {
    return this.dialog.open(AffinityReportDialogComponent, {
      closeOnNavigation: true,
      width: '600px',
    });
  }

  public surveysInfo(
    type: SurveyInfoType,
    surveys: Survey[]
  ): MatDialogRef<SurveysInfoDialogComponent> {
    return this.dialog.open(SurveysInfoDialogComponent, {
      data: {
        type,
        surveys,
      },
      closeOnNavigation: true,
      width: type === SurveyInfoType.copyright ? '600px' : '80vw',
    });
  }

  public exportedGoogleFile(
    webViewLink: string
  ): MatDialogRef<ExportedGoogleFileDialogComponent> {
    const options = {
      width: '400px',
      data: webViewLink,
    };
    return this.dialog.open(ExportedGoogleFileDialogComponent, options);
  }

  public trendingCalc(
    surveys: Survey[],
    trendingCalculationService: TrendingCalculationService
  ): MatDialogRef<TrendingCalculationDialogComponent> {
    const options = {
      width: '80vw',
      maxWidth: '1200px',
      data: {
        surveys,
        trendingCalculationService,
      },
    };
    return this.dialog.open(TrendingCalculationDialogComponent, options);
  }

  public mappingOptions(
    mapping: MappingOptions,
    isYouGovSurvey: boolean = false,
    titleMode: DisplayType,
    titleLevels: number[]
  ): MatDialogRef<MappingOptionsDialogComponent> {
    const options = {
      width: '550px',
      data: {
        mapping,
        isYouGovSurvey,
        titleMode,
        titleLevels,
      },
    };
    return this.dialog.open(MappingOptionsDialogComponent, options);
  }

  public pMapsSettings(
    settings: PMapsSettings
  ): MatDialogRef<PMapsSettingsDialogComponent> {
    const options = {
      width: '700px',
      data: settings,
    };
    return this.dialog.open(PMapsSettingsDialogComponent, options);
  }

  public changePMapsData(
    data: PMapsSeriesDataItem[]
  ): MatDialogRef<PMapsDataDialogComponent> {
    const options = {
      width: '900px',
      data,
    };
    return this.dialog.open(PMapsDataDialogComponent, options);
  }

  public clusterSettings(
    viewType: ClusterViewType,
    settings: ClusterSettings
  ): MatDialogRef<ClusterSettingsDialogComponent> {
    const options = {
      width: '700px',
      data: {
        viewType,
        settings,
      },
    };
    return this.dialog.open(ClusterSettingsDialogComponent, options);
  }

  public changeVariables(
    data: ClusterVariableRowData[]
  ): MatDialogRef<VariableImportanceDialogComponent> {
    const options = {
      width: '900px',
      maxHeight: '900px',
      data,
    };
    return this.dialog.open(VariableImportanceDialogComponent, options);
  }

  public clusterPreferences(preferences: CleaningMethodOptionsItem) {
    return this.dialog.open(ClusterPreferencesDialogComponent, {
      data: preferences,
      width: '1000px',
      closeOnNavigation: true,
    });
  }

  public correspondenceActivePassive(data: CorrespondenceData) {
    return this.dialog.open(CorrespondenceActivePassiveDialogComponent, {
      data,
      width: '1000px',
      maxHeight: '900px',
      closeOnNavigation: true,
    });
  }

  public saveClusterCodes(
    data: SaveClusterCodesDialogData
  ): MatDialogRef<SaveClusterCodesDialogComponent> {
    const options = {
      width: '500px',
      data,
    };
    return this.dialog.open(SaveClusterCodesDialogComponent, options);
  }

  public saveFactorCodes(): MatDialogRef<SaveFactorCodesDialogComponent> {
    const options = {
      width: '500px',
    };
    return this.dialog.open(SaveFactorCodesDialogComponent, options);
  }

  public correspondenceSettings(
    data: CorrespondenceSettingsDialogModel
  ): MatDialogRef<CorrespondenceSettingsDialogComponent> {
    const options = {
      width: '700px',
      data,
    };
    return this.dialog.open(CorrespondenceSettingsDialogComponent, options);
  }

  public correspondenceFilterOptions(
    filterOptions: CorrespondenceFilterOptions
  ): MatDialogRef<CorrespondenceFilterDialogComponent> {
    const options = {
      width: '450px',
      data: filterOptions,
    };
    return this.dialog.open(CorrespondenceFilterDialogComponent, options);
  }

  public factorAnalysisSettings(
    data: FactorAnalysisSettingsDialogModel
  ): MatDialogRef<FactorAnalysisSettingsDialogComponent> {
    const options = {
      width: '450px',
      data,
    };
    return this.dialog.open(FactorAnalysisSettingsDialogComponent, options);
  }

  public factorAnalysisMultiSort(
    data: FactorAnalysisSettings
  ): MatDialogRef<FactorAnalysisMultiSortDialogComponent> {
    const options = {
      width: '450px',
      data,
    };
    return this.dialog.open(FactorAnalysisMultiSortDialogComponent, options);
  }

  public audienceEffectsSettings(
    viewType: AudienceEffectsViewType,
    resultMode: SelectedResultMode,
    gainAnalysisMode: SelectedGainAnalysisMode,
    settings: AudienceEffectsSettings,
    targetMinMaxDataMap: TargetColumnDataItemMap
  ): MatDialogRef<AudienceEffectsSettingsDialogComponent> {
    const options = {
      width: '1000px',
      data: {
        viewType,
        resultMode,
        gainAnalysisMode,
        settings,
        targetMinMaxDataMap,
      },
    };
    return this.dialog.open(AudienceEffectsSettingsDialogComponent, options);
  }

  public sendToPlan(
    data: SendToPlanDialogDataModel
  ): MatDialogRef<SendToPlanDialogComponent, SendToPlanDialogResultDataModel> {
    return this.dialog.open(SendToPlanDialogComponent, {
      data,
      closeOnNavigation: true,
    });
  }

  public exportOptions(data: ExportDialogDataModel) {
    return this.dialog.open(ExportDialogComponent, {
      width: '500px',
      data,
      closeOnNavigation: true,
    });
  }
}
