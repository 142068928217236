import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService, TargetService } from '../services';
import { DocumentDataState, Target, TargetItem, TargetType } from '../models';
import { TupDocument } from '@telmar-global/tup-document-storage';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesTargetGroup,
  SaveOwnCodesType,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TargetTitlePipe } from '../pipes';
import { CustomAudiencesService } from '../services/custom-audiences.service';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

export interface SaveCustomAudienceActionContext {
  targetType: TargetType;
  targetItems: TargetItem[];
}

@Injectable({
  providedIn: 'root',
})
export class SaveCustomAudienceAction extends ActionDefinition<SaveCustomAudienceActionContext> {
  private readonly dataStateOptions = [
    {
      title: 'bases*',
      key: 'tables',
      type: TargetType.tables,
    },
    {
      title: 'columns',
      key: 'columns',
      type: TargetType.columns,
    },
    {
      title: 'rows',
      key: 'rows',
      type: TargetType.rows,
    },
  ];

  constructor(
    private audienceGroupsService: TupAudienceGroupsService,
    private documentService: DocumentService,
    private customAudiencesService: CustomAudiencesService,
    private messageService: TupUserMessageService,
    private targetService: TargetService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    super();
  }

  public invoke(context: SaveCustomAudienceActionContext): void {
    this.audienceGroupsService
      .saveOwnCodes({
        survey: this.documentService.activeSurvey,
        type: SaveOwnCodesType.audience,
        fileName: '',
        targetGroups: this.formatSaveOWnCodesTargetGroups(context),
      })
      .subscribe(
        (doc: TupDocument<DocumentAudienceGroup>) => {
          this.customAudiencesService.notifyOwnCodesAdded(
            SaveOwnCodesType.audience,
            doc
          );
          this.messageService.showSnackBar(
            'Custom audiences/media saved successfully',
            'OK',
            10000
          );
        },
        (error) => {
          this.messageService.showSnackBar(error, 'OK', 10000);
        }
      );
  }

  private formatSaveOWnCodesTargetGroups(
    context: SaveCustomAudienceActionContext
  ): SaveOwnCodesTargetGroup[] {
    const selectedTargetMap = context.targetItems.reduce(
      (acc, current) => ({
        ...acc,
        [`${context.targetType}_${current.index}`]: true,
      }),
      {}
    );
    const targetGroups = [];
    this.documentService.documentState$.subscribe(
      (dataState: DocumentDataState) => {
        this.dataStateOptions.forEach((group) => {
          targetGroups.push({
            title: group.title,
            selectAll:
              group.type === context.targetType &&
              dataState[group.key].length === context.targetItems.length,
            items: dataState[group.key].map(
              (target: Target, index: number) => ({
                selected: `${group.type}_${index}` in selectedTargetMap,
                targetItem: this.convertTargetToAudienceGroupItem(target),
              })
            ),
          });
        });
      }
    );

    return targetGroups;
  }

  private convertTargetToAudienceGroupItem(
    target: Target
  ): DocumentAudienceGroupItem {
    return {
      title: this.targetTitlePipe.transform(target),
      coding: target.coding,
      options: {
        statement: null,
        target: this.targetService.shallowCopyTarget(target),
      },
    };
  }
}
