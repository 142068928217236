import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { CorrespondenceApiService } from './correspondence-api.service';
import {
  GeoCodingItem,
  GeoCodingMap,
  GeoCodingsRequest,
  GeoCodingsResponse,
} from '../models/geografix.model';
import { concatMap, reduce } from 'rxjs/operators';
import { CrossTabTableData, DataItem, Survey, Target } from '../models';
import { DecimalPointService } from './decimal-point.service';

@Injectable({
  providedIn: 'root',
})
export class GeografixService {
  constructor(
    private apiService: CorrespondenceApiService,
    private decimalPointService: DecimalPointService
  ) {}

  public loadTopoJson(url: string): Observable<any> {
    return new Observable<any>((observer) => {
      fetch(url, {
        method: 'GET',
      })
        .then((res: Response) => {
          return res.json();
        })
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public getAllGeoCodings(body: GeoCodingsRequest): Observable<GeoCodingMap> {
    return this.getGeoCodings(body).pipe(
      concatMap((data) => of(...data)),
      reduce(
        (acc, current: GeoCodingItem) => ({
          ...acc,
          [current['data-reference']]: current.geoData,
        }),
        {}
      )
    );
  }

  public formatSeriesData(
    crosstabData: CrossTabTableData[],
    survey: Survey,
    column: Target,
    dataItem: DataItem,
    geoCodingMap: GeoCodingMap
  ) {
    const targetIndex = crosstabData[0].data
      .map(
        (cell) =>
          `${cell.type}_${cell.surveyCode}_${
            cell.columnTarget?.coding || 'totals'
          }`
      )
      .findIndex(
        (cellSurveyCode) =>
          cellSurveyCode === `target_${survey.code}_${column.coding}`
      );
    const decimalPoints = this.decimalPointService.getDecimalPoints(dataItem);
    return crosstabData
      .map((row) => ({
        key: geoCodingMap[row.data[targetIndex].rowTarget?.coding] || '',
        value: parseFloat(
          row.data[targetIndex][dataItem.cellKey].toFixed(decimalPoints)
        ),
      }))
      .filter((row) => row.key !== '');
  }

  private getGeoCodings(body: GeoCodingsRequest): Observable<GeoCodingItem[]> {
    return this.apiService
      .request(
        'POST',
        environment.api.codebook.url,
        environment.api.codebook.endPoint.getgeocodings,
        { body }
      )
      .pipe(
        concatMap((response: GeoCodingsResponse) => {
          const scrollId = response.scrollId;
          if (scrollId) {
            return this.getGeoCodings({
              ...body,
              scrollId,
            });
          } else {
            return of(response.children);
          }
        })
      );
  }
}
