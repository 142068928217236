<div
  cdkDrag
  [cdkDragBoundary]="dragBoundary"
>
  <button
    *ngIf="showScrollToTopBtn"
    mat-mini-fab
    color="primary"
    cdkDragHandle
    class="scroll-to-top__btn"
  >
    <mat-icon
      class="scroll-to-top__icon"
      matTooltip="Scroll to top"
      (click)="onClick()"
    >
      arrow_upward
    </mat-icon>
  </button>
</div>
