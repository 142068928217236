import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventHandlerDirective,
  TupDocumentStorageComponent,
  TupDocumentStorageOptions,
  TupDocumentTypeId,
  TupUserContainerService,
  getColumn,
} from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { merge } from 'lodash';
import { Subject } from 'rxjs';
import { SurveyTimeDocument } from 'src/app/models';
import { CreateNewDocumentService, ImportService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-quick-reports',
  templateUrl: './dashboard-quick-reports.component.html',
  styleUrls: ['./dashboard-quick-reports.component.scss'],
})
export class DashboardQuickReportsComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  @ViewChild(TupDocumentEventHandlerDirective, { static: false })
  public documentEventHandler: TupDocumentEventHandlerDirective;
  @ViewChild(TupDocumentStorageComponent, { static: true })
  public documentStorage: TupDocumentStorageComponent;
  @ViewChild('deleteDocDialogContent') deleteDocDialogContent: TemplateRef<any>;
  @ViewChild('deleteSharedDocDialogContent')
  deleteSharedDocDialogContent: TemplateRef<any>;
  @ViewChild('deleteOtherDocDialogContent')
  deleteOtherDocDialogContent: TemplateRef<any>;

  public isLoading = false;
  public isPageLoading = false;
  public documentOwnerName = '';
  public documentName = '';
  public userEmail: string;

  public readonly tableOptions: TupDocumentStorageOptions = merge(
    {},
    environment.tupDocumentStorage.options,
    {
      types: [TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE],
      documentReplacementText: 'quick report',
      columns: [
        getColumn(environment, 'actions'),
        getColumn(environment, 'metadata.name.keyword', {
          header: 'My quick reports',
        }),
        getColumn(environment, 'metadata.description'),
        getColumn(environment, 'metadata.by.attributes.name.keyword'),
        getColumn(environment, 'metadata.created'),
        getColumn(environment, 'metadata.modified'),
        getColumn(environment, 'document.content.surveys'),
      ],
    }
  );
  constructor(
    private router: Router,
    private userMessageService: TupUserMessageService,
    private importService: ImportService,
    private userContainerService: TupUserContainerService,
    private createNewDocumentService: CreateNewDocumentService,
    private authService: TupAuthService
  ) {}

  ngOnInit(): void {
    this.getUserEmail();
    this.userContainerService.getContainer();
    // Handle default TupDocumentEvents
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      if (event.data) {
        const document = event.document;
        switch (event.data.action) {
          case 'edit':
            this.openExisting(event.document);
            break;
        }
      } else {
        this.openExisting(event.document);
      }
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public import(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.isLoading = true;
    const file = target.files[0];
    const currentContainer = this.userContainerService.getContainer();
    this.importService
      .importJSON(
        file,
        this.userEmail,
        TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE,
        currentContainer.name
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.documentEventHandler.reload.emit(true);
        },
        (error) => {
          this.isLoading = false;
          const duration = 5000;
          this.userMessageService.showSnackBar(
            `Error importing document - ${file.name}: document isn't valid`,
            'OK',
            duration
          );
        }
      );
  }

  public createQuickReport(): void {
    const isTemplateDocType = true;
    this.createNewDocumentService.create(isTemplateDocType);
  }

  public openExisting(doc: TupDocument<unknown>): void {
    const currentDoc = Object.assign(doc) as TupDocument<SurveyTimeDocument>;
    this.router.navigate([`doc/${currentDoc.metadata.id}/chart`]);
    return;
  }

  private getUserEmail() {
    this.userEmail = this.authService.user.attributes.email;
  }
}
