import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscriber } from 'rxjs';
import {
  ALL_RESPONDENTS_CODING,
  CodingDataMap,
  ColumnFilter,
  ColumnFilterOperator,
  CrossTabRequestBody,
  CrossTabRequestCell,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemDataType,
  RawCrossTabulationResponse,
  RawMaxCell,
  RawSurveyMatrixData,
  RawTrendingResponse,
  ReportMode,
  SortDirection,
  Survey,
  Target,
  TrendingRequestBody,
  TrendingCalculationItem,
  TargetColumn,
  DEFAULT_WEIGHT_INDEX,
  SortSettings,
  ColumnHeaderFilter,
  ReportPreference,
  AdditionalOptionToAdditionalIndexData,
  ALL_RESPONDENTS_TITLE,
  DEFAULT_CROSSTAB_CHUNKED_RETRIES,
  SurveyProvider,
  TargetItem,
  IgnoreZeroWeightedResps,
} from '../models';
import { ApiService } from './api.service';
import { RequestLoadingService } from './request-loading.service';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { environment } from 'src/environments/environment';
import { DataItemsService } from './data-items.service';
import { TrendingCalculationService } from './trending-calculation.service';
import { CodingDataMapService } from './coding-data-map.service';
import { SurveyColors } from '../builders/crosstab-table-xlsx.builder';
import { SurveyBgColorPipe } from '../pipes';
import { ReportPreferencesService } from './report-preferences.service';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { map, switchMap } from 'rxjs/operators';
import {
  NTILE_END_ADI_OPTION,
  NTILE_FUNCTION_WITH_ZERO_INCLUDED,
  NTILE_START_ADI_OPTION,
} from '../models/n-tiles.model';
import { DecimalPointService } from './decimal-point.service';

@Injectable({
  providedIn: 'root',
})
export class CrosstabService {
  private crossTabData = new BehaviorSubject<CrossTabTableData[]>([]);
  public crossTabData$ = this.crossTabData.asObservable();

  private targetColumnsSubject: BehaviorSubject<TargetColumn[]> =
    new BehaviorSubject<TargetColumn[]>(null);
  public targetColumns$: Observable<TargetColumn[]> =
    this.targetColumnsSubject.asObservable();

  private codingDataMap = new BehaviorSubject<CodingDataMap>({});
  public codingDataMap$ = this.codingDataMap.asObservable();

  // tslint:disable-next-line:variable-name
  private _filteredSortedCrossTabData: CrossTabTableData[] = [];
  private filteredSortedCrossTabData = new BehaviorSubject<CrossTabTableData[]>(
    this._filteredSortedCrossTabData
  );
  public filteredSortedCrossTabData$ =
    this.filteredSortedCrossTabData.asObservable();

  private crossTabModifiedData = new BehaviorSubject<TargetItem[]>([]);
  public crossTabModifiedData$ = this.crossTabModifiedData.asObservable();

  private cachedFormattedCodingDataMapForTables: CodingDataMap = {};

  private activeReportMode: ReportMode;

  private prevSortSettings: SortSettings | SortSettings[];
  private prevColumnFilters: ColumnHeaderFilter[];

  private prevCrosstabRequestPayloadForTables: {
    activeSurvey: Survey;
    activeTable: Target;
    tables: string[];
    weight: number;
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps;
  };
  private prevCrosstabDataForTables: CrossTabTableData[];

  constructor(
    private apiService: ApiService,
    private messageService: TupUserMessageService,
    private requestLoadingService: RequestLoadingService,
    private trendingCalculationService: TrendingCalculationService,
    private dataItemsService: DataItemsService,
    private surveyBackgroundColor: SurveyBgColorPipe,
    private codingDataMapService: CodingDataMapService,
    private reportPreferencesService: ReportPreferencesService,
    private decimalPointService: DecimalPointService
  ) {
    this.listenToReportModeChanges();
    this.listenToColumnSortAndFiltersChanges();
  }

  public cleanCrossTabData() {
    this.crossTabData.next([]);
    this.filteredSortedCrossTabData.next([]);
  }

  public updateCrossTabData(
    activeSurvey: Survey,
    surveys: Survey[],
    tables: Target[],
    columns: Target[],
    rows: Target[],
    weight: number,
    table: Target,
    shouldIncludeWeightedProfilesAdi: boolean,
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps
  ): void {
    this.requestLoadingService.setLoading({
      target: 'crosstab',
      isLoading: true,
    });
    this.prepareCrosstabData(
      activeSurvey,
      surveys,
      tables,
      columns,
      rows,
      weight,
      table,
      shouldIncludeWeightedProfilesAdi,
      ignoreZeroWeightedResps
    ).subscribe(
      ([crossTabData, tableData]: [
        CrossTabTableData[],
        CrossTabTableData[]
      ]) => {
        this.emitRawCrossTabData(crossTabData);
        this.emitCodingDataMap(activeSurvey, crossTabData, tableData);
        this.requestLoadingService.setLoading({
          target: 'crosstab',
          isLoading: false,
        });
      },
      (error) => {
        console.log(`error`, error);
        this.messageService.showSnackBar(error, 'OK', 10000);
        this.requestLoadingService.setLoading({
          target: 'crosstab',
          isLoading: false,
        });
      }
    );
  }

  public getTableColumnTarget(columnId: string): Target | null {
    const columnIndex = this.getTableColumnIndex(columnId);
    return this._filteredSortedCrossTabData[0].data[columnIndex].columnTarget;
  }

  public getColumnIdVolumetricCodingMap(
    data?: CrossTabTableData[]
  ): Set<string> {
    const crossTabData = data || this.crossTabData.value;
    const columnIdVolumetricCodingSet: Set<string> = new Set();
    this.getTargetColumns(crossTabData).forEach(
      (column: TargetColumn, columnIndex: number) => {
        if (
          crossTabData.filter(
            (crossTableData: CrossTabTableData) =>
              crossTableData.data[columnIndex].metadata?.isVolumetricCoding
          ).length > 0
        ) {
          columnIdVolumetricCodingSet.add(column.columnId);
        }
      }
    );

    return columnIdVolumetricCodingSet;
  }

  public getTargetColumns(data: CrossTabTableData[]): TargetColumn[] {
    const totalRow =
      data.find((row: CrossTabTableData) => row.isTotalRow) || data[0];
    const targetColumns = [
      ...totalRow.data.map((cell: CrossTabTableDataCell, index: number) => ({
        columnId: this.formatTargetColumnId(cell.columnTarget, cell.surveyCode),
        name: `#title_${index}`,
        target: cell.columnTarget,
        title: cell.title,
        position: index,
        element: cell,
      })),
    ];

    return targetColumns;
  }

  public getSurveyColors(
    data: CrossTabTableData[],
    surveys: Survey[]
  ): SurveyColors {
    const surveyColors = {};
    data[0].data.forEach((cell: CrossTabTableDataCell) => {
      surveyColors[cell.surveyCode] = this.surveyBackgroundColor.transform(
        cell.surveyCode,
        surveys
      );
    });
    return surveyColors;
  }

  private prepareCrosstabData(
    activeSurvey: Survey,
    surveys: Survey[],
    tables: Target[],
    columns: Target[],
    rows: Target[],
    weight: number,
    table: Target,
    shouldIncludeWeightedProfilesAdi: boolean,
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps
  ): Observable<[CrossTabTableData[], CrossTabTableData[]]> {
    if (rows.length > 0) {
      this.reportPreferencesService.updateAffinityRow(rows);
      if (!this.isNTilesCoding(rows, columns)) {
        this.dataItemsService.removeNTilesDataItems(this.activeReportMode);
      }
    }

    return forkJoin([
      this.loadCrossTabData(
        activeSurvey,
        surveys,
        columns,
        rows,
        weight,
        table,
        shouldIncludeWeightedProfilesAdi,
        ignoreZeroWeightedResps
      ),
      this.shouldRequestForCodingTables(
        activeSurvey,
        tables,
        weight,
        table,
        ignoreZeroWeightedResps
      )
        ? this.loadCrossTabData(
            activeSurvey,
            [],
            tables,
            [],
            weight,
            table,
            shouldIncludeWeightedProfilesAdi,
            ignoreZeroWeightedResps
          )
        : of(this.prevCrosstabDataForTables),
    ]);
  }

  public loadCrossTabData(
    activeSurvey: Survey,
    surveys: Survey[],
    columns: Target[],
    rows: Target[],
    weight: number,
    table: Target,
    shouldIncludeWeightedProfilesAdi: boolean,
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps
  ): Observable<CrossTabTableData[]> {
    const additionalOptions = this.dataItemsService
      .getAllActiveDataItems()
      .filter((item: DataItem) => !!item.adi)
      .map((item: DataItem) => item.adi);

    if (shouldIncludeWeightedProfilesAdi) {
      additionalOptions.push('WEIGHTED_PROFILES_POPULATION');
    }

    const trendingCalculations = this.trendingCalculationService
      .getTrendingCalculations()
      .map((calculation: TrendingCalculationItem) => calculation.coding);
    return surveys.length > 1
      ? this.trending(
          surveys,
          columns,
          rows,
          weight,
          table?.coding || ALL_RESPONDENTS_CODING,
          trendingCalculations,
          additionalOptions,
          ignoreZeroWeightedResps
        )
      : this.crossTab(
          activeSurvey,
          columns,
          rows,
          weight,
          table?.coding || ALL_RESPONDENTS_CODING,
          additionalOptions,
          ignoreZeroWeightedResps
        );
  }

  public emitPlaceholderCrossTabData(
    surveys: Survey[],
    rows: Target[],
    columns: Target[]
  ): void {
    this.emitRawCrossTabData(
      this.createPlaceholderCrossTabData(surveys, rows, columns)
    );
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$.subscribe((mode: ReportMode) => {
      this.activeReportMode = mode;
      if (this._filteredSortedCrossTabData.length > 0) {
        this.reportPreferencesService.setFirstColumnId(
          `totals_${this._filteredSortedCrossTabData[0].data[0].surveyCode}`
        );
      }
    });
  }

  private listenToColumnSortAndFiltersChanges(): void {
    this.reportPreferencesService.preference$
      .pipe(isNotNullOrUndefined())
      .subscribe((preference: ReportPreference) => {
        const shouldUpdateCrosstabData =
          !isEqual(this.prevSortSettings, preference.sortSettings) ||
          !isEqual(this.prevColumnFilters, preference.filters);
        this.prevSortSettings = cloneDeep(preference.sortSettings);
        this.prevColumnFilters = cloneDeep(preference.filters);
        if (this.crossTabData.value && shouldUpdateCrosstabData) {
          this.emitSortedAndFilteredCrossTabData();
        }
      });
  }

  public formatTargetColumnId(target: Target, surveyCode: string): string {
    return `${target?.id || 'totals'}_${surveyCode}`;
  }

  private crossTabulationRequest(body): Observable<RawCrossTabulationResponse> {
    return this.apiService.request(
      'POST',
      environment.api.crosstab.url,
      environment.api.crosstab.endPoint.crosstab,
      { body }
    );
  }

  private crossTabulationChunkRequest(
    body
  ): Observable<RawCrossTabulationResponse> {
    return this.apiService.request(
      'POST',
      environment.api.crosstab.url,
      environment.api.crosstab.endPoint.getChunkData,
      { body }
    );
  }

  private trendingRequest(body): Observable<RawTrendingResponse> {
    return this.apiService.request(
      'POST',
      environment.api.crosstab.url,
      environment.api.crosstab.endPoint.trending,
      { body }
    );
  }

  private trendingChunkRequest(body): Observable<RawTrendingResponse> {
    return this.apiService.request(
      'POST',
      environment.api.crosstab.url,
      environment.api.crosstab.endPoint.getTrendingChunkData,
      { body }
    );
  }

  private crossTab(
    survey: Survey,
    columns: Target[],
    rows: Target[],
    weightIndex: number,
    table: string = ALL_RESPONDENTS_CODING,
    additionalOptions: string[] = [],
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps
  ): Observable<CrossTabTableData[]> {
    return new Observable((observable: Subscriber<CrossTabTableData[]>) => {
      const [targets, insights] = this.getCrossTabRequestCells(columns, rows);
      const body: CrossTabRequestBody = {
        surveyCode: survey.code,
        authorizationGroup: survey.authorizationGroup,
        columns: targets.map(
          (requestCell: CrossTabRequestCell) => requestCell.coding
        ),
        rows: insights.map(
          (requestCell: CrossTabRequestCell) => requestCell.coding
        ),
        table,
        weightIndex: weightIndex || DEFAULT_WEIGHT_INDEX,
        adiOptions: additionalOptions,
        failOnMnemonicNotFound: false,
        ...(rows.length > 1 &&
          this.reportPreferencesService.affinityRow && {
            affinityBaseTarget:
              this.reportPreferencesService.affinityRow.coding,
          }),
      };

      if (ignoreZeroWeightedResps.editable) {
        body.ignoreZeroWeightedResps = ignoreZeroWeightedResps.enabled;
      }

      if (
        survey.provider === SurveyProvider.youGov &&
        this.reportPreferencesService.isCompleteCasesOn()
      ) {
        body.completeCaseMode = true;
      }

      this.crossTabulationRequest(body).subscribe(
        (data: RawCrossTabulationResponse) => {
          if (data.success || data.matrix?.length > 0) {
            if (data.chunkTokens?.length > 0) {
              const chunkRequests: Observable<RawCrossTabulationResponse>[] =
                data.chunkTokens.map((chunkToken: string) => {
                  const chunkBody = {
                    chunkToken,
                    adiOptions: additionalOptions,
                    numberOfRetries: DEFAULT_CROSSTAB_CHUNKED_RETRIES,
                  };
                  return this.crossTabulationChunkRequest(chunkBody);
                });
              forkJoin(chunkRequests).subscribe(
                (chunkResponses: RawCrossTabulationResponse[]) => {
                  const completeResponse: RawCrossTabulationResponse =
                    chunkResponses.reduce(
                      (
                        prev: RawCrossTabulationResponse,
                        curr: RawCrossTabulationResponse
                      ) => {
                        prev.matrix = prev.matrix.concat(curr.matrix);
                        return prev;
                      },
                      data
                    );
                  observable.next(
                    this.formatMatrix(
                      [
                        {
                          op: survey.code,
                          matrix: completeResponse.matrix,
                        },
                      ],
                      columns,
                      rows,
                      data?.adiOptionToAdiIndex
                        ? this.retrieveAdditionalOptionsFromResponse(
                            data.adiOptionToAdiIndex
                          )
                        : additionalOptions
                    )
                  );
                  observable.complete();
                },
                (error: any) => {
                  observable.error(error);
                }
              );
            } else {
              observable.next(
                this.formatMatrix(
                  [
                    {
                      op: survey.code,
                      matrix: data.matrix,
                    },
                  ],
                  columns,
                  rows,
                  data?.adiOptionToAdiIndex
                    ? this.retrieveAdditionalOptionsFromResponse(
                        data.adiOptionToAdiIndex
                      )
                    : additionalOptions
                )
              );
              observable.complete();
            }
          } else {
            observable.error(this.formatCrossTabRequestError(data));
          }
        },
        (error) => {
          observable.error(error);
        }
      );
    });
  }

  private trending(
    surveys: Survey[],
    columns: Target[],
    rows: Target[],
    weightIndex: number,
    table: string = ALL_RESPONDENTS_CODING,
    trendingOps: string[],
    additionalOptions: string[] = [],
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps
  ): Observable<CrossTabTableData[]> {
    return new Observable((observable: Subscriber<CrossTabTableData[]>) => {
      const [targets, insights] = this.getCrossTabRequestCells(columns, rows);
      const body: TrendingRequestBody = {
        surveyCodes: surveys.map((survey: Survey) => survey.code),
        authorizationGroups: surveys.map(
          (survey: Survey) => survey.authorizationGroup
        ),
        columns: targets.map(
          (requestCell: CrossTabRequestCell) => requestCell.coding
        ),
        rows: insights.map(
          (requestCell: CrossTabRequestCell) => requestCell.coding
        ),
        table,
        trendingOps,
        weightIndex: weightIndex || DEFAULT_WEIGHT_INDEX,
        adiOptions: additionalOptions,
        failOnMnemonicNotFound: false,
        ...(this.reportPreferencesService.affinityRow && {
          affinityBaseTarget: this.reportPreferencesService.affinityRow.coding,
        }),
      };

      if (ignoreZeroWeightedResps.editable) {
        body.ignoreZeroWeightedResps = ignoreZeroWeightedResps.enabled;
      }

      if (
        surveys[0].provider === SurveyProvider.youGov &&
        this.reportPreferencesService.isCompleteCasesOn()
      ) {
        body.completeCaseMode = true;
      }

      this.trendingRequest(body)
        .pipe(
          switchMap((data: RawTrendingResponse) => {
            if (data.chunkTokenLists?.length > 0) {
              const chunkIdList = data.chunkTokenLists;
              const chunkRequests = chunkIdList.map((chunkArray) => {
                const chunkBody = {
                  chunkIdList: chunkArray,
                  adiOptions: additionalOptions,
                  numberOfRetries: DEFAULT_CROSSTAB_CHUNKED_RETRIES,
                  trendingOps,
                };
                return this.getChunkedResponse(chunkBody).pipe(
                  map((response: RawTrendingResponse) => response)
                );
              });

              const matrices = forkJoin([...chunkRequests]).pipe(
                map((responses) => {
                  // tslint:disable-next-line:no-shadowed-variable
                  const matrices = data.data.map((item, i) => {
                    let matrix = item.matrix;
                    let op = item.op;
                    responses.forEach((response) => {
                      const responseItem = response.data.find(
                        (res) => res.op === item.op
                      );
                      if (responseItem) {
                        matrix = matrix.concat(responseItem.matrix);
                        op = responseItem.op;
                      }
                    });
                    return {
                      op,
                      matrix,
                      adiOptionToAdiIndex: data?.adiOptionToAdiIndex,
                    };
                  });

                  return matrices;
                })
              );
              return matrices;
            } else {
              if (data.success || data.data?.length > 0) {
                return of(
                  data.data.map((matrixData) => ({
                    ...matrixData,
                    adiOptionToAdiIndex: data?.adiOptionToAdiIndex,
                  }))
                );
              } else {
                throw this.formatCrossTabRequestError(data);
              }
            }
          })
        )
        .subscribe(
          (mergedData) => {
            observable.next(
              this.formatMatrix(
                mergedData,
                columns,
                rows,
                mergedData[0]?.adiOptionToAdiIndex
                  ? this.retrieveAdditionalOptionsFromResponse(
                      mergedData[0].adiOptionToAdiIndex
                    )
                  : additionalOptions
              )
            );
            observable.complete();
          },
          (error: any) => {
            observable.error(error);
          }
        );
    });
  }

  private getChunkedResponse(body): Observable<RawTrendingResponse> {
    return this.trendingChunkRequest(body);
  }

  private formatCrossTabRequestError(
    data: RawCrossTabulationResponse | RawTrendingResponse
  ): string {
    if (data.message.indexOf('Survey mnemonics could not be found') !== -1) {
      return (
        'Your data selection is incompatible with the existing data set. ' +
        'Please clear your rows and columns and start over.'
      );
    }
    return data.message;
  }

  private getCrossTabRequestCells(
    columns: Target[],
    rows: Target[]
  ): CrossTabRequestCell[][] {
    let targets: CrossTabRequestCell[] =
      this.formatCrossTabRequestCells(columns);
    let insights: CrossTabRequestCell[] = this.formatCrossTabRequestCells(rows);

    const noColumnsAndRows = columns.length < 1 && rows.length < 1;
    const hasEmptyColumnsOrRows = columns.length < 1 || rows.length < 1;
    const emptyCodingRequestCell: CrossTabRequestCell[] = [
      {
        title: '',
        coding: ALL_RESPONDENTS_CODING,
      },
    ];
    if (noColumnsAndRows) {
      insights = targets = emptyCodingRequestCell;
    }

    if (hasEmptyColumnsOrRows) {
      if (targets.length > 0) {
        insights = emptyCodingRequestCell;
      } else {
        targets = emptyCodingRequestCell;
      }
    }
    return [targets, insights];
  }

  private formatCrossTabRequestCells(targets: Target[]): CrossTabRequestCell[] {
    return targets.map((target: Target) => ({
      title: target.title,
      coding: target.coding,
    }));
  }

  private formatMatrix(
    data: RawSurveyMatrixData[],
    columns: Target[],
    rows: Target[],
    additionalOptions: string[],
    isPlaceholder?: boolean
  ): CrossTabTableData[] {
    const tableData: CrossTabTableData[] = [];
    const surveyData = data;
    const matrixRowCount = data[0].matrix.length;
    const matrixColumnCount = data[0].matrix[0].length;
    const rowTitles = rows.map((target: Target) => target.title);
    const columnTitles = columns.map((target: Target) => target.title);
    const hasNoRows = rows.length < 1;
    const hasNoColumns = columns.length < 1;

    const rankDataItems = this.dataItemsService
      .getActiveDataItems(this.activeReportMode)
      .filter((item: DataItem) => item.type === DataItemDataType.rank);
    const shouldSortDataByRankItems = rankDataItems.length > 0;
    const columnCells: CrossTabTableDataCell[][] = [];

    // Data Item for n-tile is selected/unselected only in presence of NTile ADIs/coding
    const isNTilesCoding = this.isNTilesCoding(rows, columns);

    if (
      !(hasNoRows && hasNoColumns) &&
      ![...rowTitles, ...columnTitles].includes(ALL_RESPONDENTS_TITLE)
    ) {
      this.dataItemsService.toggleNTilesDataItems(
        this.activeReportMode,
        isNTilesCoding,
        additionalOptions
      );
    }

    let rowIndex = 0;
    for (let row = 0; row < matrixRowCount; row++) {
      // duplicate rows will be returned if no rows are present
      if (row === 0 && hasNoRows) {
        continue;
      }

      const isTotalRow = rowIndex === 0;
      const cells: CrossTabTableDataCell[] = [];
      let columnIndex = 0;
      for (
        let surveyColumn = 0;
        surveyColumn < matrixColumnCount;
        surveyColumn++
      ) {
        // duplicate columns will be returned if no columns are provided
        if (surveyColumn === 0 && hasNoColumns) {
          continue;
        }

        surveyData.forEach((dataItem: RawSurveyMatrixData) => {
          const surveyCode = dataItem.op.toUpperCase();
          const surveyMatrixRow = dataItem.matrix[row];

          // Remove NTiles additional option if not NTilesCoding
          if (additionalOptions.length > 0) {
            additionalOptions = isNTilesCoding
              ? additionalOptions
              : additionalOptions.filter(
                  (option) =>
                    ![NTILE_START_ADI_OPTION, NTILE_END_ADI_OPTION].includes(
                      option
                    )
                );
          }

          const isInsight = surveyColumn < 1 || hasNoColumns;
          const title =
            isTotalRow && isInsight
              ? 'Totals'
              : isInsight
              ? rowTitles[row - 1]
              : columnTitles[surveyColumn - 1];

          const baseColumnData = !surveyMatrixRow[surveyColumn]
            ? {
                projected: null,
                sample: null,
                stability: null,
                validPerc: null,
                index: null,
                row: null,
                column: null,
                metadata: {
                  isVolumetricCoding: false,
                },
              }
            : {
                projected: surveyMatrixRow[surveyColumn].proj,
                sample: surveyMatrixRow[surveyColumn].sample,
                stability: surveyMatrixRow[surveyColumn].stability,
                validPerc: surveyMatrixRow[surveyColumn].validPerc,
                index: surveyMatrixRow[surveyColumn].index,
                row: surveyMatrixRow[surveyColumn].rowindex,
                column: surveyMatrixRow[surveyColumn].colindex,
                metadata: {
                  isVolumetricCoding:
                    surveyMatrixRow[surveyColumn].validPerc === 1,
                },
                ...(surveyMatrixRow[surveyColumn] &&
                'adis' in surveyMatrixRow[surveyColumn] &&
                surveyMatrixRow[surveyColumn].adis.filter(
                  (item) => typeof item !== 'number'
                ).length === 0
                  ? surveyMatrixRow[surveyColumn].adis.reduce(
                      (prev, value: number, index: number) => {
                        return {
                          ...prev,
                          [additionalOptions[index]]: value,
                        };
                      },
                      {}
                    )
                  : {}),
              };
          const cellData: CrossTabTableDataCell = {
            type: !isInsight ? 'target' : 'insight',
            isTotalsColumn: isInsight,
            columnPosition: hasNoColumns ? 0 : surveyColumn,
            title,
            ...baseColumnData,
            columnTarget:
              surveyColumn > 0 ? columns[surveyColumn - 1] : undefined,
            rowTarget: row > 0 ? rows[row - 1] : undefined,
            isAffinityRow:
              this.reportPreferencesService.isInAffinityReportMode() && row > 0
                ? rows[row - 1] === this.reportPreferencesService.affinityRow
                : false,
            affinityScore:
              surveyMatrixRow[surveyColumn]?.affinityScore ?? undefined,
            surveyCode,
            rowIndex,
          };
          cells.push(cellData);

          if (shouldSortDataByRankItems) {
            if (!(columnIndex in columnCells)) {
              columnCells[columnIndex] = [];
            }
            columnCells[columnIndex].push(cellData);
          }
          columnIndex++;
        });
      }

      tableData.push({
        position: rowIndex,
        rowIndex,
        index: rowIndex,
        title: cells[0].title,
        isTotalRow,
        data: cells,
        isPlaceholder,
        metadata: {
          hasVolumetricCoding:
            cells.filter(
              (cell: CrossTabTableDataCell) => cell.metadata?.isVolumetricCoding
            ).length > 0,
        },
      });
      rowIndex++;
    }

    return isPlaceholder || !shouldSortDataByRankItems
      ? tableData
      : this.sortTableDataByRankItems(tableData, rankDataItems, columnCells);
  }

  private sortTableDataByRankItems(
    tableData: CrossTabTableData[],
    rankDataItems: DataItem[],
    columnCells: CrossTabTableDataCell[][]
  ): CrossTabTableData[] {
    if (rankDataItems.length > 0) {
      columnCells.forEach(
        (columnItems: CrossTabTableDataCell[], columnIndex: number) => {
          rankDataItems.forEach((item: DataItem) => {
            const dependsOnCellKey = item.dependsOnCellKey;
            const cellKey = item.cellKey;
            // ignore totals row cells
            let sortedCells;
            if (cellKey === 'affinityRank') {
              // only rank the first totals column
              if (columnIndex > 0) {
                return;
              }
              sortedCells = columnItems
                .slice(1)
                .filter(
                  (cell: CrossTabTableDataCell) =>
                    cell.affinityScore !== undefined
                )
                .sort(
                  (a: CrossTabTableDataCell, b: CrossTabTableDataCell) =>
                    a[dependsOnCellKey] - b[dependsOnCellKey]
                );
            } else {
              tableData[0].data[columnIndex][cellKey] = 0;
              sortedCells = columnItems
                .slice(1)
                .sort((a: CrossTabTableDataCell, b: CrossTabTableDataCell) =>
                  a[dependsOnCellKey] === b[dependsOnCellKey]
                    ? 0
                    : a[dependsOnCellKey] > b[dependsOnCellKey]
                    ? -1
                    : 1
                );
            }
            sortedCells.forEach(
              (sortedRankCell: CrossTabTableDataCell, rankIndex: number) => {
                tableData[sortedRankCell.rowIndex].data[columnIndex][cellKey] =
                  rankIndex + 1;
              }
            );
          });
        }
      );
    }

    return tableData;
  }

  private createPlaceholderCrossTabData(
    surveys: Survey[],
    rows: Target[],
    columns: Target[]
  ): CrossTabTableData[] {
    const emptyRows = rows.length === 0;
    const emptyColumns = columns.length === 0;
    const data: RawSurveyMatrixData[] = surveys.map((survey: Survey) => {
      const matrix: RawMaxCell[][] = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i <= rows.length; i++) {
        const row = [];
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j <= columns.length; j++) {
          const title =
            j !== 0
              ? columns[j - 1].title
              : i !== 0
              ? rows[i - 1].title
              : 'Totals';
          const cellData = {
            position: i,
            title,
            projected: 0,
            sample: 0,
            stability: 0,
            validPerc: 0,
            index: 0,
            row: 0,
            column: 0,
          };
          row.push(cellData);
          if (emptyColumns) {
            row.push(cellData);
          }
        }

        matrix.push(row);
        if (emptyRows) {
          matrix.push(row);
        }
      }
      return {
        op: survey.code,
        matrix,
      };
    });

    return this.formatMatrix(data, columns, rows, [], true);
  }

  private emitRawCrossTabData(data: CrossTabTableData[]): void {
    this.crossTabData.next(data);
    this.emitSortedAndFilteredCrossTabData();
  }

  private emitCodingDataMap(
    survey: Survey,
    crosstabData: CrossTabTableData[],
    crosstabDataForCodingTables: CrossTabTableData[]
  ): void {
    this.cachedFormattedCodingDataMapForTables =
      this.codingDataMapService.formatCodingDataMap(
        survey,
        crosstabDataForCodingTables
      );
    this.prevCrosstabDataForTables = crosstabDataForCodingTables;
    this.codingDataMap.next({
      ...this.codingDataMapService.formatCodingDataMap(survey, crosstabData),
      ...this.cachedFormattedCodingDataMapForTables,
    });
  }

  private emitSortedAndFilteredCrossTabData(): void {
    this._filteredSortedCrossTabData = this.shouldFilterCrossTabData()
      ? this.filterTableData(this.crossTabData.value)
      : [...this.crossTabData.value];

    const sortSettings = this.reportPreferencesService.getSortSettings();
    this._filteredSortedCrossTabData =
      this.reportPreferencesService.instanceOfSortSettings(sortSettings) &&
      this.shouldSortCrossTabData(sortSettings)
        ? this.formatSortedRows(
            this.sortTableData(this._filteredSortedCrossTabData, sortSettings),
            sortSettings
          )
        : this._filteredSortedCrossTabData;
    this.filteredSortedCrossTabData.next(this._filteredSortedCrossTabData);
  }

  private shouldFilterCrossTabData(): boolean {
    return (
      this.reportPreferencesService.hasColumnHeaderFilters() &&
      this.crossTabData.value.length > 0 &&
      !this.crossTabData.value[0].isPlaceholder
    );
  }

  private shouldSortCrossTabData(
    sortSettings: SortSettings | SortSettings[]
  ): boolean {
    return (
      this.reportPreferencesService.isSortActive() &&
      this._filteredSortedCrossTabData.length > 0
    );
  }

  private filterTableData(tableData: CrossTabTableData[]): CrossTabTableData[] {
    if (this.reportPreferencesService.hasSingleColumnFilter()) {
      return this.filterSingleColumnData(tableData);
    } else {
      return this.filterWholeTableData(tableData);
    }
  }

  private filterSingleColumnData(
    tableData: CrossTabTableData[]
  ): CrossTabTableData[] {
    const columnHeaderFilterMap =
      this.reportPreferencesService.getColumnFilterMap();

    const dataNotToFilter = tableData.map((crossTabData: CrossTabTableData) => {
      return {
        ...crossTabData,
        data: crossTabData.data.filter(
          (column: CrossTabTableDataCell) =>
            (!columnHeaderFilterMap.includes(column.columnTarget?.id) &&
              column.type === 'target') ||
            (column.type === 'insight' &&
              !columnHeaderFilterMap.includes('totals'))
        ),
      };
    });

    const filteredTableDateSets: Record<string, CrossTabTableData[]> = ({} =
      {});
    columnHeaderFilterMap.forEach((columnId: string) => {
      filteredTableDateSets[columnId] = this.getFilteredTableData(
        columnId,
        cloneDeep(tableData)
      );
    });

    const mergeFilterData: CrossTabTableData[] = dataNotToFilter.map(
      (crossTabData: CrossTabTableData) => {
        let data: CrossTabTableDataCell[] = crossTabData.data;
        let filteredTableData: CrossTabTableData;

        // tslint:disable-next-line:forin
        for (const newFilterData in filteredTableDateSets) {
          filteredTableData = filteredTableDateSets[newFilterData].find(
            (filteredCrossTabData: CrossTabTableData) => {
              return filteredCrossTabData.position === crossTabData.position;
            }
          );

          if (filteredTableData) {
            data = data.concat(filteredTableData.data);
          }
        }

        data = sortBy(data, ['columnPosition']);

        return {
          ...crossTabData,
          data,
        };
      }
    );

    return mergeFilterData;
  }

  private getFilteredTableData(
    columnId: string,
    tableData: CrossTabTableData[]
  ): CrossTabTableData[] {
    const columnTableDataToFilter: CrossTabTableData[] = tableData.map(
      (crossTabData: CrossTabTableData) => {
        return {
          ...crossTabData,
          data: crossTabData.data.filter(
            (column: CrossTabTableDataCell) =>
              column.columnTarget?.id === columnId ||
              (column.type === 'insight' && columnId === 'totals')
          ),
        };
      }
    );

    return columnTableDataToFilter.map(
      (data: CrossTabTableData, index: number) => {
        const updatedTableData = data.data.map(
          (cellData: CrossTabTableDataCell) => ({
            ...cellData,
            filteredOutCell: !data.isTotalRow && !this.isValidTableRow(data),
          })
        );

        return {
          ...data,
          data: updatedTableData,
          rowIndex: index,
          filteredOut: !data.isTotalRow && !this.isValidTableRow(data),
        };
      }
    );
  }

  private filterWholeTableData(
    tableData: CrossTabTableData[]
  ): CrossTabTableData[] {
    return tableData
      .filter(
        (row: CrossTabTableData) => row.isTotalRow || this.isValidTableRow(row)
      )
      .map((data: CrossTabTableData, index: number) => ({
        ...data,
        rowIndex: index,
      }));
  }

  public sortTableData(
    tableData: CrossTabTableData[],
    sortSettings: SortSettings
  ): CrossTabTableData[] {
    const targetIndex = this.getTableColumnIndex(sortSettings.columnId);
    if (targetIndex === -1) {
      return tableData;
    }
    const cellKey = DATA_ITEMS_MAP[sortSettings.dataItem].cellKey;
    let tableRows: CrossTabTableData[] = tableData;
    const totalRow = tableRows.shift();
    const backupRows = tableRows.slice(0, sortSettings.startingRow - 1);
    tableRows.splice(0, sortSettings.startingRow - 1);

    if (sortSettings.order === SortDirection.desc) {
      tableRows = tableRows.sort(
        (a: CrossTabTableData, b: CrossTabTableData) =>
          (b.data[targetIndex][cellKey] || 0) -
          (a.data[targetIndex][cellKey] || 0)
      );
    } else {
      tableRows = tableRows.sort(
        (a: CrossTabTableData, b: CrossTabTableData) =>
          (a.data[targetIndex][cellKey] || 0) -
          (b.data[targetIndex][cellKey] || 0)
      );
    }

    return this.reindexTableData([totalRow, ...backupRows, ...tableRows]);
  }

  private getTableColumnIndex(columnId: string): number {
    const [id, surveyCode] = columnId.split('_');
    return this._filteredSortedCrossTabData[0].data.findIndex(
      (rowCell: CrossTabTableDataCell) =>
        (rowCell.columnTarget?.id || 'totals') === id &&
        rowCell.surveyCode === surveyCode
    );
  }

  private formatSortedRows(
    tableData: CrossTabTableData[],
    sortSettings: SortSettings
  ): CrossTabTableData[] {
    if (
      !this.reportPreferencesService.isSortActive() ||
      sortSettings.showTopOrBottomRows === ''
    ) {
      return tableData;
    }
    let formattedRows: CrossTabTableData[];
    if (sortSettings.showTopOrBottomRows === 'top') {
      formattedRows = tableData.slice(0, sortSettings.topRows + 1);
    } else if (sortSettings.showTopOrBottomRows === 'bottom') {
      formattedRows = [
        tableData.shift(),
        ...tableData.slice(-sortSettings.bottomRows),
      ];
    }
    return this.reindexTableData(formattedRows);
  }

  private reindexTableData(
    tableData: CrossTabTableData[]
  ): CrossTabTableData[] {
    return tableData.map((rowData: CrossTabTableData, index: number) => ({
      ...rowData,
      position: index,
      rowIndex: index,
    }));
  }

  private isValidTableRow(row: CrossTabTableData): boolean {
    return row.data.every((rowCell: CrossTabTableDataCell) => {
      const columnId = this.formatTargetColumnId(
        rowCell.columnTarget,
        rowCell.surveyCode
      );
      const filters: ColumnFilter[] =
        this.reportPreferencesService.getColumnHeaderFilters(columnId);
      if (filters.length < 1) {
        return true;
      }
      // tslint:disable-next-line:no-eval
      return eval(
        filters.reduce((prev: string, filter: ColumnFilter) => {
          const cellKey = DATA_ITEMS_MAP[filter.dataItem].cellKey;
          const cellValue =
            rowCell[cellKey] !== null && rowCell[cellKey] !== undefined
              ? parseFloat(
                  rowCell[cellKey].toFixed(
                    this.decimalPointService.getDecimalPoints(
                      DATA_ITEMS_MAP[filter.dataItem]
                    )
                  )
                )
              : rowCell[cellKey];
          const operator = filter.operator === 'AND' ? '&& ' : '||';
          return `${prev} ${operator} ${this.isFilterCriteriaMatched(
            cellValue,
            filter
          )}`;
        }, 'true')
      );
    });
  }

  private isFilterCriteriaMatched(
    cellValue: number,
    filter: ColumnFilter
  ): boolean {
    switch (filter.conditionalOperator) {
      case ColumnFilterOperator.greaterThan:
        return cellValue > filter.value[0];
      case ColumnFilterOperator.lessThan:
        return cellValue < filter.value[0];
      case ColumnFilterOperator.equal:
        return cellValue === filter.value[0];
      case ColumnFilterOperator.greaterThanOrEqual:
        return cellValue >= filter.value[0];
      case ColumnFilterOperator.lessThanOrEqual:
        return cellValue <= filter.value[0];
      case ColumnFilterOperator.between:
        const max = Math.max(filter.value[0], filter.value[1]);
        const min = Math.min(filter.value[0], filter.value[1]);
        return cellValue <= max && cellValue >= min;
    }
  }

  private retrieveAdditionalOptionsFromResponse(
    adiOptionToAdiIndex: AdditionalOptionToAdditionalIndexData
  ) {
    if (!adiOptionToAdiIndex) {
      return [];
    }

    return Object.keys(adiOptionToAdiIndex).reduce((prev, current) => {
      const index = adiOptionToAdiIndex[current];
      prev[index] = current;
      return prev;
    }, []);
  }

  private isNTilesCoding(rows: Target[], columns: Target[]) {
    return (
      [...rows, ...columns].filter((target) =>
        target.coding.includes(NTILE_FUNCTION_WITH_ZERO_INCLUDED)
      ).length > 0
    );
  }

  public setTargetColumns(data: TargetColumn[]) {
    this.targetColumnsSubject.next(data);
  }

  public updateCrossTabModifiedData(targetItems: TargetItem[]) {
    this.crossTabModifiedData.next(targetItems);
  }

  private shouldRequestForCodingTables(
    activeSurvey: Survey,
    tables: Target[],
    weight: number,
    table: Target,
    ignoreZeroWeightedResps: IgnoreZeroWeightedResps
  ): boolean {
    const newRequestForTables = {
      activeSurvey,
      activeTable: table,
      tables: tables.map((target) => target.coding),
      weight,
      ignoreZeroWeightedResps,
    };
    const shouldRequestForCodingTables = !isEqual(
      this.prevCrosstabRequestPayloadForTables,
      newRequestForTables
    );
    this.prevCrosstabRequestPayloadForTables = cloneDeep(newRequestForTables);
    return shouldRequestForCodingTables;
  }
}
