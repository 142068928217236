import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import {
  AudienceEffectsViewType,
  COMPATIBLE_EXPORT_FORMATS,
  ClusterViewType,
  CorrespondenceViewType,
  DEFAULT_IMAGE_EXPORT_FORMATS,
  DEFAULT_TABLE_EXPORT_FORMATS,
  DocumentViewType,
  ExportFileType,
  SelectedResultMode,
  Target,
} from 'src/app/models';
import { TupUserMessageService } from '@telmar-global/tup-user-message';

export const EXPORT_FORMAT_LABELS = {
  [ExportFileType.csv]: 'CSV',
  [ExportFileType.asc]: 'ASC',
  [ExportFileType.xlsx]: 'XLSX',
  [ExportFileType.googleSheets]: 'Google Sheets',
  [ExportFileType.googleSlides]: 'Google Slides',
  [ExportFileType.pptx]: 'PPT',
  [ExportFileType.jpeg]: 'JPEG',
  [ExportFileType.png]: 'PNG',
  [ExportFileType.svg]: 'SVG',
  [ExportFileType.pdf]: 'PDF',
};

export interface TableBaseCheckboxOption {
  tableBase: Target;
  selected: boolean;
}

export interface ExportFormatOptions {
  label: string;
  name: ExportFileType;
  checked: boolean;
}

export interface ExportDialogDataModel {
  docName: string;
  tableBases: Target[];
  activeTablebase: Target;
  selectViewType: DocumentViewType;
  selectViewResult:
    | ClusterViewType
    | CorrespondenceViewType
    | AudienceEffectsViewType;
  selectedAudienceEffectsViewResultMode?: SelectedResultMode;
}

export interface ExportDialogResult {
  docName: string;
  selectedFormat: ExportFileType;
  selectedTableBases: Target[];
}

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
  public readonly maxTablebase = 10;
  public readonly documentViewType = DocumentViewType;
  public readonly exportFileType = ExportFileType;
  public docName: string;
  public exportFormatOptions: ExportFormatOptions[];
  public tableBaseOptions: TableBaseCheckboxOption[];
  public selectAll = false;
  public selectedFormat: ExportFileType;
  public selectedTablebases: Target[];
  public selectViewType: DocumentViewType;
  public selectViewResult:
    | ClusterViewType
    | CorrespondenceViewType
    | AudienceEffectsViewType;
  public selectedAudienceEffectsViewResultMode: SelectedResultMode;
  public activeTablebase: Target;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    private messageService: TupUserMessageService
  ) {
    this.docName = data.docName;
    this.activeTablebase = data.activeTablebase;
    this.selectViewType = data.selectViewType;
    this.selectViewResult = data.selectViewResult;
    this.selectedAudienceEffectsViewResultMode =
      data.selectedAudienceEffectsViewResultMode;

    this.populateExportFormatOptions(this.selectViewType);
    this.populateSelectableTargetBaseOptions(
      data.tableBases,
      data.activeTablebase
    );
  }

  public onClose() {
    this.dialogRef.close(null);
  }

  public onExport() {
    this.dialogRef.close({
      docName: this.docName,
      selectedFormat: this.selectedFormat,
      selectedTableBases: this.tableBaseOptions
        .filter((option) => option.selected)
        .map((option) => option.tableBase),
    });
  }

  public populateExportFormatOptions(selectViewType: any): void {
    const compatibleFormats: ExportFormatOptions[] = COMPATIBLE_EXPORT_FORMATS[
      selectViewType
    ].map((format: ExportFileType, index) => ({
      name: format,
      label: EXPORT_FORMAT_LABELS[format],
      checked: index === 0,
    }));

    this.exportFormatOptions =
      this.getCompatibleFormatsForViewResults(compatibleFormats);

    this.selectedFormat = this.exportFormatOptions[0].name;
  }

  private populateSelectableTargetBaseOptions(
    tableBases: Target[],
    activeTablebase: Target
  ): void {
    this.tableBaseOptions = tableBases.map((tableBase: Target) => ({
      tableBase,
      selected: tableBase.id === activeTablebase.id,
    }));

    this.updateSelectedTablebases();
  }

  public getCompatibleFormatsForViewResults(
    formatOptions: ExportFormatOptions[]
  ): ExportFormatOptions[] {
    if (
      [
        DocumentViewType.data,
        DocumentViewType.graph,
        DocumentViewType.pmaps,
        DocumentViewType.geografix,
        DocumentViewType.factorAnalysis,
      ].includes(this.selectViewType)
    ) {
      return formatOptions;
    }

    if (this.selectViewType === DocumentViewType.audienceEffects) {
      if ((this.selectViewResult as AudienceEffectsViewType) === 'variable') {
        return formatOptions.filter((option) =>
          [...DEFAULT_TABLE_EXPORT_FORMATS, ExportFileType.pptx].includes(
            option.name
          )
        );
      } else {
        if (
          this.selectedAudienceEffectsViewResultMode ===
          SelectedResultMode.chaid
        ) {
          return formatOptions.filter((option) =>
            [ExportFileType.pptx, ...DEFAULT_IMAGE_EXPORT_FORMATS].includes(
              option.name
            )
          );
        } else {
          return formatOptions.filter((option) =>
            [
              ExportFileType.pptx,
              ExportFileType.csv,
              ExportFileType.asc,
              ExportFileType.xlsx,
              ExportFileType.googleSheets,
            ].includes(option.name)
          );
        }
      }
    } else {
      if (this.selectViewResult === 'table') {
        return formatOptions.filter((option) =>
          DEFAULT_TABLE_EXPORT_FORMATS.includes(option.name)
        );
      } else {
        return formatOptions.filter((option) =>
          [ExportFileType.pptx, ...DEFAULT_IMAGE_EXPORT_FORMATS].includes(
            option.name
          )
        );
      }
    }
  }

  public onTablebaseSelect(event: MatRadioChange) {
    this.tableBaseOptions.forEach((option) => {
      option.selected = option.tableBase.id === event.value.id;
    });
  }

  public onFormatChange() {
    if (
      [ExportFileType.csv, ExportFileType.asc].includes(this.selectedFormat)
    ) {
      this.tableBaseOptions.forEach((option) => {
        option.selected = option.tableBase.id === this.activeTablebase.id;
      });
    }

    if (this.selectedFormat === ExportFileType.xlsx) {
      this.selectAll = false;
    }

    this.updateSelectedTablebases(false);
  }

  public updateSelectedTablebases(shouldShowAlert: boolean = true) {
    this.selectedTablebases = this.tableBaseOptions
      .filter((option) => option.selected)
      .map((option) => option.tableBase);

    if (
      shouldShowAlert &&
      this.selectedTablebases.length >= this.maxTablebase
    ) {
      this.messageService.openMessageDialog(
        'Maximum number of tablebases has been selected.',
        'Alert'
      );
    }
  }
}
