import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { sortBy } from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CrosstabTableCsvBuilder } from 'src/app/builders/crosstab-table-csv.builder';
import { CrosstabTableXlsxBuilder } from 'src/app/builders/crosstab-table-xlsx.builder';
import {
  AudienceEffectsSettings,
  AudienceEffectsViewType,
  ChaidAnalysisResponseBody,
  GainTableRowItem,
  SelectedGainAnalysisMode,
  Survey,
} from 'src/app/models';
import { TargetTitlePipe } from 'src/app/pipes';
import { DocumentService } from 'src/app/services';
import { CsvService } from 'src/app/services/csv.service';
import { shadeColor } from 'src/app/utils/colorHelper';
import pptxgen from 'pptxgenjs';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-gain-analysis-table',
  templateUrl: './gain-analysis-table.component.html',
  styleUrls: ['./gain-analysis-table.component.scss'],
})
export class GainAnalysisTableComponent implements OnInit, OnChanges {
  @Input() selectedGainAnalysisMode: SelectedGainAnalysisMode;
  @Input() chaidAnalysis: ChaidAnalysisResponseBody;
  @Input() chartSelectedRow: GainTableRowItem;
  @Input() settings: AudienceEffectsSettings;
  @Input() chaidTableData: GainTableRowItem[];
  @Input() survey: Survey;
  @Input() activeTableTitle: string;
  @Output() selectedRowChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('tableCardContainer') tableCardContainer: ElementRef;

  public scrollViewportHeight = 0;
  public dataSource: MatTableDataSource<GainTableRowItem> =
    new TableVirtualScrollDataSource([]);

  public readonly defaultColumnMap = {
    rowNumber: {
      name: ' ',
      align: 'left',
    },
    segments: { name: 'Segments', align: 'left' },
    population: {
      name: 'Population accum. (000)',
      align: 'right',
    },
    populationPercentage: {
      name: 'Population accum. (%)',
      align: 'right',
    },
    targets: { name: 'Targets accum. (000)', align: 'right' },
    targetsPercentage: {
      name: 'Targets accum. (%)',
      align: 'right',
    },
    index: { name: 'Index accum.', align: 'right' },
  };

  private readonly columns = [
    'rowNumber',
    'segments',
    'population',
    'populationPercentage',
    'targets',
    'targetsPercentage',
    'index',
  ];

  public displayedColumns: string[] = [...this.columns];
  public columnMap = this.defaultColumnMap;
  public selectedRow: GainTableRowItem;

  constructor(
    private csvService: CsvService,
    private documentService: DocumentService,
    private targetTitlePipe: TargetTitlePipe
  ) {}

  @HostListener('window:resize')
  public onResize() {
    this.updateScrollViewportHeight();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chaidTableData) {
      this.chaidTableData = sortBy(this.chaidTableData, ['rowNumber']);
    }
    const highlightColor = this.getHighlightColor();
    if (this.chartSelectedRow) {
      const index = this.chaidTableData.findIndex(
        (row) => row.rowNumber === this.chartSelectedRow.rowNumber
      );
      this.dataSource.data = this.chaidTableData.map((row, rowIndex) => ({
        ...row,
        backgroundColor:
          rowIndex > index
            ? '#ffffff'
            : shadeColor(
                highlightColor,
                rowIndex === index ? 0 : 100 - row.targetsPercentage
              ),
        segments: row.longTitle,
      }));
      this.selectedRow = this.dataSource.data[index];
    } else {
      this.dataSource.data = this.chaidTableData.map((row, index) => ({
        ...row,
        backgroundColor:
          index !== 0 ? '#ffffff' : shadeColor(highlightColor, 0),
        segments: row.longTitle,
      }));
      this.selectedRow = this.dataSource.data[0];
    }

    this.triggerFakeWindowResizeEvent();
  }

  public getTotal() {
    return {
      rowNumber: '',
      segments: 'Total',
      population: this.chaidAnalysis.totalPopulation,
      populationPercentage: '',
      targets: this.chaidAnalysis.totalSample,
      targetsPercentage: '',
      index: '',
    };
  }

  public onRowClick(row: any) {
    this.selectedRow = row;
    this.selectedRowChange.emit(row);
  }

  private getHighlightColor(): string {
    return this.settings.showHAccumulatedHighlightColor
      ? this.settings.accumulatedHighlightColor
      : '#ffffff';
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  private updateScrollViewportHeight(): void {
    if (this.tableCardContainer) {
      this.scrollViewportHeight =
        this.tableCardContainer.nativeElement.offsetHeight - 14;
    }
  }

  public exportToXlsx(docName?: string) {
    return this.getXlsxExportData(docName);
  }

  public exportToPptxTableRows() {
    const rows = [
      [
        { text: 'SL.No' },
        { text: 'Segments' },
        { text: 'Population accum. (000)' },
        { text: 'Population accum. (%)' },
        { text: 'Targets accum. (000)' },
        { text: 'Targets accum. (%)' },
        { text: 'Index accum.' },
      ],
      ...this.dataSource.data.map((row) => [
        {
          text: String(row.rowNumber),
          options: { fill: row['backgroundColor'] },
        },
        {
          text: row['segments'],
          options: { fill: row['backgroundColor'] },
        },
        {
          text: String(row.population),
          options: { fill: row['backgroundColor'] },
        },
        {
          text: String(row.populationPercentage),
          options: { fill: row['backgroundColor'] },
        },
        {
          text: String(row.targets),
          options: { fill: row['backgroundColor'] },
        },
        {
          text: String(row.targetsPercentage),
          options: { fill: row['backgroundColor'] },
        },
        {
          text: String(row.index),
          options: { fill: row['backgroundColor'] },
        },
      ]),
    ];

    return rows;
  }

  private getXlsxExportData(
    docName?: string
  ): [string, CrosstabTableXlsxBuilder] {
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');
    const crossTabTableData = this.prepareXlsxExportData(docName);
    crossTabTableBuilder.addAudienceEffectsTable(
      crossTabTableData,
      AudienceEffectsViewType.results
    );
    crossTabTableBuilder.build();

    return [crossTabTableData.documentName, crossTabTableBuilder];
  }

  private prepareXlsxExportData(docName?: string): any {
    return {
      documentName: this.getExportDocumentName(docName),
      data: this.dataSource.data,
      displayedColumns: this.displayedColumns,
      survey: this.survey,
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
      totalPopulation: this.chaidAnalysis.totalPopulation,
      totalSample: this.chaidAnalysis.totalSample,
      activeTableTitle: this.activeTableTitle,
    };
  }

  public exportToCSV(docName?: string) {
    const documentName = docName || this.getExportDocumentName();
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addAudienceEffectsTableData(
      {
        documentName,
        displayedColumns: this.displayedColumns,
        data: this.dataSource.data,
        survey: this.survey,
        surveyCodeMap: this.documentService.getSurveyCodeMap(),
      },
      AudienceEffectsViewType.results
    );
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  private getExportDocumentName(docName?: string) {
    return `${
      docName || this.documentService.document.metadata.name
    } - Audience Effects Gain Analysis`;
  }
}
