import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ViewContainerRef,
} from '@angular/core';
import {
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventType,
  TupDocumentMetadata,
  TupDocumentService,
} from '@telmar-global/tup-document-storage';
import { merge } from 'lodash';
import { RenameDialogComponent, RenameDialogResult } from '../dialogs';
import { ConfirmChartStylesActionDialogContentComponent } from '../dialogs/confirm-chart-styles-action-dialog-content/confirm-chart-styles-action-dialog-content.component';
import { DocumentChartStyle } from '../models';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { concatMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Directive({
  selector: '[chartStyleDocumentEventHandler]',
})
export class ChartStyleDocumentEventHandlerDirective {
  constructor(
    private documentEventEmitter: TupDocumentEventEmitterDirective,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private documentService: TupDocumentService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      if (event.data) {
        switch (event.data.action) {
          case 'delete':
            this.delete(event);
            break;
          case 'rename':
            this.rename(event.document as TupDocument<DocumentChartStyle>);
            break;
          case 'move_to':
            this.moveTo(event);
            break;
        }
      }
    });
  }

  private delete(documentEvent: TupDocumentEvent): void {
    const metadata: TupDocumentMetadata = documentEvent.document.metadata;

    const componentFactory: ComponentFactory<ConfirmChartStylesActionDialogContentComponent> =
      this.componentFactoryResolver.resolveComponentFactory(
        ConfirmChartStylesActionDialogContentComponent
      );
    const componentRef: ComponentRef<ConfirmChartStylesActionDialogContentComponent> =
      this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.metadata = metadata;
    componentRef.instance.options = { documentType: 'document' };

    const data: any = merge({}, documentEvent.data, {
      options: { centered: true, width: '400px' },
      title: 'Delete style?',
    });

    if (metadata.container.name === metadata.by.attributes.email) {
      merge(data, {
        templateRef: componentRef.instance.delete,
        options: { confirmText: 'Delete', cancelText: 'Keep' },
      });
    }

    this.documentEventEmitter.emit(
      documentEvent.document,
      TupDocumentEventType.DELETE,
      data
    );
  }

  private rename({ metadata }: TupDocument<DocumentChartStyle>): void {
    // tslint:disable-next-line:variable-name
    let _dialogResult: RenameDialogResult;
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle: 'Rename style',
          inputTitle: 'Style name',
          inputValue: metadata.name,
        },
        closeOnNavigation: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        tap(
          (dialogResult: RenameDialogResult) => (_dialogResult = dialogResult)
        ),
        concatMap(() =>
          this.documentService.get(metadata.container.name, metadata.id)
        )
      )
      .subscribe((document: TupDocument<DocumentChartStyle>) => {
        this.updateDoc(document, {
          ...document.metadata,
          name: _dialogResult.name,
        });
      });
  }

  private moveTo(documentEvent: TupDocumentEvent): void {
    const metadata: TupDocumentMetadata = documentEvent.document.metadata;

    const componentFactory: ComponentFactory<ConfirmChartStylesActionDialogContentComponent> =
      this.componentFactoryResolver.resolveComponentFactory(
        ConfirmChartStylesActionDialogContentComponent
      );
    const componentRef: ComponentRef<ConfirmChartStylesActionDialogContentComponent> =
      this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.metadata = metadata;
    componentRef.instance.options = { documentType: 'document' };

    const data: any = merge({}, documentEvent.data, {
      options: { centered: true, width: '400px' },
      title: 'Move style?',
      documentTypeLabel: 'style',
    });

    this.documentEventEmitter.emit(
      documentEvent.document,
      TupDocumentEventType.UPDATE_CONTAINER,
      data
    );
  }

  private updateDoc(
    document: TupDocument<DocumentChartStyle>,
    metadata: TupDocumentMetadata
  ) {
    const updatedDoc: TupDocument<DocumentChartStyle> = {
      ...document,
      metadata,
    };

    this.documentEventEmitter.emit(updatedDoc, TupDocumentEventType.UPDATE);
  }
}
