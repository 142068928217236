import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CellColor,
  CellColorId,
  CellStyleStatus,
  ColumnHeaderFilter,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemId,
  DataItemType,
  DecimalPointFormat,
  DEFAULT_SORT_OPTIONS,
  DEFAULT_WEIGHT_INDEX,
  HeatmapValues,
  HighlightValues,
  ReportHighlightType,
  ReportMode,
  ReportPreference,
  SortDirection,
  SortSettings,
  StabilityLevels,
  Survey,
  SurveyCodeMap,
  SurveyMetaDataWeights,
  Target,
  TargetColumn,
  TargetType,
  VOLUMETRIC_DATA_ITEM_DECIMAL_POINTS,
  VOLUMETRIC_DATA_ITEM_DIGITS_INFO,
  VOLUMETRIC_DATA_ITEM_IDS,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
} from 'src/app/models';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Subject } from 'rxjs';
import {
  CrosstabService,
  DataItemsService,
  DocumentService,
  HighlightCellsService,
  RequestLoadingService,
  TargetLoading,
  XlsxService,
} from '../../services';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { filter, first, takeUntil } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import {
  CrosstabTableXlsxBuilder,
  CrosstabTableXlsxData,
} from '../../builders/crosstab-table-xlsx.builder';
import { FormatReportUnitsPipe } from '../../pipes/format-report-units.pipe';
import { CsvService } from '../../services/csv.service';
import { SortColumnTargetAction } from '../../actions/SortColumnTargetAction';
import { CrosstabTableCsvBuilder } from '../../builders/crosstab-table-csv.builder';
import { cloneDeep } from 'lodash';
import { AddOrEditFilterAction } from '../../actions/AddOrEditFilterAction';
import { ColumnHeaderFiltersService } from '../../services/column-header-filters.service';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { DecimalPointService } from '../../services/decimal-point.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-combined-rank-report-table',
  templateUrl: './combined-rank-report-table.component.html',
  styleUrls: ['./combined-rank-report-table.component.scss'],
})
export class CombinedRankReportTableComponent
  implements OnChanges, OnInit, OnDestroy
{
  public readonly zScoreHighlightProb = Z_SCORE_FILTERED_HIGHLIGHT_PROB;
  public readonly dataItemWidthMap: Record<string, number> = {
    sm: 100,
    md: 130,
    lg: 150,
    xl: 170,
  };
  public targetType: typeof TargetType = TargetType;
  public dataItemType: typeof DataItemType = DataItemType;
  public sortDirectionType: typeof SortDirection = SortDirection;
  public displayedColumns: string[] = ['insight'];
  public targetColumns: TargetColumn[] = [];
  public dataItems: DataItem[];
  public columnWidth = 0;
  public decimalPointMap: Record<string, DecimalPointFormat>;
  public dataItemsMap: Record<DataItemId, DataItem>;
  public data: CrossTabTableData[];
  public totalRow: CrossTabTableData = null;
  public dataSource: MatTableDataSource<CrossTabTableData> =
    new TableVirtualScrollDataSource([]);

  public sortSettings: SortSettings;
  public isSortActive = false;
  public isLoading = false;
  public cellColors: Record<CellColorId, CellColor> = {};
  public highlightValues: HighlightValues;
  public heatmapValues: HeatmapValues;
  public cellStyleStatus: CellStyleStatus;
  public zScoreHighlight: boolean;
  public stabilityFlagStatus: StabilityLevels;
  public reportUnits: number;
  private unsubscribe: Subject<void> = new Subject<void>();
  public volumetricDataItemIds: number[] = VOLUMETRIC_DATA_ITEM_IDS;
  public volumetricDecimalPoints: number = VOLUMETRIC_DATA_ITEM_DECIMAL_POINTS;
  public volumetricDigitsInfo: string = VOLUMETRIC_DATA_ITEM_DIGITS_INFO;
  public columnIdVolumetricCodingSet: Set<string> = new Set();

  public activeSurvey: Survey;
  public columnHeaderFilters: ColumnHeaderFilter[] = [];
  public columnHeaderFiltersMap: Set<string> = new Set();

  public surveyCodeMap: SurveyCodeMap;

  @ViewChild('tableContainer') tableContainer: ElementRef;
  @ViewChild('tableTitleSection') tableTitleSection: ElementRef;
  @ViewChild('gridTableHeader') gridTableHeader: ElementRef;
  @ViewChild('virtualScroll', { static: false })
  public virtualScrollViewport: CdkVirtualScrollViewport;

  @Input() isViewActive: boolean;

  public scrollbarWidth = 0;
  public showScrollToTopBtn = false;
  public hoveringColumnDataItem: string;

  public weightDescriptions: SurveyMetaDataWeights[] = [];
  public currentWeightIndex = DEFAULT_WEIGHT_INDEX;

  constructor(
    private requestLoadingService: RequestLoadingService,
    private colorCellsService: HighlightCellsService,
    private documentService: DocumentService,
    private crossTabService: CrosstabService,
    private dataItemsService: DataItemsService,
    private targetTitlePipe: TargetTitlePipe,
    private formatReportUnitsPipe: FormatReportUnitsPipe,
    private injector: Injector,
    private csvService: CsvService,
    private xlsxService: XlsxService,
    private columnHeaderFiltersService: ColumnHeaderFiltersService,
    private reportPreferencesService: ReportPreferencesService,
    private decimalPointsService: DecimalPointService
  ) {}

  ngOnInit(): void {
    this.listenToLoadingState();
    this.listenToReportUnitsChanges();
    this.listenToSurveyDataWeightsChanges();
    this.listenToReportModeChanges();
    this.listenToCrossTabDataChanges();
    this.listenToReportPreferencesChanges();
    this.listenToDataItemsChanges();
    this.listenToActiveSurveyChanges();
    this.listenToSurveyCodeMapChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isViewActive?.currentValue) {
      setTimeout(() => {
        this.addScrollListener();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
    this.removeScrollListener();
  }

  public onScrollToTopClicked() {
    this.virtualScrollViewport.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public onColumnDataItemMouseEnter(
    column: TargetColumn,
    dataItem: DataItem
  ): void {
    this.hoveringColumnDataItem = `${column.columnId}_${dataItem.id}`;
  }

  public onColumnDataItemMouseLeave(): void {
    this.hoveringColumnDataItem = '';
  }

  public onColumnDataItemClick(column: TargetColumn, dataItem: DataItem): void {
    this.hoveringColumnDataItem = '';
    let sortDirection;
    const isNewSortColumnOrDataItem =
      this.sortSettings.dataItem !== dataItem.id ||
      this.sortSettings.columnId !== column.columnId;
    if (isNewSortColumnOrDataItem) {
      sortDirection = SortDirection.asc;
    } else {
      sortDirection =
        this.sortSettings.order === SortDirection.asc
          ? SortDirection.desc
          : this.sortSettings.order === SortDirection.desc
          ? SortDirection.none
          : SortDirection.asc;
    }

    this.injector.get(SortColumnTargetAction).invoke({
      targetItem: {
        target: column.target,
        index: column.position,
        type: TargetType.columns,
      },
      surveyCode: column.element.surveyCode,
      actionItem: {
        name: dataItem.displayName,
        action: SortColumnTargetAction,
        dataItem: dataItem.id,
        direction: sortDirection,
      },
    });
  }

  public onFilterClick(): void {
    this.injector.get(AddOrEditFilterAction).invoke({
      surveyCode: this.activeSurvey.code,
      showColumnOptions: true,
    });
  }

  public onColumnHeaderFilterClick(
    columnHeaderFilter: ColumnHeaderFilter
  ): void {
    this.injector.get(AddOrEditFilterAction).invoke({
      columnId: columnHeaderFilter.columnId,
      surveyCode: this.activeSurvey.code,
      showColumnOptions: true,
    });
  }

  public onColumnHeaderFilterRemove(
    columnHeaderFilter: ColumnHeaderFilter
  ): void {
    this.reportPreferencesService.removeColumnHeaderFilters(
      columnHeaderFilter.columnId
    );
  }

  public exportToCsv(
    docName?: string,
    tablebase?: Target,
    data?: CrossTabTableData[]
  ): void {
    const documentName = docName || this.documentService.document.metadata.name;
    const currentWeightIndex =
      this.documentService.document.content?.weight || DEFAULT_WEIGHT_INDEX;
    const currentWeight = this.weightDescriptions.filter(
      (weight) => weight.index === currentWeightIndex
    );

    const combinedRankData = data
      ? this.crossTabService.sortTableData(cloneDeep(data), this.sortSettings)
      : this.getRawTableData();
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addTableData({
      documentName,
      targetColumns: this.targetColumns,
      data: combinedRankData,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: { ...cloneDeep(DEFAULT_SORT_OPTIONS) },
      filters: [],
      surveys: [this.documentService.activeSurvey],
      surveyCodeMap: this.surveyCodeMap,
      defaultDecimalPoints:
        this.decimalPointsService.getActiveReportDecimalPoint(),
      tablebase: tablebase,
      ...(currentWeight.length > 0 ? { weight: currentWeight[0] } : {}),
    });
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheets(): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportData();
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  public exportToXlsxV2(
    docName?: string,
    tablebases?: Target[],
    data?: CrossTabTableData[][]
  ): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportDataV2(
      docName,
      tablebases,
      data
    );
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheetsV2(
    docName?: string,
    tablebases?: Target[],
    data?: CrossTabTableData[][]
  ): void {
    const [documentName, crossTabTableBuilder] = this.getXlsxExportDataV2(
      docName,
      tablebases,
      data
    );
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  private removeScrollListener(): void {
    this.virtualScrollViewport?.elementRef.nativeElement.removeEventListener(
      'scroll',
      () => {}
    );
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private listenToReportUnitsChanges(): void {
    this.documentService.reportUnits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((units: number) => {
        this.reportUnits = units;
      });
  }

  private listenToSurveyDataWeightsChanges(): void {
    this.documentService.surveyDataWeights$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((weights: SurveyMetaDataWeights[]) => {
        this.weightDescriptions = weights;
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        if (mode !== ReportMode.combinedRank) {
          this.unsubscribeAll();
        }
      });
  }

  private listenToCrossTabDataChanges(): void {
    this.crossTabService.filteredSortedCrossTabData$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CrossTabTableData[]) => {
        this.setupTable(data);
      });
  }

  private listenToReportPreferencesChanges(): void {
    combineLatest([
      this.reportPreferencesService.preference$,
      this.documentService.surveyStabilityLevels$,
    ])
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe(
        ([preference, surveyStabilityLevels]: [
          ReportPreference,
          StabilityLevels
        ]) => {
          this.stabilityFlagStatus = preference.stabilityFlagOn
            ? surveyStabilityLevels
            : null;
          this.heatmapValues = preference.heatMapValues;
          this.highlightValues = preference.highlightValues;
          this.cellStyleStatus = preference.cellStyleStatus;
          this.zScoreHighlight =
            preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;
          this.updateCellColors();

          this.sortSettings = preference.sortSettings as SortSettings;
          this.isSortActive = this.sortSettings.columnId !== '';

          this.columnHeaderFilters = preference.filters.map(
            (columnFilter: ColumnHeaderFilter) => ({
              ...columnFilter,
              tooltip: this.getColumnHeaderFilterTooltip(columnFilter),
            })
          );
          this.columnHeaderFiltersMap = new Set(
            this.columnHeaderFilters.map(
              (columnFilter: ColumnHeaderFilter) => columnFilter.columnId
            )
          );
        }
      );
  }

  private getColumnHeaderFilterTooltip(
    columnHeaderFilter: ColumnHeaderFilter
  ): string {
    const column = this.targetColumns.find(
      (targetColumn: TargetColumn) =>
        targetColumn.columnId === columnHeaderFilter.columnId
    );
    return this.columnHeaderFiltersService.formatFilterConditions(
      columnHeaderFilter,
      column
    );
  }

  private listenToDataItemsChanges(): void {
    this.dataItemsService.actualDataItems$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.updateTableDataPointsAndCell();
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.activeSurvey = survey;
      });
  }

  private listenToSurveyCodeMapChanges(): void {
    this.documentService.surveyCodeMap$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((map: SurveyCodeMap) => {
        this.surveyCodeMap = map;
      });
  }

  private setupTable(data: CrossTabTableData[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.addTableColumns();
    this.updateCellColors();
  }

  private populateTableData(data: CrossTabTableData[]): void {
    this.data = data.map((crossTabData: CrossTabTableData) => {
      return {
        ...crossTabData,
        data: crossTabData.data.filter(
          (column: CrossTabTableDataCell) =>
            column.surveyCode === this.documentService.activeSurvey.code
        ),
      };
    });
    this.dataSource.data = this.formatTableData(this.data);
    this.columnIdVolumetricCodingSet =
      this.crossTabService.getColumnIdVolumetricCodingMap(this.data);
  }

  private addTableColumns(): void {
    this.targetColumns = this.crossTabService.getTargetColumns(this.data);
    this.displayedColumns.push(
      ...this.targetColumns.map((column: TargetColumn) => column.name)
    );
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.targetColumns = [];
    this.displayedColumns = ['insight'];
    this.columnIdVolumetricCodingSet = new Set();
  }

  private formatTableData(data: CrossTabTableData[]): CrossTabTableData[] {
    this.totalRow = data[0];
    return data.slice(1);
  }

  private updateTableDataPointsAndCell(): void {
    this.dataItems = this.dataItemsService.getActiveDataItems(
      ReportMode.combinedRank
    );
    this.columnWidth = this.dataItems.reduce(
      (acc, dataItem: DataItem) =>
        acc + this.dataItemWidthMap[dataItem.widthType],
      0
    );
    this.dataItemsMap = DATA_ITEMS_MAP;
    this.decimalPointMap = this.decimalPointsService.getDataItemDecimalPoints(
      this.dataItems
    );
  }

  private updateCellColors(): void {
    if (this.cellStyleStatus !== CellStyleStatus.none) {
      this.cellColors = this.colorCellsService.updateCellColors(this.data);
    } else {
      this.cellColors = this.colorCellsService.updateCellColors([]);
    }
  }

  private getXlsxExportData(): [string, CrosstabTableXlsxBuilder] {
    const documentName = this.documentService.document.metadata.name;
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');

    const crossTabTableDataForCombinedRank: CrosstabTableXlsxData = {
      documentName,
      targetColumns: this.targetColumns,
      data: this.data,
      dataItems: this.dataItems,
      reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
      sortSettings: [this.sortSettings],
      filters: this.columnHeaderFilters,
      surveys: [this.documentService.activeSurvey],
      surveyColors: this.crossTabService.getSurveyColors(
        this.data,
        this.documentService.surveys
      ),
      cellStyleStatus: this.cellStyleStatus,
      highlightValues: this.highlightValues,
      bins: this.colorCellsService.getBins(),
      cellColors: this.cellColors,
      heatMapValues: {
        heatmapIndexPercentage: this.heatmapValues.heatmapIndexPercentage,
        heatmapQuartiles: this.heatmapValues.heatmapQuartiles,
        heatmapQuintiles: this.heatmapValues.heatmapQuintiles,
        heatmap: this.heatmapValues.heatmap,
      },
      surveyCodeMap: this.surveyCodeMap,
      defaultDecimalPoints:
        this.decimalPointsService.getActiveReportDecimalPoint(),
    };
    const rawData = cloneDeep(this.getRawTableData());
    const crossTabTableDataForEntireTable = {
      ...crossTabTableDataForCombinedRank,
      data: rawData,
      filters: [],
      cellColors: this.colorCellsService.updateCellColors(rawData),
      sortSettings: [{ ...cloneDeep(DEFAULT_SORT_OPTIONS) }],
    };

    crossTabTableBuilder.addCombinedRankTableSheet(
      crossTabTableDataForCombinedRank
    );
    crossTabTableBuilder.addEntireTableSheet(crossTabTableDataForEntireTable);
    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private getXlsxExportDataV2(
    docName: string,
    tablebases: Target[],
    data: CrossTabTableData[][]
  ): [string, CrosstabTableXlsxBuilder] {
    const documentName = docName || this.documentService.document.metadata.name;
    const currentWeightIndex =
      this.documentService.document.content?.weight || DEFAULT_WEIGHT_INDEX;
    const currentWeight = this.weightDescriptions.filter(
      (weight) => weight.index === currentWeightIndex
    );

    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');

    tablebases.forEach((tablebase, index) => {
      const rawData = cloneDeep(data[index]);
      const sortedData = this.sortTableData(rawData);

      const crossTabTableData: CrosstabTableXlsxData = {
        documentName,
        targetColumns: this.crossTabService.getTargetColumns(sortedData),
        data: sortedData,
        dataItems: this.dataItems,
        reportUnits: this.formatReportUnitsPipe.transform(this.reportUnits),
        sortSettings: [this.sortSettings],
        filters: this.columnHeaderFilters,
        surveys: [this.documentService.activeSurvey],
        surveyColors: this.crossTabService.getSurveyColors(
          sortedData,
          this.documentService.surveys
        ),
        cellStyleStatus: this.cellStyleStatus,
        highlightValues: this.highlightValues,
        bins:
          this.cellStyleStatus !== CellStyleStatus.none &&
          this.highlightValues?.type === ReportHighlightType.table
            ? this.colorCellsService.getBinsForCrosstabDataByTable(
                sortedData,
                this.highlightValues.dataItemId,
                this.highlightValues.colors.length
              )
            : this.colorCellsService.getBins(),
        cellColors: this.getCellColorsForCrosstabData(sortedData),
        heatMapValues: {
          heatmapIndexPercentage: this.heatmapValues.heatmapIndexPercentage,
          heatmapQuartiles: this.heatmapValues.heatmapQuartiles,
          heatmapQuintiles: this.heatmapValues.heatmapQuintiles,
          heatmap: this.heatmapValues.heatmap,
        },
        surveyCodeMap: this.surveyCodeMap,
        defaultDecimalPoints:
          this.decimalPointsService.getActiveReportDecimalPoint(),
        tablebase: tablebase,
        ...(currentWeight.length > 0 ? { weight: currentWeight[0] } : {}),
      };

      crossTabTableBuilder.addCombinedRankTableSheet(crossTabTableData);
    });

    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  private getCellColorsForCrosstabData(
    data: CrossTabTableData[]
  ): Record<CellColorId, CellColor> {
    return this.colorCellsService.updateCellColors(data);
  }

  private getRawTableData(): CrossTabTableData[] {
    let rawData;
    this.crossTabService.crossTabData$
      .pipe(first())
      .subscribe((data: CrossTabTableData[]) => {
        rawData = data.map((crossTabData: CrossTabTableData) => {
          return {
            ...crossTabData,
            data: crossTabData.data.filter(
              (column: CrossTabTableDataCell) =>
                column.surveyCode === this.documentService.activeSurvey.code
            ),
          };
        });
      });
    return rawData;
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private addScrollListener() {
    const scrollOffset = 100;
    this.showScrollToTopBtn = false;
    const container: HTMLElement =
      this.virtualScrollViewport?.elementRef.nativeElement;
    if (!container) {
      return;
    }
    this.scrollbarWidth = container.offsetWidth - container.clientWidth;
    const header: HTMLElement = this.gridTableHeader.nativeElement;
    container.addEventListener('scroll', (event) => {
      header.scrollLeft = container.scrollLeft;
      this.showScrollToTopBtn = container.scrollTop > scrollOffset;
    });
  }

  public sortTableData(tableData: CrossTabTableData[]): CrossTabTableData[] {
    const sortSettings = this.sortSettings;
    const [id, surveyCode] = sortSettings.columnId.split('_');

    const targetIndex = tableData[0].data.findIndex(
      (rowCell: CrossTabTableDataCell) =>
        (rowCell.columnTarget?.id || 'totals') === id &&
        rowCell.surveyCode === surveyCode
    );

    if (targetIndex === -1) {
      return tableData;
    }
    const cellKey = DATA_ITEMS_MAP[sortSettings.dataItem].cellKey;
    let tableRows: CrossTabTableData[] = tableData;
    const totalRow = tableRows.shift();
    const backupRows = tableRows.slice(0, sortSettings.startingRow - 1);
    tableRows.splice(0, sortSettings.startingRow - 1);

    if (sortSettings.order === SortDirection.desc) {
      tableRows = tableRows.sort(
        (a: CrossTabTableData, b: CrossTabTableData) =>
          (b.data[targetIndex][cellKey] || 0) -
          (a.data[targetIndex][cellKey] || 0)
      );
    } else {
      tableRows = tableRows.sort(
        (a: CrossTabTableData, b: CrossTabTableData) =>
          (a.data[targetIndex][cellKey] || 0) -
          (b.data[targetIndex][cellKey] || 0)
      );
    }

    return [totalRow, ...backupRows, ...tableRows].map(
      (rowData: CrossTabTableData, index: number) => ({
        ...rowData,
        position: index,
        rowIndex: index,
      })
    );
  }
}
