import { DocumentAudienceGroupItem } from '@telmar-global/tup-audience-groups';
import { SelectMenuOption } from './application.model';
import Highcharts, { SVGElement } from 'highcharts';

export const CA_VERSION = 'v3';

export type CorrespondenceViewType = 'chart' | 'table';

export const CORRESPONDENCE_LINE_ANNOTATION_PROPS = {
  type: 'path',
  strokeWidth: 2,
  stroke: 'black',
  dashStyle: 'solid',
};

export enum CorrespondenceDataItem {
  audience = 'Audience',
  resps = 'Resps',
  percentCol = '%Col',
  percentRow = '%Row',
  index = 'Index',
}

export interface StartCorrespondenceRequestBody {
  'authorization-group': string;
  'num-of-factors'?: number;
  'survey-code': string;
  columns: string[];
  rows: string[];
  table: string;
  'data-item': string;
  'passive-rows': string[];
  'passive-columns': string[];
}

export interface StartCorrespondenceResponseBody {
  success: boolean;
  message: string;
  'row-coords': number[][];
  'row-abs-contribution': number[][];
  'row-pct-inf': number[];
  'row-rel-contribution': number[][];
  'col-coords': number[][];
  'col-abs-contribution': number[][];
  'col-pct-inf': number[];
  'col-rel-contribution': number[][];
  'passive-row-coords': number[][] | null;
  'passive-row-abs-contribution': number[][] | null;
  'passive-row-pct-inf': number[] | null;
  'passive-row-rel-contribution': number[][];
  'passive-col-coords': number[][] | null;
  'passive-col-abs-contribution': number[][] | null;
  'passive-col-pct-inf': number[] | null;
  'passive-col-rel-contribution': number[][];
  'chi-squared': number[];
  eigenvalues: number[];
  'p-exp-vars': number[];
  dof: number[];
}

export interface CorrespondenceSettings {
  title: string;
  dataItem: CorrespondenceDataItem;
  xAxisFactor: number;
  yAxisFactor: number;
  showDataLabel: boolean;
  showAxisLabel: boolean;
  showLegend: boolean;
  showWeightedSymbols: CorrespondenceWeightType;
  showHighlighting?: boolean;
  displayQuadrantTitles: boolean;
  quadrant1: string;
  quadrant2: string;
  quadrant3: string;
  quadrant4: string;
}

export enum CorrespondenceWeightType {
  none,
  INF,
  dataItem,
}

export const CORRESPONDENCE_WEIGHT_ITEMS = [
  {
    id: CorrespondenceWeightType.none,
    title: 'No',
  },
  {
    id: CorrespondenceWeightType.INF,
    title: '%INF',
  },
  {
    id: CorrespondenceWeightType.dataItem,
    title: 'Data item',
  },
];

export enum CorrespondenceDataRowType {
  activeColumn,
  activeRow,
  passiveColumn,
  passiveRow,
}

export const CORRESPONDENCE_DATA_ROW_TYPE_CHART_ITEMS = [
  {
    id: CorrespondenceDataRowType.activeColumn,
    displayName: 'Active columns',
    color: '#F65354',
  },
  {
    id: CorrespondenceDataRowType.activeRow,
    displayName: 'Active rows',
    color: '#0054FF',
  },
  {
    id: CorrespondenceDataRowType.passiveColumn,
    displayName: 'Passive columns',
    color: '#FFDE54',
  },
  {
    id: CorrespondenceDataRowType.passiveRow,
    displayName: 'Passive rows',
    color: '#62F0CC',
  },
];

export interface CorrespondenceFilterOptions {
  visibleColumns: number;
  visibleRows: number;
  allColumns: boolean;
  allRows: boolean;
  sortBy: CorrespondenceSortColumnType;
}

export enum CorrespondenceSortColumnType {
  percentINF,
  factor1,
  factor2,
  factor3,
  factor4,
  factor5,
  factor6,
}

export interface CorrespondenceTableRowData extends CorrespondenceFeatureItem {
  id: string;
  x: number | undefined;
  y: number | undefined;
  z: number | undefined; // used to indicate weight and the size of a bubble
  title: string;
  inf: number | '-';
  absContribution: number[];
  relContribution: number[];
  relSign: string[];
  coordinates: number[];
  rowNumber?: number;
  sortableValues: number[];
  tempIndex: number;
  segments?: CorrespondenceSegmentItem;
  isHighlighted?: boolean;
}

export interface CorrespondenceFeatureItem extends DocumentAudienceGroupItem {
  type: CorrespondenceDataRowType;
  visible: boolean;
}

export enum CorrespondenceTableDataMode {
  factor,
  absRel,
}

export const CORRESPONDENCE_TABLE_DATA_MODES: SelectMenuOption<CorrespondenceTableDataMode>[] =
  [
    {
      title: 'Show scores',
      value: CorrespondenceTableDataMode.absRel,
      tooltip: 'Show ABS REL scores in table',
    },
    {
      title: 'Show factors',
      value: CorrespondenceTableDataMode.factor,
      tooltip: 'Show factors in table',
    },
  ];

export interface CorrespondenceData {
  rows: CorrespondenceFeatureItem[];
  columns: CorrespondenceFeatureItem[];
}

export enum HighlightCorrespondenceSegment {
  min = 'min',
  mid = 'mid',
  max = 'max',
}

export enum CorrespondenceTableDataModeKeys {
  absContribution = 'absContribution',
  relContribution = 'relContribution',
  coordinates = 'coordinates',
}

export interface CorrespondenceItem {
  value: number;
  segment?: HighlightCorrespondenceSegment;
}

export interface CorrespondenceSegmentItem {
  [key: string]: CorrespondenceItem[];
}

export interface ExtendedCorrespondenceChart extends Highcharts.Chart {
  quadrantTitles: SVGElement[];
  options: ExtendedCorrespondenceOptions;
}

export interface ExtendedCorrespondenceOptions extends Highcharts.Options {
  annotationPositionMap: Record<number, AnnotationPosition>;
  quadrantTitles: {
    enabled: boolean;
    quadrant1: string;
    quadrant2: string;
    quadrant3: string;
    quadrant4: string;
  };
}

export interface AnnotationPosition {
  x: number;
  y: number;
}
