import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentService,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';

import { Subject, combineLatest, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  tap,
  delay,
  filter,
  first,
  takeWhile,
} from 'rxjs/operators';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

import { DialogService } from 'src/app/services/dialog.service';
import {
  CreateNewDocumentService,
  DataItemsService,
  DocumentService,
  ReportPreferencesService,
  RequestLoadingService,
  TargetLoading,
  TargetService,
  TitleLevelsService,
  TitleModeService,
  TopBarService,
  TrendingCalculationService,
} from '../../services';
import {
  CellStyleStatus,
  ChartTargetMode,
  DataItem,
  DisplayType,
  DocumentDataState,
  IgnoreZeroWeightedResps,
  Operator,
  ReportMode,
  ReportPreference,
  Survey,
  SurveyProvider,
  SurveyTimeDocument,
  SWAP_ROWS_COLUMNS_WARNING,
  Target,
  TargetType,
  TITLE_MODES,
  ViewType,
} from '../../models';
import {
  NTileDialogResult,
  SendToPlanTargetGroup,
  TitleLevelsDialogResult,
} from '../../dialogs';
import { HighlightCellsService } from 'src/app/services/highlight-cells.service';

import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesTargetGroup,
  SaveOwnCodesType,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { TargetTitlePipe } from '../../pipes';
import { SwapRowsColumnsAction } from '../../actions/SwapRowsColumnsAction';
import { CustomAudiencesService } from '../../services/custom-audiences.service';
import { DecimalPointService } from '../../services/decimal-point.service';
import { ManageCustomAudiencesService } from '../../services/manage-custom-audiences.service';
import { ManageChartStyleService } from 'src/app/services/manage-chart-style-service';
import { cloneDeep, isEqual } from 'lodash';
import { environment } from '../../../environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cross-tab',
  templateUrl: './cross-tab.component.html',
  styleUrls: ['./cross-tab.component.scss'],
})
export class CrossTabComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  private hasCurrentDocument: boolean;
  private currentSurveys: Survey[] = [];
  public targetType: typeof TargetType = TargetType;
  public viewType: typeof ViewType = ViewType;
  public isReadonly = true;
  public saveOwnCodesType: typeof SaveOwnCodesType = SaveOwnCodesType;
  public reportMode: typeof ReportMode = ReportMode;
  public targetMode: ChartTargetMode = ChartTargetMode.single;
  public docOwnerName: string;
  public docName: string;
  public currentDocument: TupDocument<SurveyTimeDocument>;
  public surveys: Survey[];
  public visibleSurveys: Survey[];
  public selectedSurvey: Survey;
  public hiddenSurveys: Set<Survey> = new Set<Survey>();
  public selectedTab: ViewType = this.viewType.crossTab;

  public rightSidebarOpened = false;
  public hasRowsAndColumns = false;

  public stabilityFlagOn = true;
  public ignoreZeroWeightedRespondents: IgnoreZeroWeightedResps;
  public zScoreHighlight = false;
  public significanceTestingVar = null;
  public titleMode: DisplayType;
  public titleLevels: number[] = [];

  public completeCasesOn = true;

  public displayTypes = DisplayType;
  public titleModes = TITLE_MODES.map((mode) => ({ ...mode, disabled: false }));

  public activeReportMode: ReportMode;
  public swapActionWarning = '';
  public isLoading = false;
  private isManageCustomAudiencesModeOn = false;
  private isManageChartStyleModeOn = false;

  public surveyProvider = SurveyProvider;

  public sortedRows;

  constructor(
    private router: Router,
    private topBarService: TopBarService,
    private activatedRoute: ActivatedRoute,
    private userContainerService: TupUserContainerService,
    private documentService: DocumentService,
    private requestLoadingService: RequestLoadingService,
    private tupDocumentService: TupDocumentService,
    private messageService: TupUserMessageService,
    private targetService: TargetService,
    private authService: TupAuthService,
    private swapRowsColumnsAction: SwapRowsColumnsAction,
    private dialog: DialogService,
    private colorCellService: HighlightCellsService,
    private audienceGroupsService: TupAudienceGroupsService,
    private targetTitlePipe: TargetTitlePipe,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private dataItemsService: DataItemsService,
    private createNewDocumentService: CreateNewDocumentService,
    private trendingCalculationService: TrendingCalculationService,
    private reportPreferencesService: ReportPreferencesService,
    private manageCustomAudiencesService: ManageCustomAudiencesService,
    private manageChartStyleService: ManageChartStyleService,
    private customAudiencesService: CustomAudiencesService,
    private decimalPointsService: DecimalPointService,
    private dialogService: DialogService,
    @Inject(Window) private window: Window
  ) {
    this.hasCurrentDocument =
      this.router.getCurrentNavigation().extras?.state?.hasDocument;

    this.currentSurveys =
      this.router.getCurrentNavigation().extras?.state?.surveys;

    this.getCurrentDocument();
  }

  ngOnInit() {
    this.listenToDataTabChanges();
    this.listenToDocumentDataChanges();
    this.listenToLoadingState();
    this.listenToIgnoreZeroWeightedResps();
    this.listenToTitleModeUpdates();
    this.listenToActiveSurveyChanges();
    this.listenToReportModeChanges();
    this.listenToReportPreferencesChanges();
    this.listenToRightSideBarOpenedStatus();
    this.listenToManageCustomAudiencesModeChanges();
    this.listenToManageChartStyleModeChanges();
    this.subscribeToSortedRows();
  }

  private listenToManageCustomAudiencesModeChanges(): void {
    this.manageCustomAudiencesService.manageCustomAudiencesModeOn$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: boolean) => {
        this.isManageCustomAudiencesModeOn = mode;
      });
  }

  private listenToManageChartStyleModeChanges(): void {
    this.manageChartStyleService.manageChartStyleModeOn$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: boolean) => {
        this.isManageChartStyleModeOn = mode;
      });
  }

  private getCurrentDocument(): void {
    combineLatest([
      this.activatedRoute.params,
      this.userContainerService.container.pipe(isNotNullOrUndefined()),
    ])
      .pipe(
        takeUntil(this.unsubscribe),
        takeWhile(() => !this.isManageCustomAudiencesModeOn),
        takeWhile(() => !this.isManageChartStyleModeOn),
        tap(() => {
          this.documentService.restoreInitialDocumentState();
        }),
        switchMap(([params, container]: [Params, UserContainer]) => {
          const documentId = params.documentId;

          if (
            (this.hasCurrentDocument && this.currentSurveys.length) ||
            this.isManageCustomAudiencesModeOn ||
            this.isManageChartStyleModeOn
          ) {
            return of([this.currentSurveys, true, documentId]);
          }

          return this.tupDocumentService.get(container.name, documentId).pipe(
            map((document: TupDocument<SurveyTimeDocument>) => {
              this.documentService.setCurrentDocument(document);
              const surveys = document?.content?.surveys ?? [];
              return [surveys, true, documentId, undefined];
            }),
            catchError(() => of([[], false, documentId]))
          );
        })
      )
      .subscribe(
        ([surveys, includeRowCol, documentId]: [
          Survey[],
          boolean,
          string | undefined
        ]) => {
          if (this.isManageCustomAudiencesModeOn) {
            return;
          }
          const hasSurveys = surveys && surveys.length;

          this.requestLoadingService.setLoading({
            target: 'global',
            isLoading: false,
          });
          if (hasSurveys) {
            this.createNewDocumentService.setListRecentSurveys(surveys);
            this.documentService.setActiveDocumentId(documentId);
            this.documentService.initDocument(
              surveys,
              includeRowCol,
              false,
              true,
              false
            );
          } else {
            if (documentId) {
              this.router.navigate(['404']);
            } else {
              this.router.navigate(['dashboard']);
            }
          }
        }
      );
  }

  public openHighlightDialog(): void {
    this.rightSidebarOpened = false;
    this.significanceTestingVar = null;
    this.colorCellService
      .highlightColors()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data) => {
        this.reportPreferencesService.updateHighlightValues({
          ...data,
        });
      });
  }

  private listenToTitleModeUpdates(): void {
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((titleMode) => (this.titleMode = titleMode));
  }

  public selectTitleMode(): void {
    this.rightSidebarOpened = false;
    this.titleModeService.updateTitleMode(this.titleMode);
    this.documentService.updateDocumentDataTitle(
      {
        activeTitleMode: this.titleMode,
      },
      false
    );
  }

  public clickTitleMode(displayType: DisplayType): void {
    if (displayType === DisplayType.levels) {
      this.openTitleLevelsDialog();
    }
  }

  public resetTitleMode() {
    this.titleMode = this.displayTypes.title;
    this.selectTitleMode();
  }

  public openManageReportItemsDialog(): void {
    this.rightSidebarOpened = false;
    this.documentService.documentState$
      .pipe(first())
      .subscribe((state: DocumentDataState) =>
        this.dialog
          .manageReportItems(state, this.titleMode, this.titleLevels)
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.documentService.deleteDocumentData(result, true);
            }
          })
      );
  }

  public turnOnSignificanceTesting(): void {
    this.rightSidebarOpened = false;
    const significanceTestingOn =
      this.significanceTestingVar === 'row'
        ? CellStyleStatus.significanceTestingRow
        : CellStyleStatus.significanceTestingColumn;
    this.reportPreferencesService.updateCellStatus(significanceTestingOn);
  }

  public showCompleteCasesFlag(): void {
    this.rightSidebarOpened = false;
    this.completeCasesOn = !this.completeCasesOn;
    this.reportPreferencesService.updateGlobalCompleteCasesStatus(
      this.completeCasesOn
    );
  }

  public showStabilityFlag(): void {
    this.rightSidebarOpened = false;
    this.stabilityFlagOn = !this.stabilityFlagOn;
    this.reportPreferencesService.updateStabilityFlagStatus(
      this.stabilityFlagOn
    );
  }

  public showIgnoreZeroWeightedRespondents(): void {
    this.rightSidebarOpened = false;
    this.ignoreZeroWeightedRespondents.enabled =
      !this.ignoreZeroWeightedRespondents.enabled;
    this.documentService.updateCrossTabData();
  }

  public toggleZScoreHighlight(): void {
    this.rightSidebarOpened = false;
    this.zScoreHighlight = !this.zScoreHighlight;
    this.reportPreferencesService.updateCellStatus(
      this.zScoreHighlight
        ? CellStyleStatus.zScoreHighlight
        : CellStyleStatus.none
    );
  }

  public openDataItemsDialog(): void {
    this.rightSidebarOpened = false;
    const originalAdditionalOptions = this.dataItemsService
      .getAllActiveDataItems()
      .filter((item: DataItem) => !!item.adi)
      .map((item: DataItem) => item.adi);
    this.dialog
      .dataItems(
        this.dataItemsService.getDataItems(this.activeReportMode),
        this.decimalPointsService.getActiveReportDecimalPoint()
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe(({ dataItems, decimalPoints }) => {
        this.dataItemsService.updateDataItems(
          this.activeReportMode,
          dataItems,
          decimalPoints
        );
        const updatedAdditionalOptions = this.dataItemsService
          .getAllActiveDataItems()
          .filter((item: DataItem) => !!item.adi)
          .map((item: DataItem) => item.adi);
        const shouldMakeNewCrosstabRequest =
          !isEqual(
            originalAdditionalOptions.sort(),
            updatedAdditionalOptions.sort()
          ) &&
          !updatedAdditionalOptions.every((option) =>
            originalAdditionalOptions.includes(option)
          );
        if (shouldMakeNewCrosstabRequest) {
          this.documentService.notifyCrosstabDataAboutToChange();
          this.documentService.updateCrossTabData();
        }
      });
  }

  public openHeatmapDialog(): void {
    this.rightSidebarOpened = false;
    this.significanceTestingVar = null;
    this.colorCellService
      .heatmap()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((data) => {
        this.reportPreferencesService.updateHeatmapPercentageValue(
          data.selectedHeatmapOption,
          data.heatmapValues
        );
      });
  }

  ngOnDestroy(): void {
    this.resetTrendingCalculation();
    this.unsubscribeLoadingSubscription();
    this.documentService.unsetActiveDocumentId();
    this.documentService.unsetReadonlyDoc();
    this.reportPreferencesService.resetStates();
  }

  public openTitleLevelsDialog(): void {
    this.rightSidebarOpened = false;
    this.titleLevelsService
      .openDialog()
      .subscribe((dialogResult: TitleLevelsDialogResult) => {
        this.titleLevels = dialogResult.titleLevels;

        const target: Partial<Target> = {
          titleLevels: dialogResult.titleLevels,
        };
        this.titleLevelsService.updateTitleLevels(dialogResult.titleLevels);

        if (this.titleMode !== DisplayType.levels) {
          target.activeTitleMode = DisplayType.levels;
          this.titleModeService.updateTitleMode(target.activeTitleMode);
        }
        this.documentService.updateDocumentDataTitle(target, false);
      });
  }

  public swapRowsAndColumns(): void {
    this.rightSidebarOpened = false;
    this.swapRowsColumnsAction.invoke();
  }

  public openNTileSettingsDialog(): void {
    this.rightSidebarOpened = false;
    this.dialog
      .nTileSettings()
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: NTileDialogResult) => {
        const targets = this.targetService.createNTileTargets(
          result.target,
          result.nTileBuckets,
          result.nTileFunction
        );
        const createdTargets: Target[] = [];
        targets.forEach((target) => {
          const createdTarget = this.targetService.groupTargets(
            [target],
            Operator.ntiles,
            true
          );
          createdTargets.push(...createdTarget);
        });

        if (result.sourceTargetType === result.targetType) {
          const insertIndex =
            this.documentService.document.content[
              this.documentService.getTargetKey(result.targetType)
            ].findIndex((row: Target) => row.id === result.target.id) + 1;

          this.documentService.addDocumentData(
            createdTargets,
            result.targetType,
            true,
            insertIndex
          );
        } else {
          this.documentService.addDocumentData(
            createdTargets,
            result.targetType,
            true
          );
        }
      });
  }

  public saveOwnCodes(type: SaveOwnCodesType): void {
    this.rightSidebarOpened = false;
    this.audienceGroupsService
      .saveOwnCodes({
        survey: this.documentService.activeSurvey,
        type,
        fileName: '',
        targetGroups: this.formatSaveOWnCodesTargetGroups(),
      })
      .subscribe(
        (doc: TupDocument<DocumentAudienceGroup>) => {
          this.customAudiencesService.notifyOwnCodesAdded(type, doc);
          this.messageService.showSnackBar(
            'Custom audiences/media saved successfully',
            'OK',
            10000
          );
        },
        (error) => {
          console.log(`error`, error);
          this.messageService.showSnackBar(error, 'OK', 10000);
        }
      );
  }

  public openSnapshotManager(): void {
    this.rightSidebarOpened = false;
    let snapshots = cloneDeep(this.currentDocument.content.snapshots);
    snapshots = snapshots.reverse();
    const containerName = this.userContainerService.getContainer().name;
    this.dialog
      .manageSnapshots(snapshots, containerName, this.currentDocument)
      .afterClosed()
      .subscribe(() => {});
  }

  public openCustomAudienceManager(): void {
    this.rightSidebarOpened = false;
    this.manageCustomAudiencesService.manageCustomAudiencesModeChange(true);
    this.dialog
      .manageCustomAudiences()
      .afterClosed()
      .subscribe(() => {
        this.setMyDrive();
        this.manageCustomAudiencesService.manageCustomAudiencesModeChange(
          false
        );
        this.customAudiencesService.notifyOwnCodesContainerUpdated(
          SaveOwnCodesType.audience
        );
      });
  }

  public sendToPlan(): void {
    this.dialogService
      .sendToPlan({
        survey: this.documentService.activeSurvey,
        targetGroups: this.formatTargetGroups(),
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe(() => {
        this.requestLoadingService.setLoading({
          target: 'global',
          isLoading: true,
        });
        this.documentService.documentUpdated$
          .pipe(isNotNullOrUndefined(), first())
          .subscribe((doc: any) => {
            this.goToPlan(this.documentService.document.metadata.id);
            this.requestLoadingService.setLoading({
              target: 'global',
              isLoading: false,
            });
          });
      });
  }

  private goToPlan(docId: string): void {
    this.window.open(
      `${environment.sendToPlanUrl}${encodeURIComponent(docId)}`,
      '_blank'
    );
  }

  private formatTargetGroups(): SendToPlanTargetGroup[] {
    const targetGroups = [];
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        targetGroups.push({
          title: 'Rows',
          items: rows.map((target: Target) => ({
            selected: target.selectedForPlan || false,
            target,
          })),
        });
        targetGroups.push({
          title: 'Columns',
          items: columns.map((target: Target) => ({
            selected: target.selectedForPlan || false,
            target,
          })),
        });
      });

    return targetGroups;
  }

  private setMyDrive() {
    const currentContainer = this.userContainerService.getContainer();
    const user = this.authService.user;
    const userEmail = user.attributes.email;
    const usersContainer = this.authService.user.containers.find(
      (container) => container.name === userEmail
    );

    if (currentContainer && usersContainer.name !== currentContainer.name) {
      this.userContainerService.setContainer(usersContainer);
    }
  }

  private formatSaveOWnCodesTargetGroups(): SaveOwnCodesTargetGroup[] {
    const targetGroups = [];
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ tables, columns, rows }: DocumentDataState) => {
        targetGroups.push({
          title: 'bases*',
          selectAll: false,
          items: tables.map((target: Target) => ({
            selected: false,
            targetItem: this.convertTargetToAudienceGroupItem(target),
          })),
        });
        targetGroups.push({
          title: 'columns',
          selectAll: false,
          items: columns.map((target: Target) => ({
            selected: false,
            targetItem: this.convertTargetToAudienceGroupItem(target),
          })),
        });
        const sortRows =
          this.sortedRows &&
          this.reportPreferencesService.getActiveReportMode() ===
            ReportMode.crossTab
            ? this.sortedRows
            : rows;
        targetGroups.push({
          title: 'rows',
          selectAll: false,
          items: sortRows.map((target: Target) => ({
            selected: false,
            targetItem: this.convertTargetToAudienceGroupItem(target),
          })),
        });
      });

    return targetGroups;
  }

  private convertTargetToAudienceGroupItem(
    target: Target
  ): DocumentAudienceGroupItem {
    return {
      title: this.targetTitlePipe.transform(target),
      coding: target.coding,
      options: {
        statement: null,
        target: this.targetService.shallowCopyTarget(target),
      },
    };
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'global' ||
            targetLoading.target === 'edit-custom-audiences'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  private listenToDataTabChanges(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: Params) => {
        this.selectedTab = Number(params.tab);
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.currentDoc
      .pipe(isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.docOwnerName = doc.metadata.by.attributes.name;
        this.docName = doc.metadata.name;
        this.currentDocument = doc;
        this.surveys = doc.content.surveys;
        this.hasRowsAndColumns =
          doc.content.rows.length > 0 || doc.content.columns.length > 0;
        this.toggleTitleModeDataContextStatus();
      });

    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });

    this.documentService.restoreDocumentState$
      .pipe(takeUntil(this.unsubscribe), delay(0))
      .subscribe(() => {
        this.updateSurveys();
      });
  }

  private listenToIgnoreZeroWeightedResps(): void {
    this.documentService.ignoreZeroWeightedResps$
      .pipe(isNotNullOrUndefined())
      .subscribe((ignoreZeroWeightedResps: IgnoreZeroWeightedResps) => {
        this.ignoreZeroWeightedRespondents = ignoreZeroWeightedResps;
      });
  }

  private updateSurveys(): void {
    this.surveys = this.documentService.surveys;
    this.selectedSurvey = this.documentService.activeSurvey;
    this.visibleSurveys = this.documentService.getVisibleSurveys();
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.swapActionWarning =
          survey.provider === SurveyProvider.youGov
            ? SWAP_ROWS_COLUMNS_WARNING
            : '';
        this.selectedSurvey = survey;
        this.visibleSurveys = this.documentService.getVisibleSurveys();
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mode: ReportMode) => {
        this.activeReportMode = mode;
      });
  }

  private listenToReportPreferencesChanges(): void {
    this.reportPreferencesService.preference$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((preference: ReportPreference) => {
        this.zScoreHighlight =
          preference.cellStyleStatus === CellStyleStatus.zScoreHighlight;
        this.completeCasesOn = preference.completeCasesOn;
      });
  }

  private subscribeToSortedRows() {
    this.dataItemsService.sortedRows$.subscribe(
      (rows) => (this.sortedRows = rows)
    );
  }

  private unsubscribeLoadingSubscription(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenToRightSideBarOpenedStatus(): void {
    this.topBarService.rightSidebarOpenedState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((opened: boolean) => {
        this.rightSidebarOpened = opened;
        this.documentService.documentState$
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(({ tables, columns, rows }: DocumentDataState) => {
            this.hasRowsAndColumns = columns.length > 0 || rows.length > 0;
            this.toggleTitleModeDataContextStatus();
          });
      });
  }

  private resetTrendingCalculation(): void {
    this.trendingCalculationService.reset();
  }

  private toggleTitleModeDataContextStatus() {
    this.titleModes[3].disabled = !this.hasRowsAndColumns;
  }
}
