<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Volumetric Coding"
    class="ntile-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxFlex
      fxLayout="column grid"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="split-wrapper"
    >
      <ui-split
        unit="pixel"
        direction="horizontal"
        #split="uiSplit"
      >
        <ui-split-area
          [size]="400"
          [minSize]="380"
          [maxSize]="500"
        >
          <div
            fxLayout="column"
            class="builder-container"
          >
            <div fxLayout="column">
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex="grow"
                class="display-text-field"
              >
                <mat-label>Coding statement title</mat-label>
                <textarea
                  [(ngModel)]="transformedTitleStatement"
                  matInput
                  required
                  class="display-text-field-textarea"
                  placeholder="Coding title. Please enter midpoints."
                  (change)="onTitleChanged()"
                ></textarea>
              </mat-form-field>
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxGrow="1"
              class="display-container coding-preview__container"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <span class="coding-preview__label"
                  >Coding statement preview:</span
                >
              </div>

              <div class="coding-preview">
                <span>{{
                  codingStatement.length > 0
                    ? codingStatement
                    : "Coding statement preview. Please enter midpoints."
                }}</span>
              </div>
            </div>
          </div>
        </ui-split-area>

        <ui-split-area>
          <div
            fxLayout="column"
            fxLayoutGap="20px"
          >
            <div
              fxLayout="row"
              class="bold-labels"
            >
              <div
                fxLayout="column"
                fxLayoutGap="10px"
                fxFlex
                class="apply-coding"
              >
                <mat-label>Apply Coding to</mat-label>
                <mat-radio-group
                  aria-label="Select an option"
                  fxLayout="column"
                  fxLayoutGap="10px"
                  [(ngModel)]="selectedTargetType"
                >
                  <mat-radio-button
                    *ngFor="let type of targetTypeOptions"
                    [value]="getTargetTypeValue(type)"
                  >
                    {{
                      type.charAt(0).toUpperCase() + type.slice(1)
                    }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>

              <div
                fxlayout="column"
                fxFlex
              >
                <div
                  fxLayout="column"
                  fxLayoutGap="10px"
                >
                  <mat-label>Calculation method</mat-label>
                  <mat-form-field
                    appearance="outline"
                    tupMatFormField
                  >
                    <mat-label>Method</mat-label>
                    <mat-select
                      required
                      [(ngModel)]="selectedCalculationMethod"
                      (selectionChange)="updateCodingStatement()"
                      fxLayout="column"
                    >
                      <mat-option
                        *ngFor="let method of calculationMethods"
                        [value]="method"
                      >
                        {{ method }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  fxLayout="column"
                  fxLayoutGap="10px"
                >
                  <mat-label>Decimal</mat-label>
                  <mat-form-field
                    appearance="outline"
                    tupMatFormField
                  >
                    <mat-label>Decimal</mat-label>
                    <mat-select
                      required
                      [(ngModel)]="selectedDecimal"
                      (selectionChange)="updateCodingStatement()"
                      fxLayout="column"
                    >
                      <mat-option
                        *ngFor="let decimal of decimals"
                        [value]="decimal"
                      >
                        {{ decimal }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="table-container">
              <form [formGroup]="formGroup">
                <table
                  mat-table
                  [dataSource]="dataSource"
                  formArrayName="midpoints"
                >
                  <ng-container matColumnDef="coding">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                    >
                      Coding
                    </th>

                    <td
                      mat-cell
                      *matCellDef="let element"
                    >
                      <span
                        matPrefix
                        class="volumetric-node__code"
                        matTooltip="{{ element.transformedTitle }}"
                        >{{ element.coding }}</span
                      >
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="midpoint"
                    sticky="true"
                    stickyEnd="true"
                  >
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                    >
                      Midpoint
                    </th>

                    <td
                      mat-cell
                      *matCellDef="let element; let rowIndex = index"
                    >
                      <div [formGroupName]="rowIndex">
                        <mat-form-field>
                          <span
                            matPrefix
                            class="volumetric-node__code"
                            >*#</span
                          >
                          <input
                            type="number"
                            matInput
                            formControlName="midpoint"
                            (keyup)="updateCodingStatement()"
                            (change)="updateCodingStatement()"
                            ng-cut="updateCodingStatement()"
                            ng-paste="updateCodingStatement()"
                          />
                          <mat-hint
                            *ngIf="
                              midpointFormArray.controls[rowIndex]
                                .get('midpoint')
                                .hasError('min')
                            "
                            class="volumetric-node__midpoint-error"
                            >Minimum value: 1
                          </mat-hint>
                          <mat-hint
                            *ngIf="
                              midpointFormArray.controls[rowIndex].get(
                                'midpoint'
                              ).errors?.max
                            "
                            class="volumetric-node__midpoint-error"
                            >Maximum value: 99999
                          </mat-hint>
                        </mat-form-field>
                      </div>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </form>
            </div>
          </div>
        </ui-split-area>
      </ui-split>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onGenerateClick()"
      data-qa="generate-button"
      [disabled]="!isGeneratable()"
    >
      Generate
    </button>
  </mat-dialog-actions>
</div>
