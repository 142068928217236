import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DeleteResearchRequestBody,
  DeleteResearchResponseBody,
  DuplicateResearchRequestBody,
  DuplicateResearchResponseBody,
  GetResearchStatusRequestBody,
  GetResearchStatusResponseBody,
} from '../models';
import { environment } from 'src/environments/environment';
import { ClusterApiService } from './cluster-api.service';

@Injectable({
  providedIn: 'root',
})
export class ResearchService {
  constructor(private apiService: ClusterApiService) {}

  public getStatus(
    researchId: string
  ): Observable<GetResearchStatusResponseBody> {
    const body: GetResearchStatusRequestBody = {
      'research-session-id': researchId,
    };
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.getStatus,
      { body }
    );
  }

  public deleteResearch(
    researchId: string
  ): Observable<DeleteResearchResponseBody> {
    const body: DeleteResearchRequestBody = {
      'research-session-id': researchId,
    };
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.deleteResearch,
      { body }
    );
  }

  public duplicateResearch(
    researchId: string
  ): Observable<DuplicateResearchResponseBody> {
    const body: DuplicateResearchRequestBody = {
      'research-session-id': researchId,
    };
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.duplicateResearch,
      { body }
    );
  }
}
