<div class="table-container">
  <div class="cluster-table-viewport-container">
    <cdk-virtual-scroll-viewport
      [tvsItemSize]="33"
      headerEnabled="true"
      bufferMultiplier="4"
      headerHeight="44"
      class="virtual-scroll-viewport"
    >
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        class="non-standard-table cluster-table"
        (matSortChange)="sortData($event)"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          matColumnDef="{{ column }}"
        >
          <th
            mat-header-cell
            [mat-sort-header]="column"
            *matHeaderCellDef
            class="table-header-cell"
          >
            <div *ngIf="!columnMap[column]['editable']">
              <span>{{ columnMap[column]["name"] }}</span>
            </div>
            <div
              *ngIf="
                columnMap[column]['editable'] && !columnMap[column]['editing']
              "
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              (click)="$event.stopPropagation()"
            >
              <span (click)="onHeaderClick(column)">{{
                columnMap[column]["name"]
              }}</span>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-align-left]="column === 'variable'"
            [class.text-align-center]="column === 'rowNumber'"
            [ngClass]="
              column.startsWith('Cluster')
                ? element.clusters[column.split('_')[1] - 1]?.segment || ''
                : ''
            "
          >
            <ng-container
              *ngTemplateOutlet="
                cellValue;
                context: {
                  element: element,
                  column: column,
                  isCluster: column.startsWith('Cluster')
                }
              "
            ></ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.is-sticky-row]="row.isSticky"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<ng-template
  #cellValue
  let-element="element"
  let-column="column"
  let-isCluster="isCluster"
>
  <ng-container *ngIf="isCluster">
    {{ element["clusters"][column.split("_")[1] - 1].value }}
  </ng-container>
  <ng-container *ngIf="!isCluster">
    <ng-container *ngIf="column === 'type'">
      {{
        element[column] !== "likert"
          ? element[column]
          : "likert (" + likertScaleSettingForSurvey + " = Agree)"
      }}
    </ng-container>
    <ng-container *ngIf="column !== 'type'">
      {{ element[column] }}
    </ng-container>
  </ng-container>
</ng-template>
