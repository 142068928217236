<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Change data"
    class="dialog-title"
    cdkDragHandle
  >
    <button
      mat-icon-button
      matTooltip="Click to select the rows you want to hide from your P-Map"
    >
      <mat-icon
        color="primary"
        class="material-icons-outlined"
        >info
      </mat-icon>
    </button>
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
    >
      close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div
      fxFlex
      class="control-buttons"
      fxLayout="row"
      fxLayoutGap="10px"
      style="padding: 2px"
    >
      <button
        onclick="this.blur()"
        fxFlex="0 0 auto"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        class="control-button"
        [disabled]="selectedRows === 0"
        (click)="setDataVisible(true)"
      >
        <mat-icon
          matTooltip="Show data"
          class="material-symbols-outlined"
        >
          visibility
        </mat-icon>
      </button>

      <button
        onclick="this.blur()"
        fxFlex="0 0 auto"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        class="control-button"
        [disabled]="selectedRows === 0"
        (click)="setDataVisible(false)"
      >
        <mat-icon
          matTooltip="Hide data"
          class="material-symbols-outlined"
        >
          visibility_off
        </mat-icon>
      </button>
    </div>

    <div class="table-container">
      <cdk-virtual-scroll-viewport
        [tvsItemSize]="50"
        headerEnabled="true"
        [headerHeight]="56"
        class="virtual-scroll-viewport"
      >
        <table
          ctrlShift
          (ctrlShift)="onCtrlShift($event)"
          mat-table
          [dataSource]="dataSource"
        >
          <ng-container matColumnDef="selected">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header column-header--checkbox"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [checked]="isAllSelected()"
                (change)="onAllRowsSelectedChange($event)"
                [indeterminate]="selectedRows > 0 && !isAllSelected()"
              ></mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="column-cell--checkbox"
            >
              <mat-checkbox
                tupMatCheckbox
                appearance="small"
                color="primary"
                [checked]="selectedRowIds[element.id]"
                (change)="onSingleRowSelectedChange($event, element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="visibility">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header column-header--visibility"
            ></th>
            <td
              mat-cell
              class="column-cell--visibility"
              *matCellDef="let element"
            >
              <button
                mat-icon-button
                color="primary"
                class="column-cell--visibility-btn"
                (click)="setDataVisible(!element.visible, element)"
              >
                <mat-icon
                  [matTooltip]="element.visible ? 'Hide data' : 'Show data'"
                  class="material-symbols-outlined"
                >
                  {{ element.visible ? "visibility_off" : "visibility" }}
                </mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Title
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [matTooltip]="element.title"
            >
              {{ element.title | truncate: 100 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="xAxis">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              X-Axis
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ element.x }}
            </td>
          </ng-container>

          <ng-container matColumnDef="yAxis">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Y-Axis
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ element.y }}
            </td>
          </ng-container>

          <ng-container matColumnDef="weight">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="column-header"
            >
              Weight
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="column-cell--weight"
            >
              {{ element.z }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{
              'is-selected': selectedRowIds[row.id],
              'is-hidden': !row.visible
            }"
          ></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onApplyClick()"
    >
      Apply changes
    </button>
  </mat-dialog-actions>
</div>
