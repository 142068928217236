import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { MatSort, Sort } from '@angular/material/sort';
import { cloneDeep } from 'lodash';
import { compare } from '../../utils/sortHelper';
import { ClusterTableRowData, Survey, TargetColumn } from '../../models';
import { DocumentService, XlsxService } from 'src/app/services';
import { CsvService } from 'src/app/services/csv.service';
import {
  ClusterTableXlsxData,
  CrosstabTableCsvBuilder,
} from 'src/app/builders/crosstab-table-csv.builder';
import { TargetTitlePipe } from '../../pipes';
import { TupCsvBuilder } from '@telmar-global/tup-document-exporter';
import { CrosstabTableXlsxBuilder } from 'src/app/builders/crosstab-table-xlsx.builder';
import { MatDialog } from '@angular/material/dialog';
import { RenameDialogComponent } from 'src/app/dialogs';

@Component({
  selector: 'app-cluster-table',
  templateUrl: './cluster-table.component.html',
  styleUrls: ['./cluster-table.component.scss'],
})
export class ClusterTableComponent
  extends TupCsvBuilder
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableData: ClusterTableRowData[];
  @Input() survey: Survey;
  @Input() clusterTitles: string[];
  @Output() clusterTitleChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  public columnMap = {
    rowNumber: {
      id: 'rowNumber',
      name: ' ',
      editable: false,
      editing: false,
    },
    variable: {
      id: 'variable',
      name: 'Variable',
      editable: false,
      editing: false,
    },
    type: {
      id: 'type',
      name: 'Type',
      editable: false,
      editing: false,
    },
    rank: {
      id: 'rank',
      name: 'Rank',
      editable: false,
      editing: false,
    },
    determination: {
      id: 'determination',
      name: 'Determination %',
      editable: false,
      editing: false,
    },
    total: {
      id: 'total',
      name: 'Total',
      editable: false,
      editing: false,
    },
  };

  private readonly defaultColumns = [
    'rowNumber',
    'variable',
    'type',
    'rank',
    'determination',
    'total',
  ];
  public displayedColumns: string[] = [...this.defaultColumns];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public dataSource: MatTableDataSource<ClusterTableRowData> =
    new TableVirtualScrollDataSource([]);

  private unsubscribe: Subject<void> = new Subject<void>();
  public targetColumns: TargetColumn[] = [];
  public totalRow: ClusterTableRowData = null;
  public likertScaleSettingForSurvey: string;

  public sortState: Sort = {
    active: 'rank',
    direction: 'asc',
  };

  constructor(
    private documentService: DocumentService,
    private csvService: CsvService,
    private targetTitlePipe: TargetTitlePipe,
    private xlsxService: XlsxService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.setlikertScaleForSurvey();
    this.setupTable(cloneDeep(this.tableData));

    this.sort.active = this.sortState.active;
    this.sort.direction = this.sortState.direction;
    this.sort.sortChange.emit(this.sortState);
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  sortData(sort: Sort) {
    this.sortState = sort;
    let sortedData = cloneDeep(this.tableData);
    if (!(!sort.active || sort.direction === '')) {
      const isClusterData = sort.active.startsWith('Cluster');
      const dataKey = isClusterData
        ? Number(sort.active.split('_')[1]) - 1
        : sort.active;
      const isAsc = sort.direction === 'asc';
      sortedData = [
        ...sortedData.slice(0, 2),
        ...sortedData
          .slice(2)
          .sort((a, b) =>
            isClusterData
              ? compare(
                  a.clusters[dataKey].value,
                  b.clusters[dataKey].value,
                  isAsc
                )
              : compare(a[dataKey], b[dataKey], isAsc)
          ),
      ];
    }
    this.populateTableData(sortedData);
  }

  private setupTable(data: ClusterTableRowData[]): void {
    this.clearCurrentData();
    this.populateTableData(data);
    this.addTableColumns(data);
  }

  private populateTableData(data: ClusterTableRowData[]): void {
    this.dataSource.data = data;
  }

  private addTableColumns(data: ClusterTableRowData[]): void {
    this.displayedColumns.push(
      ...Array.from(
        { length: data[0].clusters.length },
        (_, index) => `Cluster_${index + 1}`
      )
    );

    this.columnMap = {
      ...this.columnMap,
      ...data[0].clusters.reduce((acc, _, index) => {
        acc[`Cluster_${index + 1}`] = {
          id: `Cluster_${index + 1}`,
          name: this.getClusterTitle(index),
          editable: true,
          editing: false,
        };
        return acc;
      }, {}),
    };

    this.clusterTitles = Object.values(this.columnMap)
      .filter((column) => column.id.startsWith('Cluster'))
      .map((column) => column.name);
  }

  private getClusterTitle(index: number) {
    if (this.clusterTitles && this.clusterTitles.length - 1 >= index) {
      return this.clusterTitles[index];
    } else {
      return `Cluster ${index + 1}`;
    }
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.displayedColumns = [...this.defaultColumns];
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getColumnCustomNames(): string[] {
    return this.displayedColumns.map((col) => this.columnMap[col].name);
  }

  public exportToCsv(docName?: string): void {
    const documentName = docName || this.documentService.document.metadata.name;
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addClusterTableData({
      documentName,
      displayedColumns: this.getColumnCustomNames(),
      data: this.tableData,
      survey: this.survey,
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
    });
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.getXlsxExportData(docName);
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  private getXlsxExportData(
    docName?: string
  ): [string, CrosstabTableXlsxBuilder] {
    const documentName = docName || this.documentService.document.metadata.name;
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');
    const crossTabTableData: ClusterTableXlsxData = {
      documentName,
      data: this.tableData,
      survey: this.survey,
      displayedColumns: this.getColumnCustomNames(),
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
    };
    crossTabTableBuilder.addClusterTable(crossTabTableData);
    crossTabTableBuilder.build();

    return [documentName, crossTabTableBuilder];
  }

  public exportToSheets(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.getXlsxExportData(docName);
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  private setlikertScaleForSurvey() {
    const fiveAsHighestSurveyProviders = ['Vividata', 'Numeris'];
    this.likertScaleSettingForSurvey = fiveAsHighestSurveyProviders.includes(
      this.survey.provider
    )
      ? '5'
      : '1';
  }

  public onHeaderClick(column: string) {
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle: 'Change title',
          inputTitle: 'Title',
          inputValue: this.columnMap[column]['name'],
        },
        closeOnNavigation: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((results: any) => {
        if (results?.name) {
          const index = this.displayedColumns
            .filter((column) => column.startsWith('Cluster'))
            .findIndex((displayedColumn) => column === displayedColumn);
          this.clusterTitles[index] = results.name;
          this.clusterTitleChanged.emit(this.clusterTitles);
          this.columnMap[column]['name'] = results.name;
        }
      });
  }
}
