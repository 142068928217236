import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import {
  CodebookSelectionService,
  DocumentService,
  TargetService,
} from '../services';
import {
  DocumentDataState,
  MAX_COLUMN_TARGET_LIMIT,
  MAX_ROW_TARGET_LIMIT,
  Target,
  TargetType,
  APPLICATION_NAME,
} from '../models';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { first } from 'rxjs/operators';

export interface SeparateOperatorActionContext {
  targetType: TargetType;
  selectedTargets: Target[];
  shouldRefreshCrossTab?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SeparateOperatorAction extends ActionDefinition<SeparateOperatorActionContext> {
  constructor(
    private documentService: DocumentService,
    private targetService: TargetService,
    private codebookSelectionService: CodebookSelectionService,
    private messageService: TupUserMessageService
  ) {
    super();
  }

  public invoke(context: SeparateOperatorActionContext): void {
    let existingTargets = 0;
    let docTables = [],
      docColumns = [],
      docRows = [];

    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ tables, columns, rows }: DocumentDataState) => {
        existingTargets =
          context.targetType === TargetType.columns
            ? columns.length
            : rows.length;

        docTables = tables;
        docColumns = columns;
        docRows = rows;
      });

    if (
      !this.documentService.hasCompatibleWeightsThenAlert(
        context.selectedTargets,
        docTables,
        docColumns,
        docRows
      )
    ) {
      return;
    }

    if (this.hasReachedMaxLimit(context, existingTargets)) {
      this.showMaxLimitAlert(context, existingTargets);
      return;
    }

    const targets = this.targetService.separateTargets(context.selectedTargets);
    const shouldRefreshCrossTab =
      context.shouldRefreshCrossTab !== undefined
        ? context.shouldRefreshCrossTab
        : true;
    this.documentService.addDocumentData(
      targets,
      context.targetType,
      shouldRefreshCrossTab
    );
    this.codebookSelectionService.unselectNodes();
  }

  private hasReachedMaxLimit(
    context: SeparateOperatorActionContext,
    existingTargetItemsCount: number
  ): boolean {
    return (
      context.targetType !== TargetType.tables &&
      existingTargetItemsCount + context.selectedTargets.length >
        this.getTargetTypeLimit(context.targetType)
    );
  }

  private showMaxLimitAlert(
    context: SeparateOperatorActionContext,
    existingCount: number
  ): void {
    const targetType = this.getTargetType(context.targetType);
    const selectedCount = context.selectedTargets.length;
    const targetLimit = this.getTargetTypeLimit(context.targetType);
    this.messageService.openMessageDialog(
      `Sorry! You cannot add ${selectedCount} ${this.formatTargetTypeStr(
        targetType,
        selectedCount
      )}.
          Maximum allowed limit for ${targetType}s is ${targetLimit} and ${existingCount} ${this.formatTargetTypeStr(
        targetType,
        existingCount
      )} ${this.formatBeVerbStr(existingCount)} already added.`,
      APPLICATION_NAME
    );
  }

  private formatTargetTypeStr(targetType: string, count: number): string {
    return targetType + (this.isPlural(count) ? 's' : '');
  }

  private formatBeVerbStr(count: number): string {
    return this.isPlural(count) ? 'are' : 'is';
  }

  private isPlural(count: number): boolean {
    return count > 1;
  }

  private getTargetTypeLimit(targetType: TargetType): number {
    return targetType === TargetType.columns
      ? MAX_COLUMN_TARGET_LIMIT
      : MAX_ROW_TARGET_LIMIT;
  }

  private getTargetType(targetType: TargetType): string {
    return targetType === TargetType.columns
      ? 'column'
      : targetType === TargetType.rows
      ? 'row'
      : 'table';
  }
}
