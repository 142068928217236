import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './app-api-error-dialog.component.html',
  styleUrls: ['./app-api-error-dialog.component.scss'],
})
export class AppApiErrorDialogComponent implements OnInit {
  public appMessage: string;
  public errorMessage: string;
  public appTitle: string;

  constructor(
    public dialogRef: MatDialogRef<AppApiErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppApiErrorDialogComponent
  ) {
    this.errorMessage = data.errorMessage;
    this.appMessage = data.appMessage;
    this.appTitle = data.appTitle;
  }

  ngOnInit(): void {}

  public ok() {
    this.dialogRef.close(true);
  }

  public close() {
    this.dialogRef.close(false);
  }
}
