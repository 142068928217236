import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  DEFAULT_CROSSTAB_NAME,
  DEFAULT_QUICK_REPORT_NAME,
  MAX_NUMBER_TREND_SURVEYS,
  Survey,
  SurveyTimeDocument,
  Target,
  ViewType,
} from '../models';
import { TupAudienceGroupsService } from '@telmar-global/tup-audience-groups';
import { DocumentService } from './document.service';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentService,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { Router } from '@angular/router';
import { omit, uniqBy } from 'lodash';
import { TupUserPreferenceStorageService } from '@telmar-global/tup-user-preference-storage';
import { TupLoggerService } from '@telmar-global/tup-logger-angular';
import { RequestLoadingService } from './request-loading.service';
import { NameReportDialogResult } from '../dialogs';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class CreateNewDocumentService {
  constructor(
    private audienceGroupsService: TupAudienceGroupsService,
    private documentService: DocumentService,
    private authService: TupAuthService,
    private tupDocumentService: TupDocumentService,
    private router: Router,
    private loggerService: TupLoggerService,
    private requestLoadingService: RequestLoadingService,
    private userPreferenceService: TupUserPreferenceStorageService,
    private dialogService: DialogService,
    private userContainerService: TupUserContainerService
  ) {}

  public create(isTemplateDocType: boolean): void {
    if (isTemplateDocType) {
      const user = this.authService.user;
      const containerName = user.attributes.email;

      this.openSurveySelector(containerName, isTemplateDocType);
    } else {
      this.dialogService
        .nameReport()
        .afterClosed()
        .subscribe((dialogResult: NameReportDialogResult) => {
          dialogResult &&
            this.openSurveySelector(
              dialogResult.container.name,
              isTemplateDocType,
              dialogResult.name,
              dialogResult.description
            );
        });
    }
  }

  private openSurveySelector(
    containerName: string,
    isTemplateDocType: boolean,
    crossTabName?: string,
    crossTabDescription?: string
  ) {
    this.audienceGroupsService
      .surveySelector([], null, null, null, MAX_NUMBER_TREND_SURVEYS)
      .pipe(isNotNullOrUndefined())
      .subscribe((surveys: any) => {
        this.requestLoadingService.setLoading({
          target: 'global',
          isLoading: true,
        });
        this.createNewDocument(
          surveys.survey,
          containerName,
          isTemplateDocType,
          crossTabName,
          crossTabDescription
        );
      });
  }

  private async createNewDocument(
    surveys: Survey[],
    containerName: string,
    isTemplateDocType = false,
    crossTabName?: string,
    crossTabDescription?: string
  ): Promise<void> {
    if (!crossTabName || crossTabName?.length === 0) {
      crossTabName = isTemplateDocType
        ? DEFAULT_QUICK_REPORT_NAME
        : DEFAULT_CROSSTAB_NAME;
    }
    const docId = await this.documentService
      .createDocument(
        containerName,
        crossTabName,
        crossTabDescription,
        undefined,
        isTemplateDocType
      )
      .toPromise();

    const currentContainer = this.userContainerService.getContainer();
    if (currentContainer.name !== containerName) {
      let usersContainer = this.authService.user.containers.find(
        (container) => container.name === containerName
      );
      this.userContainerService.setContainer(usersContainer);
    }

    const document = (await this.tupDocumentService
      .get(containerName, docId)
      .toPromise()) as TupDocument<SurveyTimeDocument>;

    const selectedSurveys = this.removeSurveysSearchInfo(surveys);
    this.documentService.setCurrentDocument(document);
    this.documentService
      .loadTableBases(selectedSurveys[0])
      .subscribe((tablebases: Target[]) => {
        this.documentService.tablebases.next(tablebases);
        this.documentService.createInitialTable();
      });
    this.documentService.setDocumentSurveys(selectedSurveys, false);
    this.documentService.setDefaultReportPreferences();
    this.documentService.setDefaultCodingGridPreferences();
    this.documentService.setDefaultChartStyles();
    this.documentService.setActiveDocumentId(docId);
    this.setListRecentSurveys(selectedSurveys);

    this.goToDocument(docId, selectedSurveys, isTemplateDocType);
  }

  private removeSurveysSearchInfo(surveys: Survey[]): Survey[] {
    return surveys.map((survey: Survey) =>
      omit(survey, ['occurrenceCount', 'customCode'])
    );
  }

  public setListRecentSurveys(surveys: Survey[]) {
    this.userPreferenceService
      .hasItem(environment.userPreferences.lastTenSurveysUsed)
      .pipe(first())
      .subscribe(
        async (userPrefExists: boolean) => {
          if (userPrefExists) {
            this.userPreferenceService
              .getItem<Survey[]>(environment.userPreferences.lastTenSurveysUsed)
              .pipe(first())
              .subscribe(
                (surveyList: Survey[]) => {
                  surveyList.forEach((savedSurvey) => {
                    if (
                      surveys.filter(
                        (survey) =>
                          survey.code === savedSurvey.code &&
                          survey.authorizationGroup ===
                            savedSurvey.authorizationGroup
                      ).length !== 0
                    ) {
                      surveyList.splice(surveyList.indexOf(savedSurvey), 1);
                    }
                  });
                  let surveysToSave = uniqBy(
                    surveys.concat(surveyList),
                    'code'
                  ).map((survey) =>
                    omit(survey, ['occurrenceCount', 'customCode'])
                  );

                  if (surveysToSave.length > 10) {
                    surveysToSave = surveysToSave.slice(0, 10);
                  }
                  this.userPreferenceService
                    .setItem<Survey[]>(
                      environment.userPreferences.lastTenSurveysUsed,
                      surveysToSave
                    )
                    .pipe(first())
                    .subscribe(
                      () =>
                        this.loggerService.info(
                          'lastTenSurveysUsed User preference stored successfully'
                        ),
                      (error: any) =>
                        this.loggerService.error(
                          'Error storing user preference',
                          error
                        )
                    );
                },
                (error: any) =>
                  this.loggerService.error(
                    'Error retrieving user preference',
                    error
                  )
              );
          } else {
            if (surveys.length > 10) {
              surveys = surveys.slice(0, 10);
            }

            surveys = this.removeSurveysSearchInfo(surveys);
            this.userPreferenceService
              .setItem<Survey[]>(
                environment.userPreferences.lastTenSurveysUsed,
                surveys
              )
              .pipe(first())
              .subscribe(
                () =>
                  this.loggerService.info(
                    'lastTenSurveysUsed User preference stored successfully'
                  ),
                (error: any) =>
                  this.loggerService.error(
                    'Error storing user preference',
                    error
                  )
              );
          }
        },
        (error: any) =>
          this.loggerService.error('Error setting user preference', error)
      );
  }

  private goToDocument(
    docId: string,
    selectedSurveys: Survey[],
    isTemplateDocType: boolean
  ): void {
    const url = isTemplateDocType ? `doc/${docId}/chart` : `doc/${docId}/data`;
    this.documentService.documentUpdated$
      .pipe(isNotNullOrUndefined(), first())
      .subscribe(() => {
        if (docId && selectedSurveys.length) {
          this.router.navigate([url], {
            state: {
              hasDocument: true,
              surveys: selectedSurveys,
              isQuickReport: isTemplateDocType,
            },
            ...(isTemplateDocType
              ? {}
              : {
                  queryParams: {
                    tab: ViewType.crossTab,
                  },
                }),
          });
        }
      });
  }
}
