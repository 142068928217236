<tup-mat-title-bar
  title="Survey code editor"
  class="dialog-title"
>
  <mat-icon
    title="Close dialog"
    class="close-icon"
    (click)="cancel()"
  >
    close
  </mat-icon>
</tup-mat-title-bar>

<mat-dialog-content>
  <div
    *ngFor="let survey of surveys; let i = index"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
    class="survey-row-container"
  >
    <span
      fxFlex
      fxGrow="1"
      class="survey-title margin-adjustment"
      [matTooltip]="survey.title"
    >
      {{ i + 1 }}. {{ survey.title }}
    </span>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="survey-code-container"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field-without-label"
      >
        <input
          matInput
          type="text"
          required
          [(ngModel)]="survey.customCode"
          (ngModelChange)="onSurveyCodeChanged()"
        />
      </mat-form-field>
      <button
        mat-stroked-button
        tupMatButton
        appearance="nano"
        color="primary"
        matTooltip="Reset to default survey code"
        (click)="onRefreshSurveyCode(survey)"
        class="margin-adjustment"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="dialog-actions"
>
  <button
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    cdkFocusRegionstart
    (click)="saveAs()"
    [disabled]="!canSave"
    data-qa="rename-save-button"
  >
    Save
  </button>
</mat-dialog-actions>
