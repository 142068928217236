import { cloneDeep } from 'lodash';

import {
  TupChartData,
  TupXlsxChartBuilder,
  CIRCULAR_CHART_TYPES,
  UNSUPPORTED_PPTX_CHART_TYPES,
} from '@telmar-global/tup-document-exporter';

import { ExportFileType, SurveyTimeProps } from '../models';
import { provideColorsToCharts } from '../utils/colorHelper';
import { getExportProps } from '../utils/export-utils';

export class SurveyTimeXlsxChartsBuilder extends TupXlsxChartBuilder {
  private readonly badCharsReg = /[?*:\\/\[\]]/g;
  private readonly sheetNameLength = 27;

  public async init(
    props: SurveyTimeProps[],
    exportFileType: ExportFileType
  ): Promise<TupXlsxChartBuilder> {
    const builder: TupXlsxChartBuilder = new TupXlsxChartBuilder();

    for (const {
      settings,
      primaryData,
      secondaryData,
      charts,
      title,
      subtitle,
      source,
      copyright,
      chartNote,
    } of props) {
      const formattedSource = source.join(';');
      const formattedCopyright = copyright.join(';');
      let chartNoteTitle;
      let chartNoteDescription;

      if (chartNote) {
        chartNoteTitle = chartNote.title;
        chartNoteDescription = chartNote.description;
      }

      const {
        primaryChartType,
        primaryChartData,
        secondaryChartType,
        chartOpts,
      } = getExportProps(settings, primaryData, secondaryData, exportFileType);

      if (
        UNSUPPORTED_PPTX_CHART_TYPES.includes(primaryChartType) ||
        secondaryChartType
      ) {
        for (const [index, chart] of charts.toArray().entries()) {
          builder.addWorksheet(
            this.formatTitle(title),
            this.formatTitle(subtitle),
            formattedSource,
            new Date(),
            formattedCopyright,
            chartNoteTitle,
            chartNoteDescription
          );

          await builder.addImage(
            [primaryChartData[index]],
            chart.toBase64Image(),
            chartOpts
          );
        }
      } else if (CIRCULAR_CHART_TYPES.includes(primaryChartType)) {
        primaryChartData.forEach((data: TupChartData, i: number) => {
          // tslint:disable-next-line:variable-name
          const _options: { [key: string]: any } = cloneDeep(chartOpts);

          const startColor: string = !_options.chartColors[i].startsWith('#')
            ? `#${_options.chartColors[i]}`
            : _options.chartColors[i];

          _options.chartColors = provideColorsToCharts(
            startColor,
            data.values.length
          );

          builder.addWorksheet(
            this.formatTitle(title),
            this.formatTitle(subtitle),
            formattedSource,
            new Date(),
            formattedCopyright,
            chartNoteTitle,
            chartNoteDescription
          );

          builder.addCoreChart(primaryChartType, [data], _options);
        });
      } else {
        builder.addWorksheet(
          this.formatTitle(title),
          this.formatTitle(subtitle),
          formattedSource,
          new Date(),
          formattedCopyright,
          chartNoteTitle,
          chartNoteDescription
        );
        builder.addCoreChart(primaryChartType, primaryChartData, chartOpts);
      }
    }

    return builder;
  }

  private formatTitle(title: string): string {
    return title
      .replace(this.badCharsReg, '')
      .substring(0, this.sheetNameLength);
  }
}
