import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  Empty,
  TupDocument,
  TupDocumentService,
  TupDocumentStatus,
  TupDocumentTypeId,
  TupDocumentTypes,
} from '@telmar-global/tup-document-storage';
import { HttpResponse } from '@angular/common/http';
import { GetSurveyResponse, SurveyTimeDocument } from '../models';
import { merge, pick } from 'lodash';
import { catchError, mergeMap } from 'rxjs/operators';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(
    private authService: TupAuthService,
    private documentService: DocumentService,
    private tupDocumentService: TupDocumentService
  ) {}

  public importJSON(
    file: File,
    userEmail: string,
    docTypeId:
      | TupDocumentTypeId.SURVEYTIME_CAMPAIGN
      | TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
      | TupDocumentTypeId.OWN_CODES_TARGET,
    userContainerName?: string
  ): Observable<HttpResponse<Empty>> {
    return this.read(file).pipe(
      mergeMap((result) => {
        const docJSON = JSON.parse(result);
        const doc: TupDocument<SurveyTimeDocument> = merge(
          {},
          pick(docJSON, [
            'metadata.type',
            'metadata.status',
            'content',
            'metadata.name',
          ]),
          {
            metadata: {
              name: `${docJSON.metadata.name} (imported)`,
              type: TupDocumentTypes[docTypeId],
              status: TupDocumentStatus.ACTIVE,
            },
          }
        );

        if (doc.content.snapshots) {
          doc.content.snapshots = [];
        }

        const userContainer = this.authService.user.containers.find(
          (container: UserContainer) => container.name === userEmail
        );

        const containerName = userContainerName || userContainer.name;

        if (docJSON.metadata.type.id !== docTypeId) {
          return throwError('Incorrect file type for importing');
        }

        if (docTypeId === TupDocumentTypeId.OWN_CODES_TARGET) {
          if (this.invalidCustomAudiencesFile(docJSON)) {
            return throwError('Incorrect custom audiences file');
          }
          return this.handleCustomDocImport(containerName, doc);
        } else {
          return this.createDocument(containerName, doc);
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private handleCustomDocImport(
    containerName: string,
    doc: TupDocument<any>
  ): Observable<HttpResponse<Empty>> {
    const surveyCode = doc.content.survey.code;
    if (!doc.content.survey.provider) {
      return this.documentService.getSurvey(surveyCode).pipe(
        mergeMap((result: GetSurveyResponse) => {
          if (doc.content) {
            doc.content.survey.provider = result.survey['survey-provider'];
          }
          return this.createDocument(containerName, doc);
        })
      );
    } else {
      return this.createDocument(containerName, doc);
    }
  }

  private invalidCustomAudiencesFile(docJSON: TupDocument<any>): boolean {
    let invalid = false;
    if (
      docJSON.metadata.type?.id !== TupDocumentTypeId.OWN_CODES_TARGET ||
      !docJSON.content.survey ||
      !docJSON.content.survey.code
    ) {
      invalid = true;
    }

    return invalid;
  }

  private createDocument(
    containerName: string,
    doc: TupDocument<SurveyTimeDocument>
  ): Observable<HttpResponse<Empty>> {
    return this.tupDocumentService.create(containerName, doc);
  }

  private read(file: File): Observable<string> {
    const fileReader = new FileReader();

    return new Observable((subscriber: Subscriber<string>) => {
      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        subscriber.next(event.target.result.toString());
        subscriber.complete();
      };

      fileReader.onerror = (error: ProgressEvent<FileReader>): void => {
        subscriber.error(error);
      };

      fileReader.readAsText(file);
    });
  }
}
