<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Export factor scores"
    class="save-factor-codes-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
      class="save-factor-codes-content"
    >
      <form
        [formGroup]="formGroup"
        fxLayout="column"
        class="save-factor-codes-form-field"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFill
          class="display-text-field"
        >
          <mat-label>Title</mat-label>
          <input
            type="text"
            required
            matInput
            formControlName="name"
          />
          <mat-error
            *ngIf="formGroup.get('name').errors?.required"
            class="save-factor-codes__name-error"
            >Please enter name</mat-error
          >
        </mat-form-field>

        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          formGroupName="exportGroup"
          class="export-checkboxes"
        >
          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            formControlName="saveForCrosstab"
            class="tiny-checkbox"
          >
            Export factors for crosstab
          </mat-checkbox>
        </div>

        <tup-user-container-selector
          #userContainerSelector
          [options]="{ showSubheader: true, showDivider: true }"
          (selectionChange)="selectionChange($event)"
        >
        </tup-user-container-selector>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="saveCodes()"
      data-qa="generate-button"
      [disabled]="
        formGroup.get('name').errors?.required ||
        formGroup.get('exportGroup').errors?.requireCheckboxesToBeChecked
      "
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
