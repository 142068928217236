<ng-template #own>
  <p>
    Are you sure you want to delete
    <b
      ><i>{{ metadata.name }}</i></b
    >?
  </p>
  <p>
    This action cannot be undone. Your document will be permanently deleted.
  </p>
</ng-template>

<ng-template #shared>
  <p>
    Caution: This {{ options.documentType }}
    <b
      ><i>{{ metadata.name }}</i></b
    >
    is currently shared with other users.
  </p>
  <p>
    Deleting it will also remove access for those users. Are you sure you want
    to proceed?
  </p>
</ng-template>
