import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ColumnFilter,
  ColumnFilterOperator,
  DataItem,
  DataItemType,
  SelectMenuOption,
  ChartFilter,
  ChartSettingsMode,
  ChartFilterOperator,
  FILTER_OPERATORS,
  DataItemId,
  ChartTargetMode,
  Target,
  CrossTabTableDataCellMetaData,
} from '../../models';
import { first } from 'rxjs/operators';
import { DataItemsService } from '../../services/data-items.service';
import { TargetTitlePipe } from '../../pipes';
import { cloneDeep } from 'lodash';

export interface ChartFiltersDialogDataModel {
  chartSettingsMode: ChartSettingsMode;
  chartFilters: ChartFilter[];
  targetMode: ChartTargetMode;
  targets?: Target[];
  chartData?: any;
}

@Component({
  templateUrl: './chart-filters-dialog.component.html',
  styleUrls: ['./chart-filters-dialog.component.scss'],
})
export class ChartFiltersDialogComponent implements OnInit {
  public readonly maxFilterNumber = 5;
  public targetColumns: SelectMenuOption<string>[] = [];
  public columnId: string;
  public filters: ColumnFilter[] = [];
  public dataItems: DataItem[];
  public readonly conditionalOperators: string[] = [
    ColumnFilterOperator.greaterThan,
    ColumnFilterOperator.lessThan,
    ColumnFilterOperator.equal,
    ColumnFilterOperator.greaterThanOrEqual,
    ColumnFilterOperator.lessThanOrEqual,
    ColumnFilterOperator.between,
  ];
  public shouldShowClearButton = false;
  public hasVolumetricCoding: boolean;
  public dataItemType: typeof DataItemType = DataItemType;
  public readonly chartSettingsModeType: typeof ChartSettingsMode =
    ChartSettingsMode;
  public readonly filterOperators: ChartFilterOperator[] = FILTER_OPERATORS;
  public chartFilterOperator = ChartFilterOperator;
  public chartSettingsMode: ChartSettingsMode;
  public sortColumns: any[];
  public chartDataItems: SelectMenuOption<DataItemId>[] = [];
  public chartFilters: ChartFilter[];
  public targets: Target[] = [];
  public chartData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChartFiltersDialogDataModel,
    public dialogRef: MatDialogRef<ChartFiltersDialogComponent>,
    private dataItemsService: DataItemsService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    this.chartSettingsMode = data.chartSettingsMode;
    this.chartFilters = cloneDeep(data.chartFilters);
    this.targets = data.targets;
    this.chartData = data.chartData;

    if (this.chartSettingsMode !== ChartSettingsMode.global) {
      this.updateSortColumns(data.targetMode);
    }
  }

  ngOnInit(): void {
    this.dataItemsService.chartDataItems$
      .pipe(first())
      .subscribe((dataItems: DataItem[]) => {
        this.formatChartDataItem(dataItems);
      });
  }

  public onFilterCancelClick(filter: ChartFilter): void {
    if (this.chartSettingsMode !== ChartSettingsMode.global) {
      filter.target = 'None';
    }
    filter.dataItem = DataItemType.none;
    filter.operator = ChartFilterOperator.none;
    filter.value[0] = 0;
    filter.value[1] = 0;
  }

  public onFilterValueChange(filter: ChartFilter): void {
    if (this.isInvalidNoneNegativeNumber(filter.value[0])) {
      filter.value[0] = 0;
    }
    if (this.isInvalidNoneNegativeNumber(filter.value[1])) {
      filter.value[1] = 0;
    }
  }

  public onFilterValueInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  private formatChartDataItem(dataItems: DataItem[]): void {
    const hasVolumetricData =
      this.chartData?.cellMetadataSets.filter(
        (cellMetadata: CrossTabTableDataCellMetaData) =>
          cellMetadata.isVolumetricCoding
      ).length > 0;
    this.chartDataItems = dataItems.map((dataItem: DataItem) => ({
      title:
        hasVolumetricData && dataItem.id === DataItemType.audience
          ? `${dataItem.volumetricDisplayName}/${dataItem.displayName}`
          : dataItem.displayName,
      value: dataItem.id,
    }));
  }

  private isInvalidNoneNegativeNumber(value: any): boolean {
    return value < 0 || value === null || isNaN(value);
  }

  public onApplyButtonClick(): void {
    this.dialogRef.close(this.chartFilters);
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  private updateSortColumns(targetMode: ChartTargetMode): void {
    this.sortColumns = this.targets.map((target: Target) => ({
      id: target.id,
      title: this.targetTitlePipe.transform(target, target.activeTitleMode),
    }));

    if (this.chartSettingsMode !== ChartSettingsMode.global) {
      switch (targetMode) {
        case ChartTargetMode.surveysGroup:
          const surveyCode = this.chartData.surveyCodes[0];
          this.sortColumns = this.chartData.insightIds.map(
            (id: string, index: number) => ({
              id: `${id}#${surveyCode}`,
              title: this.chartData.targetTitles[index],
            })
          );
          break;
        case ChartTargetMode.insightsGroup:
          const targetId = this.chartData.targetIds[0];
          this.sortColumns = this.chartData.surveyCodes.map(
            // tslint:disable-next-line:no-shadowed-variable
            (surveyCode: string, index: number) => ({
              id: `${targetId}#${surveyCode}`,
              title: this.chartData.targetTitles[index],
            })
          );
          break;
        default:
          break;
      }
    }
  }
}
