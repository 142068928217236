import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AUDIENCE_EFFECTS_VARIABLE_TYPE_MAP,
  AudienceEffectsVariableTableRowData,
  ChaidAnalysisRequestBody,
  ChaidAnalysisResponseBody,
  CRITERIA_DATA_ITEM_MAP,
  CriteriaAction,
  CriteriaDataItem,
  CriteriaItem,
  CriteriaPreferencesRequestBody,
  CriteriaPreferencesResponseBody,
  CrossTabTableData,
  GainTableRowItem,
  Survey,
  Target,
  VariableType,
  AudienceEffectsVariable,
  SelectedValuable,
  AudienceEffectsVariableSeriesItem,
  TargetColumnDataItemMap,
  GainTableItem,
  DisplayType,
} from '../models';
import { TargetTitlePipe, TruncatePipe } from '../pipes';
import { compare } from '../utils/sortHelper';
import { cloneDeep } from 'lodash';
import { AudienceEffectsApiService } from './audience-effects-api.service';

@Injectable({
  providedIn: 'root',
})
export class AudienceEffectsService {
  constructor(
    private apiService: AudienceEffectsApiService,
    private targetTitlePipe: TargetTitlePipe,
    private truncatePipe: TruncatePipe
  ) {}

  public getRecommendedVariables(
    body: CriteriaPreferencesRequestBody
  ): Observable<CriteriaPreferencesResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.audienceEffects.url,
      environment.api.audienceEffects.endPoint.criteriapreferences,
      { body }
    );
  }

  public getChaidAnalysis(
    body: ChaidAnalysisRequestBody
  ): Observable<ChaidAnalysisResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.audienceEffects.url,
      environment.api.audienceEffects.endPoint.chaidanalysis,
      { body }
    );
  }

  public formatTargetVariables(
    target: Target,
    activeTitleMode: DisplayType,
    titleLevels: number[]
  ): {
    targets: string[];
    targetTitles: string[];
  } {
    return {
      targets: [target.coding],
      targetTitles: [
        this.targetTitlePipe.transform(target, activeTitleMode, titleLevels),
      ],
    };
  }

  public formatRowVariables(
    targets: Target[],
    activeTitleMode: DisplayType,
    titleLevels: number[]
  ): {
    variables: string[];
    variableTitles: string[];
  } {
    return {
      variables: targets.map((target) => target.coding),
      variableTitles: targets.map((target) =>
        this.targetTitlePipe.transform(target, activeTitleMode, titleLevels)
      ),
    };
  }

  public formatAllVariables(
    criteriaPreferences: CriteriaPreferencesResponseBody
  ): AudienceEffectsVariable[] {
    return [
      ...criteriaPreferences.paretoLayers
        .reduce(
          (acc, current) => [
            ...acc,
            ...current.selected_code.map((_, index) => ({
              variable: current.selected_code[index],
              title: current.selected_var[index],
              index: current.pareto_optimal[index],
              targets: current.pareto_plot[index],
              type: VariableType.recommended,
            })),
          ],
          []
        )
        .sort((a, b) => compare(a.index, b.index, false)),
      ...criteriaPreferences.nonLayered
        .reduce(
          (acc, current) => [
            ...acc,
            ...current.selected_code.map((_, index) => ({
              variable: current.selected_code[index],
              title: current.selected_var[index],
              index: current.pareto_optimal[index],
              targets: current.pareto_plot[index],
              type: VariableType.variables,
            })),
          ],
          []
        )
        .sort((a, b) => compare(a.index, b.index, false)),
    ];
  }

  public formatTableDataToSelectedValuables(
    data: AudienceEffectsVariableTableRowData[]
  ): SelectedValuable[] {
    return data.reduce((acc, current) => {
      if (current.recommended) {
        acc.push({
          variable: current.coding,
          title: current.title,
        });
      }
      return acc;
    }, []);
  }

  public formatSelectedVariablesToTableData(
    variables: AudienceEffectsVariable[],
    recommendedMap?: Record<
      string,
      { recommended: boolean; type: VariableType }
    >
  ): AudienceEffectsVariableTableRowData[] {
    return variables.map((item, index) => ({
      coding: item.variable,
      title: item.title,
      shortTitle: this.truncatePipe.transform(item.title),
      y: item.targets,
      x: item.index,
      recommended: recommendedMap
        ? recommendedMap[item.variable].recommended
        : item.type === VariableType.recommended ||
          item.type === VariableType.useSelected,
      originalType: item.type,
      type: recommendedMap ? recommendedMap[item.variable].type : item.type,
      rowNumber: index + 1,
    }));
  }

  public formatChartSeries(
    data: AudienceEffectsVariableTableRowData[]
  ): AudienceEffectsVariableSeriesItem[] {
    const seriesArray = data.reduce(
      (acc, row) => ({
        ...acc,
        [row.type]: acc[row.type].concat(row),
      }),
      {
        [VariableType.variables]: [],
        [VariableType.recommended]: [],
        [VariableType.useSelected]: [],
      }
    );

    return Object.keys(seriesArray).map((key) => {
      const chartItem = AUDIENCE_EFFECTS_VARIABLE_TYPE_MAP[key];
      return {
        type: 'bubble',
        name: chartItem.displayName,
        color: chartItem.color,
        data: seriesArray[key],
      };
    });
  }

  public getTargetMinMaxDataMap(
    survey: Survey,
    crosstabData: CrossTabTableData[]
  ): TargetColumnDataItemMap {
    const firstTargetIndex = this.findFirstTargetIndex(crosstabData, survey);
    return Object.keys(CRITERIA_DATA_ITEM_MAP).reduce((acc, key) => {
      const dataItemData = crosstabData.map((data) =>
        parseFloat(
          data.data[firstTargetIndex][
            CRITERIA_DATA_ITEM_MAP[key].cellKey
          ]?.toFixed(CRITERIA_DATA_ITEM_MAP[key].decimalPoints)
        )
      );
      return {
        ...acc,
        [key]: {
          min: Math.min(...dataItemData),
          max: Math.max(...dataItemData),
        },
      };
    }, {} as TargetColumnDataItemMap);
  }

  public formatDefaultCriteriaList(
    column: Target,
    targetMinMaxDataMap: TargetColumnDataItemMap,
    activeTitleMode: DisplayType,
    titleLevels: number[]
  ): CriteriaItem[] {
    const targetTitle = this.formatTargetVariables(
      column,
      activeTitleMode,
      titleLevels
    ).targetTitles[0];
    return [
      this.formatCriteriaItem({
        id: 1,
        ...targetMinMaxDataMap[CriteriaDataItem.audience],
        targetTitle,
        dataItemId: CriteriaDataItem.audience,
        actionId: CriteriaAction.min,
      }),
      this.formatCriteriaItem({
        id: 2,
        ...targetMinMaxDataMap[CriteriaDataItem.index],
        targetTitle,
        dataItemId: CriteriaDataItem.index,
        actionId: CriteriaAction.max,
      }),
    ];
  }

  public calculateGainData(
    chaidAnalysis: ChaidAnalysisResponseBody
  ): GainTableRowItem[] {
    const tableData = cloneDeep(chaidAnalysis.gainsTable);
    const totalMarketAcc = Number(chaidAnalysis.totalSample);
    const totalPopAcc = Number(chaidAnalysis.totalPopulation);
    const denominator = totalMarketAcc / totalPopAcc;

    return tableData
      .map((node: GainTableItem, index) => ({
        longTitle: this.formLongTitle(node.segment),
        shortTitle: this.formShortTitle(node.segment),
        population: node.pop,
        populationPercentage: node.total_pop_perc,
        targets: node.sample,
        targetsPercentage: node.total_sample_perc,
        index: this.getAccumIndex(node, denominator),
        fullCode: node.full_code,
        rowNumber: index + 1,
      }))
      .sort((a, b) => b.population - a.population);
  }

  private getAccumIndex(node: GainTableItem, denominator: number): number {
    const numerator = node.sample / node.pop;
    return Number(((numerator / denominator) * 100).toFixed(1));
  }

  private formLongTitle(segments: string[]): string {
    return segments.join('\nAND ');
  }

  private formShortTitle(segments: string[]): string {
    return segments.map((title) => `${title.split('~')[0]})`).join('\nAND ');
  }

  private findFirstTargetIndex(
    crosstabData: CrossTabTableData[],
    survey: Survey
  ): number {
    return crosstabData[0].data
      .map((cell) => `${cell.type}_${cell.surveyCode}`)
      .findIndex(
        (cellSurveyCode) => cellSurveyCode === `target_${survey.code}`
      );
  }

  private formatCriteriaItem({
    id,
    min,
    max,
    targetTitle,
    dataItemId,
    actionId,
  }): CriteriaItem {
    return {
      id,
      upper_cutoff: max,
      lower_cutoff: min,
      deviation: 0,
      variables: targetTitle,
      data_item_id: dataItemId,
      action_id: actionId,
      min_observed: min,
      max_observed: max,
    };
  }
}
