import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DataItem,
  DataItemId,
  DataItemType,
  DATA_ITEMS_MAP,
  HighlightColors,
  ReportHighlightType,
  ReportMode,
} from 'src/app/models';
import { generateGradientColors } from 'src/app/utils/colorHelper';
import { ColorPickerService } from '../../services/color-picker.service';
import { DataItemsService } from '../../services/data-items.service';
import { ReportPreferencesService } from '../../services/report-preferences.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-highlight-values-dialog',
  templateUrl: './highlight-values-dialog.component.html',
  styleUrls: ['./highlight-values-dialog.component.scss'],
})
export class HighlightValuesDialogComponent implements OnInit {
  public dataItems: DataItem[];

  public chosenDataItemId: DataItemId;

  public chosenColor: string;

  public type: ReportHighlightType;

  public gradientColors: string[] = [];
  public dataItemType: typeof DataItemType = DataItemType;
  public dataItemsMap = DATA_ITEMS_MAP;
  public readonly highlightTypes = [
    {
      value: ReportHighlightType.table,
      title: 'Table',
    },
    {
      value: ReportHighlightType.rows,
      title: 'Rows',
    },
    {
      value: ReportHighlightType.columns,
      title: 'Columns',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<HighlightValuesDialogComponent>,
    private colorPicker: ColorPickerService,
    private dataItemsService: DataItemsService,
    private reportPreferencesService: ReportPreferencesService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.chosenColor = data.chosenColor;
    this.gradientColors = this.getColorArray();
    this.type = data.type;
    this.reportPreferencesService.reportMode$
      .pipe(first())
      .subscribe((mode: ReportMode) => {
        this.dataItems = this.dataItemsService.getActiveDataItems(mode, false);
      });
    this.chosenDataItemId = data.dataItemId || this.dataItems[0].id;
  }

  ngOnInit(): void {}

  public onApplyClick(): void {
    this.dialogRef.close({
      chosenColor: this.chosenColor,
      dataItemId: this.chosenDataItemId,
      colors: this.gradientColors,
      type: this.type,
      status: 'highlight',
    });
  }
  public onClose(): void {
    this.dialogRef.close(null);
  }

  public colorPickerDialog(): void {
    this.colorPicker
      .chartColor({
        target: undefined,
        colors: HighlightColors,
      })
      .afterClosed()
      .subscribe((color: string) => {
        this.chosenColor = color ? color : this.chosenColor;
        this.getColorArray();
      });
  }

  private getColorArray() {
    this.gradientColors = generateGradientColors(
      this.chosenColor,
      '#ffffff',
      5,
      0.6
    ).reverse();
    return this.gradientColors;
  }
}
