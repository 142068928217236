import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService } from '../services';
import {
  SurveyCodeEditorDialogComponent,
  SurveyCodeEditorDialogDataModel,
} from '../dialogs';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class SurveyCodeEditorAction extends ActionDefinition<void> {
  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService
  ) {
    super();
  }

  public invoke(): void {
    this.dialog
      .open(SurveyCodeEditorDialogComponent, {
        data: {
          surveys: this.documentService.surveys,
        },
        closeOnNavigation: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: SurveyCodeEditorDialogDataModel) => {
        this.documentService.updateSurveyCodes(result.surveys);
      });
  }
}
