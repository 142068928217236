<div class="single-table-wrapper">
  <div
    class="table-title"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <b data-qa="separated-rank-survey-title"
        >[{{ surveyCodeMap[targetData.surveyCode] }}]
        {{
          targetData.columnTarget || targetData
            | targetTitle
              : targetData.columnTarget?.activeTitleMode
              : targetData.columnTarget?.titleLevels
            | truncate: 80
        }}</b
      >
      <mat-chip-list class="separated-rank-filters">
        <mat-chip
          class="filter-chip"
          *ngIf="columnHeaderFilter"
          [disableRipple]="false"
          [matTooltip]="columnHeaderFilter.filterConditions"
          (click)="onColumnHeaderFilterClick()"
        >
          {{ columnHeaderFilter.filterConditions | truncate: 60 }}
          <div
            (click)="onColumnHeaderFilterRemove()"
            class="close-btn"
          >
            x
          </div>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
    >
      <mat-chip-list
        *ngIf="columnHeaderFilter?.applyGlobally"
        class="separated-rank-filters"
      >
        <mat-chip
          class="filter-chip filter-chip-global"
          (click)="removeGlobalFilters()"
        >
          Remove all global filters
          <div class="close-btn">x</div>
        </mat-chip>
      </mat-chip-list>
      <button
        data-tracking="separated-report-filter"
        mat-mini-fab
        color="primary"
        (click)="openFilter()"
        matTooltip="Separated rank filter"
        class="separated-rank__filter-btn"
      >
        <mat-icon> filter_list </mat-icon>
      </button>
    </div>
  </div>

  <div class="table-wrapper">
    <scroll-top-top
      (scrollToTopClick)="onScrollToTopClicked()"
      [showScrollToTopBtn]="showScrollToTopBtn"
      dragBoundary=".table-wrapper"
    ></scroll-top-top>
    <div
      *ngIf="dataSource.data.length > 20"
      class="table-spinner-container-overlay"
    >
      <ngx-skeleton-loader
        [count]="7"
        appearance="line"
        [theme]="{
          height: '40px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div
      class="table-header-wrapper"
      [style.padding-right]="scrollbarWidth + 'px'"
      #tableHeader
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="non-standard-table separated-rank-table"
        (matSortChange)="onSortChange($event)"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          matColumnDef="{{ column }}"
          [sticky]="column === 'title'"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-header-cell"
            [style.width.px]="dataItemWidthMap[dataItemMap[column]?.widthType]"
            [class]="column === 'title' ? 'title-header' : 'column-header'"
          >
            <div class="table-header-cell-content">
              <div mat-sort-header>
                <span
                  matTooltip="Sort column"
                  matTooltipPosition="above"
                >
                  <ng-container *ngIf="hasVolumetricCoding">
                    {{
                      dataItemMap[column]?.id === dataItemType.audience
                        ? dataItemMap[column]?.volumetricDisplayName
                        : dataItemMap[column]?.displayName
                    }}
                  </ng-container>
                  <ng-container *ngIf="!hasVolumetricCoding">
                    {{ dataItemMap[column]?.displayName }}
                    {{
                      dataItemMap[column]?.id === dataItemType.audience
                        ? (reportUnits | formatReportUnits)
                        : ""
                    }}
                  </ng-container>
                </span>

                <mat-icon
                  *ngIf="columnFiltersMap.has(dataItemMap[column]?.id)"
                  class="column-title-filter-icon"
                  >filter_alt</mat-icon
                >
              </div>

              <mat-icon
                [matMenuTriggerFor]="sortMenu"
                class="column-sort-option-icon"
                matTooltip="Global sort options"
                *ngIf="
                  column !== 'title' &&
                  !!sortSettings &&
                  sortSettings.columnId !== '' &&
                  dataItemMap[column]?.id === sortSettings.dataItem
                "
                >more_vert</mat-icon
              >
              <mat-menu
                #sortMenu="matMenu"
                [hasBackdrop]="false"
                class="action-menu"
              >
                <button
                  mat-menu-item
                  (click)="onApplySortGlobally()"
                  class="context-menu-item"
                >
                  <span>Apply sort globally</span>
                </button>
                <mat-divider></mat-divider>
                <button
                  mat-menu-item
                  (click)="onResetAllSort()"
                  class="context-menu-item"
                >
                  <span>Reset all sort</span>
                </button>
              </mat-menu>
            </div>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let totalColumn of displayedTotals"
          matColumnDef="{{ totalColumn }}"
          [sticky]="totalColumn.split('&')[0] === 'title'"
        >
          <ng-container *ngIf="totalColumn.split('&')[0] === 'title'">
            <th
              mat-header-cell
              *matHeaderCellDef
              [style.width.px]="
                dataItemWidthMap[
                  dataItemMap[totalColumn.split('&')[0]]?.widthType
                ]
              "
              class="totals-row-header totals-row-header--totals"
            >
              {{ singleTableData[0][totalColumn.split("&")[0].split("#")[0]] }}
            </th>
          </ng-container>

          <ng-container *ngIf="totalColumn.split('&')[0] !== 'title'">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="totals-row-header"
              [style.width.px]="
                dataItemWidthMap[
                  dataItemMap[totalColumn.split('&')[0]]?.widthType
                ]
              "
            >
              {{
                singleTableData[0][
                  totalColumn.split("&")[0].split("#")[0]
                ]?.toFixed(
                  decimalPointMap[
                    dataItemMap[totalColumn.split("&")[0]]?.displayName
                  ]?.decimalPoints
                )
                  | number
                    : decimalPointMap[
                        dataItemMap[totalColumn.split("&")[0]]?.displayName
                      ]?.digitsInfo
              }}
            </th>
          </ng-container>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedTotals"
        ></tr>
      </table>

      <div
        class="table-header-scroll-padding"
        [style.width]="scrollbarWidth + 'px'"
      ></div>
    </div>
    <cdk-virtual-scroll-viewport
      *ngIf="isViewActive"
      tvsItemSize="50"
      bufferMultiplier="4"
      headerHeight="-60"
      class="virtual-scroll-viewport"
      #virtualScroll
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="non-standard-table separated-rank-table"
        (matSortChange)="onSortChange($event)"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          matColumnDef="{{ column }}"
          [sticky]="column === 'title'"
        >
          <ng-container *ngIf="column === 'title'">
            <td
              mat-cell
              [hidden]="element.filteredOutCell"
              *matCellDef="let element"
              class="title-cell"
              title="{{
                element.rowTarget || element
                  | targetTitle
                    : element.rowTarget?.activeTitleMode
                    : element.rowTarget?.titleLevels
              }}"
            >
              <span>
                {{
                  element.rowTarget || element
                    | targetTitle
                      : element.rowTarget?.activeTitleMode
                      : element.rowTarget?.titleLevels
                    | truncate: 80
                }}
              </span>
            </td>
          </ng-container>

          <ng-container *ngIf="column !== 'title'">
            <td
              [hidden]="element.filteredOutCell"
              mat-cell
              *matCellDef="let element"
              [style.width.px]="
                dataItemWidthMap[dataItemMap[column]?.widthType]
              "
              [ngStyle]="
                cellStyleStatus !== 'none' &&
                highlightValues.dataItemId === dataItemMap[column]?.id && {
                  'background-color': cellColors[iCol + '_' + element.rowIndex]
                }
              "
              [ngClass]="{
                'z-score-green':
                  zScoreHighlight &&
                  !element.isTotalsColumn &&
                  dataItemMap[column]?.id === dataItemType.filterZScore &&
                  element[column.split('#')[0]]?.toFixed(
                    decimalPointMap[dataItemMap[column]?.displayName]
                      ?.decimalPoints
                  ) > zScoreHighlightProb,
                'z-score-red':
                  zScoreHighlight &&
                  !element.isTotalsColumn &&
                  dataItemMap[column]?.id === dataItemType.filterZScore &&
                  element[column.split('#')[0]]?.toFixed(
                    decimalPointMap[dataItemMap[column]?.displayName]
                      ?.decimalPoints
                  ) < zScoreHighlightProb
              }"
              [class.is-active-sort-data-item]="
                !!sortSettings &&
                sortSettings.columnId !== '' &&
                dataItemMap[column]?.id === sortSettings.dataItem
              "
              [class.is-highlighted-data-item]="
                !element.isTotalsColumn &&
                cellStyleStatus === 'highlight' &&
                highlightValues.dataItemId === dataItemMap[column]?.id
              "
            >
              <ng-container
                *ngIf="
                  !element.metadata?.isVolumetricCoding ||
                  (element.metadata?.isVolumetricCoding &&
                    volumetricDataItemIds.includes(dataItemMap[column]?.id))
                "
              >
                <span
                  *ngIf="
                    stabilityFlagStatus &&
                    dataItemMap[column]?.id === dataItemType.audience
                  "
                >
                  <span
                    *ngIf="element.sample <= stabilityFlagStatus.firstLevel"
                  >
                    *
                  </span>
                  <span
                    *ngIf="element.sample <= stabilityFlagStatus.secondLevel"
                  >
                    *
                  </span>
                </span>

                <span>
                  {{
                    element[column.split("#")[0]]?.toFixed(
                      element.metadata?.isVolumetricCoding &&
                        dataItemMap[column]?.id === dataItemType.audience
                        ? volumetricDecimalPoints
                        : decimalPointMap[dataItemMap[column]?.displayName]
                            ?.decimalPoints
                    )
                      | number
                        : (element.metadata?.isVolumetricCoding &&
                          dataItemMap[column]?.id === dataItemType.audience
                            ? volumetricDigitsInfo
                            : decimalPointMap[dataItemMap[column]?.displayName]
                                ?.digitsInfo)
                  }}
                </span>
              </ng-container>

              <ng-container
                *ngIf="
                  element.metadata?.isVolumetricCoding &&
                  !volumetricDataItemIds.includes(dataItemMap[column]?.id)
                "
              >
                <span>-</span>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
