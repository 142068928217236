import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { UserAction } from '@telmar-global/tup-auth';
import { environment } from 'src/environments/environment';
import { version } from '../../../../package.json';
import { ActivatedRoute } from '@angular/router';
import { SideNavRoute } from '@telmar-global/tup-auth/lib/tup-side-nav/tup-side-nav.component';
import { APPLICATION_NAME, SurveyTimeDocument } from 'src/app/models';
import { DocumentService } from 'src/app/services';
import { Survey } from 'src/app/models';
import { takeUntil } from 'rxjs/operators';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { Subject, combineLatest } from 'rxjs';
import {
  TupDocument,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  public selectedSurvey: Survey;
  public sidebarOpened = false;
  public isLoaded = false;
  public docRoutesConfig: SideNavRoute[];
  public dashboardRouteConfig: SideNavRoute;
  public routerUrl: string;
  version: string = version;
  public helpLink: string;
  private isQuickReport: boolean;

  constructor(
    private analyticsService: TupAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    @Inject(Window) private window: Window
  ) {
    this.buildNavConfig();
    this.helpLink = environment.helpLink;
  }

  ngOnInit() {
    this.listenToActiveSurveyChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onLogoClick() {
    this.goToPlatformHomepage();
  }

  onToggleSidePanel(value: boolean) {
    this.sidebarOpened = value;
  }

  onUserAction(event: UserAction) {
    if (
      event === UserAction.changePassword ||
      event === UserAction.changePasswordFailed
    ) {
      this.analyticsService.e('user engagement', event);
    }
    this.removeSearchTextOnLogOut(event);
  }

  removeSearchTextOnLogOut(event: UserAction) {
    event === UserAction.logout &&
      sessionStorage.removeItem('previousSearches');
  }

  private goToPlatformHomepage(): void {
    this.analyticsService.e('Platform', 'platform');
    this.window.open(environment.appOptions.platformHomepage, '_self');
  }

  private buildNavConfig(): void {
    this.dashboardRouteConfig = {
      url: '/dashboard',
      attributeData: 'dashboard-btn',
      tooltip: 'Dashboard',
      matIcon: 'bar_chart',
      label: `My ${APPLICATION_NAME} dashboard`,
    };

    const param = this.activatedRoute.snapshot.params.documentId;
    const data = {
      url: `/doc/${param}/data`,
      queryParams: {
        tab: 0,
      },
      attributeData: `data-btn`,
      label: 'Data',
      tooltip: 'Data',
      matIcon: `table_chart`,
      children: [
        {
          url: `/doc/${param}/data`,
          label: 'Report',
          tooltip: 'Report',
          attributeData: 'report-btn',
          queryParams: { tab: 0 },
        },
        {
          url: `/doc/${param}/data`,
          label: 'Coding grid',
          tooltip: 'Coding grid',
          attributeData: 'coding-grid-btn',
          queryParams: { tab: 1 },
        },
      ],
    };
    const app = {
      url: `/doc/${param}/fa`,
      attributeData: `statistics-btn`,
      tooltip: 'Statistics',
      matIcon: `timeline`,
      label: 'Statistics',
      children: [
        {
          url: `/doc/${param}/fa`,
          attributeData: 'factor-analysis-btn',
          tooltip: 'Factor Analysis',
          label: 'Factor Analysis',
          permissions: ['Surveytime.STATS_FactorAnalysis'],
        },
        {
          url: `/doc/${param}/ca`,
          attributeData: 'correspondence-btn',
          tooltip: 'Correspondence',
          label: 'Correspondence',
          permissions: ['Surveytime.STATS_Correspondence'],
        },
        {
          url: `/doc/${param}/cluster`,
          attributeData: 'cluster-btn',
          tooltip: 'Cluster',
          label: 'Cluster',
          permissions: ['Surveytime.STATS_Clustering'],
        },
        {
          url: `/doc/${param}/ae`,
          attributeData: 'audience-effects-btn',
          tooltip: 'Audience Effects',
          label: 'Audience Effects',
          permissions: ['Surveytime.STATS_AudienceEffects'],
        },
      ],
    };
    this.docRoutesConfig = [
      ...(this.isQuickReport ? [] : [data]),
      {
        url: `/doc/${param}/chart`,
        attributeData: `visualisation-btn`,
        tooltip: 'Visualisation',
        matIcon: `pie_chart`,
        label: 'Visualisation',
        children: [
          {
            url: `/doc/${param}/chart`,
            attributeData: 'chart-btn',
            tooltip: 'Chart',
            label: 'Chart',
          },
          ...(this.isQuickReport
            ? []
            : [
                {
                  url: `/doc/${param}/pmaps`,
                  attributeData: 'pmaps-btn',
                  tooltip: 'PMaps',
                  label: 'PMaps',
                },
              ]),
          ...(this.selectedSurvey && this.selectedSurvey?.meta?.['is-geografix']
            ? [
                {
                  url: `/doc/${param}/geografix`,
                  attributeData: 'geografix-btn',
                  tooltip: 'Geografix',
                  label: 'Geografix',
                },
              ]
            : []),
        ],
      },
      ...(this.isQuickReport ? [] : [app]),
    ];
  }

  private listenToActiveSurveyChanges(): void {
    combineLatest([
      this.documentService.selectedSurvey$.pipe(
        takeUntil(this.unsubscribe),
        isNotNullOrUndefined()
      ),
      this.documentService.currentDoc.pipe(
        takeUntil(this.unsubscribe),
        isNotNullOrUndefined()
      ),
    ]).subscribe(
      ([survey, doc]: [Survey, TupDocument<SurveyTimeDocument>]): void => {
        this.selectedSurvey = survey;
        this.isQuickReport =
          doc.metadata.type.id ===
          TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE;

        this.buildNavConfig();
      }
    );
  }
}
