import { Injectable } from '@angular/core';
import { TargetTitlePipe } from '../pipes';
import {
  CHART_COLORS,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemId,
  DataItemType,
  DisplayType,
  Survey,
  SurveyProvider,
} from '../models';
import { ColumnTargetId, MappingOptions } from '../dialogs';
import { ChartMappingOptionsData } from '../models/p-maps.model';
import { isNotNullOrUndefined } from '../utils/validationHelper';
import { DecimalPointService } from './decimal-point.service';

@Injectable({
  providedIn: 'root',
})
export class PMapsService {
  constructor(
    private targetTitlePipe: TargetTitlePipe,
    private decimalPointService: DecimalPointService
  ) {}

  public formatCrosstabDataForSingleSurvey(
    surveys: Survey[],
    survey: Survey,
    crosstabData: CrossTabTableData[]
  ): CrossTabTableData[] {
    return (
      surveys.length > 1
        ? crosstabData.map((crossTabData: CrossTabTableData) => ({
            ...crossTabData,
            data: crossTabData.data.filter(
              (column: CrossTabTableDataCell) =>
                column.surveyCode === survey.code
            ),
          }))
        : crosstabData
    ).filter((row: CrossTabTableData) => !row.isTotalRow);
  }

  public formatChartMappingOptionsData(
    options: MappingOptions,
    surveyData: CrossTabTableData[],
    survey: Survey,
    titleMode: DisplayType,
    titleLevels: number[]
  ): ChartMappingOptionsData {
    const weightDataItem = this.getWeightDataItem(survey);
    return options.graphType === 'dataItem'
      ? this.graphADataItemByTwoColumns(
          options,
          surveyData,
          weightDataItem,
          titleMode,
          titleLevels
        )
      : this.graphAColumnByTwoDataItems(
          options,
          surveyData,
          weightDataItem,
          titleMode,
          titleLevels
        );
  }

  public getWeightDataItem(survey: Survey): DataItem {
    const weightDataItemType =
      survey.provider !== SurveyProvider.youGov
        ? DataItemType.audience
        : DataItemType.filterMarketSize;
    return DATA_ITEMS_MAP[weightDataItemType];
  }

  private graphADataItemByTwoColumns(
    options: MappingOptions,
    surveyData: CrossTabTableData[],
    weightDataItem: DataItem,
    titleMode: DisplayType,
    titleLevels: number[]
  ): ChartMappingOptionsData {
    const xAxisTargetId = options.xAxis as ColumnTargetId;
    const yAxisTargetId = options.yAxis as ColumnTargetId;
    const xAxisTargetIndex = this.findColumnTargetIndex(
      surveyData,
      xAxisTargetId
    );
    const yAxisTargetIndex = this.findColumnTargetIndex(
      surveyData,
      yAxisTargetId
    );
    const xAxisLabel = this.targetTitlePipe.transform(
      surveyData[0]?.data[yAxisTargetIndex].columnTarget,
      titleMode,
      titleLevels
    );
    const yAxisLabel = this.targetTitlePipe.transform(
      surveyData[0]?.data[xAxisTargetIndex].columnTarget,
      titleMode,
      titleLevels
    );
    const dataItem = DATA_ITEMS_MAP[options.variableToGraph as DataItemId];
    const dataItemCellKey = dataItem.cellKey;
    const dataItemDecimalPoints =
      this.decimalPointService.getDecimalPoints(dataItem);
    const weightDecimalPoints =
      this.decimalPointService.getDecimalPoints(weightDataItem);
    const seriesData = surveyData.map((row: CrossTabTableData, index) => ({
      id: index + 1,
      x: isNotNullOrUndefined(row.data[xAxisTargetIndex][dataItemCellKey])
        ? parseFloat(
            row.data[xAxisTargetIndex][dataItemCellKey].toFixed(
              dataItemDecimalPoints
            )
          )
        : undefined,
      y: isNotNullOrUndefined(row.data[yAxisTargetIndex][dataItemCellKey])
        ? parseFloat(
            row.data[yAxisTargetIndex][dataItemCellKey].toFixed(
              dataItemDecimalPoints
            )
          )
        : undefined,
      z: isNotNullOrUndefined(row.data[0][weightDataItem.cellKey])
        ? parseFloat(
            row.data[0][weightDataItem.cellKey]?.toFixed(weightDecimalPoints)
          )
        : undefined,
      target: row.data[yAxisTargetIndex].rowTarget,
      title: this.targetTitlePipe.transform(
        row.data[yAxisTargetIndex].rowTarget,
        titleMode,
        titleLevels
      ),
      color:
        CHART_COLORS[
          index >= CHART_COLORS.length ? index % CHART_COLORS.length : index
        ],
      visible: true,
    }));
    return {
      xAxisLabel,
      yAxisLabel,
      data: seriesData,
    };
  }

  private graphAColumnByTwoDataItems(
    options: MappingOptions,
    surveyData: CrossTabTableData[],
    weightDataItem: DataItem,
    titleMode: DisplayType,
    titleLevels: number[]
  ): ChartMappingOptionsData {
    const columnTargetId = options.variableToGraph as ColumnTargetId;
    const columnTargetIndex = this.findColumnTargetIndex(
      surveyData,
      columnTargetId
    );
    const xAxisDataItem = DATA_ITEMS_MAP[options.xAxis as DataItemId];
    const yAxisDataItem = DATA_ITEMS_MAP[options.yAxis as DataItemId];
    const xAxisLabel = yAxisDataItem.displayName;
    const yAxisLabel = xAxisDataItem.displayName;
    const xAxisDecimalPoints =
      this.decimalPointService.getDecimalPoints(xAxisDataItem);
    const yAxisDecimalPoints =
      this.decimalPointService.getDecimalPoints(yAxisDataItem);
    const weightDecimalPoints =
      this.decimalPointService.getDecimalPoints(weightDataItem);
    const seriesData = surveyData
      .filter((row: CrossTabTableData) => !row.isTotalRow)
      .map((row: CrossTabTableData, index) => ({
        id: index + 1,
        x: isNotNullOrUndefined(
          row.data[columnTargetIndex][xAxisDataItem.cellKey]
        )
          ? parseFloat(
              row.data[columnTargetIndex][xAxisDataItem.cellKey].toFixed(
                xAxisDecimalPoints
              )
            )
          : undefined,
        y: isNotNullOrUndefined(
          row.data[columnTargetIndex][yAxisDataItem.cellKey]
        )
          ? parseFloat(
              row.data[columnTargetIndex][yAxisDataItem.cellKey].toFixed(
                yAxisDecimalPoints
              )
            )
          : undefined,
        z: isNotNullOrUndefined(row.data[0][weightDataItem.cellKey])
          ? parseFloat(
              row.data[0][weightDataItem.cellKey].toFixed(weightDecimalPoints)
            )
          : undefined,
        target: row.data[columnTargetIndex].rowTarget,
        title: this.targetTitlePipe.transform(
          row.data[columnTargetIndex].rowTarget,
          titleMode,
          titleLevels
        ),
        color:
          CHART_COLORS[
            index >= CHART_COLORS.length ? index % CHART_COLORS.length : index
          ],
        visible: true,
      }));
    return {
      xAxisLabel,
      yAxisLabel,
      data: seriesData,
    };
  }

  private findColumnTargetIndex(
    surveyData: CrossTabTableData[],
    targetId: ColumnTargetId
  ): number {
    return targetId === 'totals'
      ? 0
      : surveyData[0].data.findIndex(
          (cell: CrossTabTableDataCell) =>
            cell.columnTarget && cell.columnTarget.id === targetId
        );
  }
}
