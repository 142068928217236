<tup-mat-title-bar
  title="Correspondence filter"
  class="dialog-title"
>
  <mat-icon
    title="Close dialog"
    class="close-icon"
    (click)="close()"
    >close
  </mat-icon>
</tup-mat-title-bar>

<mat-dialog-content>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="5px"
      class="filter-row"
    >
      <mat-label class="row-label">Visible columns</mat-label>
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field-without-label filter-value-field"
      >
        <input
          matInput
          type="number"
          min="0"
          [(ngModel)]="settings.visibleColumns"
          (change)="onVisibleColumnsOrRowsValueChange('visibleColumns')"
          (keydown)="onFilterValueInputKeydown($event)"
          [disabled]="settings.allColumns"
        />
      </mat-form-field>

      <mat-checkbox
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox row-checkbox"
        [(ngModel)]="settings.allColumns"
      >
        All columns
      </mat-checkbox>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="5px"
      class="filter-row"
    >
      <mat-label class="row-label">Visible rows</mat-label>
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field-without-label filter-value-field"
      >
        <input
          matInput
          type="number"
          min="0"
          [(ngModel)]="settings.visibleRows"
          (change)="onVisibleColumnsOrRowsValueChange('visibleRows')"
          (keydown)="onFilterValueInputKeydown($event)"
          [disabled]="settings.allRows"
        />
      </mat-form-field>

      <mat-checkbox
        tupMatCheckbox
        appearance="small"
        color="primary"
        class="tiny-checkbox row-checkbox"
        [(ngModel)]="settings.allRows"
      >
        All rows
      </mat-checkbox>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="filter-row"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
      >
        <mat-label> Sort by</mat-label>
        <mat-select [(ngModel)]="settings.sortBy">
          <mat-option
            *ngFor="let item of sortColumns"
            [value]="item.value"
          >
            {{ item.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="dialog-actions"
>
  <button
    mat-stroked-button
    color="primary"
    appearance="medium"
    tupMatButton
    (click)="close()"
  >
    Cancel
  </button>
  <div>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onApplyButtonClick()"
    >
      OK
    </button>
  </div>
</mat-dialog-actions>
