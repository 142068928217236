<h1 mat-dialog-title>Snapshot limit reached</h1>

<div mat-dialog-content>
  <p>
    The maximum number of <b>{{ data.maxLimitNumber }}</b> snapshots, has been
    reached, please delete older snapshots to continue.
  </p>
</div>

<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button
    mat-stroked-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    tupMatButton
    appearance="medium"
    matDialogClose
    (click)="confirm()"
  >
    Okay
  </button>
</mat-dialog-actions>
