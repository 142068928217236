import { chunk, cloneDeep, isArray } from 'lodash';

import { TupChartsComponent } from '@telmar-global/tup-charts';
import {
  percentToInches,
  TupChartData,
  TupChartType,
  TupPptxBuilder,
  CIRCULAR_CHART_TYPES,
  UNSUPPORTED_PPTX_CHART_TYPES,
} from '@telmar-global/tup-document-exporter';

import {
  chartsWithTransparentBackgroundFill,
  Note,
  SurveyTimeProps,
} from '../models';
import { provideColorsToCharts, rgbaToHex } from '../utils/colorHelper';
import {
  getExportProps,
  getComboChartData,
  CHART_NOTE_DESC_TEXT_PROPS,
  CHART_NOTE_TITLE_TEXT_PROPS,
  CHART_SOURCE_TEXT_PROPS,
  CHART_SOURCE_TEXT_WITH_NOTE_PROPS,
  CHART_FIRST_SLIDE_TITLE,
  CHART_FIRST_SLIDE_SUBTITLE,
  CHART_LAST_SLIDE_COPYRIGHT_TITLE,
  CHART_LAST_SLIDE_COPYRIGHT_CONTENT,
} from '../utils/export-utils';
import PptxGenJS from 'pptxgenjs';
import ImageProps = PptxGenJS.ImageProps;
import TableRow = PptxGenJS.TableRow;
import TableProps = PptxGenJS.TableProps;
import { TupUserMessageService } from '@telmar-global/tup-user-message';

/**
 * Custom Survey Time PptxBuilder
 */
export class SurveyTimePptxBuilder extends TupPptxBuilder {
  constructor(private tupUserMessageService: TupUserMessageService) {
    super();
  }
  public async init(
    documentName: string,
    props: SurveyTimeProps[]
  ): Promise<SurveyTimePptxBuilder> {
    this.setLayout();

    this.addStartSlide(documentName, 'Charts');

    let sourceCopyrightMap: Record<string, string> = {};
    for (const {
      settings,
      primaryData,
      secondaryData,
      charts,
      title,
      subtitle,
      source,
      copyright,
      chartNote,
    } of props) {
      sourceCopyrightMap = source.reduce(
        (acc, src, index) =>
          src in acc
            ? acc
            : {
                ...acc,
                [src]: copyright[index],
              },
        sourceCopyrightMap
      );
      const formattedSource = source.join('; ');
      const {
        primaryChartType,
        primaryChartData,
        secondaryChartType,
        secondaryChartData,
        chartColors,
        chartOpts,
      } = getExportProps(settings, primaryData, secondaryData);
      // Unsupported chart types (which includes combined target scatter charts, for now):
      // Displayed as a single image, or multiple images (up to 4 per slide)
      if (
        UNSUPPORTED_PPTX_CHART_TYPES.includes(primaryChartType) ||
        (primaryChartType === TupChartType.tupScatter &&
          isArray(secondaryData) &&
          secondaryData.length > 1)
      ) {
        try {
          const imageData = charts.map((chart: TupChartsComponent) => {
            if (chart.toBase64Image()) {
              return chart.toBase64Image();
            } else {
              const canvas = document.getElementById(
                chart.canvasId
              ) as HTMLCanvasElement;
              return canvas.toDataURL();
            }
          });
          const shouldResizeHeight =
            primaryChartType === TupChartType.tupPyramid ||
            primaryChartType === TupChartType.tupFunnel;
          await this.addChunkedImages(
            imageData,
            shouldResizeHeight,
            title,
            subtitle,
            formattedSource,
            chartNote
          );
        } catch (error) {
          this.tupUserMessageService.showSnackBar(
            'There is an error in one or more charts',
            'OK',
            10000
          );
        }

        // Donut and pie charts: Displayed as a single chart, or multiple charts (up to 4 per slide)
      } else if (CIRCULAR_CHART_TYPES.includes(primaryChartType)) {
        this.addChunkedCharts(
          primaryChartType,
          primaryChartData,
          chartOpts,
          title,
          subtitle,
          formattedSource,
          chartNote
        );
        // Supported core and combo chart types: Displayed as a single chart
      } else {
        this.addDefaultSlide(title, subtitle, formattedSource, chartNote);

        if (secondaryChartType) {
          this.addComboChart(
            getComboChartData(
              settings,
              primaryChartData,
              secondaryChartData,
              chartColors.map((color) => rgbaToHex(color.toLowerCase()))
            ),
            chartOpts
          );
        } else {
          if (chartsWithTransparentBackgroundFill.includes(primaryChartType)) {
            chartOpts.chartColors = chartOpts.chartColors.map((color) =>
              rgbaToHex(color.toLowerCase())
            );
          }
          this.addCoreChart(primaryChartType, primaryChartData, chartOpts);
        }
      }
    }

    const formattedCopyrightText = Object.keys(sourceCopyrightMap)
      .map((key) => `${key} - ${sourceCopyrightMap[key]}`)
      .join('\n\n');
    this.addLastSlide(formattedCopyrightText);

    return this;
  }

  private addStartSlide(title: string, subtitle: string): void {
    const builder = this.addSlide();
    builder.slide.background = { path: '../../../assets/ppt/start.jpg' };
    builder
      .addChartTitle(title, CHART_FIRST_SLIDE_TITLE)
      .addChartSubtitle(subtitle, CHART_FIRST_SLIDE_SUBTITLE);
  }

  private addLastSlide(copyrightText: string): void {
    const copyrightBuilder = this.addSlide();
    copyrightBuilder.slide.background = { path: '../../../assets/ppt/end.jpg' };
    copyrightBuilder
      .addText('Copyright', CHART_LAST_SLIDE_COPYRIGHT_TITLE)
      .addText(copyrightText, CHART_LAST_SLIDE_COPYRIGHT_CONTENT);
  }

  private addDefaultSlide(
    title: string,
    subtitle: string,
    source: string,
    chartNote?: Note
  ): SurveyTimePptxBuilder {
    const hasChartNote =
      chartNote &&
      (chartNote.title.trim() !== '' || chartNote.description.trim() !== '');
    this.addSlide()
      .addChartTitle(title)
      .addChartSubtitle(subtitle)
      .addChartSource(
        source,
        hasChartNote
          ? CHART_SOURCE_TEXT_WITH_NOTE_PROPS
          : CHART_SOURCE_TEXT_PROPS
      );

    if (hasChartNote) {
      this.addText(chartNote.title, CHART_NOTE_TITLE_TEXT_PROPS).addText(
        chartNote.description,
        CHART_NOTE_DESC_TEXT_PROPS
      );
    }

    return this;
  }

  /**
   * Should these guys be extracted into the shared library? They seem quite
   * Survey Time specific and provideColorsToCharts is already replicated in
   * multiple libraries.
   */
  private addChunkedCharts(
    type: TupChartType,
    data: TupChartData[],
    options: { [key: string]: any },
    title: string,
    subtitle: string,
    source: string,
    chartNote?: Note
  ): SurveyTimePptxBuilder {
    chunk(data, 4).forEach((chunks: TupChartData[], i: number) => {
      this.addDefaultSlide(title, subtitle, source, chartNote);

      chunks.forEach(
        // tslint:disable-next-line:no-shadowed-variable
        (chunk: TupChartData, j: number, array: TupChartData[]) => {
          // tslint:disable-next-line:variable-name
          const _options: { [key: string]: any } = cloneDeep(options);

          if (data.length > 1) {
            _options.w = percentToInches('30%', 'h');
            _options.legendPos = 'l!important';

            this.adjustPosition(_options, j, array);

            const startColor: string = !_options.chartColors[i + j].startsWith(
              '#'
            )
              ? `#${_options.chartColors[i + j]}`
              : _options.chartColors[i + j];

            _options.chartColors = provideColorsToCharts(
              startColor,
              chunk.values.length
            );
          }

          this.addCoreChart(type, [chunk], _options);
        }
      );
    });

    return this;
  }

  private async addChunkedImages(
    data: string[],
    shouldResizeHeight: boolean,
    title: string,
    subtitle: string,
    source: string,
    chartNote?: Note
  ): Promise<SurveyTimePptxBuilder> {
    for (const [, chunks] of chunk(data, 4).entries()) {
      this.addDefaultSlide(title, subtitle, source, chartNote);

      // tslint:disable-next-line:no-shadowed-variable
      for (const [j, chunk] of chunks.entries()) {
        let options: { [key: string]: any };

        if (data.length > 1) {
          options = {
            sizing: {
              w: percentToInches('30%', 'h'),
              h: percentToInches('50%', 'v'),
            },
          };

          this.adjustPosition(options, j, chunks);
        } else {
          if (shouldResizeHeight) {
            options = {
              sizing: {
                h: percentToInches('75%', 'v'),
              },
            };
          }
        }
        await this.addImage(chunk, options);
      }
    }

    return this;
  }

  private adjustPosition(
    options: { [key: string]: any },
    index: number,
    array: any[]
  ): void {
    // Move odd number charts to the right
    if (index % 2) {
      options.x = percentToInches('65%', 'h');
    } else {
      // Leave even number charts where they are, unless it is the first
      // (and only) *or* the last chart, in which case, move it to the middle
      if (index === array.length - 1) {
        options.x = percentToInches('35%', 'h');
      }
    }

    // Move the last two charts down to the bottom
    if (index > 1) {
      options.y = percentToInches('50%', 'v');
    }
  }

  public async exportStatsAppToPptx(
    table: { tableRows: TableRow[]; options?: TableProps } | null,
    images: string[] | ImageProps[],
    fileName: string,
    appName: string,
    copyright: string
  ): Promise<SurveyTimePptxBuilder> {
    const pptx = this.build();
    this.addStartSlide(fileName, appName);

    if (table) {
      pptx.addSlide().addTable(table.tableRows, table.options);
    }

    images.forEach((image) => {
      const slide = pptx.addSlide();
      if (typeof image === 'string') {
        slide.addImage({ data: image, x: 0, y: 0, w: '100%', h: '100%' });
      } else {
        slide.addImage(image);
      }
    });

    this.addLastSlide(copyright);
    return this;
  }
}
