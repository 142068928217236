import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { cloneDeep } from 'lodash';
import {
  CorrespondenceData,
  CorrespondenceDataRowType,
  CorrespondenceFeatureItem,
} from 'src/app/models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CtrlShiftKeyStates } from '../../directives';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

interface CorrespondenceActivePassiveItem extends CorrespondenceFeatureItem {
  active: boolean;
}

interface DataSourceState {
  source: MatTableDataSource<CorrespondenceActivePassiveItem>;
  total: number;
  activePassiveCount: number[] | null;
  shiftPressed: boolean;
  lastSelectedRowId: RowId | null;
  rowIds: RowId[];
}

interface DataTypeItem {
  dataType: DataType;
  activeRowType: CorrespondenceDataRowType;
  passiveRowType: CorrespondenceDataRowType;
}

type RowId = string;
type DataType = 'columns' | 'rows';

@Component({
  selector: 'app-correspondence-active-passive-dialog',
  templateUrl: './correspondence-active-passive-dialog.component.html',
  styleUrls: ['./correspondence-active-passive-dialog.component.scss'],
})
export class CorrespondenceActivePassiveDialogComponent
  implements OnInit, AfterContentChecked
{
  private readonly defaultActiveRows = 2;
  public displayedColumns: string[] = ['active', 'title'];

  private dataTypeItems: Record<DataType, DataTypeItem> = {
    columns: {
      dataType: 'columns',
      activeRowType: CorrespondenceDataRowType.activeColumn,
      passiveRowType: CorrespondenceDataRowType.passiveColumn,
    },
    rows: {
      dataType: 'rows',
      activeRowType: CorrespondenceDataRowType.activeRow,
      passiveRowType: CorrespondenceDataRowType.passiveRow,
    },
  };

  public dataSourceState: Record<DataType, DataSourceState> = {
    columns: this.getDefaultSourceState(),
    rows: this.getDefaultSourceState(),
  };

  constructor(
    private changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CorrespondenceActivePassiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CorrespondenceData
  ) {
    if (!data) {
      return;
    }

    this.initialiseDataSourceState();
  }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  public onApply(): void {
    this.dialogRef.close(
      Object.keys(this.dataSourceState).reduce(
        (acc, dataType) => ({
          ...acc,
          [dataType]: this.dataSourceState[dataType].source.data.reduce(
            (prev, current, currentIndex) => ({
              ...prev,
              [currentIndex]: current.active,
            }),
            {}
          ),
        }),
        {}
      )
    );
  }

  public onActiveToggle(
    event: MatCheckboxChange,
    element: CorrespondenceActivePassiveItem,
    dataType: DataType
  ): void {
    const selectedRowId = element.coding;
    const dataSourceState = this.dataSourceState[dataType];
    const dataSource = dataSourceState.source.data;
    if (dataSourceState.shiftPressed && dataSourceState.lastSelectedRowId) {
      const lastSelectedRowIndex = dataSourceState.rowIds.indexOf(
        dataSourceState.lastSelectedRowId
      );
      const selectedRowIndex = dataSourceState.rowIds.indexOf(selectedRowId);

      let lowerIndex = selectedRowIndex;
      let upperIndex = lastSelectedRowIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedRowIndex;
        upperIndex = selectedRowIndex;
      }

      for (let i = lowerIndex; i <= upperIndex; i++) {
        this.updateRowItemActiveState(event.checked, dataSource[i], dataType);
      }
    } else {
      this.updateRowItemActiveState(event.checked, element, dataType);
    }

    dataSourceState.lastSelectedRowId = selectedRowId;

    this.setActivePassiveCounts();

    if (dataSourceState.activePassiveCount[0] < this.defaultActiveRows) {
      setTimeout(() => this.retainAtLeastTwoActiveRows(dataType), 0);
    }
  }

  public onActiveHeaderToggle(event: MatCheckboxChange, dataType: DataType) {
    const active = event.checked;
    const type: CorrespondenceDataRowType = event.checked
      ? this.dataTypeItems[dataType].activeRowType
      : this.dataTypeItems[dataType].passiveRowType;
    const activeType = this.dataTypeItems[dataType].activeRowType;
    const dataSource: CorrespondenceActivePassiveItem[] =
      this.dataSourceState[dataType].source.data;
    dataSource.forEach((element, index) => {
      if (index < this.defaultActiveRows && !active) {
        element.active = true;
        element.type = activeType;
      } else {
        element.active = active;
        element.type = type;
      }
    });

    this.setActivePassiveCounts();
    this.dataSourceState[dataType].lastSelectedRowId = null;
  }

  public onCtrlShift(states: CtrlShiftKeyStates, dataType: DataType): void {
    this.dataSourceState[dataType].shiftPressed = states.shiftPressed;
  }

  private getDefaultSourceState(): DataSourceState {
    return {
      source: new TableVirtualScrollDataSource([]),
      total: 0,
      activePassiveCount: null,
      shiftPressed: false,
      lastSelectedRowId: null,
      rowIds: [],
    };
  }

  private initialiseDataSourceState(): void {
    Object.keys(this.dataTypeItems).forEach((dataType: DataType) => {
      const dataTypeItem = this.dataTypeItems[dataType];
      this.dataSourceState[dataType].total = this.data[dataType].length;
      this.dataSourceState[dataType].source.data = cloneDeep(
        this.data[dataType].map((row) => ({
          ...row,
          active: row.type === dataTypeItem.activeRowType,
        }))
      );
      this.dataSourceState[dataType].rowIds = this.dataSourceState[
        dataType
      ].source.data.map((row) => row.coding);
    });
    this.setActivePassiveCounts();
  }

  private setActivePassiveCounts() {
    this.dataSourceState.columns.activePassiveCount =
      this.calculateActivePassiveCount(
        this.dataSourceState.columns.source.data,
        CorrespondenceDataRowType.passiveColumn
      );
    this.dataSourceState.rows.activePassiveCount =
      this.calculateActivePassiveCount(
        this.dataSourceState.rows.source.data,
        CorrespondenceDataRowType.passiveRow
      );
  }

  private calculateActivePassiveCount(
    data: CorrespondenceActivePassiveItem[],
    type:
      | CorrespondenceDataRowType.passiveColumn
      | CorrespondenceDataRowType.passiveRow
  ): number[] {
    const passiveCount = data.filter((row) => row.type === type).length;
    return [data.length - passiveCount, passiveCount];
  }

  private updateRowItemActiveState(
    isActive: boolean,
    element: CorrespondenceActivePassiveItem,
    dataType: DataType
  ): void {
    element.active = isActive;
    element.type = isActive
      ? this.dataTypeItems[dataType].activeRowType
      : this.dataTypeItems[dataType].passiveRowType;
  }

  private retainAtLeastTwoActiveRows(dataType: DataType): void {
    const dataSource = this.dataSourceState[dataType].source.data;
    let missingActiveRowCount =
      this.defaultActiveRows -
      this.dataSourceState[dataType].activePassiveCount[0];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].active) {
        continue;
      }
      this.updateRowItemActiveState(true, dataSource[i], dataType);
      missingActiveRowCount--;
      if (missingActiveRowCount === 0) {
        break;
      }
    }
    this.setActivePassiveCounts();
  }
}
