<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Add trending calculations"
    class="dialog-title"
    cdkDragHandle
  >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxFlex
      fxLayout="column grid"
      fxLayoutAlign="center center"
      class="split-wrapper"
    >
      <ui-split
        unit="pixel"
        direction="horizontal"
        #split="uiSplit"
      >
        <ui-split-area [size]="640">
          <mat-tab-group
            fxFill
            mat-stretch-tabs
            mat-align-tabs="start"
            class="trending-calculation__tab"
            (selectedTabChange)="tabClick($event)"
          >
            <mat-tab label="Difference">
              <div class="trending-calculation__tab-content">
                <ng-container
                  *ngTemplateOutlet="
                    differenceTemplate;
                    context: {
                      type: 'diff'
                    }
                  "
                >
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="previewActionTemplate">
              </ng-container>
            </mat-tab>

            <mat-tab label="%Difference">
              <div class="trending-calculation__tab-content">
                <ng-container
                  *ngTemplateOutlet="
                    differenceTemplate;
                    context: {
                      type: '%diff'
                    }
                  "
                >
                </ng-container>
                <div
                  fxLayout="row"
                  fxLayoutAlign="end center"
                  class="trending-calculation__dividend"
                >
                  <div>dividend</div>
                  <mat-form-field
                    appearance="outline"
                    class="survey-selection__select select-small"
                    tupMatFormField
                  >
                    <mat-select
                      [(ngModel)]="selectedDividendSurvey"
                      (ngModelChange)="onDividendSurveyChange()"
                    >
                      <mat-option
                        [value]="survey"
                        [matTooltip]="survey.title"
                        *ngFor="let survey of surveys; let index = index"
                      >
                        {{ survey.title }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="previewActionTemplate">
              </ng-container>
            </mat-tab>

            <mat-tab label="Average">
              <ng-container *ngTemplateOutlet="surveySelectorTemplate">
              </ng-container>
              <ng-container *ngTemplateOutlet="previewActionTemplate">
              </ng-container>
            </mat-tab>

            <mat-tab label="Sum">
              <ng-container *ngTemplateOutlet="surveySelectorTemplate">
              </ng-container>
              <ng-container *ngTemplateOutlet="previewActionTemplate">
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </ui-split-area>

        <ui-split-area class="trending-calculation__preview">
          <div
            fxLayout="column"
            fxLayoutAlign="center start"
          >
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="trending-calculation__preview-control-label"
            >
              <span class="mat-tab-label">My calculation</span>
            </div>
            <div class="trending-calculation__preview-control-content">
              <div
                *ngFor="let calculation of trendingCalculationList"
                class="trending-calculation__item"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <span class="trending-calculation__item-name">{{
                  calculation.coding | trendingCoding: surveyCodeMap
                }}</span>
                <button
                  mat-icon-button
                  (click)="onDeleteCalculation(calculation)"
                >
                  <mat-icon
                    class="tiny-icon"
                    matTooltip="Delete trending calculation"
                    >delete_outline
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ui-split-area>
      </ui-split>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="ok()"
    >
      Calculate
    </button>
  </mat-dialog-actions>
</div>

<ng-template
  #differenceTemplate
  let-type="type"
>
  <div fxLayout="column">
    <span class="trending-calculation__label">Choose surveys:</span>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <mat-form-field
        appearance="outline"
        class="survey-selection__select select-small"
        tupMatFormField
      >
        <mat-select
          [(ngModel)]="selectedDiffSurveys[0]"
          (ngModelChange)="onDiffSurveysChange()"
        >
          <mat-option
            [value]="survey"
            [matTooltip]="survey.title"
            *ngFor="let survey of surveys; let index = index"
          >
            {{ survey.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <span *ngIf="type === 'diff'">-</span>
      <span *ngIf="type === '%diff'">%</span>
      <mat-form-field
        appearance="outline"
        class="survey-selection__select select-small"
        tupMatFormField
      >
        <mat-select
          [(ngModel)]="selectedDiffSurveys[1]"
          (ngModelChange)="onDiffSurveysChange()"
        >
          <mat-option
            [value]="survey"
            [matTooltip]="survey.title"
            *ngFor="let survey of surveys; let index = index"
          >
            {{ survey.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #previewActionTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="trending-calculation__preview-action"
  >
    <span class="trending-calculation__preview-content">{{
      calculationPreview | trendingCoding: surveyCodeMap
    }}</span>
    <span
      [matTooltip]="disableTooltipMessage"
      matTooltipPosition="above"
    >
      <button
        mat-flat-button
        color="primary"
        appearance="small"
        tupMatButton
        [disabled]="unableToAdd"
        (click)="onAdd()"
        data-qa="add-to-calculation-btn"
      >
        Add to calculation
      </button>
    </span>
  </div>
</ng-template>

<ng-template #surveySelectorTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
    class="trending-calculation__survey-selection"
  >
    <mat-checkbox
      *ngFor="let survey of surveys"
      tupMatCheckbox
      [checked]="selectedSurveyList.includes(survey)"
      appearance="small"
      color="primary"
      (change)="onSurveyChecked($event, survey)"
    >
      {{ survey.title }}
    </mat-checkbox>
  </div>
</ng-template>
