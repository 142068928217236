import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DEFAULT_INDEX_PERCENTAGE,
  HEATMAP_CUSTOM,
  HEATMAP_OPTIONS,
  HEATMAP_QUARTILES,
  HEATMAP_QUINTILES,
  HeatmapValues,
} from '../../models';
import { ColorPickerService } from '../../services/color-picker.service';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-highlight-values-dialog',
  templateUrl: './heatmap-dialog.component.html',
  styleUrls: ['./heatmap-dialog.component.scss'],
})
export class HeatmapDialogComponent implements OnInit {
  public readonly HEATMAP_OPTIONS = HEATMAP_OPTIONS;
  public readonly HEATMAP_QUARTILES = HEATMAP_QUARTILES;
  public readonly HEATMAP_QUINTILES = HEATMAP_QUINTILES;
  public selectedHeatmapOption: string;
  public heatmapValues: HeatmapValues;
  public readonly min = 5;
  public readonly max = 100;
  public readonly defaultIndex = DEFAULT_INDEX_PERCENTAGE;

  constructor(
    private colorPicker: ColorPickerService,
    public dialogRef: MatDialogRef<HeatmapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.selectedHeatmapOption =
      data.selectedHeatmapOption || 'heatmapQuartiles';
    this.heatmapValues = cloneDeep(data.heatmapValues);
  }

  ngOnInit(): void {}

  public onIndexPercentageInputChange(): void {
    if (this.heatmapValues.heatmapIndexPercentage === null) {
      this.heatmapValues.heatmapIndexPercentage = this.defaultIndex;
    }

    if (this.heatmapValues.heatmapIndexPercentage < this.min) {
      this.heatmapValues.heatmapIndexPercentage = this.min;
    }
  }

  public onIndexPercentageInputKeydown(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  public onIndexPercentageInputKeyup(): void {
    if (this.heatmapValues.heatmapIndexPercentage > this.max) {
      this.heatmapValues.heatmapIndexPercentage = this.max;
    }
  }

  public onApplyClick(): void {
    this.dialogRef.close({
      value: this.heatmapValues.heatmapIndexPercentage,
      selectedHeatmapOption: this.selectedHeatmapOption,
      heatmapValues: {
        heatmapIndexPercentage: this.heatmapValues.heatmapIndexPercentage,
        heatmapQuartiles: this.heatmapValues.heatmapQuartiles,
        heatmapQuintiles: this.heatmapValues.heatmapQuintiles,
        heatmap: this.heatmapValues.heatmap,
      },
    });
  }

  public onClearClick(): void {
    this.dialogRef.close({
      heatmapValues: {
        heatmapIndexPercentage: this.heatmapValues.heatmapIndexPercentage,
        heatmapQuartiles: this.heatmapValues.heatmapQuartiles,
        heatmapQuintiles: this.heatmapValues.heatmapQuintiles,
        heatmap: this.heatmapValues.heatmap,
      },
    });
  }

  public onResetClick(): void {
    this.heatmapValues = {
      heatmapIndexPercentage: DEFAULT_INDEX_PERCENTAGE,
      heatmapQuartiles: HEATMAP_QUARTILES,
      heatmapQuintiles: HEATMAP_QUINTILES,
      heatmap: HEATMAP_CUSTOM,
    };
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  public colorPickerDialog(heatmapOption: string, index: number): void {
    this.colorPicker
      .chartColor({
        target: undefined,
        colors: undefined,
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((color: string) => {
        if (color) this.heatmapValues[heatmapOption][index].background = color;
      });
  }
}
