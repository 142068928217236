import { CleaningMethod } from './cluster.model';

export enum ResearchStatus {
  onGoing = 'ongoing',
  finished = 'finished',
  failure = 'failure',
}

export enum ResearchOperation {
  factorAnalysis = 'factor-analysis',
  clustering = 'clustering',
}

export interface GetResearchStatusRequestBody {
  'research-session-id': string;
}

export interface GetResearchStatusResponseBody {
  success: string;
  message: string;
  operation: ResearchOperation;
  progress: number;
  phase: string;
  'cleaning-method': CleaningMethod;
  'num-of-factors': number;
  'num-of-clusters': number;
  status: ResearchStatus;
}

export interface DeleteResearchRequestBody {
  'research-session-id': string;
}

export interface DeleteResearchResponseBody {
  success: boolean;
  message: string;
}

export interface DuplicateResearchRequestBody {
  'research-session-id': string;
}

export interface DuplicateResearchResponseBody {
  success: boolean;
  message: string;
  'research-session-id': string;
}
