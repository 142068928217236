import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { merge } from 'lodash';
import { filter } from 'rxjs/operators';
import {
  DocumentsDataSource,
  TupDocument,
  TupDocumentEvent,
  TupDocumentEventEmitterDirective,
  TupDocumentEventType,
  TupDocumentStorageComponent,
  TupDocumentStorageOptions,
  TupDocumentTypeId,
  getColumn,
} from '@telmar-global/tup-document-storage';
import { SurveyTimeDocument, ViewType } from 'src/app/models';
import { dashboardTabOptions } from '../dashboard-tab-options';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-report-list',
  templateUrl: './my-report-list.component.html',
  styleUrls: ['./my-report-list.component.scss'],
})
export class MyReportListComponent implements OnInit {
  public readonly options: TupDocumentStorageOptions = merge(
    {},
    dashboardTabOptions,
    {
      types: [TupDocumentTypeId.SURVEYTIME_CAMPAIGN],
      documentReplacementText: 'latest report',
      columns: [
        getColumn(environment, 'actions'),
        getColumn(environment, 'metadata.name.keyword', {
          header: 'My reports',
          sortable: false,
          tooltip: (document: TupDocument<unknown>) => document.metadata.name,
        }),
        getColumn(environment, 'metadata.modified', { sortable: false }),
        getColumn(environment, 'document.content.surveys'),
      ],
    }
  );

  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;
  public dataSource: DocumentsDataSource;

  @ViewChild('myReports') public myReports: TupDocumentStorageComponent;

  @Output()
  navigateToReportsTab: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  createReport: EventEmitter<null> = new EventEmitter<null>();

  public eventType: TupDocumentEventType = TupDocumentEventType.CLICK;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.documentEventEmitter.click
      .pipe(
        filter(
          (event: TupDocumentEvent) => typeof event.data?.action === 'undefined'
        )
      )
      .subscribe((event: TupDocumentEvent) => {
        this.openExisting(event.document);
      });
  }

  public ngAfterViewInit(): void {
    this.dataSource = this.myReports?.dataSource;
  }

  public openExisting(doc: TupDocument<unknown>): void {
    const currentDoc = Object.assign(doc) as TupDocument<SurveyTimeDocument>;
    this.router.navigate([`doc/${currentDoc.metadata.id}/data`], {
      queryParams: {
        tab: ViewType.crossTab,
      },
    });
    return;
  }
}
