import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TupCsvBuilder } from '@telmar-global/tup-document-exporter';
import { cloneDeep } from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Subject } from 'rxjs';
import {
  CrosstabTableCsvBuilder,
  FactorAnalysisTableXlsxData,
} from 'src/app/builders/crosstab-table-csv.builder';
import { CrosstabTableXlsxBuilder } from 'src/app/builders/crosstab-table-xlsx.builder';
import { RenameDialogComponent } from 'src/app/dialogs';
import {
  DEFAULT_FA_TABLE_HIGHLIGHT_OPTIONS,
  FAExplainedVariances,
  FATableHighlightOption,
  FactorAnalysisSettings,
  FactorAnalysisTableRowData,
  PercentageType,
  PercentageTypeLabels,
  Survey,
} from 'src/app/models';
import { TargetTitlePipe } from 'src/app/pipes';
import { DocumentService, XlsxService } from 'src/app/services';
import { CsvService } from 'src/app/services/csv.service';
import { compare } from 'src/app/utils/sortHelper';

@Component({
  selector: 'app-factor-analysis-table',
  templateUrl: './factor-analysis-table.component.html',
  styleUrls: ['./factor-analysis-table.component.scss'],
})
export class FactorAnalysisTableComponent
  extends TupCsvBuilder
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableData: FactorAnalysisTableRowData[];
  @Input() explainedVariances: FAExplainedVariances;
  @Input() survey: Survey;
  @Input() activeTableTitle: string;
  @Input() audienceSize: number;
  @Input() factorTitles: string[];
  @Input() multiSortThreshold: number;
  @Output() factorTitleChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Input() settings: FactorAnalysisSettings;
  public sort: Sort = {
    active: '',
    direction: '',
  };
  @ViewChild(MatSort, { static: true }) tableSort: MatSort;

  public readonly defaultColumnMap = {
    rowNumber: {
      id: 'rowNumber',
      name: '',
      align: 'left',
      sortable: false,
      editable: false,
    },
    title: {
      id: 'title',
      name: 'Title',
      align: 'left',
      sortable: false,
      editable: false,
    },
  };

  public readonly defaultExplainedVarianceDisplayedColumns = {
    [PercentageType.individual]: [
      {
        id: `rowNumber-${PercentageTypeLabels.individual}`,
        label: '',
        align: 'left',
      },
      {
        id: PercentageTypeLabels.individual,
        label: PercentageTypeLabels.individual,
        align: 'left',
      },
    ],
    [PercentageType.cumulative]: [
      {
        id: `rowNumber-${PercentageTypeLabels.cumulative}`,
        label: '',
        align: 'left',
      },
      {
        id: PercentageTypeLabels.cumulative,
        label: PercentageTypeLabels.cumulative,
        align: 'left',
      },
    ],
  };
  private readonly defaultColumns = ['rowNumber', 'title'];
  public displayedColumns: string[] = [...this.defaultColumns];
  public explainedVarianceDisplayedColumns = cloneDeep(
    this.defaultExplainedVarianceDisplayedColumns
  );
  public explainedVarianceDisplayedColumnIDs: Record<PercentageType, string[]> =
    {
      [PercentageType.individual]: [],
      [PercentageType.cumulative]: [],
    };

  public readonly tableHiglightColors: typeof FATableHighlightOption =
    FATableHighlightOption;

  public columnMap = this.defaultColumnMap;
  public dataSource: MatTableDataSource<FactorAnalysisTableRowData> =
    new TableVirtualScrollDataSource([]);
  private multiSortRows: FactorAnalysisTableRowData[][] = [];
  private unsubscribe: Subject<void> = new Subject<void>();

  public scrollbarWidth = 0;

  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private csvService: CsvService,
    private xlsxService: XlsxService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.tableData?.currentValue) {
      this.setupTable(cloneDeep(changes.tableData.currentValue));
    }
    if (
      'multiSortThreshold' in changes &&
      !changes.multiSortThreshold.firstChange
    ) {
      this.sort = {
        active: '',
        direction: '',
      };
      this.setupTable(cloneDeep(this.tableData));
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setupTable(data: FactorAnalysisTableRowData[]): void {
    this.clearCurrentData();
    this.populateExplainedVarianceData(this.explainedVariances);
    this.populateTableData(data);
    this.addFactorColumnsToDisplay(data);
    this.setSort(this.sort);
    this.triggerFakeWindowResizeEvent();
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.displayedColumns = [...this.defaultColumns];
    this.explainedVarianceDisplayedColumns = cloneDeep(
      this.defaultExplainedVarianceDisplayedColumns
    );
    this.explainedVarianceDisplayedColumnIDs = {
      [PercentageType.individual]: [],
      [PercentageType.cumulative]: [],
    };
  }

  private populateTableData(data: FactorAnalysisTableRowData[]) {
    if (!data) {
      return;
    }
    const rawData = data.map((row, index) => ({
      ...row,
      rowNumber: row.rowNumber,
      // tslint:disable-next-line:no-shadowed-variable
      ...row.loadings.reduce((acc, loading, index) => {
        acc[`factor-${index + 1}`] = Number(loading).toFixed(2);
        return acc;
      }, {}),
    }));
    if (this.multiSortThreshold !== -1) {
      const numOfFactors = data[0].loadings.length;
      this.multiSortRows = Array.from({ length: numOfFactors + 1 }, () => []);
      rawData.forEach((row) => {
        const allLessThanThreshold = row.loadings.every(
          (value) => value < this.multiSortThreshold
        );
        if (allLessThanThreshold) {
          this.multiSortRows[numOfFactors] = this.multiSortRows[
            numOfFactors
          ].concat(cloneDeep(row));
        } else {
          row.loadings.forEach((loading: number, loadingIndex: number) => {
            if (loading >= this.multiSortThreshold) {
              this.multiSortRows[loadingIndex] = this.multiSortRows[
                loadingIndex
              ].concat(cloneDeep(row));
            }
          });
        }
      });
      this.multiSortRows = this.multiSortRows.map((rows, index) =>
        index < numOfFactors
          ? rows.sort((a, b) => {
              return compare(a.loadings[index], b.loadings[index], false);
            })
          : rows
      );
      this.multiSortRows.forEach((factorRows, index) => {
        if (factorRows.length > 0 && index < numOfFactors) {
          factorRows[factorRows.length - 1].hasFactorDivider = true;
        }
      });
      this.dataSource.data = this.multiSortRows.reduce(
        (acc, rows) => acc.concat(...rows),
        []
      );
    } else {
      this.multiSortRows = [];
      this.dataSource.data = rawData;
    }
  }

  private addFactorColumnsToDisplay(data: FactorAnalysisTableRowData[]) {
    this.displayedColumns.push(
      ...Array.from(
        { length: data[0].loadings.length },
        (_, index) => `factor-${index + 1}`
      )
    );

    this.columnMap = {
      ...this.columnMap,
      ...data[0].loadings.reduce((acc, _, index) => {
        acc[`factor-${index + 1}`] = {
          id: `factor-${index + 1}`,
          name: this.getFactorTitle(index),
          align: 'right',
          sortable: true,
          editable: true,
          editing: false,
        };
        return acc;
      }, {}),
    };

    this.factorTitles = Object.values(this.columnMap)
      .filter((column) => column.id.startsWith('factor'))
      .map((column) => column.name);
  }

  private getFactorTitle(index: number) {
    if (this.factorTitles && this.factorTitles.length - 1 >= index) {
      return this.factorTitles[index];
    } else {
      return `Factor ${index + 1}`;
    }
  }

  public populateExplainedVarianceData(
    explainedVariances: FAExplainedVariances
  ) {
    this.explainedVarianceDisplayedColumns[PercentageType.individual].push(
      ...explainedVariances['p-explained-variances'].map((variance, index) => ({
        id: `factor-${PercentageType.individual}-${index + 1}`,
        label: variance.toFixed(2),
        align: 'right',
      }))
    );

    this.explainedVarianceDisplayedColumns[PercentageType.cumulative].push(
      ...explainedVariances['cum-p-explained-variances'].map(
        (variance, index) => ({
          id: `factor-${PercentageType.cumulative}-${index + 1}`,
          label: variance.toFixed(2),
          align: 'right',
        })
      )
    );

    this.explainedVarianceDisplayedColumnIDs[PercentageType.individual] =
      this.explainedVarianceDisplayedColumns[PercentageType.individual].map(
        (row) => row.id
      );
    this.explainedVarianceDisplayedColumnIDs[PercentageType.cumulative] =
      this.explainedVarianceDisplayedColumns[PercentageType.cumulative].map(
        (row) => row.id
      );
  }

  public sortData(sort: Sort) {
    this.sort = sort;
    this.populateTableData(this.getSortedTableData(this.tableData, sort));
  }

  private getSortedTableData(
    data: FactorAnalysisTableRowData[],
    sort: Sort
  ): FactorAnalysisTableRowData[] {
    if (sort.active === '' || sort.direction === '') {
      return cloneDeep(data);
    } else {
      return this.sortSelectedTableRowsByNumber(
        cloneDeep(data),
        sort.active,
        sort.direction === 'asc'
      );
    }
  }

  private sortSelectedTableRowsByNumber(
    tableRows: FactorAnalysisTableRowData[],
    sortElement: string,
    isAsc: boolean
  ) {
    const sortIndex = Number(sortElement[sortElement.length - 1]) - 1;
    const sortedRows = tableRows.sort((a, b) => {
      return compare(a.loadings[sortIndex], b.loadings[sortIndex], isAsc);
    });
    return [...sortedRows];
  }

  private setSort(sort: Sort): void {
    this.tableSort.active = sort.active;
    this.tableSort.direction = sort.direction;
  }

  public onHeaderClick(column: string) {
    this.dialog
      .open(RenameDialogComponent, {
        data: {
          dialogTitle: 'Change title',
          inputTitle: 'Title',
          inputValue: this.columnMap[column].name,
        },
        closeOnNavigation: true,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe((results: any) => {
        if (results?.name) {
          const index = this.displayedColumns
            // tslint:disable-next-line:no-shadowed-variable
            .filter((column) => column.startsWith('factor'))
            .findIndex((displayedColumn) => column === displayedColumn);
          this.factorTitles[index] = results.name;
          this.factorTitleChanged.emit(this.factorTitles);
          this.columnMap[column].name = results.name;
        }
      });
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    setTimeout(() => {
      this.calculateScrollbarWidth();
    }, 1000);
  }

  private calculateScrollbarWidth() {
    const container: HTMLElement = document.querySelector('.grid-table-body');
    if (!container) {
      return;
    }
    this.scrollbarWidth = container.offsetWidth - container.clientWidth;
    const header: HTMLElement = document.querySelector('.grid-table-header');
    container.addEventListener('scroll', (event) => {
      header.scrollLeft = container.scrollLeft;
    });
  }

  public exportToCsv(docName?: string): void {
    const documentName = this.getExportDocumentName(docName);
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addFactorAnalysisTableData(
      this.prepareXlsxExportData()
    );
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.getXlsxExportData(docName);
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  public exportToSheets(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.getXlsxExportData(docName);
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  private getExportDocumentName(docName?: string) {
    return `${
      docName || this.documentService.document.metadata.name
    } - Factor Analysis`;
  }

  private prepareXlsxExportData(docName?: string): FactorAnalysisTableXlsxData {
    return {
      documentName: this.getExportDocumentName(docName),
      data: this.dataSource.data.map((data) => ({
        ...data,
        loadings: data.loadings.map((loading) => Number(loading.toFixed(2))),
        highlightColors: this.getColorHiglights(data.loadings),
      })),
      explainedVariances: Object.values(
        cloneDeep(this.explainedVarianceDisplayedColumns)
      ).map((columnArray) => {
        return columnArray.splice(1).map((row) => row.label);
      }),
      displayedColumns: this.displayedColumns.map(
        (column) => this.columnMap[column].name
      ),
      isMultiSort: this.multiSortThreshold !== -1,
      activeTableTitle: this.activeTableTitle,
      audienceSize: String(this.audienceSize),
      survey: this.survey,
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
      ...(this.sort.active !== '' && this.sort.direction !== ''
        ? {
            sort: {
              surveyCode: this.survey.code,
              sortColumn: this.columnMap[this.sort.active].name || '',
              sortOrder:
                this.sort.direction === 'asc' ? 'Ascending' : 'Descending',
            },
          }
        : {}),
    };
  }

  private getXlsxExportData(
    docName?: string
  ): [string, CrosstabTableXlsxBuilder] {
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');
    const crossTabTableData = this.prepareXlsxExportData(docName);
    crossTabTableBuilder.addFactorAnalysisTable(crossTabTableData);
    crossTabTableBuilder.build();

    return [crossTabTableData.documentName, crossTabTableBuilder];
  }

  private getColorHiglights(loadings: number[]) {
    const tableHighlightOptions = this.settings.tableHighlightOptions;

    return loadings.map((element) => {
      const loading = Number(element.toFixed(2));
      if (
        loading >= tableHighlightOptions[FATableHighlightOption.large].threshold
      ) {
        return DEFAULT_FA_TABLE_HIGHLIGHT_OPTIONS[FATableHighlightOption.large]
          .background;
      } else {
        if (
          loading >=
            tableHighlightOptions[FATableHighlightOption.moderate].threshold &&
          loading <
            tableHighlightOptions[FATableHighlightOption.large].threshold
        ) {
          return DEFAULT_FA_TABLE_HIGHLIGHT_OPTIONS[
            FATableHighlightOption.moderate
          ].background;
        } else {
          return DEFAULT_FA_TABLE_HIGHLIGHT_OPTIONS[
            FATableHighlightOption.small
          ].background;
        }
      }
    });
  }
}
