import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesTargetGroup,
  SaveOwnCodesType,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { Empty, TupDocument } from '@telmar-global/tup-document-storage';
import { TupAuthService } from '@telmar-global/tup-auth';
import { catchError, concatMap, first, tap } from 'rxjs/operators';
import {
  APPLICATION_NAME,
  CrossTabTableData,
  CrossTabTableDataCell,
  DocumentDataState,
  ReportMode,
  Target,
} from '../models';
import { TargetService } from './target.service';
import { TargetTitlePipe } from '../pipes';
import { ReportPreferencesService } from './report-preferences.service';
import { DataItemsService } from './data-items.service';
import { DocumentService } from '../services/document.service';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { CodingDataMapService } from './coding-data-map.service';
import { RequestLoadingService } from './request-loading.service';

@Injectable({
  providedIn: 'root',
})
export class CustomAudiencesService {
  private addedOwnCodesSubject = new BehaviorSubject<{
    type: SaveOwnCodesType;
    isShared: boolean;
  } | null>(null);
  public addedOwnCodesSubject$ = this.addedOwnCodesSubject.asObservable();

  private updatedOwnCodesContainerSubject =
    new BehaviorSubject<SaveOwnCodesType | null>(null);
  public updatedOwnCodesContainerSubject$ =
    this.updatedOwnCodesContainerSubject.asObservable();

  public sortedRows: Target[];

  constructor(
    private authService: TupAuthService,
    @Inject(DocumentService) private documentService: DocumentService,
    private userMessageService: TupUserMessageService,
    private targetService: TargetService,
    private targetTitlePipe: TargetTitlePipe,
    private dataItemsService: DataItemsService,
    private audienceGroupsService: TupAudienceGroupsService,
    private reportPreferencesService: ReportPreferencesService,
    private codingDataMapService: CodingDataMapService,
    private requestLoadingService: RequestLoadingService
  ) {
    this.subscribeToSortedRows();
  }

  public notifyOwnCodesAdded(
    type: SaveOwnCodesType,
    doc: TupDocument<DocumentAudienceGroup>
  ): void {
    const user = this.authService.user;
    const userEmail = user.attributes.email;
    const isShared = userEmail !== doc.metadata.container.name;
    this.addedOwnCodesSubject.next({
      type,
      isShared,
    });
  }

  public notifyOwnCodesContainerUpdated(type: SaveOwnCodesType): void {
    this.updatedOwnCodesContainerSubject.next(type);
  }

  public canEditCustomAudiences(
    doc: TupDocument<DocumentAudienceGroup>
  ): boolean {
    return (
      doc.content.survey.code.toLowerCase() ===
      this.documentService.activeSurvey.code.toLowerCase()
    );
  }

  public showUnableToEditCustomAudiencesWarning(): void {
    this.userMessageService.openMessageDialog(
      'Sorry! You cannot edit this row due to differences in survey code.',
      APPLICATION_NAME
    );
  }

  public editCustomAudiences(
    doc: TupDocument<DocumentAudienceGroup>
  ): Observable<TupDocument<DocumentAudienceGroup> | Empty> {
    this.requestLoadingService.setLoading({
      target: 'edit-custom-audiences',
      isLoading: true,
    });
    const targets = doc.content.targets.map(
      (targetItem: DocumentAudienceGroupItem) => targetItem.options.target
    );
    return this.documentService.fetchDynamicCrosstabData(targets, [], []).pipe(
      tap(() => {
        this.requestLoadingService.setLoading({
          target: 'edit-custom-audiences',
          isLoading: false,
        });
      }),
      concatMap((crossTabData: CrossTabTableData[]) =>
        this.audienceGroupsService.saveOwnCodes({
          survey: this.documentService.activeSurvey,
          type: SaveOwnCodesType.audience,
          document: doc,
          fileName: doc.metadata.name,
          targetGroups: this.formatSaveOWnCodesTargetGroups(doc, crossTabData),
        })
      ),
      catchError((error) => {
        this.requestLoadingService.setLoading({
          target: 'edit-custom-audiences',
          isLoading: false,
        });
        return throwError(error);
      })
    );
  }

  private formatSaveOWnCodesTargetGroups(
    doc: TupDocument<DocumentAudienceGroup>,
    crossTabData: CrossTabTableData[]
  ): SaveOwnCodesTargetGroup[] {
    const codingData = crossTabData[0].data
      .filter((cell: CrossTabTableDataCell) => !cell.isTotalsColumn)
      .map((cell: CrossTabTableDataCell) =>
        this.codingDataMapService.formatCodingData(cell)
      );
    return [
      {
        title: 'existing',
        selectAll: true,
        items: doc.content.targets.map(
          (targetItem: DocumentAudienceGroupItem, index: number) => ({
            selected: true,
            targetItem,
            extraInfo: `(Audience: ${codingData[index].population}, Resps: ${codingData[index].resps})`,
          })
        ),
      },
      ...this.getDefaultTargetGroups(),
    ];
  }

  private getDefaultTargetGroups(): SaveOwnCodesTargetGroup[] {
    const targetGroups: SaveOwnCodesTargetGroup[] = [];
    this.documentService.documentState$
      .pipe(first())
      .subscribe(({ tables, columns, rows }: DocumentDataState) => {
        targetGroups.push({
          title: 'bases*',
          selectAll: false,
          items: tables.map((target: Target) => ({
            selected: false,
            targetItem: this.convertTargetToAudienceGroupItem(target),
          })),
        });
        targetGroups.push({
          title: 'columns',
          selectAll: false,
          items: columns.map((target: Target) => ({
            selected: false,
            targetItem: this.convertTargetToAudienceGroupItem(target),
          })),
        });
        const sortRows =
          this.sortedRows &&
          this.reportPreferencesService.getActiveReportMode() ===
            ReportMode.crossTab
            ? this.sortedRows
            : rows;
        targetGroups.push({
          title: 'rows',
          selectAll: false,
          items: sortRows.map((target: Target) => ({
            selected: false,
            targetItem: this.convertTargetToAudienceGroupItem(target),
          })),
        });
      });
    return targetGroups;
  }

  private convertTargetToAudienceGroupItem(
    target: Target
  ): DocumentAudienceGroupItem {
    return {
      title: this.targetTitlePipe.transform(target),
      coding: target.coding,
      options: {
        statement: null,
        target: this.targetService.shallowCopyTarget(target),
      },
    };
  }

  private subscribeToSortedRows() {
    this.dataItemsService.sortedRows$.subscribe(
      (rows) => (this.sortedRows = rows)
    );
  }
}
