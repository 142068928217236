<div
  fxFill
  fxLayout="column"
>
  <app-top-bar
    [selectedCorrespondenceViewResult]="selectedViewResults"
    [selectViewType]="selectViewType"
    [showSurveyInfo]="false"
    [isReadonly]="isReadonly"
    [canExportHighcharts]="hasValidDataset"
    (exportToPNG)="exportTo('image/png', $event)"
    (exportToJPEG)="exportTo('image/jpeg', $event)"
    (exportToPDF)="exportTo('application/pdf', $event)"
    (exportToSVG)="exportTo('image/svg+xml', $event)"
    (exportToCsv)="exportToCsv($event)"
    (exportToXlsx)="exportToXlsx($event)"
    (exportToSheets)="exportToSheets($event)"
    (exportToPptx)="exportToPptx($event)"
  ></app-top-bar>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="10px"
    *ngIf="hasValidDataset"
    class="correspondence-control-bar"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        class="input-form-field select-small"
        style="width: 100px"
        tupMatFormField
        matTooltip="View results as"
      >
        <mat-select
          [(ngModel)]="selectedViewResults"
          (ngModelChange)="onSelectedViewResultsChange()"
          [disabled]="inProgress"
        >
          <mat-option value="chart"> Chart</mat-option>
          <mat-option value="table"> Table</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-button-toggle-group
        class="item-gap"
        *ngIf="selectedViewResults === 'table'"
        [disabled]="inProgress"
        [(ngModel)]="selectedTableDataMode"
        (change)="toggleTableScores()"
      >
        <mat-button-toggle
          class="toggle-padding"
          *ngFor="let mode of dataModes"
          [disableRipple]="true"
          [value]="mode.value"
          [matTooltip]="mode.tooltip"
        >
          {{ mode.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field
        *ngIf="surveys.length > 1"
        appearance="outline"
        class="input-form-field select-small"
        tupMatFormField
        style="width: 200px"
        matTooltip="Change survey"
      >
        <mat-select
          [(ngModel)]="selectedCorrespondenceSurvey"
          (ngModelChange)="onSelectedSurveyChanged()"
          [disabled]="inProgress || isReadonly"
        >
          <mat-option
            *ngFor="let survey of surveys"
            [value]="survey"
          >
            {{ survey.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="selectedViewResults === 'table'"
      fxLayout="row"
      fxLayoutAlign="end start"
      fxLayoutGap="10px"
    >
      <mat-chip-list>
        <mat-chip
          *ngIf="settings.showHighlighting"
          [disableRipple]="false"
          (removed)="clearHighlightSettings()"
          [popper]="highlightTooltip"
          [popperTrigger]="'hover'"
          [popperPlacement]="'bottom'"
          [popperAppendTo]="'app-ca-table'"
          [popperStyles]="{ 'background-color': 'white' }"
          (click)="openSettings()"
          onclick="this.blur()"
          class="highlight-table-chip"
        >
          <mat-icon MatChipAvatar> palette </mat-icon>
          <popper-content
            #highlightTooltip
            fxLayout="column"
          >
            <p
              class="highlightTooltip"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <b>Highlight:</b>
            </p>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
            >
              <span
                class="highlight-color-dot-container"
                *ngFor="let color of highlightColors"
              >
                <span
                  class="highlight-color-dot"
                  [style.background-color]="color.background"
                ></span>
                {{ color.label }}
              </span>
            </div>
          </popper-content>
          <mat-icon
            matChipRemove
            matTooltipPosition="above"
            matTooltip="Remove highlight"
            >cancel
          </mat-icon>
        </mat-chip>
      </mat-chip-list>

      <ng-container *ngTemplateOutlet="reCorrespondenceButton"></ng-container>
      <ng-container *ngTemplateOutlet="passiveButton"></ng-container>
      <ng-container *ngTemplateOutlet="filterButton"></ng-container>

      <button
        onclick="this.blur()"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Change title mode"
        [matMenuTriggerFor]="changeTitleModeMenu"
      >
        <mat-icon class="material-symbols-outlined"> title </mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Settings"
        (click)="openSettings()"
      >
        <mat-icon class="tiny-icon">settings</mat-icon>
      </button>
    </div>
    <div
      *ngIf="selectedViewResults === 'chart'"
      fxLayout="row"
      fxLayoutAlign="end start"
      fxLayoutGap="10px"
    >
      <ng-container *ngTemplateOutlet="reCorrespondenceButton"></ng-container>
      <ng-container *ngTemplateOutlet="passiveButton"></ng-container>
      <ng-container *ngTemplateOutlet="filterButton"></ng-container>

      <button
        onclick="this.blur()"
        mat-stroked-button
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Change title mode"
        [matMenuTriggerFor]="changeTitleModeMenu"
      >
        <mat-icon class="material-symbols-outlined"> title </mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Best fit"
        (click)="bestFit()"
      >
        <mat-icon class="material-symbols-outlined">fit_page</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Zoom out"
        (click)="zoomOut()"
      >
        <mat-icon>zoom_out</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Zoom in"
        (click)="zoomIn()"
      >
        <mat-icon>zoom_in</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Move pan"
        [matMenuTriggerFor]="movePan"
      >
        <mat-icon class="material-symbols-outlined tiny-icon"
          >drag_pan
        </mat-icon>
      </button>

      <ng-container *ngTemplateOutlet="annotationButton"></ng-container>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        [disabled]="!canResetZoom"
        (click)="resetZoom()"
      >
        <mat-icon matTooltip="Reset zoom">restart_alt</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Settings"
        (click)="openSettings()"
      >
        <mat-icon class="tiny-icon">settings</mat-icon>
      </button>

      <button
        mat-stroked-button
        onclick="this.blur()"
        color="primary"
        tupMatButton
        appearance="tiny"
        matTooltip="Full screen"
        (click)="openFullScreen()"
      >
        <mat-icon class="tiny-icon">open_in_full</mat-icon>
      </button>
    </div>
  </div>

  <tup-highcharts
    *ngIf="hasValidDataset && chartOptions && selectedViewResults === 'chart'"
    id="container"
    [Highcharts]="Highcharts"
    [callbackFunction]="chartCallback"
    [options]="chartOptions"
    class="correspondence-chart"
  >
  </tup-highcharts>

  <app-ca-table
    *ngIf="
      hasValidDataset &&
      selectedViewResults === 'table' &&
      selectedTableDataMode === dataModes[0].value
    "
    [tableData]="tableData"
    [isTableHighlighted]="settings.showHighlighting"
    [selectedTableDataMode]="selectedTableDataMode"
    [sort]="dataModelSortMap[selectedTableDataMode]"
    [survey]="selectedCorrespondenceSurvey"
    (tableDataChange)="onTableDataChange($event)"
    (sortChange)="onTableSortChange($event)"
  ></app-ca-table>

  <app-ca-table
    *ngIf="
      hasValidDataset &&
      selectedViewResults === 'table' &&
      selectedTableDataMode === dataModes[1].value
    "
    [tableData]="tableData"
    [isTableHighlighted]="settings.showHighlighting"
    [selectedTableDataMode]="selectedTableDataMode"
    [sort]="dataModelSortMap[selectedTableDataMode]"
    [survey]="selectedCorrespondenceSurvey"
    (tableDataChange)="onTableDataChange($event)"
    (sortChange)="onTableSortChange($event)"
  ></app-ca-table>

  <tup-mat-card
    class="empty-copy"
    fxLayout="column"
    *ngIf="!hasValidDataset && !inProgress"
  >
    <span *ngIf="!isReadonly">
      Correspondence analysis cannot be performed with the current dataset.<br />
      Please ensure that there are a minimum of two rows and two columns in the
      dataset, and review the coding statements or values within the columns and
      rows in the crosstab.
    </span>
    <span *ngIf="isReadonly"> No Correspondence data. </span>
  </tup-mat-card>

  <div
    class="spinner-container-overlay"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="inProgress"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<ng-template #filterButton>
  <button
    *ngIf="isFilterRowActive"
    mat-flat-button
    onclick="this.blur()"
    color="primary"
    tupMatButton
    appearance="tiny"
    matTooltip="A Filter has been applied to this correspondence view"
    (click)="filterRows()"
  >
    <mat-icon>filter_list</mat-icon>
  </button>
  <button
    *ngIf="!isFilterRowActive"
    mat-stroked-button
    onclick="this.blur()"
    color="primary"
    tupMatButton
    appearance="tiny"
    matTooltip="Filter settings"
    (click)="filterRows()"
  >
    <mat-icon>filter_list</mat-icon>
  </button>
</ng-template>

<ng-template #annotationButton>
  <button
    *ngIf="isChartStockToolsGUIEnabled"
    mat-flat-button
    onclick="this.blur()"
    color="primary"
    tupMatButton
    appearance="tiny"
    matTooltip="Draw lines or add notes to chart"
    (click)="toggleChartAnnotationMode()"
  >
    <mat-icon>mode_edit</mat-icon>
  </button>
  <button
    *ngIf="!isChartStockToolsGUIEnabled"
    mat-stroked-button
    onclick="this.blur()"
    color="primary"
    tupMatButton
    appearance="tiny"
    matTooltip="Draw lines or add notes to chart"
    (click)="toggleChartAnnotationMode()"
  >
    <mat-icon>mode_edit</mat-icon>
  </button>
</ng-template>

<mat-menu #movePan="matMenu">
  <button
    mat-menu-item
    (click)="moveLeft()"
  >
    <mat-icon>west</mat-icon>
    Move left
  </button>
  <button
    mat-menu-item
    (click)="moveRight()"
  >
    <mat-icon>east</mat-icon>
    Move right
  </button>
  <button
    mat-menu-item
    (click)="moveUp()"
  >
    <mat-icon>north</mat-icon>
    Move up
  </button>
  <button
    mat-menu-item
    (click)="moveDown()"
  >
    <mat-icon>south</mat-icon>
    Move down
  </button>
</mat-menu>

<ng-template #reCorrespondenceConfirmation>
  <p>
    Performing a re-analysis will
    <b>reset the current analysis, settings and selected variables</b> and start
    a new calculation by using the currently active survey and current rows &
    columns in the crosstab.
  </p>
</ng-template>

<ng-template #reCorrespondenceButton>
  <button
    mat-stroked-button
    onclick="this.blur()"
    color="primary"
    appearance="tiny"
    class="data-control-button"
    tupMatButton
    matTooltip="Re-run correspondence analysis"
    [disabled]="inProgress"
    (click)="onReAnalysis()"
  >
    <mat-icon class="tiny-icon">autorenew</mat-icon>
  </button>
</ng-template>

<ng-template #passiveButton>
  <button
    mat-stroked-button
    onclick="this.blur()"
    color="primary"
    appearance="tiny"
    class="data-control-button"
    tupMatButton
    matTooltip="Set active/passive"
    [disabled]="inProgress"
    (click)="openActivePassive()"
  >
    Active/passive
  </button>
</ng-template>

<div
  id="labelTextForm"
  class="input-annotation"
>
  <input id="labelTextInput" />
  <button id="labelTextButton">Add</button>
</div>

<mat-menu #changeTitleModeMenu="matMenu">
  <div
    fxLayout="column"
    fxLayoutGap="5px"
    class="title-mode-menu"
  >
    <mat-radio-group
      [(ngModel)]="activeTitleMode"
      fxLayout="column"
      fxLayoutGap="15px"
      (ngModelChange)="selectTitleMode()"
    >
      <mat-radio-button
        *ngFor="let mode of titleModes"
        [value]="mode.displayType"
        (click)="clickTitleMode(mode.displayType)"
      >
        {{ mode.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-menu>
