import { Component, Input, OnInit } from '@angular/core';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChartTargetMode,
  DocumentChartStyle,
  IGNORED_SINGLE_TARGET_GRAPH_TYPES,
} from 'src/app/models';
import { ChartsService } from '../../services/charts.service';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

@Component({
  selector: 'app-chart-styles-editable-cell',
  templateUrl: './chart-styles-editable-cell.component.html',
  styleUrls: ['./chart-styles-editable-cell.component.scss'],
})
export class ChartStylesEditableCellComponent implements OnInit {
  @Input() public document: TupDocument<DocumentChartStyle>;

  private unsubscribe: Subject<void> = new Subject<void>();

  public canApply: boolean;

  constructor(private chartsService: ChartsService) {}

  ngOnInit(): void {
    this.chartsService.activeChartTargetMode$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((chartTargetMode: ChartTargetMode) => {
        const primaryChartType =
          this.document.content.chartStyleSettings.primaryChartType;

        if (chartTargetMode === ChartTargetMode.single) {
          this.canApply =
            !IGNORED_SINGLE_TARGET_GRAPH_TYPES.includes(primaryChartType);
        } else {
          this.canApply = true;
        }
      });
  }
}
