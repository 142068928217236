import { Injectable } from '@angular/core';
import { ActionDefinition } from './ActionDefinition';
import { DocumentService, TitleModeService } from '../services';
import { TargetItem, TitleModeGridTableRow } from '../models';
import { TitleModeActionItem } from '../models/action.model';

export interface ChangeTargetTitleModeActionContext {
  targetItem: TargetItem[];
  actionItem: TitleModeGridTableRow;
}

@Injectable({
  providedIn: 'root',
})
export class ChangeTargetTitleModeAction extends ActionDefinition<ChangeTargetTitleModeActionContext> {
  constructor(
    private documentService: DocumentService,
    private titleModeService: TitleModeService
  ) {
    super();
  }

  public invoke(context: ChangeTargetTitleModeActionContext): void {
    if (context.targetItem && context.targetItem[0]) {
      context.targetItem.forEach((targetItem) => {
        targetItem.target.activeTitleMode = context.actionItem.displayType;
      });
      this.documentService.updateDocumentData(context.targetItem, false);
    } else {
      const titleMode = context.actionItem.displayType;
      this.titleModeService.updateTitleMode(titleMode);
      this.documentService.updateDocumentDataTitle(
        {
          activeTitleMode: titleMode,
        },
        false
      );
    }
  }
}
