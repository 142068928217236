import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  FA_STATUS_POLLING_TIME,
  GetFactorsRequestBody,
  GetFactorsResponseBody,
  GetResearchStatusResponseBody,
  ResearchStatus,
  SaveFactorRequestBody,
  SaveFactorResponseBody,
  StartFactorAnalysisRequestBody,
  StartFactorAnalysisResponseBody,
} from '../models';
import { startWith, switchMap, takeWhile } from 'rxjs/operators';
import { ResearchService } from './research.service';
import { FactorAnalysisApiService } from './factor-analysis-api.service';

@Injectable({
  providedIn: 'root',
})
export class FactorAnalysisService {
  constructor(
    private apiService: FactorAnalysisApiService,
    private researchService: ResearchService
  ) {}

  public startFactorAnalysis(
    body: StartFactorAnalysisRequestBody
  ): Observable<StartFactorAnalysisResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.startFa,
      { body }
    );
  }

  public getFactorAnalysisStatus(
    researchId: string
  ): Observable<GetResearchStatusResponseBody> {
    return interval(FA_STATUS_POLLING_TIME).pipe(
      startWith(0),
      switchMap(() => this.researchService.getStatus(researchId)),
      takeWhile(
        (statusResult: GetResearchStatusResponseBody) =>
          statusResult.status === ResearchStatus.onGoing,
        true
      )
    );
  }

  public getFactors(
    body: GetFactorsRequestBody
  ): Observable<GetFactorsResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.getFactors,
      { body }
    );
  }

  public saveFactor(
    body: SaveFactorRequestBody
  ): Observable<SaveFactorResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.saveFactor,
      { body }
    );
  }
}
