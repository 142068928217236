<div
  class="view-container"
  fxFlex
  fxLayout="column"
>
  <div
    #gridViewContainer
    class="data-controls-container grid-views-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-button-toggle-group
        class="view-toggle-group-control"
        [(ngModel)]="selectedTab"
        (change)="onTabToggle()"
      >
        <mat-button-toggle
          data-tracking="coding-grid-tables-toggle"
          [disableRipple]="true"
          matTooltip="Tables"
          data-qa="Tables"
          [value]="targetType.tables"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 4.5H14.5M4.5 0.5V14.5M1.5 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H1.5C0.947716 14.5 0.5 14.0523 0.5 13.5V1.5C0.5 0.947716 0.947715 0.5 1.5 0.5Z"
              stroke="#0054FF"
            />
          </svg>
          <span class="view-toggle-group-control__text">{{
            tablesTabLabel
          }}</span>
        </mat-button-toggle>

        <mat-button-toggle
          data-tracking="coding-grid-columns-toggle"
          [disableRipple]="true"
          matTooltip="Columns (Targets)"
          data-qa="Columns"
          [value]="targetType.columns"
        >
          <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 3.57628e-07L8.5 15M12.5 4.76837e-07L12.5 15M4.5 1.19209e-07L4.5 15M0.500001 0L0.5 15"
              stroke="#0054FF"
            />
          </svg>
          <span class="view-toggle-group-control__text">{{
            columnsTabLabel
          }}</span>
        </mat-button-toggle>
        <mat-button-toggle
          data-tracking="coding-grid-rows-toggle"
          [disableRipple]="true"
          matTooltip="Rows (Insights)"
          data-qa="Rows"
          [value]="targetType.rows"
        >
          <svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4.5H15M0 0.5H15M0 8.5H15M0 12.5H15"
              stroke="#0054FF"
            />
          </svg>
          <span class="view-toggle-group-control__text">{{
            rowsTabLabel
          }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <app-coding-grid-action-controls
      #appCodingGridActionControls
      [isDeletable]="isDeletable"
      [isReadonly]="isReadonly"
      [selectedRows]="selectedRows"
      [selectedTab]="selectedTab"
      [findAndReplaceDialogOpen]="findAndReplaceDialogOpen"
      [findAndReplaceDialogState]="findAndReplaceDialogState"
      [swapActionWarning]="swapActionWarning"
      [moreMenuVisibility]="moreMenuVisibility"
      [isColumnHidden]="isColumnHidden"
      [swapDisabled]="
        rows.length === emptyGridRowSize && columns.length === emptyGridRowSize
      "
      [hideColumnItems]="hideColumnItems"
      (openFindAndReplaceClicked)="onOpenFindAndReplace()"
      (deleteSelectedRowsClicked)="onDeleteSelectedRows()"
      (swapRowsAndColumnsClicked)="onSwapRowsAndColumns()"
      (separateCountActionClicked)="onSeparateCountActionClicked()"
      (openNTileSettingsActionClicked)="onOpenNTileSettings()"
      (openNewCodeBuilderActionClicked)="onOpenNewCodeBuilder()"
      (duplicateSelectedRowsActionClicked)="onDuplicateSelectedRows()"
      (renameGroupNameActionClicked)="onRenameGroupName()"
      (saveCustomAudienceActionClicked)="onSaveCustomAudience()"
      (deleteCodingGridActionClicked)="onDeleteCodingGrid()"
      (openMoreMenuActionClicked)="onOpenMoreMenu()"
      (hideColumnsActionClicked)="onHideColumns()"
      (combineActionClicked)="onCombineActionClicked($event)"
      (changeTargetTitleModeActionClicked)="
        onChangeTargetTitleModeActionClicked($event)
      "
      (sendToActionClicked)="onSendTo($event)"
    >
    </app-coding-grid-action-controls>
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <app-tablebase-info-popper
          [popperAppendTo]="'.grid-views-container'"
        ></app-tablebase-info-popper>

        <app-tablebase-select
          [isReadonly]="isReadonly"
          (tablebaseEdit)="onEditTablebase($event)"
        ></app-tablebase-select>
      </div>

      <app-weight-select [surveys]="surveys"></app-weight-select>
    </div>
  </div>
  <mat-tab-group
    tupMatIchabodsBody
    color="primary"
    [(selectedIndex)]="selectedTab"
    (selectedTabChange)="onTabChange($event)"
    class="ichabods-tab-group"
  >
    <mat-tab>
      <app-coding-grid-table
        #tableCodingGrid
        fxFlex
        [isReadonly]="isReadonly"
        [tableTargetType]="targetType.tables"
        [data]="tables"
        [sort]="gridSorts[targetType.tables]"
        [searchHits]="searchHits"
        [isDeletable]="isDeletable"
        [isProgressing]="isProgressing"
        [gridPreference]="codingGridPreferences[targetType.tables]"
        (editClicked)="onOpenCodeBuilder($event)"
        (selectedRowsChange)="onSelectedRowsChange($event)"
        (deleteClicked)="onDeleteSelectedRows($event)"
        (deleteAllClicked)="onDeleteAllRows($event)"
        (combineClicked)="onCombineActionClicked($event)"
        (separateCountClicked)="onSeparateCountActionClicked($event)"
        (changeTargetTitleModeClicked)="
          onChangeTargetTitleModeActionClicked($event)
        "
        (clickedRow)="onClickRow($event)"
        (clickedEditTitleRow)="onClickEditTitleRow($event)"
        (clickedEmptyRow)="onClickEmptyRow($event)"
        (sendToClicked)="onSendTo($event)"
        (assignGroupNameClicked)="onRenameGroupName($event)"
        (duplicateClicked)="onDuplicateSelectedRows($event)"
        (nTileSettingsClicked)="onOpenNTileSettings($event)"
        (saveCustomAudienceClicked)="onSaveCustomAudience($event)"
        [isViewActive]="isViewActive"
        [dropzoneMenu]="dropzoneMenu"
        (dropzoneEnter)="
          onDropzoneEnter($event.trigger, $event.targetType, $event.targetItem)
        "
        (dropzoneLeave)="onDropzoneLeave()"
        (dropzoneDrop)="onTableDropzoneDrop($event)"
        (moveItems)="onMoveItems($event)"
        (sortChange)="onSortChange($event)"
        (sortedRows)="onSortedRowsChange($event, targetType.tables)"
      ></app-coding-grid-table>
    </mat-tab>
    <mat-tab>
      <app-coding-grid-table
        #columnCodingGrid
        fxFlex
        [isReadonly]="isReadonly"
        [tableTargetType]="targetType.columns"
        [data]="columns"
        [sort]="gridSorts[targetType.columns]"
        [searchHits]="searchHits"
        [isDeletable]="isDeletable"
        [isProgressing]="isProgressing"
        [gridPreference]="codingGridPreferences[targetType.columns]"
        (editClicked)="onOpenCodeBuilder($event)"
        (selectedRowsChange)="onSelectedRowsChange($event)"
        (deleteClicked)="onDeleteSelectedRows($event)"
        (deleteAllClicked)="onDeleteAllRows($event)"
        (combineClicked)="onCombineActionClicked($event)"
        (separateCountClicked)="onSeparateCountActionClicked($event)"
        (changeTargetTitleModeClicked)="
          onChangeTargetTitleModeActionClicked($event)
        "
        (clickedRow)="onClickRow($event)"
        (clickedEditTitleRow)="onClickEditTitleRow($event)"
        (clickedEmptyRow)="onClickEmptyRow($event)"
        (sendToClicked)="onSendTo($event)"
        (assignGroupNameClicked)="onRenameGroupName($event)"
        (duplicateClicked)="onDuplicateSelectedRows($event)"
        (nTileSettingsClicked)="onOpenNTileSettings($event)"
        (saveCustomAudienceClicked)="onSaveCustomAudience($event)"
        [isViewActive]="isViewActive"
        [dropzoneMenu]="dropzoneMenu"
        (dropzoneEnter)="
          onDropzoneEnter($event.trigger, $event.targetType, $event.targetItem)
        "
        (dropzoneLeave)="onDropzoneLeave()"
        (dropzoneDrop)="onTableDropzoneDrop($event)"
        (moveItems)="onMoveItems($event)"
        (sortChange)="onSortChange($event)"
        (sortedRows)="onSortedRowsChange($event, targetType.columns)"
      ></app-coding-grid-table>
    </mat-tab>
    <mat-tab>
      <app-coding-grid-table
        #rowCodingGrid
        fxFlex
        [isReadonly]="isReadonly"
        [tableTargetType]="targetType.rows"
        [sort]="gridSorts[targetType.rows]"
        [data]="rows"
        [searchHits]="searchHits"
        [isDeletable]="isDeletable"
        [isProgressing]="isProgressing"
        [gridPreference]="codingGridPreferences[targetType.rows]"
        (editClicked)="onOpenCodeBuilder($event)"
        (selectedRowsChange)="onSelectedRowsChange($event)"
        (deleteClicked)="onDeleteSelectedRows($event)"
        (deleteAllClicked)="onDeleteAllRows($event)"
        (combineClicked)="onCombineActionClicked($event)"
        (separateCountClicked)="onSeparateCountActionClicked($event)"
        (changeTargetTitleModeClicked)="
          onChangeTargetTitleModeActionClicked($event)
        "
        (clickedRow)="onClickRow($event)"
        (clickedEditTitleRow)="onClickEditTitleRow($event)"
        (clickedEmptyRow)="onClickEmptyRow($event)"
        (sendToClicked)="onSendTo($event)"
        (assignGroupNameClicked)="onRenameGroupName($event)"
        (duplicateClicked)="onDuplicateSelectedRows($event)"
        (nTileSettingsClicked)="onOpenNTileSettings($event)"
        (saveCustomAudienceClicked)="onSaveCustomAudience($event)"
        [isViewActive]="isViewActive"
        [dropzoneMenu]="dropzoneMenu"
        (dropzoneEnter)="
          onDropzoneEnter($event.trigger, $event.targetType, $event.targetItem)
        "
        (dropzoneLeave)="onDropzoneLeave()"
        (dropzoneDrop)="onTableDropzoneDrop($event)"
        (moveItems)="onMoveItems($event)"
        (sortChange)="onSortChange($event)"
        (sortedRows)="onSortedRowsChange($event, targetType.rows)"
      ></app-coding-grid-table>
    </mat-tab>
  </mat-tab-group>
</div>

<button
  #virtualButton
  class="hidden-virtual-button"
></button>

<mat-menu
  #dropzoneMenu="matMenu"
  [hasBackdrop]="false"
  class="action-menu"
>
  <div class="action-menu-button-container">
    <button
      mat-menu-item
      *ngFor="let dropzoneActionItem of dropzoneActionItems"
      [dndDropzone]
      [dndDragoverClass]="menuItemDragoverClass"
      [dndDisableDropIf]="isLoadingSelectedNodes"
      [disabled]="isLoadingSelectedNodes"
      (dragenter)="onActionMenuEnter($event)"
      (dragleave)="onActionMenuLeave($event)"
      (dndDrop)="onDrop(dropzoneActionItem)"
    >
      {{ dropzoneActionItem.name }}
    </button>
  </div>
</mat-menu>
