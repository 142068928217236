import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  ALL_RESPONDENTS_CODING,
  AnnotationPosition,
  CA_VERSION,
  CORRESPONDENCE_DATA_ROW_TYPE_CHART_ITEMS,
  CORRESPONDENCE_TABLE_DATA_MODES,
  CORRESPONDENCE_WEIGHT_ITEMS,
  CorrespondenceData,
  CorrespondenceWeightType,
  DEFAULT_SURVEY_COPYRIGHT,
  DisplayType,
  DocumentDataState,
  DocumentViewType,
  ExportParams,
  ExtendedCorrespondenceChart,
  HIGHLIGHT_COLORS,
  SelectMenuOption,
  Survey,
  SurveyCodeMap,
  SurveyTimeDocument,
  SurveyTimeDocumentCorrespondenceApp,
  Target,
  TITLE_MODES,
} from '../../models';
import {
  TupDocument,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import {
  CorrespondenceService,
  CrosstabService,
  DialogService,
  DocumentService,
  PptxService,
  TargetService,
  TitleLevelsService,
  TitleModeService,
} from '../../services';
import {
  CorrespondenceDataItem,
  CorrespondenceDataRowType,
  CorrespondenceFilterOptions,
  CorrespondenceSettings,
  CorrespondenceFeatureItem,
  CorrespondenceSortColumnType,
  CorrespondenceViewType,
  StartCorrespondenceResponseBody,
  CorrespondenceTableRowData,
  CorrespondenceTableDataMode,
  CrossTabTableData,
} from '../../models';
import { DocumentAudienceGroupItem } from '@telmar-global/tup-audience-groups';
import { TargetTitlePipe } from 'src/app/pipes';
import { cloneDeep, isEqual } from 'lodash';
import Highcharts, { ExportingMimeTypeValue, SVGElement } from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import Fullscreen from 'highcharts/modules/full-screen';
import ExportingLocal from 'highcharts/modules/offline-exporting';
import Annotations from 'highcharts/modules/annotations-advanced';
import StockTools from 'highcharts/modules/stock-tools';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { CorrespondenceAnalysisTableComponent } from 'src/app/components/correspondence-analysis-table/correspondence-analysis-table.component';
import { Router } from '@angular/router';
import { SurveyTimePptxBuilder } from '../../builders/surveytime-pptx.builder';
import html2canvas from 'html2canvas';
import { compare } from '../../utils/sortHelper';
import { TitleLevelsDialogResult } from '../../dialogs';
import { Sort } from '@angular/material/sort';
import { positionLabels } from '../../utils/chart-annotation-label-helper';

More(Highcharts);
Annotations(Highcharts);
StockTools(Highcharts);
Exporting(Highcharts);
Fullscreen(Highcharts);
ExportingLocal(Highcharts);

@Component({
  selector: 'app-correspondence-analysis',
  templateUrl: './correspondence-analysis.component.html',
  styleUrls: ['./correspondence-analysis.component.scss'],
})
export class CorrespondenceAnalysisComponent implements OnInit, OnDestroy {
  public readonly highlightColors = HIGHLIGHT_COLORS;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: any;
  public selectViewType: DocumentViewType = DocumentViewType.correspondence;
  public currentDoc: TupDocument<SurveyTimeDocument>;
  public surveys: Survey[] = [];
  private activeSurvey: Survey;
  private activeTable: Target;
  private previouslySelectedSurvey: Survey;
  public selectedCorrespondenceSurvey: Survey;
  private container: UserContainer;
  private columns: CorrespondenceFeatureItem[];
  private rows: CorrespondenceFeatureItem[];
  public isReadonly = true;
  public inProgress = true;
  public hasValidDataset = false;
  public crosstabData: CrossTabTableData[];

  public settings: CorrespondenceSettings;
  private dataSource: StartCorrespondenceResponseBody;
  public tableData: CorrespondenceTableRowData[] = [];

  public selectedViewResults: CorrespondenceViewType = 'chart';
  public dataModes: SelectMenuOption<CorrespondenceTableDataMode>[] =
    CORRESPONDENCE_TABLE_DATA_MODES;
  public selectedTableDataMode = CorrespondenceTableDataMode.absRel;
  public isChartStockToolsGUIEnabled = false;

  public dataModelSortMap: Record<CorrespondenceTableDataMode, Sort> = {
    [CorrespondenceTableDataMode.absRel]: {
      active: '',
      direction: '',
    },
    [CorrespondenceTableDataMode.factor]: {
      active: '',
      direction: '',
    },
  };

  private unsubscribe: Subject<void> = new Subject<void>();

  private chartRef: Highcharts.Chart;

  private maxX: number;
  private minX: number;
  private maxY: number;
  private minY: number;
  private offsetX: number;
  private offsetY: number;
  private maxOffsetX: number;
  private maxOffsetY: number;
  private referenceAxis: number = 0;
  public canResetZoom = false;
  public isBestFit = false;
  private annotationPositionMap: Record<number, AnnotationPosition> = {};

  public readonly titleModes = TITLE_MODES;
  public activeTitleMode: DisplayType;
  private titleLevels: number[];

  private filterOptions: CorrespondenceFilterOptions | null = null;
  public isFilterRowActive = true;

  @ViewChild(CorrespondenceAnalysisTableComponent, { static: false })
  caTableComponent: CorrespondenceAnalysisTableComponent;

  @ViewChild('reCorrespondenceConfirmation')
  reCorrespondenceConfirmation: TemplateRef<any>;

  public chartCallback: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      if (chart && chart.options) {
        chart.reflow();
        this.chartRef = chart as Highcharts.Chart;
      }
    }, 0);
  };

  constructor(
    private router: Router,
    private userContainerService: TupUserContainerService,
    private userMessageService: TupUserMessageService,
    private documentService: DocumentService,
    private authService: TupAuthService,
    private correspondenceService: CorrespondenceService,
    private targetTitlePipe: TargetTitlePipe,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private targetService: TargetService,
    private dialogService: DialogService,
    private crosstabService: CrosstabService,
    private elementRef: ElementRef,
    private pptxService: PptxService
  ) {
    this.isReadonly =
      !!this.router.getCurrentNavigation().extras?.state?.isReadonly;
  }

  ngOnInit(): void {
    this.listenToTitleModeAndLevelsChanges();
    this.listenToDocumentDataChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public clickTitleMode(displayType: DisplayType): void {
    if (displayType === DisplayType.levels) {
      this.openTitleLevelsDialog();
    }
  }

  public selectTitleMode(): void {
    if (this.activeTitleMode !== DisplayType.levels) {
      this.titleLevels = [];
      this.updateDataTitles();
    }
  }

  public onSelectedViewResultsChange(): void {
    this.updateViewResults(false);
  }

  public filterRows(): void {
    if (this.isFilterRowActive) {
      this.applyFilter(null);
      return;
    }

    this.dialogService
      .correspondenceFilterOptions(
        this.filterOptions || this.getDefaultFilterOptions()
      )
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: CorrespondenceFilterOptions) => {
        this.applyFilter(result);
      });
  }

  public toggleChartAnnotationMode() {
    this.isChartStockToolsGUIEnabled = !this.isChartStockToolsGUIEnabled;

    if (!this.isChartStockToolsGUIEnabled) {
      const activeHighchartsAnnotationElement =
        this.elementRef.nativeElement.querySelector('.highcharts-active');
      activeHighchartsAnnotationElement &&
        activeHighchartsAnnotationElement.click();
    }

    this.updateViewResults();
  }

  public openFullScreen(): void {
    this.chartRef.fullscreen.toggle();
  }

  public zoomOut(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min - this.offsetX,
      this.chartRef.xAxis[0].max + this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min - this.offsetY,
      this.chartRef.yAxis[0].max + this.offsetY
    );

    this.canResetZoom = true;
  }

  public zoomIn(): void {
    this.offStick();

    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min + this.offsetX,
      this.chartRef.xAxis[0].max - this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min + this.offsetY,
      this.chartRef.yAxis[0].max - this.offsetY
    );
    this.canResetZoom = true;
  }

  public moveLeft(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min - this.offsetX,
      this.chartRef.xAxis[0].max - this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveRight(): void {
    this.offStick();
    this.chartRef.xAxis[0].setExtremes(
      this.chartRef.xAxis[0].min + this.offsetX,
      this.chartRef.xAxis[0].max + this.offsetX
    );
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveUp(): void {
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min + this.offsetY,
      this.chartRef.yAxis[0].max + this.offsetY
    );
    this.chartRef.xAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public moveDown(): void {
    this.chartRef.yAxis[0].setExtremes(
      this.chartRef.yAxis[0].min - this.offsetY,
      this.chartRef.yAxis[0].max - this.offsetY
    );
    this.chartRef.xAxis[0].setExtremes();
    this.canResetZoom = true;
  }

  public resetZoom(shouldUpdateOptions = true): void {
    this.chartRef.xAxis[0].setExtremes();
    this.chartRef.yAxis[0].setExtremes();
    this.canResetZoom = false;
    this.chartRef.zoomOut();

    if (this.isBestFit) {
      this.isBestFit = false;
      if (shouldUpdateOptions) {
        this.setChartOptions(this.settings, this.tableData, true);
      }
    }
  }

  public bestFit(): void {
    this.resetZoom(false);

    this.isBestFit = true;
    this.setChartOptions(this.settings, this.tableData, true);
    this.canResetZoom = true;
  }

  public onTableDataChange(tableRow: CorrespondenceTableRowData) {
    if (
      [
        CorrespondenceDataRowType.activeColumn,
        CorrespondenceDataRowType.passiveColumn,
      ].includes(tableRow.type)
    ) {
      this.columns.filter((col) => col.coding === tableRow.coding)[0].visible =
        tableRow.visible;
    } else {
      this.rows.filter((col) => col.coding === tableRow.coding)[0].visible =
        tableRow.visible;
    }
  }

  public onTableSortChange(sort: Sort): void {
    this.dataModelSortMap[this.selectedTableDataMode] = sort;
  }

  public onSelectedSurveyChanged(): void {
    this.userMessageService
      .openDialog(
        'Changing the survey will trigger a re-analysis which will reset the current analysis, settings and selected variables and start a new calculation by using current rows & columns in the crosstab.',
        'Change survey',
        {
          cancelText: 'Cancel',
          confirmText: 'OK',
        }
      )
      .afterClosed()
      .subscribe((result: boolean | undefined) => {
        if (!result) {
          this.prepareSurveyData(this.previouslySelectedSurvey);
          return;
        }
        this.fetchAnalysisResults(
          this.prepareCorrespondenceAppData(this.selectedCorrespondenceSurvey),
          true,
          true
        );
      });
  }

  public toggleTableScores() {
    this.updateViewResults(true);
  }

  public openSettings() {
    const dataItem = this.settings.dataItem;
    this.dialogService
      .correspondenceSettings({
        viewType: this.selectedViewResults,
        settings: this.settings,
        resultRows: this.correspondenceService.formatSettingRows(
          this.dataSource
        ),
      })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((result: CorrespondenceSettings) => {
        if (!isEqual(this.settings, result)) {
          const shouldResetDataRows =
            this.settings.xAxisFactor !== result.xAxisFactor ||
            this.settings.yAxisFactor !== result.yAxisFactor ||
            this.settings.showHighlighting !== result.showHighlighting;
          this.settings = result;
          const correspondenceData = this.formatCorrespondentData();
          if (dataItem === result.dataItem) {
            this.updateViewResults(shouldResetDataRows);
            this.saveAnalysisToDoc(correspondenceData);
          } else {
            this.fetchAnalysisResults(correspondenceData);
          }
        }
      });
  }

  public clearHighlightSettings(): void {
    this.settings.showHighlighting = false;
    this.updateViewResults(true);
    const correspondenceData = this.formatCorrespondentData();
    this.saveAnalysisToDoc(correspondenceData);
  }

  public exportTo(
    type: ExportingMimeTypeValue,
    exportParams?: ExportParams
  ): void {
    if (this.inProgress || !this.dataSource) {
      return;
    }

    this.chartRef.exportChartLocal(
      {
        type,
        sourceWidth: 1280,
        sourceHeight: 720,
        scale: 3,
        ...(exportParams?.docName ? { filename: exportParams.docName } : {}),
      },
      {}
    );
  }

  private listenToTitleModeAndLevelsChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ columns, rows }: DocumentDataState) => {
        this.titleLevelsService.updateNumberOfTitleLevelsByTargets([
          ...columns,
          ...rows,
        ]);
      });
    this.titleModeService.titleMode$
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe((activeTitleMode: DisplayType) => {
        this.activeTitleMode = activeTitleMode;
      });

    this.titleLevelsService.titleLevels$
      .pipe(
        takeUntil(this.unsubscribe),
        filter((titleLevels: number[]) => !!titleLevels?.length)
      )
      .subscribe((titleLevels: number[]) => {
        this.titleLevels = titleLevels;
      });
  }

  private listenToDocumentDataChanges(): void {
    combineLatest([
      this.userContainerService.container.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.selectedSurvey$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.documentService.activeTablebase$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
      this.crosstabService.crossTabData$.pipe(
        isNotNullOrUndefined(),
        takeUntil(this.unsubscribe)
      ),
    ]).subscribe(
      ([container, survey, tablebase, crosstabData]: [
        UserContainer,
        Survey,
        Target,
        CrossTabTableData[]
      ]): void => {
        if (crosstabData.length > 0 && crosstabData[0].isPlaceholder) {
          return;
        }
        this.activeSurvey = survey;
        this.selectedCorrespondenceSurvey = survey;
        this.currentDoc = this.documentService.document;
        this.surveys = this.documentService.document.content?.surveys;
        this.activeTable = tablebase;
        this.container = container;
        this.isReadonly = !(
          this.authService.user.attributes.email ===
          this.currentDoc?.metadata?.by?.attributes?.email
        );
        this.crosstabData = crosstabData;
        this.loadAnalysis();
      }
    );
  }

  private composeDefaultCorrespondenceSettings(): CorrespondenceSettings {
    return {
      title: this.activeTable.title,
      xAxisFactor: 0,
      yAxisFactor: 1,
      showDataLabel: true,
      showAxisLabel: true,
      showLegend: true,
      showHighlighting: false,
      showWeightedSymbols: CorrespondenceWeightType.none,
      dataItem: CorrespondenceDataItem.audience,
      displayQuadrantTitles: false,
      quadrant1: '',
      quadrant2: '',
      quadrant3: '',
      quadrant4: '',
    };
  }

  private fetchAnalysisResults(
    data: SurveyTimeDocumentCorrespondenceApp,
    saveDoc: boolean = true,
    isSurveyChanged: boolean = false
  ): void {
    this.unsetAnnotationMap();
    this.inProgress = true;
    const activeColumns = data.columns.filter(
      (row: CorrespondenceFeatureItem) =>
        row.type === CorrespondenceDataRowType.activeColumn
    );
    const passiveColumns = data.columns.filter(
      (row: CorrespondenceFeatureItem) =>
        row.type === CorrespondenceDataRowType.passiveColumn
    );
    const activeRows = data.rows.filter(
      (row: CorrespondenceFeatureItem) =>
        row.type === CorrespondenceDataRowType.activeRow
    );
    const passiveRows = data.rows.filter(
      (row: CorrespondenceFeatureItem) =>
        row.type === CorrespondenceDataRowType.passiveRow
    );
    this.correspondenceService
      .getCorrespondenceData({
        'authorization-group': data.survey.authorizationGroup,
        'survey-code': data.survey.code,
        columns: activeColumns.map((row) => row.coding),
        rows: activeRows.map((row) => row.coding),
        table: this.activeTable?.coding || ALL_RESPONDENTS_CODING,
        'data-item': data.settings?.dataItem.toLowerCase(),
        'passive-rows': passiveRows.map((row) => row.coding),
        'passive-columns': passiveColumns.map((row) => row.coding),
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (result: StartCorrespondenceResponseBody) => {
          this.dataSource = {
            ...result,
            'row-coords': result['row-coords'].concat(
              result['passive-row-coords'] || []
            ),
            'row-abs-contribution': result['row-abs-contribution'].concat(
              result['passive-row-abs-contribution'] || []
            ),
            'row-rel-contribution': result['row-rel-contribution'].concat(
              result['passive-row-rel-contribution'] || []
            ),
            'row-pct-inf': result['row-pct-inf'].concat(
              result['passive-row-pct-inf'] || []
            ),
            'col-coords': result['col-coords'].concat(
              result['passive-col-coords'] || []
            ),
            'col-abs-contribution': result['col-abs-contribution'].concat(
              result['passive-col-abs-contribution'] || []
            ),
            'col-rel-contribution': result['col-rel-contribution'].concat(
              result['passive-col-rel-contribution'] || []
            ),
            'col-pct-inf': result['col-pct-inf'].concat(
              result['passive-col-pct-inf'] || []
            ),
          };

          saveDoc && this.saveAnalysisToDoc(data, true);
          this.prepareSurveyData(data.survey);
          this.activeTitleMode = data.activeTitleMode || this.activeTitleMode;
          this.titleLevels = data.titleLevels || this.titleLevels;
          this.rows = data.rows;
          this.columns = data.columns;
          this.settings = data.settings;
          this.settings.title = this.activeTable.title;
          this.filterOptions = data.filterOptions;
          this.isFilterRowActive = !!this.filterOptions;
          this.turnOffChartAnnotationMode();

          this.inProgress = false;
          this.updateViewResults(true);
        },
        (error) => {
          isSurveyChanged &&
            this.prepareSurveyData(this.previouslySelectedSurvey);
          this.inProgress = false;
        }
      );
  }

  private updateViewResults(shouldResetData: boolean = false): void {
    if (shouldResetData) {
      this.formatViewData(this.dataSource);
    }
    if (this.selectedViewResults === 'chart') {
      this.setChartOptions(this.settings, this.tableData, true);
    } else {
      this.chartOptions = null;
      this.turnOffChartAnnotationMode();
    }
  }

  private saveAnalysisToDoc(
    data: SurveyTimeDocumentCorrespondenceApp,
    shouldUnsetRestoreDocumentState?: boolean
  ): void {
    const docToSave = cloneDeep(this.currentDoc);
    const correspondence = {
      settings: data.settings,
      filterOptions: data.filterOptions,
      survey: data.survey,
      version: CA_VERSION,
      rows: cloneDeep(data.rows),
      columns: cloneDeep(data.columns),
      activeTitleMode: data.activeTitleMode,
      titleLevels: data.titleLevels,
    };

    if (docToSave.content?.apps) {
      const apps = docToSave.content?.apps;
      apps.correspondence = correspondence;
    } else {
      docToSave.content.apps = { correspondence };
    }

    this.currentDoc = docToSave;
    this.documentService.updateDocumentApps(
      docToSave,
      shouldUnsetRestoreDocumentState
    );
  }

  private loadAnalysis(): void {
    if (this.currentDoc.content.apps?.correspondence) {
      this.hasValidDataset = true;
      this.fetchAnalysisResults(
        this.currentDoc.content.apps
          .correspondence as SurveyTimeDocumentCorrespondenceApp,
        false
      );
    } else if (!this.isReadonly) {
      this.hasValidDataset =
        this.currentDoc.content?.rows.length > 1 &&
        this.currentDoc.content?.columns.length > 1;
      if (this.hasValidDataset) {
        this.fetchAnalysisResults(this.prepareCorrespondenceAppData());
      } else {
        this.inProgress = false;
      }
    } else {
      this.inProgress = false;
    }
  }

  private prepareCorrespondenceAppData(survey?: Survey) {
    const rows = this.currentDoc.content.rows.map((row) => ({
      ...this.convertTargetToAudienceGroupItem(row),
      type: CorrespondenceDataRowType.activeRow,
      visible: true,
    }));
    const columns = this.currentDoc.content.columns.map((column) => ({
      ...this.convertTargetToAudienceGroupItem(column),
      type: CorrespondenceDataRowType.activeColumn,
      visible: true,
    }));
    const filterOptions = this.getDefaultFilterOptions();
    const settings = this.composeDefaultCorrespondenceSettings();

    return {
      survey: survey || this.activeSurvey,
      rows,
      columns,
      settings,
      filterOptions,
      activeTitleMode: this.activeTitleMode,
      titleLevels: this.titleLevels,
      version: CA_VERSION,
    };
  }

  private setChartOptions(
    settings: CorrespondenceSettings,
    data: CorrespondenceTableRowData[],
    shouldReload = false
  ): void {
    const {
      title,
      showAxisLabel,
      showDataLabel,
      showLegend,
      xAxisFactor,
      yAxisFactor,
      showWeightedSymbols,
      displayQuadrantTitles,
      quadrant1,
      quadrant2,
      quadrant3,
      quadrant4,
      dataItem,
    } = settings;

    this.getXYRange(data.filter((row) => row.visible));

    const factor1 = String(
      this.correspondenceService.roundToTwo(
        this.dataSource['p-exp-vars'][xAxisFactor]
      )
    );
    const factor2 = String(
      this.correspondenceService.roundToTwo(
        this.dataSource['p-exp-vars'][yAxisFactor]
      )
    );
    const subtitle = `Factor ${xAxisFactor + 1}=${factor1} Factor ${
      yAxisFactor + 1
    }=${factor2}`;

    const series: any = this.getChartSeries(data);
    const annotations = data.map((point) => {
      return {
        visible: showDataLabel && point.visible,
        pointId: point.id,
        checkPosition: true,
        customPosition: false,
        labelOptions: {
          allowOverlap: true,
          align: 'left',
          verticalAlign: 'top',
          backgroundColor: 'white',
          borderWidth: 0.2,
          shape: 'connector',
          x:
            point.id in this.annotationPositionMap
              ? this.annotationPositionMap[point.id].x
              : 15,
          y:
            point.id in this.annotationPositionMap
              ? this.annotationPositionMap[point.id].y
              : -12,
        },
        group: point.type,
        pointVisible: point.visible,
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: point.x,
              y: point.y,
            },
            text: point.title,
          },
        ],
        events: {
          drag() {
            this.chart.userOptions.annotationPositionMap[point.id] = {
              x: this.labels[0].options.x,
              y: this.labels[0].options.y,
            };
          },
        },
      };
    });

    if (this.chartOptions) {
      while (this.chartRef.series?.length) {
        this.chartRef.series[0].remove();
      }
      series.forEach((item) => {
        this.chartRef?.addSeries(item);
      });
    }

    if (shouldReload) {
      // to keep the added annotations such as additional lines and texts
      // @ts-ignore
      const additionalAnnotations = this.chartOptions?.annotations.filter(
        (annotation) => !annotation.checkPosition
      );
      if (additionalAnnotations) {
        annotations.push(...additionalAnnotations);
      }
      this.chartOptions = null;
    }

    setTimeout(() => {
      this.chartOptions = {
        annotationPositionMap: this.annotationPositionMap,

        exporting: {
          enabled: false,
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  draggable: true,
                  style: {
                    textOutline: 'none',
                  },
                },
              },
            },
          },
        },
        stockTools: {
          gui: {
            enabled: this.isChartStockToolsGUIEnabled,
            buttons: ['lines', 'inputText'],
            definitions: {
              inputText: {
                className: 'highcharts-inputText-annotation',
                symbol: 'label.svg',
              },
            },
          },
        },

        navigation: {
          bindings: {
            inputText: {
              className: 'highcharts-inputText-annotation',
              start: function (e) {
                const coords = this.chart.pointer.getCoordinates(e),
                  navigation = this.chart.options.navigation,
                  controlPoints = [
                    {
                      positioner: function (target) {
                        if (!target.graphic.placed) {
                          return {
                            x: 0,
                            y: -9e7,
                          };
                        }
                        return {
                          x:
                            target.graphic.alignAttr.x - this.graphic.width / 2,
                          y:
                            target.graphic.alignAttr.y -
                            this.graphic.height / 2,
                        };
                      },
                      events: {
                        drag: function (e, target) {
                          const xy = this.mouseMoveToTranslation(e);
                          target.translate(xy.x, xy.y);
                          target.annotation.labels[0].options = target.options;
                          target.redraw(false);
                        },
                      },
                    },
                  ];
                const _self = this;
                const labelTextForm = document.getElementById('labelTextForm');
                const onclick = function () {
                  _self.chart.addAnnotation(
                    Highcharts.merge(
                      {
                        langKey: 'label',
                        labelOptions: {
                          format: (
                            labelTextForm.querySelector(
                              '#labelTextInput'
                            ) as any
                          ).value,
                          shape: 'rect',
                        },
                        labels: [
                          {
                            point: {
                              xAxis: 0,
                              yAxis: 0,
                              x: coords.xAxis[0].value,
                              y: coords.yAxis[0].value,
                            },
                            overflow: 'none',
                            crop: true,
                            controlPoints,
                          },
                        ],
                      },
                      navigation.annotationsOptions,
                      navigation.bindings.labelAnnotation.annotationsOptions
                    )
                  );
                };

                Highcharts.css(labelTextForm, {
                  top: e.pageY + 15 + 'px',
                  left: e.pageX + 'px',
                  display: 'block',
                });

                const unbinder = Highcharts.addEvent(
                  labelTextForm.querySelector('#labelTextButton'),
                  'click',
                  function () {
                    onclick();

                    Highcharts.css(labelTextForm, {
                      display: 'none',
                    });

                    unbinder();
                  }
                );

                return true;
              },
            },
          },
        },

        credits: {
          enabled: false,
        },

        chart: {
          type: 'bubble',
          plotBorderWidth: 1,
          zoomType: 'xy',
          events: {
            render() {
              const chart = this as ExtendedCorrespondenceChart;
              chart.quadrantTitles?.forEach((textElement: SVGElement) => {
                textElement.destroy();
              });

              if (!chart.options.quadrantTitles.enabled) {
                chart.quadrantTitles = [];
                return;
              }

              const offset = 10;
              const fontSize = 12;
              const width = chart.plotLeft + chart.plotWidth;
              const height = chart.plotTop + chart.plotHeight;
              const left = chart.plotLeft + offset;
              const right = width - offset;
              const top = chart.plotTop + fontSize + offset;
              const bottom = height - offset;
              chart.quadrantTitles = [
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant1, right, top)
                  .add(),
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant2, left, top)
                  .add(),
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant3, left, bottom)
                  .add(),
                chart.renderer
                  .text(chart.options.quadrantTitles.quadrant4, right, bottom)
                  .add(),
              ];
              [chart.quadrantTitles[0], chart.quadrantTitles[3]].forEach(
                (element: SVGElement) => {
                  element.attr({
                    x: right - element.getBBox().width,
                  });
                }
              );
            },
            load() {
              if (showDataLabel) {
                positionLabels(this);
              }
            },
          },
        },

        caption: {
          text: this.getCopyrightHTML(),
        },

        quadrantTitles: {
          enabled: displayQuadrantTitles,
          quadrant1,
          quadrant2,
          quadrant3,
          quadrant4,
        },

        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          enabled: showLegend,
        },

        title: {
          text: this.settings?.title || title,
        },

        subtitle: {
          text: subtitle,
        },

        xAxis: {
          maxPadding: 0,
          minPadding: 0,
          startOnTick: true,
          endOnTick: true,
          max: this.isBestFit
            ? this.maxX + this.offsetX / 20
            : this.referenceAxis + this.maxOffsetX + this.offsetX / 10,
          min: this.isBestFit
            ? this.minX - this.offsetX / 20
            : this.referenceAxis - this.maxOffsetX - this.offsetX / 10,
          crosshair: true,
          gridLineWidth: 0,
          tickLength: 0,
          title: {
            text: '',
          },
          labels: {
            format: '{value}',
            enabled: showAxisLabel,
          },
          plotLines: [
            {
              color: 'black',
              dashStyle: 'Dot',
              width: 2,
              value: this.referenceAxis,
              zIndex: 3,
            },
          ],
        },

        yAxis: {
          crosshair: true,
          maxPadding: 0,
          minPadding: 0,
          startOnTick: true,
          endOnTick: true,
          max: this.isBestFit
            ? this.maxY + this.offsetY / 20
            : this.referenceAxis + this.maxOffsetY + this.offsetY / 10,
          min: this.isBestFit
            ? this.minY - this.offsetY / 20
            : this.referenceAxis - this.maxOffsetY - this.offsetY / 10,
          gridLineWidth: 0,
          tickLength: 0,
          title: {
            text: '',
          },
          labels: {
            format: '{value}',
            enabled: showAxisLabel,
          },
          plotLines: [
            {
              color: 'black',
              dashStyle: 'Dot',
              width: 2,
              value: this.referenceAxis,
              zIndex: 3,
            },
          ],
        },

        tooltip: {
          useHTML: true,
          headerFormat: '<table>',
          pointFormat: this.tooltipText(showWeightedSymbols, dataItem),
          footerFormat: '</table>',
          followPointer: true,
        },

        plotOptions: {
          bubble: {
            maxSize: this.getWeightedSymbolsValue(showWeightedSymbols),
            minSize: this.getWeightedSymbolsValue(showWeightedSymbols),
          },
        },

        series,
        annotations,
      };
    }, 0);
  }

  private tooltipText(
    showWeightedSymbols: CorrespondenceWeightType,
    dataItem: CorrespondenceDataItem
  ): string {
    let text =
      '<tr><td><h3>{point.title}</h3></td></tr>' +
      '<tr><td>x: {point.x}</td></tr>' +
      '<tr><td>y: {point.y}</td></tr>';
    switch (showWeightedSymbols) {
      case CorrespondenceWeightType.INF:
        text =
          text +
          '<tr><td>weight (' +
          CORRESPONDENCE_WEIGHT_ITEMS[1].title +
          '): {point.z:.2f}</td></tr>';
        break;
      case CorrespondenceWeightType.dataItem:
        text =
          text + '<tr><td>weight (' + dataItem + '): {point.z:.2f}</td></tr>';
        break;
      default:
        break;
    }

    return text;
  }

  private getWeightedSymbolsValue(
    showWeightedSymbols: CorrespondenceWeightType
  ): number {
    return showWeightedSymbols !== CorrespondenceWeightType.none
      ? undefined
      : 10;
  }

  private toggleAnnotation(annotations: any, key: string, visible: boolean) {
    annotations.forEach((annotation) => {
      this.annotationPositionMap[annotation.pointId] = {
        x: annotation.labels[0].options.x,
        y: annotation.labels[0].options.y,
      };
      if (
        annotation.options.pointVisible &&
        annotation.options.group === +key
      ) {
        annotation.update({
          visible,
        });
      }
    });
  }

  private getChartSeries(data: CorrespondenceTableRowData[]) {
    const seriesArray = data.reduce(
      (acc, row) => {
        acc[row.type].push(row);
        return acc;
      },
      {
        [CorrespondenceDataRowType.activeColumn]: [],
        [CorrespondenceDataRowType.activeRow]: [],
        [CorrespondenceDataRowType.passiveColumn]: [],
        [CorrespondenceDataRowType.passiveRow]: [],
      }
    );

    return Object.keys(seriesArray)
      .filter((key) => seriesArray[key].length > 0)
      .map((key) => {
        const chartItem = CORRESPONDENCE_DATA_ROW_TYPE_CHART_ITEMS.filter(
          (item) => item.id === Number(key)
        )[0];
        return {
          type: 'bubble',
          name: chartItem.displayName,
          color: chartItem.color,
          data: seriesArray[key],
          events: {
            hide: (event: any) => {
              this.toggleAnnotation(event.target.chart.annotations, key, false);
            },
            show: (event: any) => {
              this.toggleAnnotation(event.target.chart.annotations, key, true);
              if (this.settings.showDataLabel) {
                positionLabels(event.target.chart);
              }
            },
          },
        };
      });
  }

  private getXYRange(data): void {
    const xArray = data.map((d) => d.x);
    const yArray = data.map((d) => d.y);
    this.maxX = Math.max(...xArray);
    this.minX = Math.min(...xArray);

    this.maxY = Math.max(...yArray);
    this.minY = Math.min(...yArray);

    this.offsetX = (this.maxX - this.minX) / 10;
    this.offsetY = (this.maxY - this.minY) / 10;

    this.maxOffsetX =
      Math.abs(this.maxX - this.referenceAxis) >
      Math.abs(this.minX - this.referenceAxis)
        ? Math.abs(this.maxX - this.referenceAxis)
        : Math.abs(this.minX - this.referenceAxis);
    this.maxOffsetY =
      Math.abs(this.maxY - this.referenceAxis) >
      Math.abs(this.minY - this.referenceAxis)
        ? Math.abs(this.maxY - this.referenceAxis)
        : Math.abs(this.minY - this.referenceAxis);
  }

  private offStick(): void {
    this.chartRef.xAxis[0].options.startOnTick = false;
    this.chartRef.xAxis[0].options.endOnTick = false;
    this.chartRef.yAxis[0].options.startOnTick = false;
    this.chartRef.yAxis[0].options.endOnTick = false;
  }

  private getCopyrightHTML(): string {
    const surveyProvider =
      this.documentService.activeSurvey.meta['survey-provider'];
    const surveyCopyright =
      this.documentService.activeSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `<span>Survey provided by: <b>${surveyProvider}.</b></span> <b>${surveyCopyright}</b>`;
  }

  private formatViewData(correspondenceData: StartCorrespondenceResponseBody) {
    const infColumnArray = [
      correspondenceData['col-pct-inf'].concat(
        correspondenceData['row-pct-inf']
      ),
    ];

    const absTransposedCorrespondenceData =
      this.correspondenceService.transposeCorrespondenceData(
        correspondenceData['col-abs-contribution']
          .concat(correspondenceData['row-abs-contribution'])
          .concat(infColumnArray),
        this.settings.showHighlighting
      );

    const relTransposedCorrespondenceData =
      this.correspondenceService.transposeCorrespondenceData(
        correspondenceData['col-rel-contribution']
          .concat(correspondenceData['row-rel-contribution'])
          .concat(infColumnArray),
        this.settings.showHighlighting
      );

    const factorTransposedCorrespondenceData =
      this.correspondenceService.transposeCorrespondenceData(
        correspondenceData['col-coords']
          .concat(correspondenceData['row-coords'])
          .concat(infColumnArray),
        this.settings.showHighlighting
      );

    this.tableData = this.correspondenceService.formatTableRows(
      {
        columns: cloneDeep(this.columns).sort((a, b) =>
          compare(a.type, b.type, true)
        ),
        colCoords: correspondenceData['col-coords'],
        colINFs: correspondenceData['col-pct-inf'],
        colAbsContributions: correspondenceData['col-abs-contribution'],
        colRelContributions: correspondenceData['col-rel-contribution'],
        rows: cloneDeep(this.rows).sort((a, b) =>
          compare(a.type, b.type, true)
        ),
        rowCoords: correspondenceData['row-coords'],
        rowINFs: correspondenceData['row-pct-inf'],
        rowAbsContributions: correspondenceData['row-abs-contribution'],
        rowRelContributions: correspondenceData['row-rel-contribution'],
      },
      this.settings,
      this.filterOptions,
      this.crosstabData,
      this.selectedCorrespondenceSurvey.code
    );

    if (absTransposedCorrespondenceData && factorTransposedCorrespondenceData) {
      this.tableData = this.tableData.map((row, index) => ({
        ...row,
        segments: {
          absContribution: absTransposedCorrespondenceData.map(
            (absHighlights) => absHighlights[index]
          ),
          relContribution: relTransposedCorrespondenceData.map(
            (relHighlights) => relHighlights[index]
          ),
          coordinates: factorTransposedCorrespondenceData.map(
            (factorHighlights) => factorHighlights[index]
          ),
        },
      }));
    }
  }

  private convertTargetToAudienceGroupItem(
    target: Target
  ): DocumentAudienceGroupItem {
    return {
      title: this.targetTitlePipe.transform(
        target,
        this.activeTitleMode,
        this.titleLevels
      ),
      coding: target.coding,
      options: {
        statement: null,
        target: this.targetService.shallowCopyTarget(target),
      },
    };
  }

  private applyFilter(filterOptions: CorrespondenceFilterOptions | null): void {
    this.filterOptions = filterOptions;
    this.isFilterRowActive = !this.isFilterRowActive;
    const correspondenceData = {
      survey: this.selectedCorrespondenceSurvey,
      rows: this.rows,
      columns: this.columns,
      settings: this.settings,
      filterOptions: this.filterOptions,
      activeTitleMode: this.activeTitleMode,
      titleLevels: this.titleLevels,
    };

    this.saveAnalysisToDoc(
      correspondenceData as SurveyTimeDocumentCorrespondenceApp
    );
    this.updateViewResults(true);
  }

  private getDefaultFilterOptions(): CorrespondenceFilterOptions {
    return {
      visibleColumns: this.currentDoc.content.columns.length,
      visibleRows: 20,
      allColumns: true,
      allRows: false,
      sortBy: CorrespondenceSortColumnType.percentINF,
    };
  }

  private prepareSurveyData(survey: Survey): void {
    this.selectedCorrespondenceSurvey =
      this.surveys.find(
        (surveyItem: Survey) =>
          surveyItem.code === survey.code &&
          surveyItem.authorizationGroup === survey.authorizationGroup
      ) || survey;
    this.updatePreviouslySelectedSurvey(this.selectedCorrespondenceSurvey);
    this.surveys = this.getEligibleSurveys();
  }

  private updatePreviouslySelectedSurvey(survey: Survey): void {
    this.previouslySelectedSurvey = survey;
  }

  private getEligibleSurveys() {
    return [
      ...this.surveys?.filter(
        (survey) => survey.code !== this.selectedCorrespondenceSurvey?.code
      ),
      this.selectedCorrespondenceSurvey,
    ];
  }

  exportToCsv(exportParams?: ExportParams) {
    this.caTableComponent.exportToCsv(exportParams?.docName);
  }

  exportToXlsx(exportParams?: ExportParams) {
    this.caTableComponent.exportToXlsx(exportParams?.docName);
  }

  exportToSheets(exportParams?: ExportParams) {
    this.caTableComponent.exportToSheets(exportParams?.docName);
  }

  public exportToPptx(exportParams?: ExportParams) {
    const chartContainer = document.getElementsByClassName(
      'highcharts-container'
    )[0] as HTMLElement;
    html2canvas(chartContainer).then((canvas) => {
      const imageBlob = canvas.toDataURL('image/png');
      const documentName = exportParams?.docName || this.settings.title;
      const builder = new SurveyTimePptxBuilder(this.userMessageService);
      builder.exportStatsAppToPptx(
        null,
        [imageBlob],
        documentName,
        'Correspondence',
        this.getSurveyCopyrightText()
      );
      this.pptxService.saveAs(builder, documentName);
    });
  }

  private getSurveyCopyrightText(): string {
    let surveyCodeMap;
    this.documentService.surveyCodeMap$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((map: SurveyCodeMap) => {
        surveyCodeMap = map;
      });
    const { code, title } = this.documentService.activeSurvey;
    const surveyTitle = [surveyCodeMap[code], title].join(' - ');
    const surveyCopyright =
      this.documentService.activeSurvey.meta['copyright-info'] ||
      DEFAULT_SURVEY_COPYRIGHT;
    return `${surveyTitle} - ${surveyCopyright}`;
  }

  public onReAnalysis() {
    this.userMessageService
      .openCustomMessageDialog(
        this.reCorrespondenceConfirmation,
        'Re-analysis confirmation',
        {
          confirmText: 'Re-analyse',
          centered: true,
          width: '400px',
        }
      )
      .afterClosed()
      .subscribe((confirmed) => {
        confirmed &&
          this.fetchAnalysisResults(this.prepareCorrespondenceAppData());
      });
  }

  private getCorrespondenceData(): CorrespondenceData {
    return {
      rows: this.rows,
      columns: this.columns,
    };
  }

  public openActivePassive(): void {
    const { rows, columns } = this.getCorrespondenceData();
    this.dialogService
      .correspondenceActivePassive({ rows, columns })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe(
        (result: {
          rows: Record<number, boolean>;
          columns: Record<number, boolean>;
        }) => {
          if (result) {
            const data = this.prepareCorrespondenceAppData();

            data.rows = rows.map((row, index) => ({
              ...row,
              type: result.rows[index]
                ? CorrespondenceDataRowType.activeRow
                : CorrespondenceDataRowType.passiveRow,
            }));

            data.columns = columns.map((row, index) => ({
              ...row,
              type: result.columns[index]
                ? CorrespondenceDataRowType.activeColumn
                : CorrespondenceDataRowType.passiveColumn,
            }));

            this.fetchAnalysisResults(data);
          }
        }
      );
  }

  private formatCorrespondentData(): SurveyTimeDocumentCorrespondenceApp {
    return {
      survey: this.selectedCorrespondenceSurvey,
      rows: this.rows,
      columns: this.columns,
      settings: this.settings,
      filterOptions: this.filterOptions,
      activeTitleMode: this.activeTitleMode,
      titleLevels: this.titleLevels,
      version: CA_VERSION,
    };
  }

  private turnOffChartAnnotationMode() {
    this.isChartStockToolsGUIEnabled = false;
  }

  private openTitleLevelsDialog(): void {
    this.titleLevelsService
      .openRawDialog({ titleLevels: this.titleLevels })
      .subscribe((dialogResult: TitleLevelsDialogResult) => {
        this.titleLevels = dialogResult?.titleLevels || [];
        this.updateDataTitles();
      });
  }

  private updateDataTitles(): void {
    this.columns = this.columns.map((item) => ({
      ...item,
      title: this.targetTitlePipe.transform(
        item.options.target,
        this.activeTitleMode,
        this.titleLevels
      ),
    }));
    this.rows = this.rows.map((item) => ({
      ...item,
      title: this.targetTitlePipe.transform(
        item.options.target,
        this.activeTitleMode,
        this.titleLevels
      ),
    }));
    const correspondenceData = this.formatCorrespondentData();
    this.updateViewResults(true);
    this.saveAnalysisToDoc(correspondenceData);
  }

  private unsetAnnotationMap(): void {
    this.annotationPositionMap = {};
  }
}
