<div
  class="dialog-content-container"
  fxLayout="column"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Save cluster solution codes"
    class="save-cluster-codes-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
      class="save-cluster-codes-content"
    >
      <form
        [formGroup]="formGroup"
        fxLayout="column"
        class="save-cluster-codes-form-field"
      >
        <span>{{ survey.title }}</span>
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFill
          class="display-text-field"
        >
          <mat-label>Solution name</mat-label>
          <input
            type="text"
            required
            matInput
            formControlName="name"
          />
          <mat-error
            *ngIf="formGroup.get('name').errors?.required"
            class="save-cluster-codes__name-error"
            >Please enter name</mat-error
          >
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="input-form-field"
          matTooltip="Select solution"
          tupMatFormField
          fxFlex
        >
          <mat-label>Select solution</mat-label>
          <mat-select
            [(ngModel)]="selectedClustersSolutionNumber"
            formControlName="selectedSolution"
            (ngModelChange)="onSelectedSolutionChange()"
          >
            <mat-option
              [value]="solution.key"
              *ngFor="let solution of clustersSolutionTypeList"
            >
              {{ solution.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          class="variables-checkbox"
        >
          <mat-checkbox
            fxFlex="100"
            tupMatCheckbox
            appearance="small"
            color="primary"
            formControlName="saveVariables"
            class="tiny-checkbox margin-adjustment"
          >
            Save solution variables as well
          </mat-checkbox>
        </div>

        <tup-user-container-selector
          #userContainerSelector
          [options]="{ showSubheader: true, showDivider: true }"
          (selectionChange)="selectionChange($event)"
        >
        </tup-user-container-selector>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="saveCodes()"
      data-qa="generate-button"
      [disabled]="formGroup.get('name').errors?.required"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
