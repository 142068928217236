import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import {
  PlanTargetCodingResponse,
  Survey,
  Target,
  TargetType,
} from '../../models';
import { TargetService } from '../../services/target.service';
import { DocumentService } from '../../services/document.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';

export interface SendToPlanDialogDataModel {
  targetGroups: SendToPlanTargetGroup[];
  survey: Survey;
  maxTargets?: number;
}

export interface SendToPlanDialogResultDataModel {
  success: boolean;
}

export interface SendToPlanTargetGroup {
  title: string;
  items: TargetGroupItem[];
}

// each item within the given group
export interface TargetGroupItem {
  target: Target;
  selected: boolean;
  groupIndex?: number;
  itemIndex?: number;
}

export const DEFAULT_MAX_TARGETS = 10;

@Component({
  templateUrl: './send-to-plan-dialog.component.html',
  styleUrls: ['./send-to-plan-dialog.component.scss'],
})
export class SendToPlanDialogComponent implements OnInit, OnDestroy {
  public maxTargets: number;
  public isLoading = false;
  public selectedTab = 0;
  public selectedAudiences: TargetGroupItem[] = [];
  public errors: {
    [key: string]: string;
  } = {};

  private unsubscribe: Subject<void> = new Subject<void>();

  private updateFlagState = new BehaviorSubject<TargetGroupItem[]>(null);
  private updateFlagState$ = this.updateFlagState.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SendToPlanDialogDataModel,
    public dialogRef: MatDialogRef<
      SendToPlanDialogComponent,
      SendToPlanDialogResultDataModel
    >,
    private messageService: TupUserMessageService,
    private targetService: TargetService,
    private documentService: DocumentService
  ) {
    this.maxTargets = data.maxTargets || DEFAULT_MAX_TARGETS;
  }

  ngOnInit(): void {
    this.setupSelectedAudiences();
    this.listenToSaveFlagsStateChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onSelectedTabChanged(tab: MatTabChangeEvent): void {
    this.selectedTab = tab.index;
    this.triggerFakeWindowResizeEvent();
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  public onSend(): void {
    const targets: Target[] = this.getSelectedTargetGroupItems();
    this.isLoading = true;
    this.targetService
      .validatePlanCodingStatement(this.data.survey, targets)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: PlanTargetCodingResponse) => {
        this.isLoading = false;
        if (data.errors?.length > 0) {
          this.selectedTab = 0;
          this.errors = data.errors.reduce(
            (acc, value) => ({
              ...acc,
              [value.targetId]: value.error,
            }),
            {}
          );
          this.messageService.openMessageDialog(
            `You have ${data.errors.length} incompatible selected audience${
              data.errors.length === 1 ? '' : 's'
            }.`,
            'Alert'
          );
        } else {
          this.dialogRef.close({
            success: true,
          });
        }
      });
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  public toggleTargetGroupItem(groupIndex: number, itemIndex: number): void {
    const targetGroupItem = {
      ...this.data.targetGroups[groupIndex].items[itemIndex],
      groupIndex,
      itemIndex,
    };
    const selected = targetGroupItem.selected;
    if (selected) {
      this.selectedAudiences.push(targetGroupItem);
      this.saveFlags();
    } else {
      this.onDeleteSelectedAudience(targetGroupItem);
    }
    if (this.selectedAudiences.length === this.maxTargets) {
      this.messageService.openMessageDialog(
        `The maximum of ${this.maxTargets} audiences have been selected`,
        'Alert'
      );
    }
  }

  public onDeleteSelectedAudience(selectedItem: TargetGroupItem): void {
    this.data.targetGroups[selectedItem.groupIndex].items[
      selectedItem.itemIndex
    ].selected = false;
    this.selectedAudiences = this.selectedAudiences.filter(
      (item) =>
        !(
          item.groupIndex === selectedItem.groupIndex &&
          item.itemIndex === selectedItem.itemIndex
        )
    );
    this.saveFlags();
  }

  private listenToSaveFlagsStateChanges(): void {
    this.updateFlagState$
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(500),
        isNotNullOrUndefined()
      )
      .subscribe((data: TargetGroupItem[]) => {
        this.documentService.flagDocumentData(data);
      });
  }

  private setupSelectedAudiences(): void {
    const audiences = [];
    this.data.targetGroups.forEach((group, groupIndex) => {
      group.items.forEach((item, itemIndex) => {
        if (item.selected) {
          audiences.push({
            ...item,
            groupIndex,
            itemIndex,
          });
        }
      });
    });
    this.selectedAudiences = audiences;
  }

  private getSelectedTargetGroupItems(): Target[] {
    return this.selectedAudiences.map((item) => item.target);
  }

  private saveFlags(): void {
    this.updateFlagState.next(this.selectedAudiences);
  }
}
