<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Correspondence settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close
    </mat-icon>
  </tup-mat-title-bar>

  <mat-dialog-content>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Correspondence title</mat-label>
        <input
          matInput
          [(ngModel)]="settings.title"
        />
      </mat-form-field>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">Data preferences</h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="margin-adjustment"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Data item</mat-label>
        <mat-select [(ngModel)]="settings.dataItem">
          <mat-option
            *ngFor="let dataItem of dataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxFlex></div>
    </div>

    <ng-container *ngIf="viewType === 'chart'">
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">Chart view set up</h3>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="margin-adjustment"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>X-Axis Factor</mat-label>
          <mat-select [(ngModel)]="settings.xAxisFactor">
            <mat-option
              *ngFor="let factor of factors"
              [value]="factor"
            >
              Factor {{ factor + 1 }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Y-Axis actor</mat-label>
          <mat-select [(ngModel)]="settings.yAxisFactor">
            <mat-option
              *ngFor="let factor of factors"
              [value]="factor"
            >
              Factor {{ factor + 1 }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="margin-adjustment"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          class="input-form-field"
        >
          <mat-label>Show weights</mat-label>
          <mat-select [(ngModel)]="settings.showWeightedSymbols">
            <mat-option
              *ngFor="let item of weightItems"
              [value]="item.id"
            >
              {{ item.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxFlex></div>
      </div>
    </ng-container>

    <ng-container *ngIf="viewType === 'chart'">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.showDataLabel"
        >
          Show data label
        </mat-checkbox>

        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.showAxisLabel"
        >
          Show axis label
        </mat-checkbox>

        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.showLegend"
        >
          Show legend
        </mat-checkbox>
      </div>
      <div class="quadrant-checkbox">
        <mat-checkbox
          fxFlex="100"
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox margin-adjustment"
          [(ngModel)]="settings.displayQuadrantTitles"
        >
          Display quadrant titles
        </mat-checkbox>
      </div>

      <ng-container *ngIf="settings.displayQuadrantTitles">
        <mat-divider class="settings-divider"></mat-divider>
        <h3 class="setting-section-title margin-adjustment">Quadrant titles</h3>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          class="margin-adjustment"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field"
          >
            <mat-label>Quadrant 2</mat-label>
            <input
              matInput
              [(ngModel)]="settings.quadrant2"
            />
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field"
          >
            <mat-label>Quadrant 1</mat-label>
            <input
              matInput
              [(ngModel)]="settings.quadrant1"
            />
          </mat-form-field>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          class="margin-adjustment"
        >
          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field"
          >
            <mat-label>Quadrant 3</mat-label>
            <input
              matInput
              [(ngModel)]="settings.quadrant3"
            />
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            tupMatFormField
            fxFlex
            class="input-form-field"
          >
            <mat-label>Quadrant 4</mat-label>
            <input
              matInput
              [(ngModel)]="settings.quadrant4"
            />
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="viewType === 'table'">
      <mat-divider class="settings-divider"></mat-divider>

      <h3 class="setting-section-title margin-adjustment">
        Table highlighting preferences
      </h3>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <mat-checkbox
          fxFlex
          tupMatCheckbox
          appearance="small"
          color="primary"
          class="tiny-checkbox"
          [(ngModel)]="settings.showHighlighting"
        >
          Show highlighting
        </mat-checkbox>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          fxFlex="100"
        >
          <span
            class="highlight-color-dot-container"
            *ngFor="let color of highlightColors"
          >
            <span
              class="highlight-color-dot"
              [style.background-color]="color.background"
            ></span>
            {{ color.label }}
          </span>
        </div>
      </div>
    </ng-container>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">Results</h3>

    <div class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="factor">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header"
          >
            &nbsp;
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{ element.factorTitle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="percent">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header"
          >
            Percent
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{ element.percent }}
          </td>
        </ng-container>

        <ng-container matColumnDef="percent_cum">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header"
          >
            Per. Cum
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{ element.percentCum }}
          </td>
        </ng-container>

        <ng-container matColumnDef="chi2">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header"
          >
            CHI<sup>2</sup>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{ element.chi2 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deg_free">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="column-header"
          >
            Deg. Free
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{ element.degreeFree }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</div>
