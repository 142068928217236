import { Injectable } from '@angular/core';
import { diff } from 'deep-object-diff';

@Injectable({
  providedIn: 'root',
})
export class CrossTabSurveyRefreshService {
  constructor() {}

  public shouldRefresh(originalObj: any, updateObj: any): boolean {
    const diffObj = diff(originalObj, updateObj);

    if (
      diffObj['tables'] ||
      diffObj['columns'] ||
      diffObj['rows'] ||
      diffObj['weight']
    ) {
      return true;
    }

    if (diffObj['reportPreferences']) {
      for (let diff in diffObj['reportPreferences']) {
        if (
          'completeCasesOn' in diffObj['reportPreferences'][diff] ||
          'dataItems' in diffObj['reportPreferences'][diff]
        ) {
          return true;
        }
      }
    }
    return false;
  }
}
