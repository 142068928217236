import { Injectable } from '@angular/core';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  ALL_RESPONDENTS_CODING,
  ALL_RESPONDENTS_TITLE,
  ChartSettings,
  ChartTargetMode,
  CodingGridPreferences,
  CrossTabTableData,
  DataItemsBaseItem,
  DataItemsResponse,
  DataViewMode,
  DEFAULT_WEIGHT_INDEX,
  DefaultChartStyles,
  DisplayType,
  DocumentChartStyle,
  DocumentDataChange,
  DocumentDataState,
  GetAdiItem,
  GetAdiRequest,
  GetAdiResponse,
  GetIniDataRequest,
  GetIniDataResponse,
  GetReportUnitsResponse,
  GetSurveyMetaDataResponse,
  GetSurveyResponse,
  IgnoreZeroWeightedResps,
  IniDataItem,
  Note,
  PopulationBaseItem,
  PopulationBasesResponse,
  ReportMode,
  ReportPreferences,
  Snapshot,
  StabilityLevels,
  StabilityLevelsResponse,
  Survey,
  SurveyCodeMap,
  SurveyDataItems,
  SurveyMetaData,
  SurveyMetaDataWeights,
  SurveyTimeDocument,
  Target,
  TARGET_TYPE_MAP,
  TargetAction,
  TargetItem,
  TargetType,
  TrendingCalculation,
  TrendingCalculationItem,
  ViewType,
} from '../models';
import { CrosstabService } from './crosstab.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import {
  TupDocument,
  TupDocumentService,
  TupDocumentStatus,
  TupDocumentType,
  TupDocumentTypeId,
  TupDocumentTypes,
  TupUserContainerService,
} from '@telmar-global/tup-document-storage';
import { TargetService } from './target.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  skip,
  tap,
} from 'rxjs/operators';
import {
  assign,
  cloneDeep,
  intersection,
  isEmpty,
  isEqual,
  merge,
  pick,
} from 'lodash';
import { isNotNullOrUndefined } from '../utils/pipeable-operators';
import { ApiService } from './api.service';
import { RequestLoadingService } from './request-loading.service';
import { TitleModeService } from './title-mode.service';
import { TitleLevelsService } from './title-levels.service';
import { environment } from 'src/environments/environment';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { DataItemsService } from './data-items.service';
import { ReportPreferencesService } from './report-preferences.service';
import { HttpResponse } from '@angular/common/http';
import { PMapsPreferences } from '../models/p-maps.model';
import { TrendingCalculationService } from './trending-calculation.service';
import {
  DocumentAudienceGroup,
  TupAudienceGroupsService,
} from '@telmar-global/tup-audience-groups';
import { TrendingCodingPipe } from '../pipes';
import { NavigationExtras, Router } from '@angular/router';
import { ManageCustomAudiencesService } from '../services/manage-custom-audiences.service';
import { MoveTargetItemsActionContext } from '../actions/MoveTargetItemsAction';
import { CodingGridService } from './coding-grid.service';
import { ManageChartStyleService } from './manage-chart-style-service';
import { ChartStyleService } from '../services/chart-style.service';
import { TargetGroupItem } from '../dialogs';
import { CrossTabSurveyRefreshService } from './crosstab-survey-refresh.service';

class DocumentFactory {
  public static create<T>(
    name: string,
    type: TupDocumentType,
    content?: T,
    description?: string
  ): TupDocument<T> {
    return {
      metadata: {
        name,
        ...(description && { description }),
        type,
        status: TupDocumentStatus.ACTIVE,
      },
      content,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  // tslint:disable-next-line:variable-name
  private _document: TupDocument<SurveyTimeDocument>;
  // tslint:disable-next-line:variable-name
  private _originalDocument: TupDocument<SurveyTimeDocument>;
  // tslint:disable-next-line:variable-name
  private _container: UserContainer;
  // tslint:disable-next-line:variable-name
  private _surveys: Survey[] = [];
  // tslint:disable-next-line:variable-name
  private _activeSurvey: Survey;
  // tslint:disable-next-line:variable-name
  private _hiddenSurveys: Set<Survey> = new Set<Survey>();
  // tslint:disable-next-line:variable-name
  private _topojsonUrl: string;

  private targetTypeMap: Record<TargetType, string> = TARGET_TYPE_MAP;

  private setCurrentDoc: BehaviorSubject<TupDocument<SurveyTimeDocument>> =
    new BehaviorSubject<TupDocument<SurveyTimeDocument>>(null);
  public currentDoc: Observable<TupDocument<SurveyTimeDocument>> =
    this.setCurrentDoc.asObservable();

  private setReadonlyDoc: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  public readonlyDoc$: Observable<boolean> = this.setReadonlyDoc.asObservable();

  private updateDocumentState = new BehaviorSubject<
    TupDocument<SurveyTimeDocument>
  >(null);
  private updateDocumentState$ = this.updateDocumentState.asObservable();

  private restoreDocumentState =
    new BehaviorSubject<TupDocument<SurveyTimeDocument> | null>(null);
  public restoreDocumentState$ = this.restoreDocumentState.asObservable();

  private restoreTimestampState = new BehaviorSubject<number | null>(null);
  public restoreTimestampState$ = this.restoreTimestampState.asObservable();

  private documentUpdated = new BehaviorSubject<
    TupDocument<SurveyTimeDocument>
  >(null);
  public documentUpdated$ = this.documentUpdated.asObservable();

  private tempDocumentState: TupDocument<SurveyTimeDocument> | null = null;

  private documentState = new BehaviorSubject<DocumentDataState>({
    tables: [],
    columns: [],
    rows: [],
  });
  public documentState$ = this.documentState.asObservable();

  private activeDocumentId = new BehaviorSubject<string | null>(null);
  public activeDocumentId$ = this.activeDocumentId.asObservable();

  private selectedSurvey = new BehaviorSubject<Survey>(null);
  public selectedSurvey$ = this.selectedSurvey.asObservable();

  private surveyCodeMap = new BehaviorSubject<SurveyCodeMap>(null);
  public surveyCodeMap$ = this.surveyCodeMap.asObservable();

  private activeTablebase = new BehaviorSubject<Target>(null);
  public activeTablebase$ = this.activeTablebase.asObservable();

  public tablebases = new BehaviorSubject<Target[]>([]);
  public tablebases$ = this.tablebases.asObservable();

  private reportUnits = new BehaviorSubject<number>(1);
  public reportUnits$ = this.reportUnits.asObservable();

  private surveyDataWeights = new BehaviorSubject<SurveyMetaDataWeights[]>([]);
  public surveyDataWeights$ = this.surveyDataWeights.asObservable();

  private surveyStabilityLevels = new BehaviorSubject<StabilityLevels>(null);
  public surveyStabilityLevels$ = this.surveyStabilityLevels.asObservable();

  private activeReportMode: ReportMode;
  private isManageCustomAudiencesModeOn = false;
  private isManageChartStyleModeOn = false;
  private shouldIncludeWeightedProfilesAdi = false;

  private ignoreZeroWeightedResps =
    new BehaviorSubject<IgnoreZeroWeightedResps>({
      editable: false,
      enabled: false,
    });
  public ignoreZeroWeightedResps$ = this.ignoreZeroWeightedResps.asObservable();

  constructor(
    private authService: TupAuthService,
    private userContainerService: TupUserContainerService,
    private tupDocumentService: TupDocumentService,
    private targetService: TargetService,
    private crossTabService: CrosstabService,
    private crossTabSurveyRefreshService: CrossTabSurveyRefreshService,
    private apiService: ApiService,
    private requestLoadingService: RequestLoadingService,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    private messageService: TupUserMessageService,
    private dataItemService: DataItemsService,
    private reportPreferencesService: ReportPreferencesService,
    private trendingCalculationService: TrendingCalculationService,
    private router: Router,
    private trendingCodingPipe: TrendingCodingPipe,
    private manageCustomAudiencesService: ManageCustomAudiencesService,
    private audienceGroupService: TupAudienceGroupsService,
    private codingGridService: CodingGridService,
    private manageChartStyleService: ManageChartStyleService,
    private chartStyleService: ChartStyleService,
    private userMessageService: TupUserMessageService
  ) {
    this.loadContainerAndDocument();
  }

  public initDocument(
    surveys: Survey[],
    includeRowCol: boolean = false,
    surveysChanged: boolean = false,
    shouldShowPlaceholder: boolean = true,
    shouldSetRestoreDocumentStateFromTempDocumentState = false,
    shouldRefreshCrossTabSurvey: boolean = true
  ): void {
    this.setDocReadonly();
    this.resetRestoreState();
    this.setDocumentSurveys(surveys, surveysChanged);
    this.reportPreferencesService.initialisePreferences(
      this._document.content.reportPreferences ||
        this.reportPreferencesService.getDefaultPreferences(),
      surveysChanged
    );
    this.codingGridService.updateCodingGridPreferences(
      this._document.content.codingGridPreferences ||
        this.codingGridService.getDefaultCodingGridPreferences()
    );
    this.chartStyleService.updateDefaultChartStyles(
      this._document.content.defaultChartStyles ||
        this.chartStyleService.getDefaultChartStyles()
    );
    this.setTitleModeAndLevels();
    this.initializeTrendingCalculations();

    if (includeRowCol) {
      this.emitDocumentStateChanged(false);
    } else {
      this.clearDocumentData();
      this.emitDocumentStateChanged(true);
    }
    if (shouldShowPlaceholder) {
      this.crossTabService.emitPlaceholderCrossTabData(
        this.getVisibleSurveys(),
        this._document.content.rows,
        this._document.content.columns
      );
    }

    if (shouldRefreshCrossTabSurvey) {
      this.loadDocumentData(
        surveysChanged,
        shouldSetRestoreDocumentStateFromTempDocumentState
      );
    }
  }

  public createOwnCodesDocumentObject(
    name: string,
    content: DocumentAudienceGroup
  ): TupDocument<DocumentAudienceGroup> {
    return DocumentFactory.create<DocumentAudienceGroup>(
      name,
      TupDocumentTypes[TupDocumentTypeId.OWN_CODES_TARGET],
      content
    );
  }

  public createChartStyleDocumentObject(
    name: string,
    description: string,
    content: DocumentChartStyle
  ): TupDocument<DocumentChartStyle> {
    return DocumentFactory.create<DocumentChartStyle>(
      name,
      TupDocumentTypes[TupDocumentTypeId.SURVEYTIME_CHART_STYLE_TEMPLATE],
      content,
      description
    );
  }

  public createDocument(
    containerName: string,
    crosstabName?: string,
    crosstabDescription?: string,
    newDoc?: TupDocument<SurveyTimeDocument>,
    isTemplateDocType = false
  ): Observable<string> {
    let document: TupDocument<SurveyTimeDocument> =
      DocumentFactory.create<SurveyTimeDocument>(
        crosstabName,
        TupDocumentTypes[
          isTemplateDocType
            ? TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
            : TupDocumentTypeId.SURVEYTIME_CAMPAIGN
        ],
        new SurveyTimeDocument(),
        crosstabDescription
      );
    if (newDoc) {
      document = Object.assign(newDoc);
    }

    return this.tupDocumentService.create(containerName, document).pipe(
      map((response: HttpResponse<any>) => {
        return response.headers.get('Location').split('/').pop();
      })
    );
  }

  public setDocumentSurveys(surveys: Survey[], surveysChanged: boolean): void {
    this._surveys = surveys;
    this._document.content.surveys = surveys;
    if (surveysChanged) {
      this._document.content.activeSurveyId = undefined;
    }
    this._activeSurvey = this.getActiveSurvey();
    this.selectedSurvey.next(this._activeSurvey);
    this.surveyCodeMap.next(this.formatSurveyCodeMap(this._surveys));
  }

  private isReadonlyDoc(): boolean {
    const userEmail = this.authService.user.attributes.email;
    const readonly = this._document.metadata.by.attributes.email !== userEmail;
    return readonly;
  }

  private setDocReadonly(): void {
    const readonly = this.isReadonlyDoc();
    this.setReadonlyDoc.next(readonly);
  }

  public get surveys(): Survey[] {
    return this._surveys;
  }

  public get topojsonUrl(): string {
    return this._topojsonUrl;
  }

  public get document(): TupDocument<SurveyTimeDocument> {
    return this._document;
  }

  public getActiveTitleMode(): DisplayType {
    return this._document.content.activeTitleMode || DisplayType.title;
  }

  public hasCompatibleWeightsThenAlert(
    selectedTargets: Target[],
    tables?: Target[],
    columns?: Target[],
    rows?: Target[]
  ): boolean {
    const selectedWeights = this.extractValidWeightsList(selectedTargets);
    if (selectedWeights.length === 0) {
      return true;
    }

    const validSelectedWeights: number[] = intersection(
      ...(selectedWeights as [])
    );

    const validWeights = this.getValidWeightsFromDocument(
      tables,
      columns,
      rows
    );

    const intersectionWeight: number[] = validWeights.length
      ? intersection(...([validWeights].concat(selectedWeights) as []))
      : validSelectedWeights;

    if (intersectionWeight.length === 0 || validSelectedWeights.length === 0) {
      this.messageService.openMessageDialog(
        `These items cannot be added as there would be no common weights across your plan`,
        `No common weights`
      );
    } else {
      this._document.content.weight = this.getCompatibleWeight(
        intersectionWeight,
        this._document.content.weight
      );
    }

    return !!intersectionWeight.length && !!validSelectedWeights.length;
  }

  public getValidWeightsFromDocument(
    tables?: Target[],
    columns?: Target[],
    rows?: Target[]
  ): number[] {
    let weightArrays = [];
    const columnWeights = this.extractValidWeightsList(
      columns || this._document.content.columns
    );
    const rowWeights = this.extractValidWeightsList(
      rows || this._document.content.rows
    );
    const tableWeights = this.extractValidWeightsList(
      tables || this._document.content.tables
    );

    if (columnWeights.length) {
      weightArrays = weightArrays.concat(columnWeights);
    }

    if (rowWeights.length) {
      weightArrays = weightArrays.concat(rowWeights);
    }
    if (tableWeights.length) {
      weightArrays = weightArrays.concat(tableWeights);
    }
    return intersection(...weightArrays);
  }

  private extractValidWeightsList(targets: Target[]): number[] {
    const validWeightsList = [];
    targets.forEach((target: Target) => {
      validWeightsList.push(target.validWeights);
    });
    return validWeightsList.filter((weight) => weight?.length);
  }

  public getCompatibleWeight(
    validWeights: number[],
    currentWeight: number
  ): number {
    return validWeights.length === 0
      ? currentWeight
      : validWeights.includes(currentWeight)
      ? currentWeight
      : Math.min(...validWeights);
  }

  public getVisibleSurveys(): Survey[] {
    return this._surveys.filter((survey) => !this._hiddenSurveys.has(survey));
  }

  public setHiddenSurveys(
    surveys: Set<Survey>,
    shouldRefreshCrossTab: boolean = true
  ) {
    this._hiddenSurveys = surveys;
    this.reportPreferencesService.unsetColumnSortAndFiltersBySurveys(
      this._hiddenSurveys
    );
    if (shouldRefreshCrossTab) {
      this.notifyCrosstabDataAboutToChange();
      this.updateCrossTabData();
    }
  }

  public getHiddenSurveys(): Set<Survey> {
    return this._hiddenSurveys;
  }

  public get activeSurvey(): Survey {
    return this._activeSurvey;
  }

  public set activeSurvey(survey: Survey) {
    this.setTempDocumentState();
    this.reportPreferencesService.resetColumnSortAndFiltersWhenChangingActiveSurvey();
    this._activeSurvey = survey;
    this.selectedSurvey.next(this._activeSurvey);
    this._document.content.activeSurveyId = survey.code;
    this.notifyCrosstabDataAboutToChange();
    this.notifyDocumentStateChanged();
    this.loadDocumentData(true);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public getSurveyCodeMap(): SurveyCodeMap {
    return this.surveyCodeMap.value;
  }

  public setActiveDocumentId(documentId?: string): void {
    this.activeDocumentId.next(documentId);
  }

  public unsetActiveDocumentId(): void {
    this.activeDocumentId.next(null);
  }

  public unsetReadonlyDoc(): void {
    this.setReadonlyDoc.next(null);
  }

  public resetDocumentChanges(): void {
    this.resetOriginalDocument();
  }

  public resetRestoreState(): void {
    this.setRestoreDocumentState(null);
  }

  public setTempDocumentState(): void {
    this.tempDocumentState = cloneDeep(this._originalDocument);
  }

  public setRestoreDocumentStateFromTempDocumentState(): void {
    this.setRestoreDocumentState(this.tempDocumentState);
  }

  public undoDocument(): void {
    const originalObj = cloneDeep(this._document.content);
    const updatedObj = cloneDeep(this.restoreDocumentState.value.content);
    const shouldRefreshCrossTabSurvey =
      this.crossTabSurveyRefreshService.shouldRefresh(originalObj, updatedObj);

    this._document = assign(
      this._document,
      pick(this.restoreDocumentState.value, ['content'])
    );

    this.initDocument(
      this._document.content.surveys,
      true,
      false,
      false,
      false,
      shouldRefreshCrossTabSurvey
    );

    this.notifyDocumentStateChanged(true);
    this.restoreTimestampState.next(new Date().getTime());
  }

  public getActiveTable(): Target {
    return this.activeTablebase.value;
  }

  public setActiveTable(table: Target, shouldRefreshCrossTab = true): void {
    this._document.content.activeTableId = table.id;
    if (this.activeTablebase.value === table) {
      return;
    }
    this.activeTablebase.next(table);
    if (shouldRefreshCrossTab) {
      this.notifyCrosstabDataAboutToChange();
      this.notifyDocumentStateChanged();
      this.updateCrossTabData();
    }
  }

  public updateTrendingCalculation(
    trendingCalculations: TrendingCalculation[]
  ) {
    this._document.content.trendings = trendingCalculations;
    this.surveyCodeMap.next(this.formatSurveyCodeMap(this._surveys));
    this.emitDocumentStateChanged(true);
    this.updateCrossTabData();
  }

  public getTrendingCalculations(): TrendingCalculationItem[] {
    return this._document.content?.trendings
      ? this.trendingCalculationService.convertToTrendingCalculationItem(
          this.getVisibleSurveys(),
          this._document.content.trendings
        )
      : [];
  }

  public initializeTrendingCalculations() {
    this.trendingCalculationService.reset(this.getTrendingCalculations());
  }

  public updateCurrentWeight(weight: number) {
    this._document.content.weight = weight;
    this.emitDocumentStateChanged(true);
    this.notifyCrosstabDataAboutToChange();
    this.updateCrossTabData();
  }

  public updateSurveyCodes(surveys: Survey[]): void {
    this._surveys.forEach((survey: Survey, index: number) => {
      survey.customCode = surveys[index].customCode;
    });
    this._document.content.surveys = this._surveys;
    this.surveyCodeMap.next(this.formatSurveyCodeMap(this._surveys));
    this.notifyDocumentStateChanged();
  }

  public addDocumentData(
    targets: Target[],
    targetType: TargetType,
    shouldRefreshCrossTab = true,
    insertIndex?: number
  ): void {
    targets.forEach((target: Target) => {
      if (!target.activeTitleMode) {
        target.activeTitleMode = this._document.content.activeTitleMode;
      }
      if (!target.titleLevels) {
        target.titleLevels = this._document.content.titleLevels;
      }
    });
    const targetKey = this.getTargetKey(targetType);
    const addedIndex = this._document.content[targetKey].length;

    if (insertIndex) {
      this._document.content[targetKey].splice(insertIndex, 0, ...targets);
    } else {
      this._document.content[targetKey].push(...targets);
    }

    if (targetType === TargetType.tables) {
      this.setActiveTable(targets[0], false);
    }

    if (shouldRefreshCrossTab) {
      this.updateCrossTabData();
    }
    const documentDataChanges: DocumentDataChange[] = [
      {
        action: TargetAction.create,
        items: targets.map((target: Target, index: number) => ({
          type: targetType,
          target,
          index: index + (insertIndex ? insertIndex : addedIndex),
        })),
      },
    ];
    this.emitDocumentStateChanged(true, documentDataChanges);
  }

  public updateDocumentData(
    targetItems: TargetItem[],
    shouldRefreshCrossTab = true
  ): void {
    targetItems.forEach((targetItem: TargetItem) => {
      const targetKey = this.getTargetKey(targetItem.type);
      this._document.content[targetKey][targetItem.index] = targetItem.target;
    });

    const updatedTableTargetItem = targetItems
      .filter((targetItem: TargetItem) => targetItem.type === TargetType.tables)
      .find(
        (targetItem: TargetItem) =>
          targetItem.target.id === this.activeTablebase.value.id
      );
    if (updatedTableTargetItem) {
      this.setActiveTable(updatedTableTargetItem.target, false);
    }

    if (shouldRefreshCrossTab) {
      this.updateCrossTabData();
    }

    const documentDataChanges: DocumentDataChange[] = [
      {
        action: TargetAction.update,
        items: targetItems,
      },
    ];

    this.emitDocumentStateChanged(true, documentDataChanges);
  }

  public flagDocumentData(targetGroupItems: TargetGroupItem[]): void {
    [TargetType.columns, TargetType.rows].forEach((targetType) => {
      const targetKey = this.getTargetKey(targetType);
      this._document.content[targetKey].forEach((item) => {
        item.selectedForPlan = undefined;
      });
    });
    targetGroupItems.forEach((targetGroupItem) => {
      const targetType =
        targetGroupItem.groupIndex === 0 ? TargetType.rows : TargetType.columns;
      const targetKey = this.getTargetKey(targetType);
      this._document.content[targetKey][
        targetGroupItem.itemIndex
      ].selectedForPlan = true;
    });

    this.notifyDocumentStateChanged(true);
  }

  public deleteDocumentData(
    targetItems: TargetItem[],
    shouldRefreshCrossTab = true
  ): void {
    this.setTempDocumentState();
    targetItems.forEach((targetItem: TargetItem) => {
      const targetKey = this.getTargetKey(targetItem.type);
      this._document.content[targetKey] = this._document.content[
        targetKey
      ].filter((target: Target) => target.id !== targetItem.target.id);
    });

    const deletedTableTargetItem = targetItems
      .filter((targetItem: TargetItem) => targetItem.type === TargetType.tables)
      .find(
        (targetItem: TargetItem) =>
          targetItem.target.id === this.activeTablebase.value.id
      );
    if (deletedTableTargetItem) {
      this.setActiveTable(this._document.content.tables[0], false);
    }

    this.reportPreferencesService.unsetColumnSortAndFilters(
      targetItems
        .filter(
          (targetItem: TargetItem) => targetItem.type === TargetType.columns
        )
        .map((targetItem: TargetItem) => targetItem.target.id)
    );

    if (shouldRefreshCrossTab) {
      this.updateCrossTabData();
    }

    const documentDataChanges: DocumentDataChange[] = [
      {
        action: TargetAction.delete,
        items: targetItems,
      },
    ];

    this.emitDocumentStateChanged(true, documentDataChanges);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public deleteAllTargets(targetTypes: TargetType[]): void {
    this.setTempDocumentState();
    let previousColumns: Target[] = [];
    targetTypes.forEach((targetType: TargetType) => {
      const targetKey = this.getTargetKey(targetType);
      if (targetType === TargetType.tables) {
        this._document.content[targetKey] = [
          this._document.content[targetKey][0],
        ];
        this.setActiveTable(this._document.content.tables[0], false);
      } else {
        if (targetType === TargetType.columns) {
          previousColumns = this._document.content[targetKey];
        }
        this._document.content[targetKey] = [];
      }
    });

    this.reportPreferencesService.unsetColumnSortAndFilters(
      previousColumns.map((target: Target) => target.id)
    );

    this.updateCrossTabData();
    this.emitDocumentStateChanged(true);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public moveTargets(targetItems: TargetItem[], moveTo: TargetType): void {
    this.setTempDocumentState();
    const targets = targetItems.map(
      (targetItem: TargetItem) => targetItem.target
    );
    this.deleteDocumentData(targetItems, false);
    this.addDocumentData(targets, moveTo, true);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public swapColumnsAndRows(): void {
    this.setTempDocumentState();
    const columns = this._document.content.columns;
    const rows = this._document.content.rows;
    this._document.content.columns = rows;
    this._document.content.rows = columns;

    this.reportPreferencesService.resetAllColumnSortAndFilters();

    this.updateCrossTabData();
    this.emitDocumentStateChanged(true);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public moveTargetItems(moveItems: MoveTargetItemsActionContext): void {
    this.setTempDocumentState();
    const targetKey = this.getTargetKey(moveItems.type);
    const newArray = this._document.content[targetKey].slice();

    const elementsToMove = moveItems.sourceIndices.map(
      (index) => newArray[index]
    );
    moveItems.sourceIndices
      .slice()
      .reverse()
      .forEach((index) => {
        newArray.splice(index, 1);
      });

    const elementsRemovedBeforeTarget = moveItems.sourceIndices.filter(
      (index) => index <= moveItems.targetIndex
    ).length;
    const adjustedTargetIndex =
      moveItems.targetIndex - elementsRemovedBeforeTarget + 1;
    newArray.splice(adjustedTargetIndex, 0, ...elementsToMove);
    this._document.content[targetKey] = newArray;

    this.reportPreferencesService.resetAllColumnSortAndFilters();

    this.updateCrossTabData();
    this.emitDocumentStateChanged(true);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public deleteAllColumnsAndRows(): void {
    this.setTempDocumentState();
    this._document.content.columns = [];
    this._document.content.rows = [];

    this.reportPreferencesService.resetAllColumnSortAndFilters();

    this.updateCrossTabData();
    this.emitDocumentStateChanged(true);
    this.setRestoreDocumentStateFromTempDocumentState();
  }

  public updateDocumentDataTitle(
    partial: Partial<Target>,
    shouldRefreshCrossTab = true
  ): void {
    this._document.content.tables.forEach((target: Target) => {
      target = Object.assign(target, partial);
    });
    this._document.content.columns.forEach((target: Target) => {
      target = Object.assign(target, partial);
    });
    this._document.content.rows.forEach((target: Target) => {
      target = Object.assign(target, partial);
    });

    if (shouldRefreshCrossTab) {
      this.updateCrossTabData();
    }

    this.emitDocumentStateChanged(true);
  }

  public restoreInitialDocumentState(): void {
    this.crossTabService.cleanCrossTabData();

    this._surveys = [];
    this._activeSurvey = null;
    this.selectedSurvey.next(null);
    this.activeTablebase.next(null);
    this.activeDocumentId.next(null);
    this.documentState.next({
      tables: [],
      columns: [],
      rows: [],
    });
  }

  public updateCrossTabData(): void {
    this.crossTabService.updateCrossTabData(
      this._activeSurvey,
      this.getVisibleSurveys(),
      this._document.content.tables,
      this._document.content.columns,
      this._document.content.rows,
      this._document.content.weight,
      this.activeTablebase.value,
      this.shouldIncludeWeightedProfilesAdi,
      this.ignoreZeroWeightedResps.value
    );
  }

  public fetchDynamicCrosstabData(
    columns: Target[],
    rows?: Target[],
    surveys?: Survey[],
    table?: Target
  ): Observable<CrossTabTableData[]> {
    return this.crossTabService.loadCrossTabData(
      this._activeSurvey,
      !surveys ? this.getVisibleSurveys() : surveys,
      columns,
      !rows ? this._document.content.rows : rows,
      this._document.content.weight,
      !table ? this.activeTablebase.value : table,
      this.shouldIncludeWeightedProfilesAdi,
      this.ignoreZeroWeightedResps.value
    );
  }

  public fetchCodingResult(target: Target): Observable<CrossTabTableData[]> {
    return this.crossTabService.loadCrossTabData(
      this._activeSurvey,
      this.getVisibleSurveys(),
      [target],
      [],
      this._document.content.weight,
      this.activeTablebase.value,
      this.shouldIncludeWeightedProfilesAdi,
      this.ignoreZeroWeightedResps.value
    );
  }

  public setDefaultReportPreferences(): void {
    this._document.content.reportPreferences =
      this.reportPreferencesService.getDefaultPreferences();
  }

  public setDefaultCodingGridPreferences(): void {
    this._document.content.codingGridPreferences = cloneDeep(
      this.codingGridService.getDefaultCodingGridPreferences()
    );
  }

  public setDefaultChartStyles(): void {
    this._document.content.defaultChartStyles = cloneDeep(
      this.chartStyleService.getDefaultChartStyles()
    );
  }

  public createNote(associatedTarget: string, associatedInsight: string): Note {
    return {
      title: '',
      description: '',
      associatedTarget,
      associatedInsight,
    };
  }

  public findNote(note: Note): Note[] {
    return this.findNoteByInsightTarget(
      note.associatedInsight,
      note.associatedTarget
    );
  }

  public findNoteByInsightTarget(
    associatedInsight: string,
    associatedTarget: string
  ): Note[] {
    return (
      this._document.content.notes?.filter(
        (item) =>
          item.associatedInsight === associatedInsight &&
          item.associatedTarget === associatedTarget
      ) || []
    );
  }

  public addChartNote(note: Note): void {
    const currentNotes = this._document.content.notes;
    if (!currentNotes) {
      this._document.content.notes = [];
    }

    const resultingArray = this.findNote(note);
    if (isEmpty(resultingArray)) {
      this._document.content.notes.push(note);
    } else {
      const noteIndex = currentNotes.findIndex(
        (item) =>
          item.associatedInsight === note.associatedInsight &&
          item.associatedTarget === note.associatedTarget
      );
      this._document.content.notes[noteIndex] = note;
    }

    this.notifyDocumentStateChanged();
  }

  public addChartSettings(
    settings: ChartSettings,
    shouldUnsetRestoreDocumentState: boolean = false
  ): void {
    if (this._document.content.chartSettings) {
      this._document.content.chartSettings.push(settings);
    } else {
      this._document.content.chartSettings = [settings];
    }
    this.notifyDocumentStateChanged(shouldUnsetRestoreDocumentState);
  }

  public updateChartSettings(settings: ChartSettings): void {
    const index = this._document.content.chartSettings.findIndex(
      (item: ChartSettings) =>
        item.targetMode === settings.targetMode &&
        item.dataViewMode === settings.dataViewMode &&
        item.associatedInsight === settings.associatedInsight &&
        item.associatedTarget === settings.associatedTarget
    );
    if (index !== -1) {
      this._document.content.chartSettings[index] = settings;
      this.notifyDocumentStateChanged();
    }
  }

  public resetAllChartSettings(
    targetMode: ChartTargetMode,
    dataViewMode: DataViewMode,
    shouldSaveState: boolean = false
  ): void {
    this._document.content.chartSettings =
      this._document.content.chartSettings.filter(
        (setting: ChartSettings) =>
          setting.targetMode !== targetMode ||
          setting.dataViewMode !== dataViewMode
      );
    if (shouldSaveState) {
      this.notifyDocumentStateChanged();
    }
  }

  public findChartSettings(
    targetMode: ChartTargetMode,
    associatedTarget: string,
    associatedInsight: string,
    dataViewMode: DataViewMode = DataViewMode.default
  ): ChartSettings | undefined {
    return this._document.content.chartSettings?.find(
      (chartSettings: ChartSettings) =>
        chartSettings.targetMode === targetMode &&
        chartSettings.dataViewMode === dataViewMode &&
        chartSettings.associatedInsight === associatedInsight &&
        chartSettings.associatedTarget === associatedTarget
    );
  }

  public getAllChartSettings(
    targetMode: ChartTargetMode,
    dataViewMode: DataViewMode
  ): ChartSettings[] {
    return this._document.content.chartSettings.filter(
      (setting: ChartSettings) =>
        setting.targetMode === targetMode &&
        setting.dataViewMode === dataViewMode
    );
  }

  public getPMapsPreferences(): PMapsPreferences | undefined {
    return this._document?.content.pMapsPreferences;
  }

  public setPMapsPreferences(preferences: PMapsPreferences): void {
    this._document.content.pMapsPreferences = preferences;
  }

  public savePMapsPreferences(preferences: PMapsPreferences): void {
    this.setPMapsPreferences(preferences);
    this.notifyDocumentStateChanged();
  }

  public updateDocumentApps(
    doc: TupDocument<SurveyTimeDocument>,
    shouldUnsetRestoreDocumentState: boolean = false
  ): void {
    this._document = doc;
    this.notifyDocumentStateChanged(shouldUnsetRestoreDocumentState);
  }

  public setCurrentDocument(doc: TupDocument<SurveyTimeDocument>): void {
    this.setCurrentDoc.next(doc);
  }

  public navToCrosstab(): void {
    const currentDoc = this.document;
    const isDocReadonly = !(
      this.authService.user.attributes.email ===
      currentDoc.metadata.by.attributes.email
    );

    const option: NavigationExtras = {
      state: {
        isReadonly: isDocReadonly,
      },
      queryParams: {
        tab: ViewType.crossTab,
      },
    };

    this.router.navigate([`doc/${currentDoc.metadata.id}/data`], option);
  }

  private loadContainerAndDocument(): void {
    combineLatest([
      this.userContainerService.container.pipe(isNotNullOrUndefined()),
      this.currentDoc.pipe(isNotNullOrUndefined()),
    ]).subscribe(
      ([container, document]: [
        UserContainer,
        TupDocument<SurveyTimeDocument>
      ]) => {
        if (
          this.isManageCustomAudiencesModeOn ||
          this.isManageChartStyleModeOn
        ) {
          return;
        }
        const needsEventListeners = !this._document;

        this._container = container;
        this._document = document;
        this.resetOriginalDocument();

        if (needsEventListeners) {
          this.listenToSaveDocumentStateChanges();
          this.listenToTitleModeAndLevelsChanges();
          this.listenToReportModeChanges();
          this.listenToReportPreferencesChanges();
          this.listenToCodingGridPreferencesChanges();
          this.listenToManageCustomAudiencesModeChanges();
          this.listenToChartStyleModeChanges();
          this.listenToDefaultChartStyleChanges();
          this.listenToCompleteCasesChanges();
        }
      }
    );
  }

  public createInitialTable(): void {
    const surveyDefaultTablebase = this.tablebases.value.find(
      (table: Target) => table.isDefaultTablebase
    );
    const newTableBaseTarget =
      surveyDefaultTablebase ||
      this.targetService.createTarget({
        title: ALL_RESPONDENTS_TITLE,
        coding: ALL_RESPONDENTS_CODING,
      });

    this.addDocumentData(
      this.targetService.separateTargets([newTableBaseTarget]),
      TargetType.tables,
      false
    );

    this.resetOriginalDocument();
  }

  public notifyCrosstabDataAboutToChange(): void {
    this.setPMapsPreferences(null);
  }

  public async saveAsNormalReport(
    columns: Target[],
    table: Target
  ): Promise<void> {
    this.requestLoadingService.setLoading({
      target: 'document',
      isLoading: true,
    });

    const containerName = this._container.name;
    const newDoc = Object.assign(this._document);
    const originalName = this._document.metadata.name;
    newDoc.metadata.type =
      TupDocumentTypes[TupDocumentTypeId.SURVEYTIME_CAMPAIGN];
    newDoc.metadata.name = `${originalName}(converted)`;
    newDoc.content.activeTableId = table.id;
    newDoc.content.columns = columns;

    const docId = await this.createDocument(
      containerName,
      undefined,
      undefined,
      newDoc,
      false
    ).toPromise();

    const currentContainer = this.userContainerService.getContainer();
    if (currentContainer.name !== containerName) {
      const usersContainer = this.authService.user.containers.find(
        (container) => container.name === containerName
      );
      this.userContainerService.setContainer(usersContainer);
    }

    this.resetDocumentChanges();
    this.messageService.showSnackBar(
      `${originalName} has been converted to a Report`,
      'OK'
    );

    this.router.navigate([`doc/${docId}/data`], {
      queryParams: {
        tab: ViewType.crossTab,
      },
      state: {
        isQuickReport: false,
      },
    });
  }

  private emitDocumentStateChanged(
    shouldSaveState: boolean = true,
    changes?: DocumentDataChange[]
  ): void {
    const newDocumentState = {
      tables: this._document.content.tables,
      columns: this._document.content.columns,
      rows: this._document.content.rows,
      weight: this._document.content.weight,
      trendings: this.getTrendingCalculations(),
    };
    if (shouldSaveState) {
      this._document.content = {
        ...this._document.content,
        ...newDocumentState,
      };
      this.notifyCrosstabDataAboutToChange();
      this.notifyDocumentStateChanged();
    }
    this.documentState.next({
      ...newDocumentState,
      changes,
    });
  }

  private listenToSaveDocumentStateChanges(): void {
    this.updateDocumentState$
      .pipe(debounceTime(500), isNotNullOrUndefined())
      .subscribe((document: TupDocument<SurveyTimeDocument>) => {
        if (this.isReadonlyDoc()) {
          this.documentUpdated.next(document);
        } else {
          this.updateDocument(document);
        }
      });
  }

  private listenToTitleModeAndLevelsChanges(): void {
    this.titleModeService.titleMode$
      .pipe(distinctUntilChanged())
      .subscribe((activeTitleMode: DisplayType) => {
        this._document.content.activeTitleMode = activeTitleMode;
      });

    this.titleLevelsService.titleLevels$
      .pipe(filter((titleLevels: number[]) => !!titleLevels?.length))
      .subscribe((titleLevels: number[]) => {
        this._document.content.titleLevels = titleLevels;
      });
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$.subscribe((mode: ReportMode) => {
      if (
        this.activeReportMode !== mode &&
        this.activeReportMode !== undefined
      ) {
        if (mode === ReportMode.affinity) {
          this.updateCrossTabData();
        }
      }
      this.activeReportMode = mode;
    });
  }

  private listenToCompleteCasesChanges(): void {
    this.reportPreferencesService.completeCases$.pipe(skip(1)).subscribe(() => {
      this.updateCrossTabData();
    });
  }

  private listenToReportPreferencesChanges(): void {
    this.reportPreferencesService.preferences$
      .pipe(skip(1))
      .subscribe((preferences: ReportPreferences) => {
        if (!this.activeDocumentId.value) {
          return;
        }
        if (!isEqual(this._document.content.reportPreferences, preferences)) {
          this._document.content.reportPreferences = preferences;
          this.setPMapsPreferences(null);
          this.notifyDocumentStateChanged();
        }
      });
  }

  private listenToCodingGridPreferencesChanges(): void {
    this.codingGridService.codingGridPreferences$
      .pipe(skip(1))
      .subscribe((preferences: CodingGridPreferences) => {
        if (!this.activeDocumentId.value) {
          return;
        }

        if (
          !isEqual(this._document.content.codingGridPreferences, preferences)
        ) {
          this._document.content.codingGridPreferences = cloneDeep(preferences);
          this.notifyDocumentStateChanged();
        }
      });
  }

  private listenToDefaultChartStyleChanges(): void {
    this.chartStyleService.defaultChartStyles$
      .pipe(skip(1))
      .subscribe((defaultChartStyles: DefaultChartStyles) => {
        if (!this.activeDocumentId.value) {
          return;
        }

        if (
          !isEqual(
            this._document.content.defaultChartStyles,
            defaultChartStyles
          )
        ) {
          this._document.content.defaultChartStyles =
            cloneDeep(defaultChartStyles);
          this.notifyDocumentStateChanged();
        }
      });
  }

  private listenToManageCustomAudiencesModeChanges(): void {
    this.manageCustomAudiencesService.manageCustomAudiencesModeOn$.subscribe(
      (mode: boolean) => {
        this.isManageCustomAudiencesModeOn = mode;
      }
    );
  }

  private listenToChartStyleModeChanges(): void {
    this.manageChartStyleService.manageChartStyleModeOn$.subscribe(
      (mode: boolean) => {
        this.isManageChartStyleModeOn = mode;
      }
    );
  }

  public updateDocumentSnapshots(
    document: TupDocument<SurveyTimeDocument>,
    snapshot: Snapshot
  ): void {
    if (document.content.snapshots) {
      document.content.snapshots.push(snapshot);
    } else {
      document.content.snapshots = [snapshot];
    }

    this.tupDocumentService.update(this._container.name, document).subscribe(
      () => {
        this.requestLoadingService.setLoading({
          target: 'document',
          isLoading: false,
        });
        this.messageService.showSnackBar(
          `Snapshot ${snapshot.name} created`,
          'OK'
        );
      },
      (error) => {
        console.log(`error`, error);

        this.messageService.showSnackBar(error, 'OK', 10000);
        this.requestLoadingService.setLoading({
          target: 'document',
          isLoading: false,
        });
      }
    );
  }

  private updateDocument(document: TupDocument<SurveyTimeDocument>): void {
    this.requestLoadingService.setLoading({
      target: 'auto-save',
      isLoading: true,
    });
    this.tupDocumentService.update(this._container.name, document).subscribe(
      () => {
        this.requestLoadingService.setLoading({
          target: 'auto-save',
          isLoading: false,
        });
        this.documentUpdated.next(document);
      },
      (error) => {
        console.log(`error`, error);

        this.messageService.showSnackBar(error, 'OK', 10000);
        this.requestLoadingService.setLoading({
          target: 'auto-save',
          isLoading: false,
        });
      }
    );
  }

  private loadDocumentData(
    surveysChanged: boolean,
    shouldSetRestoreDocumentStateFromTempDocumentState: boolean = false
  ): void {
    this.requestLoadingService.setLoading({
      target: 'document',
      isLoading: true,
    });

    this.loadSurveyDetails(this._activeSurvey).subscribe(
      ([
        surveys,
        tablebases,
        reportUnits,
        surveyMetaData,
        stabilityLevels,
        [surveyDataItems, adiItems, iniData],
      ]: [
        Survey[],
        Target[],
        number,
        SurveyMetaData,
        StabilityLevels,
        [SurveyDataItems, GetAdiItem[], IniDataItem[]]
      ]) => {
        this._surveys.forEach((survey: Survey, index) => {
          const surveyItem = surveys.find(
            (surv) =>
              surv.code === survey.code &&
              surv.authorizationGroup === survey.authorizationGroup
          );
          if (!surveyItem) {
            return;
          }
          this._surveys[index] = merge(survey, surveyItem);
          if (
            survey.code === this._activeSurvey.code &&
            survey.authorizationGroup === this._activeSurvey.authorizationGroup
          ) {
            this._surveys[index].weights = surveyMetaData.weights;
            this._activeSurvey.weights = surveyMetaData.weights;
          }
        });
        this.tablebases.next(tablebases);
        this.reportUnits.next(reportUnits);
        this.surveyDataWeights.next(surveyMetaData.weights);
        this.surveyStabilityLevels.next(stabilityLevels);
        this.dataItemService.setDataItems(surveyDataItems);
        this.shouldIncludeWeightedProfilesAdi =
          this.hasWeightedProfileAdi(adiItems);
        this.ignoreZeroWeightedResps.next(
          this.setIgnoreZeroWeightedResps(iniData)
        );
        this._topojsonUrl = surveyMetaData.geoJson;
        this.updateActiveTable();
        if (!surveysChanged) {
          this.resetOriginalDocument();
        }
        if (shouldSetRestoreDocumentStateFromTempDocumentState) {
          this.setRestoreDocumentStateFromTempDocumentState();
        }
        this.requestLoadingService.setLoading({
          target: 'document',
          isLoading: false,
        });

        this.updateCrossTabData();
      },
      (error) => {
        console.log(`error`, error);

        this.messageService.showSnackBar(error, 'OK', 10000);
        this.requestLoadingService.setLoading({
          target: 'document',
          isLoading: false,
        });
      }
    );
  }

  private hasWeightedProfileAdi(adiItems: GetAdiItem[]): boolean {
    return !!adiItems.find(
      (item) => item?.ADI === 'WEIGHTED_PROFILES_POPULATION'
    );
  }

  private setIgnoreZeroWeightedResps(
    iniDataItems: IniDataItem[]
  ): IgnoreZeroWeightedResps {
    const iniItem = iniDataItems.find(
      (item) => item.name === 'ignorezeroresps'
    );
    return !!iniItem
      ? {
          editable: true,
          enabled: iniItem.value === '1',
        }
      : {
          editable: false,
          enabled: false,
        };
  }

  private setTitleModeAndLevels(): void {
    this.titleModeService.updateTitleMode(
      this._document.content.activeTitleMode || DisplayType.title
    );
    this.titleLevelsService.updateTitleLevels(
      this._document.content.titleLevels || []
    );
  }

  private clearDocumentData(): void {
    this._document.content.columns = [];
    this._document.content.rows = [];
    this._document.content.activeTableId = undefined;
    this._document.content.tables = [this._document.content.tables[0]];
    this._document.content.chartSettings = [];
    this._document.content.notes = [];
    this._document.content.reportPreferences = undefined;
    this._document.content.pMapsPreferences = null;
    this._document.content.codingGridPreferences = undefined;
    this._document.content.weight = DEFAULT_WEIGHT_INDEX;
  }

  private updateActiveTable(): void {
    const activeTableId = this._document.content.activeTableId;
    let activeTable = this.isPredefinedTablebaseActive()
      ? this.tablebases.value.find(
          (table: Target) => table.id === activeTableId
        )
      : activeTableId
      ? this._document.content.tables.find(
          (target: Target) => target.id === activeTableId
        )
      : this.getDefaultTablebase();
    if (!activeTable) {
      activeTable = this.getDefaultTablebase();
    }

    this.setActiveTable(activeTable, false);
  }

  public getTargetKey(type: TargetType): string {
    return this.targetTypeMap[type];
  }

  private resetOriginalDocument(): void {
    this._originalDocument = cloneDeep(this._document);
  }

  private getActiveSurvey(): Survey {
    let activeSurvey;
    if (this._document.content.activeSurveyId) {
      activeSurvey = this._document.content.surveys.find(
        (survey: Survey) =>
          survey.code === this._document.content.activeSurveyId
      );
    }

    return activeSurvey || this._document.content.surveys[0];
  }

  private loadSurveyDetails(
    survey: Survey
  ): Observable<
    [
      Survey[],
      Target[],
      number,
      SurveyMetaData,
      StabilityLevels,
      [SurveyDataItems, GetAdiItem[], IniDataItem[]]
    ]
  > {
    return forkJoin([
      this.audienceGroupService.loadSurveys(this._surveys),
      this.loadTableBases(survey),
      this.loadReportUnits(survey),
      this.loadSurveyMetaData(survey),
      this.loadSurveyStabilityLevels(survey),
      forkJoin([
        this.loadDataItems(survey),
        this.loadAdi(survey),
        this.loadIniData(survey),
      ]),
    ]);
  }

  private stabilityLevelsRequest(body): Observable<StabilityLevelsResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.getstabilitylevels,
      { body }
    );
  }

  private loadSurveyStabilityLevels(
    survey: Survey
  ): Observable<StabilityLevels> {
    return this.stabilityLevelsRequest({
      surveyVersion: survey.code,
      authorizationGroup: survey.authorizationGroup,
    }).pipe(
      first(),
      tap((data: StabilityLevelsResponse) => {
        if (!data.success) {
          throw new Error('Failed to retrieve survey stability levels');
        }
      }),
      map((data: StabilityLevelsResponse) => ({
        firstLevel: data.firstLevel,
        secondLevel: data.secondLevel,
      }))
    );
  }

  private populationBasesRequest(body): Observable<PopulationBasesResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.populationBases,
      { body }
    );
  }

  private dataItemsRequest(body): Observable<DataItemsResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.getdataitems,
      { body }
    );
  }

  private getAdiRequest(body: GetAdiRequest): Observable<GetAdiResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.getadi,
      { body }
    );
  }

  private getIniDataRequest(
    body: GetIniDataRequest
  ): Observable<GetIniDataResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.getinidata,
      { body }
    );
  }

  public loadTableBases(survey: Survey): Observable<Target[]> {
    return this.populationBasesRequest({ survey_code: survey.code }).pipe(
      first(),
      map((data: PopulationBasesResponse) =>
        data.success ? this.formatTablebaseResults(data) : []
      )
    );
  }

  private loadDataItems(survey: Survey): Observable<SurveyDataItems> {
    return this.dataItemsRequest({
      survey_code: survey.code,
      category: 'ini',
      subcategory: 'SURVEYTIME',
      names: ['Available_Dataitems', 'Default_Dataitems'],
    }).pipe(
      first(),
      map((data: DataItemsResponse) =>
        data.success
          ? {
              availableDataItemIds:
                data.results
                  .find(
                    (baseItem: DataItemsBaseItem) =>
                      baseItem.name.toLowerCase() === 'available_dataitems'
                  )
                  ?.value.split(',')
                  .map((value: string) => Number(value)) ?? [],
              defaultDataItemIds:
                data.results
                  .find(
                    (baseItem: DataItemsBaseItem) =>
                      baseItem.name.toLowerCase() === 'default_dataitems'
                  )
                  ?.value.split(',')
                  .map((value: string) => Number(value)) ?? [],
            }
          : {
              availableDataItemIds: [],
              defaultDataItemIds: [],
            }
      )
    );
  }

  private loadAdi(survey: Survey): Observable<GetAdiItem[]> {
    return this.getAdiRequest({
      survey_code: survey.code,
    }).pipe(
      first(),
      map((data: GetAdiResponse): GetAdiItem[] =>
        data.success ? data.results : []
      )
    );
  }

  private loadIniData(survey: Survey): Observable<IniDataItem[]> {
    return this.getIniDataRequest({
      survey_code: survey.code,
    }).pipe(
      first(),
      map((data: GetIniDataResponse): any => (data.success ? data.results : []))
    );
  }

  private surveyMetaDataRequest(body): Observable<GetSurveyMetaDataResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.getSurveyMetadata,
      { body }
    );
  }

  public getSurvey(surveyCode: string): Observable<GetSurveyResponse> {
    return this.apiService.request(
      'POST',
      environment.api.codebook.url,
      environment.api.codebook.endPoint.getSurvey,
      { body: { surveyVersion: surveyCode } }
    );
  }

  private loadSurveyMetaData(survey: Survey): Observable<SurveyMetaData> {
    return this.surveyMetaDataRequest({ surveyVersion: survey.code }).pipe(
      first(),
      tap((data) => {
        if (!data.success) {
          throw new Error('Failed to retrieve survey metadata');
        }
      }),
      map((data) => ({
        weights:
          data.weightsDescription.map((weight) => {
            // response returns weight.index as a string (and number everywhere else), so needs correcting
            return {
              index: parseInt('' + weight.index, 10),
              description: weight.description,
            };
          }) || [],
        geoJson: data.geoJson,
      }))
    );
  }

  private reportUnitsRequest(body): Observable<GetReportUnitsResponse> {
    return this.apiService.request(
      'POST',
      environment.api.survey.url,
      environment.api.survey.endPoint.getreportunits,
      { body }
    );
  }

  private loadReportUnits(survey: Survey): Observable<number> {
    return this.reportUnitsRequest({ surveyVersion: survey.code }).pipe(
      first(),
      tap((data: GetReportUnitsResponse) => {
        if (!data.success) {
          throw new Error('Failed to retrieve survey report units');
        }
      }),
      map((data: GetReportUnitsResponse) => data.reportUnits)
    );
  }

  private formatTablebaseResults(data: PopulationBasesResponse): Target[] {
    return data.results.map((result: PopulationBaseItem) => {
      const populationBaseItem = JSON.parse(result.value);
      const target = this.targetService.separateTargets([
        this.targetService.createTarget({
          title: populationBaseItem.name,
          coding: populationBaseItem.coding,
        }),
      ])[0];
      target.id = `tablebase-${populationBaseItem.coding}`;
      target.isDefaultTablebase = populationBaseItem.isDefault;
      return target;
    });
  }

  private isPredefinedTablebaseActive(): boolean {
    const activeTableId = this._document.content.activeTableId;
    return activeTableId && activeTableId.startsWith('tablebase');
  }

  private getDefaultTablebase(): Target {
    if (this._document.content.tables.length === 0) {
      this.createInitialTable();
    }
    return this._document.content.tables[0];
  }

  private notifyDocumentStateChanged(
    shouldUnsetRestoreDocumentState: boolean = false
  ): void {
    const restoreDocState = shouldUnsetRestoreDocumentState
      ? null
      : cloneDeep(this._originalDocument);
    this.setRestoreDocumentState(restoreDocState);
    this.updateDocumentState.next(this._document);
    this.resetOriginalDocument();
  }

  private setRestoreDocumentState(
    prevDocState: TupDocument<SurveyTimeDocument> | null
  ): void {
    this.restoreDocumentState.next(prevDocState);
  }

  private formatSurveyCodeMap(surveys: Survey[]): SurveyCodeMap {
    const surveyCodeMap = surveys.reduce(
      (acc, survey) => ({
        ...acc,
        [survey.code]: survey.customCode || survey.code,
      }),
      {}
    );

    const trendingCalculations = this.getTrendingCalculations();
    trendingCalculations.forEach((calculation: TrendingCalculationItem) => {
      surveyCodeMap[calculation.coding] = this.trendingCodingPipe.transform(
        calculation.coding,
        surveyCodeMap
      );
    });
    return surveyCodeMap;
  }

  public saveChartStyleDocumentObject(
    fileName: string,
    description: string,
    containerName: string,
    chartStyle: DocumentChartStyle
  ): void {
    const doc = this.createChartStyleDocumentObject(
      fileName,
      description,
      chartStyle
    );

    this.tupDocumentService.create(containerName, doc).subscribe((result) => {
      const info = `Chart style ${fileName} has been saved in drive ${containerName}`;
      this.userMessageService.showSnackBar(info, 'OK');
    });
  }
}
