<div
  fxLayout="row"
  tupDocumentEventEmitter
  (update)="onUpdateName($event)"
>
  <div
    class="span-container"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <span class="doc-title">{{ currentDoc?.metadata.name }}</span>
    <button
      *ngIf="!(currentDoc | isReadOnly)"
      mat-icon-button
      matTooltip="Edit"
      (click)="onEdit()"
      onclick="this.blur()"
    >
      <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
    </button>
  </div>
</div>
