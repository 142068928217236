import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  CodingDataMap,
  CodingGridSearchHit,
  CodingGridTableRow,
  DEFAULT_CODING_GRID_COLUMNS,
  MAX_TITLE_LENGTH,
  READONLY_CODING_GRID_COLUMNS,
  SendCodingGridTableRow,
  TitleModeGridTableRow,
} from '../../models/coding-grid.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CtrlShiftKeyStates } from 'src/app/directives';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import {
  ALL_RESPONDENTS_CODING,
  CodingGridPreference,
  DropzoneEnterData,
  SearchHit,
  TARGET_TYPE_MAP,
  TargetItem,
  TargetType,
  ViewType,
} from 'src/app/models';
import {
  ColumnRowActionItem,
  COMBINE_BUTTON_ACTION_ITEMS,
  CombineActionItem,
  DeleteTargetTypesActionItem,
  MoveToActionItem,
  SEND_TO_ACTION_ITEMS,
  TITLE_ACTIONS,
  TitleModeActionItem,
} from '../../models/action.model';
import { CrosstabService } from '../../services';
import { DeleteTargetTypesAction } from '../../actions/DeleteTargetTypesAction';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MoveTargetItemsActionContext } from '../../actions/MoveTargetItemsAction';
import { MatSort, Sort } from '@angular/material/sort';
import { cloneDeep, isEqual } from 'lodash';
import { parseNumberWithThousandSeparator } from '../../utils/stringHelper';
import { compare } from '../../utils/sortHelper';
import { CodingGridService } from '../../services/coding-grid.service';

type RowId = number;
type IsSelected = boolean;

interface CodingGridTableRowWithSearchHighlights extends CodingGridTableRow {
  searchFields?: string[];
  searchFocus?: string;
  scrollIntoView?: boolean;
}

@Component({
  selector: 'app-coding-grid-table',
  templateUrl: './coding-grid-table.component.html',
  styleUrls: ['./coding-grid-table.component.scss'],
})
export class CodingGridTableComponent
  implements OnChanges, OnInit, AfterViewChecked, OnDestroy
{
  public readonly maxTitleLength = MAX_TITLE_LENGTH;
  public readonly targetType: typeof TargetType = TargetType;
  public readonly targetTypeMap: Record<TargetType, string> = TARGET_TYPE_MAP;
  public displayedColumns: string[] = DEFAULT_CODING_GRID_COLUMNS;

  @Input() dropzoneMenu: MatMenu;
  @Input() isViewActive: boolean;
  @Input() isReadonly = true;
  @Input() tableTargetType: TargetType;
  @Input() data: CodingGridTableRow[];
  @Input() isDeletable: boolean;
  @Input() isProgressing: boolean;
  @Input() searchHits: SearchHit[];
  @Input() sort: Sort;
  @Input() gridPreference: CodingGridPreference;
  @Input() isAddToRowsOnly = false;

  public scrollbarWidth = 0;

  public dataSource: MatTableDataSource<CodingGridTableRowWithSearchHighlights> =
    new TableVirtualScrollDataSource([]);

  private numberOfValidRows: number;
  private shiftPressed: boolean;
  private lastSelectedRowId: RowId;
  private rowIds: RowId[] = [];
  public selectedRowIds: Record<RowId, IsSelected> = {};
  public ALL_RESPONDENTS_CODING = ALL_RESPONDENTS_CODING;

  public selectedRows: CodingGridTableRow[] = [];

  public combineActionItems = COMBINE_BUTTON_ACTION_ITEMS;

  public contextMenuPosition = { x: '0px', y: '0px' };
  @ViewChild('gridContextMenuTrigger') private gridContextMenu: MatMenuTrigger;
  public dropzoneMenuPosition = { x: '0px', y: '0px' };

  @ViewChild('virtualScroll', { static: true })
  public virtualScrollViewport: CdkVirtualScrollViewport;

  @ViewChild('gridTableHeader') gridTableHeader: ElementRef;

  public showScrollToTopBtn = false;

  @Output() selectedRowsChange = new EventEmitter<CodingGridTableRow[]>();
  @Output() clickedRow = new EventEmitter<CodingGridTableRow>();
  @Output() clickedEditTitleRow = new EventEmitter<CodingGridTableRow>();
  @Output() clickedEmptyRow = new EventEmitter<TargetType>();
  @Output() deleteClicked = new EventEmitter<CodingGridTableRow>();
  @Output() saveCustomAudienceClicked = new EventEmitter<CodingGridTableRow>();
  @Output() deleteAllClicked = new EventEmitter<DeleteTargetTypesActionItem>();
  @Output() editClicked = new EventEmitter<CodingGridTableRow>();
  @Output() combineClicked = new EventEmitter<CombineActionItem>();
  @Output() separateCountClicked = new EventEmitter<CodingGridTableRow>();
  @Output() changeTargetTitleModeClicked =
    new EventEmitter<TitleModeGridTableRow>();
  @Output() sendToClicked = new EventEmitter<SendCodingGridTableRow>();
  @Output() assignGroupNameClicked = new EventEmitter<CodingGridTableRow>();
  @Output() duplicateClicked = new EventEmitter<CodingGridTableRow>();
  @Output() nTileSettingsClicked = new EventEmitter<CodingGridTableRow>();
  @Output() dropzoneEnter: EventEmitter<DropzoneEnterData> =
    new EventEmitter<DropzoneEnterData>();
  @Output() dropzoneLeave: EventEmitter<void> = new EventEmitter<void>();
  @Output() dropzoneDrop: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Output() moveItems: EventEmitter<MoveTargetItemsActionContext> =
    new EventEmitter<MoveTargetItemsActionContext>();
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() sortedRows = new EventEmitter<CodingGridTableRow[]>();

  public sendToActionItems = SEND_TO_ACTION_ITEMS;
  public changeTitleModeItems = (TITLE_ACTIONS[0] as ColumnRowActionItem)
    .actions;
  public codingDataMap: CodingDataMap;

  public dragRow: CodingGridTableRow;
  public shouldShowDragHandle = false;
  public draggingRows: CodingGridTableRow[] = null;
  public draggingRowIds: number[] = [];
  public dropTargetId = -1;
  public draggingOverTopOrBottom = '';
  public codingColumnExpanded = false;
  @ViewChild('dndDragImage') dragImage;
  @ViewChild('idHover', { static: true }) idHover;
  @ViewChild(MatSort, { static: true }) tableSort: MatSort;

  constructor(
    private crosstabService: CrosstabService,
    private codingGridService: CodingGridService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.crosstabService.codingDataMap$.subscribe((map: CodingDataMap) => {
      const dataMapChanged = !isEqual(this.codingDataMap, map);
      this.codingDataMap = map;
      if (dataMapChanged && this.isSortActive()) {
        this.refreshTable();
      }
    });

    const displayedColumns = this.isReadonly
      ? READONLY_CODING_GRID_COLUMNS
      : DEFAULT_CODING_GRID_COLUMNS;

    if (this.gridPreference?.hiddenColumns) {
      this.displayedColumns = displayedColumns.filter(
        (column) => !this.gridPreference.hiddenColumns.includes(column)
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isViewActive) {
      this.triggerFakeWindowResizeEvent();
    }
    if (changes.data) {
      this.refreshTable();
      this.triggerFakeWindowResizeEvent();
      this.updateRowIds(this.data);
      this.updateSelectedRowsAndIds();
      this.scrollToAddedRows(changes);
    }
    if (changes.sort && !changes.sort.firstChange) {
      this.refreshTable();
    }
    if (changes?.searchHits) {
      if (changes.searchHits.currentValue.length === 0) {
        this.searchHits = [];
        this.refreshTable();
      } else {
        const codingGridSearchHits: SearchHit[] =
          changes.searchHits.currentValue.filter(
            (hit: SearchHit) =>
              hit.viewType === ViewType.codingGrid &&
              (hit.details as CodingGridSearchHit).gridView ===
                this.tableTargetType
          );

        if (codingGridSearchHits.length > 0) {
          this.searchHits = codingGridSearchHits;
          this.refreshTable();
        }
      }
    }
    if (
      changes.gridPreference &&
      changes.gridPreference.previousValue !==
        changes.gridPreference.currentValue
    ) {
      const hiddenColumns = changes.gridPreference.currentValue.hiddenColumns;
      this.displayedColumns = DEFAULT_CODING_GRID_COLUMNS.filter(
        (column) => !hiddenColumns.includes(column)
      );
    }
  }

  ngAfterViewChecked() {
    if (this.searchHits && this.searchHits.length > 0) {
      this.focusOnCurrentSearchItem();
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.removeScrollListener();
  }

  public onScrollToTopClicked(): void {
    this.virtualScrollViewport.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public onSortChange(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  public onDragHandleMouseEnter(): void {
    this.shouldShowDragHandle = true;
  }

  public onDragHandleMouseLeave(): void {
    this.shouldShowDragHandle = false;
    this.hideIdHover();
  }

  public onSourceRowMouseEnter(event, element: CodingGridTableRow): void {
    if (
      element.isEmptyRow ||
      element.targetItem?.target.coding === ALL_RESPONDENTS_CODING ||
      this.isSortActive()
    ) {
      this.resetIdHover();
      return;
    }
    this.dragRow = element;
    const targetElement = event.currentTarget;
    const rect = targetElement.getBoundingClientRect();
    const screenX = rect.left + window.pageXOffset;
    const screenY = rect.top + window.pageYOffset;

    this.idHover.nativeElement.style.top = screenY + 'px';
    this.idHover.nativeElement.style.left = screenX + 'px';
  }

  public onDragRowStart(event, element: CodingGridTableRow): void {
    this.codingGridService.setDraggingGridRow(true);
    (event.dataTransfer as any).setDragImage(
      this.dragImage.nativeElement,
      -10,
      -10
    );
    if (this.tableTargetType === TargetType.tables && this.selectedRowIds[1]) {
      this.selectedRowIds[1] = false;
    }
    this.selectedRowIds[element.id] = true;
    this.lastSelectedRowId = element.id;
    this.emitSelectedCodingGridTableRowChanged();
    this.draggingRows = this.selectedRows;
    this.draggingRowIds = this.draggingRows.map((row) => row.id);
    setTimeout(() => {
      this.hideIdHover();
    }, 0);
  }

  public onDragRowEnd(): void {
    this.resetDragState();
    this.resetIdHover();
  }

  public onDropzoneEnter(
    event: MouseEvent,
    trigger: MatMenuTrigger,
    targetType: TargetType,
    element: CodingGridTableRow
  ): void {
    this.checkDraggingOverTopOrBottom(event);

    this.dropTargetId = element.id;
    if (this.shouldDisableDnD(element)) {
      return;
    }

    this.dropzoneMenuPosition.x = event.clientX + 'px';
    this.dropzoneMenuPosition.y = event.clientY + 'px';

    this.dropzoneEnter.emit({
      trigger,
      targetType,
      targetItem: element.targetItem,
    });
  }

  public onDropzoneOver(event: MouseEvent): void {
    this.checkDraggingOverTopOrBottom(event);
  }

  public onDropzoneLeave(element: CodingGridTableRow): void {
    if (this.shouldDisableDnD(element)) {
      return;
    }
    this.dropzoneLeave.emit();
  }

  public onDrop(
    event,
    targetType: TargetType,
    element: CodingGridTableRow
  ): void {
    if (this.isDragging()) {
      this.emitMoveItemChange(element);
      return;
    }

    if (this.shouldDisableDnD(element)) {
      return;
    }

    this.dropzoneDrop.emit(element.targetItem);
  }

  public isAllSelected() {
    return (
      this.selectedRows.length &&
      this.selectedRows.length === this.numberOfValidRows
    );
  }

  public onAllRowsSelectedChange(event: MatCheckboxChange): void {
    this.setAllRowsSelected(event.checked);
    this.emitSelectedCodingGridTableRowChanged();
    this.lastSelectedRowId = null;
  }

  public onSingleRowSelectedChange(
    event: MatCheckboxChange,
    element: CodingGridTableRow
  ): void {
    const selectedRowId = element.id;

    if (this.shiftPressed && this.lastSelectedRowId) {
      const lastSelectedRowIndex = this.rowIds.indexOf(this.lastSelectedRowId);
      const selectedRowIndex = this.rowIds.indexOf(selectedRowId);

      let lowerIndex = selectedRowIndex;
      let upperIndex = lastSelectedRowIndex;

      if (lowerIndex > upperIndex) {
        lowerIndex = lastSelectedRowIndex;
        upperIndex = selectedRowIndex;
      }

      for (let i = lowerIndex; i <= upperIndex; i++) {
        const id = this.rowIds[i];

        this.selectedRowIds[id] = event.checked;
      }
    } else {
      this.selectedRowIds[selectedRowId] = event.checked;
    }

    this.lastSelectedRowId = selectedRowId;
    this.emitSelectedCodingGridTableRowChanged();
  }

  public onCtrlShift(states: CtrlShiftKeyStates): void {
    this.shiftPressed = states.shiftPressed;
  }

  public resetSelectedRowIds(): void {
    this.triggerFakeWindowResizeEvent();
    this.selectedRowIds = [];
    this.emitSelectedCodingGridTableRowChanged();
  }

  public setSelectedRows(rows: CodingGridTableRow[]): void {
    rows.forEach((row: CodingGridTableRow) => {
      this.selectedRowIds[row.id] = true;
    });

    this.emitSelectedCodingGridTableRowChanged();
  }

  public onRowClicked(row: CodingGridTableRow): void {
    if (row.isEmptyRow) {
      this.clickedEmptyRow.emit(this.tableTargetType);
    } else {
      this.clickedRow.emit(row);
    }
  }

  public onClickRenameRowTitle(row: CodingGridTableRow): void {
    this.clickedEditTitleRow.emit(row);
  }

  public onContextMenuTrigger(
    event: MouseEvent,
    row: CodingGridTableRow
  ): void {
    event.preventDefault();
    if (row.isEmptyRow) {
      return;
    }
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.gridContextMenu.openMenu();

    this.setSelectedRows([row]);
  }

  public onDeleteClick(row?: CodingGridTableRow) {
    this.deleteClicked.emit(row);
  }
  public onSaveCustomAudience(row?: CodingGridTableRow): void {
    this.saveCustomAudienceClicked.emit(row);
  }

  public onDeleteAllClick(): void {
    this.deleteAllClicked.emit({
      name: 'Delete all rows',
      action: DeleteTargetTypesAction,
      targetTypes: [this.tableTargetType],
    });
  }

  public onDeleteCodingGridClick(): void {
    this.deleteAllClicked.emit({
      name: 'Delete coding grid',
      action: DeleteTargetTypesAction,
      targetTypes: [this.tableTargetType],
    });
  }

  public onEditClick(row?: CodingGridTableRow) {
    this.editClicked.emit(row);
  }

  public onCombineActionClicked(actionItem: CombineActionItem): void {
    this.combineClicked.emit(actionItem);
  }

  public onChangeTargetTitleModeActionClicked(
    actionItem: TitleModeActionItem,
    row?: CodingGridTableRow
  ): void {
    this.changeTargetTitleModeClicked.emit({ ...actionItem, row });
  }

  public onSeparateCountActionClicked(row?: CodingGridTableRow): void {
    this.separateCountClicked.emit(row);
  }

  public onSendToClick(
    actionItem: MoveToActionItem,
    row?: CodingGridTableRow
  ): void {
    this.sendToClicked.emit({
      actionItem,
      row,
    });
  }

  public onNTileSettingActionClicked(row?: CodingGridTableRow): void {
    this.nTileSettingsClicked.emit(row);
  }

  public onRenameGroupName(row?: CodingGridTableRow): void {
    this.assignGroupNameClicked.emit(row);
  }

  public onDuplicateClick(row?: CodingGridTableRow): void {
    this.duplicateClicked.emit(row);
  }

  public expandCodingColumn(): void {
    this.codingColumnExpanded = !this.codingColumnExpanded;
  }

  private removeScrollListener(): void {
    this.virtualScrollViewport.elementRef.nativeElement.removeEventListener(
      'scroll',
      () => {}
    );
  }

  private refreshTable(): void {
    this.populateTableData(this.data);
  }

  private populateTableData(data: CodingGridTableRow[]): void {
    const sortedRows = this.formatSortData(data);
    this.sortedRows.emit(sortedRows);
    this.dataSource.data = sortedRows.map((row) => {
      const rowSearchHits =
        this.searchHits?.length > 0
          ? this.searchHits.filter(
              (result) =>
                (result.details as CodingGridSearchHit).rowId === row.id
            )
          : [];
      const searchFields =
        rowSearchHits.length > 0
          ? rowSearchHits.map(
              (hit) => (hit.details as CodingGridSearchHit).field
            )
          : [];
      let searchFocus = '';
      let scrollIntoView = false;
      const focusRow = rowSearchHits.filter(
        (hit) => (hit.details as CodingGridSearchHit).focus
      );
      if (focusRow.length) {
        searchFocus = (focusRow[0].details as CodingGridSearchHit).field;
        scrollIntoView = true;
      }

      return {
        ...row,
        searchFields,
        searchFocus,
        scrollIntoView,
      };
    });

    this.numberOfValidRows = this.data.filter(
      (row: CodingGridTableRow) => !row.isEmptyRow
    ).length;
    this.setSort(this.sort);
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
  }

  private setAllRowsSelected(isSelected: boolean): void {
    this.data.forEach((row: CodingGridTableRow) => {
      if (!row.isEmptyRow) {
        this.selectedRowIds[row.id] = isSelected;
      }
    });
  }

  private emitSelectedCodingGridTableRowChanged(): void {
    this.selectedRows = this.data.filter((row) => this.selectedRowIds[row.id]);
    this.selectedRowsChange.emit(this.selectedRows);
  }

  private updateRowIds(data: CodingGridTableRow[]): void {
    this.rowIds = data.filter((row) => !row.isEmptyRow).map((row) => row.id);
  }

  private updateSelectedRowsAndIds(): void {
    if (this.lastSelectedRowId) {
      const lastSelectedRow = this.rowIds.find(
        (rowId: number) => rowId === this.lastSelectedRowId
      );
      if (!lastSelectedRow) {
        this.lastSelectedRowId = null;
      }
    }
    if (this.selectedRows.length > 0) {
      this.selectedRows = this.selectedRows.filter((row) =>
        this.rowIds.includes(row.id)
      );
    }

    if (Object.keys(this.selectedRowIds).length > 0) {
      this.selectedRowIds = Object.keys(this.selectedRowIds).reduce(
        (acc, key: string) => ({
          ...acc,
          [key]: this.rowIds.includes(Number(key))
            ? this.selectedRowIds[key]
            : false,
        }),
        {}
      );
      setTimeout(() => {
        this.emitSelectedCodingGridTableRowChanged();
      }, 0);
    }
  }

  private triggerFakeWindowResizeEvent(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    setTimeout(() => {
      this.addScrollListener();
    }, 1000);
  }

  private shouldDisableDnD(element: CodingGridTableRow): boolean {
    return (
      element.targetItem?.target.coding === ALL_RESPONDENTS_CODING ||
      this.isDragging()
    );
  }

  private addScrollListener() {
    const scrollOffset = 200;
    this.showScrollToTopBtn = false;
    const container: HTMLElement =
      this.virtualScrollViewport.elementRef.nativeElement;
    if (!container) {
      return;
    }
    this.scrollbarWidth = container.offsetWidth - container.clientWidth;
    const header: HTMLElement = this.gridTableHeader.nativeElement;
    container.addEventListener('scroll', (event) => {
      header.scrollLeft = container.scrollLeft;
      this.showScrollToTopBtn = container.scrollTop > scrollOffset;
    });
  }

  private focusOnCurrentSearchItem(): void {
    const focusElementIndex = this.dataSource.data.findIndex(
      (row) => row.searchFocus !== '' && row.scrollIntoView === true
    );
    if (focusElementIndex >= 0) {
      this.virtualScrollViewport.scrollTo({
        top: 50 * focusElementIndex,
        behavior: 'auto',
      });

      const className = '.highlight-focused-search-item';

      const elementsWithHighlighting =
        this.elementRef.nativeElement.querySelectorAll(className);

      elementsWithHighlighting[0]?.scrollIntoView(false, {
        behavior: 'smooth',
      });
      this.dataSource.data[focusElementIndex].scrollIntoView = true;
    }
  }

  private emitMoveItemChange(element: CodingGridTableRow): void {
    const sourceIndices = this.draggingRows
      .map((row) => row.targetItem.index)
      .sort((a, b) => a - b);
    const targetIndex =
      element.targetItem.index +
      (this.draggingOverTopOrBottom === 'top' ? -1 : 0);
    if (sourceIndices.length === 1 && sourceIndices[0] === targetIndex) {
      return;
    }
    this.moveItems.emit({
      type: this.tableTargetType,
      sourceIndices,
      targetIndex,
    });
    this.onDragRowEnd();
  }

  private resetDragState(): void {
    this.draggingRows = null;
    this.draggingRowIds = [];
    this.dropTargetId = -1;
    this.draggingOverTopOrBottom = '';
    this.codingGridService.setDraggingGridRow(false);
  }

  private resetIdHover(): void {
    this.dragRow = null;
    this.hideIdHover();
  }

  private hideIdHover(): void {
    this.idHover.nativeElement.style.top = '-1000px';
    this.idHover.nativeElement.style.left = '-1000px';
  }

  private checkDraggingOverTopOrBottom(event: MouseEvent): void {
    const targetElement = event.currentTarget as any;
    const rect = targetElement.getBoundingClientRect();
    const hoverMiddleY = (rect.bottom - rect.top) / 2;
    const hoverClientY = event.clientY - rect.top;
    this.draggingOverTopOrBottom =
      hoverClientY > hoverMiddleY ? 'bottom' : 'top';
  }

  private isDragging(): boolean {
    return this.draggingRows !== null;
  }

  private formatSortData(data: CodingGridTableRow[]): CodingGridTableRow[] {
    if (!this.isSortActive()) {
      return data;
    }

    const isAsc = this.sort.direction === 'asc';
    const dataKey = this.sort.active;
    const tableData = cloneDeep(data);
    const emptyRows = tableData.slice(-10);
    const actualRows = tableData.slice(0, -10);
    let sortedData;
    if (dataKey === 'resps' || dataKey === 'population') {
      sortedData = actualRows.sort((a, b) => {
        const itemA = this.codingDataMap[a.targetId]
          ? parseNumberWithThousandSeparator(
              this.codingDataMap[a.targetId][dataKey]
            )
          : 0;
        const itemB = this.codingDataMap[b.targetId]
          ? parseNumberWithThousandSeparator(
              this.codingDataMap[b.targetId][dataKey]
            )
          : 0;
        return compare(itemA, itemB, isAsc);
      });
    } else {
      sortedData = actualRows.sort((a, b) => {
        return compare(a[dataKey], b[dataKey], isAsc);
      });
    }
    return sortedData.concat(emptyRows);
  }

  private setSort(sort: Sort): void {
    this.tableSort.active = sort.active;
    this.tableSort.direction = sort.direction;
  }

  private isSortActive(): boolean {
    return this.sort.direction !== '';
  }

  private scrollToAddedRows(changes: SimpleChanges) {
    const data = changes.data;
    if (
      !data.firstChange &&
      data.currentValue.length > data.previousValue.length
    ) {
      setTimeout(() => {
        this.virtualScrollViewport.scrollTo({
          bottom: 0,
          behavior: 'auto',
        });

        setTimeout(() => {
          const className = '.is-selected';

          const elementsWithHighlighting =
            this.elementRef.nativeElement.querySelectorAll(className);
          if (elementsWithHighlighting.length > 0) {
            elementsWithHighlighting[
              elementsWithHighlighting.length - 1
            ].scrollIntoView({
              block: 'nearest',
              behavior: 'instant',
            });
          }
        }, 100);
      }, 0);
    }
  }
}
