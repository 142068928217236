import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { ErrorHandlingService } from '../../services/error-handling.service';

@Component({
  templateUrl: './error-handling-dialog.component.html',
  styleUrls: ['./error-handling-dialog.component.scss'],
})
export class ErrorHandlingDialogComponent implements AfterViewInit {
  public message: string;
  public panelOpenState = false;
  public disableAnimation = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ErrorHandlingDialogComponent>,
    private errorHandlingService: ErrorHandlingService,
    private messageService: TupUserMessageService
  ) {
    this.message = this.data.message;
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.disableAnimation = false));
  }

  public onCopyErrorMessage(): void {
    window.navigator.clipboard.writeText(this.data.message).then(
      () => {
        this.messageService.showSnackBar(
          'Error message copied to clipboard',
          'OK',
          10000
        );
      },
      () => {
        this.messageService.showSnackBar(
          'Error message failed to copy',
          'OK',
          10000
        );
      }
    );
  }

  public cancel(): void {
    window.location.reload();
  }

  public ok(): void {
    this.errorHandlingService.shouldUndoError(true);
    this.dialogRef.close();
  }
}
