import { Injectable } from '@angular/core';

import {
  TupXlsxChartBuilder,
  TupXlsxChartExporterService,
} from '@telmar-global/tup-document-exporter';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { DialogService } from './dialog.service';
import { GoogleDriveFileInfo } from '@telmar-global/tup-google-api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class XlsxChartsService {
  private _isExportingSubject = new BehaviorSubject<boolean>(false);
  public isExporting$: Observable<boolean> =
    this._isExportingSubject.asObservable();

  constructor(
    private exporterService: TupXlsxChartExporterService,
    private dialogService: DialogService,
    private messageService: TupUserMessageService
  ) {}

  public async saveAs(
    builder: TupXlsxChartBuilder,
    filename?: string
  ): Promise<void> {
    this.exporterService.saveAs(this.exporterService.build(builder), filename);
  }

  public async exportToSheets(
    builder: TupXlsxChartBuilder,
    filename?: string
  ): Promise<void> {
    this._isExportingSubject.next(true);
    this.exporterService
      .exportToSheets(this.exporterService.build(builder), filename)
      .then((fileInfo: GoogleDriveFileInfo) => {
        this.dialogService.exportedGoogleFile(fileInfo.webViewLink);
      })
      .catch((error) => {
        console.error(error);
        this.alertExportError(error);
      })
      .finally(() => {
        this._isExportingSubject.next(false);
      });
  }

  private alertExportError(error: string): void {
    console.log(`error`, error);
    this.messageService.showSnackBar(error, 'OK');
  }
}
