import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CategoryList,
  CategoryResult,
  CodebookNavigationResponse,
  CodebookSearchResponse,
  Statement,
  Survey,
} from '../models';
import { ApiService } from './api.service';
import { StatementFactory } from '../components/tree-view/tree.models';
import {
  DocumentAudienceGroup,
  DocumentAudienceGroupItem,
  SaveOwnCodesType,
  TupAudienceGroupsSearchService,
} from '@telmar-global/tup-audience-groups';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { TupAuthService } from '@telmar-global/tup-auth';
import { cloneDeep } from 'lodash';
import { expand, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CodebookService {
  categoryResult: CategoryResult;
  // tslint:disable-next-line:variable-name
  private _activeSurvey: Survey;
  // tslint:disable-next-line:variable-name
  private _categories: CategoryList[] = [];
  // tslint:disable-next-line:variable-name
  private _codebookData: Statement[] = [];

  constructor(
    private apiService: ApiService,
    private audienceGroupsSearchService: TupAudienceGroupsSearchService,
    private authService: TupAuthService
  ) {}

  public get activeSurvey(): Survey {
    return this._activeSurvey;
  }

  public set activeSurvey(survey: Survey) {
    this._activeSurvey = survey;
  }

  public get categories(): CategoryList[] {
    return this._categories;
  }

  public set categories(categoryList: CategoryList[]) {
    this._categories = categoryList;
  }

  public get codebookData(): Statement[] {
    return this._codebookData;
  }

  public set codebookData(statements: Statement[]) {
    this._codebookData = statements;
  }

  public resetCodebookCache(): void {
    this.categories = [];
    this.codebookData = [];
  }

  getCategories(survey: Survey): Observable<CategoryResult> {
    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.codebookNavigation,
          options
        )
        .pipe(
          expand((response) => {
            if (response.scrollId) {
              options.body['scrollId'] = response.scrollId;
              return this.apiService.request(
                'POST',
                environment.api.codebook.url,
                environment.api.codebook.endPoint.codebookNavigation,
                options
              );
            } else {
              return EMPTY;
            }
          }),
          reduce((acc, current) => ({
            ...acc,
            children: acc['children'].concat(current.children),
          }))
        )
        .subscribe(
          (data) => {
            this.categoryResult = {
              success: data.success,
              message: data.message,
              categories: [],
              categoryFilters: [],
            };

            data.children?.forEach((cat) => {
              this.categoryResult.categories.push({
                category: cat.key,
                categoryFilter: cat.categoryFilter,
                count: cat.children,
              });
            });

            this.categoryResult.categoryFilters = data.categoryFilters;
            observable.next(this.categoryResult);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  getChildren(
    survey: Survey,
    path: string,
    categoryFilter: string
  ): Observable<CodebookNavigationResponse> {
    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
          category: path,
          sortField: 'pos',
          sortOrder: 'asc',
          categoryFilters: categoryFilter,
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.codebookNavigation,
          options
        )
        .pipe(
          expand((response) => {
            if (response.scrollId) {
              options.body['scrollId'] = response.scrollId;
              return this.apiService.request(
                'POST',
                environment.api.codebook.url,
                environment.api.codebook.endPoint.codebookNavigation,
                options
              );
            } else {
              return EMPTY;
            }
          }),
          reduce((acc, current) => ({
            ...acc,
            children: acc['children'].concat(current.children),
          }))
        )
        .subscribe(
          (data) => {
            const result: CodebookNavigationResponse = {
              success: data.success,
              message: data.message,
              children: [],
            };

            if (data.children) {
              data.children.forEach((element) => {
                const node: Statement = {
                  categoryFilter: element.categoryFilter,
                  description: element.key.substring(path.length + 1),
                  path: element.key,
                  children: [],
                  validWeights: (element.autoweights || []).sort(),
                };

                if ('customtag' in element && element.customtag) {
                  node.customTag = element.customtag;
                }

                if ('geoData' in element && element.geoData) {
                  node.geoData = element.geoData;
                }

                const coding: string = element['data-reference'];
                if (coding) {
                  node.coding = coding;
                }
                result.children.push(node);
              });
            }

            observable.next(result);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  getAllChildren(
    survey: Survey,
    path: string | string[],
    categoryFilters: string | string[]
  ): Observable<CodebookSearchResponse> {
    return new Observable((observable) => {
      const options = {
        body: {
          surveyVersion: survey.code,
          authorizationGroup: survey.authorizationGroup,
          category: path,
          categoryFilters,
          sortField: 'pos',
          sortOrder: 'asc',
        },
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.getleafdata,
          options
        )
        .subscribe(
          (data) => {
            observable.next(this.processCodebook(data, 'children', 'key'));
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  getOwnCodesChildren(
    surveyCode: string,
    path: string,
    isShared: boolean = false,
    categoryFilter: string
  ): Observable<Statement[]> {
    const ownCodesType = path.startsWith('Custom Audiences')
      ? SaveOwnCodesType.audience
      : SaveOwnCodesType.media;
    const user = this.authService.user;
    const userEmail = user.attributes.email;
    const audienceContainer = this.authService.user.containers.find(
      (container) =>
        !isShared ? container.name === userEmail : container.name !== userEmail
    );
    return new Observable((observable) => {
      this.audienceGroupsSearchService.search(audienceContainer).subscribe(
        (data) => {
          const documents = data.filter(
            (document: TupDocument<DocumentAudienceGroup>) =>
              document.content.survey.code === surveyCode &&
              document.content.type === ownCodesType
          );
          observable.next(
            this.formatOwnCodesChildrenNodes(
              documents,
              path,
              isShared,
              userEmail,
              categoryFilter
            )
          );
          observable.complete();
        },
        (error) => {
          observable.error(error);
          observable.complete();
        }
      );
    });
  }

  static readonly SearchAnyKeyword: string = 'any';
  static readonly SearchAllKeyword: string = 'all';
  static readonly SearchPhrase: string = 'phrase_match';
  static readonly SearchStartingKeyword: string = 'starting';

  static readonly SearchInTitle = 'title';
  static readonly SearchInCode = 'code';

  // not used for now - static readonly SearchInBoth = 'both';

  search(
    searchText: string,
    category: string | string[],
    survey: Survey,
    matchMode: string = CodebookService.SearchAnyKeyword,
    searchInCode: boolean = false,
    resultSize: number = 1000
  ): Observable<CodebookSearchResponse> {
    const searchIn = searchInCode
      ? CodebookService.SearchInCode
      : CodebookService.SearchInTitle;

    return new Observable((observable) => {
      const body = {
        surveyVersion: survey.code,
        authorizationGroup: survey.authorizationGroup,
        matchText: searchText,
        matchType: matchMode,
        searchIn,
        weightIndex: 1,
        resultFrom: 0,
        // TODO: ask why do we need to add a size here. If we leave it empty, only ten results will be returned.
        resultSize,
        includeNodeMatch: true,
        disableCatAggs: true,
      };
      if (category) {
        body['topLevelFilterCategory'] = category;
      }
      const options = {
        body,
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.search,
          options
        )
        .pipe(
          expand((response) => {
            if (response.scrollId) {
              options.body['scrollId'] = response.scrollId;
              return this.apiService.request(
                'POST',
                environment.api.codebook.url,
                environment.api.codebook.endPoint.search,
                options
              );
            } else {
              return EMPTY;
            }
          }),
          reduce((acc, current) => ({
            ...acc,
            searchResults: acc['searchResults'].concat(current.searchResults),
          }))
        )
        .subscribe(
          (data) => {
            observable.next(
              this.processCodebook(data, 'searchResults', 'category')
            );
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
    });
  }

  searchCustomNodes(
    keyword: string,
    node: Statement,
    matchMode: string,
    searchInCode: boolean
  ): Statement[] {
    const clonedNode = cloneDeep(node);
    clonedNode.children = [];
    node.children.forEach((statement: Statement) => {
      const searchText = !searchInCode
        ? statement.description
        : statement.coding || '';
      const isMatched = this.matchKeyword(searchText, keyword, matchMode);
      if (isMatched) {
        clonedNode.children.push(statement);
      } else if (node.children.length > 0) {
        clonedNode.children.push(
          ...this.searchCustomNodes(keyword, statement, matchMode, searchInCode)
        );
      }
    });

    return clonedNode && clonedNode.children.length > 0 ? [clonedNode] : [];
  }

  public matchKeyword(
    text: string,
    keyword: string,
    searchMode: string
  ): boolean {
    const searchContext = text.toLocaleLowerCase().trim();
    const normalisedKeyword = keyword.toLocaleLowerCase().trim();
    const keywords = normalisedKeyword.split(/ +/);
    switch (searchMode) {
      case CodebookService.SearchAnyKeyword:
        const matches = searchContext.match(
          new RegExp(keywords.join('|'), 'gi')
        );
        return matches !== null && matches.length > 0;
      case CodebookService.SearchAllKeyword:
        return keywords.every((word) => searchContext.includes(word));
      case CodebookService.SearchPhrase:
        return ` ${searchContext} `.includes(` ${normalisedKeyword} `);
      case CodebookService.SearchStartingKeyword:
        return searchContext.startsWith(normalisedKeyword);
    }
    return false;
  }

  private processCodebook(
    data: any,
    resultKey: string = 'searchResults',
    categoryKey: string = 'category'
  ): CodebookSearchResponse {
    const codebook: CodebookSearchResponse = {
      success: data.success,
      message: data.message,
      tree: {
        description: 'root',
        path: '',
        children: [],
        categoryFilter: '',
      },
    };

    data[resultKey]?.sort((a, b) => {
      // sort nodes by name, and leafs by position
      const aParentCategory =
        a['data-reference'] === undefined
          ? a[categoryKey]
          : StatementFactory.getParent(a[categoryKey]);
      const bParentCategory =
        b['data-reference'] === undefined
          ? b[categoryKey]
          : StatementFactory.getParent(b[categoryKey]);

      return aParentCategory < bParentCategory
        ? -1
        : aParentCategory > bParentCategory
        ? 1
        : 0;
    });

    data[resultKey]?.forEach((element) => {
      const category = element[categoryKey];

      // parse the fulltext
      const levels = category.split('|');
      let children = codebook.tree.children;

      let path = '';
      levels.forEach((level, index) => {
        if (path.length !== 0) {
          path += '|';
        }
        path += level;

        let child = children.find((node) => node.description === level);
        if (!child) {
          const statement: Statement = {
            description: level,
            categoryFilter:
              element['category-filter'] || element['categoryFilter'],
            path,
            coding:
              index === levels.length - 1
                ? element['data-reference']
                : undefined,
            validWeights: (element.autoweights || []).sort(),
            children: [],
          };
          children.push(statement);
          child = children[children.length - 1];
        }
        children = child.children;
      });
    });

    return codebook;
  }

  private formatOwnCodesChildrenNodes(
    documents: TupDocument<DocumentAudienceGroup>[],
    ownCodesPath: string,
    isShared: boolean,
    userEmail: string,
    categoryFilter: string
  ): Statement[] {
    return documents.map((document: TupDocument<DocumentAudienceGroup>) => ({
      description: document.metadata.name,
      path: ownCodesPath + '|' + document.metadata.name,
      isCustomCoding: true,
      isShared,
      categoryFilter,
      isMyContainer: isShared
        ? document.metadata.by.attributes.email === userEmail
        : true,
      document,
      children: document.content.targets.map(
        (target: DocumentAudienceGroupItem, index: number) => ({
          categoryFilter,
          description: target.title,
          path:
            ownCodesPath + '|' + document.metadata.name + '|' + target.title,
          coding: target.coding,
          children: [],
          isCustomCoding: true,
          canEditTitle: isShared
            ? document.metadata.by.attributes.email === userEmail
            : true,
          customData: {
            index,
            target: target.options.target,
            document,
          },
        })
      ),
    }));
  }
}
