import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddInsightsDialogComponent } from '../dialogs/add-insights-dialog/add-insights-dialog.component';
import { Survey } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AddInsightsService {
  constructor(private dialog: MatDialog) {}

  public openDialog(
    selectedSurvey: Survey
  ): MatDialogRef<AddInsightsDialogComponent> {
    const dialogData = {
      selectedSurvey,
    };

    return this.dialog.open(AddInsightsDialogComponent, {
      data: dialogData,
      maxWidth: '95vw',
      width: '95vw',
      closeOnNavigation: true,
      autoFocus: false,
    });
  }
}
