import { Injectable } from '@angular/core';
import {
  TupDocument,
  TupDocumentService,
  TupDocumentStatus,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { Snapshot, SurveyTimeDocument, ViewType } from '../models';
import { DocumentService } from './document.service';
import { cloneDeep } from 'lodash';
import { RequestLoadingService } from './request-loading.service';
import { mergeMap } from 'rxjs/operators';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SnapshotService {
  constructor(
    private router: Router,
    private documentService: DocumentService,
    private tupDocumentService: TupDocumentService,
    private userMessageService: TupUserMessageService,
    private requestLoadingService: RequestLoadingService
  ) {}

  public applySnapshot(
    currentDoc: TupDocument<SurveyTimeDocument>,
    snapshotId: string
  ): void {
    this.requestLoadingService.setLoading({
      target: 'global',
      isLoading: true,
    });
    const containerName = currentDoc.metadata.container.name;
    const currentDocId = currentDoc.metadata.id;
    this.tupDocumentService
      .get(containerName, snapshotId)
      .pipe(
        mergeMap((snapshotDoc: TupDocument<unknown>) => {
          const newDoc = snapshotDoc as TupDocument<SurveyTimeDocument>;
          newDoc.metadata.status = TupDocumentStatus.ACTIVE;
          newDoc.metadata.id = currentDocId;
          newDoc.metadata.name = currentDoc.metadata.name;
          newDoc.content.snapshots = currentDoc.content.snapshots;
          return this.tupDocumentService.update(containerName, snapshotDoc);
        })
      )
      .subscribe(
        () => {
          this.documentService.resetDocumentChanges();

          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router
                .navigate([`doc/${currentDocId}/data`], {
                  queryParams: {
                    tab: ViewType.crossTab,
                  },
                })
                .then(() => {
                  this.userMessageService.showSnackBar(
                    `Snapshot has been applied`,
                    'OK'
                  );
                });
            });
        },
        (error) => {
          console.log(`error`, error);
          this.userMessageService.showSnackBar(error, 'OK', 10000);
        }
      );
  }

  public createSnapshot(doc: TupDocument<SurveyTimeDocument>): void {
    this.requestLoadingService.setLoading({
      target: 'document',
      isLoading: true,
    });
    const snapshotDoc = cloneDeep(doc);
    this.createSnapshotDoc(snapshotDoc, doc);
  }

  private createSnapshotDoc(
    snapshotDoc: TupDocument<SurveyTimeDocument>,
    activeDoc: TupDocument<SurveyTimeDocument>
  ): void {
    const containerName = snapshotDoc.metadata.container.name;
    const snapshotCreatedTime = new Date();
    const snapshotDocName = `${
      snapshotDoc.metadata.name
    } - ${snapshotCreatedTime.toLocaleDateString()} ${snapshotCreatedTime.toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit', hour12: false }
    )}`;
    snapshotDoc.metadata.status = TupDocumentStatus.ARCHIVED;
    snapshotDoc.metadata.name = snapshotDocName;
    if (snapshotDoc.content.snapshots) {
      snapshotDoc.content.snapshots = [];
    }
    this.documentService
      .createDocument(
        containerName,
        snapshotDocName,
        snapshotDoc.metadata.description,
        snapshotDoc,
        snapshotDoc.metadata.type.id ===
          TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE
      )
      .subscribe((docId: string) => {
        const snapshot: Snapshot = {
          docId,
          name: snapshotDocName,
          createdTime: snapshotCreatedTime,
        };
        this.documentService.updateDocumentSnapshots(activeDoc, snapshot);
      });
  }
}
