<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    [title]="appTitle"
    class="dialog-title"
    cdkDragHandle
  >
  </tup-mat-title-bar>

  <mat-dialog-content class="no-overflow">
    <br />
    <p>{{ appMessage }}</p>
    <p>
      <b>{{ errorMessage }}</b>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="ok()"
    >
      Back to crosstab
    </button>
  </mat-dialog-actions>
</div>
