import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { UserContainer } from '@telmar-global/tup-auth';
import {
  TupTag,
  TupTagEditorComponent,
  TupUserContainerSelectorComponent,
} from '@telmar-global/tup-document-storage';

export interface SaveAsDialogData {}

export interface SaveAsDialogResult {
  container: UserContainer;
  name: string;
  description: string;
  tags: TupTag[];
}

@Component({
  templateUrl: './save-as-dialog.component.html',
  styleUrls: ['./save-as-dialog.component.scss'],
})
export class SaveAsDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(TupUserContainerSelectorComponent, { static: true })
  public userContainerSelector: TupUserContainerSelectorComponent;

  public container: UserContainer;

  @ViewChild(TupTagEditorComponent, { static: true })
  public tagEditor: TupTagEditorComponent;

  public formGroup: FormGroup;

  public get disabled(): boolean {
    return this.formGroup.invalid || this.tagEditor.content.formGroup.invalid;
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SaveAsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveAsDialogData
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
    });
  }

  public ngAfterViewInit(): void {
    this.container = this.userContainerSelector.container;
  }

  public selectionChange(event: MatSelectionListChange): void {
    this.container = event.option.value;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public saveAs(): void {
    const dialogResult: SaveAsDialogResult = {
      container: this.container,
      ...this.formGroup.value,
      tags: this.tagEditor.content.formGroupRawValueToTags,
    };

    this.dialogRef.close(dialogResult);
  }
}
