import { Component, TemplateRef, ViewChild } from '@angular/core';

import { TupDocumentMetadata } from '@telmar-global/tup-document-storage';

export interface ConfirmDeleteDialogContentOptions {
  documentType: 'document' | 'report' | 'quick report';
}

@Component({
  templateUrl: './confirm-delete-dialog-content.component.html',
})
export class ConfirmDeleteDialogContentComponent {
  @ViewChild('own', { static: true, read: TemplateRef })
  public own: TemplateRef<HTMLElement>;
  @ViewChild('shared', { static: true, read: TemplateRef })
  public shared: TemplateRef<HTMLElement>;

  public metadata: TupDocumentMetadata;
  public options: ConfirmDeleteDialogContentOptions;
}
