import {
  Border,
  Borders,
  Cell,
  CellValue,
  Color,
  Column,
  Image,
  Row,
  ValueType,
} from 'exceljs';
import { cloneDeep, compact, merge, pick } from 'lodash';

import { TupXlsxBuilder } from '@telmar-global/tup-document-exporter';

import {
  APPLICATION_NAME,
  AudienceEffectsViewType,
  CellColors,
  CellStyleStatus,
  ClusterTableRowData,
  ColumnFilter,
  ColumnHeaderFilter,
  CorrespondenceDataRowType,
  CorrespondenceTableDataMode,
  CorrespondenceTableRowData,
  CrossTabTableData,
  CrossTabTableDataCell,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemType,
  DEFAULT_DECIMAL_POINTS,
  DEFAULT_SURVEY_COPYRIGHT,
  HeatmapValues,
  HIGHLIGHT_COLORS,
  HighlightValues,
  SortSettings,
  Survey,
  SurveyCode,
  SurveyCodeBackgroundColor,
  SurveyCodeMap,
  SurveyMetaDataWeights,
  Target,
  TargetColumn,
  VOLUMETRIC_DATA_ITEM_IDS,
  Z_SCORE_FILTERED_HIGHLIGHT_PROB,
} from '../models';
import { TargetTitlePipe } from '../pipes';
import {
  AudienceEffectsTableXlsxData,
  ClusterTableXlsxData,
  CorrespondenceTableXlsxData,
  FactorAnalysisTableXlsxData,
} from './crosstab-table-csv.builder';

export type SurveyColors = Record<SurveyCode, SurveyCodeBackgroundColor>;

export interface CrosstabTableXlsxData {
  documentName: string;
  targetColumns: TargetColumn[];
  data: CrossTabTableData[];
  weight?: SurveyMetaDataWeights;
  tablebase?: Target;
  dataItems: DataItem[];
  reportUnits: string;
  sortSettings: SortSettings[];
  filters: ColumnHeaderFilter[];
  surveys: Survey[];
  surveyColors: SurveyColors;
  highlightValues: HighlightValues;
  bins: number[][];
  cellStyleStatus: CellStyleStatus;
  cellColors: CellColors;
  heatMapValues: HeatmapValues;
  surveyCodeMap: SurveyCodeMap;
  defaultDecimalPoints: number;
}

export class CrosstabTableXlsxBuilder extends TupXlsxBuilder {
  private readonly colours: any = {
    green: {
      fill: '00B1FAC6',
    },
    red: {
      fill: '00FFE8F4',
    },
    yellow: {
      fill: 'FFFF00',
    },
    blue: {
      fill: '0054FF',
    },
  };
  private readonly badCharsReg = /[?*:\\/\[\]]/g;
  private readonly sheetNameLength = 27;

  constructor(private targetTitlePipe: TargetTitlePipe) {
    super();
  }

  public addSeparateColumnTableSheets(
    data: CrosstabTableXlsxData,
    noOfExistingSheets?: number
  ): void {
    data.data[0].data.forEach(
      (cell: CrossTabTableDataCell, columnIndex: number) => {
        let sheetName = '';
        if (data?.tablebase) {
          sheetName = [
            `${noOfExistingSheets + columnIndex + 1}. ${
              data.surveyCodeMap[cell.surveyCode]
            }`,
            this.formatTitle(cell.columnTarget, cell.title),
            ...(data?.tablebase ? [data.tablebase.title] : []),
          ]
            .join('-')
            .replace(this.badCharsReg, '')
            .substring(0, this.sheetNameLength);
        } else {
          sheetName = [
            `${columnIndex + 1}. ${data.surveyCodeMap[cell.surveyCode]}`,
            this.formatTitle(cell.columnTarget, cell.title),
          ]
            .join('-')
            .replace(this.badCharsReg, '')
            .substring(0, this.sheetNameLength);
        }

        this.addWorksheet(sheetName);

        const hasVolumetricCoding =
          data.data.filter(
            (crossTableData: CrossTabTableData) =>
              crossTableData.data[columnIndex].metadata?.isVolumetricCoding
          ).length > 0;
        this.addHeaderOrFooter(this.getHeaderRows(data, cell, columnIndex))
          .addSeparatedTableHeader(
            cell,
            data.dataItems,
            data.reportUnits,
            hasVolumetricCoding,
            data.surveyCodeMap
          )
          .addSeparatedTableBody(data, columnIndex)
          .addHeaderOrFooter(this.getFooterRows(data, cell));
        this.addColorCodingToHeatmapFooter(sheetName, data);
      }
    );
  }

  public addCombinedRankTableSheet(data: CrosstabTableXlsxData): void {
    const sheetName = data?.tablebase
      ? `${data.tablebase.title.substring(
          0,
          this.sheetNameLength - 10
        )} - Combined rank`
      : 'Combined rank';
    this.addWorksheet(sheetName);
    this.addHeaderOrFooter(this.getHeaderRows(data))
      .addCombinedRankTableHeader(data.data, data.dataItems, data.reportUnits)
      .addCombinedRankTableBody(data)
      .addHeaderOrFooter(this.getFooterRows(data));
    this.addColorCodingToHeatmapFooter(sheetName, data);
  }

  public addEntireTableSheet(data: CrosstabTableXlsxData, name?: string): void {
    const sheetName = name || 'Entire table';
    this.addWorksheet(sheetName);
    this.addHeaderOrFooter(this.getHeaderRows(data, null, 0))
      .addCombinedTableHeader(data.data, data.surveyCodeMap)
      .addCombinedTableBody(data)
      .addHeaderOrFooter(this.getFooterRows(data));
    this.addColorCodingToHeatmapFooter(sheetName, data);
  }

  public addClusterTable(data: ClusterTableXlsxData): void {
    this.addWorksheet('Cluster table');
    this.addHeaderOrFooter(this.getClusterHeaderRows(data))
      .addClusterTableBody(data)
      .addHeaderOrFooter(this.getClusterFooterRows(data));
  }

  public addCorrespondenceTable(data: CorrespondenceTableXlsxData): void {
    const worksheetOrder =
      data.primaryTabTableDataMode === CorrespondenceTableDataMode.absRel
        ? [
            CorrespondenceTableDataMode.absRel,
            CorrespondenceTableDataMode.factor,
          ]
        : [
            CorrespondenceTableDataMode.factor,
            CorrespondenceTableDataMode.absRel,
          ];

    worksheetOrder.forEach((tableMode) =>
      this.addCorrespondenceWorksheet(data, tableMode)
    );
  }

  private addCorrespondenceWorksheet(
    data: CorrespondenceTableXlsxData,
    dataMode: CorrespondenceTableDataMode
  ) {
    this.addWorksheet(
      dataMode === CorrespondenceTableDataMode.absRel
        ? 'Correspondence ABS REL table'
        : 'Correspondence factor table'
    );
    this.addHeaderOrFooter(this.getCorrespondenceHeaderRows(data))
      .addCorrespondenceTableBody(data, dataMode)
      .addHeaderOrFooter(this.getCorrespondenceFooterRows(data));
  }

  public addAudienceEffectsTable(
    data: AudienceEffectsTableXlsxData,
    mode: AudienceEffectsViewType
  ): void {
    this.addAudienceEffectsWorksheet(data, mode);
  }

  private addAudienceEffectsWorksheet(
    data: AudienceEffectsTableXlsxData,
    mode: AudienceEffectsViewType
  ) {
    this.addWorksheet('Audience Effects table');
    this.addHeaderOrFooter(this.getAudienceEffectsHeaderRows(data))
      .addAudienceEffectsTableBody(data, mode)
      .addHeaderOrFooter(this.getAudienceEffectsFooterRows(data));
  }

  public addFactorAnalysisTable(data: FactorAnalysisTableXlsxData): void {
    this.addWorksheet('Factor Analysis table');
    this.addHeaderOrFooter(this.getFactorAnalysisHeaderRows(data))
      .addFactorAnalysisTableBody(data)
      .addHeaderOrFooter(this.getFactorAnalysisFooterRows(data));
  }

  public addAudienceEffectsChart(
    chartWorksheetTitle: string,
    imageData: Image,
    imageWidth: number,
    imageHeight: number
  ): void {
    this.workbook.addWorksheet(chartWorksheetTitle);
    const chartWorkSheet = this.workbook.getWorksheet(chartWorksheetTitle);

    const tableWorkSheetRows = this.workbook
      .getWorksheet('Audience Effects table')
      .getRows(1, 6);
    tableWorkSheetRows.forEach((sourceRow, rowNumber) => {
      const targetRow = chartWorkSheet.getRow(rowNumber);
      sourceRow.eachCell({ includeEmpty: false }, (cell, cellNumber) => {
        targetRow.getCell(cellNumber).value = cell.value;
      });
      targetRow.commit();
    });

    this.addImage(chartWorksheetTitle, imageData, imageWidth, imageHeight);
  }

  private getHeaderRows(
    data: CrosstabTableXlsxData,
    cell?: CrossTabTableDataCell,
    columnIndex?: number
  ): string[][] {
    const rows = [
      [],
      [`${APPLICATION_NAME} Report:`, data.documentName],
      data?.tablebase ? ['Tablebase:', data.tablebase.title] : [],
      data?.weight ? ['Weights:', data.weight.description] : [],
      ...this.getSourceParts(
        data.surveys,
        cell?.surveyCode,
        data.surveyCodeMap
      ),
    ];

    const sortSettings =
      data.sortSettings.length > 1
        ? data.sortSettings[columnIndex]
        : data.sortSettings[0];

    const sortSettingsParts = this.getSortSettingsHeaderParts(
      sortSettings,
      data.targetColumns,
      data.surveys,
      data.surveyCodeMap
    );
    if (sortSettingsParts.length > 0) {
      rows.push(...sortSettingsParts);
    }
    if (data.filters.length > 0) {
      if (!cell) {
        rows.push(
          this.getColumnFiltersHeaderParts(
            data.filters,
            data.targetColumns,
            data.surveys,
            data.surveyCodeMap
          )
        );
      } else {
        const currentFilter = this.getCurrentCellFilter(cell, data.filters);
        if (currentFilter) {
          rows.push(
            this.getColumnFiltersHeaderParts(
              [currentFilter],
              data.targetColumns,
              data.surveys,
              data.surveyCodeMap
            )
          );
        }
      }
    }
    if (data.cellStyleStatus === CellStyleStatus.highlight) {
      const highlightParts = this.getHighlightHeaderParts(data);
      if (highlightParts.length > 0) {
        rows.push(highlightParts);
      }
    }
    rows.push([]);

    return rows;
  }

  private getClusterHeaderRows(data: ClusterTableXlsxData): string[][] {
    return [
      [],
      [`${APPLICATION_NAME} Report:`, data.documentName],
      [],
      this.getClusterSourceParts(data.survey, data.surveyCodeMap),
      [],
    ];
  }

  private getCorrespondenceHeaderRows(
    data: CorrespondenceTableXlsxData
  ): string[][] {
    return [
      [],
      [`${APPLICATION_NAME} Report:`, data.documentName],
      [],
      this.getCorrespondenceSourceParts(data.survey, data.surveyCodeMap),
      ...this.getCorrespondenceSortingHeaderParts(data),
      [],
    ];
  }

  private getAudienceEffectsHeaderRows(
    data: AudienceEffectsTableXlsxData
  ): string[][] {
    return [
      [],
      [`${APPLICATION_NAME} Report:`, data.documentName],
      this.getAudienceEffectsSourceParts(data.survey, data.surveyCodeMap),
      ['Table:', data.activeTableTitle],
      ['Report Units:', "1000's"],
      ['Population:', data.totalPopulation],
      ['Sample:', data.totalSample],
      [],
    ];
  }

  private getFactorAnalysisHeaderRows(
    data: FactorAnalysisTableXlsxData
  ): string[][] {
    return [
      [],
      [`${APPLICATION_NAME} Report:`, data.documentName],
      [],
      this.getFactorAnalysisSourceParts(data.survey, data.surveyCodeMap),
      ...this.getFactorAnalysisSortingHeaderParts(data),
      ['Table:', data.activeTableTitle],
      ['Audience', data.audienceSize],
      [],
    ];
  }

  private getCorrespondenceSortingHeaderParts(
    data: CorrespondenceTableXlsxData
  ): string[][] {
    if (!data?.sort) {
      return [];
    }

    return [
      ['Sort Survey Code:', data.sort.surveyCode],
      ['Sort Column:', data.sort.sortColumn],
      ['Sort Order:', data.sort.sortOrder],
    ];
  }

  private getFactorAnalysisSortingHeaderParts(
    data: FactorAnalysisTableXlsxData
  ): string[][] {
    if (!data?.sort) {
      return [];
    }

    return [
      ['Sort Survey Code:', data.sort.surveyCode],
      ['Sort Column:', data.sort.sortColumn],
      ['Sort Order:', data.sort.sortOrder],
    ];
  }

  private getCurrentCellFilter(
    cell: CrossTabTableDataCell,
    filters: ColumnHeaderFilter[]
  ): ColumnHeaderFilter {
    return filters.find((filter: ColumnHeaderFilter) => {
      if (cell.type === 'insight') {
        return filter.columnId.startsWith('totals');
      } else {
        return filter.columnId.startsWith(cell.columnTarget?.id);
      }
    });
  }

  private getFooterRows(
    data: CrosstabTableXlsxData,
    cell?: CrossTabTableDataCell
  ): string[][] {
    const rows = [
      ...this.getSourceParts(
        data.surveys,
        cell?.surveyCode,
        data.surveyCodeMap
      ),
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      ...this.getCopyrightParts(data.surveys, cell?.surveyCode),
    ];
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus.startsWith('significanceTesting')
    ) {
      rows.push(['Colour Coding:', 'Green > 99% | Orange > 95% | Red > 90%']);
    }
    if (
      data.cellStyleStatus &&
      (data.cellStyleStatus === CellStyleStatus.heatmapQuartiles ||
        data.cellStyleStatus === CellStyleStatus.heatmapQuintiles)
    ) {
      rows.push([
        'Colour Coding:',
        ...data.heatMapValues[data.cellStyleStatus].map(
          (option) => option.label
        ),
      ]);
    }
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus === CellStyleStatus.heatmap
    ) {
      rows.push([
        'Colour Coding:',
        `Index < ${100 - data.heatMapValues.heatmapIndexPercentage}`,
        `Index > ${100 + data.heatMapValues.heatmapIndexPercentage}`,
      ]);
    }
    if (
      data.cellStyleStatus &&
      data.cellStyleStatus === CellStyleStatus.zScoreHighlight
    ) {
      rows.push([
        'Z-score filtered highlight:',
        `Red < ${Z_SCORE_FILTERED_HIGHLIGHT_PROB} | Green > ${Z_SCORE_FILTERED_HIGHLIGHT_PROB}`,
      ]);
    }
    return rows;
  }

  private getClusterFooterRows(data: ClusterTableXlsxData): string[][] {
    return [
      [],
      this.getClusterSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getClusterCopyrightParts(data.survey),
    ];
  }

  private getCorrespondenceFooterRows(
    data: CorrespondenceTableXlsxData
  ): string[][] {
    return [
      [],
      this.getCorrespondenceSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getCorrespondenceCopyrightParts(data.survey),
    ];
  }

  private getAudienceEffectsFooterRows(
    data: AudienceEffectsTableXlsxData
  ): string[][] {
    return [
      [],
      this.getAudienceEffectsSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getAudienceEffectsCopyrightParts(data.survey),
    ];
  }

  private getFactorAnalysisFooterRows(
    data: FactorAnalysisTableXlsxData
  ): string[][] {
    return [
      [],
      this.getFactorAnalysisSourceParts(data.survey, data.surveyCodeMap),
      [],
      ['Export date:', new Date().toLocaleDateString('en-GB')],
      [],
      this.getFactorAnalysisCopyrightParts(data.survey),
    ];
  }

  private getSourceParts(
    surveys: Survey[],
    surveyCode: string | undefined,
    surveyCodeMap: SurveyCodeMap
  ): string[][] {
    const sources = this.getSources(surveys, surveyCode, surveyCodeMap);
    const sourceParts = [[]];
    sources.forEach((source: string, index: number) => {
      if (index === 0) {
        sourceParts.push(['Source:', source]);
      } else {
        sourceParts.push(['', source]);
      }
    });
    return sourceParts;
  }

  private getClusterSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getCorrespondenceSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getAudienceEffectsSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getFactorAnalysisSourceParts(
    survey: Survey,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    return ['Source:', `${surveyCodeMap[survey.code]}-${survey.title}`];
  }

  private getCopyrightParts(
    surveys: Survey[],
    surveyCode?: string
  ): string[][] {
    const sources = this.getCopyrights(surveys, surveyCode);
    const sourceParts = [[]];
    sources.forEach((copyright: string, index: number) => {
      if (index === 0) {
        sourceParts.push(['Copyright:', copyright]);
      } else {
        sourceParts.push(['', copyright]);
      }
    });
    return sourceParts;
  }

  private getClusterCopyrightParts(survey: Survey): string[] {
    const source = this.getClusterSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getCorrespondenceCopyrightParts(survey: Survey): string[] {
    const source = this.getCorrespondenceSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getAudienceEffectsCopyrightParts(survey: Survey): string[] {
    const source = this.getAudienceEffectsSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getFactorAnalysisCopyrightParts(survey: Survey): string[] {
    const source = this.getFactorAnalysisSurveyCopyrights(survey);
    return ['Copyright: ', source];
  }

  private getSources(
    surveys: Survey[],
    surveyCode: string | undefined,
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    if (surveyCode) {
      const survey = surveys.find(
        (surveyItem) => surveyItem.code === surveyCode
      );
      let code: string;
      let title: string;

      if (survey) {
        code = surveyCodeMap[survey.code];
        title = survey.title;
        return [[code, title].join(' - ')];
      } else {
        return [surveyCodeMap[surveyCode]];
      }
    }

    return surveys.map(({ code, title }: Survey) =>
      [surveyCodeMap[code], title].join(' - ')
    );
  }

  private getCopyrights(surveys: Survey[], surveyCode?: string): string[] {
    if (surveyCode) {
      const survey = surveys.find(
        (surveyItem) => surveyItem.code === surveyCode
      );

      if (survey && survey.meta['copyright-info']) {
        return [survey.meta['copyright-info']?.toString()];
      } else {
        return [DEFAULT_SURVEY_COPYRIGHT];
      }
    }

    return [DEFAULT_SURVEY_COPYRIGHT];
  }

  private getClusterSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getCorrespondenceSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getAudienceEffectsSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getFactorAnalysisSurveyCopyrights(survey: Survey): string {
    if (survey.meta['copyright-info']) {
      return survey.meta['copyright-info']?.toString();
    } else {
      return DEFAULT_SURVEY_COPYRIGHT;
    }
  }

  private getSortSettingsHeaderParts(
    sortSettings: SortSettings,
    targetColumns: TargetColumn[],
    surveys: Survey[],
    surveyCodeMap: SurveyCodeMap
  ): string[][] {
    if (sortSettings.columnId === '' || sortSettings.order.length === 0) {
      return [];
    }

    const targetColumn = targetColumns.find(
      (column: TargetColumn) => column.columnId === sortSettings.columnId
    );

    if (!targetColumn) {
      return [];
    }

    const surveyCount = surveys.length;
    const sortSurveyCode =
      sortSettings.survey || sortSettings.columnId.split('_')[1];
    const sortColumn = this.formatTitle(
      targetColumn.target,
      targetColumn.title
    );

    const sortOrder =
      sortSettings.order[0].toUpperCase() + sortSettings.order.slice(1);
    const sortDataItem = DATA_ITEMS_MAP[sortSettings.dataItem].displayName;

    const sortSettingsHeaderParts = [];
    if (surveyCount > 1) {
      sortSettingsHeaderParts.push([
        'Sort Survey Code:',
        surveyCodeMap[sortSurveyCode],
      ]);
    }
    return [
      ...sortSettingsHeaderParts,
      ['Sort Column:', sortColumn],
      ['Sort Order:', sortOrder],
      ['Sort Data Item:', sortDataItem],
    ];
  }

  private getColumnFiltersHeaderParts(
    filters: ColumnHeaderFilter[],
    targetColumns: TargetColumn[],
    surveys: Survey[],
    surveyCodeMap: SurveyCodeMap
  ): string[] {
    const surveyCount = surveys.length;
    const filterParts = filters.map((columnFilter: ColumnHeaderFilter) => {
      const column = targetColumns.find(
        (targetColumn: TargetColumn) =>
          targetColumn.columnId === columnFilter.columnId
      );
      const columnTitle = this.formatTitle(column.target, column.title);
      const surveyCode =
        surveyCount > 1
          ? ` (${surveyCodeMap[columnFilter.columnId.split('_')[1]]})`
          : '';
      const filterCount = columnFilter.filters.length;
      const filterConditions = columnFilter.filters.map(
        (filter: ColumnFilter, index: number) => {
          const dataItemName = DATA_ITEMS_MAP[filter.dataItem].displayName;
          const conditionalOperator = filter.conditionalOperator.toLowerCase();
          const value =
            conditionalOperator === 'is between'
              ? '(' + filter.value.join(', ') + ')'
              : filter.value[0];
          const filterOperator =
            index < filterCount - 1 ? ` ${filter.operator}` : '';
          return `${dataItemName} ${conditionalOperator} ${value}${filterOperator}`;
        }
      );

      return `${columnTitle}${surveyCode} - ${filterConditions.join(' ')}`;
    });
    return ['Filter(s):', filterParts.join(', ')];
  }

  private getHighlightHeaderParts(data: CrosstabTableXlsxData): string[] {
    const dataItemName =
      DATA_ITEMS_MAP[data.highlightValues.dataItemId].displayName;
    const decimalPoints =
      data.defaultDecimalPoints === DEFAULT_DECIMAL_POINTS
        ? DATA_ITEMS_MAP[data.highlightValues.dataItemId].decimalPoints
        : data.defaultDecimalPoints;
    const conditions = data.bins
      .map((bin: number[]) => {
        return `${dataItemName} is between (${bin[0].toFixed(
          decimalPoints
        )},${bin[1].toFixed(decimalPoints)})`;
      })
      .join(', ');
    if (conditions === '') {
      return [];
    }

    return ['Highlight Condition(s):', conditions];
  }

  private addHeaderOrFooter(rows: string[][]): CrosstabTableXlsxBuilder {
    this.worksheet
      .addRows(rows)
      .forEach((row: Row) => (row.getCell('A').font = { bold: true }));
    return this;
  }

  private addCombinedTableHeader(
    data: CrossTabTableData[],
    surveyCodeMap: SurveyCodeMap
  ): CrosstabTableXlsxBuilder {
    let cells: string[];
    let offset: number;
    let row: Row;

    const totalsColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'insight'
    );
    const targetColumnCells = data[0].data.filter(
      (cell: CrossTabTableDataCell) => cell.type === 'target'
    );
    const shouldAddSurveyCodeHeader = totalsColumnCells.length > 1;

    if (shouldAddSurveyCodeHeader) {
      cells = ['', ''];
      offset = cells.length;
      const surveyCodeCells = data[0].data.map(
        (cell: CrossTabTableDataCell) =>
          `${cell.surveyCode ? surveyCodeMap[cell.surveyCode] : ''}`
      );
      row = this.worksheet.addRow([...cells, ...surveyCodeCells]);
      for (let index = offset; index < row.cellCount; index++) {
        this.applyBorder(
          this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
        ); // !!! One based indexing !!!
      }
    }

    cells = ['', ''];
    offset = cells.length;

    cells.push(
      ...totalsColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );

    cells.push(
      ...targetColumnCells.map((cell: CrossTabTableDataCell) =>
        this.formatTitle(cell.columnTarget, cell.title)
      )
    );

    row = this.worksheet.addRow(cells);

    // The first two cells are empty, use the offset defined earlier to loop
    // through the remaining cells and apply the appropriate styles
    for (let index = offset; index < cells.length; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      ); // !!! One based indexing !!!
    }

    return this;
  }

  private addCombinedTableBody(
    data: CrosstabTableXlsxData
  ): CrosstabTableXlsxBuilder {
    const shouldApplyZScoreHighlight = this.shouldApplyZScoreHighlight(
      data.cellStyleStatus
    );
    data.data.forEach((row: CrossTabTableData, rowIndex: number) => {
      const start: number = this.worksheet.rowCount + 1;

      const insights: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'insight'
      );
      const targets: CrossTabTableDataCell[] = row.data.filter(
        (cell: CrossTabTableDataCell) => cell.type === 'target'
      );

      const cells: CrossTabTableDataCell[] = [
        ...cloneDeep(insights),
        ...cloneDeep(targets),
      ];
      data.dataItems.forEach((dataItem: DataItem) => {
        this.addCombinedRow(data, row, rowIndex, dataItem, cells);
      });

      const end: number = this.worksheet.rowCount;

      this.applyCombinedOuterBorders(start, end);

      this.mergeTitleCells(start, end);

      if (!row.isTotalRow && shouldApplyZScoreHighlight) {
        this.applyCombinedZScoreHighlight(cells, start, end);
      }
    });

    this.fitToContents(this.worksheet.getColumn('A'));
    this.worksheet.getColumn('B').width = 20;

    return this;
  }

  private addSeparatedTableHeader(
    cell: CrossTabTableDataCell,
    dataItems: DataItem[],
    reportUnits: string,
    hasVolumetricCoding: boolean,
    surveyCodeMap: SurveyCodeMap
  ): CrosstabTableXlsxBuilder {
    const emptyCells: string[] = [''];

    const offset: number = emptyCells.length;

    const dataItemCount = dataItems.length;
    const headerTitle = `${surveyCodeMap[cell.surveyCode]} - ${this.formatTitle(
      cell.columnTarget,
      cell.title
    )}`;

    let row: Row = this.worksheet.addRow([...emptyCells, headerTitle]);

    // The first cell is empty, use the offset defined earlier to loop through
    // the remaining cells and apply the appropriate styles
    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    this.worksheet.mergeCells(
      `${row.getCell(offset + 1).$col$row}:${
        row.getCell(offset + dataItemCount).$col$row
      }`
    );

    row = this.worksheet.addRow([
      ...emptyCells,
      ...dataItems.map((dataItem: DataItem, index: number) =>
        this.formatDataItemName(dataItem, reportUnits, hasVolumetricCoding)
      ),
    ]);

    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    return this;
  }

  private addSeparatedTableBody(
    data: CrosstabTableXlsxData,
    columnIndex: number
  ): CrosstabTableXlsxBuilder {
    const tableData = cloneDeep(data);
    const cellColors = tableData.cellColors;

    tableData.data.forEach((row: CrossTabTableData) => {
      const dataCell = row.data[columnIndex];
      if (dataCell.filteredOutCell) {
        return;
      }

      if (dataCell) {
        dataCell.color = cellColors[`${columnIndex}_${dataCell.rowIndex}`];
      }

      this.addSeparatedRow(tableData, row, dataCell, columnIndex);
    });

    this.fitToContents(this.worksheet.getColumn('A'));

    return this;
  }

  private addClusterTableBody(
    data: ClusterTableXlsxData
  ): CrosstabTableXlsxBuilder {
    const offsetRow = 9;
    const clusterColors = [];
    let counter = 0;

    const displayedColumns = this.worksheet.addRow(
      data.displayedColumns.slice(1)
    );

    for (let i = 0; i < data.displayedColumns.length - 1; i++) {
      this.applyBorder(
        this.embolden(this.applyDefaultFill(displayedColumns.getCell(i + 1)))
      );
    }

    data.data.forEach((row: ClusterTableRowData) => {
      this.addSeparatedClusterRow(row);
    });

    this.fitToContents(this.worksheet.getColumn('A'));

    data.data.forEach((rowData) =>
      rowData.clusters.forEach((cluster) =>
        HIGHLIGHT_COLORS.forEach((color) => {
          if (cluster.segment === color.label.toLowerCase()) {
            clusterColors.push(color.background.replace('#', ''));
          }
        })
      )
    );

    this.worksheet
      .getRows(offsetRow, data.data.length - 2)
      .forEach((row: Row) => {
        let offsetColumn = 6;
        const columnCount = this.worksheet.columnCount;

        for (let i = counter; i < clusterColors.length; i++) {
          this.applyFill(row.getCell(offsetColumn), { argb: clusterColors[i] });
          offsetColumn++;
          if (offsetColumn > columnCount) {
            counter = i + 1;
            break;
          }
        }
      });

    return this;
  }

  private addCorrespondenceTableBody(
    data: CorrespondenceTableXlsxData,
    dataMode: CorrespondenceTableDataMode
  ): CrosstabTableXlsxBuilder {
    const dataColumns =
      dataMode === CorrespondenceTableDataMode.absRel
        ? data.absRelTabDisplayedColumns
        : data.factorTabDisplayedColumns;
    const displayedColumns = this.worksheet.addRow(dataColumns.slice(2));
    let caColors = [];
    const offsetRow = 7 + (data?.sort ? 3 : 0);
    let counter = 0;

    for (let i = 0; i < dataColumns.length - 2; i++) {
      this.applyBorder(
        this.embolden(this.applyDefaultFill(displayedColumns.getCell(i + 1)))
      );
    }

    data.data.forEach((row: CorrespondenceTableRowData) => {
      this.addSeparatedCorrespondenceRow(row, dataMode);
    });

    for (let i = 0; i < dataColumns.length - 2; i++) {
      this.fitToContents(this.worksheet.getColumn(i + 1));
    }

    if (!data.isTableHighlighted) {
      return this;
    }

    const segmentKey =
      dataMode === CorrespondenceTableDataMode.factor
        ? 'coordinates'
        : 'absContribution';

    caColors = data.data.reduce((acc, value) => {
      acc.push(
        ...value.segments[segmentKey].map((entry) => {
          return HIGHLIGHT_COLORS.filter(
            (color) => entry.segment === color.label.toLowerCase()
          )[0].background.replace('#', '');
        })
      );
      return acc;
    }, []);

    this.worksheet.getRows(offsetRow, data.data.length).forEach((row: Row) => {
      let offsetColumn = 3;
      const columnCount = this.worksheet.columnCount;

      for (let i = counter; i < caColors.length; i++) {
        this.applyFill(row.getCell(offsetColumn), { argb: caColors[i] });
        offsetColumn++;
        if (offsetColumn > columnCount) {
          counter = i + 1;
          break;
        }
      }
    });

    return this;
  }

  private addFactorAnalysisTableBody(
    data: FactorAnalysisTableXlsxData
  ): CrosstabTableXlsxBuilder {
    let offsetRow = data?.sort ? 14 : 11;
    const factorColors = [];
    let counter = 0;

    const displayedColumns = this.worksheet.addRow(
      data.displayedColumns.slice(1)
    );

    for (let i = 0; i < data.displayedColumns.length - 1; i++) {
      this.applyBorder(
        this.embolden(this.applyDefaultFill(displayedColumns.getCell(i + 1)))
      );
    }

    data.explainedVariances.forEach((row: any) => {
      this.addSeparatedFactorAnalysisEVRow(row);
    });

    data.data.forEach((row: any) => {
      this.addSeparatedFactorAnalysisRow(row);

      row.highlightColors.forEach((color) => {
        factorColors.push(color.replace('#', ''));
      });
    });

    this.fitToContents(this.worksheet.getColumn('A'));

    this.worksheet.getRows(offsetRow, data.data.length).forEach((row: Row) => {
      let offsetColumn = 2;
      const columnCount = this.worksheet.columnCount;

      for (let i = counter; i < factorColors.length; i++) {
        this.applyFill(row.getCell(offsetColumn), { argb: factorColors[i] });
        offsetColumn++;
        if (offsetColumn > columnCount) {
          counter = i + 1;
          break;
        }
      }
    });

    this.addFactorAnalysisMultiSortDivider(data, offsetRow);

    return this;
  }

  private addAudienceEffectsTableBody(
    data: AudienceEffectsTableXlsxData,
    mode: AudienceEffectsViewType
  ) {
    if (mode === AudienceEffectsViewType.variable) {
      const displayedColumns = this.worksheet.addRow(
        data.displayedColumns.slice(1).map((row) => {
          switch (row) {
            case 'rowNumber':
              return 'Sl No.';
            case 'title':
              return 'Title';
            case 'y':
              return 'Targets';
            case 'x':
              return 'Index';
          }
          return row;
        })
      );

      for (let i = 0; i < data.displayedColumns.length - 1; i++) {
        this.applyBorder(
          this.embolden(this.applyDefaultFill(displayedColumns.getCell(i + 1)))
        );
      }

      data.data.forEach((row: any, index) => {
        this.addSeparatedAudienceEffectsRow(mode, index + 1, row);
      });
    } else if (mode === AudienceEffectsViewType.results) {
      const displayedColumns = this.worksheet.addRow(
        data.displayedColumns.map((row) => {
          switch (row) {
            case 'rowNumber':
              return 'Sl No.';
            case 'segments':
              return 'Segments';
            case 'population':
              return 'Population accum. (000)';
            case 'populationPercentage':
              return 'Population accum. (%)';
            case 'targets':
              return 'Targets accum. (000)';
            case 'targetsPercentage':
              return 'Targets accum. (%)';
            case 'index':
              return 'Index accum.';
          }
          return row;
        })
      );

      for (let i = 0; i < data.displayedColumns.length; i++) {
        this.applyBorder(
          this.embolden(this.applyDefaultFill(displayedColumns.getCell(i + 1)))
        );
      }

      data.data.forEach((row: any, index) => {
        this.addSeparatedAudienceEffectsRow(mode, index + 1, row);
      });
    }

    this.fitToContents(this.worksheet.getColumn('A'));
    this.fitToContents(this.worksheet.getColumn('B'));

    return this;
  }

  private addCombinedRow(
    data: CrosstabTableXlsxData,
    rowData: CrossTabTableData,
    rowIndex: number,
    dataItem: DataItem,
    cells: CrossTabTableDataCell[]
  ): void {
    const titles: string[] = [
      this.formatTitle(rowData.data[0].rowTarget, rowData.title),
      this.formatDataItemName(
        dataItem,
        data.reportUnits,
        rowData.metadata?.hasVolumetricCoding
      ),
    ];
    const cellKey = dataItem.cellKey;
    const decimalPoints = this.formatDecimalPoints(
      dataItem,
      data,
      rowData.metadata?.hasVolumetricCoding
    );
    const offset: number = titles.length;
    const isAffinityRow = cells[0].isAffinityRow;

    const excelRow: Row = this.worksheet.addRow([
      ...titles,
      ...cells.map((cell: CrossTabTableDataCell) => {
        if (
          cell.metadata?.isVolumetricCoding &&
          !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
        ) {
          return '';
        }
        return cell[cellKey] !== undefined && cell[cellKey] !== null
          ? parseFloat(cell[cellKey]?.toFixed(decimalPoints))
          : '';
      }),
    ]);

    // Style the first two cells (the title and subtitle)
    // tslint:disable-next-line:variable-name
    titles.forEach((_title: string, index: number) => {
      const cell: Cell = excelRow.getCell(index + 1);
      if (index === 0) {
        this.embolden(cell);
      }
      if (
        !rowData.isTotalRow &&
        index !== 0 &&
        this.shouldApplySort(data, dataItem)
      ) {
        this.embolden(
          this.applyFontColor(cell, { argb: this.colours.blue.fill })
        );
      }
      this.applyBorder(
        isAffinityRow
          ? this.applyFill(cell, { argb: this.colours.yellow.fill })
          : this.applyDefaultFill(cell)
      );
    });

    // Style the rest of the cells (the values themselves)
    for (let index = offset; index < offset + cells.length; index++) {
      const columnIndex = index - offset;
      const dataCell = cells[columnIndex];
      dataCell.color = data.cellColors[`${columnIndex}_${rowIndex}`];
      dataCell.surveyColor = data.surveyColors[dataCell.surveyCode];

      const excelCell: Cell = excelRow.getCell(index + 1); // !!! One based indexing !!!

      if (excelCell.type === ValueType.Number) {
        this.applyNumberFormat(excelCell, decimalPoints);
      }

      if (this.shouldApplyHighlight(data, dataItem, dataCell)) {
        this.embolden(excelCell);
      }

      if (this.shouldApplySort(data, dataItem, dataCell)) {
        this.embolden(
          this.applyFontColor(excelCell, { argb: this.colours.blue.fill })
        );
      }

      this.applyBorder(this.applyCellColor(dataCell, excelCell));
    }
  }

  private addSeparatedRow(
    data: CrosstabTableXlsxData,
    rowData: CrossTabTableData,
    dataCell: CrossTabTableDataCell,
    columnIndex: number
  ): void {
    if (!dataCell) {
      return;
    }
    const excelRow: Row = this.worksheet.addRow([
      this.formatTitle(dataCell?.rowTarget, rowData.title),
      ...data.dataItems.map((dataItem: DataItem) => {
        if (
          dataCell.metadata?.isVolumetricCoding &&
          !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
        ) {
          return '';
        }
        const decimalPoints = this.formatDecimalPoints(
          dataItem,
          data,
          dataCell.metadata?.isVolumetricCoding
        );
        return dataCell &&
          dataCell[dataItem.cellKey] !== undefined &&
          dataCell[dataItem.cellKey] !== null
          ? parseFloat(dataCell[dataItem.cellKey]?.toFixed(decimalPoints))
          : '';
      }),
    ]);

    // Style the first cell (the title)
    const titleCell = excelRow.getCell(1);
    this.applyBorder(
      this.embolden(
        dataCell?.isAffinityRow
          ? this.applyFill(titleCell, {
              argb: this.colours.yellow.fill,
            })
          : this.applyDefaultFill(titleCell)
      )
    );

    // Style the rest of the cells (the values themselves)
    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1); // !!! One based indexing !!!

      if (cell.type === ValueType.Number) {
        const decimalPoints = this.formatDecimalPoints(
          data.dataItems[index - 1],
          data,
          dataCell.metadata?.isVolumetricCoding
        );

        this.applyNumberFormat(cell, decimalPoints);
      }

      if (
        this.shouldApplyHighlight(data, data.dataItems[index - 1], dataCell)
      ) {
        this.embolden(cell);
        this.applyCellColor(dataCell, cell, false);
      }

      if (
        this.shouldApplySort(
          data,
          data.dataItems[index - 1],
          dataCell,
          columnIndex
        )
      ) {
        this.embolden(
          this.applyFontColor(cell, { argb: this.colours.blue.fill })
        );
      }
      this.applyBorder(cell);
    }

    if (
      !rowData.isTotalRow &&
      this.shouldApplyZScoreHighlight(data.cellStyleStatus)
    ) {
      this.applySeparatedZScoreHighLight(dataCell, excelRow);
    }
  }

  private addSeparatedClusterRow(row: any): void {
    const excelRow: Row = this.worksheet.addRow([
      row.variable,
      row.type,
      row.rank,
      row.determination,
      row.total,
      ...row.clusters.map((data) => data.value),
    ]);

    const titleCell = excelRow.getCell(1);
    this.applyBorder(this.embolden(this.applyDefaultFill(titleCell)));

    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1);
      this.applyBorder(cell);
    }
  }

  private addSeparatedCorrespondenceRow(
    row: any,
    dataMode: CorrespondenceTableDataMode
  ): void {
    const excelRow: Row = this.worksheet.addRow([
      row.title,
      CorrespondenceDataRowType[row.type],
      row.inf,
      ...(dataMode === CorrespondenceTableDataMode.absRel
        ? row.absContribution.map(
            (value, index) =>
              `${value} ${row.relSign[index]} ${row.relContribution[index]}`
          )
        : row.coordinates),
    ]);

    const titleCell = excelRow.getCell(1);
    this.applyBorder(this.embolden(this.applyDefaultFill(titleCell)));

    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1);
      this.applyBorder(cell);
    }
  }

  private addSeparatedAudienceEffectsRow(
    mode: AudienceEffectsViewType,
    rowNumber: number,
    row: any
  ): void {
    const excelRow: Row =
      mode === AudienceEffectsViewType.variable
        ? this.worksheet.addRow([rowNumber, row.title, row.y, row.x])
        : this.worksheet.addRow([
            rowNumber,
            row.segments,
            row.population,
            row.populationPercentage,
            row.targets,
            row.targetsPercentage,
            row.index,
          ]);

    const titleCell = excelRow.getCell(1);
    this.applyBorder(this.embolden(this.applyDefaultFill(titleCell)));

    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1);
      this.applyBorder(cell);
      // tslint:disable-next-line:no-unused-expression
      mode === AudienceEffectsViewType.results &&
        this.applyFill(cell, { argb: row.backgroundColor.replace('#', '') });
    }
  }

  private addSeparatedFactorAnalysisRow(row: any): void {
    const excelRow: Row = this.worksheet.addRow([row.title, ...row.loadings]);

    const titleCell = excelRow.getCell(1);
    this.applyBorder(this.embolden(this.applyDefaultFill(titleCell)));

    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1);
      this.applyBorder(cell);
    }
  }

  private addSeparatedFactorAnalysisEVRow(
    explainedVarianceRow: string[]
  ): void {
    const excelRow: Row = this.worksheet.addRow(explainedVarianceRow);
    for (let index = 1; index <= excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index);
      this.applyBorder(
        this.embolden(this.applyFill(cell, { argb: '00E6ECFF' }))
      );
      if (index !== 1)
        cell.alignment = { vertical: 'bottom', horizontal: 'right' };
    }
  }

  private addFactorAnalysisMultiSortDivider(
    data: FactorAnalysisTableXlsxData,
    offsetRow: number
  ) {
    if (!data.isMultiSort) return;

    const props: string[] = ['top', 'left', 'right'];
    const borderStyle: Partial<Border> = {
      style: 'thick',
    } as Partial<Border>;

    const thinBorderStyle: Partial<Border> = {
      style: 'thin',
    } as Partial<Border>;

    const borders: Partial<Borders> = {
      top: borderStyle,
      left: thinBorderStyle,
      right: thinBorderStyle,
    };

    this.worksheet
      .getRows(offsetRow, data.data.length)
      .forEach((row, index) => {
        if (index > 0) {
          if (data.data[index - 1]?.hasFactorDivider) {
            let offsetColumn = 1;
            const columnCount = this.worksheet.columnCount;

            for (let i = offsetColumn; i <= columnCount; i++) {
              row.getCell(i).border = pick(borders, props);
            }
          }
        }
      });
  }

  private addCombinedRankTableHeader(
    data: CrossTabTableData[],
    dataItems: DataItem[],
    reportUnits: string
  ): CrosstabTableXlsxBuilder {
    const emptyCells: string[] = [''];

    const offset: number = emptyCells.length;

    const dataItemCount = dataItems.length;

    const headerTitles = data[0].data.map((cell: CrossTabTableDataCell) =>
      this.formatTitle(cell.columnTarget, cell.title)
    );

    let row: Row = this.worksheet.addRow(
      [...emptyCells].concat(
        ...headerTitles.map((item: string) => Array(dataItemCount).fill(item))
      )
    );

    // The first cell is empty, use the offset defined earlier to loop through
    // the remaining cells and apply the appropriate styles
    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    // tslint:disable-next-line:variable-name
    headerTitles.forEach((_title, index) => {
      this.worksheet.mergeCells(
        `${row.getCell(offset + 1 + index * dataItemCount).$col$row}:${
          row.getCell(offset + dataItemCount + index * dataItemCount).$col$row
        }`
      );
    });

    row = this.worksheet.addRow(
      [...emptyCells].concat(
        ...headerTitles.map((title, index) => {
          const hasVolumetricCoding =
            data.filter(
              (crossTableData: CrossTabTableData) =>
                crossTableData.data[index].metadata?.isVolumetricCoding
            ).length > 0;

          return dataItems.map((dataItem: DataItem) =>
            this.formatDataItemName(dataItem, reportUnits, hasVolumetricCoding)
          );
        })
      )
    );

    for (let index = offset; index < row.cellCount; index++) {
      this.applyBorder(
        this.applyDefaultFill(this.embolden(row.getCell(index + 1)))
      );
    }

    return this;
  }

  private addCombinedRankTableBody(
    data: CrosstabTableXlsxData
  ): CrosstabTableXlsxBuilder {
    data.data.forEach((row: CrossTabTableData, rowIndex: number) => {
      this.addCombinedRankRow(data, row, rowIndex);
    });

    this.fitToContents(this.worksheet.getColumn('A'));

    return this;
  }

  private addCombinedRankRow(
    data: CrosstabTableXlsxData,
    rowData: CrossTabTableData,
    rowIndex: number
  ): void {
    const dataCells = rowData.data;
    const excelRow: Row = this.worksheet.addRow([
      this.formatTitle(rowData.data[0].rowTarget, rowData.title),
      ...dataCells
        .map((dataCell: CrossTabTableDataCell) =>
          data.dataItems.map((dataItem: DataItem) => {
            if (
              dataCell.metadata?.isVolumetricCoding &&
              !VOLUMETRIC_DATA_ITEM_IDS.includes(dataItem.id)
            ) {
              return '';
            }

            const decimalPoints = this.formatDecimalPoints(
              dataItem,
              data,
              dataCell.metadata?.isVolumetricCoding
            );
            return dataCell[dataItem.cellKey] !== undefined &&
              dataCell[dataItem.cellKey] !== null
              ? parseFloat(dataCell[dataItem.cellKey]?.toFixed(decimalPoints))
              : '';
          })
        )
        .reduce((acc, value) => acc.concat(value), []),
    ]);

    // Style the first cell (the title)
    const titleCell = excelRow.getCell(1);
    this.applyBorder(this.embolden(this.applyDefaultFill(titleCell)));

    const shouldApplyZScoreHighlight = this.shouldApplyZScoreHighlight(
      data.cellStyleStatus
    );
    // Style the rest of the cells (the values themselves)
    for (let index = 1; index < excelRow.cellCount; index++) {
      const cell: Cell = excelRow.getCell(index + 1); // !!! One based indexing !!!
      const columnIndex = Math.floor((index - 1) / data.dataItems.length);
      const dataItemIndex = (index - 1) % data.dataItems.length;
      const dataCell = dataCells[columnIndex];
      dataCell.color = data.cellColors[`${columnIndex}_${rowIndex}`];

      if (cell.type === ValueType.Number) {
        const decimalPoints = this.formatDecimalPoints(
          data.dataItems[dataItemIndex],
          data,
          dataCell.metadata?.isVolumetricCoding
        );
        this.applyNumberFormat(cell, decimalPoints);
      }

      if (
        this.shouldApplyHighlight(data, data.dataItems[dataItemIndex], dataCell)
      ) {
        this.embolden(cell);
        this.applyCellColor(dataCell, cell, false);
      }

      if (this.shouldApplySort(data, data.dataItems[dataItemIndex], dataCell)) {
        this.embolden(
          this.applyFontColor(cell, { argb: this.colours.blue.fill })
        );
      }

      this.applyBorder(cell);

      if (!rowData.isTotalRow && shouldApplyZScoreHighlight) {
        this.applyCombinedRankZScoreHighlight(dataCell, cell);
      }
    }
  }

  private applyCombinedRankZScoreHighlight(
    dataCell: CrossTabTableDataCell,
    cell: Cell
  ): void {
    const colour: string | null = this.getCellZScoreHighlightColor(dataCell);
    if (['red', 'green'].includes(colour)) {
      this.applyFill(cell, { argb: this.colours[colour].fill });
      this.applyBorder(cell, ['top', 'left', 'bottom', 'right']);
    }
  }

  private applySeparatedZScoreHighLight(
    dataCell: CrossTabTableDataCell,
    row: Row
  ): void {
    const colour: string | null = this.getCellZScoreHighlightColor(dataCell);

    if (['red', 'green'].includes(colour)) {
      for (let index = 1; index < row.cellCount; index++) {
        const cell: Cell = row.getCell(index + 1);
        this.applyFill(cell, { argb: this.colours[colour].fill });
        this.applyBorder(cell, ['top', 'left', 'bottom', 'right']);
      }
    }
  }

  private mergeTitleCells(start: number, end: number): void {
    const tl = `A${start}`;
    const br = `A${end}`;

    this.worksheet.mergeCells(`${tl}:${br}`);

    this.worksheet.getCell(tl).alignment = { vertical: 'top' };
  }

  private fitToContents(column: Column): void {
    column.width = Math.max(
      ...column.values
        .map((value: CellValue) => value.toString().length)
        .filter((value: CellValue) => typeof value === 'number')
    );
  }

  private applyBorder(
    cell: Cell,
    props: string[] = ['top', 'left', 'bottom', 'right'],
    color?: Partial<Color>
  ): Cell {
    const borderStyle: Partial<Border> = merge(
      { style: 'thin' },
      { color }
    ) as Partial<Border>;

    const borders: Partial<Borders> = {
      top: borderStyle,
      left: borderStyle,
      bottom: borderStyle,
      right: borderStyle,
    };

    cell.border = pick(borders, props);

    return cell;
  }

  private applyDefaultFill(cell: Cell): Cell {
    this.applyFill(cell, { argb: '00E6ECFF' });

    return cell;
  }

  private applyFill(cell: Cell, fgColor: Partial<Color>): Cell {
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor };

    return cell;
  }

  private applyFontColor(cell: Cell, color: Partial<Color>): Cell {
    cell.font = {
      ...cell.font,
      color,
    };

    return cell;
  }

  private embolden(cell: Cell): Cell {
    cell.font = {
      ...cell.font,
      bold: true,
    };

    return cell;
  }

  private formatTitle(target: Target, fallbackTitle: string): string {
    return target ? this.targetTitlePipe.transform(target) : fallbackTitle;
  }

  private formatDataItemName(
    dataItem: DataItem,
    reportUnits: string,
    hasVolumetricCoding: boolean
  ): string {
    if (hasVolumetricCoding && dataItem.id === DataItemType.audience) {
      return dataItem.volumetricDisplayName;
    }

    return (
      dataItem.displayName +
      (dataItem.id === DataItemType.audience ? reportUnits : '')
    );
  }

  private formatDecimalPoints(
    dataItem: DataItem,
    data: CrosstabTableXlsxData,
    hasVolumetricCoding: boolean
  ): number {
    if (hasVolumetricCoding && dataItem.id === DataItemType.audience) {
      return dataItem.volumetricDecimalPoints;
    }

    return data.defaultDecimalPoints === DEFAULT_DECIMAL_POINTS
      ? dataItem.decimalPoints
      : data.defaultDecimalPoints;
  }

  private shouldApplySort(
    data: CrosstabTableXlsxData,
    dataItem: DataItem,
    dataCell?: CrossTabTableDataCell,
    columnIndex?: number
  ): boolean {
    if (dataCell !== undefined && !dataCell.rowTarget) {
      return false;
    }

    const sortIndex = columnIndex ?? 0;
    if (
      data.sortSettings.length < 1 ||
      !data.sortSettings[sortIndex] ||
      data.sortSettings[sortIndex].columnId === ''
    ) {
      return false;
    }

    return data.sortSettings[sortIndex].dataItem === dataItem.id;
  }

  private shouldApplyHighlight(
    data: CrosstabTableXlsxData,
    dataItem: DataItem,
    dataCell: CrossTabTableDataCell
  ): boolean {
    if (dataCell.isAffinityRow) {
      return false;
    }

    if (dataCell.isTotalsColumn) {
      return false;
    }

    if (!dataCell.rowTarget) {
      return false;
    }

    if (data.cellStyleStatus !== CellStyleStatus.highlight) {
      return false;
    }

    return data.highlightValues.dataItemId === dataItem.id;
  }

  private shouldApplyZScoreHighlight(
    cellStyleStatus: CellStyleStatus
  ): boolean {
    return cellStyleStatus === CellStyleStatus.zScoreHighlight;
  }

  private getCellZScoreHighlightColor(
    cell: CrossTabTableDataCell
  ): string | null {
    if (!('FILTER_ZSCORE' in cell)) {
      return null;
    }

    if (cell.isTotalsColumn || cell.isAffinityRow) {
      return null;
    }

    return cell.FILTER_ZSCORE > Z_SCORE_FILTERED_HIGHLIGHT_PROB
      ? 'green'
      : cell.FILTER_ZSCORE < Z_SCORE_FILTERED_HIGHLIGHT_PROB
      ? 'red'
      : null;
  }

  private applyCombinedOuterBorders(start: number, end: number): void {
    const rowCount = end - start + 1;
    this.worksheet
      .getRows(start, rowCount)
      .forEach((row: Row, index: number) => {
        row.eachCell((cell: Cell, colNumber: number) => {
          // ignore title cell
          if (colNumber === 1) {
            return;
          }
          const props: string[] = compact([
            index === 0 ? 'top' : undefined,
            'left',
            index === rowCount - 1 ? 'bottom' : undefined,
            'right',
          ]);
          this.applyBorder(cell, props);
        });
      });
  }

  private generateCombinedZScoreHighlight(
    cells: CrossTabTableDataCell[]
  ): string[] {
    return cells.map((cell: CrossTabTableDataCell) =>
      this.getCellZScoreHighlightColor(cell)
    );
  }

  private applyCombinedZScoreHighlight(
    cells: CrossTabTableDataCell[],
    start: number,
    end: number
  ): void {
    const highlight: string[] = this.generateCombinedZScoreHighlight(cells);
    const offset = 3; // Title, label, totals
    const rowCount = end - start + 1;
    this.worksheet
      .getRows(start, rowCount)
      .forEach((row: Row, index: number) => {
        row.eachCell((cell: Cell, colNumber: number) => {
          const props: string[] = compact([
            index === 0 ? 'top' : undefined,
            'left',
            index === rowCount - 1 ? 'bottom' : undefined,
            'right',
          ]);

          const colour: string = highlight[colNumber - offset];

          if (['red', 'green'].includes(colour)) {
            this.applyFill(cell, { argb: this.colours[colour].fill });
            this.applyBorder(cell, props);
          }
        });
      });
  }

  private applyNumberFormat(cell: Cell, decimalPoints: number): Cell {
    cell.numFmt = decimalPoints ? `0.${'0'.repeat(decimalPoints)}` : '#,##0';
    return cell;
  }

  private applyCellColor(
    dataCell: CrossTabTableDataCell,
    excelCell: Cell,
    fillSurveyColor: boolean = true
  ): Cell {
    let filledExcelCell =
      fillSurveyColor && dataCell && dataCell.surveyColor
        ? this.applyFill(excelCell, {
            argb: dataCell.surveyColor.replace('#', ''),
          })
        : excelCell;

    if (dataCell && dataCell.isAffinityRow) {
      filledExcelCell = this.applyFill(filledExcelCell, {
        argb: this.colours.yellow.fill,
      });
    }

    if (dataCell && dataCell.color !== '' && dataCell.color !== undefined) {
      filledExcelCell = this.applyFill(filledExcelCell, {
        argb: dataCell.color.replace('#', ''),
      });
    }

    return filledExcelCell;
  }

  public addImage(
    workSheetTitle: string,
    imageBlob: Image,
    imageWidth: number = 1280,
    imageHeight: number = 720
  ) {
    const worksheet = this.workbook.getWorksheet(workSheetTitle);
    const imageId = this.workbook.addImage(imageBlob);
    worksheet.addImage(imageId, {
      tl: { col: 3, row: 10 },
      ext: { width: imageWidth, height: imageHeight },
    });
  }

  private addColorCodingToHeatmapFooter(
    sheetName: string,
    data: CrosstabTableXlsxData
  ) {
    if (
      data.cellStyleStatus &&
      (data.cellStyleStatus === CellStyleStatus.heatmapQuartiles ||
        data.cellStyleStatus === CellStyleStatus.heatmapQuintiles ||
        data.cellStyleStatus === CellStyleStatus.heatmap)
    ) {
      this.workbook.getWorksheet(sheetName);
      data.heatMapValues[data.cellStyleStatus].forEach((option, index) => {
        const cell = this.worksheet.lastRow.getCell(index + 2);
        this.applyFill(cell, { argb: option.background.replace('#', '') });
      });
    }
  }
}
