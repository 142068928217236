import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Survey, SurveyInfoType } from 'src/app/models';
import { DialogService } from 'src/app/services';

@Component({
  selector: 'app-surveys-info',
  templateUrl: './surveys-info.component.html',
  styleUrls: ['./surveys-info.component.scss'],
})
export class SurveysInfoComponent implements OnInit, OnChanges {
  @Input() surveys: Survey[];
  @Input() showSurveyInfo: boolean = true;
  @Input() showSurveyCopyright: boolean = true;
  @Input() showSurveyCodesOnCopyrightHover: boolean = false;

  public hasSurveyInfo = true;
  public surveyCodes: string;

  constructor(private dialog: DialogService) {}

  ngOnInit(): void {
    this.checkSurveyInfo();
    this.setSurveyCodes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.surveys = changes.surveys.currentValue;
    this.checkSurveyInfo();
    this.setSurveyCodes();
  }

  public onOpenCopyright(): void {
    this.dialog
      .surveysInfo(SurveyInfoType.copyright, this.surveys)
      .afterClosed();
  }

  public onOpenSurveyInfo(): void {
    this.dialog.surveysInfo(SurveyInfoType.info, this.surveys).afterClosed();
  }

  private checkSurveyInfo(): void {
    this.hasSurveyInfo =
      this.surveys?.filter((survey: Survey) => survey.meta?.['survey-info'])
        .length > 0;
  }

  private setSurveyCodes() {
    this.surveyCodes = this.surveys.map((survey) => survey.code).join(';');
  }
}
