<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    cdkDragHandle
    title="Something went wrong"
    class="dialog-title"
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="cancel()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content class="error-handling-dialog-content">
    <p>
      An unexpected error occurred. Please send the error details to your
      TelmarHelixa representative for a resolution.
    </p>

    <div [@.disabled]="disableAnimation">
      <mat-expansion-panel
        [expanded]="panelOpenState"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        class="error-details"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ panelOpenState ? "Hide" : "Show" }} error details
          </mat-panel-title>
        </mat-expansion-panel-header>
        {{ message }}
      </mat-expansion-panel>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      tupMatButton
      appearance="medium"
      matDialogClose
      (click)="cancel()"
    >
      Cancel
    </button>

    <div>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        (click)="onCopyErrorMessage()"
      >
        Copy error details
      </button>

      <button
        mat-flat-button
        color="primary"
        tupMatButton
        appearance="medium"
        matDialogClose
        cdkFocusRegionstart
        (click)="ok()"
      >
        OK
      </button>
    </div>
  </mat-dialog-actions>
</div>
