import { DatePipe } from '@angular/common';

import {
  TupDocument,
  TupDocumentStatus,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { TupLogLevel } from '@telmar-global/tup-logger-angular';

import { SurveysTableCellComponent } from '../app/components/surveys-table-cell/surveys-table-cell.component';

export const environment = {
  production: false,

  helpLink: 'https://documentation.telmar.com/',

  engines: {
    tupApiKey: 'Vyje8Vcl9b7G7fRnyw2m02o3CRKMc9sM3DT4u933',
  },

  analytics: {
    gainsight: {
      use: false,
      url: '',
      trackingId: '',
    },
    hotjar: {
      use: false,
      url: 'https://static.hotjar.com/c/hotjar-',
      trackingId: 2720801,
    },
    productFruits: {
      use: false,
      workspaceCode: 'mVY9P1cyvV82oVup',
      language: 'en',
    },
  },

  // login
  tupApiKey: 'Vyje8Vcl9b7G7fRnyw2m02o3CRKMc9sM3DT4u933',
  tupAuth: {
    loginLocation: '/login',
    defaultLocation: '/',
    appName: 'Surveytime',
    notAuthorizedLocation: '/not-authorized',
    notFoundLocation: '/404',
    platformHomepage: {
      url: 'https://dev.platform.telmar.com',
      redirects: {
        '/404': 10,
      },
    },
  },

  amplifyAuth: {
    region: 'us-east-1',

    userPoolId: 'us-east-1_oAyEC7JId',
    userPoolWebClientId: '1gcn08pu5vqo45bse464m793f4',

    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'telmar.com',
      expires: 365,
      secure: true,
    },
    // clientMetadata: { myCustomKey: 'myCustomValue' },
    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  },

  tupCrmApi: {
    urls: {
      interface: 'https://api.telmar.com/tup-crm/v1/crm/interface',
    },
  },

  // in app options
  appOptions: {
    platformHomepage: 'https://dev.platform.telmar.com',
  },

  // engine endpoints
  api: {
    crosstab: {
      url: 'https://stage.engines.api.telmar.com/explore/stage/v8',
      endPoint: {
        crosstab: '/crosstab',
        trending: '/trending',
        getChunkData: '/getchunkdata',
        getTrendingChunkData: '/gettrendingchunkdata',
      },
    },
    codebook: {
      url: 'https://stage.engines.api.telmar.com/codebook/explore/stage/v4',
      endPoint: {
        search: '/search',
        codebookNavigation: '/codebooknavigation',
        getleafdata: '/getleafdata',
        getSurveys: '/getsurveys',
        getSurvey: '/getsurvey',
        getstabilitylevels: '/getstabilitylevels',
        getgeocodings: '/getgeocodings',
      },
    },
    survey: {
      url: 'https://stage.engines.api.telmar.com/survey-info/stage/v2',
      endPoint: {
        populationBases: '/surveyconfiguration/getpopulationbases',
        getdataitems: '/surveyconfiguration/getvalues',
        getinidata: '/surveyconfiguration/getinidata',
        getadi: '/surveyconfiguration/getadi',
        getreportunits: '/getreportunits',
        getSurveyMetadata: '/getsurveymetadata',
        getstabilitylevels: '/getstabilitylevels',
      },
    },
    coding: {
      url: 'https://stage.engines.api.telmar.com/coding/stage/v2',
      endpoint: {
        userTargetCoding: '/user/targetcoding',
        planTargetCoding: '/plan/targetcoding',
      },
    },
    factorCluster: {
      url: 'https://stage.engines.api.telmar.com/fa-cluster/stage/v1',
      endpoint: {
        startClustering: '/start_clustering',
        getClusters: '/get_clusters',
        getStatus: '/get_status',
        duplicateResearch: '/duplicate_research',
        deleteResearch: '/delete_research',
        saveClustering: '/save_clustering',
        startCorrespondence: '/start_ca',
        startFa: '/start_fa',
        getFactors: '/get_factors',
        saveFactor: '/save_fa',
      },
    },
    audienceEffects: {
      url: 'https://stage.engines.api.telmar.com/audience-effect/stage/v8',
      endPoint: {
        criteriapreferences: '/criteriapreferences',
        chaidanalysis: '/chaidanalysis',
      },
    },
  },

  tupDocumentStorage: {
    baseUrl: 'https://6awq45bcp5.execute-api.us-east-1.amazonaws.com/prd',
    options: {
      // Configure navigation between apps / Modules (only required on the platform hompage)
      applications: [],
      // Document types (by id). Leave blank to load all document types
      types: [TupDocumentTypeId.SURVEYTIME_CAMPAIGN],
      // Document statuses. Leave blank to load all document statuses
      statuses: [TupDocumentStatus.ACTIVE],
      // Reload interval: 30s
      reloadPeriodInMs: 30 * 1000000,
      actions: [
        'click:edit:edit::true',
        'click:duplicate:file_copy::true',
        'click:delete:highlight_off',
        'click:export:file_download::true',
        'update_tags',
        'update_container',
        'click:rename:abc',
      ],
      // Which columns to add to the table
      columns: [
        {
          columnDef: 'actions',
          sortable: false,
          header: '',
          columnType: 'actions',
          cell: () => '',
        },
        {
          columnDef: 'metadata.name.keyword',
          sortable: true,
          header: 'File name',
          columnType: 'ellipsis',
          cell: (document: TupDocument<any>) => document.metadata.name,
          tooltip: (document: TupDocument<unknown>) => document.metadata.name,
          tooltipPosition: 'above',
          tooltipShowDelay: 100,
        },
        {
          columnDef: 'metadata.description',
          sortable: true,
          header: 'Description',
          columnType: 'ellipsis',
          cell: (document: TupDocument<any>) => document.metadata.description,
          tooltip: (document: TupDocument<unknown>) =>
            document.metadata.description,
          tooltipPosition: 'above',
          tooltipShowDelay: 100,
        },
        {
          columnDef: 'metadata.by.attributes.name.keyword',
          sortable: true,
          header: 'Owner',
          columnType: 'string',
          cell: (document: TupDocument<any>) =>
            document.metadata.by.attributes.name,
        },
        {
          columnDef: 'metadata.created',
          sortable: true,
          header: 'Date created',
          columnType: 'pipe',
          cell: (document: TupDocument<any>) => document.metadata.created,
          pipe: { token: DatePipe, args: ['HH:mm, d MMM, y'] },
        },
        {
          columnDef: 'metadata.modified',
          sortable: true,
          header: 'Last modified',
          columnType: 'pipe',
          cell: (document: TupDocument<any>) => document.metadata.modified,
          pipe: { token: DatePipe, args: ['HH:mm, d MMM, y'] },
        },
        {
          columnDef: 'document.content.surveys',
          sortable: false,
          header: 'Surveys used',
          columnType: 'component',
          cell: () => '',
          component: SurveysTableCellComponent,
        },
      ],
      // Source filtering
      _source: ['metadata', 'content.surveys', 'content.snapshots'],
      tags: {
        suggested: ['Client', 'Project'],
      },
    },
  },

  tupLogger: {
    logLevel: TupLogLevel.DEBUG,
  },

  tupUserPreferenceStorage: {
    appSyncClientOptions: {
      region: 'us-east-1',
      graphqlEndpoint:
        'https://566u6iatc5bctla7v72ekfujt4.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },

  tupAudienceGroups: {
    documentTypes: [TupDocumentTypeId.OWN_CODES_TARGET],
    status: [TupDocumentStatus.ACTIVE],
  },

  userPreferences: {
    lastTenSurveysUsed: 'survey-time.last-ten-used-surveys.v0',
  },

  googleApi: {
    clientId:
      '269176030796-an367pn9tec4p5sk795kabkh9j4hp2dh.apps.googleusercontent.com',
    apiKey: 'AIzaSyD0w6xvezia2W6VUBJ8U7hvheiY6-vhCAA',
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    ],
    scope: 'https://www.googleapis.com/auth/drive.file',
  },

  googleDrive: {
    folder: {
      name: 'explore',
    },
  },

  sendToPlanUrl: 'http://localhost:4201/new/data?tab=0&exploreReportId=',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
