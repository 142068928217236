import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { cloneDeep } from 'lodash';
import {
  CorrespondenceDataRowType,
  CorrespondenceTableDataMode,
  CorrespondenceTableDataModeKeys,
  CorrespondenceTableRowData,
} from 'src/app/models/correspondence.model';
import { MatSort, Sort } from '@angular/material/sort';
import { compare } from 'src/app/utils/sortHelper';
import { DocumentService, XlsxService } from 'src/app/services';
import { CsvService } from 'src/app/services/csv.service';
import { TupCsvBuilder } from '@telmar-global/tup-document-exporter';
import {
  CorrespondenceTableXlsxData,
  CrosstabTableCsvBuilder,
} from 'src/app/builders/crosstab-table-csv.builder';
import { TargetTitlePipe } from 'src/app/pipes';
import { CrosstabTableXlsxBuilder } from 'src/app/builders/crosstab-table-xlsx.builder';
import { Survey } from 'src/app/models';

const FACTOR_ABS_REL_ROW_PREFIX = 'factor-abs+rel';

@Component({
  selector: 'app-ca-table',
  templateUrl: './correspondence-analysis-table.component.html',
  styleUrls: ['./correspondence-analysis-table.component.scss'],
})
export class CorrespondenceAnalysisTableComponent
  extends TupCsvBuilder
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableData: CorrespondenceTableRowData[];
  @Input() selectedTableDataMode: CorrespondenceTableDataMode;
  @Input() sort: Sort;
  @Input() survey: Survey;
  @Input() isTableHighlighted: boolean;
  @Output() tableDataChange = new EventEmitter<CorrespondenceTableRowData>();
  @Output() sortChange = new EventEmitter<Sort>();
  @ViewChild(MatSort, { static: true }) tableSort: MatSort;

  public readonly correspondenceTableDataMode = CorrespondenceTableDataMode;
  public readonly correspondenceTableDataModeKeys =
    CorrespondenceTableDataModeKeys;
  public readonly factorAbsRelRowPrefix = FACTOR_ABS_REL_ROW_PREFIX;
  public readonly defaultColumnMap = {
    visible: {
      name: '',
      align: 'center',
      sortable: false,
    },
    rowNumber: {
      name: '',
      align: 'left',
      sortable: false,
    },
    title: { name: 'Title', align: 'left', sortable: false },
    type: { name: 'Type', align: 'right', sortable: false },
    inf: { name: '%INF', align: 'right', sortable: true },
  };

  private readonly defaultColumns = [
    'visible',
    'rowNumber',
    'title',
    'type',
    'inf',
  ];
  public displayedColumns: string[] = [...this.defaultColumns];
  public columnMap = this.defaultColumnMap;
  public dataSource: MatTableDataSource<CorrespondenceTableRowData> =
    new TableVirtualScrollDataSource([]);
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private csvService: CsvService,
    private xlsxService: XlsxService,
    private targetTitlePipe: TargetTitlePipe
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData || changes.selectedDataModel) {
      this.setupTable(cloneDeep(this.tableData));
    }
    if (changes.sort && !changes.sort.firstChange) {
      this.populateTableData(
        this.getSortedTableData(this.tableData, this.sort)
      );
    }
  }

  ngOnDestroy(): void {
    this.clearCurrentData();
    this.unsubscribeAll();
  }

  sortData(sort: Sort) {
    this.sortChange.emit(sort);
  }

  private getSortedTableData(
    data: CorrespondenceTableRowData[],
    sort: Sort
  ): CorrespondenceTableRowData[] {
    if (sort.active === '' || sort.direction === '') {
      return cloneDeep(data);
    } else {
      const isAsc = sort.direction === 'asc';
      const { activeColumns, passiveColumns, activeRows, passiveRows } =
        cloneDeep(data).reduce(
          (acc, value) => {
            value.type === CorrespondenceDataRowType.activeColumn &&
              acc.activeColumns.push(value);
            value.type === CorrespondenceDataRowType.activeRow &&
              acc.activeRows.push(value);
            value.type === CorrespondenceDataRowType.passiveColumn &&
              acc.passiveColumns.push(value);
            value.type === CorrespondenceDataRowType.passiveRow &&
              acc.passiveRows.push(value);
            return acc;
          },
          {
            activeColumns: [],
            passiveColumns: [],
            activeRows: [],
            passiveRows: [],
          }
        );

      return [
        ...this.sortSelectedTableRowsByNumber(
          activeColumns,
          sort.active,
          isAsc
        ),
        ...this.sortSelectedTableRowsByNumber(
          passiveColumns,
          sort.active,
          isAsc
        ),
        ...this.sortSelectedTableRowsByNumber(activeRows, sort.active, isAsc),
        ...this.sortSelectedTableRowsByNumber(passiveRows, sort.active, isAsc),
      ];
    }
  }

  private sortSelectedTableRowsByNumber(
    tableRows: CorrespondenceTableRowData[],
    sortElement: string,
    isAsc: boolean
  ) {
    const sortOn =
      this.selectedTableDataMode === CorrespondenceTableDataMode.absRel
        ? 'absContribution'
        : 'coordinates';
    const sortIndex = Number(sortElement[sortElement.length - 1]) - 1;
    let sortedRows = tableRows.sort((a, b) => {
      if (sortElement.startsWith(this.factorAbsRelRowPrefix)) {
        return compare(a[sortOn][sortIndex], b[sortOn][sortIndex], isAsc);
      } else {
        return compare(a[sortElement], b[sortElement], isAsc);
      }
    });
    if (this.selectedTableDataMode === CorrespondenceTableDataMode.absRel) {
      sortedRows = sortedRows.sort((a, b) => {
        return compare(a.relSign[sortIndex], b.relSign[sortIndex], true);
      });
    }
    return [...sortedRows];
  }

  private setupTable(data: CorrespondenceTableRowData[]): void {
    this.clearCurrentData();
    this.populateTableData(this.getSortedTableData(data, this.sort));
    this.addFactorAbsRelColumns(data);
    this.setSort(this.sort);
  }

  private populateTableData(data: CorrespondenceTableRowData[]): void {
    const showAbsRelContribution =
      this.selectedTableDataMode === CorrespondenceTableDataMode.absRel;
    this.dataSource.data = data.map((row, index) => ({
      ...row,
      ...(showAbsRelContribution
        ? row.absContribution
        : row.coordinates
      ).reduce((acc, value, i) => {
        acc[`${this.factorAbsRelRowPrefix}-${i + 1}`] = showAbsRelContribution
          ? `${value}  ${row.relSign[i]} ${row.relContribution[i]}`
          : value;
        return acc;
      }, {}),
      rowNumber: index + 1,
    }));
  }

  private addFactorAbsRelColumns(data: CorrespondenceTableRowData[]) {
    this.displayedColumns.push(
      ...Array.from(
        { length: data[0].absContribution.length },
        (_, index) => `${this.factorAbsRelRowPrefix}-${index + 1}`
      )
    );

    this.columnMap = {
      ...this.columnMap,
      ...(this.selectedTableDataMode === CorrespondenceTableDataMode.absRel
        ? data[0].absContribution
        : data[0].coordinates
      ).reduce((acc, value, index) => {
        acc[`${this.factorAbsRelRowPrefix}-${index + 1}`] = {
          name:
            `Factor ${index + 1} \n` +
            (this.selectedTableDataMode === CorrespondenceTableDataMode.absRel
              ? 'ABS REL'
              : ''),
          align: 'right',
          sortable: true,
        };
        return acc;
      }, {}),
    };
  }

  private clearCurrentData(): void {
    this.dataSource.data = [];
    this.displayedColumns = [...this.defaultColumns];
  }

  private unsubscribeAll(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public updateVisibility(element: any) {
    element.visible = !element.visible;
    this.tableData.filter((row) => row.coding === element.coding)[0].visible =
      element.visible;
    this.tableDataChange.emit(element);
  }

  public getCorrespondenceRowType(value: number) {
    return CorrespondenceDataRowType[value];
  }

  public exportToCsv(docName?: string): void {
    const documentName = this.getExportDocumentName(docName);
    const crossTabTableCsvBuilder: CrosstabTableCsvBuilder =
      new CrosstabTableCsvBuilder(this.targetTitlePipe);
    crossTabTableCsvBuilder.addCorrespondenceTableData({
      documentName,
      displayedColumns: [
        ...this.getDefaultColumnTitles(),
        ...(this.selectedTableDataMode === CorrespondenceTableDataMode.absRel
          ? [
              ...this.getTableModeDisplayedColumns(
                CorrespondenceTableDataMode.absRel
              ),
              ...this.getTableModeDisplayedColumns(
                CorrespondenceTableDataMode.factor
              ),
            ]
          : [
              ...this.getTableModeDisplayedColumns(
                CorrespondenceTableDataMode.factor
              ),
              ...this.getTableModeDisplayedColumns(
                CorrespondenceTableDataMode.absRel
              ),
            ]),
      ],
      data: this.dataSource.data.map((row) => ({
        ...this.tableData.filter(
          (tableDataRow) => tableDataRow.coding === row.coding
        )[0],
      })),
      survey: this.survey,
      primaryTabTableDataMode: this.selectedTableDataMode,
      ...(!this.tableSort.active
        ? {}
        : {
            sort: {
              surveyCode: this.survey.code,
              sortColumn: this.getSortColumn(),
              sortOrder:
                this.tableSort.direction.charAt(0).toUpperCase() +
                this.tableSort.direction.slice(1),
            },
          }),
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
    });
    this.csvService.saveAs(crossTabTableCsvBuilder, `${documentName}.csv`);
  }

  public exportToXlsx(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.getXlsxExportData(docName);
    this.xlsxService.saveAs(crossTabTableBuilder, documentName);
  }

  private getXlsxExportData(
    docName?: string
  ): [string, CrosstabTableXlsxBuilder] {
    const crossTabTableBuilder: CrosstabTableXlsxBuilder =
      new CrosstabTableXlsxBuilder(this.targetTitlePipe);
    crossTabTableBuilder.init('Telmar');
    const crossTabTableData: CorrespondenceTableXlsxData =
      this.prepareXlsxExportData(docName);
    crossTabTableBuilder.addCorrespondenceTable(crossTabTableData);
    crossTabTableBuilder.build();

    return [crossTabTableData.documentName, crossTabTableBuilder];
  }

  public exportToSheets(docName?: string) {
    const [documentName, crossTabTableBuilder] =
      this.getXlsxExportData(docName);
    this.xlsxService.exportToSheets(crossTabTableBuilder, documentName);
  }

  private prepareXlsxExportData(docName?: string): CorrespondenceTableXlsxData {
    return {
      documentName: this.getExportDocumentName(docName),
      data: this.dataSource.data.map((row) => ({
        ...this.tableData.filter(
          (tableDataRow) => tableDataRow.coding === row.coding
        )[0],
      })),
      survey: this.survey,
      factorTabDisplayedColumns: [
        ...this.getDefaultColumnTitles(),
        ...this.getTableModeDisplayedColumns(
          CorrespondenceTableDataMode.factor
        ),
      ],
      absRelTabDisplayedColumns: [
        ...this.getDefaultColumnTitles(),
        ...this.getTableModeDisplayedColumns(
          CorrespondenceTableDataMode.absRel
        ),
      ],
      primaryTabTableDataMode: this.selectedTableDataMode,
      ...(!this.tableSort.active
        ? {}
        : {
            sort: {
              surveyCode: this.survey.code,
              sortColumn: this.getSortColumn(),
              sortOrder:
                this.tableSort.direction.charAt(0).toUpperCase() +
                this.tableSort.direction.slice(1),
            },
          }),
      isTableHighlighted: this.isTableHighlighted,
      surveyCodeMap: this.documentService.getSurveyCodeMap(),
    };
  }

  private getTableModeDisplayedColumns(dataMode: CorrespondenceTableDataMode) {
    if (dataMode === CorrespondenceTableDataMode.factor) {
      return [
        ...this.tableData[0].coordinates.map(
          (_, index) => `Factor ${index + 1}`
        ),
      ];
    }

    return [
      ...this.tableData[0].absContribution.map(
        (_, index) => `Factor ${index + 1} ABS REL`
      ),
    ];
  }

  private getExportDocumentName(docName?: string) {
    return `${
      docName || this.documentService.document.metadata.name
    } - Correspondence Analysis`;
  }

  private getDefaultColumnTitles() {
    return this.defaultColumns.map(
      (column) => this.defaultColumnMap[column].name
    );
  }

  private getSortColumn() {
    if (this.defaultColumns.includes(this.tableSort.active)) {
      return this.defaultColumnMap[this.tableSort.active].name;
    }

    if (!this.tableSort.active.startsWith(FACTOR_ABS_REL_ROW_PREFIX)) {
      return false;
    }

    if (this.selectedTableDataMode === CorrespondenceTableDataMode.absRel) {
      return `Factor ${
        this.tableSort.active[this.tableSort.active.length - 1]
      } ABS REL`;
    }

    return `Factor ${this.tableSort.active[this.tableSort.active.length - 1]}`;
  }

  private setSort(sort: Sort): void {
    this.tableSort.active = sort.active;
    this.tableSort.direction = sort.direction;
  }
}
