<div
  fxFill
  fxLayout="column"
>
  <app-top-bar
    [selectedClusterViewResult]="selectedViewResults"
    [selectViewType]="documentViewType.cluster"
    [showSurveyInfo]="false"
    [isReadonly]="isReadonly"
    (exportToPNG)="exportTo('image/png', $event)"
    (exportToJPEG)="exportTo('image/jpeg', $event)"
    (exportToPDF)="exportTo('application/pdf', $event)"
    (exportToSVG)="exportTo('image/svg+xml', $event)"
    (exportToCsv)="exportToCsv($event)"
    (exportToXlsx)="exportToXlsx($event)"
    (exportToSheets)="exportToSheets($event)"
    (exportToPptx)="exportToPptx($event)"
  ></app-top-bar>
  <mat-progress-bar
    *ngIf="gettingSolutions"
    color="primary"
    mode="indeterminate"
  >
  </mat-progress-bar>
  <mat-progress-bar
    *ngIf="status === researchStatus.onGoing"
    color="primary"
    [value]="statusValue"
    mode="determinate"
  >
  </mat-progress-bar>
  <div
    fxLayout="column"
    *ngIf="currentDoc?.content?.rows.length !== 0"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="10px"
      class="cluster-control-bar"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
      >
        <mat-form-field
          appearance="outline"
          class="input-form-field"
          style="width: 100px"
          class="select-small"
          tupMatFormField
          matTooltip="View results as"
        >
          <mat-select
            [(ngModel)]="selectedViewResults"
            (ngModelChange)="onSelectedViewResultsChange()"
            [disabled]="inProgress || !clusterData"
          >
            <mat-option value="chart"> Chart </mat-option>
            <mat-option value="table"> Table </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-button-toggle-group
          [disabled]="inProgress || !clusterData || gettingSolutions"
          [(ngModel)]="showRecommendedChart"
          (change)="updateChartView()"
        >
          <mat-button-toggle
            class="toggle-padding"
            *ngFor="let mode of chartViewModes"
            [disableRipple]="true"
            [value]="mode.value"
            [matTooltip]="mode.tooltip"
          >
            {{ mode.title }}
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field
          appearance="outline"
          class="input-form-field select-small"
          style="width: 180px"
          matTooltip="Solutions"
          tupMatFormField
        >
          <mat-select
            [(ngModel)]="selectedClustersSolutionNumber"
            (ngModelChange)="onSelectedSolutionChange()"
            [disabled]="
              inProgress ||
              !clusterData ||
              showRecommendedChart === chartViewMode.recommended
            "
          >
            <mat-option
              [value]="solution.key"
              *ngFor="let solution of clustersSolutionTypeList"
            >
              {{ solution.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="surveys.length > 1"
          appearance="outline"
          class="input-form-field select-small"
          tupMatFormField
          style="width: 200px"
          matTooltip="Change survey"
        >
          <mat-select
            [(ngModel)]="selectedClusterSurvey"
            (ngModelChange)="onSelectedSurveyChanged()"
            [disabled]="
              inProgress || !clusterData || gettingSolutions || isReadonly
            "
          >
            <mat-option
              *ngFor="let survey of surveys"
              [value]="survey"
            >
              {{ survey.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
      >
        <mat-chip-list>
          <mat-chip
            *ngIf="selectedViewResults === 'table' && settings.showHighlighting"
            [disableRipple]="false"
            (removed)="clearHighlightSettings()"
            [popper]="highlightTooltip"
            [popperTrigger]="'hover'"
            [popperPlacement]="'bottom'"
            [popperAppendTo]="'app-cluster-table'"
            [popperStyles]="{ 'background-color': 'white' }"
            (click)="openSettings()"
            onclick="this.blur()"
            class="highlight-table-chip"
          >
            <mat-icon MatChipAvatar> palette </mat-icon>
            <popper-content
              #highlightTooltip
              fxLayout="column"
            >
              <p
                class="highlightTooltip"
                fxLayout="row"
                fxLayoutAlign="center center"
              >
                <b>Highlight:</b>
              </p>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
              >
                <span
                  class="highlight-color-dot-container"
                  *ngFor="let color of highlightColors"
                >
                  <span
                    class="highlight-color-dot"
                    [style.background-color]="color.background"
                  ></span>
                  {{ color.label }}
                </span>
                <span
                  >in
                  <b>{{ highlightColorTypes[settings.highlightType] }}</b></span
                >
              </div>
            </popper-content>
            <mat-icon
              matChipRemove
              matTooltipPosition="above"
              matTooltip="Remove highlight"
              >cancel
            </mat-icon>
          </mat-chip>
        </mat-chip-list>

        <button
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          appearance="tiny"
          class="data-control-button"
          *ngIf="!isReadonly"
          tupMatButton
          matTooltip="Re cluster"
          [disabled]="inProgress || !clusterData || gettingSolutions"
          (click)="onReCluster()"
        >
          <mat-icon class="tiny-icon">autorenew</mat-icon>
        </button>
        <button
          mat-stroked-button
          onclick="this.blur()"
          appearance="tiny"
          class="data-control-button"
          color="primary"
          *ngIf="!isReadonly"
          tupMatButton
          matTooltip="Variable management"
          [disabled]="inProgress || !clusterData || gettingSolutions"
          (click)="openVariableDialog()"
        >
          Variable management
        </button>
        <button
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          tupMatButton
          appearance="tiny"
          *ngIf="!isReadonly"
          class="data-control-button"
          matTooltip="Save cluster solution"
          [disabled]="inProgress || !clusterData"
          (click)="openSaveClusterCodesDialog()"
        >
          <mat-icon class="tiny-icon">calculate</mat-icon>
        </button>
        <button
          mat-stroked-button
          onclick="this.blur()"
          appearance="tiny"
          class="data-control-button"
          color="primary"
          *ngIf="!isReadonly"
          tupMatButton
          matTooltip="Preferences"
          [disabled]="inProgress || !clusterData || gettingSolutions"
          (click)="openPreferences()"
        >
          <mat-icon class="settings-icon">room_preferences</mat-icon>
        </button>

        <button
          onclick="this.blur()"
          mat-stroked-button
          color="primary"
          tupMatButton
          appearance="tiny"
          matTooltip="Change title mode"
          [matMenuTriggerFor]="changeTitleModeMenu"
        >
          <mat-icon class="material-symbols-outlined"> title </mat-icon>
        </button>

        <button
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          tupMatButton
          appearance="tiny"
          matTooltip="Settings"
          [disabled]="inProgress || !clusterData"
          (click)="openSettings()"
        >
          <mat-icon class="tiny-icon">settings</mat-icon>
        </button>
        <button
          *ngIf="selectedViewResults !== 'table'"
          mat-stroked-button
          onclick="this.blur()"
          color="primary"
          tupMatButton
          appearance="tiny"
          matTooltip="Full screen"
          [disabled]="inProgress || !clusterData"
          (click)="openFullScreen()"
        >
          <mat-icon class="tiny-icon">open_in_full</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <tup-highcharts
    *ngIf="
      status === researchStatus.finished &&
      chartOptions &&
      selectedViewResults === 'chart'! &&
      showRecommendedChart === chartViewMode.default
    "
    [Highcharts]="Highcharts"
    [callbackFunction]="chartCallback"
    [options]="chartOptions"
    class="cluster-chart"
  >
  </tup-highcharts>

  <tup-highcharts
    *ngIf="
      status === researchStatus.finished &&
      recommendedChartOptions &&
      selectedViewResults === 'chart' &&
      showRecommendedChart === chartViewMode.recommended
    "
    [Highcharts]="Highcharts"
    [callbackFunction]="chartCallback"
    [options]="recommendedChartOptions"
    class="cluster-chart"
  >
  </tup-highcharts>

  <app-cluster-table
    *ngIf="
      status === researchStatus.finished && selectedViewResults === 'table'
    "
    [tableData]="tableData"
    [survey]="selectedClusterSurvey"
    [clusterTitles]="clusterTitles"
    (clusterTitleChanged)="onClusterTitleChanged($event)"
  ></app-cluster-table>

  <tup-mat-card
    class="empty-copy"
    fxLayout="column"
    *ngIf="
      status === researchStatus.failure ||
      currentDoc?.content?.rows.length === 0 ||
      (!researchId && isReadonly)
    "
  >
    <span *ngIf="!isReadonly">
      Cluster calculation isn't available. Please review the coding statements
      in rows in the crosstab.
    </span>
    <span *ngIf="isReadonly"> No cluster data. </span>
  </tup-mat-card>

  <div
    class="spinner-container-overlay"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="inProgress && currentDoc?.content?.rows.length > 0"
  >
    <div class="center-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <div
      class="spinner-message"
      *ngIf="progressMessage"
    >
      <p>
        {{ progressMessage }} - <b>{{ statusValue }}% finished</b>
      </p>
    </div>
  </div>
</div>

<ng-template #reClusterConfirmation>
  <p>
    Performing a re-cluster will
    <b>reset the current clusters, settings and selected variables</b> and start
    a new cluster calculation by using current rows in the crosstab.
  </p>
</ng-template>

<ng-template #researchIdNotFound>
  <p>There is an issue with the clustering calculation:</p>
  <p>
    <b>Message: {{ statusMessage?.issue || statusMessage }}</b>
  </p>

  <p>
    <b>Research Id: {{ researchId }}</b>
  </p>
</ng-template>

<mat-menu #changeTitleModeMenu="matMenu">
  <div
    fxLayout="column"
    fxLayoutGap="5px"
    class="title-mode-menu"
  >
    <mat-radio-group
      [(ngModel)]="activeTitleMode"
      fxLayout="column"
      fxLayoutGap="15px"
      (ngModelChange)="selectTitleMode()"
    >
      <mat-radio-button
        *ngFor="let mode of titleModes"
        [value]="mode.displayType"
        (click)="clickTitleMode(mode.displayType)"
      >
        {{ mode.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-menu>
