<div class="dialog-content-container">
  <tup-mat-title-bar
    title="Data items"
    class="dialog-title"
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="close()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div
      fxLayout="column"
      class="data-item-list"
    >
      <div
        class="scroll"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <div
          *ngFor="let item of dataItems"
          cdkDrag
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <span
              cdkDragHandle
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <mat-icon> menu </mat-icon>
            </span>
            <mat-checkbox
              tupMatCheckbox
              appearance="small"
              color="primary"
              [(ngModel)]="item.selected"
              [disabled]="item.unchangeable"
              class="available-checkboxes"
              (change)="checked($event)"
              *ngIf="item.available"
            >
              {{ item.displayName }}
            </mat-checkbox>
            <button
              *ngIf="
                item.available &&
                (item.id === dataItemType.audience ||
                  item.id === dataItemType.nTileEnd ||
                  item.id === dataItemType.nTileStart)
              "
              mat-icon-button
              color="primary"
              tupMatButton
              appearance="tiny"
            >
              <mat-icon
                [matTooltip]="item.description"
                class="material-icons-outlined"
                >info</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <mat-form-field
      appearance="outline"
      tupMatFormField
      fxFlex
      class="input-form-field"
    >
      <mat-label>Decimal place</mat-label>
      <mat-select [(ngModel)]="decimalPoints">
        <mat-option
          *ngFor="let option of decimalPointOptions"
          [value]="option.value"
        >
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <h5
      class="error-message"
      *ngIf="disableButton"
    >
      You must select at least one data item for your crosstab
    </h5>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="close()"
    >
      Cancel
    </button>
    <div>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        (click)="onReset()"
        data-qa="reset-data-items-button"
      >
        Reset
      </button>
      <button
        mat-flat-button
        color="primary"
        appearance="medium"
        tupMatButton
        [disabled]="disableButton"
        (click)="onApplyButtonClick()"
      >
        Apply
      </button>
    </div>
  </mat-dialog-actions>
</div>
