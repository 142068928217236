<div
  fxLayout="row"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
  class="variable-selection-view"
>
  <app-audience-effects-variable-table
    #variableTable
    [survey]="survey"
    [activeTableTitle]="activeTableTitle"
    [totalPopulation]="totalPopulation"
    [totalSample]="totalSample"
    [tableData]="variableTableData"
    [hoveringRow]="hoveringVariableRow"
    (tableRowChange)="onTableRowChange($event)"
    (tableRowsChange)="onTableRowsChange($event)"
    (tableRowMouseEnter)="onTableRowMouseEnter($event)"
    (tableRowMouseLeave)="onTableRowMouseLeave($event)"
    class="variable-table"
    id="variable-table"
  >
  </app-audience-effects-variable-table>
  <tup-highcharts
    *ngIf="chartOptions"
    id="container"
    [Highcharts]="Highcharts"
    [callbackFunction]="chartCallback"
    [options]="chartOptions"
    class="variable-chart"
  >
  </tup-highcharts>
</div>
