import { Pipe, PipeTransform } from '@angular/core';

import { compact } from 'lodash';

@Pipe({
  name: 'chartTitle',
})
export class ChartTitlePipe implements PipeTransform {
  public transform(chartTitle: string, targetTitle: string): string {
    return chartTitle ? chartTitle : targetTitle;
  }
}
