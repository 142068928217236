<tup-mat-furniture
  (logoClick)="onLogoClick()"
  [sidebarOpenState]="false"
  (toggleSidePanel)="onToggleSidePanel($event)"
  [hasHiddenTopbar]="false"
  [appVersion]="version"
  [helpLink]="helpLink"
>
  <tup-mat-sidebar-content>
    <mat-divider
      class="side-divider"
      [class.side-bar-opened]="sidebarOpened"
    ></mat-divider>

    <tup-side-nav
      [sidebarOpened]="sidebarOpened"
      (userAction)="onUserAction($event)"
      [dashboardRouteConfig]="dashboardRouteConfig"
      [docRoutesConfig]="docRoutesConfig"
    ></tup-side-nav>
  </tup-mat-sidebar-content>

  <tup-mat-sidebar-footer-content> </tup-mat-sidebar-footer-content>
  <div
    fxFill
    fxFlex="auto"
  >
    <ng-content></ng-content>
  </div>
</tup-mat-furniture>
