import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ALL_RESPONDENTS_CODING,
  SearchHit,
  CodingGridTableRow,
  DEFAULT_WEIGHT_INDEX,
  DisplayType,
  DocumentDataChange,
  DocumentDataState,
  Operator,
  SendCodingGridTableRow,
  Statement,
  Survey,
  SurveyMetaDataWeights,
  SWAP_ROWS_COLUMNS_WARNING,
  Target,
  TargetAction,
  TargetItem,
  TargetType,
  TitleModeGridTableRow,
  ViewType,
  SurveyProvider,
  CodingGridPreferences,
  READONLY_CODING_GRID_COLUMNS,
  CodingGridHideColumnMap,
  PERSISTENT_CODING_GRID_COLUMNS,
  MoreMenuVisibility,
  MoreMenuVisibilityOptions,
  EXTRA_LARGE_BREAKPOINT,
  LARGE_BREAKPOINT,
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT,
  EXTRA_SMALL_BREAKPOINT,
  EMPTY_GRID_ROW_SIZE,
} from '../../models';
import {
  CodebookSelectionService,
  DocumentService,
  RequestLoadingService,
  TargetLoading,
  TargetService,
} from '../../services';
import { Subject } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DropDataContext } from '../../pages';
import { cloneDeep } from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import { TargetTitlePipe } from '../../pipes';
import { CodingGridTableComponent } from '../coding-grid-table/coding-grid-table.component';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import {
  ColumnRowActionItem,
  COMBINE_BUTTON_ACTION_ITEMS,
  CombineActionItem,
  DEFAULT_BASE_TABLE_ACTION_ITEMS,
  DEFAULT_CODING_GRID_ACTION_ITEMS,
  DeleteTargetTypesActionItem,
  DropActionItem,
  SEND_TO_ACTION_ITEMS,
  TITLE_ACTIONS,
} from '../../models/action.model';
import { SwapRowsColumnsAction } from '../../actions/SwapRowsColumnsAction';
import { AssignGroupNameAction } from '../../actions/AssignGroupNameAction';
import { SeparateCountAction } from '../../actions/SeparateCountAction';
import { DuplicateTargetsAction } from '../../actions/DuplicateTargetsAction';
import { isNotNullOrUndefined } from '../../utils/pipeable-operators';
import { ChangeTargetTitleModeAction } from 'src/app/actions/ChangeTargetTitleModeAction';
import { MatSelectChange } from '@angular/material/select';
import { SaveCustomAudienceAction } from '../../actions/SaveCustomAudienceAction';
import { DeleteTargetTypesAction } from '../../actions/DeleteTargetTypesAction';
import { MatMenuTrigger } from '@angular/material/menu';
import { FindAndReplaceService } from 'src/app/services/find-and-replace.service';
import { ActivatedRoute } from '@angular/router';
import { CodingGridService } from 'src/app/services/coding-grid.service';
import {
  MoveTargetItemsAction,
  MoveTargetItemsActionContext,
} from '../../actions/MoveTargetItemsAction';
import { Sort } from '@angular/material/sort';
import { MatSelectionList } from '@angular/material/list';
import { CodingGridActionControlsComponent } from './coding-grid-action-controls/coding-grid-action-controls.component';
import { RenameTargetAction } from '../../actions/RenameTargetAction';

@Component({
  selector: 'app-coding-grid-view',
  templateUrl: './coding-grid-view.component.html',
  styleUrls: ['./coding-grid-view.component.scss'],
})
export class CodingGridViewComponent implements OnInit, OnDestroy {
  public readonly emptyGridRowSize = EMPTY_GRID_ROW_SIZE;

  public readonly targetType: typeof TargetType = TargetType;
  public selectedTab = this.targetType.tables;

  public tablesTabLabel = '(0)';
  public columnsTabLabel = '(0)';
  public rowsTabLabel = '(0)';

  public tables: CodingGridTableRow[] = [];
  public columns: CodingGridTableRow[] = [];
  public rows: CodingGridTableRow[] = [];

  public codingGridPreferences: CodingGridPreferences;

  public selectedRows: CodingGridTableRow[] = [];
  public sortedRows: Record<TargetType, CodingGridTableRow[]> = {
    [TargetType.tables]: [],
    [TargetType.columns]: [],
    [TargetType.rows]: [],
  };
  public gridSorts: Record<TargetType, Sort> = {
    [TargetType.tables]: { active: '', direction: '' },
    [TargetType.columns]: { active: '', direction: '' },
    [TargetType.rows]: { active: '', direction: '' },
  };

  private unsubscribe: Subject<void> = new Subject<void>();

  public isDeletable = false;
  public isProgressing = false;

  public searchHits: SearchHit[];
  public findAndReplaceDialogState: boolean;
  public findAndReplaceDialogOpen = false;

  @Input() isViewActive: boolean;
  @Input() surveys: Survey[];
  @Output() dropNode = new EventEmitter<
    DropDataContext<DropActionItem | Operator>
  >();
  @Output() targetClick: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Input() isReadonly = true;
  @Output() ntileClick: EventEmitter<TargetItem> =
    new EventEmitter<TargetItem>();
  @Output() newCodeClick: EventEmitter<TargetType> =
    new EventEmitter<TargetType>();
  @Output() findAndReplaceClick: EventEmitter<{
    rows: CodingGridTableRow[];
    targetType: TargetType;
  }> = new EventEmitter<{
    rows: CodingGridTableRow[];
    targetType: TargetType;
  }>();

  @ViewChild('tableCodingGrid')
  private tableCodingGrid: CodingGridTableComponent;
  @ViewChild('columnCodingGrid')
  private columnCodingGrid: CodingGridTableComponent;
  @ViewChild('rowCodingGrid') private rowCodingGrid: CodingGridTableComponent;

  @ViewChild('appCodingGridActionControls', { static: true })
  public appCodingGridActionControls: CodingGridActionControlsComponent;

  public combineActionItems = COMBINE_BUTTON_ACTION_ITEMS;
  public sendToActionItems = SEND_TO_ACTION_ITEMS;
  public changeTitleModeItems = (TITLE_ACTIONS[0] as ColumnRowActionItem)
    .actions;
  public swapActionWarning = '';

  public hideColumnItems: CodingGridHideColumnMap =
    {} as CodingGridHideColumnMap;
  public isColumnHidden = false;

  public weightDescriptions: SurveyMetaDataWeights[] = [];
  public filteredWeightDescriptions: SurveyMetaDataWeights[] = [];
  public currentWeightIndex = 1;

  private dropzoneTargetType: TargetType = TargetType.tables;
  private dropzoneTargetItem: TargetItem;
  public dropzoneActionItems: DropActionItem[] =
    DEFAULT_CODING_GRID_ACTION_ITEMS;

  private dropzoneEnterCount = 0;
  private isOverActionMenu = false;
  private isDropzoneMenuOpen = false;
  private activeMenuTrigger: MatMenuTrigger;
  private menuTarget: EventTarget;

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('virtualButton') public virtualButton;
  public readonly menuItemDragoverClass = 'drag-over-menu-button';
  public isLoadingSelectedNodes = false;

  private selectedCodebookNodes: Target[] = [];

  @ViewChild('gridViewContainer') public gridViewContainer: ElementRef;
  public moreMenuVisibility: MoreMenuVisibility = '';
  public readonly moreMenuVisibilityOptions = MoreMenuVisibilityOptions;

  constructor(
    private documentService: DocumentService,
    private userMessageService: TupUserMessageService,
    private targetTitlePipe: TargetTitlePipe,
    private codebookSelectionService: CodebookSelectionService,
    private targetService: TargetService,
    private injector: Injector,
    private requestLoadingService: RequestLoadingService,
    private swapRowsColumnsActionService: SwapRowsColumnsAction,
    private moveTargetItemActionService: MoveTargetItemsAction,
    private findAndReplaceService: FindAndReplaceService,
    private activatedRoute: ActivatedRoute,
    private codingGridService: CodingGridService
  ) {}

  ngOnInit(): void {
    this.listenToDocumentDataChanges();
    this.listenToActiveSurveyChanges();
    this.listenToSurveyDataWeightsChanges();
    this.listenToLoadingState();
    this.listenToCodingGridPreferences();
    this.listenToSearchHitChanges();
    this.listenToSelectedNodes();
    this.setActiveCodingGridRows();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('window:keydown', ['$event'])
  listenToSearchEvent(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
      if (
        this.activatedRoute.snapshot.queryParams?.tab ===
          String(ViewType.codingGrid) &&
        !(this.findAndReplaceDialogOpen || this.findAndReplaceDialogState)
      ) {
        event.preventDefault();
        this.onOpenFindAndReplace();
      }
    }
  }

  public onTabChange(tab: MatTabChangeEvent) {
    this.selectedTab = tab.index;
    this.codingGridService.updateActiveGridRows(
      this.sortedRows[this.selectedTab]
    );
    this.codingGridService.updateActiveGrid(this.selectedTab);
    this.resetGrids();
  }

  public onOpenFindAndReplace(): void {
    if (this.findAndReplaceDialogOpen) {
      return;
    }

    this.findAndReplaceDialogOpen = true;
    setTimeout(() => {
      this.findAndReplaceDialogOpen = false;
    }, 1000);

    this.findAndReplaceClick.emit({
      rows: this.sortedRows[this.selectedTab],
      targetType: this.selectedTab,
    });
  }

  public onOpenNewCodeBuilder(): void {
    this.newCodeClick.emit(this.selectedTab);
  }

  public onOpenCodeBuilder(row?: CodingGridTableRow): void {
    const targetItem: TargetItem = row
      ? cloneDeep(row.targetItem)
      : cloneDeep(this.selectedRows[0].targetItem);
    this.targetClick.emit(targetItem);
  }

  public onOpenNTileSettings(row?: CodingGridTableRow): void {
    const targetItem: TargetItem = row
      ? cloneDeep(row.targetItem)
      : cloneDeep(this.selectedRows[0].targetItem);
    this.ntileClick.emit(targetItem);
  }

  public onSwapRowsAndColumns(): void {
    this.swapRowsColumnsActionService.invoke();
    this.resetGrids();
  }

  public onMoveItems(moveItem: MoveTargetItemsActionContext): void {
    this.moveTargetItemActionService.invoke(moveItem);
    this.resetGrids();
  }

  public onSortChange(sort: Sort): void {
    this.gridSorts[this.selectedTab] = sort;
  }

  public onSortedRowsChange(
    rows: CodingGridTableRow[],
    type: TargetType
  ): void {
    this.sortedRows[type] = rows.filter((row) => !row.isEmptyRow);
    if (this.selectedTab === type) {
      this.codingGridService.updateActiveGridRows(
        this.sortedRows[this.selectedTab]
      );
    }
  }

  public onSelectedRowsChange(rows: CodingGridTableRow[]): void {
    this.selectedRows = rows;
    this.updateTabLabels();
    this.updateDeleteAbility();
  }

  public onClickRow(row: CodingGridTableRow): void {
    const targetItem: TargetItem = cloneDeep(row.targetItem);
    this.targetClick.emit(targetItem);
  }

  public onClickEditTitleRow(row: CodingGridTableRow): void {
    const targetItem: TargetItem = cloneDeep(row.targetItem);
    this.injector.get(RenameTargetAction).invoke({
      targetItem,
    });
  }

  public onClickEmptyRow(targetType: TargetType): void {
    this.newCodeClick.emit(targetType);
  }

  public onEditTablebase(targetItem: TargetItem): void {
    this.targetClick.emit(targetItem);
  }

  public onDeleteSelectedRows(row?: CodingGridTableRow): void {
    if (this.isAllSelected()) {
      this.showDeleteMessageBeforeDelete();
    } else {
      this.deleteSelectedRows(row);
    }
  }

  public onSaveCustomAudience(row?: CodingGridTableRow): void {
    this.injector.get(SaveCustomAudienceAction).invoke({
      targetType: this.selectedTab,
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onDeleteCodingGrid(): void {
    this.onDeleteAllRows({
      name: 'Delete coding grid',
      action: DeleteTargetTypesAction,
      targetTypes: [TargetType.tables, TargetType.columns, TargetType.rows],
    });
  }

  public onDeleteAllRows(actionItem: DeleteTargetTypesActionItem): void {
    this.injector.get(actionItem.action).invoke({
      actionItem,
    });
    this.resetGrids();
  }

  public onCombineActionClicked(actionItem: CombineActionItem): void {
    this.injector.get(actionItem.action).invoke({
      targetType: this.selectedTab,
      selectedTargets: this.selectedRows.map(
        (selectedRow: CodingGridTableRow) => ({
          ...cloneDeep(selectedRow.targetItem.target.targets[0]),
          activeTitleMode: undefined,
          titleLevels: undefined,
        })
      ),
      actionItem,
    });
    this.resetGrids();
  }

  public onSeparateCountActionClicked(row?: CodingGridTableRow): void {
    this.injector.get(SeparateCountAction).invoke({
      targetType: this.selectedTab,
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onChangeTargetTitleModeActionClicked(
    actionItem: TitleModeGridTableRow
  ): void {
    if (actionItem?.row) {
      this.injector.get(ChangeTargetTitleModeAction).invoke({
        targetItem: [actionItem.row.targetItem],
        actionItem,
      });
    } else if (this.selectedRows?.length >= 1) {
      this.injector.get(ChangeTargetTitleModeAction).invoke({
        targetItem: this.selectedRows.map((row) => row.targetItem),
        actionItem,
      });
    }
  }

  public onSendTo(sendRow: SendCodingGridTableRow): void {
    this.injector.get(sendRow.actionItem.action).invoke({
      actionItem: sendRow.actionItem,
      ...(sendRow.row
        ? {
            targetItem: sendRow.row.targetItem,
          }
        : {
            targetItems: this.selectedRows.map(
              (row: CodingGridTableRow) => row.targetItem
            ),
          }),
    });
  }

  public onRenameGroupName(row?: CodingGridTableRow): void {
    this.injector.get(AssignGroupNameAction).invoke({
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onDuplicateSelectedRows(row?: CodingGridTableRow): void {
    this.injector.get(DuplicateTargetsAction).invoke({
      targetItems: row
        ? [row.targetItem]
        : this.selectedRows.map(
            (selectedRow: CodingGridTableRow) => selectedRow.targetItem
          ),
    });
  }

  public onDropzoneEnter(
    trigger: MatMenuTrigger,
    targetType: TargetType,
    targetItem?: TargetItem
  ): void {
    this.dropzoneTargetType = targetType;
    this.dropzoneTargetItem = targetItem;

    this.dropzoneEnterCount++;
    setTimeout(() => {
      if (this.dropzoneEnterCount === 0 || this.activeMenuTrigger === trigger) {
        return;
      }
      if (
        this.activeMenuTrigger &&
        (this.isDropzoneMenuOpen || this.dropzoneEnterCount > 1)
      ) {
        this.closeActionMenu();
      }
      this.activeMenuTrigger = trigger;
      this.isDropzoneMenuOpen = true;

      if (!this.hasDropTargetItem()) {
        this.dropzoneActionItems = DEFAULT_BASE_TABLE_ACTION_ITEMS;
      }

      if (this.canOpenCombineMenuOptions()) {
        trigger.openMenu();
      }
      // need to focus on something else, otherwise the first item of the menu gets focused for some reason
      this.virtualButton.nativeElement.focus();
    }, 300);
  }

  public onDropzoneLeave(): void {
    this.dropzoneEnterCount--;
    setTimeout(() => {
      this.closeActionMenu();
    }, 200);
  }

  public onTableDropzoneDrop(targetItem: TargetItem): void {
    this.dropzoneTargetItem = targetItem;
    this.onDrop(
      !this.hasDropTargetItem()
        ? DEFAULT_BASE_TABLE_ACTION_ITEMS[1]
        : DEFAULT_BASE_TABLE_ACTION_ITEMS[0]
    );
  }

  public onActionMenuEnter(event: DragEvent): void {
    this.menuTarget = event.currentTarget;
    this.isOverActionMenu = true;
  }

  public onActionMenuLeave(event: DragEvent): void {
    const target = event.currentTarget;
    // note: dndDragoverClass doesn't always get removed after leaving the target
    if (target && target instanceof Element) {
      target.classList.remove(this.menuItemDragoverClass);
    }
    if (this.menuTarget === target) {
      this.isOverActionMenu = false;
      this.closeActionMenu();
    }
  }

  public onDrop(actionItem: DropActionItem): void {
    if (this.isLoadingSelectedNodes) {
      return;
    }
    this.dropNode.emit({
      selectedNodes: this.codebookSelectionService.getSelectedNodes(),
      context: actionItem,
      handleDropNode: (action: DropActionItem, selectedNodes: Statement[]) =>
        this.handleDropNode(action, selectedNodes),
    });
    this.unsetDraggingState();
  }

  public onHideColumns() {
    const hiddenColumns = [];
    this.appCodingGridActionControls.hideColumnSelectionList.options.forEach(
      (option) => {
        !option.selected && hiddenColumns.push(option.value);
        this.hideColumnItems[this.selectedTab].filter(
          (item) => item.value === option.value
        )[0].show = option.selected;
      }
    );

    this.codingGridPreferences[this.selectedTab] = { hiddenColumns };
    this.codingGridPreferences = cloneDeep(this.codingGridPreferences);
    this.codingGridService.updateCodingGridPreferences(
      this.codingGridPreferences
    );
    this.isColumnHidden =
      this.codingGridPreferences[this.selectedTab].hiddenColumns.length > 0;
  }

  public handleDropNode(
    actionItem: DropActionItem,
    selectedNodes: Statement[]
  ): void {
    const dropzoneTargetRows =
      this.dropzoneTargetType === TargetType.columns
        ? this.columns
        : this.dropzoneTargetType === TargetType.rows
        ? this.rows
        : this.tables;
    this.injector.get(actionItem.action).invoke({
      targetType: this.dropzoneTargetType,
      selectedTargets:
        this.targetService.convertStatementsToTargets(selectedNodes),
      selectableTargets: dropzoneTargetRows
        .filter((row) => !row.isEmptyRow)
        .map((row) => row.targetItem.target),
      targetItem: this.dropzoneTargetItem,
      actionItem,
    });
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'crosstab' ||
            targetLoading.target === 'document'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isProgressing = targetLoading.isLoading;
      });
  }

  private listenToSearchHitChanges(): void {
    this.findAndReplaceService.dialogState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: boolean) => {
        setTimeout(() => {
          this.findAndReplaceDialogState = state;
        }, 0);
      });

    this.findAndReplaceService.searchHits$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((hits: SearchHit[]) => {
        this.searchHits = hits;
      });
  }

  private listenToCodingGridPreferences(): void {
    this.codingGridService.codingGridPreferences$
      .pipe()
      .subscribe((preferences: CodingGridPreferences) => {
        this.codingGridPreferences = cloneDeep(preferences);
        this.loadGridPreferences();
      });
  }

  private resetGrids(): void {
    this.tableCodingGrid.resetSelectedRowIds();
    this.columnCodingGrid.resetSelectedRowIds();
    this.rowCodingGrid.resetSelectedRowIds();
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.documentState$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state: DocumentDataState) => {
        this.handleDocumentStateChange(state);
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.swapActionWarning =
          survey.provider === SurveyProvider.youGov
            ? `: ${SWAP_ROWS_COLUMNS_WARNING}`
            : '';
      });
  }

  private handleDocumentStateChange({
    columns,
    rows,
    tables,
    changes,
  }: DocumentDataState): void {
    this.tables = this.formatGridData(tables, TargetType.tables);
    this.columns = this.formatGridData(columns, TargetType.columns);
    this.rows = this.formatGridData(rows, TargetType.rows);
    this.updateTabLabels();

    if (changes && changes[0].action !== TargetAction.delete) {
      this.selectUpdatedDocumentRows(changes);
    }
  }

  private selectUpdatedDocumentRows(changes: DocumentDataChange[]): void {
    const changesTargetType = changes[0].items[0].type;
    const tabRows =
      changesTargetType === this.targetType.tables
        ? this.tables
        : changesTargetType === this.targetType.columns
        ? this.columns
        : this.rows;
    const addedRows = changes[0].items
      .map((item: TargetItem) => item.index)
      .map((index: number) => tabRows[index]);

    this.selectedTab = changesTargetType;
    setTimeout(() => this.updateSelectedTableRows(addedRows), 200);
  }

  private updateSelectedTableRows(addedRows: CodingGridTableRow[]): void {
    switch (this.selectedTab) {
      case TargetType.tables:
        this.tableCodingGrid.setSelectedRows(addedRows);
        break;
      case TargetType.rows:
        this.rowCodingGrid.setSelectedRows(addedRows);
        break;
      case TargetType.columns:
        this.columnCodingGrid.setSelectedRows(addedRows);
        break;
    }
  }

  private formatGridData(
    targets: Target[],
    type: TargetType
  ): CodingGridTableRow[] {
    return [
      ...targets.map(
        (target: Target, index: number): CodingGridTableRow => ({
          id: index + 1,
          title: this.targetTitlePipe.transform(target),
          code: target.coding,
          groupName: this.targetTitlePipe.transform(target, DisplayType.group),
          selected: false,
          isEmptyRow: false,
          targetId: target.id,
          targetItem: {
            type,
            target,
            index,
          },
        })
      ),
      ...this.createEmptyGridData(targets.length),
    ];
  }

  private updateTabLabels(): void {
    const selectedRowLength = this.selectedRows.length;
    this.tablesTabLabel = `(${
      this.selectedTab === TargetType.tables ? selectedRowLength : 0
    } / ${this.tables.length - this.emptyGridRowSize})`;
    this.columnsTabLabel = `(${
      this.selectedTab === TargetType.columns ? selectedRowLength : 0
    } / ${this.columns.length - this.emptyGridRowSize})`;
    this.rowsTabLabel = `(${
      this.selectedTab === TargetType.rows ? selectedRowLength : 0
    } / ${this.rows.length - this.emptyGridRowSize})`;
  }

  private createEmptyGridData(startIndex: number): CodingGridTableRow[] {
    return Array(this.emptyGridRowSize)
      .fill(0)
      .map((v, i) => ({
        id: startIndex + i + 1,
        title: '',
        code: '',
        resps: '-',
        population: '-',
        groupName: '',
        selected: false,
        isEmptyRow: true,
      }));
  }

  private isAllSelected(): boolean {
    let isAll = false;
    let activeRowsNumber: number;
    switch (this.selectedTab) {
      case TargetType.tables:
        activeRowsNumber = this.tables.length - this.emptyGridRowSize;
        break;
      case TargetType.rows:
        activeRowsNumber = this.rows.length - this.emptyGridRowSize;
        break;
      case TargetType.columns:
        activeRowsNumber = this.columns.length - this.emptyGridRowSize;
        break;
    }
    if (this.selectedRows.length === activeRowsNumber) {
      isAll = true;
    }
    return isAll;
  }

  private showDeleteMessageBeforeDelete(): void {
    this.userMessageService
      .openDialog(
        `You selected to clear your entire grid. This action cannot be undone. Are you sure you want to delete your entire grid? `,
        'Delete',
        {
          cancelText: 'Cancel',
          confirmText: 'Delete',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.deleteSelectedRows();
      });
  }

  private deleteSelectedRows(row?: CodingGridTableRow): void {
    const selectedTargetItems: TargetItem[] = row
      ? [row.targetItem]
      : // tslint:disable-next-line:no-shadowed-variable
        this.selectedRows.map((row: CodingGridTableRow) => row.targetItem);

    this.documentService.deleteDocumentData(selectedTargetItems, true);
    this.resetGrids();
  }

  private updateDeleteAbility(): void {
    this.isDeletable =
      !this.isAllRespondentsSelected() && this.selectedRows.length > 0;
  }

  private isAllRespondentsSelected(): boolean {
    let isSelected = false;
    this.selectedRows.forEach((row) => {
      if (
        row.targetItem.type === TargetType.tables &&
        row.targetItem.target.coding === ALL_RESPONDENTS_CODING
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  }

  private listenToSelectedNodes(): void {
    this.codebookSelectionService.selectedNodes$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedNodes: Statement[]) => {
        this.selectedCodebookNodes =
          selectedNodes.length > 0
            ? this.targetService.convertStatementsToTargets(selectedNodes)
            : [];
      });
  }

  private listenToSurveyDataWeightsChanges(): void {
    this.documentService.surveyDataWeights$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((weights: SurveyMetaDataWeights[]) => {
        this.weightDescriptions = weights;
        this.filteredWeightDescriptions = weights;

        if (this.documentService.document?.content && weights.length) {
          const existingWeight = weights.find(
            (weight) =>
              weight.index === this.documentService.document.content.weight
          );

          this.currentWeightIndex = existingWeight
            ? existingWeight.index
            : DEFAULT_WEIGHT_INDEX;
        }
        this.updateCompatibleWeights();
      });
  }

  private updateCompatibleWeights() {
    const validWeights = this.documentService.getValidWeightsFromDocument();
    if (validWeights.length) {
      this.filteredWeightDescriptions = this.weightDescriptions.filter(
        (weight) => validWeights.includes(weight.index)
      );
      this.currentWeightIndex = this.documentService.getCompatibleWeight(
        validWeights,
        this.currentWeightIndex
      );
    } else {
      this.filteredWeightDescriptions = this.weightDescriptions;
      this.currentWeightIndex = this.documentService.document.content?.weight
        ? this.documentService.document.content.weight
        : DEFAULT_WEIGHT_INDEX;
    }
  }

  public onWeightDescriptionChange(item: MatSelectChange) {
    this.currentWeightIndex = item.value;
    if (this.documentService.document?.content) {
      this.documentService.updateCurrentWeight(this.currentWeightIndex);
    }
  }

  public isWeightSelectable(weight: SurveyMetaDataWeights): boolean {
    return !!this.filteredWeightDescriptions.find(
      (filteredWeight) => filteredWeight === weight
    );
  }

  private closeActionMenu(): void {
    if (this.isDropzoneMenuOpen && !this.isOverActionMenu) {
      this.isDropzoneMenuOpen = false;
      this.activeMenuTrigger.closeMenu();
      this.activeMenuTrigger = null;
      this.dropzoneActionItems = DEFAULT_CODING_GRID_ACTION_ITEMS;
    }
  }

  private unsetDraggingState(): void {
    this.isOverActionMenu = false;
    this.dropzoneEnterCount = 0;
    this.closeActionMenu();
  }

  private setActiveCodingGridRows() {
    let activeGridRows = this.tables;
    switch (this.selectedTab) {
      case TargetType.rows:
        activeGridRows = this.rows;
        break;
      case TargetType.columns:
        activeGridRows = this.columns;
        break;
    }
    this.codingGridService.updateActiveGridRows(activeGridRows);
  }

  private loadGridPreferences() {
    Object.keys(this.codingGridPreferences).forEach((targetType) => {
      const codingGridPreference = this.codingGridPreferences[targetType];
      const preferences = this.getCodingGridHideableColumns().map((column) => ({
        value: column,
        label:
          column === 'group'
            ? 'Group name'
            : column.charAt(0).toUpperCase() + column.slice(1),
        show: !codingGridPreference.hiddenColumns.includes(column),
      }));
      this.hideColumnItems[targetType] = cloneDeep(preferences);
    });

    this.isColumnHidden =
      this.codingGridPreferences[this.selectedTab].hiddenColumns.length > 0;
    this.setHideColumnSelectionList();
  }

  private setHideColumnSelectionList() {
    if (this.appCodingGridActionControls?.hideColumnSelectionList?.options) {
      this.appCodingGridActionControls.hideColumnSelectionList.selectedOptions.clear();
      const selectedTabShowCol = this.hideColumnItems[this.selectedTab]
        .filter((option) => option.show)
        .map((option) => option.value);
      this.appCodingGridActionControls.hideColumnSelectionList.options.forEach(
        (option) => {
          if (selectedTabShowCol.includes(option.value)) {
            this.appCodingGridActionControls.hideColumnSelectionList.selectedOptions.select(
              option
            );
          }
        }
      );
    }
  }

  private getCodingGridHideableColumns() {
    return READONLY_CODING_GRID_COLUMNS.filter(
      (column) => !PERSISTENT_CODING_GRID_COLUMNS.includes(column)
    );
  }

  public onTabToggle() {
    this.appCodingGridActionControls.hideColumnSelectionList.deselectAll();
    this.loadGridPreferences();
  }

  private canOpenCombineMenuOptions(): boolean {
    return this.hasDropTargetItem() || this.hasMultipleSelectedNodes();
  }

  private hasDropTargetItem(): boolean {
    return !!this.dropzoneTargetItem;
  }

  private hasMultipleSelectedNodes(): boolean {
    return this.selectedCodebookNodes.length > 1;
  }

  public onOpenMoreMenu() {
    if (this.gridViewContainer?.nativeElement?.offsetWidth) {
      const containerWidth = this.gridViewContainer.nativeElement.offsetWidth;

      if (containerWidth >= EXTRA_LARGE_BREAKPOINT) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.none;
      } else if (
        containerWidth >= LARGE_BREAKPOINT &&
        containerWidth < EXTRA_LARGE_BREAKPOINT
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.xl;
      } else if (
        containerWidth >= MEDIUM_BREAKPOINT &&
        containerWidth < LARGE_BREAKPOINT
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.lg;
      } else if (
        containerWidth >= SMALL_BREAKPOINT &&
        containerWidth < MEDIUM_BREAKPOINT
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.md;
      } else if (
        containerWidth >= EXTRA_SMALL_BREAKPOINT &&
        containerWidth < SMALL_BREAKPOINT
      ) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.sm;
      } else if (containerWidth < EXTRA_SMALL_BREAKPOINT) {
        this.moreMenuVisibility = MoreMenuVisibilityOptions.xs;
      }
    }
  }
}
