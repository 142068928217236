import { DocumentAudienceGroupItem } from '@telmar-global/tup-audience-groups';
import { Survey } from './survey.model';
import { SelectMenuOption } from './application.model';

export const STATUS_POLLING_TIME = 2000;
export const DEFAULT_MAX_NMU_OF_CLUSTERS = 10;
export const DEFAULT_NMU_OF_FACTORS = 0;
export const CLUSTER_VERSION = `v2`;

export enum ChartViewMode {
  recommended,
  default,
}

export const CLUSTER_CHART_VIEW_MODES: SelectMenuOption<ChartViewMode>[] = [
  {
    title: 'Solutions',
    value: ChartViewMode.default,
    tooltip: 'View cluster solutions',
  },
  {
    title: 'Rec. solution',
    value: ChartViewMode.recommended,
    tooltip: 'View recommended solution cluster quality chart',
  },
];

export interface ClusterFeatureItem extends DocumentAudienceGroupItem {
  selected: boolean;
}

export interface ClusterSurveySelectionItem extends Survey {
  selected: boolean;
}

export interface ClusterData {
  rows: ClusterFeatureItem[];
  survey: Survey;
}

export enum NumberOfClusters {
  C_2 = 2,
  C_3 = 3,
  C_4 = 4,
  C_5 = 5,
  C_6 = 6,
  C_7 = 7,
  C_8 = 8,
  C_9 = 9,
  C_10 = 10,
}

export interface ClustersSolutionType {
  key: NumberOfClusters;
  value: string;
}

export const CLUSTERS_SOLUTION_TYPE_LIST: ClustersSolutionType[] = [
  {
    key: NumberOfClusters.C_2,
    value: '2 clusters',
  },
  {
    key: NumberOfClusters.C_3,
    value: '3 clusters',
  },
  {
    key: NumberOfClusters.C_4,
    value: '4 clusters',
  },
  {
    key: NumberOfClusters.C_5,
    value: '5 clusters',
  },
  {
    key: NumberOfClusters.C_6,
    value: '6 clusters',
  },
  {
    key: NumberOfClusters.C_7,
    value: '7 clusters',
  },
  {
    key: NumberOfClusters.C_8,
    value: '8 clusters',
  },
  {
    key: NumberOfClusters.C_9,
    value: '9 clusters',
  },
  {
    key: NumberOfClusters.C_10,
    value: '10 clusters',
  },
];

export enum CleaningMethod {
  cleanDrop = 'clean_drop',
  cleanImputation = 'clean_imputation',
}

export type CleaningMethodType =
  | CleaningMethod.cleanDrop
  | CleaningMethod.cleanImputation;

export interface CleaningMethodOptions {
  'clean-drop-respondents-valid-values-threshold': number;
  'clean-drop-features-valid-values-threshold'?: number;
}

export interface CleaningMethodOptionsItem {
  cleanDropRespondentsValidValuesThreshold: number;
  cleanDropFeaturesValidValuesThreshold?: number;
}

export const DEFAULT_CLEAN_DROP_RESPONDENTS_VALID_VALUES_THRESHOLD = 100;

export interface StartClusteringRequestBody {
  'respondent-filter': string;
  'survey-code': string;
  'survey-version': string;
  features: string[];
  'cleaning-method': CleaningMethod;
  'num-of-factors': number;
  'max-num-of-clusters': number;
  'data-treatment'?: '5 as highest' | '1 as highest';
}

export interface UpdateClusteringRequestBody {
  'research-session-id': string;
  'cleaning-method': CleaningMethod;
  'num-of-factors': number;
  'max-num-of-clusters': number;
  'cleaning-method-options'?: CleaningMethodOptions;
}

export interface StartClusteringResponseBody {
  success: boolean;
  message: string;
  'research-session-id': string;
}

export interface UpdateClusteringResponseBody {
  success: boolean;
  message: string;
  'research-session-id': string;
}

export interface GetClustersRequestBody {
  'research-session-id': string;
  'cleaning-method': string;
  'num-of-factors': number;
  'max-num-of-clusters': number;
}

export interface SaveClusteringRequestBody {
  'research-session-id': string;
  'cleaning-method': CleaningMethod;
  'num-of-factors': number;
  'num-of-clusters': number;
}

export interface SaveClusteringResponseBody {
  success: boolean;
  message: string;
  'cluster-keys': string[];
}

export enum ClusterResultType {
  clean_drop = 'clean_drop',
}

export type ClusterResults = Partial<Record<ClusterResultType, any>>;

export interface ClusterRecommendedSolution {
  overall: number;
  'metric-specific'?: ClusterQuality;
}

export interface ClusterQuality {
  average: number;
  determination: number;
  'ch-index': number;
  'silhouette-score': number;
  inertia: number;
}

export enum EligibleClusterQualityMetrics {
  average = 'average',
  determination = 'determination',
  'ch-index' = 'ch-index',
  'silhouette-score' = 'silhouette-score',
  'inertia' = 'inertia',
}

export interface ClusterVariable {
  type: string;
  rank: number;
  determination: number;
}

export interface ClusterSolutionClusterData {
  average: number[];
  'standard-deviation': number[];
  index: number[];
  'standardized-score': number[];
}

export interface ClusterSolutionCluster {
  center: number[];
  audience: number;
  respondents: number;
  data: ClusterSolutionClusterData;
}

export interface ClusterSolution {
  'num-of-clusters': number;
  quality: ClusterQuality;
  clusters: ClusterSolutionCluster[];
  variables: ClusterVariable[];
}

export interface ClusterTotalVariables {
  'max-determinations': number[];
  type: string[];
}

export interface ClusterTotal {
  audience: number;
  respondents: number;
  data: ClusterSolutionClusterData;
  variables: ClusterTotalVariables;
}

export interface GetClustersResponseBody {
  success: boolean;
  message: string;
  solutions: ClusterSolution[];
  totals: ClusterTotal;
  'recommended-solution': ClusterRecommendedSolution;
}

export type ClusterViewType = 'chart' | 'table';
export type ClusterChartType = 'line' | 'polar';
export type ClusterDataItemType =
  | 'average'
  | 'index'
  | 'standardized-score'
  | 'standard-deviation';

export interface ClusterTableRowData {
  rowNumber: number | '';
  variable: string;
  type: string;
  rank: number | '-';
  determination: number | '-';
  total: number;
  clusters: ClusterItem[];
  isSticky: boolean;
}

export interface ClusterVariableRowData extends DocumentAudienceGroupItem {
  maxDetermination: number | string;
  selected: boolean;
}

export enum HighlightSegment {
  min = 'min',
  mid = 'mid',
  max = 'max',
}

export interface ClusterItem {
  value: number;
  segment?: HighlightSegment;
}

export interface ClusterSettings {
  title: string;
  dataItem: ClusterDataItemType;
  chartType: ClusterChartType;
  showDataLabel: boolean;
  showAxisLabel: boolean;
  showLegend: boolean;
  showHighlighting: boolean;
  highlightType: HighlightType;
}

export enum HighlightType {
  table,
  variable,
  cluster,
}

export interface HighlightColor {
  color: string;
  label: string;
  background: string;
}

export const HIGHLIGHT_COLORS: HighlightColor[] = [
  {
    color: 'green',
    label: 'Max',
    background: '#9cd296',
  },
  {
    color: 'yellow',
    label: 'Mid',
    background: '#ffeb98',
  },
  {
    color: 'orange',
    label: 'Min',
    background: '#ffb985',
  },
];

export const RECOMMENDED_SOLUTION_BAR_CHART_COLOR = '#00A36C';
