<tup-mat-content-wrapper gap="0">
  <tup-mat-content-wrapper
    position="middle"
    gap="2"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap=".5rem"
    >
      <mat-card
        class="total-reports header-button"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <span>
          {{ documents?.dataSource.length || 0 }}
        </span>
        {{
          documents?.dataSource.length === 1 ? "quick report" : "quick reports"
        }}
      </mat-card>

      <tup-document-filter
        data-tracking="crosstab-search"
        formFieldAppearance="outline"
        formFieldLabel="Search quick reports"
        [filterFor]="documents"
        fxFlex="1 1 auto"
      ></tup-document-filter>

      <button
        data-tracking="import-crosstab-btn"
        class="import-button header-button"
        mat-flat-button
        tupMatButton
        appearance="small"
        color="primary"
        (click)="fileInput.click($event)"
      >
        <mat-icon>file_upload</mat-icon>
        Import local file
      </button>

      <button
        data-tracking="create-quick-report-btn"
        class="create-button header-button"
        mat-flat-button
        tupMatButton
        appearance="small"
        color="primary"
        (click)="createQuickReport()"
      >
        <mat-icon class="bar-chart-icon tiny-icon">bar_chart</mat-icon>
        Create quick report
      </button>
      <input
        type="file"
        accept=".json"
        hidden
        #fileInput
        onclick="this.value = null"
        (change)="import($event)"
      />
    </div>
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper
    position="last"
    gap="2"
    tupDocumentEventEmitter
    tupDocumentEventHandler
    customDocumentEventHandler
    documentType="quick report"
  >
    <mat-divider class="divider-bottom"></mat-divider>
    <tup-document-storage
      [options]="tableOptions"
      class="document-storage-table"
      #documents
    ></tup-document-storage>
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>

<div
  class="spinner-container-overlay"
  [ngClass]="{
    'spinner-container-overlay--white': isPageLoading
  }"
  *ngIf="isLoading || isPageLoading"
>
  <div class="center-spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
