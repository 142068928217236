import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectMenuOption } from 'src/app/models/application.model';
import {
  BASIC_CHART_TYPES,
  CHART_COLORS,
  ChartColor,
  ChartSettings,
  ChartSettingsMode,
  ChartTargetMode,
  COLUMN_SORT_ORDERS,
  ColumnSortOrder,
  DataViewMode,
  GraphSelectionValue,
  IGNORED_SINGLE_TARGET_GRAPH_TYPES,
  INVALID_NUMBER_CHARS,
  MULTIPLE_SURVEY_CHART_TYPES,
  NO_SECONDARY_CHART_TYPES,
  SECONDARY_CHART_TYPES,
  SelectMenuOptionChart,
  SUPPORTED_CHART_STYLE_SETTINGS,
  unsuitableChartMixed,
} from 'src/app/models/charts.model';
import {
  DefaultChartStyles,
  DisplayType,
  Target,
} from 'src/app/models/document.model';
import { TITLE_MODES } from 'src/app/models/crosstab.model';
import { ColorPickerService } from 'src/app/services/color-picker.service';
import { cloneDeep } from 'lodash';
import { TargetTitlePipe } from '../../pipes';
import {
  DataItem,
  DataItemId,
  DataItemType,
  HAS_DATA_FLAGS_DATA_ITEM_IDS,
} from '../../models/data-item.model';
import { DataItemsService } from '../../services/data-items.service';
import { first } from 'rxjs/operators';
import { CrossTabTableDataCellMetaData } from 'src/app/models';
import { ChartSettingsService } from '../../services/chart-settings.service';
import { TitleLevelsService } from '../../services/title-levels.service';
import { TitleLevelsDialogResult } from '../title-levels-dialog/title-levels-dialog.component';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';
import { ChartStyleService } from '../../services/chart-style.service';
import { SaveChartStyleDialogResult } from '../save-chart-style-dialog/save-chart-style-dialog.component';
import { DocumentService } from '../../services/document.service';

export interface ChartSettingsDialogDataModel {
  targetMode: ChartTargetMode;
  chartSettingsMode: ChartSettingsMode;
  config: ChartSettings;
  targetColors: Record<string, string>;
  targets: Target[];
  chartData?: any;
  groupName?: string;
  chartTitle?: string;
  isReadonly?: boolean;
  defaultChartStyles?: DefaultChartStyles;
}

@Component({
  templateUrl: './chart-settings-dialog.component.html',
  styleUrls: ['./chart-settings-dialog.component.scss'],
})
export class ChartSettingsDialogComponent implements OnInit, OnDestroy {
  public readonly placeholderColor = '#f65354';
  public readonly maxAxisLabelAngle = 360;
  public readonly maxDecimalPlaces = 9;
  public chartTypes: SelectMenuOptionChart<GraphSelectionValue>[] =
    BASIC_CHART_TYPES;
  public dataViewModeType: typeof DataViewMode = DataViewMode;
  public chartDataItems: SelectMenuOption<DataItemId>[] = [];
  public shouldShowFlagRowResp = true;
  public readonly secondaryChartTypes: SelectMenuOptionChart<GraphSelectionValue>[] =
    SECONDARY_CHART_TYPES;
  public readonly columnSortOrders: ColumnSortOrder[] = COLUMN_SORT_ORDERS;
  public readonly chartTargetModeType: typeof ChartTargetMode = ChartTargetMode;
  public readonly chartSettingsModeType: typeof ChartSettingsMode =
    ChartSettingsMode;

  public chartTargetMode: ChartTargetMode;
  public chartSettingsMode: ChartSettingsMode;
  public chartSettings: ChartSettings;
  public targetColors: Record<string, string>;
  public targets: Target[];
  public groupName: string;
  public chartTitle: string;
  public sortColumns: any[];
  public chartData: any;
  public isReadonly = true;

  public isSecondaryChartTypeDisabled = false;
  public isSuitableChartForMixing: boolean;
  public chartColors: ChartColor[];
  private isGlobalChartSettings: boolean;

  public titleModes = TITLE_MODES;

  private defaultChartStyles: DefaultChartStyles;
  public hasDefaultChartStyle: boolean;

  constructor(
    public dialogRef: MatDialogRef<ChartSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChartSettingsDialogDataModel,
    private colorPicker: ColorPickerService,
    private targetTitlePipe: TargetTitlePipe,
    private titleLevelsService: TitleLevelsService,
    private dataItemsService: DataItemsService,
    private chartSettingsService: ChartSettingsService,
    private chartStyleService: ChartStyleService,
    private documentService: DocumentService
  ) {
    this.chartTargetMode = data.config.targetMode;
    this.chartSettingsMode = data.chartSettingsMode;
    this.chartSettings = cloneDeep(data.config);
    this.isGlobalChartSettings =
      this.chartSettingsService.isGlobalChartSettings(this.chartSettings);
    this.targetColors = data.targetColors;
    this.targets = data.targets;
    this.groupName = data.groupName;
    this.chartTitle = this.chartSettings.chartTitle || data.chartTitle;
    this.chartData = data.chartData;
    this.isReadonly = data.isReadonly;
    this.updateChartTypesByTargetMode(this.chartTargetMode);
    this.updateSortColumns(this.chartTargetMode);

    this.defaultChartStyles =
      data.defaultChartStyles || this.chartStyleService.getDefaultChartStyles();

    this.hasDefaultChartStyle =
      this.chartTargetMode in this.defaultChartStyles &&
      this.defaultChartStyles[this.chartTargetMode] !== null;
  }

  ngOnInit(): void {
    this.formatChartColors(this.targetColors);
    this.isSecondaryChartTypeDisabled =
      this.shouldDisableSecondaryChartType(
        this.chartSettings.primaryChartType
      ) || this.chartSettings.secondaryDataItem === DataItemType.none;
    this.isSuitableChartForMixing = !unsuitableChartMixed.includes(
      this.chartSettings.primaryChartType
    );

    this.dataItemsService.chartDataItems$
      .pipe(first())
      .subscribe((dataItems: DataItem[]) => {
        this.formatChartDataItem(dataItems);

        this.shouldShowFlagRowResp =
          HAS_DATA_FLAGS_DATA_ITEM_IDS.filter((dataItemId) =>
            dataItems.find((dataItem: DataItem) => dataItem.id === dataItemId)
          ).length > 0;
      });
  }

  ngOnDestroy(): void {}

  public onPrimaryChartTypeChange(): void {
    if (
      this.shouldDisableSecondaryChartType(
        this.chartSettings.primaryChartType
      ) ||
      this.chartSettings.secondaryDataItem === DataItemType.none
    ) {
      this.isSecondaryChartTypeDisabled = true;
      this.chartSettings.secondaryChartType = 'None';
    }

    this.unsetSecondaryDataItemAndChartType();

    // exception: set a default data item for secondary data item if the primary chart type is scatter
    if (
      this.chartSettings.primaryChartType === 'tupScatter' &&
      this.chartSettings.secondaryDataItem === DataItemType.none
    ) {
      const secondaryDataItemForScatterChart =
        this.findSecondaryDataItemForScatterChart();
      this.onSecondaryDataItemChange(secondaryDataItemForScatterChart);
      this.chartSettings.secondaryDataItem = secondaryDataItemForScatterChart;
    }

    this.isSuitableChartForMixing = !unsuitableChartMixed.includes(
      this.chartSettings.primaryChartType
    );
    if (
      !this.isSuitableChartForMixing &&
      this.chartSettings.primaryDataItem !==
        this.chartSettings.secondaryDataItem
    ) {
      this.setExtraTableSettingsSelected(
        this.chartSettings.secondaryDataItem,
        false
      );
      this.chartSettings.secondaryDataItem = DataItemType.none;
    }
  }

  public onPrimaryDataItemChange(dataItem: DataItemId): void {
    if (
      this.chartSettings.primaryDataItem !==
      this.chartSettings.secondaryDataItem
    ) {
      this.setExtraTableSettingsSelected(
        this.chartSettings.primaryDataItem,
        false
      );
    }
    this.setExtraTableSettingsSelected(dataItem, true);
  }

  public onSecondaryDataItemChange(dataItem: DataItemId): void {
    this.isSecondaryChartTypeDisabled =
      dataItem === DataItemType.none ||
      this.chartSettings.primaryChartType === 'tupScatter';
    if (this.isSecondaryChartTypeDisabled) {
      this.chartSettings.secondaryChartType = 'None';
    }
    if (
      this.chartSettings.primaryDataItem !==
      this.chartSettings.secondaryDataItem
    ) {
      this.setExtraTableSettingsSelected(
        this.chartSettings.secondaryDataItem,
        false
      );
    }
    this.setExtraTableSettingsSelected(dataItem, true);
  }

  public onNumberOfChartInAxisLabelChange(): void {
    if (
      this.isInvalidNoneNegativeNumber(
        this.chartSettings.numberOfChartInAxisLabel
      )
    ) {
      this.chartSettings.numberOfChartInAxisLabel = 0;
    }
  }

  public onTopRowsCountChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.topRowsCount)) {
      this.chartSettings.topRowsCount = 0;
    }

    if (
      !!this.chartSettings.maxRowsCount &&
      this.chartSettings.topRowsCount > this.chartSettings.maxRowsCount
    ) {
      this.chartSettings.topRowsCount = this.chartSettings.maxRowsCount;
    }
  }

  public onFlagRowRespsChange(): void {
    if (
      this.isInvalidNoneNegativeNumber(this.chartSettings.flagRowRespsValue)
    ) {
      this.chartSettings.flagRowRespsValue = 0;
    }
  }

  public onDecimalPlacesChange(): void {
    if (this.isInvalidNoneNegativeNumber(this.chartSettings.decimalPlaces)) {
      this.chartSettings.decimalPlaces = 0;
    }

    if (this.chartSettings.decimalPlaces > this.maxDecimalPlaces) {
      this.chartSettings.decimalPlaces = this.maxDecimalPlaces;
    }
  }

  public onKeydown(e: KeyboardEvent): void {
    if (INVALID_NUMBER_CHARS.includes(e.key)) {
      e.preventDefault();
    }
  }

  public colorPickerDialog(index: number): void {
    this.colorPicker
      .chartColor({
        target: this.chartColors[index].title,
        colors: undefined,
      })
      .afterClosed()
      .subscribe((color: string) => {
        if (color) {
          this.changeColor(
            index,
            this.chartColors[index].colors.length - 1,
            color.toUpperCase()
          );
        }
      });
  }

  public changeColor(index: number, colorIndex: number, color: string): void {
    const prevSelectedColorIndex = this.chartColors[index].colors.findIndex(
      (colorItem) => colorItem.isSelected
    );
    this.chartColors[index].colors[prevSelectedColorIndex].isSelected = false;
    this.chartColors[index].colors[colorIndex].color = color;
    this.chartColors[index].colors[colorIndex].isSelected = true;
  }

  public onButtonClick(): void {
    this.normaliseSeriesColors();
    this.chartSettings.chartTitle = this.chartTitle;
    this.dialogRef.close(this.chartSettings);
  }

  public onSaveStyleClick(): void {
    const chartSettings = this.chartSettings;
    this.chartStyleService
      .openSaveDialog({ chartSettings })
      .afterClosed()
      .pipe(isNotNullOrUndefined())
      .subscribe((dialogResult: SaveChartStyleDialogResult) => {
        if (dialogResult) {
          this.documentService.saveChartStyleDocumentObject(
            dialogResult.name,
            dialogResult.description,
            dialogResult.container.name,
            {
              default: dialogResult.defaultChartStyle,
              chartStyleSettings: dialogResult.chartStyleSettings,
            }
          );

          if (dialogResult.defaultChartStyle) {
            const newDefaultChartStyles = cloneDeep(this.defaultChartStyles);
            newDefaultChartStyles[this.chartTargetMode] =
              dialogResult.chartStyleSettings;
            this.chartStyleService.updateDefaultChartStyles(
              newDefaultChartStyles
            );
          }
        }
      });
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  private formatChartDataItem(dataItems: DataItem[]): void {
    const hasVolumetricData =
      this.chartData?.cellMetadataSets.filter(
        (cellMetadata: CrossTabTableDataCellMetaData) =>
          cellMetadata.isVolumetricCoding
      ).length > 0;
    this.chartDataItems = dataItems.map((dataItem: DataItem) => ({
      title:
        hasVolumetricData && dataItem.id === DataItemType.audience
          ? `${dataItem.volumetricDisplayName}/${dataItem.displayName}`
          : dataItem.displayName,
      value: dataItem.id,
    }));
  }

  private updateChartTypesByTargetMode(targetMode: ChartTargetMode): void {
    if (
      targetMode === ChartTargetMode.insightsGroup ||
      targetMode === ChartTargetMode.surveysGroup
    ) {
      this.chartTypes = MULTIPLE_SURVEY_CHART_TYPES;
    }

    if (targetMode === ChartTargetMode.single) {
      this.chartTypes = this.chartTypes.filter(
        (chartType) =>
          typeof chartType.graphType !== 'string' ||
          !IGNORED_SINGLE_TARGET_GRAPH_TYPES.includes(chartType.graphType)
      );
    }
  }

  private updateSortColumns(targetMode: ChartTargetMode): void {
    this.sortColumns = this.targets.map((target: Target) => ({
      id: target.id,
      title: this.targetTitlePipe.transform(target, target.activeTitleMode),
    }));

    if (this.chartSettingsMode !== ChartSettingsMode.global) {
      switch (targetMode) {
        case ChartTargetMode.surveysGroup:
          const surveyCode = this.chartData.surveyCodes[0];
          this.sortColumns = this.chartData.insightIds.map(
            (id: string, index: number) => ({
              id: `${id}#${surveyCode}`,
              title: this.chartData.targetTitles[index],
            })
          );
          break;
        case ChartTargetMode.insightsGroup:
          const targetId = this.chartData.targetIds[0];
          this.sortColumns = this.chartData.surveyCodes.map(
            // tslint:disable-next-line:no-shadowed-variable
            (surveyCode: string, index: number) => ({
              id: `${targetId}#${surveyCode}`,
              title: this.chartData.targetTitles[index],
            })
          );
          break;
        default:
          break;
      }
    }
  }

  private formatChartColors(columnColors: Record<string, string>): void {
    const colors: string[] = CHART_COLORS.slice(0, 6);
    const seriesColors: Record<string, string> = this.chartSettings.seriesColor;
    this.chartColors = this.targets.map((target: Target) => {
      const targetId = target.id;
      const targetColor =
        targetId in seriesColors
          ? seriesColors[targetId]
          : this.chartSettings.dataViewMode === DataViewMode.default
          ? columnColors[targetId]
          : seriesColors.dynamic || columnColors.dynamic;
      const isDefaultColor = colors.includes(targetColor);
      const isDynamicData =
        this.chartSettings.dataViewMode === DataViewMode.dynamic &&
        this.isGlobalChartSettings;
      return {
        title: this.targetTitlePipe.transform(target, target.activeTitleMode),
        colors: [
          ...colors.map((color: string) => ({
            isSelected: targetColor === color,
            color,
          })),
          {
            isSelected: !(
              isDefaultColor && targetColor !== this.placeholderColor
            ),
            color: isDefaultColor ? this.placeholderColor : targetColor,
          },
        ],
        targetId: isDynamicData ? 'dynamic' : targetId,
      };
    });
  }

  private normaliseSeriesColors(): void {
    if (this.chartSettingsMode === ChartSettingsMode.single) {
      this.chartSettings.seriesColor = {
        [this.targets[0].id]: this.chartColors[0].colors.find(
          (colorItem) => colorItem.isSelected
        ).color,
      };
    } else {
      this.chartSettings.seriesColor = this.chartColors.reduce(
        (prev, chartColor: ChartColor) => ({
          ...prev,
          [chartColor.targetId]: chartColor.colors.find(
            (colorItem) => colorItem.isSelected
          ).color,
        }),
        {}
      );
    }
  }

  private shouldDisableSecondaryChartType(
    primaryChartType: GraphSelectionValue
  ): boolean {
    return NO_SECONDARY_CHART_TYPES.includes(primaryChartType);
  }

  private isInvalidNoneNegativeNumber(value: any): boolean {
    return value < 0 || value === null || isNaN(value);
  }

  private setExtraTableSettingsSelected(
    dataItem: DataItemId,
    selected: boolean
  ): void {
    if (dataItem === DataItemType.none) {
      return;
    }
    if (selected) {
      if (this.chartSettings.extraTableSettings.indexOf(dataItem) === -1) {
        this.chartSettings.extraTableSettings.push(dataItem);
      }
    } else {
      this.chartSettings.extraTableSettings =
        this.chartSettings.extraTableSettings.filter(
          (item: DataItemId) => item !== dataItem
        );
    }
  }

  private findSecondaryDataItemForScatterChart() {
    let secondaryDataItemForScatterChart = this.chartDataItems[0].value;
    for (const dataItem of this.chartDataItems) {
      if (dataItem.value !== this.chartSettings.primaryDataItem) {
        secondaryDataItemForScatterChart = dataItem.value;
        break;
      }
    }
    return secondaryDataItemForScatterChart;
  }

  private unsetSecondaryDataItemAndChartType(): void {
    if (
      this.chartSettings.secondaryDataItem !==
      this.chartSettings.primaryDataItem
    ) {
      this.setExtraTableSettingsSelected(
        this.chartSettings.secondaryDataItem,
        false
      );
    }
    this.chartSettings.secondaryDataItem = DataItemType.none;
    this.chartSettings.secondaryChartType = 'None';
    this.isSecondaryChartTypeDisabled = true;
  }

  public selectTitleMode(event: any): void {
    if (event === DisplayType.levels) {
      this.titleLevelsService
        .openDialog()
        .subscribe((dialogResult: TitleLevelsDialogResult) => {
          this.chartSettings.titleLevels = dialogResult.titleLevels;
        });
    } else {
      this.chartSettings.titleLevels = [];
    }
  }

  public onApplyDefaultClick() {
    this.dialogRef.close(this.getChartSettingsWithDefaultChartStyle());
  }

  private getChartSettingsWithDefaultChartStyle() {
    const chartSettings = cloneDeep(this.chartSettings);
    const chartStyleSettings = this.defaultChartStyles[this.chartTargetMode];

    SUPPORTED_CHART_STYLE_SETTINGS.forEach((setting) => {
      if (setting === 'topRowsCount') {
        const topRowsCount = chartSettings.maxRowsCount
          ? chartStyleSettings[setting] > chartSettings.maxRowsCount
            ? chartSettings.maxRowsCount
            : chartStyleSettings[setting]
          : chartStyleSettings[setting];
        chartSettings[setting] = topRowsCount;
      } else {
        chartSettings[setting] = chartStyleSettings[setting];
      }
    });
    return chartSettings;
  }
}
