import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TargetTitlePipe } from 'src/app/pipes';
import {
  CodingData,
  CrossTabTableData,
  DisplayType,
  NEW_TARGET_CODING,
  NEW_TARGET_TITLE,
  Survey,
  Target,
  TargetType,
  UserTargetCodingResponse,
} from 'src/app/models';
import { DocumentService, TargetService } from 'src/app/services';
import { CodingDataMapService } from 'src/app/services/coding-data-map.service';

@Component({
  templateUrl: './new-code-builder-dialog.component.html',
  styleUrls: ['./new-code-builder-dialog.component.scss'],
})
export class NewCodeBuilderDialogComponent implements OnInit {
  public selectedSurvey: Survey;
  public targetType: TargetType;
  private documentService: DocumentService;
  public targetService: TargetService;
  public codingDataMapService: CodingDataMapService;
  public resultTarget: Target = null;

  public dialogTitleMode: DisplayType;
  public titleDisplayType = DisplayType;

  public title: string = NEW_TARGET_TITLE;
  public coding: string = NEW_TARGET_CODING;

  // TODO: Figure out what this is for
  public codingError = '';
  public targetResult: CodingData = {
    resps: null,
    population: null,
  };
  public gettingCodingResult = false;
  public isResultDirty = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewCodeBuilderDialogComponent>,
    private targetTitlePipe: TargetTitlePipe
  ) {
    this.targetType = this.data.gridType;
    this.selectedSurvey = this.data.survey;
    this.documentService = this.data.documentService;
    this.targetService = this.data.targetService;
    this.codingDataMapService = this.data.codingDataMapService;
    this.resultTarget = this.targetService.createTarget({
      title: this.title,
      coding: this.coding,
    });
  }

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close(null);
  }

  public onButtonClick(buttonOption: boolean): void {
    this.updateTitleAndCoding();

    if (!buttonOption) this.dialogRef.close(null);

    this.targetService
      .validateCodingStatement(
        this.targetTitlePipe.codeBuilderTransform(
          this.resultTarget,
          DisplayType.coding,
          this.resultTarget.titleLevels
        )
      )
      .pipe()
      .subscribe((result: UserTargetCodingResponse) => {
        if (
          result.convertedTargets[0] &&
          !result.hasOwnProperty('Warnings') &&
          !result.hasOwnProperty('Errors')
        ) {
          this.dialogRef.close({
            ...this.resultTarget,
            targets: [this.resultTarget],
          });
        } else {
          this.setCodingError(result.Errors, result.Warnings);
        }
      });
  }

  public onCodingEdited(): void {
    this.isResultDirty = true;
  }

  public onSyncAudienceSize(): void {
    this.updateTitleAndCoding();

    this.gettingCodingResult = true;
    this.targetService
      .validateCodingStatement(
        this.targetTitlePipe.codeBuilderTransform(
          this.resultTarget,
          DisplayType.coding,
          this.resultTarget.titleLevels
        )
      )
      .pipe()
      .subscribe((result: UserTargetCodingResponse) => {
        if (
          result.convertedTargets[0] &&
          !result.hasOwnProperty('Warnings') &&
          !result.hasOwnProperty('Errors')
        ) {
          this.codingError = '';
          this.fetchCodingResults();
        } else {
          this.setCodingError(result.Errors, result.Warnings);

          this.targetResult = {
            resps: null,
            population: null,
          } as CodingData;
          this.isResultDirty = false;
          this.gettingCodingResult = false;
        }
      });
  }

  private updateTitleAndCoding(): void {
    this.resultTarget.title = this.title;
    this.resultTarget.coding = this.coding.replace(/[\n\t]+/g, ' ');
  }

  private fetchCodingResults(): void {
    this.documentService.fetchCodingResult(this.resultTarget).subscribe(
      (crossTabData: CrossTabTableData[]) => {
        this.targetResult = this.codingDataMapService.formatCodingData(
          crossTabData[0].data.filter(
            (data) =>
              data.type === 'target' &&
              this.selectedSurvey.code.toLowerCase() ===
                data.surveyCode.toLowerCase()
          )[0]
        );
        this.isResultDirty = false;
        this.gettingCodingResult = false;
      },
      (error) => {
        this.targetResult = {
          resps: null,
          population: null,
        } as CodingData;
        this.isResultDirty = false;
        this.gettingCodingResult = false;
      }
    );
  }

  private setCodingError(errors: any, warnings: any): void {
    this.codingError = warnings
      ? (Object.values(warnings)[0] as string)
      : errors && (Object.values(errors)[0] as string);
    this.codingError = this.codingError.replace(/\/r\/n/g, 'Warning: ');
  }
}
