import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import {
  TupDocument,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GraphViewComponent } from 'src/app/components';
import {
  ChartTargetMode,
  DocumentViewType,
  ExportFileType,
  ExportParams,
  Survey,
  SurveyTimeDocument,
  TARGET_MODES,
  TARGET_MODES_TRENDING_SURVEY,
} from 'src/app/models';
import {
  ChartsService,
  DocumentService,
  RequestLoadingService,
  TargetLoading,
} from 'src/app/services';
import { isNotNullOrUndefined } from 'src/app/utils/pipeable-operators';

@Component({
  selector: 'cross-tab-graph',
  templateUrl: './cross-tab-graph.component.html',
  styleUrls: ['./cross-tab-graph.component.scss'],
})
export class CrossTabGraphComponent implements OnInit, OnDestroy {
  @ViewChild(GraphViewComponent, { static: false })
  graphViewComponent: GraphViewComponent;

  private unsubscribe: Subject<void> = new Subject<void>();
  public readonly targetmodestrendingsurvey = TARGET_MODES_TRENDING_SURVEY;
  public readonly targetmodes = TARGET_MODES;
  public targetMode: ChartTargetMode = ChartTargetMode.single;
  public combinedMode = ChartTargetMode.combined;
  public isFetchingCrosstabData = false;
  public isExporting = false;
  public surveys: Survey[];
  public isReadonly = true;
  public selectedSurvey: Survey;
  public canExportCharts = true;
  public readonly documentViewType: typeof DocumentViewType = DocumentViewType;
  public isQuickReport = true;

  constructor(
    private requestLoadingService: RequestLoadingService,
    private chartsService: ChartsService,
    private router: Router,
    private documentService: DocumentService
  ) {
    this.isReadonly =
      this.router.getCurrentNavigation().extras?.state?.isReadonly;
    this.isQuickReport =
      this.router.getCurrentNavigation().extras?.state?.isQuickReport;
  }

  ngOnInit() {
    this.listenToLoadingState();
    this.listenToCurrentDoc();
    this.listenToDocumentDataChanges();
    this.listenToActiveSurveyChanges();

    this.chartsService.updateActiveChartTargetMode(this.targetMode);
  }

  ngOnDestroy(): void {
    this.unsubscribeLoadingSubscription();
  }

  public exportToPptx(exportParams?: ExportParams): void {
    this.graphViewComponent.exportAs(
      ExportFileType.pptx,
      exportParams?.docName
    );
  }

  public exportToSlides(exportParams?: ExportParams): void {
    this.graphViewComponent.exportAs(
      ExportFileType.googleSlides,
      exportParams?.docName
    );
  }

  public exportToXlsx(exportParams?: ExportParams): void {
    this.graphViewComponent.exportAs(
      ExportFileType.xlsx,
      exportParams?.docName
    );
  }

  public exportToSheets(exportParams?: ExportParams): void {
    this.graphViewComponent.exportAs(
      ExportFileType.googleSheets,
      exportParams?.docName
    );
  }

  public onChartExportEnabled(enabled: boolean): void {
    this.canExportCharts = enabled;
  }

  public onChartsViewTabChange(tab: MatTabChangeEvent): void {
    this.targetMode = this.targetmodestrendingsurvey[tab.index].value;
    this.chartsService.updateActiveChartTargetMode(this.targetMode);
  }

  public convertToNormalReport(): void {
    this.graphViewComponent.saveAsNormalReport();
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'exporting' ||
            targetLoading.target === 'document' ||
            targetLoading.target === 'crosstab'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isFetchingCrosstabData = targetLoading.isLoading;
      });
  }

  private listenToCurrentDoc(): void {
    this.documentService.currentDoc
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.isQuickReport =
          doc.metadata.type.id ===
          TupDocumentTypeId.SURVEYTIME_CAMPAIGN_TEMPLATE;
      });
  }

  private unsubscribeLoadingSubscription(): void {
    this.isFetchingCrosstabData = false;
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.readonlyDoc$
      .pipe(isNotNullOrUndefined(), takeUntil(this.unsubscribe))
      .subscribe((readonly: boolean) => {
        this.isReadonly = readonly;
      });
  }

  private listenToActiveSurveyChanges(): void {
    this.documentService.selectedSurvey$
      .pipe(takeUntil(this.unsubscribe), isNotNullOrUndefined())
      .subscribe((survey: Survey) => {
        this.selectedSurvey = survey;
        this.surveys = this.documentService.getVisibleSurveys();
      });
  }
}
