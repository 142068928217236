<div class="table-container">
  <div class="table-wrapper">
    <div
      *ngIf="dataSource.data.length > 50"
      class="spinner-container-overlay"
    >
      <div class="center-spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
    <div
      class="grid-table-header"
      [style.padding-right]="scrollbarWidth + 'px'"
    >
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
        [matSortDisabled]="multiSortThreshold !== -1"
        class="non-standard-table fa-table"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          matColumnDef="{{ column }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!columnMap[column]['sortable']"
            class="table-header-cell mat-header-height"
            [class.title-column]="column.startsWith('title')"
            [class.factor-column]="column.startsWith('factor')"
          >
            <div *ngIf="!columnMap[column]['editable']">
              <span>{{ columnMap[column]["name"] }}</span>
            </div>
            <div
              *ngIf="
                columnMap[column]['editable'] && !columnMap[column]['editing']
              "
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
              (click)="$event.stopPropagation()"
            >
              <span (click)="onHeaderClick(column)">{{
                columnMap[column]["name"]
              }}</span>
            </div>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let column of explainedVarianceDisplayedColumns['individual']"
          matColumnDef="{{ column.id }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-cell is-sticky-row"
            [class.text-align-header-right]="column.align === 'right'"
          >
            <div>
              {{ column.label }}
            </div>
          </th>
        </ng-container>

        <ng-container
          *ngFor="let column of explainedVarianceDisplayedColumns['cumulative']"
          matColumnDef="{{ column.id }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-cell is-sticky-row"
            [class.text-align-header-right]="column.align === 'right'"
          >
            <div>
              {{ column.label }}
            </div>
          </th>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="
            explainedVarianceDisplayedColumnIDs['individual'];
            sticky: true
          "
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="
            explainedVarianceDisplayedColumnIDs['cumulative'];
            sticky: true
          "
        ></tr>
      </table>
      <div
        class="grid-table-header-scroll-padding"
        [style.width]="scrollbarWidth + 'px'"
      ></div>
    </div>
    <cdk-virtual-scroll-viewport
      [tvsItemSize]="33"
      headerEnabled="false"
      [headerHeight]="-60"
      bufferMultiplier="4"
      headerHeight="44"
      class="grid-table-body"
    >
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
        [matSortDisabled]="multiSortThreshold !== -1"
        class="non-standard-table fa-table"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          matColumnDef="{{ column }}"
        >
          <td
            mat-cell
            *matCellDef="let element"
            [class.text-align-left]="columnMap[column]['align'] === 'left'"
            [class.text-align-center]="columnMap[column]['align'] === 'center'"
            [class.text-align-right]="columnMap[column]['align'] === 'right'"
            [ngClass]="
              column.startsWith('factor')
                ? element[column] >=
                  settings.tableHighlightOptions[tableHiglightColors.large]
                    .threshold
                  ? ['large-threshold']
                  : element[column] >=
                      settings.tableHighlightOptions[
                        tableHiglightColors.moderate
                      ].threshold &&
                    element[column] <
                      settings.tableHighlightOptions[tableHiglightColors.large]
                        .threshold
                  ? ['moderate-threshold']
                  : ['small-threshold']
                : ''
            "
            [class.title-column]="column.startsWith('title')"
            [class.factor-column]="column.startsWith('factor')"
            [class.has-factor-divider]="element.hasFactorDivider"
          >
            <span>{{ element[column] }}</span>
          </td>
        </ng-container>

        <tr
          class="row-container"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
