<div
  class="table-container"
  #tableCardContainer
>
  <div class="ae-table-viewport-container">
    <div [style.height.px]="scrollViewportHeight">
      <cdk-virtual-scroll-viewport
        tvsItemSize="98"
        headerEnabled="true"
        bufferMultiplier="10"
        headerHeight="60"
        footerHeight="35"
        footerEnabled="true"
        class="virtual-scroll-viewport"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          class="non-standard-table ae-table"
        >
          <ng-container
            *ngFor="let column of displayedColumns"
            matColumnDef="{{ column }}"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              class="table-header-cell"
            >
              <div
                *ngIf="columnMap[column]['sortable']"
                mat-sort-header
              >
                {{ columnMap[column]["name"] }}
              </div>
              <div *ngIf="!columnMap[column]['sortable']">
                {{ columnMap[column]["name"] }}
              </div>
            </th>

            <td
              mat-cell
              *matCellDef="let element"
              [title]="element[column]"
              [class.text-align-left]="columnMap[column]['align'] === 'left'"
              [class.text-align-center]="
                columnMap[column]['align'] === 'center'
              "
              [class.text-align-right]="columnMap[column]['align'] === 'right'"
            >
              {{ element[column] | truncate: 100 }}
            </td>
            <td
              mat-footer-cell
              *matFooterCellDef
            >
              {{ getTotal()[column] }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"
            [ngStyle]="{ 'background-color': row.backgroundColor }"
          ></tr>

          <tr
            mat-footer-row
            *matFooterRowDef="displayedColumns"
          ></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
