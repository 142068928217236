import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TupAuthService } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentEventEmitterDirective,
  TupDocumentEventHandlerDirective,
  TupDocumentStorageOptions,
  TupDocumentTypeId,
  TupUserContainerService,
  getColumn,
  TupDocumentEvent,
} from '@telmar-global/tup-document-storage';
import { TupUserMessageService } from '@telmar-global/tup-user-message';
import { Subject } from 'rxjs';
import { ImportService } from '../../services/import.service';
import { environment } from 'src/environments/environment';
import { SaveOwnCodesType } from '@telmar-global/tup-audience-groups';
import { DocumentAudienceGroup } from '@telmar-global/tup-audience-groups/lib/models';
import { CustomAudiencesService } from '../../services/custom-audiences.service';
import {
  RequestLoadingService,
  TargetLoading,
} from '../../services/request-loading.service';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomAudiencesEditableCell } from '../../components/custom-audiences-editable-cell/custom-audiences-editable-cell.component';

@Component({
  templateUrl: './manage-custom-audiences-dialog.component.html',
  styleUrls: ['./manage-custom-audiences-dialog.component.scss'],
})
export class ManageCustomAudiencesDialogComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();
  @ViewChild(TupDocumentEventEmitterDirective, { static: true })
  public documentEventEmitter: TupDocumentEventEmitterDirective;

  @ViewChild(TupDocumentEventHandlerDirective, { static: false })
  public documentEventHandler: TupDocumentEventHandlerDirective;

  public readonly options: TupDocumentStorageOptions = {
    types: [TupDocumentTypeId.OWN_CODES_TARGET],
    documentReplacementText: 'Custom audiences',
    columns: [
      {
        columnDef: 'editable.indicator',
        header: '',
        columnType: 'component',
        component: CustomAudiencesEditableCell,
      },
      getColumn(environment, 'actions'),
      getColumn(environment, 'metadata.name.keyword', {
        header: 'Audiences',
        sortable: true,
        tooltip: (document: TupDocument<unknown>) => document.metadata.name,
      }),
      getColumn(environment, 'metadata.by.attributes.name.keyword', {
        sortable: true,
      }),
      getColumn(environment, 'metadata.modified', { sortable: true }),
      {
        columnDef: 'content.survey.provider',
        sortable: false,
        header: 'Survey provider',
        columnType: 'string',
        cell: (document: TupDocument<any>) =>
          document.content.survey?.provider || '',
      },
      {
        columnDef: 'content.survey.code',
        sortable: false,
        header: 'Survey code',
        columnType: 'string',
        tooltip: (document: TupDocument<any>) => document.content.survey?.title,
        cell: (document: TupDocument<any>) =>
          document.content.survey?.code || '',
      },
    ],
    panel: true,
    pageSize: 8,
    _source: ['metadata', 'content'],
    tags: {
      suggested: ['Client', 'Project'],
    },
    actions: [
      'click:edit:edit',
      'click:duplicate:file_copy::true',
      'click:delete:highlight_off',
      'click:export:file_download::true',
      'update_tags',
      'update_container',
      'click:rename:abc',
    ],
  } as TupDocumentStorageOptions;

  public isLoading = false;
  private userEmail: string;

  constructor(
    public dialogRef: MatDialogRef<ManageCustomAudiencesDialogComponent>,
    private importService: ImportService,
    private authService: TupAuthService,
    private userMessageService: TupUserMessageService,
    private userContainerService: TupUserContainerService,
    private customAudiencesService: CustomAudiencesService,
    private requestLoadingService: RequestLoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.getUserEmail();
    this.documentEventEmitter.click.subscribe((event: TupDocumentEvent) => {
      const document = event.document as TupDocument<DocumentAudienceGroup>;
      if (event.data) {
        switch (event.data.action) {
          case 'edit':
            this.edit(document);
            break;
        }
      } else {
        this.edit(document);
      }
    });
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) =>
            targetLoading.target === 'edit-custom-audiences'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public import(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.isLoading = true;
    const file = target.files[0];
    const duration = 5000;
    const currentContainer = this.userContainerService.getContainer();
    this.importService
      .importJSON(
        file,
        this.userEmail,
        TupDocumentTypeId.OWN_CODES_TARGET,
        currentContainer.name
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.documentEventHandler.reload.emit(true);
          this.userMessageService.showSnackBar(
            `Importing document - ${file.name}: Success`,
            'OK',
            duration
          );
        },
        (error) => {
          this.isLoading = false;
          this.userMessageService.showSnackBar(
            `Error importing document - ${file.name}: document isn't valid`,
            'OK',
            duration
          );
        }
      );
  }
  private edit(doc: TupDocument<DocumentAudienceGroup>): void {
    if (!this.customAudiencesService.canEditCustomAudiences(doc)) {
      this.customAudiencesService.showUnableToEditCustomAudiencesWarning();
      return;
    }

    this.customAudiencesService.editCustomAudiences(doc).subscribe(
      () => {
        this.customAudiencesService.notifyOwnCodesAdded(
          SaveOwnCodesType.audience,
          doc
        );
        this.userMessageService.showSnackBar(
          'Custom audiences/media updated successfully',
          'OK',
          10000
        );
        this.documentEventHandler.reload.emit(true);
      },
      (error) => {
        this.userMessageService.showSnackBar(error, 'OK', 10000);
      }
    );
  }

  private getUserEmail() {
    this.userEmail = this.authService.user.attributes.email;
  }
}
