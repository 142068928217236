<div
  class="dialog-content-container"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <tup-mat-title-bar
    title="Global chart settings"
    class="dialog-title"
    cdkDragHandle
  >
    <mat-icon
      title="Close dialog"
      class="close-icon"
      (click)="onClose()"
      >close</mat-icon
    >
  </tup-mat-title-bar>

  <mat-dialog-content>
    <h3 class="setting-section-title margin-adjustment">Chart view set up</h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Title Mode</mat-label>
        <mat-select
          (ngModelChange)="selectTitleMode($event)"
          [(ngModel)]="chartSettings.activeTitleMode"
        >
          <mat-option
            *ngFor="let mode of titleModes"
            [value]="mode.displayType"
          >
            {{ mode.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Primary data item</mat-label>
        <mat-select [(ngModel)]="chartSettings.primaryDataItem">
          <mat-option
            *ngFor="let dataItem of chartDataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Primary chart type</mat-label>
        <mat-select
          [(ngModel)]="chartSettings.primaryChartType"
          (selectionChange)="onPrimaryChartTypeChange()"
        >
          <mat-option
            *ngFor="let chartType of chartTypes"
            [value]="chartType.value"
          >
            {{ chartType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Column sort order</mat-label>
        <mat-select [(ngModel)]="chartSettings.columnSortOrder">
          <mat-option
            *ngFor="let columnSortOrder of columnSortOrders"
            [value]="columnSortOrder"
          >
            {{ columnSortOrder }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      [ngClass]="{ 'is-disabled': !isSuitableChartForMixing }"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        class="input-form-field"
      >
        <mat-label>Secondary data item</mat-label>
        <mat-select
          (ngModelChange)="onSecondaryDataItemChange($event)"
          [(ngModel)]="chartSettings.secondaryDataItem"
        >
          <mat-option
            [value]="0"
            *ngIf="chartSettings.primaryChartType !== 'tupScatter'"
          >
            None
          </mat-option>
          <mat-option
            *ngFor="let dataItem of chartDataItems"
            [value]="dataItem.value"
          >
            {{ dataItem.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex
        [ngClass]="{ 'is-disabled': isSecondaryChartTypeDisabled }"
        class="input-form-field"
      >
        <mat-label>Secondary chart type</mat-label>
        <mat-select [(ngModel)]="chartSettings.secondaryChartType">
          <mat-option
            *ngFor="let chartType of secondaryChartTypes"
            [value]="chartType.value"
          >
            {{ chartType.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider class="settings-divider"></mat-divider>

    <h3 class="setting-section-title margin-adjustment">
      Chart data preferences
    </h3>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
        class="input-form-field"
      >
        <mat-label>Top rows count</mat-label>
        <input
          matInput
          type="number"
          step="1"
          pattern="[0-9]"
          min="1"
          [(ngModel)]="chartSettings.topRowsCount"
          (blur)="onTopRowsCountChange()"
          (keydown)="onKeydown($event)"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        tupMatFormField
        fxFlex="100"
        class="input-form-field"
      >
        <mat-label>Decimal places</mat-label>
        <input
          matInput
          type="number"
          step="1"
          pattern="[0-9]"
          [max]="maxDecimalPlaces"
          min="0"
          [(ngModel)]="chartSettings.decimalPlaces"
          (blur)="onDecimalPlacesChange()"
          (keydown)="onKeydown($event)"
          [matTooltip]="'The maximum decimal places is ' + maxDecimalPlaces"
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      color="primary"
      appearance="medium"
      tupMatButton
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      appearance="medium"
      tupMatButton
      cdkFocusRegionstart
      (click)="onButtonClick()"
    >
      Apply settings
    </button>
  </mat-dialog-actions>
</div>
