import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  SaveChartStyleDialogComponent,
  SaveChartStyleDialogData,
} from '../dialogs/save-chart-style-dialog/save-chart-style-dialog.component';
import { ChartTargetMode, DefaultChartStyles } from '../models';
import { TupDocumentService } from '@telmar-global/tup-document-storage';
import {
  ManageChartStyleDialogData,
  ManageChartStylesDialogComponent,
} from '../dialogs/manage-chart-styles-dialog/manage-chart-styles-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartStyleService {
  private defaultChartStyles: BehaviorSubject<DefaultChartStyles> =
    new BehaviorSubject<DefaultChartStyles>(null);
  public defaultChartStyles$ = this.defaultChartStyles.asObservable();

  constructor(
    private dialog: MatDialog,
    public tupDocumentService: TupDocumentService
  ) {}

  public openSaveDialog(
    data: SaveChartStyleDialogData
  ): MatDialogRef<SaveChartStyleDialogComponent> {
    const options = {
      width: '600px',
      data: data,
    };
    return this.dialog.open(SaveChartStyleDialogComponent, options);
  }

  public openManageDialog(
    data: ManageChartStyleDialogData
  ): MatDialogRef<ManageChartStylesDialogComponent> {
    const options = {
      width: '1200px',
      maxWidth: '90vw',
      closeOnNavigation: true,
      data,
    };
    return this.dialog.open(ManageChartStylesDialogComponent, options);
  }

  public updateDefaultChartStyles(defaultChartStyles: DefaultChartStyles) {
    this.defaultChartStyles.next(defaultChartStyles);
  }

  public getDefaultChartStyles(): DefaultChartStyles {
    return {
      [ChartTargetMode.single]: null,
      [ChartTargetMode.combined]: null,
      [ChartTargetMode.surveysGroup]: null,
      [ChartTargetMode.insightsGroup]: null,
    };
  }
}
