<div
  fxLayout="row"
  fxLayoutGap="10px"
  fxLayoutAlign="start center"
  class="weight-control--compact"
  matTooltip="Weight"
>
  <mat-form-field
    appearance="outline"
    tupMatFormField
    fxFlex
    class="input-form-field"
    data-qa="weights-dropdown"
  >
    <mat-label>Weight</mat-label>
    <mat-select
      [disabled]="surveys?.length > 1"
      [value]="currentWeightIndex"
      (selectionChange)="onWeightDescriptionChange($event)"
    >
      <mat-option
        data-qa="weight-option"
        *ngFor="let weight of weightDescriptions"
        [disabled]="!isWeightSelectable(weight)"
        [value]="weight.index"
        [matTooltip]="weight.description"
      >
        {{ weight.index }}: {{ weight.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
