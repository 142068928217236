import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  DEFAULT_SELECTED_AFFINITY_DATA_ITEM_IDS,
  DATA_ITEMS_MAP,
  DataItem,
  DataItemsSelection,
  DataItemType,
  DEFAULT_AFFINITY_DATA_ITEMS,
  DEFAULT_CROSSTAB_DATA_ITEMS,
  DefaultDataItem,
  ReportMode,
  SurveyDataItems,
  DefaultDataItems,
} from '../models';
import { cloneDeep, uniqBy } from 'lodash';
import { ReportPreferencesService } from '../services/report-preferences.service';
import {
  NTILE_END_ADI_OPTION,
  NTILE_START_ADI_OPTION,
} from '../models/n-tiles.model';

@Injectable({
  providedIn: 'root',
})
export class DataItemsService {
  // tslint:disable-next-line:variable-name
  private _defaultDataItems: DataItemsSelection =
    this.getDefaultDataItemsSelection();

  // actual data items used in the crossTab table, dialogs and so on
  private actualDataItems: BehaviorSubject<DataItemsSelection> =
    new BehaviorSubject<DataItemsSelection>(cloneDeep(this._defaultDataItems));
  public actualDataItems$ = this.actualDataItems.asObservable();

  private sortedRowsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public sortedRows$ = this.sortedRowsSubject.asObservable();

  private chartDataItems: BehaviorSubject<DataItem[]> = new BehaviorSubject<
    DataItem[]
  >([]);
  public chartDataItems$ = this.chartDataItems.asObservable();

  private activeReportMode: ReportMode;

  constructor(private reportPreferencesService: ReportPreferencesService) {
    this.listenToReportModeChanges();
  }

  public setDataItems(surveyDataItems: SurveyDataItems): void {
    const surveyDataItemsSelection =
      this.getSurveyDataItemsSelection(surveyDataItems);
    const savedDefaultDataItems =
      this.reportPreferencesService.getSavedDefaultDataItems();
    const actualDataItemsSelection = savedDefaultDataItems
      ? this.formatDataItems(
          savedDefaultDataItems[ReportMode.crossTab],
          savedDefaultDataItems[ReportMode.affinity],
          savedDefaultDataItems[ReportMode.separatedRank],
          savedDefaultDataItems[ReportMode.combinedRank]
        )
      : surveyDataItems.availableDataItemIds.length < 1 &&
        surveyDataItems.defaultDataItemIds.length < 1
      ? this.getDefaultDataItemsSelection()
      : surveyDataItemsSelection;

    this._defaultDataItems = cloneDeep(surveyDataItemsSelection);
    this.actualDataItems.next(actualDataItemsSelection);

    this.updateChartDataItems();
  }

  public updateDataItems(
    reportMode: ReportMode,
    dataItems: DataItem[],
    decimalPoints: number
  ): void {
    const newActualDataItems = {
      ...this.actualDataItems.value,
      [reportMode]: dataItems,
    };

    this.notifyPersistentDataItemsChanged(newActualDataItems, decimalPoints);

    this.actualDataItems.next(newActualDataItems);

    this.updateChartDataItems(reportMode);
  }

  public getDataItems(reportMode: ReportMode): DataItem[] {
    return this.actualDataItems.value[reportMode];
  }

  public getActiveDataItems(
    reportMode: ReportMode,
    includeAffinityItems = true
  ): DataItem[] {
    const items = this.getDataItems(reportMode).filter(
      (selectionItem: DataItem) =>
        selectionItem.available && selectionItem.selected
    );
    return includeAffinityItems
      ? items
      : items.filter(
          (item: DataItem) =>
            item.id !== DataItemType.affinityRank &&
            item.id !== DataItemType.affinityScore
        );
  }

  public getAllActiveDataItems(): DataItem[] {
    let items: DataItem[] = [];
    for (let reportMode in ReportMode) {
      if (!isNaN(Number(reportMode))) {
        items = items.concat(this.getActiveDataItems(+reportMode));
      }
    }
    return uniqBy(items, `id`);
  }

  public getDefaultDataItems(mode?: ReportMode): DataItem[] {
    return cloneDeep(this._defaultDataItems)[
      mode !== undefined ? mode : this.activeReportMode
    ];
  }

  public updateChartDataItems(mode?: ReportMode): void {
    const chartDataItems = this.getActiveDataItems(
      mode ? mode : ReportMode.crossTab,
      false
    );

    this.chartDataItems.next(chartDataItems);
  }

  public updateSortedRows(rows) {
    this.sortedRowsSubject.next(rows);
  }

  public toggleNTilesDataItems(
    mode: ReportMode,
    isNTilesCoding: boolean,
    additionalOptions: string[]
  ): void {
    const selected =
      additionalOptions.filter((option) =>
        [NTILE_START_ADI_OPTION, NTILE_END_ADI_OPTION].includes(option)
      ).length > 0;

    let dataItems = this.getDataItems(mode);

    const nTilesSelection = selected && isNTilesCoding;

    let nTilesStartItem = dataItems.filter((dataItem) =>
      [DataItemType.nTileStart].includes(dataItem.id)
    );

    // In case nTiles dataitem is not already picked
    if (nTilesStartItem.length === 0) {
      let dataItemsLen = dataItems.length;
      const nTileDataItems = [
        DATA_ITEMS_MAP[DataItemType.nTileStart],
        DATA_ITEMS_MAP[DataItemType.nTileEnd],
      ].map((item) => ({
        ...item,
        available: true,
        originalIndex: dataItemsLen++,
        selected: false,
      }));

      dataItems = dataItems.concat(...nTileDataItems);

      nTilesStartItem = [nTileDataItems[0]];
    }

    if (nTilesStartItem[0].selected !== nTilesSelection) {
      dataItems = dataItems.map((dataItem) =>
        [DataItemType.nTileStart, DataItemType.nTileEnd].includes(dataItem.id)
          ? {
              ...dataItem,
              selected: nTilesSelection,
              available: nTilesSelection,
            }
          : dataItem
      );

      this.updateNTilesDataItems(mode, dataItems);
    }
  }

  public removeNTilesDataItems(mode: ReportMode) {
    let dataItems = this.getDataItems(mode);
    const nTIlesStartItem = dataItems.filter((dataItem) =>
      [DataItemType.nTileStart].includes(dataItem.id)
    );
    if (nTIlesStartItem.length > 0 && nTIlesStartItem[0].selected !== false) {
      dataItems = dataItems.map((dataItem) =>
        [DataItemType.nTileStart, DataItemType.nTileEnd].includes(dataItem.id)
          ? {
              ...dataItem,
              selected: false,
              available: false,
            }
          : dataItem
      );

      this.updateNTilesDataItems(mode, dataItems);
    }
  }

  private updateNTilesDataItems(mode: ReportMode, dataItems: DataItem[]) {
    this.actualDataItems.next({
      ...this.actualDataItems.value,
      [mode]: dataItems,
    });

    const updatedDefaultItems = [
      ReportMode.crossTab,
      ReportMode.affinity,
      ReportMode.separatedRank,
      ReportMode.combinedRank,
    ].reduce(
      // tslint:disable-next-line:no-shadowed-variable
      (acc: DefaultDataItems, mode: ReportMode) => ({
        ...acc,
        [mode]: this.actualDataItems.value[mode].map((item: DataItem) => ({
          id: item.id,
          selected: item.selected,
        })),
      }),
      {} as DefaultDataItems
    );

    this.reportPreferencesService.updateDefaultDataItems(
      updatedDefaultItems,
      null,
      false,
      false,
      false
    );

    this.updateChartDataItems(mode);
  }

  private getDefaultDataItemsSelection(): DataItemsSelection {
    return this.formatDataItems(
      DEFAULT_CROSSTAB_DATA_ITEMS,
      DEFAULT_AFFINITY_DATA_ITEMS
    );
  }

  private getSurveyDataItemsSelection({
    availableDataItemIds,
    defaultDataItemIds,
  }: SurveyDataItems): DataItemsSelection {
    const selectedCrossTabIds =
      defaultDataItemIds.length > 0
        ? defaultDataItemIds
        : this.getSelectedDataItemIds(DEFAULT_CROSSTAB_DATA_ITEMS);
    const selectedAffinityIds =
      defaultDataItemIds.length > 0
        ? [
            ...defaultDataItemIds.filter((id: number) =>
              DEFAULT_SELECTED_AFFINITY_DATA_ITEM_IDS.includes(id)
            ),
            DataItemType.affinityScore,
            DataItemType.affinityRank,
          ]
        : this.getSelectedDataItemIds(DEFAULT_AFFINITY_DATA_ITEMS);

    return availableDataItemIds.length > 0
      ? this.formatDataItems(
          this.formatDefaultDataItems(
            availableDataItemIds,
            selectedCrossTabIds
          ),
          this.formatDefaultDataItems(
            [
              ...availableDataItemIds,
              DataItemType.affinityScore,
              DataItemType.affinityRank,
            ],
            selectedAffinityIds
          )
        )
      : this.formatDataItems(
          this.formatDefaultDataItems(
            DEFAULT_CROSSTAB_DATA_ITEMS.map((item: DefaultDataItem) => item.id),
            selectedCrossTabIds
          ),
          this.formatDefaultDataItems(
            DEFAULT_AFFINITY_DATA_ITEMS.map((item: DefaultDataItem) => item.id),
            selectedAffinityIds
          )
        );
  }

  private formatDataItems(
    crossTabDataItems: DefaultDataItem[],
    affinityDataItems: DefaultDataItem[],
    separatedRankDataItems?: DefaultDataItem[],
    combinedRankDataItems?: DefaultDataItem[]
  ): DataItemsSelection {
    return {
      [ReportMode.crossTab]: this.formatReportModeDataItems(crossTabDataItems),
      [ReportMode.affinity]: this.formatReportModeDataItems(affinityDataItems),
      [ReportMode.separatedRank]: this.formatReportModeDataItems(
        separatedRankDataItems || crossTabDataItems
      ),
      [ReportMode.combinedRank]: this.formatReportModeDataItems(
        combinedRankDataItems || crossTabDataItems
      ),
    };
  }

  private getSelectedDataItemIds(dataItems: DefaultDataItem[]): number[] {
    return dataItems
      .filter((item: DefaultDataItem) => item.selected)
      .map((item: DefaultDataItem) => item.id);
  }

  private formatDefaultDataItems(
    dataItemIds: number[],
    selectedIds: number[]
  ): DefaultDataItem[] {
    return (
      [
        ...selectedIds,
        ...dataItemIds.filter((id: number) => !selectedIds.includes(id)),
      ]
        // to hide unsupported calculations for now
        .filter((id: number) => 'cellKey' in DATA_ITEMS_MAP[id])
        .map((id: number) => ({
          id,
          selected: selectedIds.includes(id),
        }))
    );
  }

  private formatReportModeDataItems(dataItems: DefaultDataItem[]): DataItem[] {
    return (
      dataItems
        // to hide unsupported calculations for now
        .filter((item: DefaultDataItem) => 'cellKey' in DATA_ITEMS_MAP[item.id])
        .map((item: DefaultDataItem, index: number) => ({
          ...DATA_ITEMS_MAP[item.id],
          ...item,
          available: true,
          originalIndex: index,
        }))
    );
  }

  private notifyPersistentDataItemsChanged(
    actualDataItems: DataItemsSelection,
    decimalPoints: number
  ): void {
    this.reportPreferencesService.updateDefaultDataItems(
      [
        ReportMode.crossTab,
        ReportMode.affinity,
        ReportMode.separatedRank,
        ReportMode.combinedRank,
      ].reduce(
        (acc: DefaultDataItems, mode: ReportMode) => ({
          ...acc,
          [mode]: actualDataItems[mode].map((item: DataItem) => ({
            id: item.id,
            selected: item.selected,
          })),
        }),
        {} as DefaultDataItems
      ),
      decimalPoints
    );
  }

  private listenToReportModeChanges(): void {
    this.reportPreferencesService.reportMode$.subscribe((mode: ReportMode) => {
      this.activeReportMode = mode;
      this.updateChartDataItems(mode);
    });
  }
}
