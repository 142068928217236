import { Component, OnInit } from '@angular/core';
import { TupDocument } from '@telmar-global/tup-document-storage';
import { Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { SurveyTimeDocument } from 'src/app/models';
import {
  DocumentService,
  RequestLoadingService,
  TargetLoading,
} from 'src/app/services';
import { ErrorHandlingService } from '../../services/error-handling.service';

@Component({
  selector: 'app-undo-redo-controls',
  templateUrl: './undo-redo-controls.component.html',
  styleUrls: ['./undo-redo-controls.component.scss'],
})
export class UndoRedoControlsComponent implements OnInit {
  public isSaving = false;
  public isUndoing = false;
  public canUndo = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private requestLoadingService: RequestLoadingService,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.listenToLoadingState();
    this.listenToDocumentDataChanges();
    this.listenToErrorHandlingChanges();
  }

  public undo(): void {
    this.isUndoing = true;
    this.documentService.undoDocument();
  }

  private listenToLoadingState(): void {
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) => targetLoading.target === 'auto-save'
        )
      )
      .subscribe((targetLoading: TargetLoading) => {
        this.isSaving = targetLoading.isLoading;
        if (!this.isSaving) {
          this.isUndoing = false;
        }
      });
  }

  private listenToDocumentDataChanges(): void {
    this.documentService.restoreDocumentState$
      .pipe(takeUntil(this.unsubscribe), delay(0))
      .subscribe((doc: TupDocument<SurveyTimeDocument>) => {
        this.canUndo = !!doc;
      });
  }

  private listenToErrorHandlingChanges(): void {
    this.errorHandlingService.undoError$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((shouldUndoError: boolean) => {
        if (shouldUndoError && this.canUndo) {
          this.undo();
        }
      });
  }
}
