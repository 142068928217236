import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_CLEAN_DROP_RESPONDENTS_VALID_VALUES_THRESHOLD } from 'src/app/models';

const RESPONDENTS_THRESHOLD_CUSTOM_VALUE = 'custom';

@Component({
  templateUrl: './cluster-preferences-dialog.component.html',
  styleUrls: ['./cluster-preferences-dialog.component.scss'],
})
export class ClusterPreferencesDialogComponent {
  public respondentsValidValuesThreshold:
    | number
    | typeof RESPONDENTS_THRESHOLD_CUSTOM_VALUE = DEFAULT_CLEAN_DROP_RESPONDENTS_VALID_VALUES_THRESHOLD;
  public customRespondentsValidValuesThreshold = 40;
  constructor(
    public dialogRef: MatDialogRef<ClusterPreferencesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const respondentThreshold = data?.cleanDropRespondentsValidValuesThreshold;
    if (respondentThreshold != null && respondentThreshold != undefined) {
      if (respondentThreshold > 0 && respondentThreshold < 100) {
        this.respondentsValidValuesThreshold =
          RESPONDENTS_THRESHOLD_CUSTOM_VALUE;
        this.customRespondentsValidValuesThreshold = respondentThreshold;
      } else {
        this.respondentsValidValuesThreshold = respondentThreshold;
      }
    }
  }

  public save(): void {
    this.dialogRef.close({
      cleanDropRespondentsValidValuesThreshold:
        this.respondentsValidValuesThreshold ===
        RESPONDENTS_THRESHOLD_CUSTOM_VALUE
          ? this.customRespondentsValidValuesThreshold
          : this.respondentsValidValuesThreshold,
    });
  }

  public updateRespondentThreshold() {
    this.respondentsValidValuesThreshold = RESPONDENTS_THRESHOLD_CUSTOM_VALUE;
  }

  public close(): void {
    this.dialogRef.close(null);
  }
}
