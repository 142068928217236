import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { DisplayType, Operator, Target, TargetType } from '../../models';
import {
  TargetService,
  TitleLevelsService,
  TitleModeService,
} from 'src/app/services';
import { TargetTitlePipe } from 'src/app/pipes';
import { first } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';

enum CalculationMethod {
  TOTAL = 'TOTAL',
  MEAN = 'MEAN',
  MEANZ = 'MEANZ',
  MEDIAN = 'MEDIAN',
  MEDIANZ = 'MEDIANZ',
}

enum Decimal {
  NONE = 'None',
  ONE = 'DEC(1)',
  TWO = 'DEC(2)',
}

const MIN_MIDPOINT = 1;
const MAX_MIDPOINT = 99999;
const MIDPOINT_EQUALITY_CODE = '*#';

@Component({
  selector: 'app-volumetric-coding-dialog',
  templateUrl: './volumetric-coding-dialog.component.html',
  styleUrls: ['./volumetric-coding-dialog.component.scss'],
})
export class VolumetricCodingDialogComponent implements OnInit {
  public readonly minMidpoint = MIN_MIDPOINT;
  public readonly maxMidpoint = MAX_MIDPOINT;
  public displayedColumns = ['coding', 'midpoint'];
  public dataSource = [];
  public selectedTargets: Target[];
  public selectedTargetType = TargetType.rows;
  public targetTypeOptions = Object.keys(TargetType).filter((key) =>
    isNaN(Number(key))
  );
  public calculationMethods = [...new Set(Object.keys(CalculationMethod))];
  public selectedCalculationMethod = CalculationMethod.TOTAL;
  public decimals = [Decimal.NONE, Decimal.ONE, Decimal.TWO];
  public selectedDecimal = Decimal.NONE;
  public codingStatement: string = '';
  public titleStatement: string = '';
  public transformedTitleStatement = '';
  public innerCoding: string = '';
  public innerTitle: string = '';
  public transformedInnerTitle: string = '';
  public ownTitle: string;
  private titleMode: DisplayType;
  private titleLevels: number[];
  public formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<VolumetricCodingDialogComponent>,
    private targetService: TargetService,
    private targetTitlePipe: TargetTitlePipe,
    private titleModeService: TitleModeService,
    private titleLevelsService: TitleLevelsService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!data) return;
    this.selectedTargets = data.selectedTargets.map((target) => ({
      ...cloneDeep(target),
      titlePrefix: Operator.volumetric,
    }));
    this.selectedTargetType = data.targetType;
  }

  ngOnInit(): void {
    this.titleModeService.titleMode$
      .pipe(first())
      .subscribe((titleMode) => (this.titleMode = titleMode));
    this.titleLevelsService.titleLevels$
      .pipe(first())
      .subscribe((titleLevels: number[]) => (this.titleLevels = titleLevels));
    this.initializeDataSource();

    this.formGroup = this.formBuilder.group({
      midpoints: this.formBuilder.array(this.loadMidpoint(this.dataSource)),
    });

    this.updateCodingStatement();

    this.changeDetectorRef.detectChanges();
  }

  public initializeDataSource() {
    this.dataSource = this.selectedTargets.map((target) => ({
      title: target.title,
      transformedTitle: this.targetTitlePipe.transform(
        target,
        this.titleMode,
        this.titleLevels
      ),
      coding: target.coding,
      id: target.id,
    }));
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public onGenerateClick(): void {
    const innerTarget = {
      ...this.targetService.createTarget(),
      title: this.innerTitle,
      coding: this.innerCoding,
      titlePrefix: Operator.volumetric,
      targets: this.selectedTargets,
      volumetricCoding: {
        calculationMethod: this.selectedCalculationMethod,
        decimal: this.selectedDecimal,
      },
    };

    this.dialogRef.close({
      target: {
        ...this.targetService.createTarget(),
        title: this.titleStatement,
        coding: this.codingStatement,
        targets: [innerTarget],
        ...(this.ownTitle && {
          activeTitleMode: DisplayType.ownTitle,
          ownTitle: this.ownTitle,
        }),
      },
      targetType: this.selectedTargetType,
    });
  }

  public getTargetTypeValue(type: string): string {
    return TargetType[type];
  }

  public updateCodingStatement() {
    if (this.formGroup.valid) {
      const midpointsControl = this.formGroup.get('midpoints') as FormArray;

      const calcMethod =
        this.selectedCalculationMethod !== CalculationMethod.TOTAL
          ? this.selectedCalculationMethod
          : '';
      const displayStatement =
        this.selectedDecimal !== Decimal.NONE ? ` ${this.selectedDecimal}` : '';

      const [innerTitle, transformedInnerTitle, innerCoding] = this.dataSource
        .reduce(
          (prev, current, index) => {
            const midpoint = midpointsControl.at(index).value?.midpoint;
            return [
              [
                ...prev[0],
                `(${current.title}${
                  midpoint ? MIDPOINT_EQUALITY_CODE + midpoint : ''
                })`,
              ],
              [
                ...prev[1],
                `(${current.transformedTitle}${
                  midpoint ? MIDPOINT_EQUALITY_CODE + midpoint : ''
                })`,
              ],
              [
                ...prev[2],
                `(${current.coding}${
                  midpoint ? MIDPOINT_EQUALITY_CODE + midpoint : ''
                })`,
              ],
            ];
          },
          [[], [], []]
        )
        .reduce((prev, current) => [...prev, current.join('+')], []);

      this.innerTitle = innerTitle;
      this.innerCoding = innerCoding;
      this.transformedInnerTitle = transformedInnerTitle;
      this.titleStatement =
        innerTitle.length > 0
          ? `(${calcMethod}(${innerTitle})${displayStatement})`
          : '';
      this.transformedTitleStatement =
        innerTitle.length > 0
          ? `(${calcMethod}(${transformedInnerTitle})${displayStatement})`
          : '';
      this.codingStatement =
        innerCoding.length > 0
          ? `(${calcMethod}(${innerCoding})${displayStatement})`
          : '';
    }
  }

  public isGeneratable() {
    return this.formGroup.valid && this.transformedTitleStatement;
  }

  public onTitleChanged() {
    this.ownTitle = this.transformedTitleStatement;
  }

  private createMidpointFormGroup(item) {
    return new FormGroup({
      coding: new FormControl(item.coding),
      midpoint: new FormControl(item.midpoint, [
        Validators.min(1),
        Validators.max(99999),
      ]),
    });
  }

  private loadMidpoint(midpoints) {
    return midpoints.map((item) => this.createMidpointFormGroup(item));
  }

  get midpointFormArray(): FormArray {
    return this.formGroup.controls['midpoints'] as FormArray;
  }
}
