import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CorrespondenceDataItem,
  CorrespondenceFeatureItem,
  CorrespondenceFilterOptions,
  CorrespondenceItem,
  CorrespondenceSettings,
  CorrespondenceSortColumnType,
  CorrespondenceTableRowData,
  CorrespondenceWeightType,
  CrossTabTableData,
  HighlightCorrespondenceSegment,
  StartCorrespondenceRequestBody,
  StartCorrespondenceResponseBody,
} from '../models';
import { compare } from '../utils/sortHelper';
import { cloneDeep } from 'lodash';
import { CorrespondenceSettingsRowData } from '../dialogs/correspondence-settings-dialog/correspondence-settings-dialog.component';
import { CorrespondenceApiService } from './correspondence-api.service';

@Injectable({
  providedIn: 'root',
})
export class CorrespondenceService {
  constructor(private apiService: CorrespondenceApiService) {}

  public formatTableRows(
    {
      columns,
      colCoords,
      colINFs,
      colAbsContributions,
      colRelContributions,
      rows,
      rowCoords,
      rowINFs,
      rowAbsContributions,
      rowRelContributions,
    }: {
      columns: CorrespondenceFeatureItem[];
      colCoords: number[][];
      colAbsContributions: number[][];
      colRelContributions: number[][];
      colINFs: number[];
      rows: CorrespondenceFeatureItem[];
      rowCoords: number[][];
      rowINFs: number[];
      rowAbsContributions: number[][];
      rowRelContributions: number[][];
    },
    settings: CorrespondenceSettings,
    filterOptions: CorrespondenceFilterOptions | null,
    crosstabData: CrossTabTableData[],
    activeSurveyCode: string
  ): CorrespondenceTableRowData[] {
    const colDataRows = this.formatDataRows(
      {
        featureItems: columns,
        coords: colCoords,
        infs: colINFs,
        absContributions: colAbsContributions,
        relContributions: colRelContributions,
      },
      settings,
      [crosstabData[0]],
      activeSurveyCode,
      'column'
    );
    const rowDataRows = this.formatDataRows(
      {
        featureItems: rows,
        coords: rowCoords,
        infs: rowINFs,
        absContributions: rowAbsContributions,
        relContributions: rowRelContributions,
      },
      settings,
      crosstabData.slice(1),
      activeSurveyCode,
      'row'
    );

    const shouldFilterColumns = filterOptions && !filterOptions.allColumns;
    const shouldFilterRows = filterOptions && !filterOptions.allRows;
    return [
      ...(shouldFilterColumns
        ? this.filterDataRows(
            colDataRows,
            filterOptions.sortBy,
            filterOptions.visibleColumns
          )
        : colDataRows),
      ...(shouldFilterRows
        ? this.filterDataRows(
            rowDataRows,
            filterOptions.sortBy,
            filterOptions.visibleRows
          )
        : rowDataRows),
    ];
  }

  public formatDataRows(
    {
      featureItems,
      coords,
      infs,
      absContributions,
      relContributions,
    }: {
      featureItems: CorrespondenceFeatureItem[];
      coords: number[][];
      absContributions: number[][];
      infs: number[];
      relContributions: number[][];
    },
    settings: CorrespondenceSettings,
    crosstabData: CrossTabTableData[],
    surveyCode: string,
    rowOrColumn: string
  ): CorrespondenceTableRowData[] {
    let crosstabValue = [];
    let dataItem = '';
    switch (settings.dataItem) {
      case CorrespondenceDataItem.audience:
        dataItem = 'projected';
        break;
      case CorrespondenceDataItem.resps:
        dataItem = 'sample';
        break;
      case CorrespondenceDataItem.percentCol:
        dataItem = 'column';
        break;
      case CorrespondenceDataItem.percentRow:
        dataItem = 'row';
        break;
      case CorrespondenceDataItem.index:
        dataItem = 'index';
        break;
    }

    if (rowOrColumn === 'column') {
      crosstabValue = crosstabData[0].data
        .map((item) =>
          !item.isTotalsColumn && item.surveyCode === surveyCode
            ? item[dataItem]
            : undefined
        )
        .filter((item) => item !== undefined);
    } else {
      crosstabValue = crosstabData
        .map((item) =>
          item.data
            .map((row) =>
              row.isTotalsColumn && row.surveyCode === surveyCode
                ? row[dataItem]
                : undefined
            )
            // tslint:disable-next-line:no-shadowed-variable
            .filter((item) => item !== undefined)
        )
        .reduce((acc, curr) => acc.concat(curr), []);
    }
    return featureItems.map((featureItem, index) => {
      return {
        ...featureItem,
        id: featureItem.coding,
        x: this.roundToTwo(coords[index][settings.xAxisFactor]),
        y: this.roundToTwo(coords[index][settings.yAxisFactor]),
        z:
          settings.showWeightedSymbols === CorrespondenceWeightType.INF
            ? this.roundToTwo(infs[index])
            : this.roundToTwo(crosstabValue[index]),
        inf: this.roundToTwo(infs[index]),
        absContribution: absContributions[index].map((contribution) =>
          this.roundToTwo(contribution)
        ),
        relContribution: relContributions[index].map((contribution) =>
          this.roundToTwo(contribution)
        ),
        relSign: relContributions[index].map((_, j) =>
          this.roundToTwo(coords[index][j]) < 0 ? '-' : '+'
        ),
        coordinates: coords[index].map((value) => this.roundToTwo(value)),
        sortableValues: [
          this.roundToTwo(infs[index]),
          ...coords[index].map((value) => this.roundToTwo(value)),
        ],
        visible: true,
        tempIndex: index,
      };
    });
  }

  public filterDataRows(
    dataRows: CorrespondenceTableRowData[],
    sortBy: CorrespondenceSortColumnType,
    visibleItems: number
  ): CorrespondenceTableRowData[] {
    cloneDeep(dataRows)
      .sort((a, b) =>
        compare(a.sortableValues[sortBy], b.sortableValues[sortBy], false)
      )
      .forEach((row, index) => {
        dataRows[row.tempIndex].visible = index < visibleItems;
      });
    return dataRows;
  }

  public formatSettingRows(
    data: StartCorrespondenceResponseBody
  ): CorrespondenceSettingsRowData[] {
    return data['p-exp-vars'].reduce(
      (acc: CorrespondenceSettingsRowData[], value: number, index: number) => [
        ...acc,
        {
          factorTitle: `Factor ${index + 1}`,
          percent: this.roundToTwo(value),
          percentCum: this.roundToTwo(
            index === 0 ? value : acc[index - 1].percentCum + value
          ),
          chi2: this.roundToTwo(data['chi-squared'][index]),
          degreeFree: data.dof[index],
        },
      ],
      []
    );
  }

  public getCorrespondenceData(
    body: StartCorrespondenceRequestBody
  ): Observable<StartCorrespondenceResponseBody> {
    return this.startCorrespondence(body);
  }

  private startCorrespondence(
    body: StartCorrespondenceRequestBody
  ): Observable<StartCorrespondenceResponseBody> {
    return this.apiService.request(
      'POST',
      environment.api.factorCluster.url,
      environment.api.factorCluster.endpoint.startCorrespondence,
      { body }
    );
  }

  public roundToTwo(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  public transposeCorrespondenceData(
    absContribution: number[][],
    shouldShowHighlighting: boolean
  ): CorrespondenceItem[][] {
    const transformedArray: CorrespondenceItem[] = this.repackData(
      absContribution,
      shouldShowHighlighting
    );

    return [
      this.addSegmentsToInfColumn(absContribution, shouldShowHighlighting),
      ...this.splitDataIntoColumns(absContribution, transformedArray),
    ];
  }

  private repackData(
    absContribution: number[][],
    shouldShowHighlighting: boolean
  ) {
    const transformedArray = [];

    for (let i = 0; i < absContribution[0].length; i++) {
      let tempArr = [];
      for (let j = 0; j < absContribution.length - 1; j++) {
        tempArr.push(absContribution[j][i]);
      }
      const min = Math.min.apply(null, tempArr);
      const max = Math.max.apply(null, tempArr);
      const segments = 3;
      const segmentSize = (max - min) / segments;
      for (const value of tempArr) {
        transformedArray.push({
          value: this.roundToTwo(value),
          segment: shouldShowHighlighting
            ? this.getHighlightSegment(value, min, segmentSize)
            : undefined,
        });
      }
      tempArr = [];
    }
    return transformedArray;
  }

  private splitDataIntoColumns(
    data: number[][],
    numberOfColumns: CorrespondenceItem[]
  ) {
    const chunkSize = data.length - 1;
    const arrToReturn = [];
    for (let i = 0; i < numberOfColumns.length; i += chunkSize) {
      const chunk = numberOfColumns.slice(i, i + chunkSize);
      arrToReturn.push(chunk);
    }
    return arrToReturn;
  }

  private addSegmentsToInfColumn(data: any, shouldShowHighlighting: boolean) {
    const minForInf = Math.min.apply(null, data[data.length - 1]);
    const maxForInf = Math.max.apply(null, data[data.length - 1]);
    const infColumnSegments = [];
    const segments = 3;
    const segmentSize = (maxForInf - minForInf) / segments;
    for (const value of data[data.length - 1]) {
      infColumnSegments.push({
        value: this.roundToTwo(value),
        segment: shouldShowHighlighting
          ? this.getHighlightSegment(value, minForInf, segmentSize)
          : undefined,
      });
    }
    return infColumnSegments;
  }

  private getHighlightSegment(
    value: number,
    min: number,
    segmentSize: number
  ): HighlightCorrespondenceSegment {
    return value <= min + segmentSize
      ? HighlightCorrespondenceSegment.min
      : value <= min + 2 * segmentSize
      ? HighlightCorrespondenceSegment.mid
      : HighlightCorrespondenceSegment.max;
  }
}
