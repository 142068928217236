import { QueryList } from '@angular/core';

import { TupChartsComponent } from '@telmar-global/tup-charts';
import {
  TupChartData,
  TupChartOptions,
  TupChartType,
} from '@telmar-global/tup-document-exporter';

import { ChartSettings } from './charts.model';
import { Note, DocumentViewType, Target } from './document.model';

export enum ExportFileType {
  pptx,
  xlsx,
  googleSheets,
  googleSlides,
  csv,
  asc,
  png,
  jpeg,
  pdf,
  svg,
}

/**
 * Missing models (－‸ლ).
 */
export interface Data {
  labels: string[];
  datasets: Dataset[];
}

export interface Dataset {
  name: string;
  label: string;
  data: number[];
  dataFlag: unknown[];
  backgroundColor: string[];
  // Flesh this out if we ever need to use any of the other properties
  [key: string]: unknown;
}

export interface ExportParams {
  docName: string;
  exportTablebases?: Target[];
}

/**
 * Narrows down the type of secondary data.
 */
export const isData = (data: number[][] | Data): data is Data => {
  return (
    (data as Data).labels !== undefined && (data as Data).datasets !== undefined
  );
};

export interface SurveyTimeProps {
  settings: ChartSettings;
  primaryData: Data;
  secondaryData: number[][] | Data;
  charts: QueryList<TupChartsComponent>;
  title: string;
  subtitle: string;
  source: string[];
  selected: boolean;
  copyright?: string[];
  chartNote?: Note;
}

export interface ExportProps {
  primaryChartType: TupChartType;
  primaryChartData: TupChartData[];
  secondaryChartType?: TupChartType;
  secondaryChartData?: TupChartData[];
  chartColors: string[];
  chartOpts: TupChartOptions;
}

export const EXPORT_FORMAT_LABELS = {
  [ExportFileType.csv]: 'CSV',
  [ExportFileType.asc]: 'ASC',
  [ExportFileType.xlsx]: 'XLSX',
  [ExportFileType.googleSheets]: 'Google Sheets',
  [ExportFileType.googleSlides]: 'Google Slides',
  [ExportFileType.pptx]: 'PPT',
  [ExportFileType.jpeg]: 'JPEG',
  [ExportFileType.png]: 'PNG',
  [ExportFileType.svg]: 'SVG',
  [ExportFileType.pdf]: 'PDF',
};

export const DEFAULT_TABLE_EXPORT_FORMATS = [
  ExportFileType.xlsx,
  ExportFileType.googleSheets,
  ExportFileType.csv,
  ExportFileType.asc,
];

export const DEFAULT_IMAGE_EXPORT_FORMATS = [
  ExportFileType.png,
  ExportFileType.jpeg,
  ExportFileType.pdf,
  ExportFileType.svg,
];

export const COMPATIBLE_EXPORT_FORMATS = {
  [DocumentViewType.data]: DEFAULT_TABLE_EXPORT_FORMATS,
  [DocumentViewType.graph]: [
    ExportFileType.pptx,
    ExportFileType.xlsx,
    ExportFileType.googleSlides,
    ExportFileType.googleSheets,
  ],
  [DocumentViewType.pmaps]: DEFAULT_IMAGE_EXPORT_FORMATS,
  [DocumentViewType.geografix]: DEFAULT_IMAGE_EXPORT_FORMATS,
  [DocumentViewType.factorAnalysis]: DEFAULT_TABLE_EXPORT_FORMATS,
  [DocumentViewType.correspondence]: [
    ...DEFAULT_TABLE_EXPORT_FORMATS,
    ExportFileType.pptx,
    ...DEFAULT_IMAGE_EXPORT_FORMATS,
  ],
  [DocumentViewType.cluster]: [
    ...DEFAULT_TABLE_EXPORT_FORMATS,
    ExportFileType.pptx,
    ...DEFAULT_IMAGE_EXPORT_FORMATS,
  ],
  [DocumentViewType.audienceEffects]: [
    ExportFileType.pptx,
    ...DEFAULT_TABLE_EXPORT_FORMATS,
    ...DEFAULT_IMAGE_EXPORT_FORMATS,
  ],
};
