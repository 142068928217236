import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageChartStyleService {
  private manageChartStyleModeOn = new BehaviorSubject<boolean>(false);
  public manageChartStyleModeOn$ = this.manageChartStyleModeOn.asObservable();

  public manageChartStyleModeChange(modeOn: boolean): void {
    this.manageChartStyleModeOn.next(modeOn);
  }
}
